import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import Select from 'react-select';

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import RadioButton from "components/styled/RadioButton";

const radioSelection = {
  all: 0,
  selectedItems: 1
};

function RadioWithMultipleLists({ classes, isMulti, lists, onFilterChange, label}) {
  const [config, setConfig] = React.useState(radioSelection.all);
  const [selectedOption, setSelectedOption] = React.useState(lists[0]);
  const [selectedItems, setSelectedItems] = React.useState([]);

  const handleRadioChange = React.useCallback(radioOption => {
    setConfig(radioOption);
    if(radioOption === radioSelection.all) onFilterChange(null);
    else onFilterChange({
      key: selectedOption,
      items: selectedItems
    });
  }, [selectedItems, selectedOption, setConfig, onFilterChange]);

  const handleSelectedOptionChange = React.useCallback((option) => {
    setSelectedOption(option);
    setSelectedItems([]);
    onFilterChange({
      key: option,
      items: []
    });
  }, [setSelectedItems, setSelectedOption, onFilterChange]);

  const handleSelectedItemsChange = React.useCallback(selectedItems => {
    setSelectedItems(selectedItems);
    let items = [];
    if (selectedItems) {
      items = selectedItems;
    } 
    onFilterChange({
      key: selectedOption,
      items: items
    });
  }, [setSelectedItems, selectedOption, onFilterChange]);

  return (
    <div>
      <div>
        <RadioButton
          checked={config === radioSelection.all}
          onChange={() => handleRadioChange(radioSelection.all)}
          value={radioSelection.all}
          label="Incluir todos"
        />
        <RadioButton
          checked={config === radioSelection.selectedItems}
          onChange={() => handleRadioChange(radioSelection.selectedItems)}
          value={radioSelection.selectedItems}
          label={"Agregar " + label}
        />
      </div>
      {config === radioSelection.selectedItems && 
        <>
          <div>
            <Select
              placeholder={"Buscar " + label}
              value={selectedOption}
              onChange={handleSelectedOptionChange}
              options={lists}
            />
          </div>
          <div>
            <Select
              placeholder={"Buscar " + label}
              value={selectedItems}
              onChange={handleSelectedItemsChange}
              options={selectedOption.values}
              isMulti={isMulti}
            />
          </div>
        </>
      } 
    </div>
  );
}

RadioWithMultipleLists.propTypes = {
};

const styles = theme => ({
});

export default withStyles(styles)(RadioWithMultipleLists);
