import { CHECKLIST_EXCLUDED_OPERATIONS, OPERATIONS_LABEL, OPERATIONS_WITH_GLOBAL, GLOBAL_CATEGORIES } from '../../constants';
import { calculateCombinedScores } from './combinedScores';
import { duration } from "../dates/dates";
import Moment from 'moment';

export function selectedKeyfields(keyfields, states){
  let selected = [];
  states.forEach((state, idx) => {
    if(state)selected.push(keyfields[idx]);
  });
  return selected;
}

export function selectedCategory(categories, states, global, operation){
  if(!categories || !states || !operation) return null;
  if(global) return GLOBAL_CATEGORIES[operation.value];
  const idx = states.findIndex(value => value === true);
  return (idx !== -1)? categories[idx] : null;
}

export function groupByKeyfields(items, keyfields){
  let groups = [items.map((_, idx) => idx)];
  keyfields.forEach(keyfield => {
    if (keyfield) {
      const newGroups = [];
      let kfID = keyfield.id;
      groups.forEach((group) => {
        let bucket = {};
        group.forEach((itemIdx) => {
          let itemKF = items[itemIdx].keyfields.find(({id}) => id === kfID);
          if(!!itemKF){
            if(!bucket[itemKF.value]) bucket[itemKF.value] = [];
            bucket[itemKF.value].push(itemIdx);
          };
        });
        Object.values(bucket).forEach((group) => {
          newGroups.push(group);
        });
      });
      groups = newGroups;
    }
  });
  return groups;
}

function getRowsFromGroups(groups, items, category, keyfields, operation){
  const rows = [];
  groups.forEach((group, idx) => {
    const groupKeyfields = {};
    keyfields.forEach(({name, id}) => {
      groupKeyfields[name] = items[group[0]].keyfields.find((itemKeyfield) => itemKeyfield.id === id).value;
    });

    const scoreValue = calculateCombinedScores(group, items, operation, category, keyfields);

    let row = {
      id: idx,
      keyfields: groupKeyfields,
      category: {
        ...category,
        value: scoreValue
      },
      visitAnswersCount: group.length,
    };
    rows.push(row);
  });
  return rows;
}

export function getTableRows(items, keyfields, category, operation){
  if(keyfields.length === 0) return [];
  if(category === null) return [];
  if(operation === null) return [];
  const groups = groupByKeyfields(items, keyfields);
  return getRowsFromGroups(groups, items, category, keyfields, operation);
}

export function getVisitsVisorRows(visitAnswers){
  if(!visitAnswers || !visitAnswers.length) return [];

  return visitAnswers.sort(
    (a, b) => a.checkOutDate < b.checkOutDate ?
      1 : a.checkOutDate > b.checkOutDate ?
        -1 : 0
  ).map(visitAnswer => {
    let parsed = {};
    // Add the ID
    parsed.id = visitAnswer.id;
    // Add the name
    parsed.userName = visitAnswer.userRef.firstName + " " + visitAnswer.userRef.lastName;
    // Add the email
    parsed.email = visitAnswer.userRef.email;
    // Add the dates
    parsed.checkInDate = Moment(visitAnswer.checkInDate).format("DD/MM/YY, hh:mm:ss a");
    parsed.checkOutDate = Moment(visitAnswer.checkOutDate).format("DD/MM/YY, hh:mm:ss a");
    parsed.duration = duration(visitAnswer.checkInDate, visitAnswer.checkOutDate);
    // Add the keyfields
    let keyfields = {};
    visitAnswer.keyfields.forEach((keyfield) => {
      keyfields[keyfield.name] = keyfield.value;
    });
    parsed.keyfields = keyfields;
    return parsed;
  });
}

export function getChartData(groups, items, selectedItem, category){
  if(groups.length === 0 || !selectedItem) return {};
  const labels = [];
  const dataIDs = groups.find(group => {
    const sampleItem = items[group[0]].keyfields.find(({value}) => value === selectedItem.value);
    return !!sampleItem;
  });

  const dataValues = dataIDs.map(id => items[id])
    .sort((a, b) => a.checkInDate < b.checkInDate ? -1 : a.checkInDate > b.checkInDate ? 1 : 0)
    .map(item => {
      labels.push(Moment(item.checkInDate).format("DD-MM-YYYY"));
      const categoryFind = item.categories.find(({id}) => id === category.id);
      return categoryFind && categoryFind.evaluation !== null ? parseFloat(categoryFind.evaluation).toFixed() : 0;
      //return parseFloat(item.categories.find(({id}) => id === category.id).evaluation.toFixed(2))
    })

  const data = {};
  data.labels = labels;
  data.datasets = [{
    label: selectedItem.value,
    backgroundColor: 'rgb(255, 99, 132)',
    borderColor: 'rgb(255, 99, 132)',
    data: dataValues,
    lineTension: 0,
    pointHitRadius: 4,
    pointRadius: 8,
    fill: false
  }];
  return data;
}

export function getRadarChartData(visitAnswers, operation){
  if(!visitAnswers || !operation) return {};
  let labels = [];
  let dataValues = [];
  let bucket = {};
  visitAnswers.forEach(visitAnswer => {
    if (visitAnswer && visitAnswer.categories) {
      visitAnswer.categories.forEach(category => {
        if(isGlobalCategory(category.id)) return;
        if(!bucket[category.name]){
          bucket[category.name] = {
            label: category.name,
            values: []
          }
        }
        bucket[category.name].values.push(
          reduceToOneOperation(category, operation).evaluation
        );
      });
    }
  });

  Object.values(bucket).forEach(category => {
    labels.push(category.label);
    dataValues.push(parseFloat(
      category.values.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      ) / category.values.length).toFixed(2));
  });

  const data = {};
  data.labels = labels;
  data.datasets = [{
    label: "Distribución de Categorías",
    backgroundColor: 'rgb(66,233,203)',
    borderColor: 'rgb(66,233,203)',
    data: dataValues,
    lineTension: 0,
    pointHitRadius: 4,
    pointRadius: 8,
    fill: false
  }];
  return data;
}

export function getPieChartData(data, title){
  if(!data) return {};
  const total = data.reduce((acumm, {count}) => acumm + count, 0);
  const colors = data.map(({color}) => color);
  const labels = data.map(({label}) => label);
  const dataValues = data.map(({count}) => (count / total * 100).toFixed(2));

  const dataWrapper = {};
  dataWrapper.labels = labels;
  dataWrapper.datasets = [{
    label: title,
    backgroundColor: colors,
    borderWidth: 0,
    data: dataValues,
  }];
  return dataWrapper;
}

export function getOperationsFromColumns(columns){
  if(!columns || columns.length === 0) return [];
  const bucket = {};
  columns.forEach((column) => {
    if(column.operation && column.category){
      column.operation.forEach((op) => {
        // Check if the operator isn't excluded
        if(!CHECKLIST_EXCLUDED_OPERATIONS[op.operator]){
          if(!bucket[op.operator]){
            bucket[op.operator] = {
              id: op.operator,
              name: OPERATIONS_LABEL[op.operator],
              categories: []
            }
          }
          bucket[op.operator].categories.push(column.category);
        }
      });
    }
  });

  return Object.values(bucket);
}

export function isGlobalCategory(category){
  return !!Object.values(GLOBAL_CATEGORIES).find(
    (GLOBAL_CAT) => GLOBAL_CAT.id === category
  );
}

export function hasImageField(moduleRef){
  if(!moduleRef
    || !moduleRef.propertiesRef
    || !moduleRef.propertiesRef.fields
    || moduleRef.propertiesRef.fields.length === 0
  )
    return [];

  return moduleRef.propertiesRef.fields.some(field => {
    if (!field) {
      return false;
    }
    switch (field.__typename) {
      case "PhotoReportField":
      case "ChecklistField":
        return true;
      default:
        return false;
    }
  });
}

export function hasTablesField(moduleRef){
  if(!moduleRef
    || !moduleRef.propertiesRef
    || !moduleRef.propertiesRef.fields
    || moduleRef.propertiesRef.fields.length === 0
  )
    return [];

  return moduleRef.propertiesRef.fields.some(
    field => {
      if (!field) {
        return false;
      }else{
        return field.__typename === "TableField"
      }
    }
  );
}

export function hasGlobalOperation(operation){
  if(!operation || !operation.id) return false;
  return !!OPERATIONS_WITH_GLOBAL[operation.id];
}

export function getKeyfields(visit){
  if(!visit || !visit.keys || visit.keys.length === 0) return [];
  return visit.keys.map(key => ({id: key, name: key}));
}

export function getModulesWithImages(visit){
  if(!visit || !visit.engines || visit.engines.length === 0) return [];
  let modules = [];
  visit.engines.forEach(engine => {
    if(hasImageField(engine.moduleRef)){
      modules.push(engine.moduleRef);
    }
  });
  return modules;
}

export function getModulesWithTables(visit){
  if(!visit || !visit.engines || visit.engines.length === 0) return [];
  let modules = [];
  visit.engines.forEach(engine => {
    if(hasTablesField(engine.moduleRef)){
      modules.push(engine.moduleRef);
    }
  });
  return modules;
}

export function getSelectedKeyfield(keyfields, idKeyfield){
  if(!keyfields || keyfields.length === 0 || !idKeyfield) return {id: "Pendiente", name: "Pendiente"};
  return keyfields.find(keyfield => keyfield.id === idKeyfield);
}

export function getSelectedCategory(categories, idCategory){
  if(!categories || categories.length === 0 || !idCategory)
    return {id: "Pendiente", name: "Pendiente"};

  const GLOBAL = Object.values(GLOBAL_CATEGORIES).find(GLOBAL => GLOBAL.id === idCategory);

  return GLOBAL ? GLOBAL :
    categories.find(category => category.id === idCategory);
}

export function getCategories(operation){
  if(!operation || !operation.categories || operation.categories.length === 0) return [];
  return operation.categories.map(category => ({id: category, name: category}));
}

export function addGlobalCategory(categories, operation){
  if(!operation || !operation.value || !GLOBAL_CATEGORIES[operation.value]) return categories;
  return [{...GLOBAL_CATEGORIES[operation.value]}, ...categories];
}

function flatAnswer(answers, avoitFlatKeyfield){
  if(!answers && !answers.answer.__typename) return null;
  const typeName = answers.answer.__typename;
  const value = answers.answer[typeName];
  return (Array.isArray(value)) && !avoitFlatKeyfield ? value.join('-') : value;
}

function getKeyfieldsFromCheckIn(checkIn, keyfields, avoitFlatKeyfield){
  if(keyfields){
    return keyfields.map(({id}) => {
      // Keyfield answers may be optional, if it wasn't answered use a default label to group them all
      let answers = "No respondido";
      if(
        checkIn.questionAnswersRef &&
        checkIn.questionAnswersRef.answers &&
        Array.isArray(checkIn.questionAnswersRef.answers)
      ){
        const keyAnswers = checkIn.questionAnswersRef.answers.find(({key}) => key === id);
        if(keyAnswers) answers = flatAnswer(keyAnswers, avoitFlatKeyfield);
      }
      return {
        id: id,
        name: id,
        value: answers
      };
    });
  }
  return [];
}

function parseCalculatedCategories(categories, globals){
  let parsedCategories = categories.map(
    category => ({id: category.name,  ...category})
  );

  if(!!globals){
    globals.forEach(global => {
      const globalCategory = Object.values(GLOBAL_CATEGORIES)
        .find(GLOBAL_CAT => GLOBAL_CAT.gqlName === global.name);
      if(!!globalCategory)
        parsedCategories.push({
          id: globalCategory.id,
          name: globalCategory.name,
          operations: [{
            name: globalCategory.name,
            operator: globalCategory.operator,
            value: global.value
          }]
        });
    });
  }

  return parsedCategories;
}

export function parseVisitAnswers(visitAnwers, keyfields, avoitFlatKeyfield){
  if(!visitAnwers || visitAnwers.length === 0) return [];
  return visitAnwers.map(visitAnwer => {
    let parsedVisitAnswer = {};

    // Add the id
    parsedVisitAnswer.id = visitAnwer._id;

    // Add the modules
    if(visitAnwer.checkIn || visitAnwer.checkOut || visitAnwer.activities){
      parsedVisitAnswer.modules = [];
      if(visitAnwer.checkIn)
        parsedVisitAnswer.modules.push(visitAnwer.checkIn);
      if(visitAnwer.checkOut)
        parsedVisitAnswer.modules.push(visitAnwer.checkOut);
      if(visitAnwer.activities)
        visitAnwer.activities.forEach(
          activity => parsedVisitAnswer.modules.push(activity)
        );
    }

    // Add the dates
    parsedVisitAnswer.checkInDate = visitAnwer.checkInDate;
    parsedVisitAnswer.checkOutDate = visitAnwer.checkOutDate;

    // Add user's name
    parsedVisitAnswer.userRef = visitAnwer.userRef;

    // If keyfields exist, add them to the object
    if(!!keyfields && !!visitAnwer.checkIn){
      parsedVisitAnswer.keyfields = getKeyfieldsFromCheckIn(visitAnwer.checkIn, keyfields, avoitFlatKeyfield);
    }

    // Parse the calculated Columns
    if(!!visitAnwer.calculatedChecklistColumns){
      parsedVisitAnswer.categories = parseCalculatedCategories(
        visitAnwer.calculatedChecklistColumns.categories,
        visitAnwer.calculatedChecklistColumns.globals
      );
    }

    if (!!visitAnwer.analytics) {
      parsedVisitAnswer.analytics = visitAnwer.analytics;
    }

    return parsedVisitAnswer;
  });
}

function countActiveVisits(visitAnswers){
  return visitAnswers.reduce((acumm, visitAnswer) => {
    // A visit is active if it hasn't checkOutDate
    return !visitAnswer.checkOutDate ?
      acumm + 1 : acumm;
  }, 0);
}

function validApprovalStatus(approvalStatus){
  if(approvalStatus === "Pending" || approvalStatus === "Rejected")
    return false;
  return true;
}

function getClosedVisits(visitAnswers){
  return visitAnswers.filter((visitAnswer) => !!visitAnswer.checkOutDate ?
    validApprovalStatus(visitAnswer.approvalStatus) : false
  );
}

export function classifyAndFilter(visitAnswers){
  const activeCount = countActiveVisits(visitAnswers);
  const closedVisits = getClosedVisits(visitAnswers)
  return [activeCount, closedVisits];
}

function reduceToOneOperation(category, operation){
  const operationValue = category.operations.find(
    catOperation => catOperation.id === operation.value
  );

  return {
    id: category.id,
    name: category.name,
    evaluation: operationValue.value
  };
}

export function reduceOperations(visitAnswers, keyfield, operation){
  if(!visitAnswers || !keyfield || !operation) return [];

  return visitAnswers.map(
    (visitAnswer, i) => {

      let categories = [];

      if (visitAnswer.categories) {
        visitAnswer.categories.map(category => {
          const reduceOperation = reduceToOneOperation(category, operation);
          categories.push(reduceOperation);
        });
      }

      return {
      ...visitAnswer,
      keyfield: visitAnswer.keyfields.find(visitKey => visitKey.id === keyfield.id),
      categories: categories,
    }
  });
}

export function reduceToSelectedOperation(visitAnswers, operation){
  if(!visitAnswers || !operation) return [];
  return visitAnswers.map(
    visitAnswer => ({
      ...visitAnswer,
      categories: visitAnswer.categories ? visitAnswer.categories.map(
        category => reduceToOneOperation(category, operation)
      ) : [],
    })
  );
}

function removeCategoriesNA (listCategories) {
  return listCategories.filter(categories => {
    const globalOperation = categories.find(category => (category.id === 'idxGlobalEfficacy' || category.id === 'idxGlobalAverage'));
    return globalOperation?.evaluation !== 0;
  });
}

function averageCategories(listCategories){
  if(!listCategories || listCategories.length === 0) return [];

  listCategories = listCategories.filter(categories => categories.length);
  listCategories = removeCategoriesNA(listCategories);

  if(listCategories.length === 0) return [];

  const reducedCategories = listCategories.reduce(
    (acumm, current) => {
      acumm.map((category, index) => {
        const toAddCategory = current.find(currentCat => currentCat.id === category.id);
        if(toAddCategory) {
          if (toAddCategory.evaluation !== null) {
            acumm[index].evaluation += toAddCategory.evaluation;
          }
        }
      });

      return acumm;
    }
  );


  return reducedCategories.map(category => {
    return {
    ...category,
    evaluation: category.evaluation / listCategories.length
  }});
}

export function reducedGroupedVisitsAnswers(groupsIndex, items){
  if(!groupsIndex || groupsIndex.length === 0
      || !items || items.length === 0)
    return [];
  return groupsIndex.map(group => {
    const groupKeyfield = items[group[0]].keyfield;
    const groupCategories = averageCategories(group.map(idx => items[idx].categories));
    return {
      keyfield: groupKeyfield,
      categories: groupCategories ? groupCategories : []
    };
  });
}

export function getGeofences(geofenceGroups){
  if(!geofenceGroups || geofenceGroups.length === 0)
    return [];
  let geofences = [];
  geofenceGroups.forEach(geofenceGroup => {
    const name = geofenceGroup.name;
    let groupGeofences = geofenceGroup.geofences.map(geofence => `${name} / ${geofence.name}`);
    Array.prototype.push.apply(geofences, groupGeofences);
  });
  return geofences;
}

export function getAcumGroupByKeyfield(visitAnswers, keyfieldSelect, currentOperation){
  const operation = GLOBAL_CATEGORIES[currentOperation.id];
  const visitAnswerFilter = visitAnswers.filter(visitAnswer => visitAnswer.keyfields.some(keyfield => keyfield.id === keyfieldSelect.value));
  const keyfieldAnswers = visitAnswerFilter.map(visitAnswer => visitAnswer.keyfields.find(keyfield => keyfield.id === keyfieldSelect.value).value);
  const uniqueAnswers = Array.from(new Set(keyfieldAnswers))
  let keyfieldCount = uniqueAnswers.map(answer => {return { name: answer, total: 0 }})

  keyfieldCount.map((keyfieldAnswer, index) => {
    const visitAnswers = visitAnswerFilter.filter(visitAnswer => visitAnswer.keyfields.some(keyfield => keyfield.value === keyfieldAnswer.name));
    const globalOperation = visitAnswers.map(visitAnswer => {
      const operationValue = visitAnswer.categories.find(category => category.id === operation.id);
      const evaluation = operationValue?.evaluation;

      if (evaluation) {
        return evaluation;
      }
    });

    const result = globalOperation.reduce((total, num) => total + num, 0);

    keyfieldCount[index].total = result/globalOperation.length;
  });

  return keyfieldCount;
}

export function getFrequencyAnswers(answers, ranges) {
  const frequency = ranges.map(range => {return { label: range, total:0 }});

  frequency.map((range, index) =>{
    const arrayRange = range.label.split('-');
    const groupAnswers = answers.filter(answer => answer.total >= parseFloat(arrayRange[0]) && answer.total <= parseFloat(arrayRange[1]));

    frequency[index].total = groupAnswers.length;
    frequency[index].answers = groupAnswers;
  });

  return frequency;
}

export function filterVisitAnswerByUsersSegment(visitAnswers, usersSegment){
  if(!usersSegment.length) return visitAnswers;

  const visitAnswersFilter = visitAnswers.filter(visitAnswer => usersSegment.some(userSegment => userSegment.user._id === visitAnswer.userRef._id));

  return visitAnswersFilter;
}

export function filterResultNA(answers) {
  const validAnswers = answers.filter(answer => answer.total);
  const notValidAnswer = answers
    .filter((answer) => !answer.total)
    .map((answer) => {
      if (isNaN(answer.total)) {
        answer.total = 'N/A';
      }

      return answer;
    });

  return [validAnswers, notValidAnswer];
}

export function reduceVisitanswerKeyField(visitAnswers, keyfield){
  if(!visitAnswers || !keyfield) return visitAnswers;

  return visitAnswers.map(
    (visitAnswer, i) => {
      return {
        ...visitAnswer,
        keyfield: visitAnswer.keyfields.find(visitKey => visitKey.id === keyfield.value)
      }
  });
}

export function getKeyfieldOptions(groupsIndex, items){
  if(!groupsIndex || groupsIndex.length === 0
      || !items || items.length === 0)
    return [];
  return groupsIndex.map(group => {
    const groupKeyfield = items[group[0]].keyfield;
    return {
      keyfield: groupKeyfield
    };
  });
}

export function reduceVisitAnswerKeyfieldValues(visitAnswers, keyfieldValues){
  if(!visitAnswers || !keyfieldValues ||  !keyfieldValues.length) return visitAnswers;

  const reduceVisitAnswers = visitAnswers.filter(visitAnswer => keyfieldValues.some(keyFieldValue => keyFieldValue.value === visitAnswer.keyfield.value));

  return reduceVisitAnswers;
}

export function getFrequencyColumns(startDate, endDate, frequency, activities) {
  startDate = Moment(startDate);
  endDate = Moment(endDate);
  let columnsCount;
  let columns = [];
  switch (frequency.value) {
    case 1:
      //console.log(endDate.diff(startDate, 'days'));
      columnsCount = endDate.diff(startDate, 'days');

      columnsCount = !columnsCount ? 1 : columnsCount;

      columns = getColumnsDates(startDate, endDate, columnsCount, 1);
      break;
    case 2:
      //console.log(endDate.diff(startDate, 'weeks'));
      columnsCount = endDate.diff(startDate, 'weeks', true).toFixed(2);

      columnsCount = !columnsCount ? 1 : Math.round(columnsCount);

      columns = getColumnsDates(startDate, endDate, columnsCount, 7);
      break;
    case 3:
      //console.log(endDate.diff(startDate, 'weeks'));
      columnsCount = endDate.diff(startDate, 'weeks', true).toFixed(2) / 2;
      columnsCount = !columnsCount ? 1 : Math.ceil(columnsCount);

      columns = getColumnsDates(startDate, endDate, columnsCount, 14);
      break;
    case 4:
      //console.log(endDate.diff(startDate, 'months'));
      columnsCount = endDate.diff(startDate, 'months', true).toFixed(2);

      columnsCount = !columnsCount ? 1 : Math.ceil(columnsCount);

      columns = getColumnsDates(startDate, endDate, columnsCount, 30);
      break;
    case 5:
      columnsCount = endDate.diff(startDate, 'months', true).toFixed(2) / 3;

      columnsCount = !columnsCount ? 1 : Math.ceil(columnsCount);

      columns = getColumnsDates(startDate, endDate, columnsCount, 90);
      break;
  }

  const columnsDates = columns.map(column => ({
    ...column,
    column: column.end
  }));

  let data = [];
  activities.map(activity => {
    let row = {
      _id: activity.value,
      name: activity.label
    }

    columns.map(column => {
      row[column.end] = 0;
    });

    data.push(row);
  });

  return { columnsDates, data };
}

export function getColumnsDates(startDate, endDate, columnsCount, rangeDays) {
  let datesColumns = [];

  endDate = new Date(Moment(endDate).format());
  startDate = new Date(Moment(startDate).format());

  for (let i = 1; i <= columnsCount; i++) {
    let endDateCurrent;
    let startDateCurrent;
    if(i === 1) {
      startDateCurrent = Moment(startDate).format('YYYY-MM-DD');
      endDateCurrent = Moment(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + rangeDays)).format('YYYY-MM-DD')
    }else{
      startDateCurrent = Moment(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1 + (rangeDays * ( i - 1)))).format('YYYY-MM-DD')
      endDateCurrent = Moment(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + ((rangeDays * (i))))).format('YYYY-MM-DD');
    }

    if (i === columnsCount) {
      endDateCurrent = Moment(endDate).format('YYYY-MM-DD');
    }

    datesColumns.push({
      start: startDateCurrent,
      end: endDateCurrent,
    });
  }

  return datesColumns;
}

export function getAcumCountActivity(visitAnswers, data, columns) {

  visitAnswers.map(visitAnswer => {

    data.map((row, index) => {
      const answersCount = visitAnswer.modules.filter(module => module.moduleId && module.moduleId === row._id);
      row.answers += answersCount.length;

      columns.map(column => {
        const start = Moment(column.start).startOf('day').format();
        const end = Moment(column.end).endOf('day').format();

        if (Moment(visitAnswer.checkInDate).isBetween(start, end)) {
          row[column.column] += answersCount.length;
        }
      });

      return row;
    });
  });

  return data;
}
