import React from 'react';
import PropTypes from 'prop-types';
import TagsInput from "react-tagsinput";
import {Field} from "formik";

// Core components
import Danger from "components/Typography/Danger.jsx";

// Styled Components
import DivMarginCenter from "../../components/styled-components/DivMarginCenter";

// Custom components
import SuggestionSearch from "../../components/styled/SuggestionSearch";

/*
* Custom field Autosuggest single
* Requires a list of suggestions for searching and automatically setting field value according to
* */
const FieldAutosuggestSingle = ({
                                  name, hidden,
                                  suggestionList, getSuggestionName,
                                  getSuggestionId, placeholder,
                                  error
                                }) => {

  // local utils
  const findSelectedSuggestion = id => suggestionList ? suggestionList.find((segment) => {
    return segment._id === id;
  }) : [];

  const getTagsForFieldValue = (field) => {
    return !!field.value ? [getSuggestionName(findSelectedSuggestion(field.value))] : [];
  };

  return (
    <Field name={name}>
      {({field, form}) => {
        if (!hidden) {
          return (
            <DivMarginCenter>
              {
                field.value ?
                  <TagsInput
                    value={getTagsForFieldValue(field)}
                    onChange={(values) => {
                      // Clear field value when tags deleted
                      if (!values.length) {
                        form.setFieldValue(name, '');
                      }
                    }}
                    tagProps={{className: "react-tagsinput-tag info"}}
                    inputProps={{
                      type: 'hidden'
                    }}
                  /> : []
              }
              {/* ERROR */}
              {
                !error ? [] : <Danger>{error}</Danger>
              }
              {!field.value ?
                <SuggestionSearch
                  suggestionList={suggestionList}
                  selected={(id) => {
                    form.setFieldValue(name, id);
                  }}
                  placeholder={placeholder}
                  getSuggestionName={getSuggestionName}
                  getSuggestionId={getSuggestionId}
                /> : []
              }
            </DivMarginCenter>
          );
        }else {
          return [];
        }
      }}
    </Field>
  );
};

FieldAutosuggestSingle.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  hidden: PropTypes.bool,
  suggestionList: PropTypes.array.isRequired,
  getSuggestionName: PropTypes.func.isRequired,
  getSuggestionId: PropTypes.func.isRequired
};

export default FieldAutosuggestSingle;
