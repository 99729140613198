export const hasError = (errors, fieldName) => {

  if (errors && Object.getOwnPropertyNames(errors).length >= 1) {
    return errors.hasOwnProperty(fieldName);
  }
  return false;
};

export const validateEmail = (email) => {
  //eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getErrorLabel = (t, suffix, fieldName, errors) => {
  return t(`${suffix}.${fieldName}.error.${errors[fieldName]}`);
};
