import React, { useState, useEffect, useMemo } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Divider,
  Select,
  MenuItem,
  TimeField,
  Grid,
  FormControl,
  Switch
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';
import ReactTable from 'react-table';
import CloseIcon from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { useTranslation } from "react-i18next";
import { Form, withFormik } from 'formik';
import * as yup from 'yup';
import Datetime from "react-datetime";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import CheckIcon from '@material-ui/icons/Check';
import ButtonIcon from 'components/styled/ButtonIcon';
import DivCenter from "../components/styled-components/DivCenter";
import { TICKETS_CONFIG_DELAY_ALERT } from "../containers/Tickets/Queries";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import Button from "../components/Button";
import { CREATE_UPDATE_TICKET_DELAY_ALERT_CONFIG } from "../containers/Tickets/Mutations";
import Swal from 'sweetalert2'
import Delete from '@material-ui/icons/Delete';
import Tooltip from "@material-ui/core/Tooltip";

import InputMask from 'react-input-mask';

const EmailAutocomplete = ({ valueCorreos, changeMail }) => {
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);

  const handleInputChange = (event) => {
    if(event){
      const value = event.target.value.trim();
      setInputValue(value);
      setError(!isValidEmail(value));
    }else{
      setInputValue("");
    }
    
  };

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      const trimmedValue = inputValue.trim();
      if (trimmedValue && isValidEmail(trimmedValue)) {
        setEmails([...emails, trimmedValue]);
        setInputValue('');
        setError(false);
      }
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div>
      <Autocomplete
        multiple
        freeSolo
        options={emails}
        value={valueCorreos}
        onChange={(event, value) => changeMail(value)}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Correos electrónicos"
            variant="outlined"
            error={error}
            helperText={error ? 'Dirección de correo electrónico no válida' : ''}
            onKeyPress={handleInputKeyPress}
            onKeyDown={(event) => {
              if (event.key === 'Backspace' && !inputValue) {
                const newEmails = emails.slice(0, emails.length - 1);
                changeMail(newEmails);
              }
            }}
          />
        )}
      />
    </div>
  );
};


function convertMinutesToDaysHoursMinutes(minutes) {
  const minutesPerDay = 24 * 60;
  const minutesPerHour = 60;

  const days = Math.floor(minutes / minutesPerDay);
  const remainingMinutes = minutes % minutesPerDay;
  const hours = Math.floor(remainingMinutes / minutesPerHour);
  const remainingMinutesInHour = remainingMinutes % minutesPerHour;

  const formattedTime = `${hours.toString().padStart(2, '0')}:${remainingMinutesInHour.toString().padStart(2, '0')}`;

  return {
    days: days,
    horas: formattedTime
  };
}

const TimeLimitInput = ({ valueTimes, changeFunct, dataconverted }) => {
  const [timeValue, setTimeValue] = React.useState("00:00");
  const [daysValue, setSelectValue] = React.useState(0);    


  const handleTextChange = (event) => {
    const value = event.target.value; 
    setTimeValue(value);    
  };
  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
  };

  useEffect(() => {
    let dataconverted = convertMinutesToDaysHoursMinutes(valueTimes)    
    setTimeValue(dataconverted.horas)
    setSelectValue(dataconverted.days)
    

  },[])


  useEffect(() => {
    try {      
      const [hours, minutes] = timeValue.split(':');
      console.log("hours, minutes", hours, minutes)
      let valueMinuteCalcule = parseInt(daysValue, 10) * 24 * 60 + parseInt(hours, 10) * 60 + parseInt(minutes, 10)
      if (isNaN(valueMinuteCalcule)) {
        valueMinuteCalcule = null
      }      
      changeFunct(valueMinuteCalcule)
    } catch (_) {
      console.log("-")
    }
  }, [timeValue, daysValue])

  console.log("timeValue", timeValue)

  return (
    <Grid container spacing={1} alignItems="flex-end">

      <Grid item xs={4}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">Días</InputLabel>
          <Select
            value={daysValue}
            onChange={handleSelectChange}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Días"
            fullWidth
          >
            {[...Array(31)].map((x, i) =>
              <MenuItem key={"menudays-" + i.toString()} value={i}>{i}</MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <InputMask mask="99:99" maskChar="" fullWidth  value={timeValue}          
            onChange={handleTextChange}            >
          {(inputProps) => <TextField 
          
            {...inputProps} 
            // type="tel"
            
            variant="outlined"
            label="Horas"
      
            
          />}
        </InputMask>
      
      </Grid>
    </Grid>
  );
};


const AlertDelayModal = (props) => {

  console.log("props", props)
  
  const { t } = useTranslation("checklistAnalisys");
  const classes = withStyles(styles);
  const { modalStatus, setModalStatus } = props;
  const [listItemsAlert, setListItemsAlert] = useState([])
  const handleClose = () => {
    setModalStatus({
      open: false,
      reportConfig: {}
    });
    setFormData({
      "app": "",
      "ticket": "",
      "startStatus": "Assigned",
      "endStatus": "Resolved",
      "config": [],
      "sendMailOnfinished": false
    })
  };
  const statusOptionsTableInit = {
    'Created': 'Creada',
    'Assigned': 'Asignada',
    'Resolved': 'Resuelta'
  }
  const statusOptionsTableEnd = {
    'Assigned': 'Asignada',
    'Resolved': 'Resuelta',
    'Accepted': 'Aceptada'
  }
  const addNewItemToList = (v) => {
    if(formData["config"].length < 5){
      updateFormResulte("addconfig")      
    }else{
      
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Limite máximo de alertas alcanzado.',
        didOpen: () => {                        
          document.querySelector('.swal2-container').style.zIndex = '9999';
        },
      })
    }
  }  
  const [formData, setFormData] = useState({
    "app": "",
    "ticket": "",
    "startStatus": "Assigned",
    "endStatus": "Resolved",
    "config": [],
    "sendMailOnfinished": false
  })
  const {
    data: dataTickets,
    error: errorTickets,
    loading: loadingTickets,
    refetch: refetchTickets
  } = useQuery(TICKETS_CONFIG_DELAY_ALERT, {    
    variables: {
      "where":{
        "app": modalStatus.app,
	      "ticket": modalStatus.ticket
      }
    },
    fetchPolicy: 'network-only'
  });

  const [indexError, setIndexError] = useState(-1)

  // CREATE_UPDATE_TICKET_DELAY_ALERT_CONFIG
  const [saveChanges, {loading: loadingChange}] = useMutation(CREATE_UPDATE_TICKET_DELAY_ALERT_CONFIG);

  useEffect(() => {      
    if(modalStatus?.open){
          
      refetchTickets()
    }else{
      // alert("")
      setFormData({
        "app": "",
        "ticket": "",
        "startStatus": "Assigned",
        "endStatus": "Resolved",
        "config": [],
        "sendMailOnfinished": false
      })
    }
  }, [modalStatus])

  useEffect(() => { 
    if(!modalStatus?.open){
      setFormData({
        "app": "",
        "ticket": "",
        "startStatus": "Assigned",
        "endStatus": "Resolved",
        "config": [],
        "sendMailOnfinished": false
      })
    }else{
      if(!loadingTickets && dataTickets && dataTickets.ticketsdelayalertconfig){          
        console.log("dataTickets.ticketsdelayalertconfig.config", dataTickets.ticketsdelayalertconfig.config)  
        
        setFormData({
          "app": dataTickets.ticketsdelayalertconfig.app,
          "ticket": dataTickets.ticketsdelayalertconfig.ticket,
          "startStatus": dataTickets.ticketsdelayalertconfig.startStatus,
          "endStatus": dataTickets.ticketsdelayalertconfig.endStatus,
          "config": dataTickets.ticketsdelayalertconfig.config,
          "sendMailOnfinished": dataTickets.ticketsdelayalertconfig.sendMailOnfinished
        }) 
           
        
        
      }else{
        if(dataTickets && !dataTickets.ticketsdelayalertconfig){
          
          setFormData({
            "app": "",
            "ticket": "",
            "startStatus": "Assigned",
            "endStatus": "Resolved",
            "config": [],
            "sendMailOnfinished": false
          })
         
        }
      }
    }
    
  },[dataTickets, modalStatus?.open])  

  useEffect(() => {
    
  },[formData])

  function validarSecuencia(arr) {
    const removeInZero = [];
    for (let i = 0; i <= arr.length - 1; i++) {
      const current = arr[i].timeDelay;
      const next = arr[i + 1] ? arr[i + 1].timeDelay : false;
      const mailsinArr = arr[i].mails;      
      if (current === 0) {        
        removeInZero.push(i);
      }            
  
      // if (current === 0) {
      //   continue; // Ignorar valores en 0
      // }
      
      if ((next !== false) && next - current < 10) {
        return { validation: false, error: i + 1, removeInZero };
      }
      if(mailsinArr.length == 0){        
        return { validation: false, error: i, removeInZero };
      }
    }
    return { validation: true, error: false, removeInZero };
  }

  const updateFormResulte = (key, value = null, index=0, config=null) => {    
    if(key == "config"){
      let formDataConfig = formData["config"]                  
      if(formDataConfig[index][config] != value){        
        formDataConfig[index][config] = value
        setFormData(formData => ({
          ...formData,
          ...{ "config": formDataConfig  }
        }))        
      }      
      
    }
    else if(key == "addconfig"){
      setFormData((prevState) => ({
        ...prevState,
        config: [...prevState.config, {
          timeDelay: 0,
          addUserAsigned: false,
          mails: [],
          step: 0
        } ],
      }));            
  }
   else{
      let formUpt = {}
      formUpt[key] = value
      setFormData(formData => ({
        ...formData,
        ...formUpt
      }))
    }
    
  }

  const transitionTable = {
    'Created': ['Assigned', 'Resolved', 'Accepted'],
    'Assigned': ['Resolved', 'Accepted'],
    'Resolved': ['Accepted']
  };
  
  function isValidTransition(currentStatus, newStatus) {
    const validTransitions = transitionTable[currentStatus];
    
    if (!validTransitions) {
      return {
        validation: false,
        razon: `Estado actual inválido: ${statusOptionsTableInit[currentStatus]}`
      };
    }
  
    if (validTransitions.includes(newStatus)) {
      return {
        validation: true,
        razon: 'La transición de estado es válida'
      };
    } else {
      return {
        validation: false,
        razon: `El estatus no puede ser ${statusOptionsTableEnd[newStatus]}, porque el estatus inicial es ${statusOptionsTableInit[currentStatus]}`
      };
    }
  }
  
  
  const removeItemFromConfig = (index) => {
    // Primero, crea una copia del objeto formData y de la propiedad config
    const newFormData = { ...formData };
    const newConfig = [...formData.config];
  
    // Luego, elimina el elemento en el índice especificado
    newConfig.splice(index, 1);
  
    // Actualiza la propiedad config en el nuevo objeto formData
    newFormData.config = newConfig;
  
    // Finalmente, establece el nuevo objeto formData como el nuevo estado
    setFormData(newFormData);
  };
  

  const sendChangesToDb = () => {
    let saveChangesText = "¿Deseas guardar los cambios?"
    let saveDB = true
    let configFormater = formData.config
    console.log("formDataformData", formData)
    if(!formData.config || formData.config.length == 0) { saveDB = false }
    else{
      let validationResult = validarSecuencia(configFormater)  
      saveDB = validationResult.validation    
      if(formData.config[0].timeDelay < 10 || formData.config[0].mails < 10){              
        Swal.fire({
          icon: 'error',
          title: '¡Ups!',
          text: 'Los intervalos de tiempo entre las notificaciones no pueden ser inferiores a diez minutos, y cada una debe incluir al menos un correo electrónico.',
          didOpen: () => {                        
            document.querySelector('.swal2-container').style.zIndex = '9999';
          },
        })                
        setIndexError(0)        
        saveDB = false
      }
      else{        
        if(!validationResult.validation){
          setIndexError(validationResult.error)
          //alert("Los valores para alerta, debe tener entre ellos, un tiempo minimo de 10 minutos.")
          Swal.fire({
            icon: 'error',
            title: '¡Ups!',
            text: 'Los intervalos de tiempo entre las notificaciones no pueden ser inferiores a diez minutos, y cada una debe incluir al menos un correo electrónico.',
            didOpen: () => {                        
              document.querySelector('.swal2-container').style.zIndex = '9999';
            },
          })
        }else{
          setIndexError(-1)
        }
      }                      
      
      if(validationResult.removeInZero.length > 0){
        let updatedArray = configFormater.filter((_, index) => !validationResult.removeInZero.includes(index));
        saveChangesText = "¿Deseas guardar los cambios, aunque los valores que contienen 0 minuto seran eliminado de la lista?"
      }      
    }
    
    const statusValidate = isValidTransition(formData.startStatus, formData.endStatus)
    if(!statusValidate.validation){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: statusValidate.razon,
        didOpen: () => {                        
          document.querySelector('.swal2-container').style.zIndex = '9999';
        },
      })                      
      saveDB = false
    }

        
    if(saveDB){
      

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-raised MuiButton-raisedPrimary mr-2',          
          cancelButton: 'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSuccess MuiButton-raised MuiButton-raisedSuccess'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: '¡Atención!',
        text: saveChangesText,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        reverseButtons: true,
        didOpen: () => {                        
          document.querySelector('.swal2-container').style.zIndex = '9999';
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: 'success',
            title: 'Muy bien',
            text: '',
            didOpen: () => {                        
              document.querySelector('.swal2-container').style.zIndex = '9999';
            },
          })
          saveChanges({
            variables: {
              "where": {
                "app": modalStatus.app,
                "ticket": modalStatus.ticket
              },
              "data": {
                "app": modalStatus.app,
                "ticket": modalStatus.ticket,
                "startStatus": formData.startStatus,
                "endStatus": formData.endStatus,
                "config": formData.config,
                "sendMailOnfinished": formData.sendMailOnfinished
              }
            }
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          
        }
      })      
      
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalStatus?.open}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <DialogTitleComponent id="customized-dialog-title" onClose={handleClose}>
        {t("checklistAnalisys.delayAlert.title")}
      </DialogTitleComponent>
      <DialogContent dividers>
        <div className={classes.root}>
          <GridContainer justify="center">

            <GridItem xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="init-status-label">Estatus Inicial</InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  labelId="init-status-label"
                  label="Estatus Inicial"
                  value={formData.startStatus}
                  onChange={(event) => { updateFormResulte("startStatus", event.target.value) }}
                  inputProps={{
                    name: 'value',
                    id: 'value-simple',
                  }}
                >
                  {Object.keys(statusOptionsTableInit).map(key =>
                    <MenuItem value={key}>{statusOptionsTableInit[key]}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="end-status-label">Estatus Final</InputLabel>
                  <Select
                    label="Estatus Final"
                    variant="outlined"
                    labelId="end-status-label"
                    fullWidth
                    onChange={(event) => { updateFormResulte("endStatus", event.target.value) }}
                    value={formData.endStatus}
                    inputProps={{
                      name: 'value',
                      id: 'value-simple',
                    }}
                  >
                    {Object.keys(statusOptionsTableEnd).map(key =>
                      <MenuItem value={key}>{statusOptionsTableEnd[key]}</MenuItem>
                    )}
                  </Select>
                </FormControl>
            </GridItem>

            
          </GridContainer>

          <GridContainer justify="center">            
            <GridItem xs={12}>
              <hr />
              Enviar correo de confirmación de resolución a usuarios notificados.
              <br />
              <Switch
                color="primary"
                checked={formData.sendMailOnfinished}
                onChange={(event) => { updateFormResulte("sendMailOnfinished", event.target.checked) }}                
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  icon: classes.switchIcon,
                  iconChecked: classes.switchIconChecked,
                  bar: classes.switchBar
                }}
              />
              <hr style={{ marginTop: "16px"}} />                          
            </GridItem>
            
          </GridContainer>

          <GridContainer justify="center">
            <GridItem xs={10}>
              <h4>
                Notificaciones programadas
              </h4>
            </GridItem>
            <GridItem xs={2}>              
              <IconButton
                aria-label="close"
                style={{ float: "right", color: "green"}}
                onClick={addNewItemToList}
              >
                +
              </IconButton>
            </GridItem>
            <GridItem xs={12}>
              {formData.config.map((e, index) => {                
                return (
                <GridContainer justify="center">
                  <GridItem xs={12}></GridItem>
                  <GridItem xs={4} style={{ backgroundColor: indexError ==  index && "#ffcaca"}}>
                    <TimeLimitInput valueTimes={e.timeDelay}  changeFunct={(value) => {
                        updateFormResulte("config", value, index, "timeDelay")
                      }} />
                  </GridItem>
                  <GridItem xs={1} style={{ backgroundColor: indexError ==  index && "#ffcaca"}}>
                    <Tooltip
                      id="tooltip-top"
                      title="Enviar correo a usuario asignado"
                      placement="top"                      
                    >
                     <Typography style={{ marginTop:"-14px"}}> <font style={{color:"#7c7676", fontSize:"11px"}}>Asignado <font style={{color:"red"}}>*</font></font> </Typography> 
                    </Tooltip>
                    <Switch                        
                        checked={e.addUserAsigned}
                        onChange={(event) => { 
                          updateFormResulte("config", event.target.checked, index, "addUserAsigned") }
                        }  
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar
                        }}
                      />
                  </GridItem>
                  <GridItem xs={6} style={{ backgroundColor: indexError ==  index && "#ffcaca"}}>
                    <Tooltip
                      id="tooltip-top"
                      title="Recuerda presionar enter despues de escribir el correo"
                      placement="top"                      
                    >
                      <div>
                      <EmailAutocomplete valueCorreos={e.mails} changeMail={(value) => {
                          updateFormResulte("config", value, index, "mails")
                        }} />
                        </div>
                    </Tooltip>
                    
                  </GridItem>
                  <GridItem xs={1} style={{ backgroundColor: indexError ==  index && "#ffcaca"}}>
                    <Tooltip
                      id="tooltip-top"
                      title="Eliminar"
                      placement="top"                      
                    >
                      <IconButton
                        aria-label="Eliminar"    
                        onClick={() => removeItemFromConfig(index)}                    
                      >
                        <Delete style={{ color: "#f56a6a"}} />
                      </IconButton>
                    </Tooltip>
                    
                  </GridItem>
                  <GridItem xs={12}>
                    <hr />
                  </GridItem>
                </GridContainer>)
              })}             
            </GridItem>
            <GridItem xs={12}>
                <Button
                  size="sm"
                  color={"success"}                  
                  fullWidth
                  onClick={()=> sendChangesToDb()}
                >
                  Guardar Cambios
                </Button>
            </GridItem>
          </GridContainer>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const DialogTitleComponent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle disableTypography {...other}>
      <GridContainer>
        <GridItem xs={10}>
          <Typography variant="h6">{children}</Typography>
        </GridItem>
        <GridItem xs={2} style={{ textAlign: "end" }}>
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </GridItem>
      </GridContainer>
    </DialogTitle>
  );
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "rgba(0, 0, 0, 0.26)",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex"
  },
  marginTop: {
    marginTop: "50px !important"
  }
});


export default AlertDelayModal;
