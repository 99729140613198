import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import { groupByKeyfields } from 'utils/analytics/dataManipulation';
import DetailsTable from 'components/AdminData/DetailsTable';
import ListButton from 'components/AdminData/ListButton';
import PieChart from 'components/Chart/PieChart';
import { getPieChartData } from '../../../utils/analytics/dataManipulation';
import { getDefaultSubject, getDefaultBody } from '../../../utils/emails/defaultContent';
import { REPORT_ACTIONS } from '../../../constants';

function GeneralExecutionView({  
  classes,
  geofences,
  keyfields,
  loading,
  massiveList,
  setMassiveListID,
  visitAnswers,
  createGeneralExecutionReport,
  sendGeneralExecutionPDFReport,
  emailData,
  createGeneralExecutionXLSReport,
  sendGeneralExecutionXLSReport
}) {
  const [keyfieldOption, setkeyfieldOption] = React.useState({ ...keyfields[0], value: keyfields[0].id, label:keyfields[0].name});
  const [executionCount, setExcecutionCount] = React.useState(1);

  const handleKeyfieldChange = React.useCallback((value) => {
    setkeyfieldOption(value);
    if(value.type === "MassiveListUniqueField" || value.type === "MassiveListMultipleField") 
      setMassiveListID(value.massiveList);
  }, [setkeyfieldOption]);

  const handleExecutionCountChange = React.useCallback((e) => {
    setExcecutionCount(e.target.value);
  }, [setExcecutionCount]);

  const selectOptions = keyfields.map(keyfield => ({
    ...keyfield,
    value: keyfield.id,
    label: keyfield.name
  }));

  function groupByRepetitions(groups, repetitions){
    let under = [];
    let over = [];
    groups.forEach(group => {
      if(group.values.length < repetitions){
        under.push(group.name);
      } else {
        over.push(group.name);
      }
    });
    return [under, over];
  };
  
  const selectedKeyfield = keyfields.find((keyfield) => keyfield.id === keyfieldOption.value);
  const completeList = selectedKeyfield && !loading ? getList(selectedKeyfield, massiveList, geofences) : [];
  const groupByKeyfieldValue = selectedKeyfield && !loading ? groupByKeyfieldValues(visitAnswers, selectedKeyfield, completeList) : [];
  
  const [notExecutedList, executedList] = groupByKeyfieldValue ? groupByRepetitions(groupByKeyfieldValue, executionCount) : [[], []];

  const data = [
    {
      label: "Ejecutadas",
      count: executedList.length,
      color: "#F27C34"
    }, {
      label: "No ejecutadas",
      count: notExecutedList.length,
      color: "#4572C7"
    }
  ];

  const chartData = getPieChartData(data, "Ejecución General");

  const wrapPDFData = () => {
    return {
      keyfield: {
        name: keyfieldOption.name,
        value: keyfieldOption.value,
      },
      items: {
        executed: executedList,
        notExecuted: notExecutedList,
        cantidad: executionCount,
        data: data
      },
    };
  };

  const wrapXLSData = () => {
    return {
      keyfield: {
        name: keyfieldOption.name,
        value: keyfieldOption.value,
      },
      items: {
        executed: executedList,
        notExecuted: notExecutedList,
        cantidad: executionCount,
        data: data
      },
    };
  };

  const handlePDFReportClick = React.useCallback(() => {
    const payload = wrapPDFData();
    createGeneralExecutionReport(payload);
  }, [createGeneralExecutionReport, keyfieldOption, executedList, groupByKeyfieldValue, executionCount]);

  const handleXLSReportClick = React.useCallback(() => {
    const payload = wrapXLSData();
    createGeneralExecutionXLSReport(payload);
  }, [ createGeneralExecutionXLSReport, keyfieldOption, executedList, groupByKeyfieldValue, executionCount ]);

  const handlePDFReportEmailClick = React.useCallback((emailData) => {
    const payload = wrapPDFData();
    sendGeneralExecutionPDFReport(payload, emailData);
  }, [ sendGeneralExecutionPDFReport, keyfieldOption, executedList, groupByKeyfieldValue, executionCount ]);

  const handleXLSReportEmailClick = React.useCallback((emailData) => {
    const payload = wrapXLSData();
    sendGeneralExecutionXLSReport(payload, emailData);
  }, [ sendGeneralExecutionXLSReport, keyfieldOption, executedList, groupByKeyfieldValue, executionCount ]);

  const defaultSubject = {
    PDF: getDefaultSubject( REPORT_ACTIONS.generalExecutionPDF,
      { visit: emailData.visit, keyfield: keyfieldOption.label,
        startDate: emailData.startDate, endDate: emailData.endDate } ),
    XLS: getDefaultSubject(
      REPORT_ACTIONS.generalExecutionXLS,
      { visit: emailData.visit, keyfield: keyfieldOption.label,
        startDate: emailData.startDate, endDate: emailData.endDate })
  };

  const defaultBody = {
    PDF: getDefaultBody( REPORT_ACTIONS.generalExecutionPDF, 
      { visit: emailData.visit, keyfield: keyfieldOption.label,
        startDate: emailData.startDate, endDate: emailData.endDate, executionCount: executionCount} ),
    XLS: getDefaultBody(REPORT_ACTIONS.generalExecutionXLS,
      { 
        visit: emailData.visit, keyfield: keyfieldOption.label,
        startDate: emailData.startDate, endDate: emailData.endDate, executionCount: executionCount
      })
  };

  return (
    <Card>
      <CardHeader color="info" text>
        <GridContainer>
          <GridItem xs={6}>
            <CardText color="info">
              <h4 className={classes.cardTitle}>Ejecución General</h4>
            </CardText>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody className={classes.generalExecutionCard}>
        <GridContainer>
          <GridItem xs={6}>
            <div>
              <h4>Selecciona un campo clave para la ejecución General</h4>
            </div>
            <Select
              className={classes.selector}
              placeholder={"Selecciona un campo clave para la ejecución General"}
              value={keyfieldOption}
              onChange={handleKeyfieldChange}
              options={selectOptions}
            />
            {selectedKeyfield.isAnalyzable ?
              <>
                <CustomInput
                  labelText="Cantidad que define una ejecución"
                  id="executionCount"
                  type={"number"}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleExecutionCountChange,
                    autoComplete: "false",
                    defaultValue: 1
                  }}
                />
                <ListButton
                  classes={classes}
                  label="Ejecutadas" 
                  items={executedList}
                  enableButtons={{
                    email: true,
                    download: true,
                    preview: false,
                    xls: true
                  }}
                  defaultBody={defaultBody}
                  defaultSubject={defaultSubject}
                  handlePDFReportEmailClick={handlePDFReportEmailClick}
                  handleXLSReportEmailClick={handleXLSReportEmailClick}
                  handlePDFReportClick={handlePDFReportClick}
                  handleXLSReportClick={handleXLSReportClick}
                />
                <ListButton
                  classes={classes}
                  label="Lista No Ejecutadas" 
                  items={notExecutedList}
                  enableButtons={{
                    email: false,
                    download: false,
                    preview: false
                  }} 
                />
              </>
            :
              <h4>Análisis no disponible para este campo clave</h4> 
            }
          </GridItem>
          <GridItem xs={6}>
            {selectedKeyfield.isAnalyzable ?
              <>
                <div className={classes.container}>
                  <div className={classes.chart}>
                    <PieChart
                      id="generalExecutionChart"
                      data={chartData}
                    />
                  </div>
                </div>
                <DetailsTable
                  data={data} 
                />
              </>
            : 
              <h4>Análisis no disponible para este campo clave</h4> 
            }
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

function getList(keyfield, massiveList, geofences){
  if(keyfield.type === "ListUniqueField" || keyfield.type === "ListMultipleField"){
    return keyfield.optionsList.map(({value}) => value);
  } else if(keyfield.type === "MassiveListUniqueField" || keyfield.type === "MassiveListMultipleField"){
    return massiveList.map(({value}) => value);
  } else if (keyfield.type === "GeofenceField"){
    return geofences;
  }
  return [];
}

function groupByKeyfieldValues(visitAnswers, keyfield, list){
  let bucket = []; 

  list.forEach(value => {
    bucket[value] = {
      id: keyfield.id,
      name: value,
      values: []
    };
  })

  visitAnswers.forEach((visitAnswer, idx) => {
    let visitKey = visitAnswer.keyfields.find(({id}) => id === keyfield.id);
    // The geofences are in the format "group/name, group/name" 
    // it needs parsing before grouping
    if(keyfield.type === "GeofenceField") {      
      if(Array.isArray(visitKey.value)){
        visitKey.value.forEach(value => {
          if(bucket[value])
            bucket[value].values.push(idx);  
        });
      }else{
        visitKey.value = visitKey.value.split(',').map(val => val.trim());
      }
    }

    if(Array.isArray(visitKey.value)){
      visitKey.value.forEach(value => {
        if(bucket[value])
          bucket[value].values.push(idx);  
      });
    }
    else {
      if(bucket[visitKey.value])
        bucket[visitKey.value].values.push(idx);
    }
  });
  return Object.values(bucket);
}

GeneralExecutionView.propTypes = {
  geofences: PropTypes.array.isRequired,
  keyfields: PropTypes.array.isRequired,
  massiveList: PropTypes.array,
  visitAnswers: PropTypes.array.isRequired,
};

const styles = theme => ({
  container:{
    display: "flex",
    justifyContent: "center"
  },
  chart: {
    width: "400px",
  },
  generalExecutionCard:{
    zIndex: 14
  },
});

export default withStyles(styles)(GeneralExecutionView);
