import React from 'react';
import ReactTable from 'react-table';
import DivCenter from '../../components/styled-components/DivCenter';
import moment from "moment";

const ChronologicalReportTable = ({ data, columnsDates, classes }) => {
    let columns = [];
    columns = columnsDates.map(column => {
        return {
            Header: (<DivCenter>{moment(column.column).format('DD-MM-YYYY')}</DivCenter>),
            accessor: "column",
            Cell: d => {
              return d.original[column.column];
            },
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 250
          }
    });


    columns.unshift({
        Header: (<DivCenter>Actividad</DivCenter>),
        accessor: "name",
        filterable: false,
        sortable: false,
        style: { textAlign: "left" },
        minWidth: 250,
    });
    
    

    return (
        <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={data.length}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
    )
    
        
}

export default ChronologicalReportTable;
