import React from 'react';
import ReactTable from 'react-table';
import PropTypes from "prop-types";
import DivCenter from '../../components/styled-components/DivCenter';
import { useTranslation } from "react-i18next";
import moment from "moment";
import {  Tooltip } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";

const VisitAnswersTable = ({ data, visit }) => {
    const { t } = useTranslation("checklistAnalisys");

    
    const columns = [
        {
          Header: (
            <DivCenter>
              {t("checklistAnalisys.list.columns.user")}
            </DivCenter>
          ),
          accessor: "user",
          Cell: ({ original }) => `${original.userRef.firstName} ${original.userRef.lastName}`,
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          width: 200,
        },
        {
          Header: (
            <DivCenter>{t("checklistAnalisys.list.columns.email")}</DivCenter>
          ),
          accessor: "email",
          Cell: ({ original }) => `${original.userRef.email}`,
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          width: 200,
        },
        {
          Header: (
            <DivCenter>{t("checklistAnalisys.list.columns.checkIn")}</DivCenter>
          ),
          accessor: "email",
          Cell: ({ original }) => `${moment(original.checkInDate).format('DD/MM/YYYY HH:mm:ss')}`,
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          width: 200,
        },
        {
          Header: (
            <DivCenter>{t("checklistAnalisys.list.columns.checkOut")}</DivCenter>
          ),
          accessor: "email",
          Cell: ({ original }) => `${moment(original.checkOutDate).format('DD/MM/YYYY HH:mm:ss')}`,
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          width: 200,
        }
    ]

    visit.visitRef.keys.map(key => {
      columns.push({
          Header: <DivCenter>{key}</DivCenter>,
          accessor: "keyfield",
          Cell: ({original}) => {
            let answer = original.keyfields.find(keyField => keyField.name === key);

            if (answer) {
              return answer.value;
            }else{
              return '';
            }
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          width: 200
      });
    });

    visit.modules.map(module => {
      columns.push({
        Header: <DivCenter>{module.name}</DivCenter>,
        accessor: "module",
        Cell: ({original}) => {
          let answer = original.analytics.find(analytic => analytic.moduleId === module._id);

          if (answer) {
            return answer.analytic;
          }else{
            return '';
          }
        },
        filterable: false,
        sortable: false,
        style: { textAlign: "center" },
        width: 200
    });
    })

    return (
      <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={15}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
    );
}

export default VisitAnswersTable;
