import React from 'react';
import PropTypes from 'prop-types';

import history from '../../utils/history';
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Button from 'components/Button';
import FiltersSidebar from "components/FiltersSidebar/FiltersSidebar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import ActiveUsersItem from "containers/Visits/AdminDataItem/ActiveUsersItem";
import GeneralExecutionItem from "containers/Visits/AdminDataItem/GeneralExecutionItem";
import VisitsTimeView from './AdminData/VisitsTimeView';

const AdminDataItemView = ({
  classes,
  activeVisitAnswer, 
  keyfields,
  segments,
  selectedVisit,
  visits, 
  visitAnswers, 
  operators,
  createGeneralExecutionReport,
  createActiveUSerReport,
  createVisitingTimeReport,
  sendGeneralExecutionPDFReport,
  emailData,
  sendActiveUserPDFReport,
  sendVisitingTimePDFReport,
  createGeneralExecutionXLSReport,
  createActiveUserXLSReport,
  sendActiveUserXLSReport,
  sendGeneralExecutionXLSReport,
  closedVisitAnswer
}) => {
  const { loadingVisitAnswersClosedCount, dataVisitanswerClosedCount } = closedVisitAnswer;

  let closedCount = null;
  if (!loadingVisitAnswersClosedCount && dataVisitanswerClosedCount) {
    const { visitAnswerConnection } = dataVisitanswerClosedCount;
    if (visitAnswerConnection) {
      const { aggregate } = visitAnswerConnection;
      closedCount = aggregate.count;
    }
  }

  const { loadingVisitAnswersClosed, dataVisitAnswer } = visitAnswers;

  return (
    <GridContainer>
      <GridItem xs={3}>
        <FiltersSidebar
          activeVisitAnswer={activeVisitAnswer}
          closedVisitAnswer={closedCount}
          visits={visits}
          visitAnswers={visitAnswers}
          operators={operators}
        />
      </GridItem>
      <GridItem xs={9}>
        <Button
          simple
          size="lg"
          color="info"
          onClick={history.goBack}
          className={classes.buttonNoMargin}
        >
          <ArrowBackIcon/><span>Volver al tablero general</span>
        </Button>
        <GeneralExecutionItem
          selectedVisit={selectedVisit}
          keyfields={keyfields}
          visitAnswers={!loadingVisitAnswersClosed && dataVisitAnswer ? dataVisitAnswer : []}
          createGeneralExecutionReport={createGeneralExecutionReport}
          sendGeneralExecutionPDFReport={sendGeneralExecutionPDFReport}
          createGeneralExecutionXLSReport={createGeneralExecutionXLSReport}
          emailData={emailData}
          sendGeneralExecutionXLSReport={sendGeneralExecutionXLSReport}
        />
        <ActiveUsersItem
          segments={segments}
          visitAnswers={!loadingVisitAnswersClosed && dataVisitAnswer ? dataVisitAnswer : []}
          createActiveUSerReport={createActiveUSerReport}
          sendActiveUserPDFReport={sendActiveUserPDFReport}
          createActiveUserXLSReport={createActiveUserXLSReport}
          emailData={emailData}
          sendActiveUserXLSReport={sendActiveUserXLSReport}
        />
        <VisitsTimeView
          visitAnswers={!loadingVisitAnswersClosed && dataVisitAnswer ? dataVisitAnswer : []}
          createVisitingTimeReport={createVisitingTimeReport}
          sendVisitingTimePDFReport={sendVisitingTimePDFReport}
          emailData={emailData}
        />
      </GridItem>
    </GridContainer>
  );
};

AdminDataItemView.propTypes = {
  activeVisitAnswer: PropTypes.object.isRequired,
  keyfields: PropTypes.array.isRequired,
  segments: PropTypes.array.isRequired,
  selectedVisit: PropTypes.object.isRequired,
  visits: PropTypes.array.isRequired,
  visitAnswers: PropTypes.object.isRequired,
  operators: PropTypes.array.isRequired,
  createGeneralExecutionReport: PropTypes.func.isRequired,
  createActiveUSerReport: PropTypes.func.isRequired,
  createVisitingTimeReport: PropTypes.func.isRequired,
  emailData: PropTypes.object.isRequired,
  sendGeneralExecutionPDFReport: PropTypes.func.isRequired,
  sendActiveUserPDFReport: PropTypes.func.isRequired,
  sendVisitingTimePDFReport: PropTypes.func.isRequired,
  createGeneralExecutionXLSReport: PropTypes.func.isRequired,
  createActiveUserXLSReport: PropTypes.func.isRequired,
  sendActiveUserXLSReport: PropTypes.func.isRequired,
  sendGeneralExecutionXLSReport: PropTypes.func.isRequired
};

const styles = theme => ({
  buttonNoMargin:{
    padding: "10px 0px"
  },
  cardLessBorder: {
    marginBottom: "0px"
  }
});

export default withStyles(styles)(AdminDataItemView);
