export const addReport = async (store, newReport) => {
  let updatedReportList = store.state.reports.reportsList;

  // Add the new item at the begining 
  updatedReportList.unshift(newReport);

  // Trim the array to the last 5 items
  if(updatedReportList.length === 6) updatedReportList.pop();

  await store.setState({
    reports: {
      reportsList: updatedReportList,
      lastInsert: store.state.reports.lastInsert + 1
    }
  });
};