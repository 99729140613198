import {APPROVAL_ITEM_STATUS, APPROVAL_LOG_STATUS} from "../../constants";

export const getColorByStatus = status => {
  if (status === APPROVAL_ITEM_STATUS.Pending) {
    return 'info';
  }
  if (status === APPROVAL_ITEM_STATUS.Fulfilled) {
    return 'success';
  }
};

export const getColorByLogStatus = status => {
  if (status === APPROVAL_LOG_STATUS.Approved) {
    return "success";
  }
  if (status === APPROVAL_LOG_STATUS.Rejected) {
    return "danger";
  }
};
