import React from 'react';
import PropTypes from 'prop-types';

// custom components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import DivCenter from "components/styled-components/DivCenter";
import {useTranslation} from "react-i18next";


const EngineAnswerFieldFooter = props => {

  const { t, } = useTranslation('question-engine-module-answer');


  return (
    <GridContainer>
      <GridItem xs={7}>
        <small>{`${t("question-engine-module-answer.view.field")} : ${props.answerData.questionLabel}`}</small>
      </GridItem>
      <GridItem xs={5}>
        <DivCenter>
          <small>{`${t("question-engine-module-answer.view.keyField")} : ${props.answerData.key}`}</small>
        </DivCenter>
      </GridItem>
    </GridContainer>
  );
};

EngineAnswerFieldFooter.propTypes = {
  answerData: PropTypes.object.isRequired
};

export default EngineAnswerFieldFooter;
