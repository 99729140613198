import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
import GridContainer from "../../components/Grid/GridContainer";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from '../../utils/history';
import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import {CircularProgress} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import VisitSchedulingTabulatorTable from "../../tables/visitScheduling/VisitSchedulingTabulatorTable";
import CardBody from "../../components/Card/CardBody";

const VisitSchedulingTabulatorView = ({
  results,
  visitScheduling
}) => {
  const { t } = useTranslation(['visitScheduling', 'common']);
  const { data, resultsLoading, resultsError } = results;

  return (
    <GridContainer>
      <GridItem>
        <Button
          simple
          size="lg"
          color="info"
          onClick={history.goBack}
        >
          <ArrowBackIcon/><span>{t("common:common.back")}</span>
        </Button>
      </GridItem>

      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <EventAvailableIcon/>
            </CardIcon>
            <div style={style.left}>
              <h4 style={style.cardTitle}>
                {t("visitScheduling.tabulator.title")}
                {visitScheduling.name}
              </h4>
            </div>
          </CardHeader>

          <CardBody>
            {resultsLoading ? (
              <div style={style.progressCnt}>
                <CircularProgress style={style.progress}/>
              </div>
            ) : resultsError ? (
              <div>
                <Alert variant="outlined" severity="error">
                  {t('common:common.error.title')}
                </Alert>
              </div>
            ) : (
              <VisitSchedulingTabulatorTable
                data={data}
                visitScheduling={visitScheduling}
              />
            )}
          </CardBody>

        </Card>
      </GridItem>

    </GridContainer>
  );
};

const style = {
  cardTitle: {
    color: "#3C4858"
  },
  progress: {
    margin: '16px'
  },
  progressCnt:{
    width: '100px !important',
    marginLeft: '50%',
    marginRight: '50%'
  },
  cardHeight: {
    height: "430px"
  },
  left: {
    float: "left"
  },
  right: {
    float: "right"
  },
  pointer: {
    cursor: "pointer"
  },
  marginBottom: {
    marginBottom: "25px"
  }
};

export default VisitSchedulingTabulatorView;
