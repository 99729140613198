import { Typography } from '@material-ui/core';
import React from 'react';
import ReactTable from "react-table";
import DivCenter from "../../components/styled-components/DivCenter";
import { Tooltip } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import ConfirmIconButton from "../../components/ConfirmIconButton/ConfirmIconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useTranslation } from "react-i18next";

const PenaltiesTable = ({
  data,
  setCurrentPenalty,
  setVisibleForm,
  setEditForm,
  processRelationship,
  deletePenaltyConfig,
}) => {
  const { t } = useTranslation("processRelationship");

  return (
    <React.Fragment>
      <Typography variant="h5" style={{ textAlign: 'center', marginTop: '10px' }}>
        {t("processRelationship.workPlan.penalties.rulesTitle")}
      </Typography>
      <ReactTable
        data={data}
        columns={[
          {
            Header: (
              <DivCenter>
                {t("processRelationship.workPlan.list.indicator")}
              </DivCenter>
            ),
            accessor: "indicator",
            filterable: false,
            sortable: false,
            style: { textAlign: "left", whiteSpace: 'unset' },
            minWidth: 180,
            Cell: ({ original }) => {
              return (
                <Typography>
                  {" "}
                  {t(
                    `processRelationship.workPlan.indictors.${original.indicator}`
                  )}{" "}
                </Typography>
              );
            },
          },
          {
            Header: (
              <DivCenter>
                {t("processRelationship.workPlan.list.operation")}
              </DivCenter>
            ),
            accessor: "operation",
            filterable: false,
            sortable: false,
            style: { textAlign: "center", whiteSpace: 'unset' },
            Cell: ({ original }) => {
              return (
                <Typography>
                  {" "}
                  {t(
                    `processRelationship.workPlan.operations.${original.operation}`
                  )}{" "}
                </Typography>
              );
            },
          },
          {
            Header: (
              <DivCenter>
                {t("processRelationship.workPlan.list.value")}
              </DivCenter>
            ),
            accessor: "value",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            Cell: ({ original }) => {
              return <Typography> {`${original.value}`} </Typography>;
            },
          },
          {
            Header: (
              <DivCenter>
                {t("processRelationship.workPlan.list.affectation")}
              </DivCenter>
            ),
            accessor: "percentagePenalty",
            filterable: false,
            sortable: false,
            style: { textAlign: "center", color: '#cc0606' },
            Cell: ({ original }) => {
              return (
                <Typography>
                  {" "}
                  {`${(original.percentagePenalty) * -1 ?? 0} %`}{" "}
                </Typography>
              );
            },
          },
          {
            Header: <DivCenter></DivCenter>,
            accessor: "actions",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            Cell: ({ original }) => {
              return (
                <div>
                  <Button
                    justIcon
                    round
                    simple
                    color="info"
                    onClick={() => {
                      setCurrentPenalty({
                        penaltiesConfig: original,
                      });
                      setVisibleForm(false);
                      setEditForm(true);
                    }}
                  >
                    <Tooltip title="Editar">
                      <EditIcon />
                    </Tooltip>
                  </Button>
                  <ConfirmIconButton
                    t={t}
                    simple={true}
                    baseTooltip={"processRelationship.tooltip.archived"}
                    confirmTooltip={
                      "processRelationship.tooltip.archiveConfirm"
                    }
                    baseIcon={DeleteForeverIcon}
                    confirmDelay={3000}
                    confirmOperation={() => {
                      const variables = {
                        where: {
                          processRelationshipId: processRelationship._id,
                          penaltyId: original._id,
                        },
                      };

                      deletePenaltyConfig({
                        variables: variables,
                      });
                    }}
                  />
                </div>
              );
            },
          },
        ]}
        defaultPageSize={data.length}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
        noDataText={t("processRelationship.workPlan.noData")}
        resizable={false}
      />
    </React.Fragment>
  );
};

export default PenaltiesTable;
