// @material-ui/icons
import CardTravel from "@material-ui/icons/CardTravel";
import FiberNew from "@material-ui/icons/FiberNew";
import Person from '@material-ui/icons/Person';

import {APPROVAL_FLOW_ITEM_STATUS, APPROVAL_ITEM_STATUS} from "../../constants";

export const getColorByApprovalItemStatus = status => {
  if (status === APPROVAL_ITEM_STATUS.Pending) {
    return 'warning';
  }
  if (status === APPROVAL_ITEM_STATUS.Fulfilled) {
    return 'success';
  }
};


export const getColorByApprovalFlowItemStatus = status => {
  switch (status) {
    case APPROVAL_FLOW_ITEM_STATUS.Pending:
      return 'warning';
    case APPROVAL_FLOW_ITEM_STATUS.Fulfilled:
      return 'success';
    case APPROVAL_FLOW_ITEM_STATUS.Approved:
      return 'success';
    case APPROVAL_FLOW_ITEM_STATUS.Rejected:
      return 'danger';
    case APPROVAL_FLOW_ITEM_STATUS.NotReached:
      return 'gray';
    default:
      return 'gray';
  }
};

export const getIconByApprovalItemStatus = (status) => {
  switch (status) {
    case APPROVAL_ITEM_STATUS.Pending:
      return FiberNew;
    case APPROVAL_ITEM_STATUS.Fulfilled:
      return Person;
    default:
      return CardTravel;
  }
};

export const getGlobalChecklistColumns = (visit) => {
  return !!visit.checkListConfiguration && !!visit.checkListConfiguration.columns ?
    visit.checkListConfiguration.columns.reduce((result, columnGroup) => {
      const averageOperationFound = columnGroup.operation
        .find(operation => operation.name.endsWith('average'));
      const efficacyOperationFound = columnGroup.operation
        .find(operation => operation.name.endsWith('efficacy'));

      // Add global columns if missing
      if (averageOperationFound) {
        const globalName = 'global';
        const columnGlobalAverageFound = result.find(column => column === globalName);
        if (!columnGlobalAverageFound) {
          result = [...result, globalName]
        }
      }
      if (efficacyOperationFound) {
        const globalEfficacyName = 'globalEfficacy';
        const columnGlobalEfficacyFound = result.find(column => column === globalEfficacyName);
        if (!columnGlobalEfficacyFound) {
          result = [...result, globalEfficacyName]
        }
      }
      return result;
    }, []) : [];
};
