import React from "react";
import Moment from "moment";
import {useTranslation} from "react-i18next";

// custom components
import Timeline from "components/Timeline/Timeline.jsx";

// core components
import withStyles from "@material-ui/core/styles/withStyles";

// utils
import {getColorByStatus, getIconByStatus} from "../../utils/views/tickets";


const TicketReportTimelineView = ({ticketReport, classes}) => {
  const { t, } = useTranslation('tickets');

  const stories = ticketReport.ticketReportLogs.reverse().map((log, index) => {
    return   {
      // First story
      inverted: index % 2,
      badgeColor: getColorByStatus(log.status),
      badgeIcon: getIconByStatus(log.status),
      title: t(`tickets.model.status.${log.status}`),
      titleColor: getColorByStatus(log.status),
      body: (
        <h5><small>{log.userRef ? log.userRef.email : []}</small></h5>
      ),
      footerTitle: Moment(log.created).local().fromNow()
    };
  });

  if (ticketReport) {
    return (
      <div className={classes.mainContainer}>
        <h4 className={classes.center}>Timeline de Ticket</h4>
        <Timeline stories={stories} />
      </div>
    );
  }else{
    return [];
  }
};

const styles = theme => ({
  mainContainer: {
    marginTop: 50,
    padding: 15
  },
  center: {
    textAlign: "center"
  }
});


export default withStyles(styles)(TicketReportTimelineView);

