import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

// styled components
import DivCenter from "../../components/styled-components/DivCenter";
import PreviewButton from '../../components/Reports/PreviewButton';
import DownloadButton from '../../components/Reports/DownloadButton';
import SendEmailButton from '../../components/Reports/SendEmailButton';
import withStyles from "@material-ui/core/styles/withStyles";

function AverageAnalysisTable({classes, data}) {
  return (
    <div className={classes.marginTable}>
      <ReactTable
        data={data}
        columns={[
          // {
          //   id: "actions",
          //   Header: <DivCenter>Acciones</DivCenter>,
          //   filterable: false,
          //   sortable: false,
          //   minWidth: 150,
          //   Cell: (d) => {
          //     return (
          //       <DivCenter>
          //         <PreviewButton
          //           isReady={false}
          //         />
          //       </DivCenter>
          //     );
          //   }
          // },
          {
            id: "name",
            Header: "Criterio",
            accessor: "name",
            filterable: false,
            sortable: false,
            minWidth: 150
          },
          {
            id: "avg",
            Header: <DivCenter>AVG</DivCenter>,
            accessor: "analysis.average",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 150
          },
          {
            id: "max",
            Header: <DivCenter>MAX</DivCenter>,
            accessor: "analysis.max",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 150
          },
          {
            id: "best",
            Header: <DivCenter>MEJOR</DivCenter>,
            accessor: "analysis.best",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 150
          },
          {
            id: "min",
            Header: <DivCenter>MIN</DivCenter>,
            accessor: "analysis.min",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 150
          },
          {
            id: "worst",
            Header: <DivCenter>PEOR</DivCenter>,
            accessor: "analysis.worst",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 150
          },
        ]}
        defaultPageSize={data.length}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
    </div>
  );
};

AverageAnalysisTable.propTypes = {
  data: PropTypes.array.isRequired
};

const styles = theme => ({
  marginTable: {
    marginTop: " 15px"
  },
});

export default withStyles(styles)(AverageAnalysisTable);
