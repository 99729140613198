import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { useTranslation } from "react-i18next";
import {Form, withFormik } from 'formik';
import * as yup from 'yup';
import Datetime from "react-datetime";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import CheckIcon from '@material-ui/icons/Check';
import ButtonIcon from 'components/styled/ButtonIcon';

const validationSchema = yup.object().shape({
    from: yup.string().required(),
    to: yup.string().required()
  });

const ReportFiltersModal = (props) => {
  const { t } = useTranslation("checklistAnalisys");
  const classes = withStyles(styles);

  const { modalStatus, setModalStatus } = props;
  // Formik bag
  const { setFieldValue, values, submitForm } = props;

  const handleClose = () => {
    setModalStatus({
        open: false,
        reportConfig: {}
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalStatus?.open}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <DialogTitleComponent id="customized-dialog-title" onClose={handleClose}>
        {t("checklistAnalisys.modal.title")}
      </DialogTitleComponent>
      <DialogContent dividers>
        <div className={classes.root}>
        <Form>
      <GridContainer justify="center">
        <GridItem xs={6}>
          <InputLabel className={classes.label}>
            {t("checklistAnalisys.modal.from")}
          </InputLabel>
          <Datetime
            name={'from'}
            timeFormat={false}
            defaultValue={values.from}
            onChange={e => {
              if (e === '') {
                setFieldValue('from', e);
              }else{
                setFieldValue('from', e._d);
              }

            }}
          />
        </GridItem>
        <GridItem xs={6}>
          <InputLabel className={classes.label}>
            {t("checklistAnalisys.modal.to")}
          </InputLabel>
          <Datetime
            name={'to'}
            timeFormat={false}
            defaultValue={values.to}
            onChange={e => {
              if (e === '') {
                setFieldValue('to', e);
              }else{
                setFieldValue('to', e._d);
              }              
            }}
          />
        </GridItem>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <GridItem xs={12} styles={{
            marginTop: '500px !important'
        }}>
          <ButtonIcon
            icon={CheckIcon}
            label={'generar'}
            color={'info'}
            block={true}
            onClick={() => {
              submitForm();
            }}
          />
        </GridItem>
      </GridContainer>
    </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const DialogTitleComponent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle disableTypography {...other}>
      <GridContainer>
        <GridItem xs={10}>
          <Typography variant="h6">{children}</Typography>
        </GridItem>
        <GridItem xs={2} style={{ textAlign: "end" }}>
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </GridItem>
      </GridContainer>
    </DialogTitle>
  );
}

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "rgba(0, 0, 0, 0.26)",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    marginTop: {
        marginTop: "50px !important"
    }
  });

  const ReportsFilterFormWF = withFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    mapPropsToValues: () => {
      return {
        from: moment().startOf('day').subtract(30, 'days'),
        to: moment().endOf('day')
      };
    },
    handleSubmit: (values, { props }) => {
        const { modalStatus } = props;
      props.generateReport({
        ...values,
        ...modalStatus.reportConfig
      });

      props.setModalStatus({
        open: false,
        reportConfig: {}
      });
    }
  })(ReportFiltersModal);

export default ReportsFilterFormWF;
