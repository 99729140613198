import React from 'react';
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";

import FiltersSidebar from "components/FiltersSidebar/FiltersSidebar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";

import GeneralInformation from './VisitsDashboard/GeneralInformation';
import CombinedScores from './VisitsDashboard/CombinedScores';
import SpecialReports from './VisitsDashboard/SpecialReports';
import { selectedKeyfields, selectedCategory, getTableRows } from '../../utils/analytics/dataManipulation';
import { analizeCombinedScores } from '../../utils/analytics/combinedScores';
import { initializeKeyfields, initializeCategories } from '../../utils/initializers';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { CircularProgress } from '@material-ui/core';
import LoadingProcessView from '../LoadingProcessView';

const VisitsDashboardItemView = ({
  classes, 
  activeVisitAnswer,
  keyfields, 
  categories,
  createCombinedScoresReport,
  createCombinedScoresXLSReport,
  emailData,
  hasGlobal, 
  visits, 
  visitAnswers, 
  operatorsObj, 
  operation,
  sendCombinedScoresPDFReport,
  sendCombinedScoresXLSReport,
  closedVisitAnswer,
}) => {
  const [stateKeyfields, setStateKeyfields] = React.useState([]);
  const [stateCategory, setStateCategory] = React.useState([]);
  const [globalCategory, setGlobalCategory] = React.useState(false);
  
  React.useEffect(() => {
    setStateKeyfields(initializeKeyfields(keyfields.length));
    setStateCategory(initializeCategories(categories.length, hasGlobal));
    setGlobalCategory(hasGlobal)
  }, [keyfields, categories, hasGlobal]);

  const filteredKeyfields = selectedKeyfields(keyfields, stateKeyfields);
  const filteredCategory = selectedCategory(
    categories, 
    stateCategory, 
    globalCategory, 
    operation
  );
  
  const { loadignOperator, operators } = operatorsObj;
  const { loadingVisitAnswersClosed, dataVisitAnswer } = visitAnswers;
  const { loadingVisitAnswersClosedCount, dataVisitanswerClosedCount } = closedVisitAnswer;

  let closedCount = null;
  let data = [];
  let analytics = {};
  if (!loadingVisitAnswersClosed && dataVisitAnswer) {
    data = getTableRows(dataVisitAnswer, filteredKeyfields, filteredCategory, operation);
    analytics = analizeCombinedScores(data);
  }

  if (!loadingVisitAnswersClosedCount && dataVisitanswerClosedCount) {
    const { visitAnswerConnection } = dataVisitanswerClosedCount;
    if (visitAnswerConnection) {
      const { aggregate } = visitAnswerConnection;
      closedCount = aggregate.count;
    }
  }

  return (
    <GridContainer>
      <GridItem lg={3} md={3} sm={4} xs={12}>
        <FiltersSidebar
          activeVisitAnswer={activeVisitAnswer}
          closedVisitAnswer={closedCount}
          visits={visits}
          visitAnswers={visitAnswers}
          operators={loadignOperator ? [] : operators}
        />
      </GridItem>
      <GridItem lg={9} md={9} sm={8} xs={12}>
        <GeneralInformation
          categories={categories}
          hasGlobal={hasGlobal}
          keyfields={keyfields}
          stateCategory={stateCategory}
          stateKeyfields={stateKeyfields}
          globalCategory={globalCategory}
          operation={operation}
          setStateCategory={setStateCategory}
          setStateKeyfields={setStateKeyfields}
          setGlobalCategory={setGlobalCategory}
        />
        
        <GridContainer>
          <GridItem  lg={9} md={12} sm={12} xs={12}>
            { loadingVisitAnswersClosed ?
                <LoadingProcessView closedCount={closedCount} />
              :
              <CombinedScores
                analytics={analytics}
                emailData={emailData}
                data={data}
                filteredKeyfields={filteredKeyfields}
                createCombinedScoresReport={createCombinedScoresReport}
                createCombinedScoresXLSReport={createCombinedScoresXLSReport}
                sendCombinedScoresPDFReport={sendCombinedScoresPDFReport}
                sendCombinedScoresXLSReport={sendCombinedScoresXLSReport}
                filteredCategory={filteredCategory}
              />
            }
              
          </GridItem>
          <GridItem  lg={3} md={12} sm={12} xs={12}>
            <SpecialReports
              classes={classes} 
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

VisitsDashboardItemView.propTypes = {
  activeVisitAnswer: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  createCombinedScoresReport: PropTypes.func.isRequired,
  createCombinedScoresXLSReport: PropTypes.func.isRequired,
  emailData: PropTypes.object.isRequired,
  hasGlobal: PropTypes.bool.isRequired,
  keyfields: PropTypes.array.isRequired, 
  visits: PropTypes.array.isRequired,
  visitAnswers: PropTypes.object.isRequired,
  operator: PropTypes.object,
  operatorsObj: PropTypes.object,
  sendCombinedScoresPDFReport: PropTypes.func.isRequired,
  sendCombinedScoresXLSReport: PropTypes.func.isRequired,
};

const styles = theme => ({
  progress:{
    marginLeft: '50%',
    marginRight: '50%',
    marginTop: '50%',
    marginBottom: '50%'
  },
  textCenter:{
    textAlign: 'center'
  }
});

export default withStyles(styles)(VisitsDashboardItemView);
