import React, {useState} from 'react';
import DetailProcessRelationshipView from '../../Views/ProcessRelationship/DetailProcessRelationshipView';
import {useQuery} from "react-apollo-hooks";
import {PROCESS_RELATIONSHIPS_CALCULATIONS_DETAIL} from "./gql/Queries";
import moment from "moment";

const DetailProcessRelationship = ({ location }) => {
    const item = location?.state?.item;
    const filterDates = location?.state?.filterDates;
    const processRelationship = location?.state?.processRelationship;
    const startDate = moment(filterDates.created_gt);
    const endDate = moment(filterDates.created_lte);

  const {
    data: resultsCalculations,
    loading: resultsLoading,
    error: resultsError
  } = useQuery(PROCESS_RELATIONSHIPS_CALCULATIONS_DETAIL, {
    variables: {
      id: processRelationship._id.toString(),
      element: item._id.toString(),
      created_gt: startDate,
      created_lte: endDate
    },
    fetchPolicy: 'network-only',
  });

    return (
        <DetailProcessRelationshipView
            results={{ data: resultsCalculations.getProcessRelationshipCalculations, loading: resultsLoading, resultsError}}
            processRelationship={processRelationship}
            item={item}
        />
    )
}

export default DetailProcessRelationship;
