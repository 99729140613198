import React from 'react'
import VisitAnswersReportView from '../../Views/Reports/VisitAnswersReportView'
import useGlobal from "../../store";
import { useQuery } from 'react-apollo';
import { VISIT_ANSWER_REPORT } from './gql/Queries';
import { useState } from 'react';
import moment from "moment";
import { useMutation } from 'react-apollo-hooks';
import { CREATE_CUSTOM_REPORT_MUTATION } from './gql/Mutations';
import { useTranslation } from "react-i18next";
import { classifyAndFilter, parseVisitAnswers } from '../../utils/analytics/dataManipulation';

const VisitAnswersList = ({ location }) => {
    const { t } = useTranslation("checklistAnalisys");
    const { state } = location;
    const [session] = useGlobal(
      state => state.session,
      actions => actions
    );
    const [, globalActions] = useGlobal();

    const [visitAnswers, setVisitanswers ] = useState({
        where: {
            app: session.user ? session.appRef._id : "",
            visitId: state.visit.visit,
            checkInDate_gt: moment().startOf('day').subtract(30, 'days'),
            checkInDate_lt: moment().endOf('day'),
            hasCheckOutDate: true
        },
        first: 15,
        skip: 0,
        numPage: 1,
        orderBy: 'created_ASC'
    });

    const { data: dataVisitAnswers, loading: loadingVisitanswers, error } = useQuery(VISIT_ANSWER_REPORT, {
        variables: visitAnswers,
        fetchPolicy: "network-only"
    });

    const paginationAction  = async (values) => {
        setVisitanswers(values);
    }

    const createCustomReport = useMutation(CREATE_CUSTOM_REPORT_MUTATION);

    const filteredDataVisitAnswers = dataVisitAnswers && dataVisitAnswers.getAllVisitAnswers.visitAnswers ? 
    dataVisitAnswers.getAllVisitAnswers.visitAnswers.filter(visitAnswer => visitAnswer) : [];

    let keyfields = [];
    state.visit.visitRef.keys.map(key => {
        keyfields.push({ id: key, name: key });
    })

    const [activeCount, closedVisits] = classifyAndFilter(filteredDataVisitAnswers);

    const parsedClosed = filteredDataVisitAnswers ? parseVisitAnswers(closedVisits, keyfields) : [];

    if (dataVisitAnswers) {
      const { getAllVisitAnswers } = dataVisitAnswers;
      state.visit.modules = getAllVisitAnswers.modules;

    }
    return (
        <VisitAnswersReportView
            total={ !loadingVisitanswers && dataVisitAnswers.getAllVisitAnswers.total ? dataVisitAnswers.getAllVisitAnswers.total : 0}
            visit={state.visit}
            paginationAction={paginationAction}
            visitAnswers={visitAnswers}
            visitAnswersData={{ loading: loadingVisitanswers, data: parsedClosed }}
            filterChanged={(filter) => {
                let changedFilter = {
                  where: {
                    app: visitAnswers.where.app,
                    visitId: visitAnswers.where.visitId,
                    checkInDate_gt: filter.startDateCreated,
                    checkInDate_lt: filter.endDateCreated,
                    hasCheckOutDate: true
                  },
                  first: visitAnswers.first,
                  skip: 0,
                  orderBy: 'created_ASC'
                };

                setVisitanswers(changedFilter);

            }}
            submitSuccess={() => {
                let variables = {
                  data: {
                    visitId: visitAnswers.where.visitId,
                    app: visitAnswers.where.app,
                    session: {
                        user: {
                            email: session.user.email,
                            firstName: session.user.firstName,
                            lastName: session.user.lastName,
                            _id: session.user._id
                        }
                    },
                    dateRange: {
                      startDate: visitAnswers.where.checkInDate_gt,
                      endDate: visitAnswers.where.checkInDate_lt,
                    }
                  },
                  where:{
                      _id: state.visit._id
                  }
                };

                const propMutation = createCustomReport({ variables: variables });
                if (!propMutation.loading) {
                    if (!propMutation.error) {
                      // Add success notification
                      globalActions.notification.openNotification("tr", {
                        message: t('checklistAnalisys.banner.reportSucces'),
                        color: "success"
                      });

                    }else{
                      globalActions.notification.openNotification("tr", {
                        message: t('checklistAnalisys.banner.reportFail'),
                        color: "danger"
                      });
                    }
                }
            }}
        />
    )
}

export default VisitAnswersList
