import React, { useState } from "react";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import { CircularProgress } from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import Button from "components/CustomButtons/Button.jsx";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ProcessRelationshipTable from "../../tables/ProcessRelationship/ProcessRelationshipTable";
import history from "../../utils/history";
import TablePagination from '../../pagination/table/TablePagination';
import PenaltiesModal from "../../modals/PenaltiesModal";
import Badge from 'components/Badge/Badge';
import auth from "../../utils/auth";
import useGlobal from "../../store";

const ProcessRelationshipListView = ({
  processRelationships,
  processRelationshipFilter,
  archiveProcessRelationship,
  isArchivedMode,
  changeArchivedMode,
  paginationAction,
  plan
  // submitSuccess,
}) => {
  const { t } = useTranslation("processRelationship");
  const [session] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );
  const [open, setOpen] = useState(false);
  const [processRelationship, setProcessRelationship] = useState(null);
  const { loading, data } = processRelationships;

  const processRelationshipCount = plan ? parseInt(plan?.features?.processRelationship) : 50;
  const currentProcessRelationship = data ? data?.length : 50;

  const disabledProcessRelationship = processRelationshipCount <= currentProcessRelationship;
  const reportCount = (processRelationshipCount - currentProcessRelationship) >= 0 ? processRelationshipCount - currentProcessRelationship : 0;

  const hasPermission = (permission) => {
    if ( session.user && !auth.hasPermission(permission, session.permissions)) return false;
    return true;
  }; 

  return (
    <Card>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <AssignmentTurnedInIcon />
        </CardIcon>
        <div style={style.left}>
          <h4 style={style.cardTitle}>{t("processRelationship.title")}</h4>
        </div>
        <div style={style.right}>
          <Button
            id={"archived-object"}
            color={"danger"}
            size="sm"
            onClick={() => {
              changeArchivedMode(!isArchivedMode ? true : false);
            }}
          >
            {!isArchivedMode
              ? t("processRelationship.list.buttons.viewArchived")
              : t("processRelationship.list.buttons.viewActive")}
          </Button>
          <Button
            id={"new-object"}
            size="sm"
            color="info"
            disabled={!hasPermission('processRelationshipCreated')}
            onClick={() => history.push(`/objects/new`)}
            disabled={disabledProcessRelationship}
          >
            {t("processRelationship.newObject")}
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <div style={style.right}>
          <Badge color={"info"}>{t("processRelationship.remaning")}: {reportCount}</Badge>
        </div>
        <TablePagination
          data={data ? data : []}
          paginationAction={paginationAction}
          filter={processRelationshipFilter}
        />
        {loading ? (
          <div style={style.progressCnt}>
            <CircularProgress style={style.progress} />
          </div>
        ) : (
          <ProcessRelationshipTable
            data={data}
            archiveProcessRelationship={archiveProcessRelationship}
            isArchivedMode={isArchivedMode}
            open={open}
            setOpen={setOpen}
            setProcessRelationship={setProcessRelationship}
          />
        )}
        <PenaltiesModal open={open} setOpen={setOpen} processRelationship={processRelationship}  />
      </CardBody>
    </Card>
  );
};

ProcessRelationshipListView.propTypes = {
  // submitSuccess: PropTypes.func.isRequired,
  paginationAction: PropTypes.func.isRequired,
  processRelationshipFilter: PropTypes.object.isRequired,
  archiveProcessRelationship: PropTypes.func.isRequired,
};

const style = {
  cardTitle: {
    color: "#3C4858",
  },
  progress: {
    margin: "16px",
  },
  progressCnt: {
    width: "100px !important",
    marginLeft: "50%",
    marginRight: "50%",
  },
  cardHeight: {
    height: "430px",
  },
  left: {
    float: "left",
  },
  right: {
    float: "right",
  },
};

export default ProcessRelationshipListView;
