import React from "react";
import {useQuery} from "react-apollo-hooks";
import history from "utils/history";
import Moment from 'moment';

// Own components
import LoadingCircleView from "../../Views/LoadingCircleView";
import useGlobal from "../../store";
import { VISIT_ITEMS, VISIT_ANSWER_ITEMS, VISIT_ANSWER_COUNT } from "./gql/Queries";
import { getOperationsFromColumns, getKeyfields, parseVisitAnswers, classifyAndFilter } from "../../utils/analytics/dataManipulation";
import VisitsVisorItemView from "../../Views/Visits/VisitsVisorItemView";
import LoadingProcessView from "../../Views/LoadingProcessView";

function VisitsDashboardItem({match}) {
  const [ session, ] = useGlobal(
    state => state.session,
    actions => actions
  );
  const [ filters, filtersActions ] = useGlobal(
    state => state.filters,
    actions => actions.filters
  );

  const visitID = match.params.idVisit === ":idVisit" ? null : match.params.idVisit;

  const {data, loading} = useQuery(VISIT_ITEMS, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : '',
        hasChecklistConfiguration: true,
        isArchived: false
      }
    },
    fetchPolicy: 'network-only'
  }); 

  const {
    data: dataVisitAnswers, 
    loading: loadingVisitAnswers
  } = useQuery(VISIT_ANSWER_ITEMS, {
      variables: {
        where: {
          userId: session.user._id,
          app: session.appId,
          visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
          checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
          checkInDate_lte: filters.range.endDate,
          approvalStatus_not_in: ["Rejected", "Excluded"],
          hasCheckOutDate: true
        }, 
        orderBy: 'checkInDate_ASC' 
      },
      skip: !filters.selectedVisitID,
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    });

    const {
      data: dataVisitAnswersOpen, 
      loading: loadingVisitAnswersOpen
    } = useQuery(VISIT_ANSWER_COUNT, {
        variables: {
          where: {
            userId: session.user._id,
            app: session.appId,
            visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
            checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
            checkInDate_lte: filters.range.endDate,
            approvalStatus_not_in: ["Rejected", "Excluded"],
            hasCheckOutDate: false
          }
        },
        skip: !filters.selectedVisitID,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      });

  const {
      data: dataVisitAnswersClosed, 
      loading: loadingVisitAnswersClosed
    } = useQuery(VISIT_ANSWER_COUNT, {
        variables: {
          where: {
            userId: session.user._id,
            app: session.appId,
            visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
            checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
            checkInDate_lte: filters.range.endDate,
            approvalStatus_not_in: ["Rejected", "Excluded"],
            hasCheckOutDate: true
          }
        },
        skip: !filters.selectedVisitID,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      });

  const filteredDataVisitAnswers = dataVisitAnswers && dataVisitAnswers.visitAnswers ? 
    dataVisitAnswers.visitAnswers.filter(visitAnswer => visitAnswer) : [];

  // If the store is empty or different, use the visitID from the route params
  if(data.visits && (
      (!!visitID && !filters.selectedVisitID) || 
      (!!visitID && visitID !== filters.selectedVisitID.value))){
    const option = data.visits.find((visit) => visitID === visit._id);
    filtersActions.setVisitID({label: option.name, value: option._id});
  }

  // Select by default the first visit ID
  if(!visitID && !filters.selectedVisitID && data.visits && data.visits.length > 0) {
    const option = data.visits[0];
    history.push(`/visits/${option._id}/dashboard/`);
  }

  // Select by default the first visit ID
  if(!visitID && filters.selectedVisitID) {
    history.push(`/visits/${filters.selectedVisitID.value}/dashboard/`);
  }

  let closedCount = null;
  if (!loadingVisitAnswersClosed && dataVisitAnswersClosed) {
    const { visitAnswerConnection } = dataVisitAnswersClosed;
    if (visitAnswerConnection) {
      const { aggregate } = visitAnswerConnection;
      closedCount = aggregate.count;
    }
  }

  if (loading || loadingVisitAnswers || loadingVisitAnswersClosed || loadingVisitAnswersOpen) {
    return <LoadingProcessView closedCount={closedCount}/>
  } else {
    const selectedVisit = data.visits ? 
      data.visits.find((visit) => 
        filters.selectedVisitID && 
        filters.selectedVisitID.value === visit._id) 
      : null;

    const operators = 
      selectedVisit && selectedVisit.checkListConfiguration 
      && selectedVisit.checkListConfiguration.columns ? 
        getOperationsFromColumns(selectedVisit.checkListConfiguration.columns) 
        : [];

    const selectedOperation = operators.find(
      operator => 
        filters.selectedOperation && 
        filters.selectedOperation.value === operator.id);

    // Select by default the first option
    if(!selectedOperation && operators && operators.length > 0) {
      const option = operators[0];
      filtersActions.setOperation({label: option.name, value: option.id});
    }

    const visits = data.visits ? data.visits.map(({_id, name}) => ({id: _id, name})) : []; 
    const keyfields = getKeyfields(selectedVisit);
    const [activeCount, closedVisits] = classifyAndFilter(filteredDataVisitAnswers);
    const parsedClosed = closedVisits ? 
      parseVisitAnswers(closedVisits, keyfields) : [];

    return (
      <VisitsVisorItemView
        activeVisitAnswer={{ loadingActiveVisitanswers: loadingVisitAnswersOpen, dataOpenVisitanswer: dataVisitAnswersOpen }}
        closedVisitAnswer={{ loadingVisitAnswersClosedCount: loadingVisitAnswersClosed, dataVisitanswerClosedCount: dataVisitAnswersClosed }}
        keyfields={keyfields}
        operators={operators}
        visit={selectedVisit}
        visits={visits}
        visitAnswers={{ loadingVisitAnswersClosed: loadingVisitAnswers, dataVisitAnswer: parsedClosed }}
      />
    );
  }

}

export default VisitsDashboardItem;
