import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Badge from "components/Badge/Badge.jsx";

import timelineminiStyle from "assets/jss/material-dashboard-pro-react/components/timelineminiStyle.jsx";

function TimelineMini({ ...props }) {
  const { classes, stories, simple, counter } = props;
  const timelineClass =
    classes.timeline +
    " " +
    cx({
      [classes.timelineSimple]: simple
    });
  return (
    <ul className={timelineClass}>
      {stories.map((prop, key) => {
        const panelClasses =
          classes.timelinePanel +
          " " +
          cx({
            [classes.timelinePanelInverted]: prop.inverted || simple,
            [classes.timelineSimplePanel]: simple
          });
        const timelineBadgeClasses =
          classes.timelineBadge +
          " " +
          classes[prop.badgeColor] +
          " " +
          cx({
            [classes.timelineSimpleBadge]: simple
          });
        return (
          <li className={classes.item} key={key}>
            <div className={timelineBadgeClasses} style={{backgroundColor:"#006aa3"}}>
            </div>
            <div className={panelClasses}>
              {prop.title ? (
                <div className={classes.timelineHeading} >
                    {prop.title}
                  {/* <Badge color={prop.titleColor}>{prop.title}</Badge> */}
                </div>
              ) : null}
              <div className={classes.timelineBody}>{prop.body}</div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

TimelineMini.propTypes = {
  classes: PropTypes.object.isRequired,
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  simple: PropTypes.bool,
  counter: PropTypes.bool,
};

export default withStyles(timelineminiStyle)(TimelineMini);
