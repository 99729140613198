import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeaderSimple from "components/Header/CardHeaderSimple"
// import AssignmentTurnedInIcon from '@material-ui/icons/Combine';
import CallMergeIcon from '@material-ui/icons/CallMerge';

import SendEmailButton from "components/Reports/SendEmailButton"
import DownloadButton from "components/Reports/DownloadButton"
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import CombinedAnalyticsTable from "tables/Visits/CombinedAnalyticsTable";
import CombinedScoresTable from "tables/Visits/CombinedScoresTable";
import { REPORT_ACTIONS } from '../../../constants';
import { getDefaultSubject, getDefaultBody } from '../../../utils/emails/defaultContent';
import { useTranslation } from 'react-i18next';

function CombinedScores({
  classes,
  analytics,
  createCombinedScoresReport,
  createCombinedScoresXLSReport,
  emailData,
  data,
  filteredKeyfields,
  filteredCategory,
  sendCombinedScoresPDFReport,
  sendCombinedScoresXLSReport,
}){

  const { t } = useTranslation("general_adaptation");

  const wrapPDFData = () => {
    return {
      // Analytics
      combinedAnalytics: {... analytics},
      // Combined Scores data
      combinedScores: {
        keyfields: filteredKeyfields,
        category: filteredCategory,
        data: data
      }
    };
  };

  const wrapXLSData = () => {
    return {
      // Combined Scores data
      combinedScores: {
        keyfields: filteredKeyfields,
        category: filteredCategory,
        data: data
      }
    };
  };

  const handlePDFReportClick = React.useCallback(() => {
    const payload = wrapPDFData();
    createCombinedScoresReport(payload);
  }, [ createCombinedScoresReport, analytics, data, filteredKeyfields, filteredCategory  ]);

  const handleXLSReportClick = React.useCallback(() => {
    const payload = wrapXLSData();
    createCombinedScoresXLSReport(payload);
  }, [ createCombinedScoresXLSReport, analytics, data, filteredKeyfields, filteredCategory]);

  const handlePDFEmailClick = React.useCallback((emailData) => {
    let payload = wrapPDFData();
    sendCombinedScoresPDFReport(payload, emailData);
  }, [ sendCombinedScoresPDFReport, analytics, data, filteredKeyfields, filteredCategory  ]);

  const handleXLSEmailClick = React.useCallback((emailData) => {
    let payload = wrapXLSData();
    sendCombinedScoresXLSReport(payload, emailData);
  }, [sendCombinedScoresXLSReport, analytics, data, filteredKeyfields, filteredCategory]);

  const defaultBody = {
    PDF: getDefaultBody(
      REPORT_ACTIONS.combinedScoresPDF,
      {
        keyfields: filteredKeyfields.map(({name}) => name).join(", "),
        category: filteredCategory ? filteredCategory.name : ''
      }
    ),
    XLS: getDefaultBody(
      REPORT_ACTIONS.combinedScoresXLS,
      {
        keyfields: filteredKeyfields.map(({name}) => name).join(", "),
        category: filteredCategory ? filteredCategory.name : ''
      }
    )
  };

  const defaultSubject = {
    PDF: getDefaultSubject(
      REPORT_ACTIONS.combinedScoresPDF,
      {
        visit: emailData.visit,
        operation: emailData.operation,
        startDate: emailData.startDate,
        endDate: emailData.endDate,
        category: filteredCategory ? filteredCategory.name : '',
      }
    ),
    XLS: getDefaultSubject(
      REPORT_ACTIONS.combinedScoresXLS,
      {
        visit: emailData.visit,
        operation: emailData.operation,
        startDate: emailData.startDate,
        endDate: emailData.endDate,
        category: filteredCategory ? filteredCategory.name : '',
      }
    )
  };

  return (
    <Card>
      <CardHeader color="info" text>
        <GridContainer>
          <GridItem lg={9} sm={10} xs={12}>
            {/* <CardText color="info">
              <h4 className={classes.cardTitle}>Indicadores Combinados</h4>
            </CardText> */}
            <CardHeaderSimple 
              titleStyle={{"color":"#000", "float": "left",  "margin-left":"15px","margin-top":"15px"}} 
              cardLeft={{ value: (<CallMergeIcon />) }} 
              title={t("combined_indicators")} />
          </GridItem>
          <GridItem lg={3} sm={2} xs={8}>
              <IconButton style={{"float":"right", "padding":"5px"}}>
                <DownloadButton
                    color="beepquest"
                    isReady={true}
                    size="md"
                    hasXLS={true}
                    onClickPDF={handlePDFReportClick}
                    onClickXLS={handleXLSReportClick}
                  />
              </IconButton>
              <IconButton style={{"float":"right", "padding":"5px"}}>
                <SendEmailButton
                    color="beepquest"
                    defaultBody={defaultBody}
                    defaultSubject={defaultSubject}
                    isReady={true}
                    hasXLS={true}
                    size="md"
                    sendPDFReport={handlePDFEmailClick}
                    sendXLSReport={handleXLSEmailClick}
                  />
              </IconButton>
          
            
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        {!!analytics &&
          <div className={classes.analyticsTable}>
            <CombinedAnalyticsTable
              data={analytics}
            />
          </div>
        }
        <CombinedScoresTable
          data={data}
          keyfields={filteredKeyfields}
          category={filteredCategory}
        />
      </CardBody>
    </Card>
  );
}

CombinedScores.propTypes = {
  analytics: PropTypes.object,
  createCombinedScoresReport: PropTypes.func.isRequired,
  createCombinedScoresXLSReport: PropTypes.func.isRequired,
  emailData: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  filteredKeyfields: PropTypes.array.isRequired,
  filteredCategory: PropTypes.object,
  sendCombinedScoresPDFReport: PropTypes.func.isRequired,
  sendCombinedScoresXLSReport: PropTypes.func.isRequired,
};

const styles = theme => ({
  analyticsTable:{
    // padding: "50px 100px"
    marginBottom:"20px"
  },
  noPadding: {
    padding: 0
  }
});

export default withStyles(styles)(CombinedScores);
