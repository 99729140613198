import React from 'react';
import PropTypes from 'prop-types';

// own components
import EngineAnswerFieldEditButton from "../EngineAnswerFieldEditButton";
import EngineAnswerFieldChangesLogView from "../EngineAnswerFieldChangesLogView";
import EngineAnswerFieldFooter from "../EngineAnswerFieldFooter";

// custom components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from "components/Card/CardFooter.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DivCenter from "components/styled-components/DivCenter";

// core components
import Collapse from "@material-ui/core/Collapse";

const DateFieldAnswerView = ({answerData, editable, changeToEditable,
                               toggleLogChangesView, logChangesViewMode, questionAnswerUpdateLogs}) => {
  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem xs={editable ? 10 : 12}>
            <DivCenter>
              <h5>{answerData.answer.DateFieldAnswer}</h5>
            </DivCenter>
          </GridItem>

          {editable ? (
            <GridItem xs={2}>
              <EngineAnswerFieldEditButton
                changeToEditable={() => changeToEditable(answerData)}
                toggleLogChangesView={() => toggleLogChangesView(answerData)}
              />
            </GridItem>
          ) : (
            []
          )}
          <Collapse in={logChangesViewMode}>
            <EngineAnswerFieldChangesLogView
              questionAnswerUpdateLogs={questionAnswerUpdateLogs}
            />
          </Collapse>
        </GridContainer>
      </CardBody>
      <CardFooter>
        <EngineAnswerFieldFooter answerData={answerData} />
      </CardFooter>
    </Card>
  );
};

DateFieldAnswerView.propTypes = {
  answerData: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  changeToEditable: PropTypes.func,
  toggleLogChangesView: PropTypes.func
};

export default DateFieldAnswerView;
