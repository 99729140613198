import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import {makeStyles} from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';
import RequisitionFormFK from '../../forms/ProcessRelationship/RequisitionForm';

const NewRequisitionConfigView = ({
  elemets,
  questionEngineModules,
  submitSuccess,
  requisition,
  disabled,
  editAction
}) => {
  const classes = useStyles();

  return (
    <GridContainer justify="center">
      <GridItem xs={8}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <DescriptionIcon />
            </CardIcon>
            <h4 className={classes.cardTitle}>
              {requisition ? requisition.name : "Nueva Requisición"}
            </h4>
          </CardHeader>
          <CardBody>
            <RequisitionFormFK
              requisition={requisition}
              elemets={elemets}
              questionEngineModules={questionEngineModules}
              submitSuccess={submitSuccess}
              disabled={disabled}
              editAction={editAction}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const useStyles = makeStyles(theme => ({
    cardTitle: {
      color: "#3C4858"
    },
    progress:{
      marginLeft: '50%',
      marginRight: '50%'
    },
    cardHeight:{
      height: "430px"
    },
  
  }));

export default NewRequisitionConfigView;