import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles from "@material-ui/core/styles/withStyles";

import Button from '../Button';
import DownloadButton from '../Reports/DownloadButton';
import SendEmailButton from '../Reports/SendEmailButton';
import PreviewButton from '../Reports/PreviewButton';

function ListButton({classes, label, items, enableButtons, handlePDFReportClick,
  handlePDFReportEmailClick, defaultBody, defaultSubject, handleXLSReportClick, handleXLSReportEmailClick}){
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const ListItems = items.map((item, idx) => 
    <ListItem>
      <ListItemIcon>{`${idx+1}.`}</ListItemIcon>  
      <ListItemText 
        primary={item}
      />
    </ListItem>
  );

  return(
    <>
      <GridContainer>
        <GridItem xs={9}>
          <Button
            size="sm"
            color="info"
            fullWidth
            onClick={handleOpen}
          >
            {label}
          </Button>
        </GridItem>
        {
          enableButtons.download &&
          <GridItem className={classes.actionButtonContainer} xs={3}>
          <SendEmailButton
            color="info"
            isReady={enableButtons.email}
            size="lg"
            sendPDFReport={handlePDFReportEmailClick}
            defaultBody={defaultBody}
            defaultSubject={defaultSubject}
            hasXLS={enableButtons.xls}
            sendXLSReport={handleXLSReportEmailClick}
          />
          <DownloadButton
            color="info"
            isReady={enableButtons.download}
            size="lg"
            hasXLS={enableButtons.xls}
            onClickPDF={handlePDFReportClick}
            onClickXLS={handleXLSReportClick}
          />
          {
            enableButtons.preview &&
            <PreviewButton
            color="info"
            isReady={enableButtons.preview}
            size="sm"
          />}
        </GridItem>
        }
      </GridContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">{label}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <List>
            {ListItems}
          </List>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const styles = theme => ({
  actionButtonContainer: {
    display: "flex",
    justifyContent: "space-evenly"
  },
  buttonNoMargin:{
    padding: "10px 0px"
  }
});

export default withStyles(styles)(ListButton);