import React, { useEffect, useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import { CircularProgress, Grid } from "@material-ui/core";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import RequisitionsAdminTable from '../../tables/ProcessRelationship/RequisitionsAdminTable';
import Button from "components/CustomButtons/Button.jsx";
import history from "../../utils/history";
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import useGlobal from '../../store';
import auth from '../../utils/auth';

const RequisitionsAdminView = ({
  requisitions,
  processRelationshipId,
  indicator,
  processRelationship,
  filters,
  setFilters,
  status,
  setStatus,
}) => {
  const { loading, data } = requisitions;
  const [process, setProcess] = useState(0);

  useEffect(() => {
    if (data) {
      const requsitonInProcess = data.filter(
        (requisition) => requisition?.processActivities?.elementProcess !== 100
      );

      setProcess(requsitonInProcess.length);
    }
  }, [loading]);
  
  const [session] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const hasPermission = (permission) => {
    if ( session.user && !auth.hasPermission(permission, session.permissions)) return false;
    return true;
  }; 

  return (
    <GridContainer justify="center">
      <GridItem xs={12}>
        <GridContainer justify="center">
          <GridItem lg={3} md={3} sm={8} xs={8}>
            <Card>
              <CardHeader>
                <GridContainer>
                  <GridItem xs={8}>
                    <p style={styles.cardTitle}>
                      Cantidad de Requisiciones Abiertas
                    </p>
                  </GridItem>
                  <Divider orientation="vertical" flexItem />
                  <GridItem xs={3}>
                    <h3 style={styles.title}>{process}</h3>
                  </GridItem>
                </GridContainer>
              </CardHeader>
            </Card>
          </GridItem>

          <GridItem lg={7} md={7} sm={10} xs={10} style={styles.progress}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <Typography
                  style={styles.textCenter}
                  variant="h5"
                  color="textSecondary"
                >{`AVANCES DE LA REQUISICIÓN EN TODO ${
                  processRelationship?.name?.toUpperCase() ?? ""
                }`}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  {Math.round(
                    ((indicator?.approvedRequisition ?? 0) * 12) / 100
                  ) > 0 ? (
                    <Grid item
                      xs={Math.round(
                        ((indicator?.approvedRequisition ?? 0) * 12) / 100
                      )}
                      spacing={0}
                    >
                      <CustomLineProgress
                        variant="determinate"
                        customColor="#6ab3d4"
                        value={100}
                        style={{
                          width: `100%`,
                          display: "inline-block",
                        }}
                      />
                      <Typography
                        style={styles.textCenter}
                        variant="h5"
                        color="textSecondary"
                      >{`Completadas: ${(
                        indicator?.approvedRequisition ?? 0
                      ).toFixed(2)}%`}</Typography>
                    </Grid>
                  ) : (
                    []
                  )}

                  {12 -
                    Math.round(
                      ((indicator?.approvedRequisition ?? 0) * 12) / 100
                    ) >
                  0 ? (
                    <Grid item
                      xs={
                        12 -
                        Math.round(
                          ((indicator?.approvedRequisition ?? 0) * 12) / 100
                        )
                      }
                      spacing={0}
                    >
                      <CustomLineProgress
                        variant="determinate"
                        customColor="#9ec5dd"
                        value={100}
                        style={{
                          width: `100%`,
                          display: "inline-block",
                        }}
                      />
                      <Typography
                        style={styles.textCenter}
                        variant="h5"
                        color="textSecondary"
                      >{`Abiertas: ${(
                        indicator?.processRequisition ?? 0
                      ).toFixed(2)}%`}</Typography>
                    </Grid>
                  ) : (
                    []
                  )}
                </Grid>
              </Grid>
            </Grid>
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <PlaylistAddCheckIcon />
            </CardIcon>
            <div style={styles.left}>
              <h4 style={styles.cardTitle}>Lista de Requisiciones</h4>
            </div>
            <div style={styles.right}>
              <Button
                id={"new-requisition"}
                size="sm"
                color="info"
                disabled={!hasPermission('requisitionCreated')}
                onClick={() =>
                  history.push(
                    `/objects/${processRelationshipId}/requisition/new`
                  )
                }
              >
                Nueva requisición
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {/* <DatesFiltersForm
                initialValues={filters}
                submitSuccess={filterChanged}
              /> */}
            {loading ? (
              <div style={styles.progressCnt}>
                <CircularProgress style={styles.progress} />
              </div>
            ) : (
              <RequisitionsAdminTable
                data={data}
                processRelationship={processRelationship}
                filters={filters}
                setFilters={setFilters}
                status={status}
                setStatus={setStatus}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const CustomLineProgress = ({text, ...res}) => {
  return (
    <BorderLinearProgress {...res} />
  );
}

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 40,
    //borderRadius: 7,
  },
  /* colorPrimary: {
    backgroundColor: '#d5d5d5',
  },
  */
  bar: props => ({
    //borderRadius: 5,
    //backgroundColor: '#6ab3d4',
    backgroundColor: props.customColor,
  }), 
}))(LinearProgress);

const styles = {
    cardTitle: {
      color: "#3C4858",
      fontSize: '18px',
      marginTop: "30px",
      marginBottom: "25px",
    },
    title: {
      color: "#3C4858",
      textDecoration: "none",
      fontWeight: "500",
      fontSize: '40px',
      marginTop: "30px",
      marginBottom: "25px",
      minHeight: "32px",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      textAlign: "center",
    },
    progress: {
      margin: "16px",
    },
    progressCnt: {
      width: "100px !important",
      marginLeft: "50%",
      marginRight: "50%",
    },
    cardHeight: {
      height: "430px",
    },
    left: {
      float: "left",
    },
    right: {
      float: "right",
    },
    textCenter: {
      textAlign: "center",
      paddingBottom: "15px"
    },
    progress: {
      margin: "16px",
    },
    progressCnt: {
      width: "100px !important",
      marginLeft: "50%",
      marginRight: "50%",
    },
    noPadding: {
      padding: '0 !important'
    }
  };

export default RequisitionsAdminView;
