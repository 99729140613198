import gql from "graphql-tag";

export const CREATE_CHECKLIST_REPORT = gql`
  mutation createProcessRelationship($data: ProcessRelationshipCreateInput!) {
    createProcessRelationship(data: $data) {
      _id
      name
    }
  }
`;

export const UPDATE_CHECKLIST_REPORT = gql`
  mutation updateProcessRelationship(
    $where: ProcessRelationshipWhereUniqueInput!
    $data: ProcessRelationshipCreateInput!
  ) {
    updateProcessRelationship(where: $where, data: $data) {
      _id
      name
    }
  }
`;

export const ARCHIVE_CHECKLIST_REPORT = gql`
  mutation archiveProcessRelationship(
    $where: ProcessRelationshipWhereUniqueInput!
  ) {
    archiveProcessRelationship(where: $where) {
      _id
      name
    }
  }
`;

export const CREATE_SO_COUNTER_REPORT = gql`
  mutation createSOCounterReport($data: SOCounterReportInput!) {
    createSOCounterReport(data: $data) {
      _id
      name
      periodicity
      initialDate
      finalDate
      indicators {
        type
        referenceId
        targetValue
      }
    }
  }
`;

export const CREATE_SO_CHECKLIST_REPORT = gql`
  mutation createSOChecklistReport($data: SOChecklistReportInput!) {
    createSOChecklistReport(data: $data) {
      _id
      name
      periodicity
      initialDate
      finalDate
      isVisit
      keyField
      visitId
      processRelationshipId
      targetValue
      archived
    }
  }
`;

export const UPDATE_SO_COUNTER_REPORT = gql`
  mutation updateSOCounterReport(
    $data: SOCounterReportInput!
    $where: SOCounterReportWhereUniqueInput!
  ) {
    updateSOCounterReport(data: $data, where: $where) {
      _id
      name
      periodicity
      initialDate
      finalDate
      indicators {
        type
        referenceId
        targetValue
      }
    }
  }
`;

export const ARCHIVE_SO_COUNTER_REPORT = gql`
  mutation archiveSOCounterReport(
    $data: SOCounterReportArchiveInput!
    $where: SOCounterReportWhereUniqueInput!
  ) {
    archiveSOCounterReport(data: $data, where: $where) {
      _id
      name
      periodicity
      initialDate
      finalDate
      indicators {
        type
        referenceId
        targetValue
      }
    }
  }
`;

export const GENERATE_SO_COUNTER_REPORT = gql`
  mutation generateSOCounterReport(
    $where: SOCounterReportWhereUniqueInput!
    $data: UserGenerationInput!
  ) {
    generateSOCounterReport(where: $where, data: $data) {
      processing
    }
  }
`;

export const UPDATE_SO_COUNTER_REPORT_CUSTOM_OBJECTIVES = gql`
  mutation updateSOCounterReportCustomIndicators(
    $data: [SOCounterReportCustomObjectivesInput!]!
    $where: SOCounterReportWhereUniqueInput!
  ) {
    updateSOCounterReportCustomIndicators(data: $data, where: $where) {
      _id
      name
      periodicity
      initialDate
      finalDate
      indicators {
        type
        referenceId
        targetValue
      }
      customIndicatorObjectives {
        indicatorId
        targetValue
        order
        proratedObjective {
          order
          result
          objective
          keyFieldAnswer
          userEmail
        }
      }
    }
  }
`;

export const UPDATE_SO_CHECKLIST_REPORT = gql`
  mutation updateSOChecklistReport(
    $data: SOChecklistReportInput!
    $where: SOChecklistReportWhereUniqueInput!
  ) {
    updateSOChecklistReport(data: $data, where: $where) {
      _id
      name
      periodicity
      initialDate
      finalDate
      isVisit
      keyField
      visitId
      processRelationshipId
      targetValue
      archived
    }
  }
`;

export const ARCHIVE_SO_CHECKLIST_REPORT = gql`
  mutation archiveSOChecklistReport(
    $data: SOChecklistReportArchiveInput!
    $where: SOChecklistReportWhereUniqueInput!
  ) {
    archiveSOChecklistReport(data: $data, where: $where) {
      _id
      name
      periodicity
      initialDate
      finalDate
      isVisit
      keyField
      visitId
      processRelationshipId
      targetValue
      archived
    }
  }
`;

export const UPDATE_SO_CHECKLIST_REPORT_CUSTOM_OBJECTIVES = gql`
  mutation updateSOCounterReportCustomIndicators(
    $data: [SOCounterReportCustomObjectivesInput!]!
    $where: SOCounterReportWhereUniqueInput!
  ) {
    updateSOCounterReportCustomIndicators(data: $data, where: $where) {
      _id
      name
      periodicity
      initialDate
      finalDate
      indicators {
        type
        referenceId
        targetValue
      }
      customIndicatorObjectives {
        indicatorId
        targetValue
        order
        proratedObjective {
          order
          result
          objective
          keyFieldAnswer
          userEmail
        }
      }
    }
  }
`;

export const CALCULATE_RANGE_RESULT = gql`
  mutation calculateIndicatorRangeResult(
    $data: SOCounterReportIndicatorCalculateWhereInput!
  ) {
    calculateIndicatorRangeResult(data: $data) {
      indicatorId
      order
      result
    }
  }
`;

export const CALCULATE_SO_CHECKLIST_RANGE_RESULT = gql`
  mutation calculateChecklistRangeResult(
    $data: SOChecklistReportRangeCalculateWhereInput!
  ) {
    calculateChecklistRangeResult(data: $data) {
      _id
      order
      results {
        keyAnswerName
        calculatedValue
      }
    }
  }
`;

export const GENERATE_SO_CHECKLIST_REPORT = gql`
  mutation generateSOChecklistReport(
    $where: SOChecklistReportWhereUniqueInput!
    $data: UserGenerationInput!
  ) {
    generateSOChecklistReport(where: $where, data: $data) {
      processing
    }
  }
`;
