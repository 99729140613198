import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import FiltersSidebar from "components/FiltersSidebar/FiltersSidebar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Button from '../../components/Button';
import SendEmailButton from "components/Reports/SendEmailButton";
import DownloadButton from "components/Reports/DownloadButton";

import history from '../../utils/history';
import RankingView from './KeyfieldDetails/RankingView';
import AverageAnalysisView from './KeyfieldDetails/AverageAnalysisView';
import { supervisionAnalysis } from '../../utils/analytics/averageAnalysis';
import { groupByKeyfields, reducedGroupedVisitsAnswers } from '../../utils/analytics/dataManipulation';
import { getDefaultSubject, getDefaultBody } from '../../utils/emails/defaultContent';
import { REPORT_ACTIONS } from '../../constants';

const VisitsDashboardKeyfieldItemView = ({
  classes, 
  activeVisitAnswer, 
  createAverageAnalysisReport,
  createKeyfieldDashboardReport,
  createRankingsReport,
  createAverageAnalysisXLSReport,
  createRankingsXLSReport,
  emailData,
  keyfield, 
  categories, 
  items, 
  sendAverageAnalysisPDFReport,
  sendAverageAnalysisXLSReport,
  sendKeyfieldDashboardPDFReport,
  sendRankingsPDFReport,
  sendRankingsXLSReport,
  visits, 
  visitAnswers, 
  operators,
  closedVisitAnswer
}) => {
  const rankingsData = React.useRef();
  const averageAnalysisData = React.useRef();

  const {uniqueKeyfields, totalSupervisions, averageSupervisions} = supervisionAnalysis(items);
  
  const groupedIndex = groupByKeyfields(items, [keyfield]);
  const groupedItems = reducedGroupedVisitsAnswers(groupedIndex, items);

  const { loadingVisitAnswersClosedCount, dataVisitanswerClosedCount } = closedVisitAnswer;

  let closedCount = null;
  if (!loadingVisitAnswersClosedCount && dataVisitanswerClosedCount) {
    const { visitAnswerConnection } = dataVisitanswerClosedCount;
    if (visitAnswerConnection) {
      const { aggregate } = visitAnswerConnection;
      closedCount = aggregate.count;
    }
  }

  const { loadingVisitAnswersClosed, dataVisitAnswer } = visitAnswers;

  const wrapAverageAnalysisPDFData = () => {
    return { 
      averageAnalysis: {
        keyfield: {
          name: keyfield.name,
          value: keyfield.value
        },
        ...averageAnalysisData.current
      }
    };
  };

  const wrapAverageAnalysisXLSData = () => {
    return { 
      averageAnalysis: {
        keyfield: {
          name: keyfield.name,
          value: keyfield.value
        },
        ...averageAnalysisData.current
      }
    };
  };

  const wrapKeyfieldDashboardPDFData = () => {
    return {
      keyfield: {
        name: keyfield.name,
        value: keyfield.value
      },
      analytics: {
        uniqueKeyfields: uniqueKeyfields,
        totalSupervisions: totalSupervisions,
        averageSupervisions: averageSupervisions
      },
      averageAnalysis: {
        ...averageAnalysisData.current
      },
      rankings: {
        ...rankingsData.current,
      },
    };
  };

  const wrapRankingsPDFData = () => {
    return {
      rankings: {
        keyfield: {
          name: keyfield.name,
          value: keyfield.id
        },
        ...rankingsData.current,
      },
    };
  };

  const wrapRankingsXLSData = () => {
    return {
      rankings: {
        keyfield: {
          name: keyfield.name,
          value: keyfield.id
        },
        ...rankingsData.current,
      },
    };
  };

  const handleAverageAnalysisPDFReportClick = React.useCallback(() => {
    let payload = wrapAverageAnalysisPDFData();
    createAverageAnalysisReport(payload);
  }, [ createAverageAnalysisReport, averageAnalysisData, keyfield ]);

  const handleKeyfieldDashboardPDFReportClick = React.useCallback(() => {
    let payload = wrapKeyfieldDashboardPDFData();
    createKeyfieldDashboardReport(payload);
  }, [ 
    createKeyfieldDashboardReport, averageSupervisions,
    keyfield, rankingsData, uniqueKeyfields, totalSupervisions,  
  ]);
  
  const handleRankingsPDFReportClick = React.useCallback(() => {
    let payload = wrapRankingsPDFData();
    createRankingsReport(payload);
  }, [ createRankingsReport, keyfield, rankingsData ]);

  const handleAverageAnalysisXLSReportClick = React.useCallback(() => {
    let payload = wrapAverageAnalysisXLSData();
    createAverageAnalysisXLSReport(payload);
  }, [ createAverageAnalysisXLSReport, averageAnalysisData, keyfield]);

  const handleRankingsXLSReportClick = React.useCallback(() => {
    let payload = wrapRankingsXLSData();
    createRankingsXLSReport(payload);
  }, [ createRankingsXLSReport, keyfield, rankingsData]);

  const handleAverageAnalysisPDFReportEmailClick = React.useCallback((emailData) => {
    let payload = wrapAverageAnalysisPDFData();
    sendAverageAnalysisPDFReport(payload, emailData);
  }, [ sendAverageAnalysisPDFReport, averageAnalysisData, keyfield]);

  const handleAverageAnalysisXLSReportEmailClick = React.useCallback((emailData) => {
    let payload = wrapAverageAnalysisXLSData();
    sendAverageAnalysisXLSReport(payload, emailData);
  }, [ sendAverageAnalysisXLSReport, averageAnalysisData, keyfield]);

  const handleKeyfieldDashboardPDFReportEmailClick = React.useCallback((emailData) => {
    let payload = wrapKeyfieldDashboardPDFData();
    sendKeyfieldDashboardPDFReport(payload, emailData);
  }, [ 
    sendKeyfieldDashboardPDFReport, averageSupervisions,
    keyfield, rankingsData, uniqueKeyfields, totalSupervisions,  
  ]);
  
  const handleRankingsPDFReportEmailClick = React.useCallback((emailData) => {
    let payload = wrapRankingsPDFData();
    sendRankingsPDFReport(payload, emailData);
  }, [ sendRankingsPDFReport, keyfield, rankingsData ]);

  const handleRankingsXLSReportEmailClick = React.useCallback((emailData) => {
    let payload = wrapRankingsXLSData();
    sendRankingsXLSReport(payload, emailData);
  }, [ sendRankingsXLSReport, keyfield, rankingsData ]);

  const defaultAverageAnalysisBody = {
    PDF: getDefaultBody( REPORT_ACTIONS.averageAnalysisPDF, 
      { keyfield: keyfield.name, visit: emailData.visit} ),
    XLS: getDefaultBody( REPORT_ACTIONS.averageAnalysisXLS,
      { keyfield: keyfield.name, visit: emailData.visit} )
  };

  const defaultAverageAnalysisSubject = {
    PDF: getDefaultSubject( REPORT_ACTIONS.averageAnalysisPDF,
      { keyfield: keyfield.name, visit: emailData.visit } ),
    XLS: getDefaultSubject( REPORT_ACTIONS.averageAnalysisXLS,
      { keyfield: keyfield.name, visit: emailData.visit } )
  };

  const defaultKeyfieldDashboardSubject = {
    PDF: getDefaultSubject( REPORT_ACTIONS.keyfieldDashboardPDF, 
      { keyfield: keyfield.name, visit: emailData.visit,
        startDate: emailData.startDate, endDate: emailData.endDate })
  };

  const defaultKeyfieldDashboardBody = {
    PDF: getDefaultBody( REPORT_ACTIONS.keyfieldDashboardPDF, 
      { keyfield: keyfield.name, visit: emailData.visit } )
  };

  return (
    <GridContainer>
      <GridItem xs={3}>
        <FiltersSidebar
          activeVisitAnswer={activeVisitAnswer}
          closedVisitAnswer={closedCount}
          visits={visits}
          visitAnswers={visitAnswers}
          operators={operators}
        />
      </GridItem>
      <GridItem xs={9}>
          <Button
            simple
            size="lg"
            color="info"
            onClick={history.goBack}
            className={classes.buttonNoMargin}
          >
            <ArrowBackIcon/><span>Volver al tablero general</span>
          </Button>
        <Card className={classes.cardLessBorder}>
          <CardHeader color="info" text>
            <GridContainer>
              <GridItem xs={10}>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>{keyfield.name}</h4>
                </CardText>
              </GridItem>
              <GridItem xs={1}>
                <CardText color="info" className={classes.noPadding}>
                  <SendEmailButton
                    color="white"
                    defaultBody={defaultKeyfieldDashboardBody}
                    defaultSubject={defaultKeyfieldDashboardSubject}
                    isReady={true}
                    sendPDFReport={handleKeyfieldDashboardPDFReportEmailClick}
                    hasXLS={false}
                    size="lg"
                  />
                </CardText>
              </GridItem>
              <GridItem xs={1}>
                <CardText color="info" className={classes.noPadding}>
                  <DownloadButton
                    color="white"
                    isReady={true}
                    size="lg"
                    hasXLS={false}
                    onClickPDF={handleKeyfieldDashboardPDFReportClick}
                  />
                </CardText>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={6}>
                <h4 className={classes.cardTitle}>{keyfield.name} Supervisadas: {uniqueKeyfields}</h4>
                <h4 className={classes.cardTitle}>Total Supervisiones: {totalSupervisions}</h4>
              </GridItem>
              <GridItem xs={6}>
                <h4 className={classes.cardTitle}>Promedio cantidad supervisiones por {keyfield.name}: {averageSupervisions.toFixed(2)}</h4>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <GridContainer>
          <GridItem xs={8}>
            <AverageAnalysisView
              defaultBody={defaultAverageAnalysisBody}
              defaultSubject={defaultAverageAnalysisSubject}
              items={groupedItems}
              keyfield={keyfield}
              handlePDFReportClick={handleAverageAnalysisPDFReportClick}
              handleXLSReportClick={handleAverageAnalysisXLSReportClick}
              handlePDFReportEmailClick={handleAverageAnalysisPDFReportEmailClick}
              handleXLSReportEmailClick={handleAverageAnalysisXLSReportEmailClick}
              averageAnalysisData={averageAnalysisData}
            />
          </GridItem>
          <GridItem xs={4}>
            <RankingView
              categories={categories}
              items={groupedItems}
              keyfield={keyfield}
              handlePDFReportClick={handleRankingsPDFReportClick}
              handleXLSReportClick={handleRankingsXLSReportClick}
              handlePDFReportEmailClick={handleRankingsPDFReportEmailClick}
              handleXLSReportEmailClick={handleRankingsXLSReportEmailClick}
              rankingsData={rankingsData}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

VisitsDashboardKeyfieldItemView.propTypes = {
  activeVisitAnswer: PropTypes.object.isRequired,
  createAverageAnalysisReport: PropTypes.func.isRequired,
  createKeyfieldDashboardReport: PropTypes.func.isRequired,
  createAverageAnalysisXLSReport: PropTypes.func.isRequired,
  createRankingsXLSReport: PropTypes.func.isRequired,
  createRankingsReport: PropTypes.func.isRequired,
  emailData: PropTypes.object.isRequired,
  sendAverageAnalysisPDFReport: PropTypes.func.isRequired,
  sendAverageAnalysisXLSReport: PropTypes.func.isRequired,
  sendKeyfieldDashboardPDFReport: PropTypes.func.isRequired,
  sendRankingsPDFReport: PropTypes.func.isRequired,
  sendRankingsXLSReport: PropTypes.func.isRequired,
  visits: PropTypes.array.isRequired,
  visitAnswers: PropTypes.object.isRequired,
  operators: PropTypes.array.isRequired,
};

const styles = theme => ({
  buttonNoMargin:{
    padding: "10px 0px"
  },
  cardLessBorder: {
    marginBottom: "0px"
  },
  center: {
    textAlign: "center"
  },
  noPadding: {
    padding: 0
  }
});

export default withStyles(styles)(VisitsDashboardKeyfieldItemView);
