import React from 'react';
import PropTypes from "prop-types";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import TimelineMini from "components/Timeline/TimelineMini.jsx";
import moment from "moment";
import Button from "components/CustomButtons/Button.jsx";
import {useTranslation} from 'react-i18next';
import Badge from "components/Badge/Badge";

// @material-ui/icons
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { CircularProgress } from '@material-ui/core';
import history from '../../../utils/history';

const LastEvents = ({ infoEvents }) => {
  const { loading, events } = infoEvents;
  const { t, } = useTranslation('history'); 
  const { t:tt } = useTranslation("general_adaptation");

  let stories= [];
  if (events.allEvent) {
    events.allEvent.map(event => {
      stories.push({
        inverted: true,
        badgeColor: "info",
        title: moment(event.created.value)
          .local()
          .format("DD-MM-YY : hh:mm A"),
        body: (
          <div>
            {`${t(`history.elements.${event.feature}`)} - ${t(
              `history.event.${event.event}`
            )}: `}{" "}
            <Badge color="info">{event.elementName}</Badge> - {event.userName}
          </div>
        )
      });
    });
  }

  return (
    <Card style={style.cardHeight}>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <AssignmentTurnedInIcon  />
        </CardIcon>
        <div style={style.left}>
          <h4 style={style.cardTitle}>{tt("lastevents.title")}</h4></div>
        <div  style={style.right}>
          <Button size="sm" color="beepquest" onClick={() => history.push(`/history`)}>{tt("lastevents.subtitle")}</Button>
        </div>
      </CardHeader>
      <CardBody style={style.scrollVertical}>
        {/* <TimelineMini stories={lastEvents} simple={true} counter={false}/> */}
        {loading ? (
            <CircularProgress style={style.progress} />
          ) : (
            <TimelineMini stories={events.allEvent ? stories : []} simple={true} counter={false} />
          )}
      </CardBody>
    </Card>
  );
};

LastEvents.propTypes= {
  infoEvents: PropTypes.object.isRequired,
}

const style = {
  cardTitle: {
    color: "#3C4858"
  },
  progress:{
    marginLeft: '50%',
    marginRight: '50%'
  },
  cardHeight:{
    height: "430px"
  },
  scrollVertical: {
    overflowY: 'scroll'
  },
  left:{
    float: 'left'
  },
  right:{
    float: 'right'
  }
};

export default LastEvents;
