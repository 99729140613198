import React from "react";
import CreateUpdateVisitSchedulingForm from "../../forms/visitScheduling/CreateUpdateVisitSchedulingForm";
import {VISIT_SCHEDULING_BASE_MODELS} from "./gql/Queries";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {CircularProgress} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import {CREATE_VISIT_SCHEDULING} from "./gql/Mutation";

import useGlobal from "../../store";
import history from "utils/history";

const VisitSchedulingNew = () => {
  const { t } = useTranslation(["visitScheduling", "common"])
  const [session, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const {
    data: baseModels,
    loading: loadingBaseModels,
    error: errorBaseModels,
  } = useQuery(VISIT_SCHEDULING_BASE_MODELS, {
    variables: {
      app: session.user ? session.appRef._id : ""
    },
    fetchPolicy: "nework-only"
  });

  const [createVisitScheduling] = useMutation(CREATE_VISIT_SCHEDULING, {
    onCompleted: (mutationData) => {
      console.log("mutationData", mutationData);
      history.push("/visitScheduling")
    }
  });

  if (loadingBaseModels) {
    return (
      <div style={style.progressCnt}>
        <CircularProgress style={style.progress} />
      </div>
    );
  } else {
    if(errorBaseModels) {
      return (
        <div>
          <Alert variant="outlined" severity="error">
            {t('common:common.error.title')}
          </Alert>
        </div>
      );
    }
    const {
      userMobiles,
      visits,
      segments,
    } = baseModels;
    return (<CreateUpdateVisitSchedulingForm
      app={session.user ? session.appRef._id : ""}
      userMobiles={userMobiles}
      visits={visits}
      segments={segments}
      submitSuccess={(values) => {
        console.log("submitSuccess");
        console.log(values);
        createVisitScheduling({
          variables: {
            data: {
              app: session.user ? session.appRef._id : "",
              ...values
            }
          }
        });
      }}
    />);
  }
};

const style = {
  progress: {
    margin: "16px",
  },
  progressCnt: {
    width: "100px !important",
    marginLeft: "50%",
    marginRight: "50%",
  },
};

export default VisitSchedulingNew;
