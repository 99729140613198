import React, { useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import { CircularProgress, Grid } from "@material-ui/core";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import RequisitionsTable from '../../tables/ProcessRelationship/RequisitionsTable';
import RequisitionDetailView from './RequisitionDetailView';
import Drawer from "@material-ui/core/Drawer";
import Button from '../../components/Button';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import Datetime from "react-datetime";
import { InputLabel, TextField } from "@material-ui/core";

const RequisitionsView = ({
  requisitions,
  processRelationshipId,
  indicator,
  processAuthorization,
  requisition,
  filters,
  setFilters,
  status,
  setStatus,
  showArchived,
  setShowArchived,
  generateReport,
  refetch
}) => {
  const { loading, data } = requisitions;
  const [currentRightView, setCurrentRightView] = useState({
    open: false,
    requisition: null,
    view: ""
  });

  const [open, setOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});

  const sizeApprobe = Math.round(
    ((indicator?.approvedRequisition ?? 0) * 12) / 100
  );

  const sizeCompleted = Math.round(
    ((indicator?.completedRequisition ?? 0) * 12) / 100
  );

  const sizePending = Math.round(
    ((indicator?.pendingRequisition ?? 0) * 12) / 100
  );

  const closeDialog = () => {
    setOpen(false);
  };

  const handleArchive = () => {
    setOpen(false);
    const selectedIds = Object.keys(checkedItems).filter(key => checkedItems[key]);

    const selectedItems = data.filter(item => selectedIds.includes(String(item._id)));
    
    selectedItems.map((activity) => {
        processAuthorization(activity._id, {
        status: activity.status,
        archived: !activity.archived
      });
    });
  };

  const hasSelectedItems = Object.keys(checkedItems).filter(id => checkedItems[id]).length > 0;

  return (
    <div>
      <GridContainer justify="center">
      <GridItem xs={12}>
        <GridContainer justify="center">
          <GridItem lg={3} md={3} sm={8} xs={8}>
            <Card>
              <CardHeader>
                <GridContainer>
                  <GridItem xs={8} style={styles.cardTitle}>
                    <p>
                      Cantidad de Elementos { showArchived ? "Archivados" : "Abiertas"}
                    </p>
                  </GridItem>
                  <Divider orientation="vertical" flexItem />
                  <GridItem xs={3}>
                    <h3 style={styles.title}>
                      {indicator?.openRequisition ?? 0}
                    </h3>
                  </GridItem>
                </GridContainer>
              </CardHeader>
            </Card>
          </GridItem>

          <GridItem lg={7} md={7} sm={12} xs={12} style={styles.progress}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <Typography
                  style={styles.textCenter}
                  variant="h5"
                  color="textSecondary"
                >{`AVANCES DE LA REQUISICIÓN ${
                  requisition?.name?.toUpperCase() ?? ""
                }`}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  {sizeApprobe > 0 ? (
                    <Grid item xs={sizeApprobe} spacing={0}>
                      <CustomLineProgress
                        variant="determinate"
                        customColor="#6ab3d4"
                        value={100}
                        style={{
                          width: `100%`,
                          display: "inline-block",
                        }}
                      />
                      <Typography
                        style={styles.textCenter}
                        variant="h5"
                        color="textSecondary"
                      >{`Completadas: ${(
                        indicator?.approvedRequisition ?? 0
                      ).toFixed(2)}%`}</Typography>
                    </Grid>
                  ) : (
                    []
                  )}

                  {sizeCompleted > 0 ? (
                    <Grid
                      item
                      xs={sizeCompleted}
                      spacing={0}
                    >
                      <CustomLineProgress
                        variant="determinate"
                        customColor="#9ec5dd"
                        value={100}
                        style={{
                          width: `100%`,
                          display: "inline-block",
                        }}
                      />
                      <Typography
                        style={styles.textCenter}
                        variant="h5"
                        color="textSecondary"
                      >{`Por Aprobar: ${(
                        indicator?.completedRequisition ?? 0
                      ).toFixed(2)}%`}</Typography>
                    </Grid>
                  ) : (
                    []
                  )}

                  {sizePending > 0 ? (
                    <Grid item xs={sizePending} spacing={0}>
                      <CustomLineProgress
                        variant="determinate"
                        customColor="#ecf8ff"
                        value={100}
                        style={{
                          width: `100%`,
                          display: "inline-block",
                        }}
                      />
                      <Typography
                        style={styles.textCenter}
                        variant="h5"
                        color="textSecondary"
                      >{`Pendientes: ${(
                        indicator?.pendingRequisition ?? 0
                      ).toFixed(2)}%`}</Typography>
                    </Grid>
                  ) : (
                    []
                  )}
                </Grid>
              </Grid>
            </Grid>
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <PlaylistAddCheckIcon />
            </CardIcon>
            <div style={styles.left}>
              <h4 style={styles.cardTitle}>Lista de Requisiciones</h4>
            </div>
            <div style={styles.right}>
              <Button
                color={"success"}
                onClick={() => {
                  const dateRange = {
                    from: moment().startOf('day').subtract(30, 'days'),
                    to: moment().endOf('day')
                  }
                  
                  generateReport({
                    ...dateRange
                  })
                }}>
                Generar reporte
              </Button>
            <Button
                style={ styles.archiveButton }
                disabled={ hasSelectedItems === false }
                justIcon
                color="info"
                onClick={() => { setOpen(true) }}>
                <Tooltip title={ showArchived ? "Activar" : "Archivar"}>
                  {showArchived ?<UnarchiveIcon /> : <ArchiveIcon/>}
                </Tooltip>
              </Button>
              <Button 
                color={showArchived ? "success" : "danger"}
                onClick={() => {
                  setShowArchived(!showArchived);
                  setFilters({...filters, archived: !showArchived})
                  setCheckedItems({})
                
                }}> {showArchived ? "Mostrar activas": "Mostrar archivadas"}</Button>
            </div>
          </CardHeader>
          <CardBody>
            {/* <DatesFiltersForm
                  initialValues={filters}
                  submitSuccess={filterChanged}
                /> */}
            {loading ? (
              <div style={styles.progressCnt}>
                <CircularProgress style={styles.progress} />
              </div>
            ) : (
              <RequisitionsTable
                data={data}
                processAuthorization={processAuthorization}
                setCurrentRightView={setCurrentRightView}
                filters={filters}
                setFilters={setFilters}
                status={status}
                setStatus={setStatus}
                showArchived={showArchived}
                setShowArchived={setShowArchived}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
              />
            )}

            <React.Fragment>
              <Drawer
                variant="temporary"
                anchor={"right"}
                open={currentRightView.open}
                onClose={() =>
                  setCurrentRightView({ ...currentRightView, open: false })
                }
              >
                <div style={{ minWidth: 450, maxWidth: 450 }}>
                  <RequisitionDetailView
                    requisition={currentRightView.requisition}
                    requisitionHeader={currentRightView?.row || null}
                    processAuthorization={processAuthorization}
                    callbackApproval={() => {
                      setCurrentRightView({ ...currentRightView, open: false })
                    }}
                  />
                </div>
              </Drawer>
            </React.Fragment>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
      <Dialog
        open={open}
        onClose={closeDialog}>
        <DialogTitle>
          Archivar
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estas seguro de { showArchived ? "activar" : " archivar"} los elementos seleccionados?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={ handleArchive } color="info">
            {showArchived ? "Activar" : "Archivar"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const CustomLineProgress = ({text, ...res}) => {
  return <BorderLinearProgress {...res} />;
}

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 40,
      //borderRadius: 7,
    },
    /* colorPrimary: {
      backgroundColor: '#d5d5d5',
    },
    */
    bar: props => ({
      //borderRadius: 5,
      //backgroundColor: '#6ab3d4',
      backgroundColor: props.customColor,
    }),
  }))(LinearProgress);

const styles = {
    cardTitle: {
      color: "#3C4858",
      fontSize: '17px',
      textAlign: "center",
      alignContent: "center",
    },
    title: {
      color: "#3C4858",
      textDecoration: "none",
      fontWeight: "500",
      fontSize: '40px',
      marginTop: "30px",
      marginBottom: "25px",
      minHeight: "32px",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      textAlign: "center",
    },
    progress: {
      margin: "16px",
    },
    progressCnt: {
      width: "100px !important",
      marginLeft: "50%",
      marginRight: "50%",
    },
    cardHeight: {
      height: "430px",
    },
    left: {
      float: "left",
    },
    right: {
      float: "right",
    },
    textCenter: {
      textAlign: "center",
      paddingBottom: "15px"
    },
    progress: {
      margin: "16px",
    },
    progressCnt: {
      width: "100px !important",
      marginLeft: "50%",
      marginRight: "50%",
    },
    archiveButton: {
      textAlign: "center",
      alignContent: "center",
    }
};

export default RequisitionsView;
