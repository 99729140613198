import React from 'react';
import { PROCESS_RELATIONSHIP  } from './gql/Queries';
import { useQuery } from "react-apollo-hooks";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_WORK_PLAN_PENALTY, DELETE_WORK_PLAN_PENALTY, UPDATE_WORK_PLAN_PENALTY } from './gql/Mutations';
import PenaltiesWorkPlanView from '../../Views/ProcessRelationship/PenaltiesWorkPlanView';


const PenaltiesWorkPlan = ({ processRelationship }) => {
    const processRelationshipId = processRelationship?._id;

    const {
        data: dataProcessRelationship,
        loading: loadingProcessRelationship,
        error: errorProcessRelationship,
        refetch
      } = useQuery(PROCESS_RELATIONSHIP, {
        variables: {
          _id: processRelationshipId,
        },
        fetchPolicy: "network-only",
      });

      const [createPenaltyConfig] = useMutation(CREATE_WORK_PLAN_PENALTY, {
        onCompleted: () => {
          refetch();
        },
        onError: (error) => {
            console.log(error)
        }
      });
  
      const [updatePenaltyConfig] = useMutation(UPDATE_WORK_PLAN_PENALTY, {
          onCompleted: () => {
            refetch();
          },
          onError: (error) => {
              console.log(error)
          }
      });
  
      const [deletePenaltyConfig] = useMutation(DELETE_WORK_PLAN_PENALTY, {
        onCompleted: () => {
          refetch();
        },
        onError: (error) => {
          console.log(error);
        },
      });

      return (
        <PenaltiesWorkPlanView
          processRelationship={{
            loading: loadingProcessRelationship,
            data: dataProcessRelationship?.getProcessRelationship ?? {},
          }}
          createPenalty={createPenaltyConfig}
          updatePenaltyConfig={updatePenaltyConfig}
          deletePenaltyConfig={deletePenaltyConfig}
        />
      );
}

export default PenaltiesWorkPlan;