import React from 'react';
import PropTypes from 'prop-types';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import DivCenter from '../../components/styled-components/DivCenter';
import DescriptionIcon from '@material-ui/icons/Description';

const TechManualView = ({ manuals }) => {
    return (
      <GridContainer>
        {manuals.map(manual => (
          <GridItem xs={6} key={manual._id}>
            <Card>
              <CardBody>
                <a style={style.linkColor} href={manual.url} target="_blank">
                  <DivCenter>
                    <DescriptionIcon style={style.iconSize} />
                  </DivCenter>
                  <DivCenter style={style.titleSize}>
                    { manual.name }
                  </DivCenter>
                </a>
              </CardBody>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    );
};

TechManualView.propTypes = {
  manuals: PropTypes.array.isRequired,
}

const style= {
    iconSize: {
        fontSize: 90
    },
    titleSize: {
        fontSize: 20
    },
    linkColor: {
      color: 'inherit'
    }
}

export default TechManualView;
