import React, { useMemo } from "react";
import {useTranslation} from 'react-i18next';
import {useQuery, useMutation} from "react-apollo-hooks";
import history from "utils/history";
import Moment from 'moment';

// Own components
import LoadingCircleView from "../../Views/LoadingCircleView";
import VisitsDashboardItemView from "../../Views/Visits/VisitsDashboardItemView";
import useGlobal from "../../store";
import { VISIT_ITEMS, VISIT_ANSWER_ITEMS, VISIT_ANSWER_COUNT } from "./gql/Queries";
import { 
  CREATE_COMBINED_SCORES_REPORT_MUTATION, 
  CREATE_COMBINED_SCORES_XLS_REPORT_MUTATION 
} from "./gql/Mutations";
import { 
  getOperationsFromColumns, 
  getKeyfields, 
  getCategories, 
  hasGlobalOperation, 
  parseVisitAnswers, 
  classifyAndFilter 
} from "../../utils/analytics/dataManipulation";
import { 
  reportCreationFailedNotification, 
  reportCreationOnProgressNotification,
  reportSentNotification,
  reportSentFailedNotification,
} from "../../utils/snackNotifications";
import auth from '../../utils/auth';

function VisitsDashboardItem({match}) {
  const [session, globalActions] = useGlobal(
    state => state.session,
    actions => actions
  );
  
  const [filters, filtersActions] = useGlobal(
    state => state.filters,
    actions => actions.filters
  );
  
  const { t, } = useTranslation('common');

  const visitID = match.params.idVisit === ":idVisit" ? null : match.params.idVisit;

  const {data, loading} = useQuery(VISIT_ITEMS, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : '',
        hasChecklistConfiguration: true,
        isArchived: false
      }
    },
    fetchPolicy: 'network-only'
  }); 

  const {
    data: dataVisitAnswers, 
    loading: loadingVisitAnswers
  } = useQuery(VISIT_ANSWER_ITEMS, {
      variables: {
        where: {
          userId: session.user._id,
          app: session.appId,
          visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
          checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
          checkInDate_lte: filters.range.endDate,
          approvalStatus_not_in: ["Rejected", "Excluded"],
          hasCheckOutDate: true,
          session: auth.getUserSessionData(session)
        }
      },
      skip: !filters.selectedVisitID,
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    });
  
    const {
      data: dataVisitAnswersOpen, 
      loading: loadingVisitAnswersOpen
    } = useQuery(VISIT_ANSWER_COUNT, {
        variables: {
          where: {
            userId: session.user._id,
            app: session.appId,
            visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
            checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
            checkInDate_lte: filters.range.endDate,
            approvalStatus_not_in: ["Rejected", "Excluded"],
            hasCheckOutDate: false
          }
        },
        skip: !filters.selectedVisitID,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      });

  const {
      data: dataVisitAnswersClosed, 
      loading: loadingVisitAnswersClosed
    } = useQuery(VISIT_ANSWER_COUNT, {
        variables: {
          where: {
            userId: session.user._id,
            app: session.appId,
            visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
            checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
            checkInDate_lte: filters.range.endDate,
            approvalStatus_not_in: ["Rejected", "Excluded"],
            hasCheckOutDate: true
          }
        },
        skip: !filters.selectedVisitID,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      });
    
  // Add General Data required to all the mutation calls
  const addGeneralData = (payload, emailData) => {
    let data = { query: { 
      visit: filters.selectedVisitID, 
      operation: filters.selectedOperation, 
      ...payload
    }};
    data.visitId = filters.selectedVisitID?  filters.selectedVisitID.value : '';
    data.dateRange = {
      startDate: filters.range.startDate.format("DD-MM-YYYY"),
      endData: Moment(filters.range.endDate).format("DD-MM-YYYY")
    };
    data.app = session.user ? session.appRef._id : '';
    data.session = session;

    if(!!emailData){
      data.sendByEmail = true;
      data.emailInput = {
        contentHtml: emailData.contentHtml,
        subject: emailData.subject,
        to: emailData.to
      }
    }

    return data;
  };

  // Execute the mutation and trigger the notifications
  const executeMutation = (mutation, data, isEmail = false) => {
    mutation({ variables: { data: data }}).then(
      result => {
        if(isEmail) reportSentNotification(globalActions);
        else reportCreationOnProgressNotification(globalActions);
      }, 
      error => {
        console.log(error, data)
        if(isEmail) reportSentFailedNotification(globalActions);
        else reportCreationFailedNotification(globalActions);
      });
  };

  // Initilize the mutations
  const createCombinedScoresReportMutation = useMutation(CREATE_COMBINED_SCORES_REPORT_MUTATION);
  const createCombinedScoresXLSReportMutation = useMutation(CREATE_COMBINED_SCORES_XLS_REPORT_MUTATION);

  // Mutation calls
  const createCombinedScoresReport = React.useCallback((payload) => {
    const data = addGeneralData(payload);
    executeMutation(createCombinedScoresReportMutation, data); 
  }, [ createCombinedScoresReportMutation, filters, session, Moment ]);

  const createCombinedScoresXLSReport = React.useCallback((payload) => {
    const data = addGeneralData(payload);
    executeMutation(createCombinedScoresXLSReportMutation, data); 
  }, [ createCombinedScoresXLSReportMutation, filters, session, Moment ]);

  const sendCombinedScoresPDFReport = React.useCallback((payload, emailData) => {
    const data = addGeneralData(payload, emailData);
    executeMutation(createCombinedScoresReportMutation, data, true); 
  }, [ createCombinedScoresReportMutation, filters, session, Moment ]);

  const sendCombinedScoresXLSReport = React.useCallback((payload, emailData) => {
    const data = addGeneralData(payload, emailData);
    executeMutation(createCombinedScoresXLSReportMutation, data, true); 
  }, [ createCombinedScoresXLSReportMutation, filters, session, Moment ]);

  const filteredDataVisitAnswers = dataVisitAnswers && dataVisitAnswers.visitAnswers ? 
    dataVisitAnswers.visitAnswers.filter(visitAnswer => visitAnswer) : [];

  // If the store is empty or different, use the visitID from the route params
  if(data.visits && (
      (!!visitID && !filters.selectedVisitID) || 
      (!!visitID && visitID !== filters.selectedVisitID.value))){
    const option = data.visits.find((visit) => visitID === visit._id);
    filtersActions.setVisitID({label: option.name, value: option._id});
  }

  // Select by default the first visit ID
  if(!visitID && !filters.selectedVisitID && data.visits && data.visits.length > 0) {
    const option = data.visits[0];
    history.push(`/visits/${option._id}/dashboard/`);
  }

  // Select by default the first visit ID
  if(!visitID && filters.selectedVisitID) {
    history.push(`/visits/${filters.selectedVisitID.value}/dashboard/`);
  }

  /* if (loading || loadingVisitAnswers) {
    return <LoadingCircleView/>
  } else { */
    const selectedVisit = data.visits ? 
      data.visits.find((visit) => 
        filters.selectedVisitID && 
        filters.selectedVisitID.value === visit._id) 
      : null;

    const operators = 
      selectedVisit && selectedVisit.checkListConfiguration 
      && selectedVisit.checkListConfiguration.columns ? 
        getOperationsFromColumns(selectedVisit.checkListConfiguration.columns) 
        : [];

    const selectedOperation = operators.find(
      operator => 
        filters.selectedOperation && 
        filters.selectedOperation.value === operator.id);

    // Select by default the first option
    if(!selectedOperation && operators && operators.length > 0) {
      const option = operators[0];
      filtersActions.setOperation({label: option.name, value: option.id});
    }

    const visits = data.visits ? data.visits.map(({_id, name}) => ({id: _id, name})) : []; 
    const keyfields = getKeyfields(selectedVisit);
    const categories = getCategories(selectedOperation);
    const hasGlobal = hasGlobalOperation(selectedOperation);
    /* const [activeCount, closedVisits] = classifyAndFilter(filteredDataVisitAnswers); */
    const parsedClosed = filteredDataVisitAnswers ? 
      parseVisitAnswers(filteredDataVisitAnswers, keyfields) : [];
    
    const emailData = {
      visit: filters.selectedVisitID?  filters.selectedVisitID.label : '', 
      operation: filters.selectedOperation? filters.selectedOperation.label : '', 
      startDate: filters.range.startDate.format("DD-MM-YYYY"),
      endDate: Moment(filters.range.endDate).format("DD-MM-YYYY")
    };

    useMemo(() => {
      if (
        session.user &&
        !auth.hasPermission("checklistDashboardView", session.permissions)
      ) {
        globalActions.notification.openNotification("tr", {
          message: t('common.hasNotPermission'),
          color: "danger"
        });
        history.push('/analytics');
      } 
    }, [session]);

    return (
      <VisitsDashboardItemView
        activeVisitAnswer={{ loadingActiveVisitanswers: loadingVisitAnswersOpen, dataOpenVisitanswer: dataVisitAnswersOpen }}
        closedVisitAnswer={{ loadingVisitAnswersClosedCount: loadingVisitAnswersClosed, dataVisitanswerClosedCount: dataVisitAnswersClosed }}
        createCombinedScoresReport={createCombinedScoresReport}
        createCombinedScoresXLSReport={createCombinedScoresXLSReport}
        emailData={emailData}
        visits={visits}
        visitAnswers={{ loadingVisitAnswersClosed: loadingVisitAnswers, dataVisitAnswer: parsedClosed }}
        operatorsObj={{ loadignOperator: loading, operators: operators }}
        operation={filters.selectedOperation}
        keyfields={keyfields}
        categories={categories}
        hasGlobal={hasGlobal}
        sendCombinedScoresPDFReport={sendCombinedScoresPDFReport}
        sendCombinedScoresXLSReport={sendCombinedScoresXLSReport}
      />
    );
/*   }
 */
}

export default VisitsDashboardItem;
