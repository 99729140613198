import React, { useEffect, useState, useRef, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { Form, withFormik } from "formik";
import { flatten, uniq } from "ramda";
import { debounce } from "lodash";

import Select from "react-select";
import TagsInput from "react-tagsinput";
import Autosuggest from "react-autosuggest";
import { defaultTheme } from "react-autosuggest/dist/theme";

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Button from "components/CustomButtons/Button.jsx";
import { readUTF8String } from "../../utils/stringEncoding";
import LoadingCircleView from "../../Views/LoadingCircleView";
import { TextField, Tooltip } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ReactTable from "react-table";
import DivCenter from "../../components/styled-components/DivCenter";
import useGlobal from "../../store";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TablePagination from "../../pagination/table/TablePagination";

const formatSelectOptions = (elementArray) => {
  return elementArray.map((element) => ({
    value: element._id.toString(),
    label: element.email,
  }));
};

const UsersForm = (props) => {
  const { t } = useTranslation("processRelationship");
  const {
    userMobiles,
    loadingElementsList,
    updateValues,
    paginationUsers,
    paginationAction,
    objectChangedValues
  } = props;

  const sanitizedElementsList = props.elementsList.map(element => ({
    ...element,
    value: element.value.replace(/[^\w\s]/gi, '')
  }));
  
  const sanitizedCSVList = (csvData) => {
    return csvData.map(row => ({
      ...row,
      element: row?.element?.replace(/[^\w\s]/gi, '')
    }))
  };

  // Formik bag
  const { setFieldValue, resetForm, values, submitForm } = props;
  const { relations, currentTags } = values;

  /* const [session] = useGlobal(
    (state) => state.session,
  ); */

  const [importingCSV, setImportingCSV] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [objectChangedValues, setObjectChangedValues] = useState([])

  /* const [userFilter, setUserFilter] = useState({
    email_contains: '',
    app: session.user ? session.appRef._id : "",
  }); */

  const usersFormat = formatSelectOptions(userMobiles);
  
  useEffect(() => {
    submitForm();
  }, [values]);

  function upsertObjectModified(newObject) {
    const index = objectChangedValues.findIndex(item => item.elementId === newObject.elementId);
    
    if (index !== -1) {
        objectChangedValues[index] = newObject;
    } else {
        objectChangedValues.push(newObject);
    }
  }

  const handleSelectChange = (item, original) => {
    const index = relations.findIndex(
      (relation) => relation.elementId === original._id
    );
    const oldRelation = relations.find(
      (relation) => relation.elementId === original._id
    );

    if (index !== -1) {
      setFieldValue(`relations.${index}.userId`, item);
    } else {
      setFieldValue(`relations`, [
        ...relations,
        {
          elementId: original._id,
          label: original.value,
          userId: item,
          tags: [],
        },
      ]);
    }

    const objectModified = {
      'elementId': original?._id || 'N/A',
      'elementName': original?.value || 'N/A',
      'userId': item?.value || 'N/A',
      'userEmail': item?.label || 'N/A',
      'oldUserEmail': oldRelation?.userId?.label || 'N/A'
    }
    upsertObjectModified(objectModified)
  };
  
  const columns = useMemo(() => {
    return [
      {
        Header: <DivCenter>Elemento</DivCenter>,
        accessor: "value",
        filterable: true,
        sortable: false,
        style: {
          textAlign: "center",
          whiteSpace: "break-spaces",
          fontSize: "18px",
        },
      },
      {
        Header: <DivCenter>Usuario</DivCenter>,
        accessor: "user",
        filterable: false,
        sortable: false,
        Cell: ({ original }) => {
          const elementFind = relations.find(
            relation => relation?.elementId?.toString() === original?._id?.toString());
          
          return (
            <Autocomplete
              id="combo-box-demo"
              options={usersFormat}
              getOptionLabel={(option) => option.label}
              value={elementFind ? elementFind.userId : null}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              onChange={(event, item) => {
                handleSelectChange(item, original);
              }}
            />
          );
        },
      },
      {
        Header: <DivCenter>Tags</DivCenter>,
        accessor: "tag",
        filterable: false,
        sortable: false,
        style: { textAlign: "center", whiteSpace: "break-spaces" },
        Cell: ({ original }) => {
          
          const tagsFind = relations.find(
            relation => relation?.elementId?.toString() === original?._id?.toString());

          const index = relations.findIndex(
            (relation) => relation.elementId === original._id
          );

          return (
            <TagsInput
              value={tagsFind ? tagsFind.tags : []}
              onChange={(values) => {
                
                const newElements = values.filter(
                  (value) => !currentTags.includes(value)
                );

                if (index !== -1) {
                  setFieldValue(`relations.${index}.tags`, [...values]);
                }else{
                  setFieldValue(`relations`, [...relations, {
                    elementId: original._id,
                    label: original.value,
                    userId: undefined,
                    tags: [...values],
                }]);
                }

                setFieldValue(`currentTags`, [
                  ...currentTags,
                  ...newElements,
                ]);
              }}
              onlyUnique={true}
              tagProps={{ className: "react-tagsinput-tag info" }}
              inputProps={{
                placeholder: "tags",
              }}
              renderInput={({ addTag, ...props }) => {
                const handleOnChange = (e, { newValue, method }) => {
                  if (method === "enter") {
                    e.preventDefault();
                  } else {
                    props.onChange(e);
                  }
                };

                const inputValue =
                  (props.value && props.value.trim().toLowerCase()) ||
                  "";
                const inputLength = inputValue.length;

                let suggestions = currentTags.filter((tag) => {
                  return (
                    tag.toLowerCase().slice(0, inputLength) ===
                    inputValue
                  );
                });

                return (
                  <Autosuggest
                    ref={props.ref}
                    suggestions={suggestions}
                    shouldRenderSuggestions={(value) =>
                      value && value.trim().length > 0
                    }
                    getSuggestionValue={(suggestion) => suggestion}
                    renderSuggestion={(suggestion) => (
                      <span>{suggestion}</span>
                    )}
                    inputProps={{
                      ...props,
                      onChange: handleOnChange,
                    }}
                    onSuggestionSelected={(e, { suggestion }) => {
                      addTag(suggestion);
                    }}
                    onSuggestionsClearRequested={() => {}}
                    onSuggestionsFetchRequested={() => {}}
                    theme={{
                      ...defaultTheme,
                      container: "react-autosuggest__container_block",
                    }}
                  />
                );
              }}
            />
          );
        },
      },
    ];
  }, [values.relations]);

  const optionsPagination = useMemo(() => {
    const { elementsList } = props;

    const count = Math.round(elementsList.length / 50);

    return Array.from([...new Array(count).keys()], element => (element + 1) * parseInt(50));
  }, [props.elementsList]);

  const TextFieldComponent = ({ roleName }) => {
    const debouncedUpdate = debounce((value) => {
      setFieldValue("roleName", value);
    }, 1000);

    return (
      <TextField
        id="rolName"
        type={"text"}
        defaultValue={roleName}
        onChange={({ target }) => {
          debouncedUpdate(target.value);
        }}
        fullWidth
      />
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const filterElement = (filter, row) => {
    const id = filter.pivotId || filter.id;
    
    if (typeof row[id] === 'number') {
      row[id] = row[id].toString();
    }

    return (
        row[id] !== undefined ?
            String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        :
          true
    );

  }

  return (
    <Form>
      <GridContainer justify="center">
        <GridItem xs={4} style={{ textAlign: "right" }}>
          <h4>{t("processRelationship.forms.fields.roleName.label")}</h4>
        </GridItem>
        <GridItem xs={4}>
          <TextFieldComponent roleName={values.roleName} />
        </GridItem>
        <GridItem xs={4}>
          <Button
            justIcon={true}
            color="info"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Tooltip title={t("processRelationship.tooltip.downloadTemplate")}>
              <GetAppIcon />
            </Tooltip>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                const headers = ["element", "user", "tags"];
                const elements = props.elementsList.map((element) => [
                  `"${element.value}"`,
                  "",
                  "",
                ]);

                let csvContent =
                  "data:text/csv;charset=utf-8," +
                  [headers, ...elements].map((e) => e.join(",")).join("\n");

                const encodedUri = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", "elements_template.csv");
                document.body.appendChild(link); // Required for FF

                link.click();
                handleClose();
              }}
            >
              {t("processRelationship.tooltip.downloadElement")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                const headers = ["element", "user", "tags"];

                let csvContent =
                  "data:text/csv;charset=utf-8," +
                  [headers].map((e) => e.join(",")).join("\n");

                const encodedUri = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", "elements_template.csv");
                document.body.appendChild(link); // Required for FF

                link.click();
                handleClose();
              }}
            >
              {t("processRelationship.tooltip.downloadTemplate")}
            </MenuItem>
          </Menu>

          <Button
            justIcon={true}
            color="info"
            onClick={() => {
              const inputImport = document.getElementById(
                "csv-elements-import"
              );
              const readCsv = () => {
                var reader = new FileReader();
                reader.readAsBinaryString(inputImport.files[0]);
                reader.onload = () => {
                  setImportingCSV(true);
                  const csv_content = readUTF8String(reader.result);
                  const csv_lines = csv_content.split("\n");
                  const csv_data = csv_lines.map((csv_line, index) => {
                    if (index === 0) {
                      return {};
                    }
                    /* const line_data = csv_line.match(
                      /(".*?"|[^",\s]+)(?=\s*,|\s*$)/g
                    ); */
                    const line_data = csv_line.replace(/[\r\n]/gm, '').split(',');

                    if (line_data) {
                      const elementName = line_data[0]
                        ? line_data[0].replace(/"/g, "")
                        : "";
                      const userFromData = line_data[1];
                      const tags = line_data.splice(2);
                      const validTags = tags ? tags.filter((tag) => !!tag) : [];

                      return {
                        element: elementName,
                        user: userFromData,
                        tags: validTags.map(tag => tag.replace(/"/g, '')),
                      };
                    }
                    return {};
                  });
                  
                  const sanitizedCSV = sanitizedCSVList(csv_data);
                  const updated_relations = sanitizedElementsList.map((relation) => {
                    const foundImportedData = sanitizedCSV.find(
                      (csv_element) => csv_element.element === relation.value
                    ) || sanitizedCSV.find(
                      (csv_element) => csv_element.element === relation.name
                    );

                    

                    if (foundImportedData) {
                      const userFound = userMobiles.find(
                        (user) => user.email.toLowerCase() === foundImportedData.user.toLowerCase()
                      );

                      return {
                        ...relation,
                        elementId: relation._id.toString(),
                        userId: userFound
                          ? {
                              value: userFound._id.toString(),
                              label: userFound.email,
                            }
                          : undefined,
                        tags: foundImportedData.tags,
                      };
                    }
                    return {
                      ...relation,
                      elementId: relation._id.toString(),
                      userId: undefined,
                      tags: [],
                    };
                  });

                  props.updateValues({
                    ...values,
                    relations: updated_relations.filter(
                      (relation) => relation.userId
                    ),
                  });

                  setFieldValue('relations', updated_relations.filter(
                    (relation) => relation.userId
                  ));

                  setImportingCSV(false);

                  resetForm();
                };
              };
              inputImport.addEventListener("change", readCsv);
              inputImport.click();
            }}
          >
            <Tooltip title={t("processRelationship.tooltip.upload")}>
              <PublishIcon />
            </Tooltip>
          </Button>
          <input
            id="csv-elements-import"
            type="file"
            accept={".csv"}
            style={{ display: "none" }}
          />
        </GridItem>
      </GridContainer>
      <br />
      <br />
      {importingCSV || !relations || loadingElementsList ? (
        <LoadingCircleView />
      ) : (
        <GridContainer justify="center">
          <GridItem xs={8}>
            {/* <TablePagination 
              data={props.elementsList}
              paginationAction={paginationAction}
              filter={paginationUsers}
            /> */}

            <ReactTable
              data={props.elementsList}
              columns={columns}
              pageSizeOptions={optionsPagination}
              showPaginationTop={true}
              showPaginationBottom={false}
              className="-striped -highlight"
              resizable={false}
              style={{
                maxHeight: "450px",
                overflowY: "scroll",
              }}
              previousText={t("processRelationship.list.pagination.previousText")}
              nextText={t("processRelationship.list.pagination.nextText")}
              pageText={t("processRelationship.list.pagination.pageText")}
              ofText={t("processRelationship.list.pagination.ofText")}
              rowsText={t("processRelationship.list.pagination.rowsText")}
              defaultFilterMethod={(filter, row) => filterElement(filter, row) }
            />
          </GridItem>
        </GridContainer>
      )}
    </Form>
  );
};

UsersForm.propTypes = {};

const UsersFormWF = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const { initialValues, elementsList, loadingElementsList } = props;

    const relations = initialValues?.relations ? initialValues?.relations : [];

    return {
      relations: relations,
      currentTags: uniq(flatten(relations.map((relation) => relation.tags))),
      roleName: initialValues?.roleName || ""
    };
  },
  handleSubmit: (values, { props }) => {
    props.updateValues({
      ...values,
      relations: values.relations.filter((relation) => relation.userId),
    });
  },
})(UsersForm);

export default UsersFormWF;
