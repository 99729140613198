import React from 'react';
import PropTypes from 'prop-types';
import {Field} from "formik";
import {includes, pull, concat} from "lodash";

// core components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';

// styled components
import DivCenter from "../../components/styled-components/DivCenter";

const FieldListOptions = ({name, options, multi}) => {
  return (
    <Field name={name} render={({field, form}) => {
      const {setFieldValue} = form;

      return (
        <List>
          {options.map((option, index) => {
            const optionProps = {
              onChange: () => includes(field.value, option.value) ?
                setFieldValue(name, pull(field.value, option.value)) :
                setFieldValue(name, multi ? concat(field.value, option.value) : [option.value]),
              value: option.value,
              edge: 'end',
              checked: includes(field.value, option.value),
              inputProps: {'aria-labelledby': index}
            };

            return (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar
                    alt={`Avatar n°${index + 1}`}
                    src={option.image.i240}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <DivCenter>{option.value}</DivCenter>
                </ListItemText>
                <ListItemSecondaryAction>
                  {
                    multi ? <Checkbox
                      {...optionProps}
                    /> : <Radio
                      {...optionProps}
                    />
                  }
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      );
    }}/>
  );
};

FieldListOptions.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  multi: PropTypes.bool,
};

export default FieldListOptions;
