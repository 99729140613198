import React from "react";
import ReactTable from "react-table";
import DivCenter from "../../components/styled-components/DivCenter";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import moment from "moment";

const getColumnWidth = (data, accessor, headerText) => {
  const maxWidth = 600;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...data.map((row) => (`${row[accessor]}` || "").length),
    headerText.length
  );
  return Math.min(maxWidth, cellLength * magicSpacing);
};

const TabulatorProcessRelationshipTable = ({
  data,
  processRelationship,
  filterDates
}) => {
  const { t } = useTranslation("processRelationship");

  const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined ?
            String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        :
            true
    );
  }

  const tableData = [{
    _id: '0',
    name: 'Global',
    periodicity: data.resultsTotal
  }, ...data.results];

  const columns = data.periodicityRanges.map((periodicityRange, index) => {
    return {
      Header: <DivCenter>
        <DivCenter>{moment(periodicityRange.startDate).format('DD/MM/YYYY')}</DivCenter>
        <DivCenter>{moment(periodicityRange.endDate).format('DD/MM/YYYY')}</DivCenter>
      </DivCenter>,
      accessor: "column",
      Cell: ({ original }) => {
        if(original.periodicity[index] == null) {
          return 'NA';
        }
        return `${original.periodicity[index].toFixed(2)} %`;
      },
      filterable: false,
      sortable: false,
      style: { textAlign: "center" },
      getProps: (state, rowInfo, column) => {
        if(rowInfo === undefined) {
          return {};
        }
        const {original} = rowInfo;
        if(original._id === '0') {
          return {
            style: {
              backgroundColor: 'rgb(112 193 231 / 34%)'
            }
          }
        }
        return {};
      },
      //width: getColumnWidth(data, "columns", header),
    };
  });

  columns.unshift(...[{
    Header: (
      <DivCenter>
        {t("processRelationship.tabulator.list.columns.elements")}
      </DivCenter>
    ),
    accessor: "name",
    Cell: ({ original }) => {
      return (
        <div>
          {original.name}
        </div>
      );
    },
    filterable: true,
    sortable: false,
    style: {
      textAlign: "left",
      whiteSpace: "unset",
      fontWeight: "400",
    },
    getProps: (state, rowInfo, column) => {
      if(rowInfo === undefined) {
        return {};
      }
      const {original} = rowInfo;
      if(original._id === '0') {
        return {
          style: {
            backgroundColor: 'rgb(112 193 231 / 34%)'
          }
        }
      }
      return {};
    },
    width: getColumnWidth(
      tableData,
      "name",
      t("processRelationship.tabulator.list.columns.elements")
    ),
  }, {
    Header: "",
    accessor: "view",
    Cell: ({ original }) => {
      return (
        <div>
          {original.name !== "Global" ? (
            <Tooltip title={t("processRelationship.tooltip.view")}>
              <Link
                to={{
                  pathname: `/object/${processRelationship._id}/detail`,
                  state: {
                    item: original,
                    processRelationship: processRelationship,
                    filterDates
                  },
                }}
              >
                <Button justIcon round simple color="info">
                  <VisibilityIcon />
                </Button>
              </Link>
            </Tooltip>
          ) : (
            "-"
          )}
        </div>
      );
    },
    filterable: false,
    sortable: false,
    maxWidth: 50,
    style: {
      textAlign: "center",
      fontWeight: "400",
    },
    getProps: (state, rowInfo, column) => {
      if(rowInfo === undefined) {
        return {};
      }
      const {original} = rowInfo;
      if(original._id === '0') {
        return {
          style: {
            backgroundColor: 'rgb(112 193 231 / 34%)'
          }
        }
      }
      return {};
    },
  }]);

  return (
    <ReactTable
      data={tableData}
      columns={columns}
      defaultPageSize={tableData.length}
      resizable={false}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
      defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row) }
    />
  );
};

export default TabulatorProcessRelationshipTable;
