import React from "react";
import {useTranslation} from 'react-i18next';
import {useQuery, useSubscription} from "react-apollo-hooks";

// Own components
import LoadingCircleView from "../../Views/LoadingCircleView";
import VisitApprovalItemsView from "../../Views/Visits/VisitApprovalItemsView";

import useGlobal from "../../store";
import {VISIT_APPROVAL_ITEMS_INFO} from "./gql/Queries";
import {APPROVAL_ITEMS_STATUS_CHANGED_SUBSCRIPTION} from "./gql/Subscriptions";
import {APPROVAL_ITEM_STATUS} from "../../constants";
import {approvalItemChangedNotification} from "../../utils/snackNotifications"

function VisitApprovalItems({match}) {
  const [ session, globalActions] = useGlobal(
    state => state.session,
    actions => actions
  );
  const { t, } = useTranslation('visits');
  const {data, error, loading, refetch: refetchVisitApprovalItems} = useQuery(
    VISIT_APPROVAL_ITEMS_INFO,
    {
      variables: {
        userId: session.user._id,
        visitId: match.params.id
      },
      fetchPolicy: 'network-only'
    }
  );
  const {
    data: dataApprovalItemsSubscription,
    error: errorApprovalItemsSubscription,
    loading: loadingApprovalItemsSubscription
  } = useSubscription(APPROVAL_ITEMS_STATUS_CHANGED_SUBSCRIPTION, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : '',
        userId: session.user ? session.user._id : '',
        approvalItems: data && data.approvalItems ? data.approvalItems.map(appItem => appItem._id) : []
      }
    },
    onSubscriptionData: ({client, subscriptionData}) => {
      const {status, node} = subscriptionData.data.approvalItemsStatusChanged;

      approvalItemChangedNotification(t, globalActions, status, node);
      refetchVisitApprovalItems();
    }
  });

  if (loading) {
    return <LoadingCircleView/>
  } else {
    return (
      <VisitApprovalItemsView
        visit={data.visit}
        approvalItems={data.approvalItems}
      />
    );
  }

}

const styles = theme => ({
  center: {
    textAlign: "center"
  }
});

export default VisitApprovalItems;
