import React, {useState, useEffect} from "react";
import { Switch, Route } from "react-router-dom";
import {withApollo} from "react-apollo";
import {Helmet} from "react-helmet";
import {compose} from "recompose";

import indexRoutes from "../../routes";
import PrivateRoute from "../../utils/routing/PrivateRoute";
import SnackbarContainer from "../Snackbar";

function App({lng, client}) {
  const [tlShow, changeTlShow] = useState(true);
  useEffect(() => {
    require('moment/locale/es')
  }, []);

  return (
    <div className="App">
      <Helmet>
        {/*<html lang={lng} />*/}
      </Helmet>
      <Switch>
        {indexRoutes.map((prop, key) => {
          if (!prop.public) {
            return (
              <PrivateRoute path={prop.path} component={prop.component} key={key} client={client} />
            );
          }else{
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            );
          }
        })}
      </Switch>
      <SnackbarContainer/>
    </div>
  );
}

export default compose(
  withApollo,
)(App);
