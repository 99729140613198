import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from '../../utils/history';
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import CardBody from "../../components/Card/CardBody";
import VisitSchedulingTabulatorDetailTable from "../../tables/visitScheduling/VisitSchedulingTabulatorDetailTable";
import VisitAnswerItem from "../../containers/Visits/VisitsVisorItem/VisitAnswerItem";
import Drawer from "@material-ui/core/Drawer";

const VisitSchedulingTabulatorDetailView = ({
  visitScheduling,
  visitUserSchedulings
}) => {
  const { t } = useTranslation(['visitScheduling', 'common']);

  const [visitID, setVisitID] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleViewClick = (id) => {
    setVisitID(id);
    setOpen(true);
  };

  return (
    <GridContainer>
      <GridItem>
        <Button
          simple
          size="lg"
          color="info"
          onClick={history.goBack}
        >
          <ArrowBackIcon/><span>{t("common:common.back")}</span>
        </Button>
      </GridItem>

      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <EventAvailableIcon/>
            </CardIcon>
            <div style={style.left}>
              <h4 style={style.cardTitle}>
                {t("visitScheduling.tabulatorDetail.title")}
                {visitScheduling.name}
              </h4>
            </div>
          </CardHeader>

          <CardBody>
            <VisitSchedulingTabulatorDetailTable
              data={visitUserSchedulings}
              visitScheduling={visitScheduling}
              handleClick={handleViewClick}
            />
          </CardBody>

        </Card>
      </GridItem>
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div style={{minWidth: 800, maxWidth: 800}}>
          <Card>
            <CardBody>
              {
                visitID !== null ?
                  <VisitAnswerItem
                    classes={{}}
                    visitAnswerId={visitID}
                    visit={visitScheduling.visitRef}
                  /> : []
              }
            </CardBody>
          </Card>
        </div>
      </Drawer>
    </GridContainer>
  );
};

const style = {
  cardTitle: {
    color: "#3C4858"
  },
  progress: {
    margin: '16px'
  },
  progressCnt:{
    width: '100px !important',
    marginLeft: '50%',
    marginRight: '50%'
  },
  cardHeight: {
    height: "430px"
  },
  left: {
    float: "left"
  },
  right: {
    float: "right"
  },
  pointer: {
    cursor: "pointer"
  },
  marginBottom: {
    marginBottom: "25px"
  }
};

export default VisitSchedulingTabulatorDetailView;
