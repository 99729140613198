import React from 'react';
// custom components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from "components/Card/CardFooter.jsx";
import Position from 'components/Position';
import {useTranslation} from "react-i18next";
import DivCenter from "components/styled-components/DivCenter";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const FileFieldAnswerView = ({answerData}) => {
  const { t } = useTranslation("question-engine-module-answer");
  const classes = useStyles();

  return (
    <Card>
      <CardBody>
        <DivCenter className={classes.root}>
          <List component="nav" className={classes.root} aria-label="contacts">
            {answerData?.answer?.FileFieldAnswer?.map((file, index) => (
              <ListItem button key={index}>
                <ListItemIcon>
                  <InsertDriveFileIcon />
                </ListItemIcon>
                <a className={classes.linkColor} href={file.file} target="_blank">
                  <ListItemText primary={`${file.filename}`} />
                </a>
              </ListItem>
            ))}
          </List>
        </DivCenter>
      </CardBody>
      <CardFooter>
        <Position left>
          <small>{`${t("question-engine-module-answer.view.field")} : ${
            answerData.questionLabel
          }`}</small>
        </Position>
        <Position right>
          <small>{`${t("question-engine-module-answer.view.keyField")} : ${
            answerData.key
          }`}</small>
        </Position>
      </CardFooter>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  linkColor: {
    color: "inherit",
  }
}));

export default FileFieldAnswerView;
