import React, { useState } from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

// own components
import NumericFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/NumericFieldAnswerView'
import OpenFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/OpenFieldAnswerView'
import DateFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/DateFieldAnswerView'
import PhoneFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/PhoneFieldAnswerView'
import ListUniqueFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/ListUniqueFieldAnswerView'
import ListMultipleFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/ListMultipleFieldAnswerView'
import EmailFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/EmailFieldAnswerView'
import PasswordFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/PasswordFieldAnswerView'
import OpenMultilineFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/OpenMultilineFieldAnswerView'
import PhotoReportFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/PhotoReportFieldAnswerView'
import MapFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/MapFieldAnswerView'
import SignatureFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/SignatureFieldAnswerView'
import CodeBarFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/CodeBarFieldAnswerView'
import MassiveListUniqueFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/MassiveListUniqueFieldAnswerView'
import MassiveListMultipleFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/MassiveListMultipleFieldAnswerView'
import ChecklistFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/ChecklistFieldAnswerView'
import TokenFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/TokenFieldAnswerView'
import TableFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/TableFieldAnswerView'
import GeofenceFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/GeofenceFieldAnswerView'
import FileFieldAnswerView from '../QuestionEngineAnswers/AnswerTypes/FileFieldAnswerView'
// own forms
import OpenFieldAnswerEditForm from "../../forms/question-engine-answer/OpenFieldAnswerEditForm";
import NumericFieldAnswerEditForm from "../../forms/question-engine-answer/NumericFieldAnswerEditForm";
import DateFieldAnswerEditForm from "../../forms/question-engine-answer/DateFieldAnswerEditForm";
import PhoneFieldAnswerEditForm from "../../forms/question-engine-answer/PhoneFieldAnswerEditForm";
import ListMultipleFieldAnswerEditForm from "../../forms/question-engine-answer/ListMultipleFieldAnswerEditForm";
import ListUniqueFieldAnswerEditForm from "../../forms/question-engine-answer/ListUniqueFieldAnswerEditForm";
import EmailFieldAnswerEditForm from "../../forms/question-engine-answer/EmailFieldAnswerEditForm";
import PasswordFieldAnswerEditForm from "../../forms/question-engine-answer/PasswordFieldAnswerEditForm";
import OpenMultilineFieldAnswerEditForm from "../../forms/question-engine-answer/OpenMultilineFieldAnswerEditForm";
import MassiveListUniqueFieldAnswerEditForm from "../../forms/question-engine-answer/MassiveListUniqueFieldAnswerEditForm";
import MassiveListMultipleFieldAnswerEditForm from "../../forms/question-engine-answer/MassiveListMultipleFieldAnswerEditForm";
import ChecklistFieldAnswerEditForm from "../../forms/question-engine-answer/ChecklistFieldAnswerEditForm";

// core components
import Collapse from "@material-ui/core/Collapse";

// custom components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

const QuestionEngineAnswerView = ({
  questionEngineAnswer,
  editable,
  classes,
  processAnswerUpdateLog,
  preventEdit,
  questionAnswerUpdateLogs,
  approvalButton
}) => {
  const [answerEditModes, changeAnswerEditModes] = useState({});
  const [answerLogChangesView, changeAnswerLogChangesView] = useState({});
  const [lightBoxOpen, toggleLightBox] = useState(false);
  const [lightBoxImages, setLightBoxImages] = useState([]);
  const [currentLightBoxImage, setCurrentLightBoxImage] = useState(0);

  const { t } = useTranslation("question-engine-module-answer");

  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem xs={6}>
            <h4 className={classes.noMargin}>
              <small className={classes.normalColor}>
                {t("question-engine-module-answer.view.form")}
              </small>
              <br />
              <small className={classes.normalColor}>
                {questionEngineAnswer.module.name}
              </small>
            </h4>
          </GridItem>
          <GridItem xs={6} className={classes.right}>
            <h4 className={classes.noMargin}>
              <small className={classes.normalColor}>
                {`${Moment(questionEngineAnswer.created).format(
                  "dddd, MMMM Do YYYY, h:mm:ss A"
                )}`}
              </small>
            </h4>
          </GridItem>
          <GridItem xs={6} className={classes.marginTop}>
            <h4 className={classes.noMargin}>
              <small className={classes.normalColor}>
                {t("question-engine-module-answer.view.userAnswer")} :
              </small>
              <br />
              <small className={classes.normalColor}>
                {questionEngineAnswer.userRef.email}
              </small>
            </h4>
          </GridItem>

          {approvalButton ? (
            <GridItem
              xs={6}
              className={classes.marginTop}
              style={{
                textAlign: "end",
              }}
            >
              {approvalButton()}
            </GridItem>
          ) : (
            []
          )}

          {questionEngineAnswer.keyfields ? (
            <GridItem
              xs={6}
              className={classes.marginTop}
              style={{
                textAlign: "end",
              }}
            >
              {questionEngineAnswer.keyfields.map((keyField) => (
                <h4 className={classes.noMargin}>
                  <small className={classes.normalColor}>
                    <span
                      className={classes.textBold}
                    >{`${keyField.name}: `}</span>
                    {keyField.value}
                  </small>
                </h4>
              ))}
            </GridItem>
          ) : (
            []
          )}

          <GridItem xs={12}>
            <h4 className={classes.textBold}>
              {t("question-engine-module-answer.view.answers")}
            </h4>
            {questionEngineAnswer.answers.map((answer, index) => {
              let currentField;
              if (questionEngineAnswer?.module?.propertiesRef?.fields) {
                const fields =
                  questionEngineAnswer?.module?.propertiesRef?.fields;
                currentField = fields.find(
                  (field) => field?.key === answer?.key
                );
              } else {
                currentField = null;
              }

              const preventEditField = preventEdit
                ? !!preventEdit.find((key) => answer.key === key)
                : false;
              const getQuestionAnswerUpdateLogs = () => {
                return questionAnswerUpdateLogs
                  ? questionAnswerUpdateLogs
                      .filter((updateLog) => {
                        return (
                          updateLog.questionAnswersId ===
                            questionEngineAnswer._id &&
                          answer.key === updateLog.key
                        );
                      })
                      .reverse()
                  : [];
              };
              const answerProps = {
                answerData: answer,
                key: index,
                editable: editable && !preventEditField,
                changeToEditable: (answerToEdit) => {
                  changeAnswerEditModes({
                    ...answerEditModes,
                    [answerToEdit.key]: true,
                  });
                },
                toggleLogChangesView: (answerToToggle) => {
                  changeAnswerLogChangesView({
                    ...answerLogChangesView,
                    [answerToToggle.key]:
                      !answerLogChangesView[answerToToggle.key],
                  });
                },
                openImageLightBox: (imgInfo) => {
                  setLightBoxImages(imgInfo.images);
                  setCurrentLightBoxImage(imgInfo.currentImage);
                  toggleLightBox(true);
                },
                logChangesViewMode: answerLogChangesView[answer.key],
                isEditRendered: answerEditModes[answer.key],
                questionAnswerUpdateLogs: getQuestionAnswerUpdateLogs(),
              };
              const editFormProps = {
                cancelEdit: (answerToCancel) => {
                  changeAnswerEditModes({
                    ...answerEditModes,
                    [answerToCancel.key]: false,
                  });
                },
                submitSuccess: (values) => {
                  let nextAnswer = {};

                  if (answer.answer.__typename === "ChecklistFieldAnswer") {
                    nextAnswer = {
                      ...answer.answer,
                      ChecklistFieldAnswer: values.id,
                      comment: values.comment,
                    };
                  } else if (
                    answer.answer.__typename ===
                    "MassiveListMultipleFieldAnswer"
                  ) {
                    nextAnswer = {
                      ...answer.answer,
                      [answer.answer.__typename]: values.value[0],
                    };
                  } else {
                    nextAnswer = {
                      ...answer.answer,
                      [answer.answer.__typename]: values.value,
                    };
                  }
                  processAnswerUpdateLog({
                    previousAnswer: answer.answer,
                    nextAnswer: nextAnswer,
                    questionAnswersId: questionEngineAnswer._id,
                    key: answer.key,
                  });

                  // change edit mode
                  changeAnswerEditModes({
                    ...answerEditModes,
                    [answer.key]: false,
                  });
                },
              };

              switch (answer.answer.__typename) {
                case "OpenFieldAnswer":
                  return (
                    <React.Fragment key={index}>
                      <Collapse in={answerEditModes[answer.key]}>
                        <OpenFieldAnswerEditForm
                          {...editFormProps}
                          {...answerProps}
                        />
                      </Collapse>
                      <Collapse in={!answerEditModes[answer.key]}>
                        <OpenFieldAnswerView {...answerProps} />
                      </Collapse>
                    </React.Fragment>
                  );
                case "NumericFieldAnswer":
                  return (
                    <React.Fragment key={index}>
                      <Collapse in={answerEditModes[answer.key]}>
                        <NumericFieldAnswerEditForm
                          {...editFormProps}
                          {...answerProps}
                        />
                      </Collapse>
                      <Collapse in={!answerEditModes[answer.key]}>
                        <NumericFieldAnswerView {...answerProps} />
                      </Collapse>
                    </React.Fragment>
                  );
                case "DateFieldAnswer":
                  return (
                    <React.Fragment key={index}>
                      <Collapse in={answerEditModes[answer.key]}>
                        <DateFieldAnswerEditForm
                          {...editFormProps}
                          {...answerProps}
                        />
                      </Collapse>
                      <Collapse in={!answerEditModes[answer.key]}>
                        <DateFieldAnswerView {...answerProps} />
                      </Collapse>
                    </React.Fragment>
                  );
                case "PhoneFieldAnswer":
                  return (
                    <React.Fragment key={index}>
                      <Collapse in={answerEditModes[answer.key]}>
                        <PhoneFieldAnswerEditForm
                          {...editFormProps}
                          {...answerProps}
                        />
                      </Collapse>
                      <Collapse in={!answerEditModes[answer.key]}>
                        <PhoneFieldAnswerView {...answerProps} />
                      </Collapse>
                    </React.Fragment>
                  );
                case "ListUniqueFieldAnswer":
                  return (
                    <React.Fragment key={index}>
                      <Collapse in={answerEditModes[answer.key]}>
                        <ListUniqueFieldAnswerEditForm
                          {...editFormProps}
                          {...answerProps}
                        />
                      </Collapse>
                      <Collapse in={!answerEditModes[answer.key]}>
                        <ListUniqueFieldAnswerView {...answerProps} />
                      </Collapse>
                    </React.Fragment>
                  );
                case "ListMultipleFieldAnswer":
                  return (
                    <React.Fragment key={index}>
                      <Collapse in={answerEditModes[answer.key]}>
                        <ListMultipleFieldAnswerEditForm
                          {...editFormProps}
                          {...answerProps}
                        />
                      </Collapse>
                      <Collapse in={!answerEditModes[answer.key]}>
                        <ListMultipleFieldAnswerView {...answerProps} />
                      </Collapse>
                    </React.Fragment>
                  );
                case "EmailFieldAnswer":
                  return (
                    <React.Fragment key={index}>
                      <Collapse in={answerEditModes[answer.key]}>
                        <EmailFieldAnswerEditForm
                          {...editFormProps}
                          {...answerProps}
                        />
                      </Collapse>
                      <Collapse in={!answerEditModes[answer.key]}>
                        <EmailFieldAnswerView {...answerProps} />
                      </Collapse>
                    </React.Fragment>
                  );
                case "PasswordFieldAnswer":
                  return (
                    <React.Fragment key={index}>
                      <Collapse in={answerEditModes[answer.key]}>
                        <PasswordFieldAnswerEditForm
                          {...editFormProps}
                          {...answerProps}
                        />
                      </Collapse>
                      <Collapse in={!answerEditModes[answer.key]}>
                        <PasswordFieldAnswerView {...answerProps} />
                      </Collapse>
                    </React.Fragment>
                  );
                case "OpenMultilineFieldAnswer":
                  return (
                    <React.Fragment key={index}>
                      <Collapse in={answerEditModes[answer.key]}>
                        <OpenMultilineFieldAnswerEditForm
                          {...editFormProps}
                          {...answerProps}
                        />
                      </Collapse>
                      <Collapse in={!answerEditModes[answer.key]}>
                        <OpenMultilineFieldAnswerView {...answerProps} />
                      </Collapse>
                    </React.Fragment>
                  );
                case "PhotoReportFieldAnswer":
                  return <PhotoReportFieldAnswerView {...answerProps} />;
                case "MapFieldAnswer":
                  return (
                    <MapFieldAnswerView field={currentField} {...answerProps} />
                  );
                case "SignatureFieldAnswer":
                  return <SignatureFieldAnswerView {...answerProps} />;
                case "CodeBarFieldAnswer":
                  return <CodeBarFieldAnswerView {...answerProps} />;
                case "MassiveListUniqueFieldAnswer":
                  return (
                    <React.Fragment key={index}>
                      <Collapse in={answerEditModes[answer.key]}>
                        <MassiveListUniqueFieldAnswerEditForm
                          {...editFormProps}
                          {...answerProps}
                        />
                      </Collapse>
                      <Collapse in={!answerEditModes[answer.key]}>
                        <MassiveListUniqueFieldAnswerView {...answerProps} />
                      </Collapse>
                    </React.Fragment>
                  );
                case "MassiveListMultipleFieldAnswer":
                  return (
                    <React.Fragment key={index}>
                      <Collapse in={answerEditModes[answer.key]}>
                        <MassiveListMultipleFieldAnswerEditForm
                          {...editFormProps}
                          {...answerProps}
                        />
                      </Collapse>
                      <Collapse in={!answerEditModes[answer.key]}>
                        <MassiveListMultipleFieldAnswerView {...answerProps} />
                      </Collapse>
                    </React.Fragment>
                  );
                case "ChecklistFieldAnswer":
                  const checklistScale = questionEngineAnswer.checklists.find(
                    (checklist) => {
                      const foundOption = checklist.options.find((option) => {
                        return (
                          option._id ===
                          answerProps.answerData.answer.ChecklistFieldAnswer
                        );
                      });
                      return foundOption || false;
                    }
                  );

                  if (checklistScale) {
                    return (
                      <React.Fragment key={index}>
                        <Collapse in={answerEditModes[answer.key]}>
                          <ChecklistFieldAnswerEditForm
                            {...editFormProps}
                            {...answerProps}
                            checklist={checklistScale}
                          />
                        </Collapse>
                        <Collapse in={!answerEditModes[answer.key]}>
                          <ChecklistFieldAnswerView
                            checklist={checklistScale}
                            {...answerProps}
                          />
                        </Collapse>
                      </React.Fragment>
                    );
                  } else {
                    return [];
                  }
                case "TokenFieldAnswer":
                  return <TokenFieldAnswerView {...answerProps} />;
                case "TableFieldAnswer":
                  return <TableFieldAnswerView {...answerProps} />;
                case "GeofenceFieldAnswer":
                  return <GeofenceFieldAnswerView {...answerProps} />;
                case "FileFieldAnswer":
                  return <FileFieldAnswerView {...answerProps} />;
              }
            })}
          </GridItem>
          {lightBoxOpen ? (
            <Lightbox
              mainSrc={lightBoxImages[currentLightBoxImage]}
              nextSrc={
                lightBoxImages[
                  (currentLightBoxImage + 1) % lightBoxImages.length
                ]
              }
              prevSrc={
                lightBoxImages[
                  (currentLightBoxImage + lightBoxImages.length - 1) %
                    lightBoxImages.length
                ]
              }
              onCloseRequest={() => toggleLightBox(false)}
              onMovePrevRequest={() =>
                setCurrentLightBoxImage(
                  (currentLightBoxImage + lightBoxImages.length - 1) %
                    lightBoxImages.length
                )
              }
              onMoveNextRequest={() =>
                setCurrentLightBoxImage(
                  (currentLightBoxImage + 1) % lightBoxImages.length
                )
              }
              reactModalStyle={{
                overlay: {
                  zIndex: 10000,
                },
              }}
            />
          ) : (
            []
          )}
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const styles = (theme) => ({
  mainContainer: {
    marginTop: 50,
    padding: 15,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "end",
  },
  noMargin: {
    margin: 0,
  },
  normalColor: {
    color: "black",
  },
  textBold: {
    fontWeight: "bold",
    textAlign: "center",
  },
  marginTop: {
    marginTop: 15,
  },
});

QuestionEngineAnswerView.propTypes = {
  questionEngineAnswer: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  preventEdit: PropTypes.arrayOf(PropTypes.string),
};

export default withStyles(styles)(QuestionEngineAnswerView);
