import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { CircularProgress } from "@material-ui/core";

import { SO_COUNTER_REPORTS_BASE_MODELS } from "./gql/Queries";
import { UPDATE_SO_COUNTER_REPORT } from "./gql/Mutations";

import CreateUpdateCounterReportsForm from "../../forms/SO/CreateUpdateCounterReportsForm";

import useGlobal from "../../store";
import history from "utils/history";
import { SEGMENTS_FEED } from "../Reports/gql/Queries";

const CounterReportEdit = (props) => {
  const { counterReport } = props.location.state;
  const [session, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const {
    data: dataSOCounterReportsBaseModels,
    error: errorSOCounterReportsBaseModels,
    loading: loadingSOCounterReportsBaseModels,
    refetch: refetchSOCounterReportsBaseModels,
  } = useQuery(SO_COUNTER_REPORTS_BASE_MODELS, {
    variables: {
      app: session.user ? session.appRef._id : "",
      archived: false,
    },
    fetchPolicy: "network-only",
  });

  const [updateSOCounterReport] = useMutation(UPDATE_SO_COUNTER_REPORT, {
    onCompleted: (mutationData) => {
      console.log("mutationData", mutationData);
      history.push("/so/counterReport");
    },
  });

  const { data: dataSegments, loading: loadingSegments } = useQuery(SEGMENTS_FEED, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : "",
        archived: false,
      },
    },
  });

  if (loadingSOCounterReportsBaseModels && loadingSegments) {
    return <CircularProgress />;
  }
  return (
    <CreateUpdateCounterReportsForm
      segments={dataSegments?.segments ?? []}
      initialValues={counterReport}
      questionEngineModules={
        dataSOCounterReportsBaseModels?.questionEngineModules || []
      }
      visits={dataSOCounterReportsBaseModels?.visits || []}
      submitSuccess={(values) => {
        console.log(values);
        updateSOCounterReport({
          variables: {
            data: {
              app: session.user ? session.appRef._id : "",
              name: values.name,
              periodicity: values.periodicity,
              initialDate: values.initialDate,
              finalDate: values.finalDate,
              indicators: values.indicators,
            },
            where: {
              _id: counterReport._id,
            },
          },
        });
      }}
    />
  );
};

export default CounterReportEdit;
