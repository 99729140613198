import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { Field, Form, withFormik, FieldArray } from "formik";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import ButtonIcon from "components/styled/ButtonIcon";
import { useTranslation } from "react-i18next";
import { TextField, Chip } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { getOperationsFromColumns } from "../../utils/analytics/dataManipulation";
import { makeStyles } from "@material-ui/core/styles";
import useGlobal from "../../store";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';

const customReportFormSchema = yup.object().shape({
  app: yup.string().required(),
  visit: yup.string().required(),
  keyField: yup.string().required(),
  name: yup.string().required(),
  emails: yup.array(),
  operation: yup.string(),
  type: yup.string().required(),
  description: yup.string(),
  content: yup.array(),
  emailToUser: yup.boolean()
});

const CustomReportForm = (props) => {
  const { t } = useTranslation("checklistAnalisys");
  const classes = useStyles();
  let {
    setFieldValue,
    submitForm,
    errors,
    touched,
    initDataForm,
    handleBack,
    disabled,
    values,
  } = props;
  const { users, currentVisit, visits } = initDataForm;
  const [visit, setVisit] = useState(null);
  const [, globalActions] = useGlobal();

  const renderOperations = (operations) => {
    let globalOperations = [];
    operations.map((operation) => {
      if (operation.id === 5) {
        globalOperations.push("globalEfficacy");
      }

      if (operation.id === 1) {
        globalOperations.push("global");
      }
    });

    return globalOperations;
  };

  useEffect(() => {
    //New Report config
    if (visits.length > 0 && visit === null && !currentVisit) {
      setVisit(visits[0]);
      setFieldValue("visit", visits[0]._id);
      setFieldValue("keyField", visits[0].keys[0]);

      const allOperations =
        visits[0].checkListConfiguration &&
        visits[0].checkListConfiguration.columns
          ? getOperationsFromColumns(visits[0].checkListConfiguration.columns)
          : [];

      /* const globalOperations = renderOperations(allOperations);

      setFieldValue(
        "operation",
        globalOperations.length ? globalOperations[0] : null
      ); */
    }
    
    //For Edit operation
    if (visits.length > 0 && visit === null && currentVisit) {
      let visitfind = visits.find((visit) => {
        return visit._id.toString() === currentVisit.visit.toString();
      });

      if (visitfind) {
        setVisit(visitfind);
      }
      
      const allOperations =
        visitfind &&
        visitfind.checkListConfiguration &&
        visitfind.checkListConfiguration.columns
          ? getOperationsFromColumns(visitfind.checkListConfiguration.columns)
          : [];

      /* const globalOperations = renderOperations(allOperations);
      setFieldValue(
        "operation",
        globalOperations.length ? globalOperations[0] : null
      ); */
    }
  }, [props.visits]);

  const changeSecction = (event, index) => {
    setFieldValue(`content[${index}].type`, event.target.value);
  };

  const renderKeys = (type, options) =>{
    if (type === 'answer_images' && options) {
      options = options.filter(
        (option) =>
          option.__typename === "PhotoReportField" ||
          option.__typename === "ChecklistField"
      ); 
    }

    return options;
  }

  return (
    <Form>
      <GridContainer justify="center">
        <GridItem xs={10}>
          <Field
            name="name"
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  id="name"
                  required
                  error={errors.name && touched.name}
                  helperText={
                    errors.name &&
                    touched.name &&
                    t("checklistAnalisys.form.fields.name.error.isRequired")
                  }
                  label={t("checklistAnalisys.form.fields.name.helperText")}
                  margin="normal"
                  fullWidth
                  disabled={disabled}
                />
              );
            }}
          />
        </GridItem>
        <GridItem xs={10}>
          <Field
            name="description"
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  id="description"
                  required
                  error={errors.description && touched.description}
                  helperText={
                    errors.name &&
                    touched.name &&
                    t(
                      "checklistAnalisys.form.fields.description.error.isRequired"
                    )
                  }
                  label={t(
                    "checklistAnalisys.form.fields.description.helperText"
                  )}
                  margin="normal"
                  fullWidth
                  disabled={disabled}
                />
              );
            }}
          />
        </GridItem>
        <GridItem xs={10}>
          <Autocomplete
            disabled={disabled}
            id="visit"
            value={visit}
            options={visits}
            getOptionLabel={(option) => option.name}
            onChange={(event, option) => {
              setVisit(option);
              /*  setModule(option ? option.engines[0] : null); */
              setFieldValue("visit", option ? option._id : null);
              setFieldValue(
                "keyField",
                option && option.keys ? option.keys[0] : null
              );
              /* setFieldValue('content.module', option && option.engines && option.engines.length ? option.engines[0].moduleId : null);
              setKey(option && option.engines && option.engines[0].moduleRef && option.engines[0].moduleRef.propertiesRef && option.engines[0].moduleRef.propertiesRef.fields.length ? option.engines[0].moduleRef.propertiesRef.fields[0] : null)
              setFieldValue('content.key', option && option.engines && option.engines[0].moduleRef && option.engines[0].moduleRef.propertiesRef && option.engines[0].moduleRef.propertiesRef.fields.length ? option.engines[0].moduleRef.propertiesRef.fields[0].key : null) */
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("checklistAnalisys.form.fields.visit.helperText")}
                margin="normal"
                fullWidth
                error={errors.visit && touched.visit}
                helperText={
                  errors.visit &&
                  touched.visit &&
                  t("checklistAnalisys.form.fields.visit.error.isRequired")
                }
              />
            )}
          />
        </GridItem>
        <GridItem xs={3}>
          <ButtonIcon
            icon={AddBoxIcon}
            label={t("checklistAnalisys.form.buttons.newSecction")}
            color={"success"}
            block={true}
            onClick={() => {
              let content = {
                module: "",
                moduleSelect: [],
                type: "allModule",
                key: "",
                keySelect: [],
                title: "",
                subtitle: "",
              };

              if (
                visit.engines &&
                visit.engines[0] &&
                visit.engines[0].moduleRef
              ) {
                content.module = visit.engines[0].moduleId;
                content.moduleSelect = visit.engines[0];
                content.title = 'Extracto de ' + visit.engines[0].moduleRef.name;

                if (
                  visit.engines[0].moduleRef.propertiesRef.fields &&
                  visit.engines[0].moduleRef.propertiesRef.fields[0]
                ) {
                  content.key =
                    visit.engines[0].moduleRef.propertiesRef.fields[0].key;
                  content.keySelect =
                    visit.engines[0].moduleRef.propertiesRef.fields[0];
                }
              }

              values.content.push(content);

              setFieldValue("content", values.content);
            }}
          />
        </GridItem>
        <GridItem xs={10}>
          {values.content.map((secction, index) => (
            <GridContainer key={index}>
              <GridItem xs={10} key={index}>
                <ExpansionPanel key={index}>
                  <ExpansionPanelSummary
                    key={index}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography key={index}>{ secction.title }  (Sección {index + 1})</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails key={index}>
                    <GridContainer justify="center">
                      <GridItem xs={10}>
                        <Field
                          name={`content[${index}].title`}
                          render={({ field }) => {
                            return (
                              <TextField
                                {...field}
                                id={`content[${index}].title`}
                                required
                                label={t(
                                  "checklistAnalisys.form.fields.secctionTitle.helperText"
                                )}
                                margin="normal"
                                fullWidth
                                disabled={disabled}
                              />
                            );
                          }}
                        />
                      </GridItem>
                      <GridItem xs={10}>
                        <Field
                          name={`content[${index}].subtitle`}
                          render={({ field }) => {
                            return (
                              <TextField
                                {...field}
                                id={`content[${index}].subtitle`}
                                required
                                label={t(
                                  "checklistAnalisys.form.fields.secctionSubtitle.helperText"
                                )}
                                margin="normal"
                                fullWidth
                                disabled={disabled}
                              />
                            );
                          }}
                        />
                      </GridItem>
                      <GridItem xs={10}>
                        <Autocomplete
                          disabled={disabled}
                          id="module"
                          value={values.content[index].moduleSelect}
                          options={visit ? visit.engines : []}
                          getOptionLabel={(option) => option.moduleRef.name}
                          onChange={(event, option) => {
                            setFieldValue(
                              `content[${index}].module`,
                              option ? option.moduleId : null
                            );

                            setFieldValue(
                              `content[${index}].moduleSelect`,
                              option ? option : null
                            );

                            setFieldValue(
                              `content[${index}].title`,
                              option ? option.moduleRef.name : null
                            );

                            setFieldValue(
                              `content[${index}].key`,
                              option &&
                                option.moduleRef.propertiesRef &&
                                option.moduleRef.propertiesRef.fields.length
                                ? option.moduleRef.propertiesRef.fields[0].key
                                : null
                            );

                            setFieldValue(
                              `content[${index}].keySelect`,
                              option &&
                                option.moduleRef.propertiesRef &&
                                option.moduleRef.propertiesRef.fields.length
                                ? option.moduleRef.propertiesRef.fields[0]
                                : null
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t(
                                "checklistAnalisys.form.fields.module.helperText"
                              )}
                              margin="normal"
                              fullWidth
                            />
                          )}
                        />
                      </GridItem>
                      <GridItem xs={10}>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <FormLabel component="legend">
                            {t("checklistAnalisys.form.fields.secction.label")}
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-label="gender"
                            name="dateFilter"
                            value={values.content[index].type}
                            onChange={(e) => {
                              changeSecction(e, index);
                            }}
                          >
                            <FormControlLabel
                              className={classes.formLabel}
                              value="allModule"
                              control={
                                <Radio disabled={disabled} color="primary" />
                              }
                              label={t(
                                "checklistAnalisys.form.fields.secction.options.allAnswers"
                              )}
                            />
                            <FormControlLabel
                              className={classes.formLabel}
                              value="answer"
                              control={
                                <Radio disabled={disabled} color="primary" />
                              }
                              label={t(
                                "checklistAnalisys.form.fields.secction.options.answer"
                              )}
                            />
                            <FormControlLabel
                              className={classes.formLabel}
                              value="answer_images"
                              control={
                                <Radio disabled={disabled} color="primary" />
                              }
                              label={t(
                                "checklistAnalisys.form.fields.secction.options.images"
                              )}
                            />
                          </RadioGroup>
                        </FormControl>
                      </GridItem>
                      {values.content[index].type !== "allModule" ? (
                        <GridItem xs={10}>
                          <Autocomplete
                            disabled={disabled}
                            id="key"
                            value={values.content[index].keySelect}
                            options={
                              values.content[index].moduleSelect &&
                              values.content[index].moduleSelect.moduleRef
                                .propertiesRef &&
                              values.content[index].moduleSelect.moduleRef
                                .propertiesRef.fields.length
                                ? renderKeys(
                                    values.content[index].type,
                                    values.content[index].moduleSelect.moduleRef
                                      .propertiesRef.fields
                                  )
                                : []
                            }
                            getOptionLabel={(option) => option.key}
                            onChange={(event, option) => {
                              setFieldValue(
                                `content[${index}].key`,
                                option ? option.key : null
                              );

                              setFieldValue(
                                `content[${index}].keySelect`,
                                option ? option : null
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  "checklistAnalisys.form.fields.keyField.helperText"
                                )}
                                margin="normal"
                                fullWidth
                              />
                            )}
                          />
                        </GridItem>
                      ) : (
                        ""
                      )}
                    </GridContainer>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </GridItem>
              <GridItem xs={2}>
                <ButtonIcon
                  icon={DeleteIcon}
                  color={"danger"}
                  block={true}
                  onClick={() => {
                    values.content.splice(index, 1);
                    setFieldValue("content", values.content);
                  }}
                />
              </GridItem>
            </GridContainer>
          ))}
        </GridItem>
        <GridItem xs={10}>
          <Autocomplete
            disabled={disabled}
            multiple
            freeSolo
            filterSelectedOptions
            id="emails"
            error={errors.emails && touched.emails}
            errorText={touched.emails && errors.emails}
            options={users.map((user) => user.email)}
            defaultValue={currentVisit ? currentVisit.emails : []}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return <Chip label={option} {...getTagProps({ index })} />;
              })
            }
            onChange={(event, value) => setFieldValue("emails", value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={t("checklistAnalisys.form.fields.emails.helperText")}
                fullWidth
                margin="normal"
                error={errors.emails && touched.emails}
                helperText={
                  errors.emails &&
                  touched.emails &&
                  t("checklistAnalisys.form.fields.emails.error.isRequired")
                }
              />
            )}
          />
        </GridItem>
        <GridItem xs={10}>
          <FormControl component="fieldset" className={classes.formControlEmail}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.emailToUser}
                  onChange={(e) => { setFieldValue('emailToUser', e.target.checked) }}
                  name="emailToUser"
                  color="primary"
                />
              }
              label={t("checklistAnalisys.form.fields.emailToUser.helperText")}
            />
          </FormControl>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem>
          <ButtonIcon
            onClick={handleBack}
            icon={Cancel}
            color={"primary"}
            label={t("checklistAnalisys.form.buttons.previous")}
          />
        </GridItem>
        <GridItem>
          <ButtonIcon
            disabled={disabled}
            icon={CheckCircleIcon}
            label={t("checklistAnalisys.form.buttons.accept")}
            color={"info"}
            block={true}
            onClick={() => {
              submitForm();
            }}
          />
        </GridItem>
      </GridContainer>
    </Form>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    textAlign: "center",
  },
  formControlEmail: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formLabel: {
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const CustomReportFormWF = withFormik({
  validationSchema: customReportFormSchema,
  enableReinitialize: true,
  mapPropsToValues: ({ initDataForm }) => {
    const { app, visits, currentVisit } = initDataForm;
    let visit = "";
    let key = "";
    let name = "";
    let emails = [];
    let operation = "";
    let description = "";
    let emailToUser = false;
    /* let content = {
      module: '',
      type: 'allModule',
      key: '',
      title: '',
      subtitle: ''
    } */
    let content = [];

    if (visits.length > 0 && currentVisit) {
      visit = currentVisit.visit;
      key = currentVisit.keyField;
      name = currentVisit.name;
      emails = currentVisit.emails;
      operation = currentVisit.operation;
      description = currentVisit.description;
      content = currentVisit.content;
      emailToUser = currentVisit.emailToUser;

      const visitSelect = visits.find(visit => visit._id === currentVisit.visit);

      if (visitSelect) {
        content.map((secction, index) => {
          content[index].moduleSelect = visitSelect.engines.find(engine => engine.moduleId === secction.module);
          content[index].keySelect = content[index].moduleSelect.moduleRef.propertiesRef.fields.find(field => field.key === secction.key);
  
        });
      }
      
    }

    return {
      app: app,
      visit: visit,
      keyField: key,
      name: name,
      emails: emails,
      operation: operation,
      type: "CustomVisit",
      description: description,
      content: content,
      emailToUser: emailToUser,
    };
  },
  handleSubmit: (values, { props }) => {
    if (values.content) {
      values.content.map((secction, index) => {
        delete values.content[index].moduleSelect;
        delete values.content[index].keySelect;
      })
    }
    props.submitSuccess(values);
  },
})(CustomReportForm);
export default CustomReportFormWF;
