import React, { useState } from 'react';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import TableChartIcon from '@material-ui/icons/TableChart';
import { AppBar, CircularProgress, Tabs, Tab, Box } from "@material-ui/core";
import TabulatorProcessRelationshipTable from '../../tables/ProcessRelationship/TabulatorProcessRelationshipTable';
import { useTranslation } from 'react-i18next';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Datetime from "react-datetime";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from '../../utils/history';
import Button from 'components/Button';
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import {downloadCsv} from "../../utils/stringEncoding";
import {percentage100} from "../../utils/numeral/numeral";
import {Alert} from "@material-ui/lab";
import SwipeableViews from 'react-swipeable-views';
import { withStyles, useTheme } from "@material-ui/core/styles";
import EmailReportTable from '../../tables/ProcessRelationship/EmailReportTable';
import TablePagination from '../../pagination/table/TablePagination';

const TabulatorProcessRelationshipView = ({
    setFilterDates,
    filterDates,
    results,
    processRelationship,
    emailReportsResult,
    paginationAction,
    filterReport,
    generateReport
}) => {
    const { t } = useTranslation(['processRelationship', 'common']);
    const { data, loading, resultsError } = results;
    const labelPeriodicity = t(`processRelationship.forms.fields.periodicity.options.${processRelationship.periodicity.toLowerCase()}`);
    const theme = useTheme();
    const classes = withStyles(styles);
    const [value, setValue] = useState(0);

    const downloadCsvData = () => {
      const periodRanges = [
        t("processRelationship.tabulator.list.columns.elements"),
        ...data.periodicityRanges.map(
          periodicityRange => `${moment(periodicityRange.startDate).format('DD/MM/YYYY')} - ${moment(periodicityRange.endDate).format('DD/MM/YYYY')}`)
        ];
      const globalValues = ['Global', ...data.resultsTotal.map(percentage100)];
      const values = data.results.map(result => [
        `${result.name}`,
        ...result.periodicity.map(percentage100)]);

      downloadCsv([periodRanges, globalValues, ...values], t("processRelationship.tabulator.csvFileName"));
    };

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    return (
      <GridContainer>
        <GridItem>
          <Button simple size="lg" color="info" onClick={history.goBack}>
            <ArrowBackIcon />
            <span>{t("processRelationship.back")}</span>
          </Button>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <TableChartIcon />
              </CardIcon>
              <div style={styles.left}>
                <h4 style={styles.cardTitle}>
                  {`${t("processRelationship.tabulator.title", {
                    periodicity: labelPeriodicity,
                  })} ${processRelationship.name}`}
                </h4>
              </div>
              <div style={styles.right}>
                <CardIcon
                  color="info"
                  style={styles.pointer}
                  onClick={downloadCsvData}
                >
                  <GetAppIcon />
                </CardIcon>
              </div>
            </CardHeader>
            <CardBody>
              <GridContainer style={styles.marginBottom}>
                <GridItem xs={12}>
                  <div style={styles.root}>
                    <AppBar position="static" color="default">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label={"Calculos"} {...a11yProps(0)} />
                        <Tab label={"Reportes"} {...a11yProps(1)} />
                      </Tabs>
                    </AppBar>
                    <SwipeableViews
                      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                      index={value}
                      onChangeIndex={handleChangeIndex}
                    >
                      <TabPanel value={value} index={0} dir={theme.direction}>
                        <GridContainer>
                          <GridItem xs={3}>
                            <Datetime
                              name="created_gt"
                              timeFormat={false}
                              defaultValue={filterDates.created_gt}
                              onChange={(e) => {
                                if (e === "") {
                                  setFilterDates({
                                    ...filterDates,
                                    created_gt: e,
                                  });
                                } else {
                                  setFilterDates({
                                    ...filterDates,
                                    created_gt: e._d,
                                  });
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem xs={3}>
                            <Datetime
                              name="created_lte"
                              timeFormat={false}
                              defaultValue={filterDates.created_lte}
                              onChange={(e) => {
                                if (e === "") {
                                  setFilterDates({
                                    ...filterDates,
                                    created_lte: e,
                                  });
                                } else {
                                  setFilterDates({
                                    ...filterDates,
                                    created_lte: e._d,
                                  });
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            {loading ? (
                              <div style={styles.progressCnt}>
                                <CircularProgress style={styles.progress} />
                              </div>
                            ) : resultsError ? (
                              <div>
                                <Alert variant="outlined" severity="error">
                                  {t("common:common.error.title")}
                                </Alert>
                              </div>
                            ) : (
                              <TabulatorProcessRelationshipTable
                                processRelationship={processRelationship}
                                data={data}
                                filterDates={filterDates}
                              />
                            )}
                          </GridItem>
                        </GridContainer>
                      </TabPanel>
                      <TabPanel value={value} index={1} dir={theme.direction}>
                        <TablePagination
                          data={emailReportsResult.data ? emailReportsResult.data : []}
                          paginationAction={paginationAction}
                          filter={filterReport}
                        />
                        {emailReportsResult.loading ? (
                          <div style={styles.progressCnt}>
                            <CircularProgress style={styles.progress} />
                          </div>
                        ) : emailReportsResult.errorEemailReports ? (
                          <div>
                            <Alert variant="outlined" severity="error">
                              {t("common:common.error.title")}
                            </Alert>
                          </div>
                        ) : (
                          <EmailReportTable
                            emailReports={emailReportsResult.data ? emailReportsResult.data : []}
                            generateReport={generateReport}
                          />
                        )}
                      </TabPanel>
                    </SwipeableViews>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const styles = {
  cardTitle: {
    color: "#3C4858"
  },
  progress: {
    margin: '16px'
  },
  progressCnt:{
    width: '100px !important',
    marginLeft: '50%',
    marginRight: '50%'
  },
  cardHeight: {
    height: "430px"
  },
  left: {
    float: "left"
  },
  right: {
    float: "right"
  },
  pointer: {
    cursor: "pointer"
  },
  marginBottom: {
    marginBottom: "25px"
  },
  root: {
    margin: 0,
    padding: "2px",
  },
};

export default TabulatorProcessRelationshipView;
