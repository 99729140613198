
// @material-ui/icons
import CardTravel from "@material-ui/icons/CardTravel";
import FiberNew from "@material-ui/icons/FiberNew";
import Person from '@material-ui/icons/Person';
import Done from '@material-ui/icons/Done';
import DoneAllOutlined from '@material-ui/icons/DoneAllOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';

import {TICKET_REPORT_STATUS} from "../../constants";

export const getColorByStatus = status => {
  if (status === TICKET_REPORT_STATUS.CREATED) {
    return 'warning';
  }
  if (status === TICKET_REPORT_STATUS.ASSIGNED) {
    return 'primary';
  }
  if (status === TICKET_REPORT_STATUS.RESOLVED) {
    return 'info';
  }
  if (status === TICKET_REPORT_STATUS.ACCEPTED) {
    return 'success';
  }
  if (status === TICKET_REPORT_STATUS.REJECTED) {
    return 'danger';
  }
};

export const getIconByStatus = (status) => {
  switch (status) {
    case TICKET_REPORT_STATUS.CREATED:
      return FiberNew;
    case TICKET_REPORT_STATUS.ASSIGNED:
      return Person;
    case TICKET_REPORT_STATUS.RESOLVED:
      return Done;
    case TICKET_REPORT_STATUS.ACCEPTED:
      return DoneAllOutlined;
    case TICKET_REPORT_STATUS.REJECTED:
      return DeleteOutlined;
    default:
      return CardTravel;
  }
};
