import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { orderBy } from "lodash";

import {
  Box,
  Paper,
  Typography,
  Divider,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";
import ReactTable from "react-table";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/Button";

import DivCenter from "../../components/styled-components/DivCenter";
import TextFieldContentWidth from "./components/TextFieldContentWidth";

import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { baseDateAnswerFormat } from "../../utils/dates/dates";
import ProratedObjectiveModal from "../../modals/ProratedObjectiveModal";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import WarningChangeObjective from "./components/WarningChangeObjective";
import { removeFloatZero } from "../../utils/numeral/numeral";

const CounterReportView = ({
  soCounterReportData,
  calculatedIndicatorResults,
  baseModels,
  generateSoCounterReport,
  updateVCustomObjectives,
  canGenerateReport,
  getIndicatorRangeResult,
  dataIndicatorResult,
  isModify,
  setIsModify,
  loadingIndicatorResult,
}) => {
  const { t } = useTranslation("so");
  const { visits, questionEngineModules } = baseModels;
  const [currentEditObjective, setCurrentEditObject] = useState({
    referenceId: "",
  });
  const [currentViewIndicator, setCurrentViewIndicator] = useState(null);
  const [open, setOpen] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [editIndicatorObjective, setEditIndicatorObjective] = useState(null);

  const [customIndicatorObjectives, setCustomIndicatorObjectives] = useState(
    soCounterReportData.customIndicatorObjectives
      ? soCounterReportData.customIndicatorObjectives.map((objective) => ({
          indicatorId: objective.indicatorId,
          order: objective.order,
          value: objective.targetValue,
          proratedObjective: objective.proratedObjective
            ? objective.proratedObjective
            : [],
        }))
      : []
  );

  const DivDoubleClick = ({ callback, children }) => {
    const [clickCount, setClickCount] = useState(0);
    return (
      <DivCenter
        onClick={() => {
          if (clickCount === 0) {
            setClickCount(1);
            setTimeout(() => {
              setClickCount(0);
            }, 200);
          } else {
            callback();
          }
        }}
      >
        {children}
      </DivCenter>
    );
  };

  const applyRangePending = (targetValue, indicator, proratedConfig) => {
    setIsModify(true);
    setShowAlert(true);
    const renderProratedConfig = proratedConfig.map((config) => {
      delete config.fullfilment;

      return {
        ...config,
      };
    });

    const currentPeriodEdit = {
      indicatorId: indicator._id,
      order: indicator.order,
      value: targetValue,
      proratedObjective: renderProratedConfig,
    };

    const periodPending = calculatedCounterTable.filter(
      (period) => period.rangePending && !period.rangePassed
    );

    const customRangePending = periodPending.map((period) => {
      return {
        indicatorId: indicator._id,
        order: period.infoRow.order,
        value: targetValue,
        proratedObjective: renderProratedConfig.map((result) => {
          return { ...result, result: 0 };
        }),
      };
    });

    const matchNewCustom = customRangePending.filter((customRange) =>
      customIndicatorObjectives.some(
        (custom) =>
          custom.order === customRange.order &&
          custom.indicatorId === custom.indicatorId
      )
    );

    const filterCustom = customIndicatorObjectives.filter((custom) =>
      customRangePending.some(
        (customRange) =>
          custom.order !== customRange.order &&
          custom.indicatorId !== customRange.indicatorId
      )
    );

    const newCustom = customRangePending.filter(
      (custom) =>
        !matchNewCustom.some(
          (customRange) =>
            custom.order === customRange.order &&
            custom.indicatorId === customRange.indicatorId
        )
    );

    setCustomIndicatorObjectives([
      ...matchNewCustom,
      ...filterCustom,
      ...newCustom,
      currentPeriodEdit,
    ]);
  };

  const getSelectedReferenceByType = (referenceId, type, title) => {
    if (!referenceId) {
      return null;
    }
    if (type === "VisitCounter") {
      const referenceFound = visits.find((visit) => visit._id === referenceId);
      return title ? title : (referenceFound ? referenceFound.name : "");
    } else {
      const referenceFound = questionEngineModules.find(
        (module) => module.module._id === referenceId
      );
      return title ? title : (referenceFound ? referenceFound.module.name : "");
    }
  };
  const calculatedCounterTable = useMemo(() => {
    const calculatedRanges = soCounterReportData.periodicityRanges.map(
      (range) => {
        const indicatorInfo = soCounterReportData.indicators.reduce(
          (result, indicator) => {
            const indicatorId = indicator._id;
            const rangeFound = calculatedIndicatorResults.find(
              (calculatedIndicator) =>
                calculatedIndicator.order === range.order &&
                calculatedIndicator.indicatorId === indicator._id
            );

            const customFound = customIndicatorObjectives.find(
              (custom) =>
                custom.order === range.order &&
                custom.indicatorId === indicator._id
            );

            const objectuveValue = customFound
              ? customFound.value
              : indicator.targetValue;

            const currentResult = rangeFound ? rangeFound.result : 0;

            const indicatorInfo = {
              [indicatorId]: {
                real: rangeFound,
                objective: indicator.targetValue,
                fullfilment: `${removeFloatZero(objectuveValue > 0
                  ? (100 * currentResult) / objectuveValue
                  : 0
                )} %`,
              },
            };
            return {
              ...result,
              ...indicatorInfo,
            };
          },
          {}
        );
        const currentDate = Date.now();
        const initialDate = new Date(range.initialDate);
        const finalDate = new Date(range.finalDate);
        return {
          infoRow: range,
          rangePassed: currentDate > initialDate,
          rangePending: currentDate < finalDate,
          ...indicatorInfo,
        };
      }
    );
    const objectiveTotal = (rangesLength, indicatorId, indicatorObjective) => {
      const customObjectivesSum = customIndicatorObjectives.reduce(
        (result, currentObjective) => {
          if (indicatorId === currentObjective.indicatorId) {
            return result + currentObjective.value;
          }
          return result;
        },
        0
      );
      return (
        customObjectivesSum +
        (rangesLength - customIndicatorObjectives.length) * indicatorObjective
      );
    };
    const realTotal = (indicatorId) => {
      return calculatedIndicatorResults
        .filter(
          (indicatorResult) => indicatorResult.indicatorId === indicatorId
        )
        .reduce(
          (result, indicatorResult) => result + indicatorResult.result,
          0
        );
    };
    const getTotalRow = () => {
      return soCounterReportData.indicators.reduce((result, indicator) => {
        const totalObjective = objectiveTotal(
          soCounterReportData.periodicityRanges.length,
          indicator._id,
          indicator.targetValue
        );

        const totalReal = realTotal(indicator._id);
        const indicatorInfo = {
          [indicator._id]: {
            real: totalReal,
            objective: totalObjective,
            fullfilment: `${removeFloatZero((totalReal / totalObjective) * 100)} %`,
          },
        };
        return {
          ...result,
          ...indicatorInfo,
        };
      }, {});
    };
    const calculatedTotals = [getTotalRow()];

    // Custom order
    const actualRange = calculatedRanges.filter(
      (range) => range.rangePassed && range.rangePending
    );
    const passedRanges = orderBy(
      calculatedRanges.filter(
        (range) => range.rangePassed && !range.rangePending
      ),
      ["infoRow.initialDate"],
      ["desc"]
    );
    const pendingRanges = orderBy(
      calculatedRanges.filter(
        (range) => range.rangePending && !range.rangePassed
      ),
      ["infoRow.initialDate"],
      ["asc"]
    );

    // Set section headers
    if (actualRange.length > 0) {
      let totalRows = calculatedTotals.map((item, index) => {
        if (index === 0) {
          item.actualTitle = true;
        }
        return item;
      });
      let actualRows = actualRange;
      let passedRows = passedRanges;
      let pendingRows = pendingRanges;
      if (passedRanges.length > 0) {
        actualRows = actualRange.map((item, index) => {
          if (index === 0) {
            item.passedTitle = true;
          }
          return item;
        });
      }
      if (pendingRanges.length > 0) {
        if (passedRanges.length > 0) {
          passedRows = passedRows.map((item, index) => {
            if (index === passedRows.length - 1) {
              item.pendingTitle = true;
            }
            return item;
          });
        } else {
          totalRows = calculatedTotals.map((item, index) => {
            if (index === 0) {
              item.pendingTitle = true;
            }
            return item;
          });
        }
      }
      return [...totalRows, ...actualRows, ...passedRows, ...pendingRows];
    } else {
      let totalRows = calculatedTotals;
      let actualRows = actualRange;
      let passedRows = passedRanges;
      let pendingRows = pendingRanges;
      if (passedRanges.length > 0) {
        totalRows = totalRows.map((item, index) => {
          if (index === 0) {
            item.passedTitle = true;
          }
          return item;
        });
      }
      if (pendingRanges.length > 0) {
        if (passedRanges.length > 0) {
          passedRows = passedRows.map((item, index) => {
            if (index === passedRows.length - 1) {
              item.pendingTitle = true;
            }
            return item;
          });
        } else {
          totalRows = calculatedTotals.map((item, index) => {
            if (index === 0) {
              item.pendingTitle = true;
            }
            return item;
          });
        }
      }
      return [...totalRows, ...actualRows, ...passedRows, ...pendingRows];
    }
  }, [
    soCounterReportData,
    customIndicatorObjectives,
    calculatedIndicatorResults,
  ]);
  const calculatedColumns = useMemo(() => {
    const labelColumn = {
      Header: () => {
        return <DivCenter>Periodo</DivCenter>;
      },
      Cell: (rowInfo) => {
        const { original, index } = rowInfo;
        if (index === 0) {
          return (
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: 500,
                color: "#3C4858",
              }}
            >
              Total
            </Typography>
          );
        }
        return (
          <Box>
            {baseDateAnswerFormat(original.infoRow.initialDate)}
            <br />
            {baseDateAnswerFormat(original.infoRow.finalDate)}
          </Box>
        );
      },
      filterable: false,
      resizable: false,
      sortable: false,
      style: { textAlign: "center", whiteSpace: "unset" },
    };
    const indicatorColumns = soCounterReportData.indicators.map(
      (indicator) => ({
        id: indicator._id,
        indicatorId: indicator._id,
        filterable: false,
        resizable: false,
        sortable: false,
        Header: () => {
          return (
            <DivCenter>
              <Tooltip
                title={getSelectedReferenceByType(
                  indicator.referenceId,
                  indicator.type,
                  indicator.title
                )}
              >
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: 500,
                    color: "#3C4858",
                  }}
                >
                  {t(
                    `so.counterReports.reportView.mainIndicatorType.${indicator.type}`
                  )}
                  <br />
                  {getSelectedReferenceByType(
                    indicator.referenceId,
                    indicator.type,
                    indicator.title
                  )}
                </Typography>
              </Tooltip>
            </DivCenter>
          );
        },
        columns: [
          {
            Header: <DivCenter>Cumplimiento</DivCenter>,
            filterable: false,
            resizable: false,
            sortable: false,
            borderLeft: true,
            Cell: (rowInfo) => {
              const { original, column, index } = rowInfo;
              const referenceId = column.parentColumn.id;

              if (index > 0) {
                return (
                  <DivCenter>{original[referenceId].fullfilment}</DivCenter>
                );
              }
              return (
                <DivCenter>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#3C4858",
                    }}
                  >
                    {original[referenceId].fullfilment}
                  </Typography>
                </DivCenter>
              );
            },
          },
          {
            Header: <DivCenter>Objetivo</DivCenter>,
            filterable: false,
            resizable: false,
            sortable: false,
            Cell: (rowInfo) => {
              const { original, column, index } = rowInfo;
              const { indicatorId } = column.parentColumn;
              const referenceId = column.parentColumn.id;
              if (index > 0) {
                const currentOrder = original.infoRow.order;
                const customObjectiveFound = customIndicatorObjectives.find(
                  (custom) =>
                    custom.indicatorId === indicatorId &&
                    custom.order === currentOrder
                );
                const currentValue = customObjectiveFound
                  ? customObjectiveFound.value
                  : original[referenceId].objective;

                if (
                  currentEditObjective.indicatorId === indicatorId &&
                  currentEditObjective.order === currentOrder
                ) {
                  return (
                    <DivCenter>
                      <TextFieldContentWidth
                        value={currentValue}
                        onChange={(newObjective) => {
                          setIsModify(true);
                          setShowAlert(true);
                          const customIndicatorObjectiveFound = customIndicatorObjectives.find(
                            (custom) =>
                              custom.indicatorId === indicatorId &&
                              custom.order === currentOrder
                          );
                          if (customIndicatorObjectiveFound) {
                            setCustomIndicatorObjectives(
                              customIndicatorObjectives.map((custom) => {
                                if (
                                  custom.indicatorId === indicatorId &&
                                  custom.order === currentOrder
                                ) {
                                  if (custom.proratedObjective) {
                                    delete custom.proratedObjective;
                                  }
                                  return {
                                    ...custom,
                                    value: newObjective,
                                  };
                                }
                                return custom;
                              })
                            );
                          } else {
                            if (original[referenceId].real?.proratedObjective) {
                              delete original[referenceId].real
                                ?.proratedObjective;
                            }

                            setCustomIndicatorObjectives([
                              ...customIndicatorObjectives,
                              {
                                order: currentOrder,
                                indicatorId: indicatorId,
                                value: newObjective,
                                proratedObjective:
                                  original[referenceId].real
                                    ?.proratedObjective ?? [],
                              },
                            ]);
                          }
                        }}
                        endEdit={() => {
                          setCurrentEditObject({
                            indicatorId: "",
                          });
                        }}
                      />
                    </DivCenter>
                  );
                } else {
                  const indicator = soCounterReportData.indicators.find(
                    (indicator) => indicator._id === indicatorId
                  );

                  if (!original.rangePassed || original.rangePending) {
                    if (indicator?.segment || indicator?.keyField) {
                      return (
                        <DivCenter>
                          {currentValue}
                          <Button
                            justIcon
                            round
                            simple
                            color="info"
                            onClick={() => {
                              /* setCurrentEditObject({
                                indicatorId: indicatorId,
                                order: currentOrder,
                              }); */

                              setEditIndicatorObjective({
                                indicatorId: indicatorId,
                                order: currentOrder,
                              });

                              setOpenWarning(true);
                            }}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            justIcon
                            round
                            simple
                            color="info"
                            onClick={() => {
                              const indicator = soCounterReportData.indicators.find(
                                (indicator) => indicator._id === indicatorId
                              );
                              const customIndicator = customIndicatorObjectives.find(
                                (custom) =>
                                  custom.indicatorId === indicator._id &&
                                  custom.order === currentOrder
                              );
                              if (customIndicator) {
                                setCurrentViewIndicator({
                                  baseInfo: { ...original[referenceId].real },
                                  indicator: {
                                    ...indicator,
                                    ...customIndicator,
                                  },
                                  infoRow: { ...original.infoRow },
                                });
                              } else {
                                setCurrentViewIndicator({
                                  baseInfo: { ...original[referenceId].real },
                                  indicator: { ...indicator },
                                  infoRow: { ...original.infoRow },
                                });
                              }

                              setOpen(true);
                              setCanEdit(true);
                            }}
                          >
                            <VisibilityIcon />
                          </Button>
                        </DivCenter>
                      );
                    } else {
                      return (
                        <DivDoubleClick
                          callback={() => {
                            setCurrentEditObject({
                              indicatorId: indicatorId,
                              order: currentOrder,
                            });
                          }}
                        >
                          {currentValue}
                          <Button
                            justIcon
                            round
                            simple
                            color="info"
                            onClick={() => {
                              setCurrentEditObject({
                                indicatorId: indicatorId,
                                order: currentOrder,
                              });
                            }}
                          >
                            <EditIcon />
                          </Button>
                        </DivDoubleClick>
                      );
                    }
                  }

                  if (original.rangePassed || !original.rangePending) {
                    if (indicator?.segment || indicator?.keyField) {
                      return (
                        <DivCenter>
                          {currentValue}
                          <Button
                            justIcon
                            round
                            simple
                            color="info"
                            onClick={() => {
                              const indicator = soCounterReportData.indicators.find(
                                (indicator) => indicator._id === indicatorId
                              );
                              const customIndicator = customIndicatorObjectives.find(
                                (custom) =>
                                  custom.indicatorId === indicator._id &&
                                  custom.order === currentOrder
                              );
                              if (customIndicator) {
                                setCurrentViewIndicator({
                                  baseInfo: { ...original[referenceId].real },
                                  indicator: {
                                    ...indicator,
                                    ...customIndicator,
                                  },
                                  infoRow: { ...original.infoRow },
                                });
                              } else {
                                setCurrentViewIndicator({
                                  baseInfo: { ...original[referenceId].real },
                                  indicator: { ...indicator },
                                  infoRow: { ...original.infoRow },
                                });
                              }

                              setOpen(true);
                              setCanEdit(false);
                            }}
                          >
                            <VisibilityIcon />
                          </Button>
                        </DivCenter>
                      );
                    }
                  }
                  return <DivCenter>{currentValue} </DivCenter>;
                }
              }
              return (
                <DivCenter>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#3C4858",
                    }}
                  >
                    {original[referenceId].objective}
                  </Typography>
                </DivCenter>
              );
            },
          },
          {
            Header: <DivCenter>Ejecución</DivCenter>,
            filterable: false,
            resizable: false,
            sortable: false,
            Cell: (rowInfo) => {
              const { original, column, index } = rowInfo;
              const referenceId = column.parentColumn.id;
              if (index > 0) {
                const { rangePassed, rangePending } = original;
                if (rangePending && !rangePassed) {
                  return <DivCenter>0</DivCenter>;
                }
                if (original[referenceId].real) {
                  return (
                    <DivCenter>
                      {removeFloatZero(original[referenceId].real.result)}
                    </DivCenter>
                  );
                } else {
                  return <LinearProgress />;
                }
              }
              // Total Row
              return (
                <DivCenter>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#3C4858",
                    }}
                  >
                    {removeFloatZero(original[referenceId].real)}
                  </Typography>
                </DivCenter>
              );
            },
          },
        ],
      })
    );
    return [
      {
        expander: true,
        Expander: (props) => {
          return null;
        },
      },
      labelColumn,
      ...indicatorColumns,
    ];
  }, [soCounterReportData, currentEditObjective, customIndicatorObjectives]);

  return (
    <GridContainer justify={"center"}>
      <GridItem xs={12} sm={12} md={2} lg={2}>
        <Card>
          <CardBody>
            <Button
              size="sm"
              color={"info"}
              fullWidth
              onClick={() => {
                generateSoCounterReport(
                  soCounterReportData,
                  customIndicatorObjectives.map((objective) => ({
                    indicatorId: objective.indicatorId,
                    order: objective.order,
                    targetValue: objective.value,
                    proratedObjective: objective?.proratedObjective ?? [],
                  })),
                  calculatedIndicatorResults
                );
              }}
              disabled={canGenerateReport}
            >
              Generar Reporte
            </Button>
            <Button
              size="sm"
              color={!isModify ? "success" : "warning"}
              style={{
                color: !isModify ? "#fff" : "#000",
              }}
              fullWidth
              onClick={() => {
                if (customIndicatorObjectives.length > 0) {
                  updateVCustomObjectives(
                    customIndicatorObjectives.map((objective) => ({
                      indicatorId: objective.indicatorId,
                      order: objective.order,
                      targetValue: objective.value,
                      proratedObjective: objective.proratedObjective
                        ? objective.proratedObjective
                        : [],
                    }))
                  );
                }
              }}
            >
              Guardar Cambios
            </Button>
            <Divider />
            <DivCenter>
              {`Puedes editar un valor objetivo dando doble click y para finalizar presionar <Enter>`}
            </DivCenter>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem
        xs={12}
        sm={12}
        md={soCounterReportData.indicators.length > 1 ? 10 : 6}
        lg={soCounterReportData.indicators.length > 1 ? 8 : 4}
      >
        <Paper>
          <ReactTable
            data={calculatedCounterTable}
            columns={calculatedColumns}
            pageSize={calculatedCounterTable?.length || 0}
            showPaginationTop={false}
            showPaginationBottom={false}
            SubComponent={({ original }) => {
              if (
                original.pendingTitle ||
                original.passedTitle ||
                original.actualTitle
              ) {
                return (
                  <Box
                    borderTop={1}
                    borderColor="grey.200"
                    style={{
                      padding: "20px",
                      marginLeft: "50px",
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "18px",
                        fontWeight: 500,
                        color: "#3C4858",
                      }}
                    >
                      {original.pendingTitle ? "Periodos Pendientes" : ""}
                      {original.passedTitle ? "Periodos Pasados" : ""}
                      {original.actualTitle ? "Periodo Actual" : ""}
                    </Typography>
                  </Box>
                );
              }
              return [];
            }}
            defaultExpanded={calculatedCounterTable.map((element, index) => {
              return { index: true };
            })}
            expanded={calculatedCounterTable.map((element, index) => {
              return { index: true };
            })}
            Expander={(pops) => null}
            getTdProps={(state, rowInfo, column, instance) => {
              if (column.borderLeft) {
                return {
                  style: {
                    borderLeft: "1px solid #bdbdbd",
                  },
                };
              }
              return {};
            }}
            className="-highlight"
          />
        </Paper>
        <ProratedObjectiveModal
          open={open}
          setOpen={setOpen}
          currentViewIndicator={currentViewIndicator}
          getIndicatorRangeResult={getIndicatorRangeResult}
          dataIndicatorResult={dataIndicatorResult}
          customIndicatorObjectives={customIndicatorObjectives}
          setCustomIndicatorObjectives={setCustomIndicatorObjectives}
          canEdit={canEdit}
          setCanEdit={setCanEdit}
          applyRangePending={applyRangePending}
          setIsModify={setIsModify}
          setShowAlert={setShowAlert}
          loadingIndicatorResult={loadingIndicatorResult}
        />

        <WarningChangeObjective
          open={openWarning}
          setOpen={setOpenWarning}
          proratedConfig={(value) => {
            if (value) {
              setCurrentEditObject({
                ...editIndicatorObjective,
              });
            } else {
              setEditIndicatorObjective(null);
            }
          }}
        />

        <Snackbar
          place="tc"
          color="warning"
          icon={AddAlert}
          message="Hay cambios pendiente de guardar del reporte."
          style={{
            color: "#000",
          }}
          open={showAlert}
          closeNotification={() => setShowAlert(false)}
          close
          autoHideDuration={6000}
        />
      </GridItem>
    </GridContainer>
  );
};

CounterReportView.propTypes = {
  soCounterReportData: PropTypes.object.isRequired,
  calculatedIndicatorResults: PropTypes.array.isRequired,
  baseModels: PropTypes.object.isRequired,
  generateSoCounterReport: PropTypes.func.isRequired,
  updateVCustomObjectives: PropTypes.func.isRequired,
  canGenerateReport: PropTypes.bool.isRequired,
  getIndicatorRangeResult: PropTypes.func.isRequired,
  dataIndicatorResult: PropTypes.func.isRequired,
  isModify: PropTypes.bool.isRequired,
  setIsModify: PropTypes.func.isRequired,
  loadingIndicatorResult: PropTypes.bool.isRequired,
};

const style = {
  cardTitle: {
    color: "#3C4858",
  },
  progress: {
    margin: "16px",
  },
  progressCnt: {
    width: "100px !important",
    marginLeft: "50%",
    marginRight: "50%",
  },
  cardHeight: {
    height: "430px",
  },
  left: {
    float: "left",
  },
  right: {
    float: "right",
  },
};

export default CounterReportView;
