import React from 'react';
import PropTypes from 'prop-types';

// custom components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from "components/Card/CardFooter.jsx";
import Position from 'components/Position';
import DivCenter from "components/styled-components/DivCenter";
import {useTranslation} from "react-i18next";

const TokenFieldAnswerView = ({answerData}) => {
  const { t, } = useTranslation('question-engine-module-answer');
  return (
    <Card>
      <CardBody>
        <DivCenter>
          <h4>{answerData.answer.TokenFieldAnswer}</h4>
        </DivCenter>
      </CardBody>
      <CardFooter>
        <Position left>
          <small>{`${t("question-engine-module-answer.view.field")} : ${
            answerData.questionLabel
          }`}</small>
        </Position>
        <Position right>
          <small>{`${t("question-engine-module-answer.view.keyField")} : ${
            answerData.key
          }`}</small>
        </Position>
      </CardFooter>
    </Card>
  );
};

TokenFieldAnswerView.propTypes = {

};

export default TokenFieldAnswerView;
