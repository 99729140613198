import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import Checkbox from "@material-ui/core/Checkbox";
import VisibilityIcon from '@material-ui/icons/Visibility';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import withStyles from "@material-ui/core/styles/withStyles";
import tasksStyle from "assets/jss/material-dashboard-pro-react/components/tasksStyle.jsx";
import Button from "../Button";

function InformationCheckbox({classes, name, isUnique, isActive, onClickCheckbox, onViewClick}){
  return (
    <div className={classes.rowContainer}>
      <div className={classes.informationContainer}>
        <div className={classes.nameContainer} style={{"float":"left"}}>{name}</div>
        <div className={classes.informationContainer} style={{"float":"right"}}>
          <Checkbox
            icon={ isUnique ? (<RadioButtonUncheckedIcon fontSize="small" />) : (<CheckBoxOutlineBlankIcon fontSize="small" />)}
            checked={isActive}
            onClick={onClickCheckbox}
            checkedIcon={ isUnique ? (<CheckCircleOutlineIcon fontSize="small" />) : (<CheckIcon className={classes.checkedIcon} />) }
            classes={{ 
              checked: classes.checked, 
              root: classes.checkRoot
            }}
          />
          <Button 
            justIcon 
            simple 
            size="sm" 
            color="info" 
            onClick={onViewClick}>
            <VisibilityIcon />
          </Button>
        </div>
      </div>
    </div>
  );
}

const style = {
  buttonIcon:{
    border: "0px"
  },
  informationContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  nameContainer: {
    width: "150px",
  },
  rowContainer:{
    margin: "0px 30px"
  }
};

InformationCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  isActive: PropTypes.bool,
  isUnique: PropTypes.bool,
  onClickCheckbox: PropTypes.func,
  onViewClick: PropTypes.func
};

export default withStyles({...tasksStyle, ...style})(InformationCheckbox);
