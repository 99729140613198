import React from "react";
import ReactTable from "react-table";
import DivCenter from "../../components/styled-components/DivCenter";
import { useTranslation } from "react-i18next";
import moment from "moment";

const getColumnWidth = (data, accessor, headerText) => {
  const maxWidth = 600;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...data.map((row) => (`${row[accessor]}` || "").length),
    headerText.length
  );
  console.log(headerText, Math.min(maxWidth, cellLength * magicSpacing));
  return Math.min(maxWidth, cellLength * magicSpacing);
};

const DetailProcessRelationshipTable = ({
  processRelationship,
  data,
  item,
}) => {
  const { t } = useTranslation("processRelationship");
  let tableHeaderData = [{
    _id: '0',
    name: item.name,
    periodicity: data.resultsTotal
  }];
  if(data.penalties.filter(item => item !== 0 && item !== null).length > 0) {
    tableHeaderData.push({
      _id: '1',
      name: t("processRelationship.tabulator.penalties"),
      periodicity: data.penalties
    });
  }
  const tableData = [
      ...tableHeaderData,
      ...data.results
  ];

  const columns = data.periodicityRanges.map((periodicityRange, index) => {
    return {
      Header: <DivCenter>
        <DivCenter>{moment(periodicityRange.startDate).format('DD/MM/YYYY')}</DivCenter>
        <DivCenter>{moment(periodicityRange.endDate).format('DD/MM/YYYY')}</DivCenter>
      </DivCenter>,
      accessor: "column",
      Cell: ({ original }) => {
        return original.periodicity[index] !== null
          ? `${original.periodicity[index].toFixed(2)} %`
          : 'NA';
      },
      filterable: false,
      sortable: false,
      style: { textAlign: "center"},
      getProps: (state, rowInfo, column) => {
        if(rowInfo === undefined) {
          return {};
        }
        const {original} = rowInfo;
        let newStyle = {};
        switch (original._id) {
          case '0':
            newStyle['backgroundColor'] = 'rgb(112 193 231 / 34%)';
            break;
          case '1':
            newStyle['backgroundColor'] = 'rgb(112 193 231 / 34%)';
            const value = original.periodicity[index];
            if(value > 0) {
              newStyle['color'] = 'red';
            }
            break;
        }
        return {style: newStyle};
      },
      //width: getColumnWidth(data, "columns", header),
    };
  });

  columns.unshift({
    Header: (
      <DivCenter>
        {`${t("processRelationship.tabulator.list.columns.visits")}`}
      </DivCenter>
    ),
    accessor: "name",
    filterable: false,
    sortable: false,
    style: { textAlign: "center" },
    getProps: (state, rowInfo, column) => {
      if(rowInfo === undefined) {
        return {};
      }
      const {original} = rowInfo;
      let newStyle = {};
      switch (original._id) {
        case '0':
          newStyle['backgroundColor'] = 'rgb(112 193 231 / 34%)';
          break;
        case '1':
          newStyle['backgroundColor'] = 'rgb(112 193 231 / 34%)';
          break;
      }
      return {style: newStyle};
    },
    width: getColumnWidth(
      tableData,
      "name",
      `${t("processRelationship.tabulator.list.columns.visits")}`
    ),
  });

  return (
    <ReactTable
      data={tableData}
      columns={columns}
      defaultPageSize={data.length}
      resizable={false}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
    />
  );
};

export default DetailProcessRelationshipTable;
