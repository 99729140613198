import React, {useState} from 'react';
import Moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useQuery, useSubscription, useMutation} from "react-apollo-hooks";

import withStyles from '@material-ui/core/styles/withStyles';

// own components
import TicketConfigView from './../../Views/Tickets/TicketConfigView';

// Local state
import useGlobal from '../../store';

import {APP_SEGMENTS_ALL, TICKET_REPORTS_FEED, USER_MOBILES_FEED} from './Queries';
import {TICKET_REPORT_COMMENT_CHANGED, TICKET_REPORTS_CHANGED_SUBSCRIPTION} from "./Subscriptions";
import {TICKET_REPORT_STATUS} from "../../constants";
import {reportCreationFailedNotification, reportCreationOnProgressNotification, ticketReportChangedNotification, ticketReportCommentAddedNotification} from "../../utils/snackNotifications";
import { GENERATE_TICKET_REPORT } from './Mutations';
import { useCallback } from 'react';

function MaintenanceTicket(props) {
  const [ session, globalActions] = useGlobal(
    state => state.session,
    actions => actions
  );
  
  const { t, } = useTranslation('tickets');
  const appId = session.user ? session.appRef._id : '';
  const ticketId = props.match.params.id;
  
  const ticketConfig = props?.location?.state?.ticket;
  

  const [currentFilter, setCurrentFilter] = useState({
    app: appId,
    ticket: ticketId,
    status: [TICKET_REPORT_STATUS.CREATED, TICKET_REPORT_STATUS.ASSIGNED, TICKET_REPORT_STATUS.RESOLVED],
    created_gte: Moment().startOf('day').subtract(30, 'days'),
    created_lte: Moment().endOf('day'),
    skip: 0,
    first: 15
  });

  const {
    data: dataTicketReports,
    error: errorTicketReports,
    loading: loadingTicketReports,
    refetch: refetchTicketReports
  } = useQuery(TICKET_REPORTS_FEED, {
    variables: currentFilter,
    fetchPolicy: 'network-only'
  }); 
  
  const {
    data: dataUserMobile,
    error: errorUserMobile,
    loading: loadingUserMobile,
    refetch: refetchUserMobile
  } = useQuery(USER_MOBILES_FEED, {
    variables: {
      where: {
        app: appId,
        archived: false
      }
    },
    fetchPolicy: "network-only"
  });
  const {
    data: dataSegments,
    loading: loadingSegments
  } = useQuery(APP_SEGMENTS_ALL, {
    variables: {
      "where": {
        "app": appId,
        "archived": false
      }
    }
  });

  const { data: dataFirst, error: errorFirst, loading: loadingFirst  } = useSubscription(TICKET_REPORTS_CHANGED_SUBSCRIPTION, {
    variables: {where: {app: appId}},
    onSubscriptionData: ({ client, subscriptionData }) => {
      const {operation, node} = subscriptionData.data.ticketReportChanged;
      ticketReportChangedNotification(t, globalActions, operation, node);
      //  refetch query
      refetchTicketReports(currentFilter);
    }
  });
  const {
    data: dataTicketReportComment,
    error: errorTicketReportComment,
    loading: loadingTicketReportComment
  } =
    useSubscription(TICKET_REPORT_COMMENT_CHANGED, {
      variables: {
        appId: appId,
        ticketId: ticketId
      },
      onSubscriptionData: ({ client, subscriptionData }) => {
        const {operation, node, ticketReport: ticketReportUpdate} = subscriptionData.data.ticketReportCommentChanged;
        const ticketReportFeed = client.readQuery({
          query: TICKET_REPORTS_FEED,
          variables: currentFilter
        });

        if (ticketReportFeed) {
          if (operation === 'Created') {
            ticketReportCommentAddedNotification(t, globalActions);
            client.writeQuery({
              query: TICKET_REPORTS_FEED,
              variables: currentFilter,
              data: {
                ...ticketReportFeed,
                ticketReports: ticketReportFeed.ticketReports.map((ticketReport) => {
                  if (ticketReport._id === ticketReportUpdate._id) {
                    const newComments = [...ticketReport.comments, node];
                    return {
                      ...ticketReport,
                      comments: newComments
                    }
                  }
                  return ticketReport;
                })
              }
            })
          } else if(operation === 'Deleted'){
            client.writeQuery({
              query: TICKET_REPORTS_FEED,
              variables: currentFilter,
              data: {
                ...ticketReportFeed,
                ticketReports: ticketReportFeed.ticketReports.map((ticketReport) => {
                  if (ticketReport._id === ticketReportUpdate._id) {
                    const newComments = ticketReport.comments.filter((comment) => {
                      return comment._id !== node._id;
                    });
                    return {
                      ...ticketReport,
                      comments: newComments
                    }
                  }
                  return ticketReport;
                })
              }
            })
          }
        }
      }
    });

  const createTicketReport = useMutation(GENERATE_TICKET_REPORT);

  const createcreateTicketReportXLS = useCallback(() => {
    let filters = {
      ...currentFilter
    };

    delete filters.skip;
    delete filters.first;

    const data = {
      data: {
        app: filters.app,
        session: {
          user: {
            _id: session.user._id
          }
        },
        dateRange: {
          created_gte: filters.created_gte,
          created_lte: filters.created_lte
        },
        query: {
          ...filters,
          status_in: filters.status,
          ticket: {
            _id: filters.ticket
          },
          timezone: Moment.tz.guess()
        }
      },
    }

    executeMutation(createTicketReport, data);
  }, [createTicketReport, currentFilter, session]);

  // Execute the mutation and trigger the notifications
  const executeMutation = (mutation, data) => {
    mutation({ variables: data}).then(
      result => {
        reportCreationOnProgressNotification(globalActions);
      },
      error => {
        console.log(error, data)
        reportCreationFailedNotification(globalActions);
      });
  };


  const paginationAction = async (values) => {
    setCurrentFilter(values);
  }

  if (errorTicketReports) {
    return <div>Error! {errorTicketReports.message}</div>;
  }

  return (
    <TicketConfigView
      ticketConfig={ticketConfig}
      data={dataTicketReports}
      loading={loadingTicketReports}
      loadingFilters={loadingSegments || loadingUserMobile}
      ticket={ticketId}
      app={appId}
      user={session.user}
      segments={loadingSegments ? [] : dataSegments.segments}
      userMobiles={loadingUserMobile ? []: dataUserMobile.userMobiles}
      currentFilter={currentFilter}
      paginationAction={paginationAction}
      filterChanged={(filter) => {
        const changedFilter = {
          ...currentFilter,
          skip: 0,
          status: filter.status,
          created_gte: filter.startDateCreated,
          created_lte: filter.endDateCreated,
          originalUserReported_user_in: filter.usersMobileReportedBy ?
            filter.usersMobileReportedBy.map((user) => user._id) : undefined,
          originalUserReported_segment: filter.segmentReportedBy ?
            filter.segmentReportedBy._id : undefined
        };

        if (ticketConfig.hasFolio) {
          changedFilter.folio = filter.folio;
        }

        setCurrentFilter(changedFilter);
        refetchTicketReports(changedFilter);
      }}
      filterRejected={() => {
        const changedFilter = {
          ...currentFilter,
          status: [TICKET_REPORT_STATUS.REJECTED]
        };
        setCurrentFilter(changedFilter);
        refetchTicketReports(changedFilter);
      }}
      filterAccepted={() => {
        const changedFilter = {
          ...currentFilter,
          status: [TICKET_REPORT_STATUS.ACCEPTED]
        };
        setCurrentFilter(changedFilter);
        refetchTicketReports(changedFilter);
      }}
      filterPending={() => {
        const changedFilter = {
          ...currentFilter,
          status: [TICKET_REPORT_STATUS.RESOLVED, TICKET_REPORT_STATUS.CREATED, TICKET_REPORT_STATUS.ASSIGNED]
        };
        setCurrentFilter(changedFilter);
        refetchTicketReports(changedFilter);
      }}
      createcreateTicketReportXLS={createcreateTicketReportXLS}
    />
  );
}

const styles = theme => ({});

export default withStyles(styles)(MaintenanceTicket);
