import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo-hooks";
import { useMutation } from "@apollo/react-hooks";
import { flatten, uniq } from "ramda";

import { LinearProgress } from "@material-ui/core";

import ChecklistReportView from "../../../Views/SO/Checklist/ChecklistReportView";

import useGlobal from "../../../store";
import { SO_CHECKLIST_REPORT_VIEW } from "../gql/Queries";
import {
  CALCULATE_SO_CHECKLIST_RANGE_RESULT,
  GENERATE_SO_CHECKLIST_REPORT,
} from "../gql/Mutations";

const COLUMNS_PAGE_SIZE = 10;
const keyResultHash = (keyResults) => {
  return keyResults.reduce((result, current) => {
    return {
      ...result,
      [current.keyAnswerName]: current.calculatedValue,
    };
  }, {});
};

const calculateTableBaseData = (initialKeyResults, calculatedKeyResults) => {
  const initialHash = initialKeyResults.reduce((result, rangeCalculated) => {
    return {
      ...result,
      [rangeCalculated.order]: keyResultHash(rangeCalculated.results),
    };
  }, {});
  const calculatedHash = calculatedKeyResults.reduce(
    (result, rangeCalculated) => {
      return {
        ...result,
        [rangeCalculated.order]: keyResultHash(rangeCalculated.results),
      };
    },
    {}
  );
  const calculatedKeyResultsTotal = {
    ...initialHash,
    ...calculatedHash,
  };

  const validKeyColumns = uniq(
    flatten(
      Object.values(calculatedKeyResultsTotal).map((calculatedResult) =>
        Object.keys(calculatedResult)
      )
    )
  );

  const validKeyTotals = validKeyColumns.reduce((result, currentKeyColumn) => {
    const resultsForKeyColumn = Object.keys(calculatedKeyResultsTotal)
      .map(
        (rangeOrder) => calculatedKeyResultsTotal[rangeOrder][currentKeyColumn]
      )
      .filter((columnResult) => !!columnResult);
    const keyColumnAverage = resultsForKeyColumn.reduce(
      (sum, current) => sum + current,
      0.0
    );
    return {
      ...result,
      [currentKeyColumn]:
        resultsForKeyColumn.length > 0
          ? keyColumnAverage / resultsForKeyColumn.length
          : null,
    };
  }, {});

  return [calculatedKeyResultsTotal, validKeyColumns, validKeyTotals];
};

const ChecklistReport = (props) => {
  const { checklistReport } = props.location.state;
  const [session, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const [
    performedIndicatorCalculations,
    setPerformedIndicatorCalculations,
  ] = useState([]);
  const [calculationsInfo, setCalculationsInfo] = useState({
    calculations: 0,
    fulfilled: 0,
    processed: false,
  });
  const [orderedRanges, setOrderedRanges] = useState(null);

  const {
    data: dataSOChecklistReport,
    error: errorSOChecklistReport,
    loading: loadingSOChecklistReport,
  } = useQuery(SO_CHECKLIST_REPORT_VIEW, {
    variables: {
      checklistReportId: checklistReport._id,
      app: session.user ? session.appRef._id : "",
    },
    fetchPolicy: "network-only",
  });
  const [calculateSoChecklistRange] = useMutation(
    CALCULATE_SO_CHECKLIST_RANGE_RESULT,
    {
      onCompleted: (mutationData) => {
        console.log("mutationData", mutationData);
        setCalculationsInfo({
          ...calculationsInfo,
          fulfilled: calculationsInfo.fulfilled + 1,
        });
        setPerformedIndicatorCalculations([
          ...performedIndicatorCalculations,
          mutationData.calculateChecklistRangeResult,
        ]);
      },
    }
  );
  const [generateSoChecklistReport] = useMutation(
    GENERATE_SO_CHECKLIST_REPORT,
    {
      onCompleted: () => {
        globalActions.notification.openNotification("tr", {
          message: "Procesando Reporte",
          color: "success",
        });
      },
    }
  );

  useEffect(() => {
    if (dataSOChecklistReport.soChecklistReportResult) {
      const { soChecklistReportResult } = dataSOChecklistReport;
      if (!orderedRanges) {
        setOrderedRanges(
          soChecklistReportResult.periodicityRanges.map((range) => {
            const currentDate = Date.now();
            const initialDate = new Date(range.initialDate);
            const finalDate = new Date(range.finalDate);
            return {
              range,
              rangePassed: currentDate > initialDate,
              rangePending: currentDate < finalDate,
            };
          })
        );
      } else {
        let sentCalculations = 0;
        orderedRanges.map((range) => {
          const periodicityRange = range.range;
          if (range.rangePassed && range.rangePending) {
            //  actual range
            sentCalculations++;
            calculateSoChecklistRange({
              variables: {
                data: {
                  soChecklistReportId: checklistReport._id,
                  range: {
                    finalDate: range.range.finalDate,
                    initialDate: range.range.initialDate,
                    order: range.range.order,
                    periodicity: range.range.periodicity,
                  },
                  save: false,
                },
              },
            });
          } else if (range.rangePassed && !range.rangePending) {
            //  passed ranges
            const calculationFound = soChecklistReportResult.calculatedKeyResults.find(
              (keyResult) => keyResult.order === range.range.order
            );
            if (!calculationFound) {
              //  actual range
              sentCalculations++;
              calculateSoChecklistRange({
                variables: {
                  data: {
                    soChecklistReportId: checklistReport._id,
                    range: {
                      finalDate: range.range.finalDate,
                      initialDate: range.range.initialDate,
                      order: range.range.order,
                      periodicity: range.range.periodicity,
                    },
                    save: true,
                  },
                },
              });
            }
          }
        });
        setCalculationsInfo({
          ...calculationsInfo,
          calculations: sentCalculations,
          processed: true,
        });
      }
    }
  }, [dataSOChecklistReport.soChecklistReportResult, orderedRanges]);

  if (
    !loadingSOChecklistReport &&
    calculationsInfo.processed &&
    calculationsInfo.calculations === calculationsInfo.fulfilled
  ) {
    const [
      calculatedKeyResults,
      validKeyColumns,
      validKeyTotals,
    ] = calculateTableBaseData(
      dataSOChecklistReport.soChecklistReportResult.calculatedKeyResults,
      performedIndicatorCalculations
    );
    return (
      <React.Fragment>
        {dataSOChecklistReport?.soChecklistReportResult ? (
          <React.Fragment>
            <ChecklistReportView
              soChecklistReportData={
                dataSOChecklistReport.soChecklistReportResult
              }
              calculatedKeyResults={calculatedKeyResults}
              validKeyColumns={validKeyColumns}
              validKeyTotals={validKeyTotals}
              calculationsInfo={calculationsInfo}
              columnsPageSize={
                validKeyColumns.length > COLUMNS_PAGE_SIZE
                  ? COLUMNS_PAGE_SIZE
                  : validKeyColumns.length
              }
              generateReport={({ actualRanges }) => {
                generateSoChecklistReport({
                  variables: {
                    where: {
                      _id: checklistReport._id,
                    },
                    data: {
                      userId: session.user._id,
                    },
                  },
                });
              }}
            />
          </React.Fragment>
        ) : (
          []
        )}
      </React.Fragment>
    );
  }
  if (errorSOChecklistReport) {
    return <div>{errorSOChecklistReport}</div>;
  }
  return (
    <React.Fragment>
      <LinearProgress />
      {calculationsInfo.processed ? (
        <h3
          style={{
            textAlign: "center",
          }}
        >
          {`Todavia faltan ${
            calculationsInfo.calculations - calculationsInfo.fulfilled
          } rangos por calcular...`}
        </h3>
      ) : (
        []
      )}
    </React.Fragment>
  );
};

export default ChecklistReport;
