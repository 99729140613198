import useGlobal from "../../store";
import React, {useMemo, useState} from "react";
import {useQuery} from "react-apollo-hooks";
import {VISIT_SCHEDULINGS} from "./gql/Queries";
import VisitSchedulingListView from "../../Views/VisitScheduling/VisitSchedulingListView";
import {ARCHIVE_VISIT_SCHEDULING} from "./gql/Mutation";
import {useMutation} from "@apollo/react-hooks";
import { GET_PLAN_FEATURES_APP } from "../Locations/gql/Queries";
import auth from "../../utils/auth";
import history from "../../utils/history";

const {useTranslation} = require("react-i18next");

const VisitSchedulingList = () => {
  const { t } = useTranslation(["visitScheduling", "commmon"]);
  const [session, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const [isArchivedMode, setIsArchivedMode] = useState(false);

  const [visitSchedulingFilter, setVisitSchedulingFilter] = useState({
    where: {
      app: session.user ? session.appRef._id : "",
      archived: isArchivedMode
    },
    first: 15,
    skip: 0,
    orderBy: "created_ASC",
  });

  const {
    data: visitSchedulings,
    loading: loadingVisitSchedulings,
    error: errorVisitSchedulings,
    refetch,
  } = useQuery(VISIT_SCHEDULINGS, {
    variables: visitSchedulingFilter,
    fetchPolicy: "network-only",
  });

  const {
    data: dataPlan,
  } = useQuery(GET_PLAN_FEATURES_APP, {
    variables: {
      where: {
        _id: session.user ? session.appRef._id : '',
      }
    }
  });

  const paginationAction  = async (values) => {
    setVisitSchedulingFilter(values);
  }

  const [archiveVisitScheduling] = useMutation(
    ARCHIVE_VISIT_SCHEDULING,
    {
      onCompleted: () => {
        refetch();
      },
    }
  );

  useMemo(() => {
    if (
      session.user &&
      !auth.hasPermission("schedulingVisitView", session.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: t('common:common.hasNotPermission'),
        color: "danger"
      });
      history.push('dashboard');
    } 
  }, [session]);

  return (
    <VisitSchedulingListView
      plan={dataPlan?.getPlanFeaturesApp || null}
      visitSchedulings={{
        loading: loadingVisitSchedulings,
        data: visitSchedulings
      }}
      visitSchedulingFilter={visitSchedulingFilter}
      paginationAction={paginationAction}
      archiveVisitScheduling={archiveVisitScheduling}
      isArchivedMode={isArchivedMode}
      changeArchivedMode={() => {
        setIsArchivedMode(!isArchivedMode);
        visitSchedulingFilter.where.archived = !isArchivedMode;
        setVisitSchedulingFilter(visitSchedulingFilter);
      }}
    />
  );
};

export default VisitSchedulingList;
