import React from 'react';
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import Button from 'components/Button';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import FiltersSidebar from "components/FiltersSidebar/FiltersSidebar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import history from 'utils/history';

import RadioWithList from "components/Filters/RadioWithList";
import RadioWithMultipleLists from "components/Filters/RadioWithMultipleLists";
import RadioWithScoredList from "components/Filters/RadioWithScoredList";

import { COMPARISON_OPERATORS } from "constants.js";
import TablesGalleryView from './TablesVisor/TablesGalleryView';

const TablesVisorItemView = ({
  classes, 
  activeVisitAnswer,
  categories,
  keyfields,
  modules,
  operators,
  visits,
  visitAnswers,
  closedVisitAnswer,
}) => {
  const [filterCategories, setFilterCategories] = React.useState(null);
  const [filterKeyfield, setFilterKeyfield] = React.useState(null);
  const [filterModules, setfilterModules] = React.useState(null);
  const [filterUsers, setFilterUsers] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const { loadingVisitAnswersClosedCount, dataVisitanswerClosedCount } = closedVisitAnswer;
  const { loadingVisitAnswersClosed, dataVisitAnswer } = visitAnswers;

  let closedCount = null;
  if (!loadingVisitAnswersClosedCount && dataVisitanswerClosedCount) {
    const { visitAnswerConnection } = dataVisitanswerClosedCount;
    if (visitAnswerConnection) {
      const { aggregate } = visitAnswerConnection;
      closedCount = aggregate.count;
    }
  }

  const keyfieldOptions = React.useMemo(() => {
    let bucket = {};
    keyfields.forEach((keyfield) => {
      bucket[keyfield.id] = {
        value: keyfield.id,
        label: keyfield.name,
        values: {}
      }
    });
    dataVisitAnswer.forEach(visitAnswer => {
      visitAnswer.keyfields.forEach(keyfield => {
        if(!bucket[keyfield.id].values[keyfield.value]){
          bucket[keyfield.id].values[keyfield.value] = {
            label: keyfield.value,
            value: keyfield.value
          }
        }
      })
    });
    return Object.values(bucket).map(keyfield => ({
      value: keyfield.value,
      label: keyfield.label,
      values: Object.values(keyfield.values)
    })); 
  }, [keyfields, dataVisitAnswer]);

  const userOptions = React.useMemo(() => {
    let bucket = {};

    dataVisitAnswer.forEach(visitAnswer => {
      if(!bucket[visitAnswer.userRef._id]){
        bucket[visitAnswer.userRef._id] = {
          label: `${visitAnswer.userRef.firstName} ${visitAnswer.userRef.lastName}`,
          value: visitAnswer.userRef._id
        };
      }
    });

    return Object.values(bucket);
  }, [dataVisitAnswer]);

  const moduleOptions = React.useMemo(() => 
    modules.map(moduleRef => ({
     label: moduleRef.name,
     value: moduleRef._id
    }))
  , [modules]);

  const categorieOptions = React.useMemo(() => 
    categories.map(category => ({
      label: category.name,
      value: category.id
    }))
  , [categories]);

  const filteredIds = React.useMemo(
    () => applyVisitAnswerFilters(
      dataVisitAnswer, 
      filterCategories,
      filterKeyfield,
      filterUsers, 
    ), [
      dataVisitAnswer, 
      filterCategories,
      filterKeyfield,
      filterUsers, 
  ]);

  const filteredModules = React.useMemo(() => {
    let modules = []; 
    filteredIds.forEach(id => {
      const visitAnswer = dataVisitAnswer[id];
      const currentModules =  visitAnswer.modules.map(module => {
        return {
          ...module,
          keyfields: visitAnswer.keyfields
        }
      });
      Array.prototype.push.apply(modules, currentModules);
    });
    if(filterModules){
      let validModuleIds = {};
      filterModules.forEach(filterModule => {
        validModuleIds[filterModule.value] = filterModule.label
      });
      
      modules = modules.filter(moduleRef => !!validModuleIds[moduleRef.moduleId]);
    }
    return modules;
  }, [filterModules, filteredIds, dataVisitAnswer]);

  return (
    <GridContainer>
      <GridItem xs={3}>
        <FiltersSidebar
          activeVisitAnswer={activeVisitAnswer}
          closedVisitAnswer={closedCount}
          visits={visits}
          visitAnswers={visitAnswers}
          operators={operators}
        />
      </GridItem>
      <GridItem xs={9}>
        <Button
            simple
            size="lg"
            color="info"
            onClick={history.goBack}
            className={classes.buttonNoMargin}
          >
          <ArrowBackIcon/><span>Volver al tablero general</span>
        </Button>
        <Card>
          <CardHeader color="info" text>
            <GridContainer>
              <GridItem>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>Visor de Tablas</h4>
                </CardText>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <Button
              className={classes.buttonWithIcon}
              color="primary"
              fullWidth
              size="sm"
              onClick={() => {setIsOpen(prevState => !prevState)}} 
            >
              <h4>Filtros</h4> {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
            <Collapse in={isOpen}>
              <GridContainer className={classes.filterRow}>
                <GridItem xs={6}>
                  <h4>Seleccionar Campo Clave para filtro</h4>
                  <RadioWithMultipleLists
                    isMulti={true}
                    label="Campo Clave"
                    lists={keyfieldOptions}
                    onFilterChange={setFilterKeyfield}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <h4>Usuarios en el Filtro</h4>
                  <RadioWithList
                    isMulti={true}
                    label="Usuarios"
                    listOptions={userOptions}
                    onFilterChange={setFilterUsers}
                  />
                </GridItem>
              </GridContainer>
              <Divider className={classes.divider}/>
              <GridContainer className={classes.filterRow}>
                <GridItem xs={6}>
                  <h4>Formularios en el Filtro</h4>
                  <RadioWithList
                    isMulti={true}
                    label="Formularios"
                    listOptions={moduleOptions}
                    onFilterChange={setfilterModules}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <h4>Filtrar por Resultado de calificacion</h4>
                  <RadioWithScoredList
                    onFilterChange={setFilterCategories} 
                    criterias={categorieOptions}
                  />
                </GridItem>
              </GridContainer>
            </Collapse>
          </CardBody>
        </Card>
        <TablesGalleryView
          modules={filteredModules}
        />
      </GridItem>
    </GridContainer>
  );
};

function applyVisitAnswerFilters(
  visitAnswers,
  filterCategories,
  filterKeyfield,
  filterUsers,
){
  let ids = visitAnswers.map((_, idx) => idx);

  if(filterKeyfield){
    const keyfield = filterKeyfield.key.value;
    const values = filterKeyfield.items.map(item => item.value);
    ids = ids.filter(id => {
      const visitKeyfield = visitAnswers[id].keyfields.find(visitKey => visitKey.id === keyfield);
      const existIn = values.some(value => value === visitKeyfield.value);
      return existIn;
    });
  }

  if(filterUsers){
    const validUsers = {};
    filterUsers.forEach(user => validUsers[user.value] = true);
    ids = ids.filter(id => {
      const userID = visitAnswers[id].userRef._id;
      return !!validUsers[userID];
    });
  }

  if(filterCategories){
    const category = filterCategories.criteria;
    const operator = filterCategories.operation;
    const value = filterCategories.value;
    ids = ids.filter(id => {
      const visitCategory = visitAnswers[id].categories.find(({id}) => id === category.value);
      return applyOperator(visitCategory ? visitCategory.evaluation : '0', value, operator);
    }); 
  }

  return ids;
}

function applyOperator(a, b, operator){
  switch (operator.value) {  
    case COMPARISON_OPERATORS.lessThan.value: 
      return a < b;
    case COMPARISON_OPERATORS.greaterThan.value: 
      return a > b;
    case COMPARISON_OPERATORS.lessEqual.value: 
      return a <= b;
    case COMPARISON_OPERATORS.greaterEqual.value: 
      return a >= b;
    case COMPARISON_OPERATORS.equalThan.value: 
      return a == b;
    default:
      return false;
  }
}

TablesVisorItemView.propTypes = {
  activeVisitAnswer: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  keyfields: PropTypes.array.isRequired,
  modules: PropTypes.array.isRequired,
  operators: PropTypes.array.isRequired,
  visits: PropTypes.array.isRequired,
  visitAnswers: PropTypes.object.isRequired,
};

const styles = theme => ({
  buttonNoMargin:{
    padding: "15px 0px"
  },
  buttonWithIcon: {
    display: "flex",
    justifyContent: "space-between",
  },
  divider:{
    margin: "5px 0px"
  },
  filterRow: {
    paddingBottom: "15px"
  }
});

export default withStyles(styles)(TablesVisorItemView);
