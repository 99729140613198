import React, {useEffect} from 'react';
import {Route, Redirect} from "react-router-dom";
import useGlobal from "../../store";

import LoadingCircleView from "../../Views/LoadingCircleView";

const {GH_FRONT_URL} = window._env_;

const CheckAuthenticatedRoute = ({client, component: Component, ...rest}) => {
  const [ session, globalActions ] = useGlobal(
    state => state.session,
    actions => actions
  );

  useEffect(() => {
    globalActions.session.isAuthenticated(client);
  },[session.cached === true]);

  if (session.loading) {
    return <LoadingCircleView />;
  }else{
    return session.user ? <Component {...rest} /> : window.location.href = `${GH_FRONT_URL}/#/access/signin`;
  }
};

const PrivateRoute = ({ client, component: Component, ...rest }) => {

  return (
    <Route {...rest} render={(props) => {

      return(
        <CheckAuthenticatedRoute client={client} component={Component} {...props} />
      )
    }} />
  );
};

export default PrivateRoute;
