export const buildOpportunityAreasSubject = ({visit, keyfield, startDate, endDate}) => {
  return `Áreas de Oportunidad en ${visit} analizado para ${keyfield} en ${startDate} - ${endDate}`;
}

export const buildOpportunityAreasPDFBody = ({visit, keyfield}) => {
  return `
    <p>Hola,</p>
    <br>
    <p>Adjunto en este mail un análisis de las áreas de oportunidad encontradas en ${visit} y con detalle para ${keyfield}.</p>
    <br>
    <p>En el gráfico tipo telaraña encontrarás una distribución de tus categorías de supervisión en donde las más alejadas del centro con las que tienen el mejor desempeño y las más cercanas al centro son tus áreas de oportunidad.</p>
  `;
}

export const buildOpportunityAreasXLSBody = ({visit, keyfield}) => {
  return `
    <p>Hola,</p>
    <br>
    <p>Adjunto en este mail un análisis de las áreas de oportunidad encontradas en ${visit} y con detalle para ${keyfield}.</p>
  `;
}
