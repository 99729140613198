import React from "react";
import {useQuery} from "react-apollo-hooks";
import PropTypes from 'prop-types';

// Own components
import useGlobal from "../../../store";
import { GEOFENCES_GROUP_ITEMS, MASSIVE_LIST_ITEMS } from "../gql/Queries";
import GeneralExecutionView from "Views/Visits/AdminData/GeneralExecutionView";
import { getGeofences } from "../../../utils/analytics/dataManipulation";

function GeneralExecutionItem({keyfields, selectedVisit, visitAnswers, createGeneralExecutionReport, 
  sendGeneralExecutionPDFReport, emailData, createGeneralExecutionXLSReport, sendGeneralExecutionXLSReport}){
  const [ session, ] = useGlobal(
    state => state.session,
    actions => actions
  );
  const [massiveListID, setMassiveListID] = React.useState(null);
  const engines = selectedVisit.engines;
  const entryEngine = engines.find((engine) => engine.type === "Entry");

  const fullKeyfields = React.useMemo(() => 
    keyfields.map(keyfield  => {
      const field  = entryEngine.moduleRef.propertiesRef.fields.find(({key}) => key === keyfield.id);
      return {
        ...keyfield, 
        isAnalyzable: isAnalyzable(field ? field.__typename : ''),
        optionsList: field ? field.options : [],
        massiveList: field ? field.massiveList : '',
        type: field ? field.__typename : '',
      };
    })
  , [keyfields, entryEngine]);

  if(massiveListID === null && 
      (fullKeyfields[0].type  === "MassiveListUniqueField" 
        || fullKeyfields[0].type === "MassiveListMultipleField"
  )){
    setMassiveListID(fullKeyfields[0].massiveList);
  }

  const {
    data, loading,
  } = useQuery(MASSIVE_LIST_ITEMS, {
      variables: {
        where: {
          massiveList: massiveListID,
        }
      },
      fetchPolicy: 'network-only',
      skip: !massiveListID
    });


    const {
      data: dataGeofenceGroups, 
      loading: loadingGeofenceGroups,
    } = useQuery(GEOFENCES_GROUP_ITEMS, {
        variables: {
          where: {
            app: session.user ? session.appRef._id : '',
            archived: false ,
            activated: true
          }
        },
        fetchPolicy: 'network-only',
      });
  
  const geofences = dataGeofenceGroups && dataGeofenceGroups.geofencesGroup ? 
    getGeofences( dataGeofenceGroups.geofencesGroup) : [];
  const massiveList = data && data.massiveListItems ? data.massiveListItems 
    : [];

  return (
    <GeneralExecutionView
      geofences={geofences}
      keyfields={fullKeyfields}
      loading={loading || loadingGeofenceGroups}
      massiveList={massiveList}
      setMassiveListID={setMassiveListID}
      visitAnswers={visitAnswers}
      createGeneralExecutionReport={createGeneralExecutionReport}
      sendGeneralExecutionPDFReport={sendGeneralExecutionPDFReport}
      createGeneralExecutionXLSReport={createGeneralExecutionXLSReport}
      sendGeneralExecutionXLSReport={sendGeneralExecutionXLSReport}
      emailData={emailData}
    />
  );
}

function isAnalyzable(type){
  switch (type) {
    case "ListUniqueField":
    case "ListMultipleField":
    case "MassiveListUniqueField":
    case "MassiveListMultipleField":
    case "GeofenceField":
      return true;
    default:
      return false;
  }
}

GeneralExecutionItem.propTypes = {
  selectedVisit: PropTypes.object.isRequired,
  keyfields: PropTypes.array.isRequired,
  visitAnswers: PropTypes.array.isRequired,
};

export default GeneralExecutionItem;
