import {IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { split } from "apollo-link";
import { onError } from "apollo-link-error";
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { createUploadLink } from 'apollo-upload-client';

// import { withClientState } from "apollo-link-state";
import session from '../utils/session';

import introspectionQueryResultData from '../../src/fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const {NODE_ENV, GQL_BACKEND_ENDPOINT} = window._env_;

// declare cache
const cache = new InMemoryCache({
  fragmentMatcher, addTypename: false,
  typePolicies: {
    TicketReport: {
      keyFields: ["_id", "comments", ["_id"]]
    }
  }
});

// declare web sockets
const wsLink = new WebSocketLink({
  uri: `${NODE_ENV === 'development' ? 'ws' : 'wss'}://${GQL_BACKEND_ENDPOINT}`,
  options: {
    reconnect: true
  }
});

// graphql error handling
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`)
    );
  }

  if (networkError) {
    console.log(
      `[Network error ${operation.operationName}]: ${networkError.message}`
    );
  }
});

// set header authentication
const authLink = setContext(async (_, { headers }) => {

  const currentToken = session ? await session.getDashAuthToken() : '';

  const context = {
    headers: {
      ...headers,
      Authorization: currentToken
    }
  };
  return context;
});

// set http link for graphql
//const httpLink = new HttpLink({ uri: `${NODE_ENV === 'development' ? 'http' : 'https'}://${GQL_BACKEND_ENDPOINT}` });

// set http link for graphql
const uploadLink = new createUploadLink({ uri: `${NODE_ENV === 'development' ? 'http' : 'https'}://${GQL_BACKEND_ENDPOINT}` });

// handle subscriptions and authentication
const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  authLink.concat(uploadLink)
);

//init client
const client = new ApolloClient({
  link: ApolloLink.from([errorLink, link]),
  cache,
});

export default client;
