import React, { useMemo } from "react";
import {useTranslation} from 'react-i18next';
import {useQuery, useSubscription} from "react-apollo-hooks";

// Own components
import LoadingCircleView from "../../Views/LoadingCircleView";
import UserApprovalItemsView from "../../Views/Visits/UserApprovalItemsView";

import useGlobal from "../../store";
import {USER_APPROVAL_ITEMS} from "./gql/Queries";
import {APPROVAL_ITEMS_STATUS_CHANGED_SUBSCRIPTION} from "./gql/Subscriptions";
import {APPROVAL_ITEM_STATUS} from "../../constants";
import {approvalItemsByVisitSelector} from "./selectors/approvalItemSelectors";
import {approvalItemChangedNotification} from "../../utils/snackNotifications"
import auth from "../../utils/auth";
import history from "../../utils/history";

function UserApprovalItems(props) {

  const [ session, globalActions] = useGlobal(
    state => state.session,
    actions => actions
  );
  const { t, } = useTranslation(['visits', 'approvalItems', 'common']);
  const {
    data: dataUserApprovalItems,
    error: errorUserApprovalItems,
    loading: loadingUserApprovalItems,
    refetch: refetchUserApprovalItems
  } = useQuery(
    USER_APPROVAL_ITEMS,
    {
      variables: {
        where: {
          userId: session.user._id,
          app: session.user ? session.appRef._id : '',
          status: APPROVAL_ITEM_STATUS.Pending
        }
      },
      fetchPolicy: 'network-only'
    }
  );
  const { data, error, loading } = useSubscription(APPROVAL_ITEMS_STATUS_CHANGED_SUBSCRIPTION, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : '',
        userId: session.user ? session.user._id : '',
      }
    },
    onSubscriptionData: ({client, subscriptionData}) => {
      const {status, node} = subscriptionData.data.approvalItemsStatusChanged;

      approvalItemChangedNotification(t, globalActions, status, node);
      refetchUserApprovalItems();
    }
  });

  useMemo(() => {
    if (
      session.user &&
      !auth.hasPermission("approbalVisitView", session.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: t('common:common.hasNotPermission'),
        color: "danger"
      });
      history.push('/analytics');
    } 
  }, [session]);

  if (loadingUserApprovalItems) {
    return <LoadingCircleView/>
  } else {
    return (
      <UserApprovalItemsView
        approvalItems={
          approvalItemsByVisitSelector(dataUserApprovalItems)
        }/>
    );
  }

}

const styles = theme => ({
  center: {
    textAlign: "center"
  }
});

export default UserApprovalItems;
