import gql from "graphql-tag";

export const VISIT_SCHEDULING_BASE_MODELS = gql`
  query visitSchedulingBaseModels($app: ID!, ) {
    userMobiles(where: { app: $app }) {
      _id
      firstName
      email
    },
    visits(where: { app: $app, isArchived: false}) {
      _id
      name
    },
    segments(where: { app: $app, archived: false}) {
      _id
      name
    }
  }
`;

export const VISIT_SCHEDULINGS = gql`
  query getVisitSchedulings($where: VisitSchedulingWhereInput!, $orderBy: VisitSchedulingOrderByInput, $first: Int, $skip: Int) {
    visitSchedulings(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      _id
      app
      name
      whoResolves
      visitId
      visitRef {
        _id
        name
      }
    }
  }
`;

export const VISIT_SCHEDULING = gql`
  query getVisitScheduling($where: VisitSchedulingWhereUniqueInput!) {
    visitScheduling(where: $where) {
      _id
      app
      name
      whoResolves
      segments
      users
      ranges {
        _id
        initialDate
        finalDate
      }
      visitId
      visitRef {
        _id
        name
      }
      description
    }
  }
`;

export const VISIT_SCHEDULING_REPORT = gql`
  query visitSchedulingReport($id: ID!) {
    visitSchedulingReport(where: {_id: $id}) {
      visitSchedulingId
      visitSchedulingRef {
        name
        whoResolves
        visitId
        visitRef {
          _id
          name
          app
          keys
          checkListConfiguration{
            _id
            needsApproval
            columns{
              category
              operation{
                name
                operator
              }
            }
          }
          engines {
            visitId
            moduleId
            type
            moduleRef {
              _id
              name
              type
              propertiesRef {
                __typename
                ... on QuestionEngineModule {
                  _id
                  fields {
                    __typename
                    ... on OpenField {
                      _id
                      key
                    }
                    ... on NumericField {
                      _id
                      key
                    }
                    ... on DateField {
                      _id
                      key
                    }
                    ... on PhoneField {
                      _id
                      key
                    }
                    ... on EmailField {
                      _id
                      key
                    }
                    ... on PasswordField {
                      _id
                      key
                    }
                    ... on OpenMultilineField {
                      _id
                      key
                    }
                    ... on PhotoReportField {
                      _id
                      key
                    }
                    ... on MapField {
                      _id
                      key
                    }
                    ... on SignatureField {
                      _id
                      key
                    }
                    ... on CodeBarField {
                      _id
                      key
                    }
                    ... on ChecklistField {
                      _id
                      key
                    }
                    ... on TokenField {
                      _id
                      key
                    }
                    ... on TableField {
                      _id
                      key
                    }
                    ... on GeofenceField {
                      _id
                      key
                    }
                    ... on ListUniqueField {
                      _id
                      key
                      options{
                        name
                        value
                      }
                    }
                    ... on ListMultipleField {
                      _id
                      key
                      options{
                        name
                        value
                      }
                    }
                    ... on MassiveListUniqueField {
                      _id
                      key
                      massiveList

                    }
                    ... on MassiveListMultipleField {
                      _id
                      key
                      massiveList
                    }
                  }
                }
              }
            }
          }
          engines {
            visitId
            moduleId
            type
            moduleRef {
              _id
              type
              propertiesRef {
                __typename
                ... on QuestionEngineModule {
                  _id
                  fields {
                    __typename
                    ... on OpenField {
                      _id
                      key
                    }
                    ... on NumericField {
                      _id
                      key
                    }
                    ... on DateField {
                      _id
                      key
                    }
                    ... on PhoneField {
                      _id
                      key
                    }
                    ... on EmailField {
                      _id
                      key
                    }
                    ... on PasswordField {
                      _id
                      key
                    }
                    ... on OpenMultilineField {
                      _id
                      key
                    }
                    ... on PhotoReportField {
                      _id
                      key
                    }
                    ... on MapField {
                      _id
                      key
                    }
                    ... on SignatureField {
                      _id
                      key
                    }
                    ... on CodeBarField {
                      _id
                      key
                    }
                    ... on ChecklistField {
                      _id
                      key
                    }
                    ... on TokenField {
                      _id
                      key
                    }
                    ... on TableField {
                      _id
                      key
                    }
                    ... on GeofenceField {
                      _id
                      key
                    }
                    ... on ListUniqueField {
                      _id
                      key
                      options{
                        name
                        value
                      }
                    }
                    ... on ListMultipleField {
                      _id
                      key
                      options{
                        name
                        value
                      }
                    }
                    ... on MassiveListUniqueField {
                      _id
                      key
                      massiveList

                    }
                    ... on MassiveListMultipleField {
                      _id
                      key
                      massiveList
                    }
                  }
                }
              }
            }
          }
        }
      }
      ranges {
        passed {
          _id
          initialDate
          finalDate
          progress
          userCount
        }
        active {
          _id
          initialDate
          finalDate
          progress
          userCount
        }
        future {
          _id
          initialDate
          finalDate
          progress
          userCount
        }
      }
      visitUserSchedulings {
        _id
        visitSchedulingId
        userId
        userRef {
          firstName
          lastName
          email
        }
        rangeId
        count
        firstAnswerDate
        answerIds
      }
    }
  }
`;
