import React from 'react';
import ReactTable from "react-table";
import { TextField, Typography } from '@material-ui/core';
import DivCenter from "../../components/styled-components/DivCenter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from '@material-ui/icons/Visibility';

const ElementsActivitiesTable = ({data, processRelationship, filters}) => {
    const { t } = useTranslation("processRelationship");
    
    return (
      <ReactTable
        data={data}
        columns={[
          {
            Header: (
              <DivCenter style={{ ...styles.headerTitle }}>
                {t("processRelationship.workPlan.list.element")}
              </DivCenter>
            ),
            accessor: "name",
            filterable: true,
            sortable: false,
            style: { textAlign: "left" },
            Cell: ({ original }) => {
              return (
                <Typography style={styles.contentText}>
                  {original.name}
                </Typography>
              );
            },
            // Filter: () => (
            //   <TextField
            //     defaultValue={filterOptions.name}
            //     variant="outlined"
            //     style={{ width: "100%" }}
            //     onChange={async (event) => {
            //       const value = event.target.value;
            //       await delay(2000);
            //       filterAction({ ...filterOptions, name: value ? value : "" });
            //     }}
            //   />
            // ),
          },
          {
            Header: (
              <DivCenter style={styles.headerTitle}>
                {t("processRelationship.workPlan.list.totalActivitiesPendign")}
              </DivCenter>
            ),
            accessor: "totalPending",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            Cell: ({ original }) => {
              return (
                <Typography style={styles.contentText}>
                  {original.indicators.totalPending}
                </Typography>
              );
            },
          },
          {
            Header: (
              <DivCenter style={styles.headerTitle}>
                {t("processRelationship.workPlan.list.activitiesPendingDate")}
              </DivCenter>
            ),
            accessor: "totalPenginDates",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            Cell: ({ original }) => {
              return (
                <Typography style={styles.contentText}>
                  {original.indicators.totalPenginDates}
                </Typography>
              );
            },
          },
          {
            Header: (
              <DivCenter style={styles.headerTitle}>
                {t("processRelationship.workPlan.list.percentagePending")}
              </DivCenter>
            ),
            accessor: "percentagePending",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            Cell: ({ original }) => {
              return (
                <Typography style={styles.contentText}>
                  {original.indicators.percentagePending} %
                </Typography>
              );
            },
          },
          {
            Header: <DivCenter style={styles.headerTitle}></DivCenter>,
            accessor: "action",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            Cell: ({ original }) => {
              return (
                <Link
                  to={{
                    pathname: `/objects/${processRelationship._id}/element/${original._id}`,
                    state: {
                      element: original,
                      filters: filters,
                      datesRages: {
                        reportedDate_gte: filters.reportedDate_gte,
                        reportedDate_lte: filters.reportedDate_lte
                      }
                    },
                  }}
                >
                  <Button justIcon round simple color="info">
                    <Tooltip title={t("processRelationship.tooltip.workPlan")}>
                      <VisibilityIcon style={{ fontSize: 40 }} />
                    </Tooltip>
                  </Button>
                </Link>
              );
            },
          },
        ]}
        defaultPageSize={data.length}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
        resizable={false}
        noDataText={t("processRelationship.workPlan.noData")}
      />
    );
}

const styles = {
  headerTitle: {
    fontSize: "20px",
    whiteSpace: 'break-spaces',
    fontWeight: "500", 
  },
  contentText: {
    fontSize: 18,
  },
  
};

export default ElementsActivitiesTable;
