import gql from "graphql-tag";

export const TICKETS_FEED = gql`
  query TicketsFeed(
    $app: ID!, 
    $skip: Int, 
    $first: Int, 
    $user: ID, 
    $session: JSON,
    $archived: Boolean
    ) {
    tickets(
      where: { 
        app: $app, 
        user: $user, 
        session: $session,
        archived: $archived 
      }, 
      skip: $skip, 
      first: $first
      ) {
      _id
      app
      name
      description
      hasFolio
      folio
      folioRef {
        _id
        prefix
        startAt
      }
      created
      emails
      fieldsFilter {
        key
        label
      }
      triggers {
        _id
        module {
          _id
          name
          propertiesRef {
            __typename
            ... on QuestionEngineModule {
              _id
              fields {
                __typename
                ... on OpenField {
                  _id
                  key
                  label
                }
                ... on NumericField {
                  _id
                  key
                  label
                }
                ... on DateField {
                  _id
                  key
                }
                ... on PhoneField {
                  _id
                  key
                }
                ... on EmailField {
                  _id
                  key
                }
                ... on PasswordField {
                  _id
                  key
                }
                ... on OpenMultilineField {
                  _id
                  key
                }
                ... on PhotoReportField {
                  _id
                  key
                }
                ... on MapField {
                  _id
                  key
                  completeAddress
                }
                ... on SignatureField {
                  _id
                  key
                }
                ... on CodeBarField {
                  _id
                  key
                }
                ... on ChecklistField {
                  _id
                  key
                }
                ... on TokenField {
                  _id
                  key
                }
                ... on TableField {
                  _id
                  key
                }
                ... on GeofenceField {
                  _id
                  key
                  label
                }
                ... on ListUniqueField {
                  _id
                  key
                  label
                  options {
                    name
                    value
                  }
                }
                ... on ListMultipleField {
                  _id
                  key
                  options {
                    name
                    value
                  }
                }
                ... on MassiveListUniqueField {
                  _id
                  key
                  label
                  massiveList
                  massiveListItems {
                    _id
                    value
                  }
                }
                ... on MassiveListMultipleField {
                  _id
                  key
                  massiveList
                }
                ... on FileField {
                  _id
                  key
                }
                ... on TimeField {
                  _id
                  key
                }
              }
            }
          }
        }
      }
      finisher {
        _id
        module {
          _id
          name
        }
      }
      ticketSegmentAssignConfigs {
        segmentId
        userMobileId
      }
      pendingAssign
      pendingReview
      pendingSolve
      whoResolves
      segment
      archived
      pdfReportConfig {
        emails
        emaiToWhoReport
        emaiToWhoResolve
      }
      topOffender {
        massiveList
        fieldId
        elements {
          value
          time
        }
      }
      usersAdmins {
        enableSecurity
        users {
          _id
          fullName
          email
        }
      }
    }
  }
`;

export const TICKET_REPORTS_FEED = gql`
  query TicketReportList(
    $app: ID!
    $ticket: ID!
    $status: [TicketReportStatus!]
    $created_gte: DateTime
    $created_lte: DateTime
    $originalUserReported_segment: ID
    $originalUserReported_user_in: [ID!]
    $skip: Int
    $first: Int
    $user: ID
    $folio: String
  ) {
    ticketReports(
      where: {
        app: $app
        ticket: { _id: $ticket }
        status_in: $status
        created_gte: $created_gte
        created_lte: $created_lte
        originalUserReported_segment: $originalUserReported_segment
        originalUserReported_user_in: $originalUserReported_user_in
        user: $user
        folio: $folio
      }
      skip: $skip
      first: $first
      orderBy: created_ASC
    ) {
      _id
      user
      folio
      comments {
        _id
        text
        userRef {
          __typename
          ... on UserGh {
            _id
            firstName
            lastName
            email
            __typename
          }
          ... on UserMobile {
            _id
            firstName
            lastName
            email
            __typename
          }
        }
        created
      }
      userRef {
        _id
        firstName
        lastName
        email
        __typename
      }
      status
      questionEngineAnswerTrigger {
        _id
        module {
          _id
          name
          kind
          type
          propertiesRef {
            __typename
            ... on QuestionEngineModule {
              _id
              fields {
                __typename
                ... on OpenField {
                  _id
                  key
                  label
                }
                ... on NumericField {
                  _id
                  key
                  label
                }
                ... on DateField {
                  _id
                  key
                }
                ... on PhoneField {
                  _id
                  key
                }
                ... on EmailField {
                  _id
                  key
                }
                ... on PasswordField {
                  _id
                  key
                }
                ... on OpenMultilineField {
                  _id
                  key
                }
                ... on PhotoReportField {
                  _id
                  key
                }
                ... on MapField {
                  _id
                  key
                  completeAddress
                }
                ... on SignatureField {
                  _id
                  key
                }
                ... on CodeBarField {
                  _id
                  key
                }
                ... on ChecklistField {
                  _id
                  key,
                  showAllTriggerTicket
                }
                ... on TokenField {
                  _id
                  key
                }
                ... on TableField {
                  _id
                  key
                }
                ... on GeofenceField {
                  _id
                  key
                  label
                }
                ... on ListUniqueField {
                  _id
                  key
                  label
                  options {
                    name
                    value
                  }
                }
                ... on ListMultipleField {
                  _id
                  key
                  options {
                    name
                    value
                  }
                }
                ... on MassiveListUniqueField {
                  _id
                  key
                  label
                  massiveList
                }
                ... on MassiveListMultipleField {
                  _id
                  key
                  massiveList
                }
                ... on FileField {
                  _id
                  key
                }
                ... on TimeField {
                  _id
                  key
                }
              }
            }
          }
        }
        userId
        userRef {
          _id
          email
          firstName
          lastName
        }
        answers {
          key
          questionLabel
          answer {
            __typename
            ... on OpenFieldAnswer {
              OpenFieldAnswer: value
            }
            ... on NumericFieldAnswer {
              NumericFieldAnswer: value
            }
            ... on DateFieldAnswer {
              DateFieldAnswer: value
            }
            ... on PhoneFieldAnswer {
              PhoneFieldAnswer: value
            }
            ... on ListUniqueFieldAnswer {
              ListUniqueFieldAnswer: value
              config {
                key
                type
                label
                help
                required
                listStyle
                options {
                  _id
                  value
                  name
                  segment
                  emails
                  image {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
              }
            }
            ... on ListMultipleFieldAnswer {
              ListMultipleFieldAnswer: value
              config {
                key
                type
                label
                help
                required
                listStyle
                options {
                  _id
                  value
                  name
                  segment
                  emails
                  image {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
              }
            }
            ... on EmailFieldAnswer {
              EmailFieldAnswer: value
            }
            ... on PasswordFieldAnswer {
              PasswordFieldAnswer: value
            }
            ... on OpenMultilineFieldAnswer {
              OpenMultilineFieldAnswer: value
            }
            ... on PhotoReportFieldAnswer {
              PhotoReportFieldAnswer: value {
                original
                i1280
                i240
                i320
                i480
                i640
                i960
              }
            }
            ... on MapFieldAnswer {
              longitude
              latitude
              address
            }
            ... on SignatureFieldAnswer {
              SignatureFieldAnswer: value {
                original
                i1280
                i240
                i320
                i480
                i640
                i960
              }
            }
            ... on CodeBarFieldAnswer {
              CodeBarFieldAnswer: value
            }
            ... on MassiveListUniqueFieldAnswer {
              MassiveListUniqueFieldAnswer: value
              config {
                massiveList
              }
            }
            ... on MassiveListMultipleFieldAnswer {
              MassiveListMultipleFieldAnswer: value
              config {
                massiveList
              }
            }
            ... on ChecklistFieldAnswer {
              ChecklistFieldAnswer: id
              comment
              email
              photos {
                original
                i1280
                i240
                i320
                i480
                i640
                i960
              }
              signature {
                original
                i1280
                i240
                i320
                i480
                i640
                i960
              }
            }
            ... on TokenFieldAnswer {
              TokenFieldAnswer: value
            }
            ... on TableFieldAnswer {
              rows {
                answers {
                  key
                  answer {
                    __typename
                    ... on OpenSubFieldAnswer {
                      OpenSubFieldAnswer: value
                    }
                    ... on NumericSubFieldAnswer {
                      NumericSubFieldAnswer: value
                    }
                    ... on DateSubFieldAnswer {
                      DateSubFieldAnswer: value
                    }
                    ... on PhoneSubFieldAnswer {
                      PhoneSubFieldAnswer: value
                    }
                    ... on MassiveListUniqueSubFieldAnswer {
                      MassiveListUniqueSubFieldAnswer: value
                    }
                  }
                }
              }
              config {
                _id
                key
                type
                label
                columns {
                  __typename
                  ... on OpenSubField {
                    _id
                    key
                    type
                    label
                    width
                  }
                  ... on NumericSubField {
                    _id
                    key
                    type
                    label
                    width
                    numericFormatStyle
                  }
                  ... on DateSubField {
                    _id
                    key
                    type
                    label
                    width
                  }
                  ... on PhoneSubField {
                    _id
                    key
                    type
                    label
                    width
                  }
                  ... on MassiveListUniqueSubField {
                    _id
                    key
                    type
                    label
                    width
                  }
                }
              }
            }
            ... on GeofenceFieldAnswer {
              GeofenceFieldAnswer: value
            }
            ... on TimeFieldAnswer {
              TimeFieldAnswer: value
            }
            ... on FileFieldAnswer {
              FileFieldAnswer: files {
                filename
                file
              }
            }
          }
        }
        checklists {
          _id
          app
          name
          options {
            _id
            name
            image {
              i320
              i480
            }
            numericValue
          }
        }
        created
      }
      questionEngineAnswerResolved {
        _id
        module {
          _id
          name
          propertiesRef {
            __typename
            ... on QuestionEngineModule {
              _id
              fields {
                __typename
                ... on OpenField {
                  _id
                  key
                  label
                }
                ... on NumericField {
                  _id
                  key
                  label
                }
                ... on DateField {
                  _id
                  key
                }
                ... on PhoneField {
                  _id
                  key
                }
                ... on EmailField {
                  _id
                  key
                }
                ... on PasswordField {
                  _id
                  key
                }
                ... on OpenMultilineField {
                  _id
                  key
                }
                ... on PhotoReportField {
                  _id
                  key
                }
                ... on MapField {
                  _id
                  key
                  completeAddress
                }
                ... on SignatureField {
                  _id
                  key
                }
                ... on CodeBarField {
                  _id
                  key
                }
                ... on ChecklistField {
                  _id
                  key
                }
                ... on TokenField {
                  _id
                  key
                }
                ... on TableField {
                  _id
                  key
                }
                ... on GeofenceField {
                  _id
                  key
                  label
                }
                ... on ListUniqueField {
                  _id
                  key
                  options {
                    name
                    value
                  }
                }
                ... on ListMultipleField {
                  _id
                  key
                  options {
                    name
                    value
                  }
                }
                ... on MassiveListUniqueField {
                  _id
                  key
                  massiveList
                }
                ... on MassiveListMultipleField {
                  _id
                  key
                  massiveList
                }
                ... on FileField {
                  _id
                  key
                }
                ... on TimeField {
                  _id
                  key
                }
              }
            }
          }
        }
        userId
        userRef {
          _id
          email
          firstName
          lastName
        }
        answers {
          key
          questionLabel
          answer {
            __typename
            ... on OpenFieldAnswer {
              OpenFieldAnswer: value
            }
            ... on NumericFieldAnswer {
              NumericFieldAnswer: value
            }
            ... on DateFieldAnswer {
              DateFieldAnswer: value
            }
            ... on PhoneFieldAnswer {
              PhoneFieldAnswer: value
            }
            ... on ListUniqueFieldAnswer {
              ListUniqueFieldAnswer: value
              config {
                key
                type
                label
                help
                required
                listStyle
                options {
                  _id
                  value
                  name
                  segment
                  emails
                  image {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
              }
            }
            ... on ListMultipleFieldAnswer {
              ListMultipleFieldAnswer: value
              config {
                key
                type
                label
                help
                required
                listStyle
                options {
                  _id
                  value
                  name
                  segment
                  emails
                  image {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
              }
            }
            ... on EmailFieldAnswer {
              EmailFieldAnswer: value
            }
            ... on PasswordFieldAnswer {
              PasswordFieldAnswer: value
            }
            ... on OpenMultilineFieldAnswer {
              OpenMultilineFieldAnswer: value
            }
            ... on PhotoReportFieldAnswer {
              PhotoReportFieldAnswer: value {
                original
                i1280
                i240
                i320
                i480
                i640
                i960
              }
            }
            ... on MapFieldAnswer {
              longitude
              latitude
              address
            }
            ... on SignatureFieldAnswer {
              SignatureFieldAnswer: value {
                original
                i1280
                i240
                i320
                i480
                i640
                i960
              }
            }
            ... on CodeBarFieldAnswer {
              CodeBarFieldAnswer: value
            }
            ... on MassiveListUniqueFieldAnswer {
              MassiveListUniqueFieldAnswer: value
              config {
                massiveList
              }
            }
            ... on MassiveListMultipleFieldAnswer {
              MassiveListMultipleFieldAnswer: value
              config {
                massiveList
              }
            }
            ... on ChecklistFieldAnswer {
              ChecklistFieldAnswer: id
              comment
              email
              photos {
                original
                i1280
                i240
                i320
                i480
                i640
                i960
              }
              signature {
                original
                i1280
                i240
                i320
                i480
                i640
                i960
              }
            }
            ... on TokenFieldAnswer {
              TokenFieldAnswer: value
            }
            ... on TableFieldAnswer {
              rows {
                answers {
                  key
                  answer {
                    __typename
                    ... on OpenSubFieldAnswer {
                      OpenSubFieldAnswer: value
                    }
                    ... on NumericSubFieldAnswer {
                      NumericSubFieldAnswer: value
                    }
                    ... on DateSubFieldAnswer {
                      DateSubFieldAnswer: value
                    }
                    ... on PhoneSubFieldAnswer {
                      PhoneSubFieldAnswer: value
                    }
                    ... on MassiveListUniqueSubFieldAnswer {
                      MassiveListUniqueSubFieldAnswer: value
                    }
                  }
                }
              }
              config {
                _id
                key
                type
                label
                columns {
                  __typename
                  ... on OpenSubField {
                    _id
                    key
                    type
                    label
                    width
                  }
                  ... on NumericSubField {
                    _id
                    key
                    type
                    label
                    width
                    numericFormatStyle
                  }
                  ... on DateSubField {
                    _id
                    key
                    type
                    label
                    width
                  }
                  ... on PhoneSubField {
                    _id
                    key
                    type
                    label
                    width
                  }
                  ... on MassiveListUniqueSubField {
                    _id
                    key
                    type
                    label
                    width
                  }
                }
              }
            }
            ... on GeofenceFieldAnswer {
              GeofenceFieldAnswer: value
            }
            ... on TimeFieldAnswer {
              TimeFieldAnswer: value
            }
            ... on FileFieldAnswer {
              FileFieldAnswer: files {
                filename
                file
              }
            }
          }
        }
        checklists {
          _id
          app
          name
          options {
            _id
            name
            image {
              i320
              i480
            }
            numericValue
          }
        }
        created
      }
      ticketReportLogs {
        userId
        userType
        userRef {
          __typename
          ... on UserGh {
            _id
            email
            firstName
            lastName
            __typename
          }
          ... on UserMobile {
            _id
            email
            firstName
            lastName
            __typename
          }
        }
        status
        created
      }
      ticketReportTriggerOrigin {
        origin
        fieldId
        key
      }
    }
    RejectedCount: ticketReportsConnection(
      where: {
        app: $app
        ticket: { _id: $ticket }
        status: Rejected
        created_gte: $created_gte
        created_lte: $created_lte
        originalUserReported_segment: $originalUserReported_segment
        originalUserReported_user_in: $originalUserReported_user_in
        user: $user
      }
    ) {
      aggregate {
        count
      }
    }
    AcceptedCount: ticketReportsConnection(
      where: {
        app: $app
        ticket: { _id: $ticket }
        status: Accepted
        created_gte: $created_gte
        created_lte: $created_lte
        originalUserReported_segment: $originalUserReported_segment
        originalUserReported_user_in: $originalUserReported_user_in
        user: $user
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const USER_MOBILES_FEED = gql`
  query getUsersMobile($where: UserMobileWhereInput!) {
    userMobiles(where: $where) {
      _id
      email
      firstName
      lastName
    }
  }
`;

export const GET_VALID_TICKET_USERS_MOBILE_FEED = gql`
  query ticketValidUsers($ticket: ID!) {
    ticketValidUsers(ticket: $ticket) {
      _id
      firstName
      lastName
      __typename
    }
  }
`;

export const MOBILE_USERS_FEED = gql`
  query MobileUserList($app: ID!) {
    ticketReports(where: { app: $app }) {
      _id
      status
      questionEngineAnswerTrigger {
        _id
        module {
          _id
          name
        }
      }
    }
  }
`;

export const APP_SEGMENTS_ALL = gql`
  query segments($where: SegmentWhereInput) {
    segments(where: $where) {
      _id
      name
    }
  }
`;

export const ALLOWED_TICKET_QUESTION_MODULES_ALL = gql`
  query getTicketsUnusedModules(
    $where: QuestionEngineModulesTicketUnusedInput!
  ) {
    questionEngineModulesTicketUnused(where: $where) {
      _id
      module {
        _id
        name
        type
        propertiesRef {
          __typename
          ... on QuestionEngineModule {
            _id
            fields {
              __typename
              ... on OpenField {
                _id
                key
                label
              }
              ... on NumericField {
                _id
                key
                label
              }
              ... on DateField {
                _id
                key
                label
              }
              ... on PhoneField {
                _id
                key
                label
              }
              ... on EmailField {
                _id
                key
                label
              }
              ... on PasswordField {
                _id
                key
              }
              ... on OpenMultilineField {
                _id
                key
              }
              ... on PhotoReportField {
                _id
                key
              }
              ... on MapField {
                _id
                key
              }
              ... on SignatureField {
                _id
                key
              }
              ... on CodeBarField {
                _id
                key
              }
              ... on ChecklistField {
                _id
                key
              }
              ... on TokenField {
                _id
                key
              }
              ... on TableField {
                _id
                key
              }
              ... on GeofenceField {
                _id
                key
                label
              }
              ... on ListUniqueField {
                _id
                key
                label
                options {
                  name
                  value
                }
              }
              ... on ListMultipleField {
                _id
                key
                options {
                  name
                  value
                }
              }
              ... on MassiveListUniqueField {
                _id
                key
                label
                massiveList
                massiveListItems {
                  _id
                  value
                }
              }
              ... on MassiveListMultipleField {
                _id
                key
                massiveList
              }
            }
          }
        }
      }
    }
    questionEngineModulesTicketFinishUnused(where: $where) {
      _id
      module {
        _id
        name
      }
    }
  }
`;

export const TICKETS_CONFIG_DELAY_ALERT = gql`
  query TicketsDelayAlertConfig($where: TicketConfigDelayAlertWhereInput) {
    ticketsdelayalertconfig(where: $where ) {
       	_id
				app
				ticket
				startStatus
				endStatus
				config {
					step
					timeDelay
					addUserAsigned
					mails
				}
				sendMailOnfinished
				archived
				finished
    }
  }
`;

export const TICKET_FEED = gql`
  query ticket($where: TicketWhereUniqueInput) {
    ticket(where: $where ) {
       	_id
        usersAdmins {
          enableSecurity
          users {
            _id
            fullName
            email
          }
        }
    }
  }
`;

export const TOKEN_FEED = gql`
  query getIncidentTokens(
    $where: IncidentTokenWhereInput
  ) {
    getIncidentTokens(where: $where) {
      app
			incident
			token
			createdBy
			archived
    }
  }
`;