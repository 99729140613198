import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Field, Form, withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { getErrorLabel, hasError } from "../../utils/forms/inputs";
import Select, { components } from "react-select";

import {
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Divider,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { debounce } from "lodash";

const { ValueContainer, Placeholder } = components;

const VisitConfigFormValidationSchema = yup.object().shape({
  visits: yup.array().required("isRequired"),
  operationPonderable: yup.boolean(),
  visitWithNA: yup.string(),
  periodicity: yup.string(),
  visitPonderation: yup.array().of(
    yup.object().shape({
      visitId: yup.string(),
      ponderation: yup.number(),
    })
  ),
});

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const VisitConfigForm = ({
  validVisits,
  updateValues,
  errors,
  touched,
  values,
  isValid,
  setFieldValue,
  submitForm,
  processRelationshipName
}) => {
  const { t } = useTranslation("processRelationship");
  const [currentPonderation, setCurrentPonderation] = useState(0);
  const classes = useStyles();
  const visitsOptions = validVisits.map((visit) => visit);

  const periodicityOptions = [
    {
      label: t("processRelationship.forms.fields.periodicity.options.weekly"),
      value: "Weekly",
    },
    {
      label: t("processRelationship.forms.fields.periodicity.options.biweekly"),
      value: "Biweekly",
    },
    {
      label: t("processRelationship.forms.fields.periodicity.options.monthly"),
      value: "Monthly",
    },
    {
      label: t(
        "processRelationship.forms.fields.periodicity.options.bimonthly"
      ),
      value: "Bimonthly",
    },
    {
      label: t(
        "processRelationship.forms.fields.periodicity.options.quarterly"
      ),
      value: "Quarterly",
    },
  ];

  let { visits, operationPonderable, visitPonderation } = values;

  useEffect(() => {
    validationPonderation();
  }, []);

  useEffect(() => {
    if (!isValid) {
      updateValues(values, false);
    } else {
      validationPonderation();
    }

    if (visitPonderation.length) {
      const total = visitPonderation.reduce(
        (result, current) => result + current.ponderation,
        0
      );
      setCurrentPonderation(100 - total);

      if (values.operationPonderable) {
        const ponderationTotal = values.visitPonderation.reduce(
          (total, config) => {
            return total + config.ponderation;
          },
          0
        );

        if (ponderationTotal !== 100) {
          updateValues(values, false);
        }
      }
    }
  }, [values, isValid]);

  const validationPonderation = () => {
    if (values.operationPonderable) {
      const ponderationTotal = values.visitPonderation.reduce(
        (total, config) => {
          return total + config.ponderation;
        },
        0
      );

      if (ponderationTotal !== 100) {
        updateValues(values, false);
      } else {
        submitForm();
      }
    } else {
      submitForm();
    }
  };

  const handleChangePonderation = (index, lastValue) => (event) => {
    let ponderation = event.target.value;
    if (ponderation && ponderation <= 100) {
      ponderation = parseFloat(ponderation);
      if (ponderation <= currentPonderation + parseFloat(lastValue)) {
        setFieldValue(`visitPonderation[${index}].ponderation`, ponderation);
      }
    }

    if (!ponderation) {
      setFieldValue(`visitPonderation[${index}].ponderation`, 0);
    }
    
  };

  return (
    <Form>
      <GridContainer justify="center">
        <GridItem xs={3}>
          <Typography
            variant="body2"
            component="p"
            style={{ fontSize: "16px" }}
          >
            {`${t(
              "processRelationship.forms.fields.selectVisits.selectVisitForProcess"
            )} (${processRelationshipName})`}
          </Typography>
          <List dense>
            {visitsOptions.map((visit, index) => {
              const labelId = `checkbox-list-secondary-label-${visit._id}`;
              return (
                <React.Fragment key={index}>
                  <ListItem key={visit._id}>
                    <ListItemText id={labelId} primary={visit.name} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        id={`visit-check-${index}`}
                        edge="end"
                        color="default"
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                        onChange={() => {
                          let visitSelected = [...visits];
                          let visitPonderationSelected = [...visitPonderation];
                          const currentIndex = visitSelected.indexOf(visit._id);

                          if (currentIndex === -1) {
                            visitSelected.push(visit._id);
                            visitPonderationSelected.push({
                              visitId: visit._id,
                              ponderation: 0,
                            });
                          } else {
                            visitSelected.splice(currentIndex, 1);
                            visitPonderationSelected.splice(currentIndex, 1);
                          }

                          setFieldValue("visits", [...visitSelected]);
                          setFieldValue("visitPonderation", [
                            ...visitPonderationSelected,
                          ]);
                        }}
                        checked={visits.indexOf(visit._id) !== -1}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        </GridItem>
        <Divider orientation="vertical" flexItem />
        <GridItem xs={3}>
          <Field
            name="operationPonderable"
            render={({ field }) => {
              return (
                <FormControl component="fieldset" className={classes.marginTop}>
                  <FormLabel component="legend">
                    {t("processRelationship.forms.fields.ponderation.label")}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue={field.value.toString()}
                    onChange={(event) => {
                      setFieldValue(
                        "operationPonderable",
                        event.target.value === "true"
                      );
                      
                      if (event.target.value === "true") {
                        let visitsPonderationConfig = [];
                        visits.map(visit => {
                          visitsPonderationConfig.push({
                            visitId: visit,
                            ponderation: 0,
                          })
                        });

                        setFieldValue("visitPonderation", [
                          ...visitsPonderationConfig,
                        ]);
                      }
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      className={classes.textColor}
                      control={
                        <Radio
                          color="default"
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={t(
                        "processRelationship.forms.fields.ponderation.options.withPonderation"
                      )}
                    />
                    <FormControlLabel
                      value="false"
                      className={classes.textColor}
                      control={
                        <Radio
                          color="default"
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={t(
                        "processRelationship.forms.fields.ponderation.options.noPonderation"
                      )}
                    />
                  </RadioGroup>
                </FormControl>
              );
            }}
          />

          {operationPonderable ? (
            <React.Fragment>
              <Typography variant="body2" component="p">
                {t(
                  "processRelationship.forms.fields.selectVisits.selectVisits"
                )}
              </Typography>
              <List>
                {visitPonderation.map((visit, index) => {
                  const labelId = `checkbox-list-secondary-label-${visit.visitId}`;
                  const visitOption = visitsOptions.find(
                    (visitOption) => visitOption._id === visit.visitId
                  );
                  
                  if (visitOption) {
                    return (
                      <React.Fragment key={index}>
                        <ListItem key={visit.visitId}>
                          <ListItemText id={labelId} primary={visitOption.name} />
                          <ListItemSecondaryAction>
                            <Field
                              name={`visitPonderation[${index}].ponderation`}
                              render={({ field }) => {
                                return (
                                  <TextField
                                    id="outlined-number"
                                    type="number"
                                    inputProps={{
                                      style: { textAlign: "right" },
                                    }}
                                    value={field.value}
                                    onChange={handleChangePonderation(
                                      index,
                                      field.value
                                    )}
                                    onFocus={(event) => {
                                      event.target.select();
                                    }}
                                    error={field.value <= 0}
                                  />
                                );
                              }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    );
                  }
                })}
              </List>
              <Typography
                variant="body2"
                component="p"
                className={classes.textEnd}
              >
                {`${t(
                  "processRelationship.forms.fields.ponderation.availablePoints"
                )}: ${currentPonderation}`}
              </Typography>
            </React.Fragment>
          ) : (
            <div></div>
          )}
        </GridItem>
        <Divider orientation="vertical" flexItem />
        <GridItem xs={3}>
        <Field
            name="periodicity"
            render={({ field }) => {
              return (
                <Select
                  placeholder={t(
                    "processRelationship.forms.fields.periodicity.label"
                  )}
                  components={{
                    ValueContainer: CustomValueContainer,
                  }}
                  value={periodicityOptions.find(
                    (option) => option.value === field.value
                  )}
                  onChange={(option) => {
                    setFieldValue("periodicity", option.value);
                  }}
                  options={periodicityOptions}
                  styles={{
                    ...classes.marginTop,
                    container: (provided) => ({
                      ...provided,
                      marginTop: 30,
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      overflow: "visible",
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      top:
                        state.hasValue || state.selectProps.inputValue
                          ? -15
                          : "50%",
                      transition: "top 0.1s, font-size 0.1s",
                      fontSize:
                        (state.hasValue || state.selectProps.inputValue) && 13,
                    }),
                  }}
                />
              );
            }}
          />
          <Field
            name="visitWithNA"
            render={({ field }) => {
              return (
                <FormControl component="fieldset" className={classes.marginTop}>
                  <FormLabel component="legend">
                    {t("processRelationship.forms.fields.noApply.label")}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue={field.value.toString()}
                    onChange={(event) => {
                      setFieldValue("visitWithNA", event.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="Exclude"
                      className={classes.textColor}
                      control={
                        <Radio
                          color="default"
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={t(
                        "processRelationship.forms.fields.noApply.options.noApply"
                      )}
                    />
                    <FormControlLabel
                      value="Include"
                      className={classes.textColor}
                      control={
                        <Radio
                          color="default"
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={t(
                        "processRelationship.forms.fields.noApply.options.apply"
                      )}
                    />
                  </RadioGroup>
                </FormControl>
              );
            }}
          />
        </GridItem>
      </GridContainer>
    </Form>
  );
};

VisitConfigForm.protoType = {};

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: "15px",
  },
  textColor: {
    color: "#000",
  },
  radio: {
    "&$checked": {
      color: "#6ab3d4",
    },
  },
  checked: {},
  textEnd: {
    textAlign: "end",
  },
}));

const VisitConfigFormWF = withFormik({
  validationSchema: VisitConfigFormValidationSchema,
  mapPropsToValues: (props) => {
    const { initialValues } = props;
    // TODO: Set from props for edit
    const visits = initialValues?.visits;
    const operationPonderable = initialValues?.operationPonderable;
    const visitWithNA = initialValues?.visitWithNA;
    const periodicity = initialValues?.periodicity;
    const visitPonderation = initialValues?.visitPonderation;

    return {
      visits: visits ? visits : [],
      operationPonderable:
        typeof operationPonderable !== "undefined" ? operationPonderable : true,
      visitWithNA: visitWithNA ? visitWithNA : "Exclude",
      periodicity: periodicity ? periodicity : "Weekly",
      visitPonderation: visitPonderation ? visitPonderation : [],
    };
  },
  handleSubmit: (values, { props }) => {
    props.updateValues(values, true);
  },
})(VisitConfigForm);

export default VisitConfigFormWF;
