export const buildRankingsSubject = ({sorting, keyfield, category}) => {
  return `Rankings de ${sorting} sobre el campo clave: ${keyfield} en ${category}`;
}

export const buildRankingsPDFBody = ({sorting, keyfield, category}) => {
  return `
    <p>Hola,</p>
    <br>
    <p>Te adjunto un PDF con los Rankings de ${sorting} sobre el campo clave ${keyfield} en ${category}.</p>
  `;
}

export const buildRankingsXLSBody = ({sorting, keyfield, category}) => {
  return `
    <p>Hola,</p>
    <br>
    <p>Te adjunto un XLS con los Rankings de ${sorting} sobre el campo clave ${keyfield} en ${category}.</p>
  `;
}