import React, {useState} from 'react';
import Moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useQuery, useSubscription} from "react-apollo-hooks";
import {includes} from "ramda";

import withStyles from '@material-ui/core/styles/withStyles';

// own components
import AdminApprovalItemsView from './../../Views/Visits/AdminApprovalItemsView';

// Local state
import useGlobal from '../../store';
import {VISIT_ITEMS, APPROVAL_ITEMS_ADMIN} from "./gql/Queries";

const approvalItemsLogFilter = (filter, approvalItems) => {
  return approvalItems.filter((approvalItem) => {
    const canFilterLogStatus = filter.approvalLogStatus && filter.approvalLogStatus.length > 0 &&
      approvalItem.approvalLogRef;
    const canFilterLogType = filter.approvalLogType && filter.approvalLogType.length > 0 &&
      approvalItem.approvalLogRef;
    const filteredLogStatus = canFilterLogStatus ?
      includes(approvalItem.approvalLogRef.status, filter.approvalLogStatus)
      : true;
    const filteredLogType = canFilterLogType ?
      includes(approvalItem.approvalLogRef.type, filter.approvalLogType)
      : true;
    return filteredLogStatus && filteredLogType;
  });
};

function AdminApprovalItems(props) {

  const [session] = useGlobal(
    state => state.session,
    actions => actions
  );
  const { t, } = useTranslation(['visits', 'approvalItems']);


  const {data: dataVisits, loading: loadingVisits} = useQuery(VISIT_ITEMS, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : '',
        hasChecklistConfiguration: true,
        isArchived: false
      }
    },
    fetchPolicy: 'network-only'
  });

  const [currentFilter, setCurrentFilter] = useState({
    app: session.user ? session.appRef._id : '',
    created_gte: Moment().startOf('day').subtract(30, 'days'),
    created_lte: Moment().endOf('day'),
    approvalItemStatus: ['Pending'],
    approvalLogStatus: [],
    approvalLogType: [],
    visits: [],
    users: []
  });
  const {
    data: dataApprovalItems,
    loading: loadingApprovalItems,
    error: errorApprovalItems,
    refetch: refetchApprovalItems
  } = useQuery(APPROVAL_ITEMS_ADMIN, {
    variables: {
      where: {
        app: currentFilter.app,
        created_gte: currentFilter.created_gte,
        created_lte: currentFilter.created_lte,
        status_in: currentFilter.approvalItemStatus
      }
    },
    fetchPolicy: 'network-only'
  });
  const approvalItemsClientFiltered = React.useMemo(() =>
    approvalItemsLogFilter(currentFilter, !loadingApprovalItems ? dataApprovalItems.approvalItems : [])
    , [currentFilter, dataApprovalItems]);

  return (
    <AdminApprovalItemsView
      approvalItems={!loadingApprovalItems ? approvalItemsClientFiltered : []}
      loading={loadingApprovalItems}
      currentFilter={currentFilter}
      visits={loadingVisits ? [] : dataVisits.visits}
      filterChanged={(filter) => {
        let changedFilter = {
          app: currentFilter.app,
          status_in: filter.approvalItemStatus,
          created_gte: filter.startDateCreated,
          created_lte: filter.endDateCreated,
        };
        if (filter.visits ? filter.visits.length > 0 : false) {
          changedFilter.visitId_in = filter.visits;
        }
        if (filter.users ? filter.users.length > 0 : false) {
          changedFilter.userId_in = filter.users;
        }
        setCurrentFilter({
          ...currentFilter,
          ...filter
        });
        refetchApprovalItems({
          where: changedFilter
        })
      }}
    />
  );
}

export default AdminApprovalItems;
