import gql from "graphql-tag";

export const MOBILE_LOCATION_CREATED_SUBSCRIPTION = gql`
  subscription mobileLocationCreated($where: MobileLocationCreatedWhereInput!){
    mobileLocationCreated(where: $where){
      node {
        _id
        app
        userId
        location {
          type
          coordinates
        }
        created
      }
    }
  }
`;
