import React from "react";
import Moment from 'moment';
import useGlobal from "../../store";
import { useQuery, useMutation} from "react-apollo-hooks";
import { VISIT_ITEMS, VISIT_ANSWER_IMAGES_ITEMS, VISIT_ANSWER_COUNT } from "./gql/Queries";
import LoadingCircleView from "../../Views/LoadingCircleView";
import { 
  addGlobalCategory,
  getOperationsFromColumns, 
  getCategories,
  getKeyfields,
  getModulesWithImages,
  parseVisitAnswers, 
  reduceToSelectedOperation,
  classifyAndFilter 
} from "../../utils/analytics/dataManipulation";
import {
  CREATE_GALLERY_VISOR_REPORT_MUTATION
} from "./gql/Mutations";
import GalleryVisorItemView from "../../Views/Visits/GalleryVisorItemView";
import { 
  reportCreationFailedNotification, 
  reportCreationOnProgressNotification,
  reportSentNotification,
  reportSentFailedNotification,
} from "../../utils/snackNotifications";
import LoadingProcessView from "../../Views/LoadingProcessView";

function GalleryVisorItem({match}) {
  const [ session, globalActions] = useGlobal(
    state => state.session,
    actions => actions
  );
  const [ filters, filtersActions ] = useGlobal(
    state => state.filters,
    actions => actions.filters
  );
  const visitID = match.params.idVisit === ":idVisit" ? null : match.params.idVisit;
  
  const {data, loading} = useQuery(VISIT_ITEMS, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : '',
        hasChecklistConfiguration: true,
        isArchived: false
      }
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const {
    data: dataVisitAnswers, 
    loading: loadingVisitAnswers,
    error: errorVisitAnswer
  } = useQuery(VISIT_ANSWER_IMAGES_ITEMS, {
      variables: {
        where: {
          userId: session.user._id,
          app: session.appId,
          visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
          checkInDate_gt: filters.range.startDate, 
          checkInDate_lte: filters.range.endDate,
          approvalStatus_not_in: ["Rejected", "Excluded"],
          hasCheckOutDate: true
        },
        orderBy: 'checkInDate_ASC'
      },
      skip: !filters.selectedVisitID,
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    });

    const {
      data: dataVisitAnswersOpen, 
      loading: loadingVisitAnswersOpen
    } = useQuery(VISIT_ANSWER_COUNT, {
        variables: {
          where: {
            userId: session.user._id,
            app: session.appId,
            visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
            checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
            checkInDate_lte: filters.range.endDate,
            approvalStatus_not_in: ["Rejected", "Excluded"],
            hasCheckOutDate: false
          }
        },
        skip: !filters.selectedVisitID,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      });

  const {
      data: dataVisitAnswersClosed, 
      loading: loadingVisitAnswersClosed
    } = useQuery(VISIT_ANSWER_COUNT, {
        variables: {
          where: {
            userId: session.user._id,
            app: session.appId,
            visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
            checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
            checkInDate_lte: filters.range.endDate,
            approvalStatus_not_in: ["Rejected", "Excluded"],
            hasCheckOutDate: true
          }
        },
        skip: !filters.selectedVisitID,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      });

  const addGeneralData = (payload, emailData) => {
    let data = { query: { 
      visit: filters.selectedVisitID, 
      operation: filters.selectedOperation, 
      ...payload
    }};
    data.visitId = filters.selectedVisitID?  filters.selectedVisitID.value : '';
    data.dateRange = {
      startDate: filters.range.startDate.format("DD-MM-YYYY"),
      endData: Moment(filters.range.endDate).format("DD-MM-YYYY")
    };
    data.app = session.user ? session.appRef._id : '';
    data.session = session;

    if(!!emailData){
      data.sendByEmail = true;
      data.emailInput = {
        contentHtml: emailData.contentHtml,
        subject: emailData.subject,
        to: emailData.to
      }
    }

    return data;
  };

  const executeMutation = (mutation, data, isEmail = false) => {
    mutation({ variables: { data: data }}).then(
      result => {
        if(isEmail) reportSentNotification(globalActions);
        else reportCreationOnProgressNotification(globalActions);
      }, 
      error => {
        console.log(error, data)
        if(isEmail) reportSentFailedNotification(globalActions);
        else reportCreationFailedNotification(globalActions);
      });
  };

  const createGalleryVisorReportMutation = useMutation(CREATE_GALLERY_VISOR_REPORT_MUTATION);
  
  const sendGalleryVisorPDFReport = React.useCallback((payload, emailData) => {
    const data = addGeneralData(payload, emailData);
    executeMutation(createGalleryVisorReportMutation, data, true); 
  }, [ createGalleryVisorReportMutation, filters, session, Moment ]);

  const filteredDataVisitAnswers = dataVisitAnswers && dataVisitAnswers.visitAnswers ? 
    dataVisitAnswers.visitAnswers.filter(visitAnswer => visitAnswer) : [];

  // If the store is empty or different, use the visitID from the route params
  if(data.visits && (
      (!!visitID && !filters.selectedVisitID) || 
      (!!visitID && visitID !== filters.selectedVisitID.value))){
    const option = data.visits.find((visit) => visitID === visit._id);
    filtersActions.setVisitID({label: option.name, value: option._id});
  }

  // Select by default the first visit ID
  if(!visitID && !filters.selectedVisitID && data.visits && data.visits.length > 0) {
    const option = data.visits[0];
    filtersActions.setVisitID({label: option.name, value: option._id});
  }

  let closedCount = null;
  if (!loadingVisitAnswersClosed && dataVisitAnswersClosed) {
    const { visitAnswerConnection } = dataVisitAnswersClosed;
    if (visitAnswerConnection) {
      const { aggregate } = visitAnswerConnection;
      closedCount = aggregate.count;
    }
  }

  if (loading || loadingVisitAnswers || loadingVisitAnswersClosed || loadingVisitAnswersOpen) {
    return <LoadingProcessView closedCount={closedCount}/>
  } else {
    const selecteVisit = data.visits ? 
      data.visits.find((visit) => 
        filters.selectedVisitID && 
        filters.selectedVisitID.value === visit._id) 
      : null;

    const operators = 
      selecteVisit && selecteVisit.checkListConfiguration 
      && selecteVisit.checkListConfiguration.columns ? 
        getOperationsFromColumns(selecteVisit.checkListConfiguration.columns) 
        : [];

    const selectedOperation = operators.find(
      operator => 
        filters.selectedOperation && 
        filters.selectedOperation.value === operator.id);

    // Select by default the first option
    if(!selectedOperation && operators && operators.length > 0) {
      const option = operators[0];
      filtersActions.setOperation({label: option.name, value: option.id});
    }
    
    const visits = data.visits ? data.visits.map(({_id, name}) => ({id: _id, name})) : []; 
    const keyfields = getKeyfields(selecteVisit);
    const categories = addGlobalCategory(getCategories(selectedOperation), filters.selectedOperation);
    const modules = getModulesWithImages(selecteVisit);
    const [activeCount, closedVisits] = classifyAndFilter(filteredDataVisitAnswers);
    const parsedClosed = closedVisits ? 
      parseVisitAnswers(closedVisits, keyfields) : [];
    const reducedVisitAnswers = reduceToSelectedOperation(parsedClosed, selectedOperation); 

    const emailData = {
      visit: filters.selectedVisitID?  filters.selectedVisitID.label : '', 
      startDate: filters.range.startDate.format("DD-MM-YYYY"),
      endDate: Moment(filters.range.endDate).format("DD-MM-YYYY")
    };

    return (
      <GalleryVisorItemView
        activeVisitAnswer={{ loadingActiveVisitanswers: loadingVisitAnswersOpen, dataOpenVisitanswer: dataVisitAnswersOpen }}
        closedVisitAnswer={{ loadingVisitAnswersClosedCount: loadingVisitAnswersClosed, dataVisitanswerClosedCount: dataVisitAnswersClosed }}
        categories={categories}
        keyfields={keyfields}
        modules={modules}
        visits={visits}
        visitAnswers={{ loadingVisitAnswersClosed: loadingVisitAnswers, dataVisitAnswer: reducedVisitAnswers }}
        operators={operators}
        sendGalleryVisorPDFReport={sendGalleryVisorPDFReport}
        emailData={emailData}
      />
    );
  }

}

export default GalleryVisorItem;
