import React from 'react'
import PropTypes from 'prop-types';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import LastUsers from './components/LastUsers';
import LastReports from './components/LastReports';
import LastEvents from './components/LastEvents';
//import TopScreens from './components/TopScreens';
import InfoApp from './components/InfoApp';
import Messages from './components/Messages';

const DashboardView = ({ infoUsers, infoEvents, infoReports, indexesReport, infoApp, filterIndexesReport, messagesApp, messagesGeneral }) => {
    return (
      <GridContainer>
        <GridContainer>
          <GridItem xs={4}>
            <LastReports
              infoReports={infoReports}
              indexesReport={indexesReport}
              filterIndexesReport={filterIndexesReport}
            />
          </GridItem>
          <GridItem xs={4}>
            <LastEvents infoEvents={infoEvents} />
          </GridItem>
          <GridItem xs={4}>
            <LastUsers infoUsers={infoUsers} />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={8}>
            <Messages
              messagesApp={messagesApp}
              messagesGeneral={messagesGeneral}
            />
          </GridItem>
          <GridItem xs={4}>
            <InfoApp infoApp={infoApp} />
          </GridItem>
        </GridContainer>
      </GridContainer>
    ); 
};

DashboardView.propTypes = {
    infoUsers: PropTypes.object.isRequired,
    infoEvents: PropTypes.object.isRequired,
    indexesReport: PropTypes.object.isRequired,
    infoApp: PropTypes.object.isRequired,
    messagesApp: PropTypes.object.isRequired,
}

export default DashboardView;
 