import React from "react";
import QuestionEngineAnswerView from "../QuestionEngineAnswers/QuestionEngineAnswerView";
import withStyles from "@material-ui/core/styles/withStyles";

const TicketReportTriggerView = ({ticketReport, classes,ticketConfig}) => {

  const showAllTriggerTicket = (existChecklist) => {
    if (existChecklist 
      && existChecklist.showAllTriggerTicket !== 'undefined' 
      && existChecklist.showAllTriggerTicket === false) {
      return true;
    } else {
      return false;
    }
  }

  if (ticketReport) {

    const dataTriggers = ticketConfig.triggers;

    const questionEngineAnswerTrigger = ticketReport.questionEngineAnswerTrigger[0];
    const moduleConfig = questionEngineAnswerTrigger.module;

    const isModuleExist = dataTriggers.some(({module}) => module._id === moduleConfig._id);

    if(!isModuleExist){

      const { ticketReportTriggerOrigin } = ticketReport;

      if (ticketReportTriggerOrigin) {
        const existChecklist = moduleConfig.propertiesRef.fields.find(
          field => field.key === ticketReportTriggerOrigin.key 
        );

        if (showAllTriggerTicket(existChecklist)) {
            questionEngineAnswerTrigger.answers = questionEngineAnswerTrigger.answers.filter(
              answer => answer.key === ticketReportTriggerOrigin.key);
        }
      } else {
        const existChecklist = moduleConfig.propertiesRef.fields.find(
          field => field.__typename === 'ChecklistField');

        if (showAllTriggerTicket(existChecklist)) {
          questionEngineAnswerTrigger.answers = questionEngineAnswerTrigger.answers.filter(
            answer => answer.key === existChecklist.key);
        }
      }
    }

    return (
      <div className={classes.mainContainer}>
        <h4 className={classes.center}>Información de Reporte de Ticket</h4>
        <QuestionEngineAnswerView questionEngineAnswer={questionEngineAnswerTrigger} />
      </div>
    );
  }else{
    return [];
  }
};

const styles = theme => ({
  mainContainer: {
    marginTop: 50,
    padding: 15
  },
  center: {
    textAlign: "center"
  }
});


export default withStyles(styles)(TicketReportTriggerView);

