import React from 'react';
import PropTypes from 'prop-types';

// custom components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";

// core components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import DivCenter from "../../components/styled-components/DivCenter";

import {baseDateFormat} from "../../utils/dates/dates";

const EngineAnswerFieldChangesLogView = ({questionAnswerUpdateLogs, checklist}) => {

  const renderAnswerType = (updateLog) => {
    switch (updateLog.previousAnswer.__typename) {
      case "OpenFieldAnswer":
        return <DivCenter>
          <ListItemText>{updateLog.previousAnswer["OpenFieldAnswer"]}</ListItemText>
        </DivCenter>;
      case "NumericFieldAnswer":
        return <DivCenter>
          <ListItemText>{updateLog.previousAnswer["NumericFieldAnswer"]}</ListItemText>
        </DivCenter>;
      case "DateFieldAnswer":
        return <DivCenter>
          <ListItemText>{updateLog.previousAnswer["DateFieldAnswer"]}</ListItemText>
        </DivCenter>;
      case "PhoneFieldAnswer":
        return <DivCenter>
          <ListItemText>{updateLog.previousAnswer["PhoneFieldAnswer"]}</ListItemText>
        </DivCenter>;
      case "ListUniqueFieldAnswer":
        return <DivCenter>
          <ListItemText>{updateLog.previousAnswer["ListUniqueFieldAnswer"].join(' - ')}</ListItemText>
        </DivCenter>;
      case "ListMultipleFieldAnswer":
        return <DivCenter>
          <ListItemText>{updateLog.previousAnswer["ListMultipleFieldAnswer"].join(' - ')}</ListItemText>
`        </DivCenter>;
      case "EmailFieldAnswer":
        return <DivCenter>
          <ListItemText>{updateLog.previousAnswer["EmailFieldAnswer"]}</ListItemText>
        </DivCenter>;
      case "PasswordFieldAnswer":
        return <DivCenter>
          <ListItemText>{updateLog.previousAnswer["PasswordFieldAnswer"]}</ListItemText>
        </DivCenter>;
      case "OpenMultilineFieldAnswer":
        return <DivCenter>
          <ListItemText>{updateLog.previousAnswer["OpenMultilineFieldAnswer"]}</ListItemText>
        </DivCenter>;
      case "PhotoReportFieldAnswer":
        return [];
      case "MapFieldAnswer":
        return [];
      case "SignatureFieldAnswer":
        return [];
      case "CodeBarFieldAnswer":
        return [];
      case "MassiveListUniqueFieldAnswer":
        return <DivCenter>
          <ListItemText>{updateLog.previousAnswer["MassiveListUniqueFieldAnswer"].join(' - ')}</ListItemText>
        </DivCenter>;
      case "MassiveListMultipleFieldAnswer":
        return <DivCenter>
          <ListItemText>{updateLog.previousAnswer["MassiveListMultipleFieldAnswer"].join(' - ')}</ListItemText>
        </DivCenter>;
      case "ChecklistFieldAnswer":
        const checklistOption = checklist.options.find((option) => {
          return option._id === updateLog.previousAnswer.id;
        });
        return <DivCenter>
          <ListItemText>{checklistOption ? checklistOption.name : "N/A"}</ListItemText>
        </DivCenter>;
      case "TokenFieldAnswer":
        return [];
      case "TableFieldAnswer":
        return [];
      case "GeofenceFieldAnswer":
        return [];
    }
  };

  return questionAnswerUpdateLogs && questionAnswerUpdateLogs.length > 0 ? (
    <React.Fragment>
      <DivCenter>Log de Cambios</DivCenter>
      <List>
        {
          questionAnswerUpdateLogs.map((updateLog, index) => {
            return (
              <ListItem key={index}>
                <GridContainer>
                  <GridItem xs={6}>
                    <DivCenter>
                      Valor hasta:
                      <br/>
                      {baseDateFormat(updateLog.created)}
                    </DivCenter>
                  </GridItem>
                  <GridItem xs={6}>
                    <DivCenter>
                      Usuario:
                      <br/>
                      {updateLog.userRef.email}
                    </DivCenter>
                  </GridItem>
                  <GridItem xs={12}>
                    {
                      renderAnswerType(updateLog)
                    }
                  </GridItem>
                </GridContainer>
              </ListItem>
            );
          })
        }
      </List>
    </React.Fragment>
  ) : (<DivCenter>Sin cambios</DivCenter>);
};

EngineAnswerFieldChangesLogView.propTypes = {

};

export default EngineAnswerFieldChangesLogView;
