import gql from "graphql-tag";

export const All_CHECKLIST_ANALISYS_VISIT_FEED = gql`
  query GetAllChecklistAnalisysReport(
    $where: ChecklistAnalisysVisitReportWhereInput!
    $first: Int
    $skip: Int
    $orderBy: ChecklistAnalisysVisitReportOrderByInput
  ) {
    getAllChecklistAnalisysVisitReport(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      _id
      app
      visit
      visitRef{
        name
        keys
      }
      count
      keyField
      name
      emails
      groups{
        _id
        name
        type
      }
      operation
      type
      description
      content{
        type
        title
        subtitle
        module
        key
      }
      columns
      programableReport{
        destination
        type
        sendToAll
        users{
          email
        }
        startDate
        intervalCriteria
        intervalDays
        dateFilter
        status
        message
        startDateTimezone{
        	zone
          utc
    	  }
        endDateTimezone{
        	zone
          utc
    	  }
        intervalCalue
        endDate
        reportType
        reportContent
        intervalValue
      }
      archived
      created
    }
  }
`;

export const VISIT_ITEMS = gql`
  query visitItems($where: VisitWhereInput!) {
    visits(where: $where) {
      _id
      name
      app
      keys
      engines{
        visitId
        order
        moduleId
        moduleRef{
          name
          kind
          type
          status
          propertiesRef {
            __typename
            ... on QuestionEngineModule {
              _id
              fields {
                __typename
                ... on OpenField {
                  _id
                  key
                }
                ... on NumericField {
                  _id
                  key
                }
                ... on DateField {
                  _id
                  key
                }
                ... on PhoneField {
                  _id
                  key
                }
                ... on EmailField {
                  _id
                  key
                }
                ... on PasswordField {
                  _id
                  key
                }
                ... on OpenMultilineField {
                  _id
                  key
                }
                ... on PhotoReportField {
                  _id
                  key
                }
                ... on MapField {
                  _id
                  key
                }
                ... on SignatureField {
                  _id
                  key
                }
                ... on CodeBarField {
                  _id
                  key
                }
                ... on ChecklistField {
                  _id
                  key
                }
                ... on TokenField {
                  _id
                  key
                }
                ... on TableField {
                  _id
                  key
                }
                ... on GeofenceField {
                  _id
                  key
                }
                ... on ListUniqueField {
                  _id
                  key
                  options{
                    name
                    value
                  }
                }
                ... on ListMultipleField {
                  _id
                  key
                  options{
                    name
                    value
                  }
                }
                ... on MassiveListUniqueField {
                  _id
                  key
                  massiveList
                  
                }
                ... on MassiveListMultipleField {
                  _id
                  key
                  massiveList
                }
              }
            }
          }
        }
      }
      checklistAnalisysReport{
        _id
        name
        visit
        keyField
        type
      }
      checkListConfiguration{ 
      	_id
      	needsApproval
        columns{
          category
          operation{
            name
            operator
          }
        }	
      }
    }
  } 
`;

export const USERS_GH = gql`
  query userGHs($where: UserGhWhereInput!) {
    userGHs(where: $where) {
      email
    }
  } 
`;

export const SEGMENTS_FEED = gql`
  query segments($where: SegmentWhereInput!) {
    segments(where: $where) {
      _id
      name
    }
  } 
`;

export const TEAMS_FEED = gql`
  query teams($where: TeamWhereInput!) {
    getTeamsByApp(where: $where) {
      _id
      name
    }
  } 
`;

export const VISIT_ANSWER_REPORT = gql`
  query GetAllVisitAnswers(
    $where: VisitAnswerWhereInput! 
    $first: Int
    $skip: Int
    $orderBy: VisitAnswerOrderByInput){
      getAllVisitAnswers(
        where: $where
        orderBy: $orderBy
        first: $first
        skip: $skip
    ){
      total
      modules{
        _id
        name
      }
      visitAnswers{
          _id
          duration
          analytics
          checkInDate
          checkOutDate
          userId
          userRef {
            _id
            email
            firstName
            lastName
          }
          keys
          checkIn {
            questionAnswersId
            questionAnswersRef {
              _id
              module {
                _id
                name
              }
              userId
              userRef {
                _id
                email
                firstName
                lastName
              }
              answers {
                key
                questionLabel
                answer {
                  __typename
                  ... on OpenFieldAnswer {
                    OpenFieldAnswer: value
                  }
                  ... on NumericFieldAnswer {
                    NumericFieldAnswer: value
                  }
                  ... on DateFieldAnswer {
                    DateFieldAnswer: value
                  }
                  ... on PhoneFieldAnswer {
                    PhoneFieldAnswer: value
                  }
                  ... on ListUniqueFieldAnswer {
                    ListUniqueFieldAnswer: value,
                    config {
                      key
                      type
                      label
                      help
                      required
                      listStyle
                      options {
                        _id
                        value
                        name
                        segment
                        emails
                        image {
                          original
                          i1280
                          i240
                          i320
                          i480
                          i640
                          i960
                        }
                      }
                    }
                  }
                  ... on ListMultipleFieldAnswer {
                    ListMultipleFieldAnswer: value,
                    config {
                      key
                      type
                      label
                      help
                      required
                      listStyle
                      options {
                        _id
                        value
                        name
                        segment
                        emails
                        image {
                          original
                          i1280
                          i240
                          i320
                          i480
                          i640
                          i960
                        }
                      }
                    }
                  }
                  ... on EmailFieldAnswer {
                    EmailFieldAnswer: value
                  }
                  ... on PasswordFieldAnswer {
                    PasswordFieldAnswer: value
                  }
                  ... on OpenMultilineFieldAnswer {
                    OpenMultilineFieldAnswer: value
                  }
                  ... on PhotoReportFieldAnswer {
                    PhotoReportFieldAnswer: value {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                  ... on MapFieldAnswer {
                    longitude
                    latitude
                  }
                  ... on SignatureFieldAnswer {
                    SignatureFieldAnswer: value {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                  ... on CodeBarFieldAnswer {
                    CodeBarFieldAnswer: value
                  }
                  ... on MassiveListUniqueFieldAnswer {
                    MassiveListUniqueFieldAnswer: value
                    config {
                      massiveList
                    }
                  }
                  ... on MassiveListMultipleFieldAnswer {
                    MassiveListMultipleFieldAnswer: value
                    config {
                      massiveList
                    }
                  }
                  ... on ChecklistFieldAnswer {
                    ChecklistFieldAnswer: id
                    comment
                    email
                    photos {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                    signature {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                  ... on TokenFieldAnswer {
                    TokenFieldAnswer: value
                  }
                  ... on TableFieldAnswer {
                    rows {
                      answers {
                        key
                        answer {
                          __typename
                          ... on OpenSubFieldAnswer {
                            OpenSubFieldAnswer: value
                          }
                          ... on NumericSubFieldAnswer {
                            NumericSubFieldAnswer: value
                          }
                          ... on DateSubFieldAnswer {
                            DateSubFieldAnswer: value
                          }
                          ... on PhoneSubFieldAnswer {
                            PhoneSubFieldAnswer: value
                          }
                          ... on MassiveListUniqueSubFieldAnswer {
                            MassiveListUniqueSubFieldAnswer: value
                          }
                        }
                      }
                    }
                    config {
                      _id
                      key
                      type
                      label
                      columns {
                        __typename
                        ... on OpenSubField {
                          _id
                          key
                          type
                          label
                          width
                        }
                        ... on NumericSubField {
                          _id
                          key
                          type
                          label
                          width
                          numericFormatStyle
                        }
                        ... on DateSubField {
                          _id
                          key
                          type
                          label
                          width
                        }
                        ... on PhoneSubField {
                          _id
                          key
                          type
                          label
                          width
                        }
                        ... on MassiveListUniqueSubField {
                          _id
                          key
                          type
                          label
                          width
                        }
                      }
                    }
                  }
                  ... on GeofenceFieldAnswer {
                    GeofenceFieldAnswer: value
                  }
                }
              }
              checklists {
                _id
                app
                name
                options {
                  _id
                  name
                  image {
                    i320
                    i480
                  }
                  numericValue
                }
              }
            }
          }
          checkOut {
            questionAnswersId
            questionAnswersRef {
              _id
              module {
                _id
                name
              }
              userId
              userRef {
                _id
                email
                firstName
                lastName
              }
              answers {
                key
                questionLabel
                answer {
                  __typename
                  ... on OpenFieldAnswer {
                    OpenFieldAnswer: value
                  }
                  ... on NumericFieldAnswer {
                    NumericFieldAnswer: value
                  }
                  ... on DateFieldAnswer {
                    DateFieldAnswer: value
                  }
                  ... on PhoneFieldAnswer {
                    PhoneFieldAnswer: value
                  }
                  ... on ListUniqueFieldAnswer {
                    ListUniqueFieldAnswer: value,
                    config {
                      key
                      type
                      label
                      help
                      required
                      separator
                      separatorPosition
                      separatorSize
                      separatorText
                      listStyle
                      options {
                        _id
                        value
                        name
                        segment
                        emails
                        image {
                          original
                          i1280
                          i240
                          i320
                          i480
                          i640
                          i960
                        }
                      }
                    }
                  }
                  ... on ListMultipleFieldAnswer {
                    ListMultipleFieldAnswer: value,
                    config {
                      key
                      type
                      label
                      help
                      required
                      separator
                      separatorPosition
                      separatorSize
                      separatorText
                      listStyle
                      options {
                        _id
                        value
                        name
                        segment
                        emails
                        image {
                          original
                          i1280
                          i240
                          i320
                          i480
                          i640
                          i960
                        }
                      }
                    }
                  }
                  ... on EmailFieldAnswer {
                    EmailFieldAnswer: value
                  }
                  ... on PasswordFieldAnswer {
                    PasswordFieldAnswer: value
                  }
                  ... on OpenMultilineFieldAnswer {
                    OpenMultilineFieldAnswer: value
                  }
                  ... on PhotoReportFieldAnswer {
                    PhotoReportFieldAnswer: value {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                  ... on MapFieldAnswer {
                    longitude
                    latitude
                  }
                  ... on SignatureFieldAnswer {
                    SignatureFieldAnswer: value {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                  ... on CodeBarFieldAnswer {
                    CodeBarFieldAnswer: value
                  }
                  ... on MassiveListUniqueFieldAnswer {
                    MassiveListUniqueFieldAnswer: value
                    config {
                      massiveList
                    }
                  }
                  ... on MassiveListMultipleFieldAnswer {
                    MassiveListMultipleFieldAnswer: value
                    config {
                      massiveList
                    }
                  }
                  ... on ChecklistFieldAnswer {
                    ChecklistFieldAnswer: id
                    comment
                    email
                    photos {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                    signature {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                  ... on TokenFieldAnswer {
                    TokenFieldAnswer: value
                  }
                  ... on TableFieldAnswer {
                    rows {
                      answers {
                        key
                        answer {
                          __typename
                          ... on OpenSubFieldAnswer {
                            OpenSubFieldAnswer: value
                          }
                          ... on NumericSubFieldAnswer {
                            NumericSubFieldAnswer: value
                          }
                          ... on DateSubFieldAnswer {
                            DateSubFieldAnswer: value
                          }
                          ... on PhoneSubFieldAnswer {
                            PhoneSubFieldAnswer: value
                          }
                          ... on MassiveListUniqueSubFieldAnswer {
                            MassiveListUniqueSubFieldAnswer: value
                          }
                        }
                      }
                    }
                    config {
                      _id
                      key
                      type
                      label
                      columns {
                        __typename
                        ... on OpenSubField {
                          _id
                          key
                          type
                          label
                          width
                        }
                        ... on NumericSubField {
                          _id
                          key
                          type
                          label
                          width
                          numericFormatStyle
                        }
                        ... on DateSubField {
                          _id
                          key
                          type
                          label
                          width
                        }
                        ... on PhoneSubField {
                          _id
                          key
                          type
                          label
                          width
                        }
                        ... on MassiveListUniqueSubField {
                          _id
                          key
                          type
                          label
                          width
                        }
                      }
                    }
                  }
                  ... on GeofenceFieldAnswer {
                    GeofenceFieldAnswer: value
                  }
                }
              }
              checklists {
                _id
                app
                name
                options {
                  _id
                  name
                  image {
                    i320
                    i480
                  }
                  numericValue
                }
              }
            }
          }
          activities {
            moduleId
            questionAnswersId
            questionAnswersRef {
              _id
              module {
                _id
                name
              }
              userId
              userRef {
                _id
                email
                firstName
                lastName
              }
              answers {
                key
                questionLabel
                answer {
                  __typename
                  ... on OpenFieldAnswer {
                    OpenFieldAnswer: value
                  }
                  ... on NumericFieldAnswer {
                    NumericFieldAnswer: value
                  }
                  ... on DateFieldAnswer {
                    DateFieldAnswer: value
                  }
                  ... on PhoneFieldAnswer {
                    PhoneFieldAnswer: value
                  }
                  ... on ListUniqueFieldAnswer {
                    ListUniqueFieldAnswer: value,
                    config {
                      key
                      type
                      label
                      help
                      required
                      separator
                      separatorPosition
                      separatorSize
                      separatorText
                      listStyle
                      options {
                        _id
                        value
                        name
                        segment
                        emails
                        image {
                          original
                          i1280
                          i240
                          i320
                          i480
                          i640
                          i960
                        }
                      }
                    }
                  }
                  ... on ListMultipleFieldAnswer {
                    ListMultipleFieldAnswer: value,
                    config {
                      key
                      type
                      label
                      help
                      required
                      separator
                      separatorPosition
                      separatorSize
                      separatorText
                      listStyle
                      options {
                        _id
                        value
                        name
                        segment
                        emails
                        image {
                          original
                          i1280
                          i240
                          i320
                          i480
                          i640
                          i960
                        }
                      }
                    }
                  }
                  ... on EmailFieldAnswer {
                    EmailFieldAnswer: value
                  }
                  ... on PasswordFieldAnswer {
                    PasswordFieldAnswer: value
                  }
                  ... on OpenMultilineFieldAnswer {
                    OpenMultilineFieldAnswer: value
                  }
                  ... on PhotoReportFieldAnswer {
                    PhotoReportFieldAnswer: value {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                  ... on MapFieldAnswer {
                    longitude
                    latitude
                  }
                  ... on SignatureFieldAnswer {
                    SignatureFieldAnswer: value {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                  ... on CodeBarFieldAnswer {
                    CodeBarFieldAnswer: value
                  }
                  ... on MassiveListUniqueFieldAnswer {
                    MassiveListUniqueFieldAnswer: value
                    config {
                      massiveList
                    }
                  }
                  ... on MassiveListMultipleFieldAnswer {
                    MassiveListMultipleFieldAnswer: value
                    config {
                      massiveList
                    }
                  }
                  ... on ChecklistFieldAnswer {
                    ChecklistFieldAnswer: id
                    comment
                    email
                    photos {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                    signature {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                  ... on TokenFieldAnswer {
                    TokenFieldAnswer: value
                  }
                  ... on TableFieldAnswer {
                    rows {
                      answers {
                        key
                        answer {
                          __typename
                          ... on OpenSubFieldAnswer {
                            OpenSubFieldAnswer: value
                          }
                          ... on NumericSubFieldAnswer {
                            NumericSubFieldAnswer: value
                          }
                          ... on DateSubFieldAnswer {
                            DateSubFieldAnswer: value
                          }
                          ... on PhoneSubFieldAnswer {
                            PhoneSubFieldAnswer: value
                          }
                          ... on MassiveListUniqueSubFieldAnswer {
                            MassiveListUniqueSubFieldAnswer: value
                          }
                        }
                      }
                    }
                    config {
                      _id
                      key
                      type
                      label
                      columns {
                        __typename
                        ... on OpenSubField {
                          _id
                          key
                          type
                          label
                          width
                        }
                        ... on NumericSubField {
                          _id
                          key
                          type
                          label
                          width
                          numericFormatStyle
                        }
                        ... on DateSubField {
                          _id
                          key
                          type
                          label
                          width
                        }
                        ... on PhoneSubField {
                          _id
                          key
                          type
                          label
                          width
                        }
                        ... on MassiveListUniqueSubField {
                          _id
                          key
                          type
                          label
                          width
                        }
                      }
                    }
                  }
                  ... on GeofenceFieldAnswer {
                    GeofenceFieldAnswer: value
                  }
                }
              }
              checklists {
                _id
                app
                name
                options {
                  _id
                  name
                  image {
                    i320
                    i480
                  }
                  numericValue
                }
              }
            }
          }
          calculatedChecklistColumns {
            categories {
              name
              operations {
                name
                operator
                value
              }
            }
            globals {
              name
              value
            }
          }
        }
      }
  }
`;
