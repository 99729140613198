import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';

// core components
import withStyles from "@material-ui/core/styles/withStyles";

// custom components
import Button from "components/Button";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import DownloadButton from "components/Reports/DownloadButton";
import SendEmailButton from "components/Reports/SendEmailButton";
import PreviewButton from "components/Reports/PreviewButton";
import QuestionEngineAnswerView from '../../QuestionEngineAnswers/QuestionEngineAnswerView';
import TableGallery from './TableGallery';

function TablesGalleryView({
  classes, 
  modules,
}) {
  const [answerIdx, setAnswerIdx] = React.useState(0);

  React.useEffect(() => {
    setAnswerIdx(0);
  }, [modules]);

  const [tableAnswers, textModules] = React.useMemo(() => {
    const tableAnswers = [];
    const textModules = [];

    modules.forEach((moduleRef) => {
      const {answers} = moduleRef.questionAnswersRef;
      const textAnswers = [];

      answers.forEach((answer) => {
        if(answer.answer.__typename === "TableFieldAnswer" ) {
          tableAnswers.push({
            ...answer, 
            questionAnswersRef: moduleRef.questionAnswersRef._id,
          });
        } else {
          textAnswers.push(answer);
        }
      });    
      textModules.push({
        ...moduleRef.questionAnswersRef,
        answers: textAnswers,
        keyfields: moduleRef.keyfields,
      })
    })

    return [tableAnswers, textModules]; 
  }, [modules]);

  const currentTable = React.useMemo(() => 
    !!tableAnswers && tableAnswers.length > 0 ? 
    tableAnswers[answerIdx] : null,
  [tableAnswers, answerIdx]);

  const currentModuleText = React.useMemo(() => {
    const selectedModule = tableAnswers[answerIdx];
    if(!selectedModule) return null;
    return textModules.find(textModule => 
      textModule._id === selectedModule.questionAnswersRef);
  }, [tableAnswers, answerIdx, textModules]);

  const nextTable = React.useCallback(() => {
    if(answerIdx + 1 < tableAnswers.length){
      setAnswerIdx(prevIdx => prevIdx + 1);
    }
  }, [tableAnswers, answerIdx, setAnswerIdx]);

  const prevTable = React.useCallback(() => {
    if(answerIdx > 0){
      setAnswerIdx(prevIdx => prevIdx - 1);
    }
  }, [answerIdx, setAnswerIdx]);

  const isFirstTable = React.useMemo(() => {
    return answerIdx === 0;
  }, [answerIdx]);

  const isLastTable = React.useMemo(() => {
    if(!tableAnswers || tableAnswers.length === 0) return true;
    return answerIdx === tableAnswers.length - 1;
  }, [answerIdx, tableAnswers]);

  return (
    <Card>
      <CardHeader color="info" text>
        <GridContainer>
          <GridItem xs={9}>
          </GridItem>
          <GridItem xs={1}>
            <CardText color="info" className={classes.noPadding}>
              <SendEmailButton
                color="white"
                isReady={false}
                size="lg"
              />
            </CardText>
          </GridItem>
          <GridItem xs={1}>
            <CardText color="info" className={classes.noPadding}>
              <DownloadButton
                color="white"
                isReady={false}
                size="lg"
              />
            </CardText>
          </GridItem>
          <GridItem xs={1}>
            <CardText color="info" className={classes.noPadding}>
              <PreviewButton
                color="white"
                isReady={false}
                size="lg"
              />
            </CardText>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={6}>
            <TableGallery
              table={currentTable}
            />
            <GridContainer>
              <GridItem xs={6}>
                <Button
                  size="sm"
                  color={isFirstTable ?  "primary" : "info"}
                  disabled={isFirstTable}
                  fullWidth
                  onClick={prevTable}
                >
                  Anterior
                </Button>
              </GridItem>
              <GridItem xs={6}>
                <Button
                  size="sm"
                  color={isLastTable ? "primary" : "info"}
                  disabled={isLastTable}
                  fullWidth
                  onClick={nextTable}
                >
                  Siguiente
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6}>
            { !!currentModuleText &&
              <QuestionEngineAnswerView
                questionEngineAnswer={currentModuleText}
              />
            }
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const styles = theme => ({
  noPadding: {
    padding: 0
  },
  textContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: '100%',
    height: '100%',
  },
});

TablesGalleryView.propTypes = {
  modules: PropTypes.array.isRequired
};

export default withStyles(styles)(TablesGalleryView);
