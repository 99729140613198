import gql from "graphql-tag";

export const CREATE_VISIT_SCHEDULING = gql`
  mutation createVisitScheduling($data: VisitSchedulingCreateInput!) {
    createVisitScheduling(data: $data) {
      _id
      name
    }
  }
`;

export const UPDATE_VISIT_SCHEDULING = gql`
  mutation updateVisitScheduling(
    $where: VisitSchedulingWhereUniqueInput!
    $data: VisitSchedulingUpdateInput!
  ) {
    updateVisitScheduling(where: $where, data: $data) {
      _id
      name
    }
  }
`;

export const ARCHIVE_VISIT_SCHEDULING = gql`
  mutation archiveVisitScheduling(
    $where: VisitSchedulingWhereUniqueInput!
  ) {
    archiveVisitScheduling(where: $where) {
      _id
      name
    }
  }
`;
