import React from "react";
import {useQuery} from "react-apollo-hooks";
import PropTypes from 'prop-types';

// Own components
import { VISIT_ANSWER_REPORT } from "../gql/Queries";
import VisitAnswerView from "../../../Views/Visits/VisitAnswerView";
import LoadingCircleView from "../../../Views/LoadingCircleView";

function VisitAnswerItem({classes, visitAnswerId, visit}){
  const {
    data,
    loading
  } = useQuery(VISIT_ANSWER_REPORT, {
      variables: {
        where: {
          _id: visitAnswerId,
        }
      },
      skip: !visitAnswerId,
      fetchPolicy: 'network-only'
    });

  if (loading) {
    return <LoadingCircleView/>
  } else {
    return data.visitAnswers ?
      <VisitAnswerView
        classes={classes}
        visitAnswer={data.visitAnswers[0]}
        visit={visit}
        editable={false}
      />
    :
      <LoadingCircleView/>
    ;
  }
}

VisitAnswerItem.propTypes = {
  visitAnswerId: PropTypes.string.isRequired,
  visit: PropTypes.object.isRequired,
};

export default VisitAnswerItem;
