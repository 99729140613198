import session from "../utils/session";
import { CHECK_SESSION } from "../containers/App/Queries";
import history from "../utils/history";

const { GH_FRONT_URL } = window._env_;

export const signin = async (store, sessionResult, redirectTo = "") => {
  await session.setDashAuthToken(sessionResult.token);
  await session.setDashAppToken(sessionResult.appId);
  await session.setDashUserToken(sessionResult.user._id);
  await store.setState({ session: sessionResult });
  const path = redirectTo || "dashboard";
  history.push(path);
};

export const logout = async (store) => {
  await session.clearAuthTokens();
  await store.setState({ session: { user: null } });
  // await session.clearAuthToken();
  window.location.href = `${GH_FRONT_URL}/#/access/signin`;
};

export const isAuthenticated = async (store, client, route) => {
  try {
    if (store.state.loading) {
      return;
    }

    // Set as loading session
    await store.setState({
      session: {
        ...store.state.session,
        loading: true,
        cached: false,
      },
    });

    const token = session ? await session.getDashAuthToken() : "";
    const app = session ? await session.getDashAppToken() : "";

    if (token && !store.state.session.user) {
      const { data } = await client.query({
        query: CHECK_SESSION,
        variables: {
          token: token,
          app: app,
        },
      });
      const sessionResult = data.checkSession;

      if (sessionResult) {
        const session = {
          ...sessionResult,
          loading: false,
        };
        await store.setState({ session });
        await localStorage.setItem("session", JSON.stringify(session));
      }
    } else {
      const session = {
        ...store.state.session,
        loading: false,
      };
      await store.setState({ session });
      await localStorage.setItem("session", JSON.stringify(session));
    }
  } catch (e) {
    console.log(e);
    await store.setState({
      session: {
        user: null,
        loading: false,
      },
    });
  }
};

export const checkExternalSession = async (
  store,
  client,
  token,
  app,
  redirectTo
) => {
  try {
    const { data } = await client.query({
      query: CHECK_SESSION,
      variables: {
        token: token,
        app: app,
      },
    });
    const sessionResult = data.checkSession;
    const features = sessionResult?.featuresPlan?.features;

    const disabledDashboard = features
      ? features?.dashboard === "on"
        ? true
        : false
      : true;
    if (sessionResult && disabledDashboard) {
      await signin(store, sessionResult, redirectTo);
    } else {
      await logout(store);
    }
  } catch (e) {
    console.log(e);
  }
};
