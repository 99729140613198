import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { CircularProgress, ButtonBase } from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import CounterReportsTable from "../../tables/SO/CounterReportsTable";

import history from "../../utils/history";
import Badge from 'components/Badge/Badge';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import useGlobal from "../../store";
import auth from "../../utils/auth";

const CounterReportListView = ({
  soCounterReports,
  soCounterReportsLoading,
  generateReport,
  archive,
  changeArchivedMode,
  isArchivedMode,
  plan
}) => {
  const { t } = useTranslation("so");

  const soCounterReportCount = plan ? parseInt(plan?.features?.soCounterReport) : 50;
  const currentSoCounterReport = soCounterReports ? soCounterReports?.length : 50;

  const disabledSoCounterReport = soCounterReportCount <= currentSoCounterReport;
  const reportCount = (soCounterReportCount - currentSoCounterReport) >= 0 ? soCounterReportCount - currentSoCounterReport : 0;

  const [session] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const hasPermission = (permission) => {
    if ( session.user && !auth.hasPermission(permission, session.permissions)) return false;
    return true;
  }; 

  return (
    <Card>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <AssignmentTurnedInIcon />
        </CardIcon>
        <div style={style.left}>
          <h4 style={style.cardTitle}>{t("so.counterReports.list.title")}</h4>
        </div>
        <div style={style.right}>
          <Button
            size="sm"
            color={!isArchivedMode ? "danger" : "success"}
            onClick={changeArchivedMode}
          >
            {!isArchivedMode ? "Ver Archivados" : "Ver Activos"}
          </Button>
          <Button
            id={"new-report"}
            size="sm"
            color="info"
            disabled={!hasPermission('soCounterCreated')}
            onClick={() => history.push(`/so/counterReport/new`)}
            disabled={disabledSoCounterReport}
          >
            {t("so.counterReports.list.buttons.add")}
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12}>
            <div style={style.right}>
              <Badge color={"info"}>
                {t("so.remaning")}: {reportCount}
              </Badge>
            </div>
          </GridItem>

          <GridItem xs={12}>
            {soCounterReportsLoading ? (
              <div style={style.progressCnt}>
                <CircularProgress style={style.progress} />
              </div>
            ) : (
              <CounterReportsTable
                list={soCounterReports}
                archive={archive}
                isArchivedMode={isArchivedMode}
                generateReport={generateReport}
              />
            )}
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

CounterReportListView.propTypes = {
  soCounterReports: PropTypes.array.isRequired,
  soCounterReportsLoading: PropTypes.bool.isRequired,
};

const style = {
  cardTitle: {
    color: "#3C4858",
  },
  progress: {
    margin: "16px",
  },
  progressCnt: {
    width: "100px !important",
    marginLeft: "50%",
    marginRight: "50%",
  },
  cardHeight: {
    height: "430px",
  },
  left: {
    float: "left",
  },
  right: {
    float: "right",
  },
};

export default CounterReportListView;
