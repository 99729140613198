import React from 'react'
import ReactTable from 'react-table';
import PropTypes from "prop-types";
import DivCenter from '../../components/styled-components/DivCenter';


const IndexesReportsTable = ({indexesReports}) => {
    return (
      <ReactTable
        data={indexesReports}
        columns={[
          {
            Header: <DivCenter>Manuales</DivCenter>,
            accessor: "manual",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 30
          },
          {
            Header: <DivCenter>Automaticos</DivCenter>,
            accessor: "automatic",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 30
          },
          {
            Header: <DivCenter>Programables</DivCenter>,
            accessor: "programmable",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 30
          },
          /* {
            Header: <DivCenter>PDF</DivCenter>,
            accessor: "pdf",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 30
          },
          {
            Header: <DivCenter>Excel</DivCenter>,
            accessor: "excel",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 30
          }, */
        ]}
        defaultPageSize={1}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
    );
};

IndexesReportsTable.propTypes = {
    indexesReports: PropTypes.array.isRequired,
}

export default IndexesReportsTable
