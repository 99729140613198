import React, {useState} from 'react';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import AddBoxIcon from '@material-ui/icons/AddBox';
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import ChecklistAnalisysReportWizar from '../../wizars/CheckAnalisys';
const NewChecklistAnalisysVisitReportView = ({ app, visits, submitSuccess, currentVisit, users, segments, teams }) => {
    const { t } = useTranslation("checklistAnalisys");
    const { dataVisit } = visits;
    const { dataUsers } = users;
    const { dataSegments } = segments;
    const { dataTeams } = teams;
    const [state, setState] = useState({
      type: "ExitVisit"
    });

    const initData = {
      app: app,
      currentVisit: currentVisit,
      visits: dataVisit.visits ? dataVisit.visits : [],
      users: dataUsers.userGHs ? dataUsers.userGHs : [],
      segments: dataSegments.segments ? dataSegments.segments : [],
      teams: dataTeams.getTeamsByApp ? dataTeams.getTeamsByApp : []
    }
    
    return (
      <GridContainer justify="center">
        <GridItem xs={10}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <AddBoxIcon />
              </CardIcon>
              <h4 style={style.cardTitle}>
                {t("checklistAnalisys.wizar.title")}
              </h4>
            </CardHeader>
            <CardBody>
                <ChecklistAnalisysReportWizar
                  setConfigReport={setState} 
                  configReport={state} 
                  initDataForm={initData}
                  submitSuccess={submitSuccess}
                />
                {/* <ChecklistAnalisysVisitReportFormWF
                    app={app}
                    visits={dataVisit.visits ? dataVisit.visits : []}
                    submitSuccess={submitSuccess}
                    currentVisit={currentVisit}
                    users={dataUsers.userGHs ? dataUsers.userGHs : []}
                    segments={dataSegments.segments ? dataSegments.segments : []}
                    teams={dataTeams.getTeamsByApp ? dataTeams.getTeamsByApp : []}
                /> */}
            </CardBody> 
          </Card>
        </GridItem>
      </GridContainer>
    );
}
NewChecklistAnalisysVisitReportView.prototype = {
    app: PropTypes.string.isRequired,
    visits: PropTypes.object.isRequired,
    submitSuccess: PropTypes.func.isRequired,
    currentVisit: PropTypes.object,
    users: PropTypes.object.isRequired,
    segments: PropTypes.object.isRequired,
    teams: PropTypes.object.isRequired,
}
const style = {
    cardTitle: {
      color: "#3C4858"
    },
  };
export default NewChecklistAnalisysVisitReportView;
