import React, { useState } from 'react';
import moment from 'moment';
import { getColumnsDates } from '../../utils/analytics/dataManipulation';
import TabulatorProcessRelationshipView from '../../Views/ProcessRelationship/TabulatorProcessRelationshipView';
import {useQuery} from "react-apollo-hooks";
import { useMutation } from "@apollo/react-hooks";
import {EMAIL_REPORTS, PROCESS_RELATIONSHIPS_CALCULATIONS} from "./gql/Queries";
import {periodicityRanges} from "../../utils/dates/dates";
import useGlobal from "../../store";
import { GENERATE_PROCESS_RELATIONSHIP_EMAIL_REPORT } from './gql/Mutations';

const TabulatorProcessRelationship = ({ location }) => {
    const { state } = location;

    const [session, globalActions] = useGlobal(
      (state) => state.session,
      (actions) => actions
    );

    const processRelationship = state?.item;
    const periodicity = processRelationship?.periodicity;

    const periodRanges = periodicityRanges(new Date(), null, periodicity, -5);
    const [filterDates, setFilterDates] = useState({
      created_gt : periodRanges[0][0],
      created_lte : periodRanges[periodRanges.length - 1][1],
    })

    const startDate = moment(filterDates.created_gt);
    const endDate = moment(filterDates.created_lte);

    const [pagination, setPagination] = useState({
      first: 15,
      skip: 0,
      where: {
        app: session.user ? session.appRef._id : "",
        processRelationshipId: processRelationship._id.toString(),
        action: "reports.emailProcessRelationship",
      },
    });

    const {
      data: resultsCalculations,
      loading: resultsLoading,
      error: resultsError
    } = useQuery(PROCESS_RELATIONSHIPS_CALCULATIONS, {
        variables: {
          id: processRelationship._id.toString(),
          created_gt: startDate,
          created_lte: endDate
        },
        fetchPolicy: 'network-only',
    });

    const {
      data: emailReports,
      loading: loadingEmailReports,
      error: errorEemailReports,
    } = useQuery(EMAIL_REPORTS, {
      variables: pagination,
      fetchPolicy: "network-only",
    });

    const [generateReportPDF] = useMutation(
      GENERATE_PROCESS_RELATIONSHIP_EMAIL_REPORT,
      {
        onCompleted: (mutatinoDatea) => {
          console.log("mutatinoDatea", mutatinoDatea);
          globalActions.notification.openNotification("tr", {
            message: "Procesando Reporte",
            color: "success",
          });
        },
      }
    );

    const paginationAction  = async (values) => {
      setPagination(values);
    }

    return (
        <TabulatorProcessRelationshipView
            results={{ data: resultsCalculations.getProcessRelationshipCalculations, loading: resultsLoading, resultsError }}
            setFilterDates={setFilterDates}
            filterDates={filterDates}
            processRelationship={processRelationship}
            emailReportsResult={{data: emailReports.getEmailProcessRelationshipReports, loading: loadingEmailReports, errorEemailReports}}
            paginationAction={paginationAction}
            filterReport={pagination}
            generateReport={(_id) => {

              generateReportPDF({
                variables: {
                  where: {
                    _id: _id,
                    userInfo: session.user
                  }
                }
              });
            }}
        />
    )
}

export default TabulatorProcessRelationship;
