import React from "react";
import useGlobalHook from "use-global-hook";
import moment from "moment";

import * as actions from "../actions";

const defaultStartDate = moment().subtract(1, 'month');;
const defaultEndDate = moment.now();

const sessionState = localStorage.getItem('session');

const initialState = {
  session: sessionState ? {...JSON.parse(sessionState), cached: true} : {
    user: null,
    token: '',
    expiration: '',
    creation: '',
    loading: false,
    cached: false
  },
  notifications: {
    'tr': {
      open: false
    }
  },
  filters: {
    selectedOperation: null,
    range: {
      startDate: defaultStartDate,
      endDate: defaultEndDate
    },
    selectedVisitID: null, 
  },
  reports: {
    reportsList: [],
    lastInsert: 0,
  }
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
