export const ticketReportChangedNotification = (t, globalActions, operation, node) => {
  let message = t('tickets.list.notifications.ticketOperation',
    {name: node.ticket.name, operation: t(`tickets.model.crud.${operation}`)});

  if (operation === 'Updated') {
    message += '\n' + t('tickets.list.notifications.changedTo',
      {status: t(`tickets.model.status.${node.status}`)});
  }
  const selectColor = (operation) => {
    switch (operation) {
      case 'Created':
        return 'success';
      case 'Updated':
        return 'info';
    }
    return 'info';
  };
  // Subscription notification
  globalActions.notification.openNotification('tr', {
    message: message,
    color: selectColor(operation)
  });
}

export const approvalItemChangedNotification = (t, globalActions, status, node) => {
  let message = t('approvalItems.notifications.approvalItemMessage',
    {
      status: t(`visits.enums.ApprovalItemStatus.${status}`, {ns: 'visits'}),
      email: node.userRef.email,
      ns: 'approvalItems'
    });

  if (node.approvalLogRef) {
    message += '\n' + t('approvalItems.notifications.approvalItemTypeMessage',
      {
        type: t(`visits.enums.ApprovalLogType.${node.approvalLogRef.type}`, {ns: 'visits'}),
        ns: 'approvalItems'
      });
  }

  const selectColor = (status, node) => {
    if (!!node.approvalLogRef) {
      switch (node.approvalLogRef.status) {
        case 'Approved':
          return 'success';
        case 'Rejected':
          return 'danger';
      }
    }
    return 'info';
  };
  // Subscription notification
  globalActions.notification.openNotification('tr', {
    message: message,
    color: selectColor(status, node)
  });
}

export const questionAnswerUpdateLogCreatedNotification = (t, globalActions, node) => {
  let message = `El campo ${node.key} ha sido modificado por el usuario ${node.userRef.email}`;

  // Subscription notification
  globalActions.notification.openNotification('tr', {
    message: message,
    color: 'success'
  });
}

export const reportCreationOnProgressNotification = (globalActions) => {
  let message = `Su reporte esta siendo generado, espere por favor.`;

  globalActions.notification.openNotification('tr', {
    message: message,
    color: 'info'
  });
}

export const reportCreationFailedNotification = (globalActions) => {
  let message = `La creación del reporte falló, intente más tarde.`;

  globalActions.notification.openNotification('tr', {
    message: message,
    color: 'error'
  });
}

export const reportCreationCompletedNotification = (t, globalActions, node) => {
  let message = `Reporte generado exitosamente`;

  // Subscription notification
  globalActions.notification.openNotification('tr', {
    message: message,
    color: 'success'
  });
}

export const reportSentNotification = (globalActions) => {
  let message = `Su email ha sido enviado exitosamente`;

  globalActions.notification.openNotification('tr', {
    message: message,
    color: 'success'
  });
}

export const reportSentFailedNotification = (globalActions) => {
  let message = `Su email no pudo ser enviado, por favor intente más tarde.`;

  globalActions.notification.openNotification('tr', {
    message: message,
    color: 'error'
  });
}

export const ticketReportCommentAddedNotification = (t, globalActions) => {
  let message = t('tickets.maintenance.notifications.ticketReportCommentAdded');

  globalActions.notification.openNotification('tr', {
    message: message,
    color: 'info'
  });
}
