import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import Moment from "moment";

// custom components
import Badge from "components/Badge/Badge";
import DivCenter from "components/styled-components/DivCenter";
import ConfirmOptionsIconButton from "components/ConfirmOptionsIconButton/ConfirmOptionsIconButton";
import Timeline from "components/Timeline/Timeline.jsx";
import WithConfirmation from "../../forms/WithConfirmation";
import LoadingCircleView from "../LoadingCircleView";

// icons
import AddIcon from '@material-ui/icons/Add';
import RateReview from '@material-ui/icons/RateReview';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

import {APPROVAL_FLOW_ITEM_STATUS, APPROVAL_LOG_STATUS} from "../../constants";
import {getColorByApprovalFlowItemStatus} from "../../utils/views/visits";
import {baseDateFormat, dateFrom} from "../../utils/dates/dates";
import i18n from "../../i18n";

const VisitAnswerApprovalItemTimelineView = ({classes, approvalConfig, approvalItems, processAuthorization, processingAuthorization, goToConfirmation}) => {
  const { t, } = useTranslation('visits');

  const {approvalFlow} = approvalConfig;

  const stories = approvalFlow.map((approvalFlowItem, index) => {
    let approvalFlowItemStatus = '';
    // Search for visit approval item
    const approvalItem = approvalItems.find((visitApprovalItem) => {
      return approvalFlowItem.order === visitApprovalItem.step;
    });
    if (approvalItem) {
      if (approvalItem.approvalLogRef) {
        approvalFlowItemStatus = approvalItem.approvalLogRef.status;
      }else{
        approvalFlowItemStatus = approvalItem.status;
      }
    }else{
      approvalFlowItemStatus = APPROVAL_FLOW_ITEM_STATUS.NotReached;
    }

    return   {
      badgeColor: getColorByApprovalFlowItemStatus(approvalFlowItemStatus),
      badgeIcon: AddIcon,
      title: t(`visits.enums.ApprovalFlowItemStatus.${approvalFlowItemStatus}`),
      titleColor: getColorByApprovalFlowItemStatus(approvalFlowItemStatus),
      body: (
        <React.Fragment>
          <h5>Autorizador: <small>{approvalFlowItem.email}</small></h5>
          {
            approvalFlowItemStatus !== APPROVAL_FLOW_ITEM_STATUS.Pending &&
            approvalFlowItemStatus !== APPROVAL_FLOW_ITEM_STATUS.NotReached ?
              (
                <React.Fragment>
                  <p>Tipo de Autorización</p>
                  <p>
                    <Badge color={getColorByApprovalFlowItemStatus(approvalFlowItemStatus)}>
                      {t(`visits.enums.ApprovalLogType.${approvalItem.approvalLogRef.type}`)}
                    </Badge>
                  </p>
                </React.Fragment>
              ) : []
          }
          {
            approvalFlowItemStatus !== APPROVAL_FLOW_ITEM_STATUS.NotReached ?
              (
                <React.Fragment>
                  <p>Inicio de Autorización</p>
                  <p style={{fontWeight: 'bold'}}>{baseDateFormat(approvalItem.created)}</p>
                </React.Fragment>
              ) : []
          }
          {
            approvalFlowItemStatus === APPROVAL_FLOW_ITEM_STATUS.Pending &&
            approvalConfig.hasExpiration ?
              (
                <React.Fragment>
                  <p>Tiempo de Expiración</p>
                  <p style={{fontWeight: 'bold'}}>{
                    baseDateFormat(
                      Moment(approvalItem.created).add(approvalConfig.expirationHours, 'h'))}
                  </p>
                </React.Fragment>
              ) : []
          }
          {
            approvalFlowItemStatus === APPROVAL_FLOW_ITEM_STATUS.Pending ?
              (
                <React.Fragment>
                  <DivCenter>
                    {
                      !processingAuthorization ?
                        <ConfirmOptionsIconButton t={t}
                                                  baseTooltip={'visits.visitAnswerApprovalItem.tooltips.buttonApprove'}
                                                  baseLabel={'visits.visitAnswerApprovalItem.tooltips.labelApprove'}
                                                  baseColor={'info'}
                                                  confirmDelay={5000}
                                                  baseIcon={RateReview}
                                                  confirmOptions={[
                                                    {
                                                      confirmTooltip: 'visits.visitAnswerApprovalItem.tooltips.buttonAccept',
                                                      confirmOperation: () => {
                                                        goToConfirmation({
                                                          title: i18n.t('common.dialogs.confirmTitle', {
                                                            ns: 'common'
                                                          }),
                                                          message: i18n.t('visits.visitAnswerApprovalItem.messages.confirmApprove', {
                                                            ns: 'visits'
                                                          }),
                                                          callback: () => {
                                                            processAuthorization(approvalItem, APPROVAL_LOG_STATUS.Approved)
                                                          }
                                                        })
                                                      },
                                                      confirmColor: 'success',
                                                      confirmIcon: CheckOutlinedIcon
                                                    },
                                                    {
                                                      confirmTooltip: 'visits.visitAnswerApprovalItem.tooltips.buttonReject',
                                                      confirmOperation: () => {
                                                        goToConfirmation({
                                                          title: i18n.t('common.dialogs.confirmTitle', {
                                                            ns: 'common'
                                                          }),
                                                          message: i18n.t('visits.visitAnswerApprovalItem.messages.confirmReject', {
                                                            ns: 'visits'
                                                          }),
                                                          callback: () => {
                                                            processAuthorization(approvalItem, APPROVAL_LOG_STATUS.Rejected)
                                                          }
                                                        })
                                                      },
                                                      confirmColor: 'danger',
                                                      confirmIcon: CancelIcon
                                                    }
                                                  ]} /> :
                        <LoadingCircleView />
                    }
                  </DivCenter>
                </React.Fragment>
              ) : []
          }

        </React.Fragment>
      ),
      footerTitle: approvalItem && approvalItem.approvalLogRef ?
        dateFrom(approvalItem.created, approvalItem.approvalLogRef.created) : ''
    };
  });

  return (
    <div className={classes.mainContainer}>
      <h3 className={classes.center}><strong>Flujo de Aprobación</strong></h3>
      <Timeline stories={stories} simple={true} counter={true} />
    </div>
  );
};

VisitAnswerApprovalItemTimelineView.propTypes = {
  classes: PropTypes.object.isRequired,
  approvalItems: PropTypes.array.isRequired,
  approvalConfig: PropTypes.object.isRequired,
  processAuthorization: PropTypes.func.isRequired
};

export default WithConfirmation(VisitAnswerApprovalItemTimelineView);
