import React, {useState} from 'react';
import GeneralMessagesView from '../../Views/Messages/GeneralMessagesView';
import {useQuery } from "react-apollo-hooks";
import { MESSAGES_FEED } from '../Dashboard/gql/Query';


const GeneralMessages = () => {

    const [messagesFilter, setMessagesFilter] = useState({
        where: {

        },
        first: 15,
        skip: 0,
      });

      const paginationAction  = async (values) => {
        setMessagesFilter(values);
      }
    const { data: dataMessage, loading: loadingMessage } = useQuery(
      MESSAGES_FEED,
      {
        variables: messagesFilter,
        fetchPolicy: "network-only"
      }
    );


    return (
      <GeneralMessagesView
        messages={{ loading: loadingMessage, data: dataMessage }}
        paginationAction={paginationAction}
        messagesFilter={messagesFilter}
      />
    );
}

export default GeneralMessages;