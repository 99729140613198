import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

function DetailsTable({classes, data}){
  const rows = data.map(item => (
    <tr>
      <td className={classes.td}>{item.label}</td>
      <td className={classes.td}>{item.count}</td>
      <td className={classes.td} style={{backgroundColor: item.color}}></td>
    </tr>
  ));
  return(
    <div className={classes.container}>
      <table className={classes.table}>
        {rows}
      </table>
    </div>
  );
}

const styles = theme => ({
  container:{
    display: "flex",
    justifyContent: "center"
  },
  table: {
    borderCollapse: "collapse",
    margin: "10px",
    tableLayout: "fixed",
  },
  td: {
    border: "1px solid black",
    padding: "5px",
    width: "150px",
  }
});

export default withStyles(styles)(DetailsTable);