export const buildKeyfieldDashboardSubject = ({keyfield, visit, startDate, endDate}) => {
  return `Análisis General de ${keyfield} para ${visit} en ${startDate} - ${endDate}`;
}

export const buildKeyfieldDashboardBody = ({visit, keyfield}) => {
  return `
    <p>Hola,</p>
    <br>
    <p>Envío adjunto un PDF con la información general de ${visit} para ${keyfield} en donde encontrarás:</p>
    <br>
    <ul>
        <li>Conteo de visitas y promedio por ${keyfield}</li>
        <li>Ranking global de ${keyfield} en el rango de fechas</li>
        <li>Análisis tabulado por categorías</li>
    </ul>
  `;
}
