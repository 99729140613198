import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import RadioButton from '../../../components/styled/RadioButton';
import SendEmailButton from "components/Reports/SendEmailButton";
import DownloadButton from "components/Reports/DownloadButton";
import AverageAnalysisTable from '../../../tables/Visits/AverageAnalysisTable';
import { calculateWithConfig, selectionEnum } from '../../../utils/analytics/averageAnalysis';

function AverageAnalysisView({
  classes, 
  defaultBody,
  defaultSubject,
  items, 
  keyfield, 
  handlePDFReportClick, 
  handleXLSReportClick, 
  handlePDFReportEmailClick,
  handleXLSReportEmailClick,
  averageAnalysisData
}) {
  const [config, setConfig] = React.useState(selectionEnum.all);
  const [selectedItems, setSelectedItems] = React.useState([]);
  
  const handleChange = selectedOption => {
    setSelectedItems(selectedOption ? selectedOption : []); 
  };

  const suggestions = items.map(item => {
    return ({value: item.keyfield.value, label: item.keyfield.value});
  });

  const processData = calculateWithConfig(items, config, selectedItems);

  React.useEffect(() => {
    averageAnalysisData.current = {
      quantityKeyfields: config === selectionEnum.all ? items.length : selectedItems.length,
      items: processData.map(
        item => ({name: item.name, analysis: item.analysis})
      )
    };
  });

  return (
    <Card>
      <CardHeader color="info" text>
        <GridContainer>
          <GridItem xs={10}>
            <CardText color="info">
              <h4 className={classes.cardTitle}>Analisis Promedio</h4>
            </CardText>
          </GridItem>
          <GridItem xs={1}>
            <CardText color="info" className={classes.noPadding}>
              <SendEmailButton
                color="white"
                defaultBody={defaultBody}
                defaultSubject={defaultSubject}
                isReady={true}
                hasXLS={true}
                sendPDFReport={handlePDFReportEmailClick}
                sendXLSReport={handleXLSReportEmailClick}
                size="lg"
              />
            </CardText>
          </GridItem>
          <GridItem xs={1}>
            <CardText color="info" className={classes.noPadding}>
              <DownloadButton
                color="white"
                isReady={true}
                size="lg"
                hasXLS={true}
                onClickPDF={handlePDFReportClick}
                onClickXLS={handleXLSReportClick}
              />
            </CardText>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        <div>
          <RadioButton
            checked={config === selectionEnum.all}
            onChange={() => {setConfig(selectionEnum.all)}}
            value={selectionEnum.all}
            label="Incluir Todas"
          />
          <RadioButton
            checked={config === selectionEnum.selectedItems}
            onChange={() => {setConfig(selectionEnum.selectedItems)}}
            value={selectionEnum.selectedItems}
            label={"Agregar " + keyfield.name}
          />
        </div>
        {config === selectionEnum.selectedItems && 
          <div>
            <Select
              placeholder={"Buscar " + keyfield.name}
              value={selectedItems}
              onChange={handleChange}
              options={suggestions}
              isMulti
            />
          </div>
        }
        <div>
          <AverageAnalysisTable
            data={processData}
          />
        </div>
      </CardBody>
    </Card>
  );
};

AverageAnalysisView.propTypes = {
  defaultBody: PropTypes.object,
  defaultSubject: PropTypes.object,
  items: PropTypes.array.isRequired,
  keyfield: PropTypes.object.isRequired,
  handlePDFReportClick: PropTypes.func.isRequired,
  handleXLSReportClick: PropTypes.func.isRequired,
  handlePDFReportEmailClick: PropTypes.func.isRequired,
  handleXLSReportEmailClick: PropTypes.func.isRequired,
};

const styles = theme => ({
  noPadding: {
    padding: 0
  },
});

export default withStyles(styles)(AverageAnalysisView);
