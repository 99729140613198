import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

// custom components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from "components/Card/CardFooter.jsx";
import Position from 'components/Position';
import DivCenter from "components/styled-components/DivCenter";

const renderTable = (config, rows) => {
  return (
    <ReactTable
      data={rows}
      columns={config.columns.map((column, idx) => {
        return {
          id: idx,
          Header: <DivCenter>{column.label}</DivCenter>,
          filterable: false,
          sortable: false,
          Cell: (d) => {
            const answer = d.original.answers.find(answerFind => answerFind.key === column.key);
            return answer ? <DivCenter>{answer.answer[answer.answer.__typename]}</DivCenter> : '';
          },
          minWidth: column.width,
        }
      })}
      defaultPageSize={rows.length}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
      style={{
        height: "170px" 
      }}
    />
  );
};

const TableFieldAnswerView = ({answerData}) => {
  const {config, rows} = answerData.answer;

  return (
    <Card >
      <CardBody>
        {!!rows && !! config ?
          renderTable(config, rows) :
          <DivCenter><h5>Informacion de configuracion o datos faltantes</h5></DivCenter>}
      </CardBody>
      <CardFooter>
        <Position left>
          <small>{answerData.questionLabel}</small>
        </Position>
        <Position right>
          <small>{answerData.key}</small>
        </Position>
      </CardFooter>
    </Card>
  );
};

TableFieldAnswerView.propTypes = {

};

export default TableFieldAnswerView;
