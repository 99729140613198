import React, { useState } from 'react';
import {useQuery} from "react-apollo-hooks";
import VisitSchedulingTabulatorView from "../../Views/VisitScheduling/VisitSchedulingTabulatorView";
import {VISIT_SCHEDULING_REPORT} from "./gql/Queries";

const VisitSchedulingTabulator = ({ location }) => {
  const { state } = location;

  const visitScheduling = state?.item;

  const {
    data: results,
    loading: resultsLoading,
    error: resultsError,
  } = useQuery(VISIT_SCHEDULING_REPORT, {
    variables: {
      id: visitScheduling._id.toString()
    }
  });

  return (
    <VisitSchedulingTabulatorView
      results={{data: results, resultsLoading, resultsError}}
      visitScheduling={visitScheduling}
    />
  );
};

export default VisitSchedulingTabulator;
