import React from 'react';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import { CircularProgress } from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import Button from "components/CustomButtons/Button.jsx";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import history from '../../utils/history';
import TablePagination from '../../pagination/table/TablePagination';
import VisitAnswersTable from '../../tables/Reports/VisitAnswersTable'
import ReportFilterForm from '../../forms/reports/ReportFilterForm';
import Badge from 'components/Badge/Badge';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";

const VisitAnswersReportView = ({ visit, visitAnswers, submitSuccess, paginationAction, visitAnswersData, filterChanged, total }) => {
    const { t } = useTranslation("checklistAnalisys");
    const { loading, data } = visitAnswersData;
    return (
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <AssignmentTurnedInIcon />
          </CardIcon>
          <div style={style.left}>
            <h4 style={style.cardTitle}>
              {t("checklistAnalisys.visitanswers")} - {visit.visitRef.name}
            </h4>
          </div>
          <div style={style.right}>
            <Button size="sm" color="info" onClick={() => submitSuccess()}>
              {t("checklistAnalisys.customReport")}
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <ReportFilterForm
                initialValues={visitAnswers.where}
                submitSuccess={filterChanged}
              />
            </GridItem>
            <GridItem xs={12}>
              <div style={style.right}>
                <Badge color={"success"}>Total: {total}</Badge>
              </div>
            </GridItem>
            <GridItem xs={12}>
              {loading ? (
                <div style={style.progressCnt}>
                  <CircularProgress style={style.progress} />
                </div>
              ) : (
                <VisitAnswersTable data={data ? data : []} visit={visit} />
              )}
              <TablePagination
                total={total}
                data={data ? data : []}
                paginationAction={paginationAction}
                filter={visitAnswers}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
}

const style = {
    cardTitle: {
      color: "#3C4858"
    },
    progress: {
      margin: '16px'
    },
    progressCnt:{
      width: '100px !important',
      marginLeft: '50%',
      marginRight: '50%'
    },
    cardHeight: {
      height: "430px"
    },
    left: {
      float: "left"
    },
    right: {
      float: "right"
    }
  };

export default VisitAnswersReportView
