import React from "react";
import Moment from 'moment';
import {useQuery, useMutation} from "react-apollo-hooks";

import { 
  CREATE_GENERAL_EXECUTION_REPORT_MUTATION,
  CREATE_ACTIVE_USER_REPORT_MUTATION,
  CREATE_VISITING_TIME_REPORT_MUTATION,
  CREATE_GENERAL_EXECUTION_XLS_REPORT_MUTATION,
  CREATE_ACTIVE_USER_XLS_REPORT_MUTATION
} from "./gql/Mutations";

// Own components
import LoadingCircleView from "../../Views/LoadingCircleView";
import AdminDataItemView from "../../Views/Visits/AdminDataItemView";

import useGlobal from "../../store";
import { VISIT_ITEMS, VISIT_ANSWER_ITEMS, SEGMENT_ITEMS, VISIT_ANSWER_COUNT } from "./gql/Queries";
import { 
  getOperationsFromColumns, 
  getKeyfields, 
  parseVisitAnswers, 
  classifyAndFilter
} from "../../utils/analytics/dataManipulation";

import { 
  reportCreationFailedNotification, 
  reportCreationOnProgressNotification,
  reportSentNotification,
  reportSentFailedNotification,
} from "../../utils/snackNotifications";
import LoadingProcessView from "../../Views/LoadingProcessView";

function AdminDataItem({match}) {
  const [ session, globalActions] = useGlobal(
    state => state.session,
    actions => actions
  );
  const [ filters, filtersActions ] = useGlobal(
    state => state.filters,
    actions => actions.filters
  );
  const visitID = match.params.idVisit === ":idVisit" ? null : match.params.idVisit;
  
  const {data,loading} = useQuery(VISIT_ITEMS, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : '',
        hasChecklistConfiguration: true,
        isArchived: false
      }
    },
    fetchPolicy: 'network-only'
  });

  const {
    data: dataVisitAnswers, 
    loading: loadingVisitAnswers
  } = useQuery(VISIT_ANSWER_ITEMS, {
      variables: {
        where: {
          userId: session.user._id,
          app: session.appId,
          visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
          checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
          checkInDate_lte: Moment(filters.range.endDate).endOf('day').format(),
          hasCheckOutDate: true
        }
      },
      skip: !filters.selectedVisitID,
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    });

    const {
      data: dataVisitAnswersOpen, 
      loading: loadingVisitAnswersOpen
    } = useQuery(VISIT_ANSWER_COUNT, {
        variables: {
          where: {
            userId: session.user._id,
            app: session.appId,
            visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
            checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
            checkInDate_lte: Moment(filters.range.endDate).endOf('day').format(),
            approvalStatus_not_in: ["Rejected", "Excluded"],
            hasCheckOutDate: false
          }
        },
        skip: !filters.selectedVisitID,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      });

  const {
      data: dataVisitAnswersClosed, 
      loading: loadingVisitAnswersClosed
    } = useQuery(VISIT_ANSWER_COUNT, {
        variables: {
          where: {
            userId: session.user._id,
            app: session.appId,
            visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
            checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
            checkInDate_lte: Moment(filters.range.endDate).endOf('day').format(),
            approvalStatus_not_in: ["Rejected", "Excluded"],
            hasCheckOutDate: true
          }
        },
        skip: !filters.selectedVisitID,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      });

  const filteredDataVisitAnswers = dataVisitAnswers && dataVisitAnswers.visitAnswers ? 
    dataVisitAnswers.visitAnswers.filter(visitAnswer => visitAnswer) : [];

  const {
    data: dataSegments, 
  } = useQuery(SEGMENT_ITEMS, {
      variables: {
        where: {
          app: session.user ? session.appRef._id : '',
          archived: false
        }
      },
      fetchPolicy: 'network-only'
    });

    // Add General Data required to all the mutation calls
    const addGeneralData = (payload, emailData) => {
      let data = { query: { 
        visit: filters.selectedVisitID, 
        operation: filters.selectedOperation, 
        ...payload
      }};
      data.visitId = filters.selectedVisitID?  filters.selectedVisitID.value : '';
      data.dateRange = {
        startDate: filters.range.startDate.format("DD-MM-YYYY"),
        endData: Moment(filters.range.endDate).format("DD-MM-YYYY")
      };
      data.app = session.user ? session.appRef._id : '';
      data.session = session;
  
      if(!!emailData){
        data.sendByEmail = true;
        data.emailInput = {
          contentHtml: emailData.contentHtml,
          subject: emailData.subject,
          to: emailData.to
        }
      }

      return data;
    };
  
    // Execute the mutation and trigger the notifications
    const executeMutation = (mutation, data, isEmail = false) => {
      mutation({ variables: { data: data }}).then(
        result => {
          if(isEmail) reportSentNotification(globalActions);
          else reportCreationOnProgressNotification(globalActions);
        }, 
        error => {
          console.log(error, data)
          if(isEmail) reportSentFailedNotification(globalActions);
          else reportCreationFailedNotification(globalActions);
        });
    };
  
  // Initilize the mutations
  const createGeneralExecutionReportMutation = useMutation(CREATE_GENERAL_EXECUTION_REPORT_MUTATION);
  const createActiveUserReportMutation = useMutation(CREATE_ACTIVE_USER_REPORT_MUTATION);
  const createVisitingTimeReportMutation = useMutation(CREATE_VISITING_TIME_REPORT_MUTATION);
  const createGeneralExecutionXLSReportMutation = useMutation(CREATE_GENERAL_EXECUTION_XLS_REPORT_MUTATION);
  const createActiveUserXLSReportMutation = useMutation(CREATE_ACTIVE_USER_XLS_REPORT_MUTATION);

  const createGeneralExecutionReport = React.useCallback((payload) => {
    const data = addGeneralData(payload);
    executeMutation(createGeneralExecutionReportMutation, data); 
  }, [ createGeneralExecutionReportMutation, filters, session, Moment ]);

  const createGeneralExecutionXLSReport = React.useCallback((payload) => {
    const data = addGeneralData(payload);
    executeMutation(createGeneralExecutionXLSReportMutation, data); 
  }, [ createGeneralExecutionXLSReportMutation, filters, session, Moment ]);

  const createActiveUSerReport = React.useCallback((payload) => {
    const data = addGeneralData(payload);
    executeMutation(createActiveUserReportMutation, data); 
  }, [ createActiveUserReportMutation, filters, session, Moment ]);

  const createActiveUserXLSReport = React.useCallback((payload) => {
    const data = addGeneralData(payload);
    executeMutation(createActiveUserXLSReportMutation, data); 
  }, [ createActiveUserXLSReportMutation, filters, session, Moment ]);

  const createVisitingTimeReport = React.useCallback((payload) => {
    const data = addGeneralData(payload);
    executeMutation(createVisitingTimeReportMutation, data); 
  }, [ createVisitingTimeReportMutation, filters, session, Moment ]);

  const sendGeneralExecutionPDFReport = React.useCallback((payload, emailData) => {
    const data = addGeneralData(payload, emailData);
    executeMutation(createGeneralExecutionReportMutation, data, true); 
  }, [ createGeneralExecutionReportMutation, filters, session, Moment ]);

  const sendActiveUserPDFReport = React.useCallback((payload, emailData) => {
    const data = addGeneralData(payload, emailData);
    executeMutation(createActiveUserReportMutation, data, true); 
  }, [ createActiveUserReportMutation, filters, session, Moment ]);

  const sendVisitingTimePDFReport = React.useCallback((payload, emailData) => {
    const data = addGeneralData(payload, emailData);
    executeMutation(createVisitingTimeReportMutation, data, true); 
  }, [ createVisitingTimeReportMutation, filters, session, Moment ]);

  const sendActiveUserXLSReport = React.useCallback((payload, emailData) => {
    const data = addGeneralData(payload, emailData);
    executeMutation(createActiveUserXLSReportMutation, data, true); 
  }, [ createActiveUserXLSReportMutation, filters, session, Moment ]);

  const sendGeneralExecutionXLSReport = React.useCallback((payload, emailData) => {
    const data = addGeneralData(payload, emailData);
    executeMutation(createGeneralExecutionXLSReportMutation, data, true); 
  }, [ createGeneralExecutionXLSReportMutation, filters, session, Moment ]);

  // If the store is empty or different, use the visitID from the route params
  if(data.visits && (
      (!!visitID && !filters.selectedVisitID) || 
      (!!visitID && visitID !== filters.selectedVisitID.value))){
    const option = data.visits.find((visit) => visitID === visit._id);
    filtersActions.setVisitID({label: option.name, value: option._id});
  }

  // Select by default the first visit ID
  if(!visitID && !filters.selectedVisitID && data.visits && data.visits.length > 0) {
    const option = data.visits[0];
    filtersActions.setVisitID({label: option.name, value: option._id});
  }

  let closedCount = null;
  if (!loadingVisitAnswersClosed && dataVisitAnswersClosed) {
    const { visitAnswerConnection } = dataVisitAnswersClosed;
    if (visitAnswerConnection) {
      const { aggregate } = visitAnswerConnection;
      closedCount = aggregate.count;
    }
  }

  if (loading || loadingVisitAnswers || loadingVisitAnswersClosed || loadingVisitAnswersOpen) {
    return <LoadingProcessView closedCount={closedCount}/>
  } else {
    const selecteVisit = data.visits ? 
      data.visits.find((visit) => 
        filters.selectedVisitID && 
        filters.selectedVisitID.value === visit._id) 
      : null;

    const operators = 
      selecteVisit && selecteVisit.checkListConfiguration 
      && selecteVisit.checkListConfiguration.columns ? 
        getOperationsFromColumns(selecteVisit.checkListConfiguration.columns) 
        : [];

    const selectedOperation = operators.find(
      operator => 
        filters.selectedOperation && 
        filters.selectedOperation.value === operator.id);

    // Select by default the first option
    if(!selectedOperation && operators && operators.length > 0) {
      const option = operators[0];
      filtersActions.setOperation({label: option.name, value: option.id});
    }

    const visits = data.visits ? data.visits.map(({_id, name}) => ({id: _id, name})) : []; 
    const keyfields = getKeyfields(selecteVisit);
    const [activeCount, closedVisits] = classifyAndFilter(filteredDataVisitAnswers);
    const parsedClosed = closedVisits ? 
      parseVisitAnswers(closedVisits, keyfields, true) : [];
    const segments = dataSegments.segments? dataSegments.segments : [];
    
    const emailData = {
      visit: filters.selectedVisitID?  filters.selectedVisitID.label : '', 
      startDate: filters.range.startDate.format("DD-MM-YYYY"),
      endDate: Moment(filters.range.endDate).format("DD-MM-YYYY")
    };
    
    return (
      <AdminDataItemView
        activeVisitAnswer={{ loadingActiveVisitanswers: loadingVisitAnswersOpen, dataOpenVisitanswer: dataVisitAnswersOpen }}
        closedVisitAnswer={{ loadingVisitAnswersClosedCount: loadingVisitAnswersClosed, dataVisitanswerClosedCount: dataVisitAnswersClosed }}
        keyfields={keyfields}
        segments={segments}
        selectedVisit={selecteVisit}
        visits={visits}
        visitAnswers={{ loadingVisitAnswersClosed: loadingVisitAnswers, dataVisitAnswer: parsedClosed }}
        operators={operators}
        createGeneralExecutionReport={createGeneralExecutionReport}
        createActiveUSerReport={createActiveUSerReport}
        createVisitingTimeReport={createVisitingTimeReport}
        sendGeneralExecutionPDFReport={sendGeneralExecutionPDFReport}
        sendActiveUserPDFReport={sendActiveUserPDFReport}
        sendVisitingTimePDFReport={sendVisitingTimePDFReport}
        sendActiveUserXLSReport={sendActiveUserXLSReport}
        sendGeneralExecutionXLSReport={sendGeneralExecutionXLSReport}
        createGeneralExecutionXLSReport={createGeneralExecutionXLSReport}
        createActiveUserXLSReport={createActiveUserXLSReport}
        emailData={emailData}
      />
    );  
  }
}

export default AdminDataItem;
