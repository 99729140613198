import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";

import withStyles from "@material-ui/core/styles/withStyles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import ElementSelected from "./ElementSelected";
import Position from "components/Position";
import {includes} from "ramda";

import {onDebounce$} from '../../utils/subject-rxjs';

const debounceHalfSecond$ = onDebounce$(500);

const ModalElements = ({ ...props }) => {
  const {
    classes,
    title,
    open,
    getSelectedElements,
    handleModalClose,
    query,
    multi,
    variables,
    okCallback
  } = props;

  const [selected, setSelected] = useState([]);
  const [textFilter, setTextFilter] = useState(null);

  const {
    data, loading, error
  } = useQuery(query, {
    variables,
    fetchPolicy: "network-only"
  })

  const currentData = !loading && data ?  data[props.dataElement] : [];

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }

  const handleSave = () => {
    const dataSelected = dataElementsFiltered.filter((element, index) => {
      return includes(index, selected);
    });
    getSelectedElements(dataSelected);
    if (okCallback) {
      okCallback(dataSelected);
    }
  };

  const renderElementSelected = (element, index) => {
    return (
      <div
        color="primary"
        onClick={() => {
          if (multi) {
            setSelected([...selected, index])
          }else{
            setSelected([index])
          }
        }}
      >
        <ElementSelected value={props.textValue(element)} isSelected={includes(index, selected)}/>
      </div>
    );
  };

  useEffect(() => {
    if (open) {
      setSelected([])
      setTextFilter(null)
    }
  }, [open]);

  useEffect(() => {
    const debounceSubscription =
      debounceHalfSecond$.subscribe(debouncedValue => {
        setSelected([])
        setTextFilter(debouncedValue)
      });
    return () => {
      debounceSubscription.unsubscribe();
    }
  }, [])

  const filterDataElements = (textFilter, currentData) => {
    return currentData.filter((element) => {
      if (textFilter) {
        return props.textValue(element)
          .toLowerCase()
          .includes(textFilter.toLowerCase());
      }
      return true;
    })
  }

  const dataElementsFiltered = useMemo(() =>
    filterDataElements(textFilter, currentData),
    [textFilter, currentData])

  return (
    <Modal open={open} onClose={handleModalClose}>
      <div style={getModalStyle()} className={classes.layout}>
        <Grid container >
          <Grid item xs={6}>
            <h2>{title}</h2>
          </Grid>
          <Grid item xs={6}>
            <Position right>
              <TextField
                id="standard-search"
                label={props.filterLabel || "Search field"}
                type="search"
                className={classes.textField}
                margin="normal"
                onChange={(event) => {
                  if (event && event.target) {
                    debounceHalfSecond$.next(event.target.value)
                  }
                }}
              />
            </Position>
          </Grid>
        </Grid>
        <div style={{ maxHeight: 300, overflow: "auto" }}>
          <Grid container>
            {
              loading ? <p>loading...</p> : []
            }
            {
              error ? <p>{error}</p> : []
            }
            {
              !loading && dataElementsFiltered ?
                dataElementsFiltered.map((element, index) => {
                  return (
                    <Grid item xs={3} key={index}>
                      {renderElementSelected(element, index)}
                    </Grid>
                  );
                }) : []
            }
          </Grid>
        </div>
        <Position right>
          <Button onClick={handleSave} disabled={selected.length === 0}>Save</Button>
          <Button onClick={handleModalClose}>Cancel</Button>
        </Position>
      </div>
    </Modal>
  );
}

const styles = theme => ({
  layout: {
    position: "absolute",
    width: "80%",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  },
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  }
});

ModalElements.propTypes = {
  classes: PropTypes.object.isRequired,
  filterLabel: PropTypes.string,
  toggleModal: PropTypes.bool,
  okCallback: PropTypes.func
};

ModalElements.defaultProps = {};

export default withStyles(styles)(ModalElements);
