export const buildCombinedScoresSubject = ({visit, operation, startDate, endDate, category}) => {
  return `Indicadores Combinados ${visit} ${operation} ${startDate} - ${endDate} para ${category}`;
}

export const buildCombinedScoresBody = ({keyfields, category}) => {
  return `
    <p>Hola,</p>
    <br>
    <p>Envío adjunta la tabla de indicadores combinados para los campo clave: ${keyfields} en la categoria: ${category}. En la tabla se muestran el número de visitas realizadas en cada combinación de campos clave y el promedio del resultado obtenido sobre ${category}. También se muestra el promedio global, el máximo, el mínimo y el total de visitas.</p>
  `;
}
