import React from 'react';
import PropTypes from 'prop-types';
import Moment from "moment";
import {useTranslation} from "react-i18next";

// own components
import CommentForm from "../../forms/CommentForm";

// custom components
import Timeline from "components/Timeline/Timeline.jsx";
import ConfirmIconButton from "components/ConfirmIconButton/ConfirmIconButton";

// core components
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import Comment from '@material-ui/icons/Comment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {byCreated} from "../../utils/array/sort";

const TicketReportCommentView = ({classes, ticketReport, createTicketReportComment, deleteTicketReportComment}) => {

  const { t, } = useTranslation('tickets');

  const stories = ticketReport.comments.sort(byCreated).map((comment, index) => {
    return   {
      // First story
      inverted: index % 2,
      badgeColor: 'info',
      badgeIcon: Comment,
      title: comment.userRef.email,
      titleColor: 'info',
      body: (
        <h5>
          <small>{comment.text}</small>
          <ConfirmIconButton t={t}
                             simple={true}
                             baseTooltip={'tickets.maintenance.tooltips.buttonDiscard'} confirmTooltip={'tickets.maintenance.tooltips.buttonDiscard'}
                             baseIcon={DeleteForeverIcon}
                             confirmDelay={3000}
                             confirmOperation={() => {
                               deleteTicketReportComment({
                                 _id: ticketReport._id,
                                 comment: comment._id
                               });
                             }}/>
        </h5>

      ),
      footerTitle: Moment(comment.created).local().fromNow()
    };
  });

  if (ticketReport) {
    return (
      <div className={classes.mainContainer}>
        <h4 className={classes.center}>Comentarios</h4>
        <CommentForm
          submitSuccess={createTicketReportComment}
          data={{ id: ticketReport._id }}
        />
        <Timeline stories={stories} />
      </div>
    );
  }else{
    return [];
  }
};

TicketReportCommentView.propTypes = {
  ticketReport: PropTypes.object.isRequired,
  createTicketReportComment: PropTypes.func.isRequired,
  deleteTicketReportComment: PropTypes.func.isRequired,
};

const styles = theme => ({
  mainContainer: {
    marginTop: 50,
    padding: 15
  },
  center: {
    textAlign: "center"
  }
});


export default withStyles(styles)(TicketReportCommentView);
