import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, withFormik } from "formik";
import * as yup from "yup";
import Moment from "moment";
import PropTypes from "prop-types";

// core components
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  TextField,
  RadioGroup,
  Radio,
} from "@material-ui/core";

// icons
import SaveAlt from "@material-ui/icons/SaveAlt";

// custom components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Select from "react-select";
import Datetime from "react-datetime";

// styled components
import ButtonIcon from "../../components/styled/ButtonIcon";
import CardTitle from "../../components/styled/CardTitle";

//Utils
import { getErrorLabel, hasError } from "../../utils/forms/inputs";

// local state
import useGlobal from "../../store";
import {
  SO_CHECKLIST_REPORT_PERIODICITY,
  SO_COUNTER_REPORT_PERIODICITY,
} from "../../constants";
import { makeStyles } from "@material-ui/core/styles";
import {
  formatSelectArrayOptions,
  formatSelectOptions,
} from "../../utils/formatter";

/*
 * Validation Schema for CreateUpdateCounterReports
 * */
const createUpdateChecklistReportsValidationSchema = yup.object().shape({
  name: yup.string().required("isRequired"),
  periodicity: yup.string().required("isRequired"),
  initialDate: yup.date().required().max(yup.ref("finalDate")),
  finalDate: yup.date().required().min(yup.ref("initialDate")),
  visitId: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    )
    .test("visit-id-selected", "Visit required", function (value) {
      const { parent } = this;
      const { isVisit } = parent;
      if (isVisit === "Visit") {
        return value && value.length > 0;
      }
      return true;
    }),
  keyField: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    )
    .test("keyfield-selected", "KeyField required", function (value) {
      const { parent } = this;
      const { isVisit } = parent;
      if (isVisit === "Visit") {
        return value && value.length > 0;
      }
      return true;
    }),
  processRelationshipId: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    )
    .test("object-id-selected", "Object required", function (value) {
      const { parent } = this;
      const { isVisit } = parent;
      if (isVisit === "ProcessRelationship") {
        return value && value.length > 0;
      }
      return true;
    }),
  targetValue: yup.number().required().min(0).max(100),
});

const CreateUpdateChecklistReports = (props) => {
  // Main hooks
  const { t } = useTranslation("so");
  const [, globalActions] = useGlobal();
  const classes = useStyles();

  // Formik bag
  const {
    errors,
    touched,
    setFieldValue,
    values,
    submitForm,
    submitCount,
    isSubmitting,
    isValid,
  } = props;
  // other props
  const { visits, processRelationships } = props;
  // Submit validation failed effect
  console.log({ errors });
  useEffect(() => {
    if (values.visitId && values.visitId.length > 0) {
      setCurrentVisit(
        visits.find((visit) => visit._id === values.visitId[0].value)
      );
    }
  }, []);
  useEffect(() => {
    if (submitCount > 0 && !isSubmitting && !isValid) {
      const fields = Object.keys(errors);

      if (fields.length) {
        globalActions.notification.openNotification("tr", {
          message: t(
            "so.checklistReports.forms.createUpdate.validation.failed"
          ),
          color: "danger",
        });
      }
    }
  }, [submitCount, isSubmitting]);

  // custom state
  const [currentVisit, setCurrentVisit] = useState(null);
  const formatPeriodicityOptions = (options) => {
    return options.map((option) => {
      return {
        label: t(
          `so.checklistReports.forms.createUpdate.fields.periodicity.options.${option}`
        ),
        value: option,
      };
    });
  };
  const getSelectedPeriodicity = (periodicity) => {
    return {
      label: t(
        `so.checklistReports.forms.createUpdate.fields.periodicity.options.${periodicity}`
      ),
      value: periodicity,
    };
  };

  return (
    <Form>
      <GridContainer justify="center">
        <GridItem xs={12} md={8} lg={6}>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <Card>
                <CardHeader color="info" text>
                  <GridContainer>
                    <GridItem xs={8}>
                      <CardText color="info">
                        <CardTitle>
                          {t(
                            "so.checklistReports.forms.createUpdate.mainConfig"
                          )}
                        </CardTitle>
                      </CardText>
                    </GridItem>
                    <GridItem xs={4}>
                      <ButtonIcon
                        icon={SaveAlt}
                        label={
                          "so.checklistReports.forms.createUpdate.buttons.save"
                        }
                        color={"success"}
                        block={true}
                        onClick={() => {
                          submitForm();
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12}>
                      <Field
                        name="name"
                        render={({ field }) => {
                          return (
                            <TextField
                              {...field}
                              id="name"
                              helperText={
                                hasError(errors, "name")
                                  ? getErrorLabel(
                                      t,
                                      "so.checklistReports.forms.createUpdate.fields",
                                      "name",
                                      errors
                                    )
                                  : t(
                                      "so.checklistReports.forms.createUpdate.fields.name.helperText"
                                    )
                              }
                              label={t(
                                "so.checklistReports.forms.createUpdate.fields.name.label"
                              )}
                              success={
                                !hasError(errors, "name") && touched.name
                              }
                              error={hasError(errors, "name")}
                              margin="normal"
                              type={"text"}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <FormControl className={classes.formControl}>
                        <FormLabel>
                          {t(
                            "so.checklistReports.forms.createUpdate.fields.periodicity.label"
                          )}
                        </FormLabel>
                        <Select
                          inputId={"select-periodicity"}
                          placeholder={t(
                            "so.checklistReports.forms.createUpdate.fields.periodicity.helperText"
                          )}
                          value={getSelectedPeriodicity(
                            values.periodicity.value
                          )}
                          onChange={(item) => {
                            setFieldValue("periodicity", item);
                          }}
                          options={formatPeriodicityOptions(
                            Object.keys(SO_COUNTER_REPORT_PERIODICITY)
                          )}
                          styles={{
                            menu: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <Datetime
                        name={"initialDate"}
                        timeFormat={false}
                        dateFormat={"DD-MM-YYYY"}
                        defaultValue={Moment(values.initialDate).format(
                          "DD-MM-YYYY"
                        )}
                        onChange={(e) => {
                          if (e) {
                            console.log(e);
                            setFieldValue("initialDate", e);
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <Datetime
                        name={"finalDate"}
                        timeFormat={false}
                        dateFormat={"DD-MM-YYYY"}
                        defaultValue={Moment(values.finalDate).format(
                          "DD-MM-YYYY"
                        )}
                        onChange={(e) => {
                          if (e) {
                            console.log(e);
                            setFieldValue("finalDate", e);
                          }
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Card>
                <CardBody>
                  <GridItem xs={12} sm={12}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">
                        {t(
                          "so.checklistReports.forms.createUpdate.fields.isVisit.label"
                        )}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="elementType"
                        name="elementType"
                        value={values.isVisit}
                        onChange={(e) => {
                          setFieldValue("visitId", []);
                          setFieldValue("keyField", []);
                          setFieldValue("processRelationshipId", []);
                          setFieldValue("isVisit", e.target.value);
                        }}
                      >
                        <FormControlLabel
                          id={"is-visit"}
                          className={classes.formLabel}
                          value="Visit"
                          control={
                            <Radio
                              color="default"
                              classes={{
                                root: classes.radio,
                                checked: classes.checked,
                              }}
                            />
                          }
                          label={t(
                            "so.checklistReports.forms.createUpdate.fields.isVisit.options.visit"
                          )}
                        />
                        <FormControlLabel
                          id={"is-process-relationship"}
                          className={classes.formLabel}
                          value="ProcessRelationship"
                          control={
                            <Radio
                              color="default"
                              classes={{
                                root: classes.radio,
                                checked: classes.checked,
                              }}
                            />
                          }
                          label={t(
                            "so.checklistReports.forms.createUpdate.fields.isVisit.options.processRelationship"
                          )}
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12}>
                    {values.isVisit === "Visit" ? (
                      <React.Fragment>
                        <Select
                          inputId={"select-elements"}
                          placeholder={t(
                            "so.checklistReports.forms.createUpdate.fields.isVisit.options.visit"
                          )}
                          value={values.visitId}
                          onChange={(item) => {
                            setFieldValue("visitId", [item]);
                            setCurrentVisit(
                              visits.find((visit) => visit._id === item.value)
                            );
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              borderColor: hasError(errors, "visitId")
                                ? "red"
                                : base.borderColor,
                              marginRight: 10,
                            }),
                            menu: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          options={formatSelectOptions(visits, "name")}
                        />
                        <Select
                          inputId={"select-elements-key"}
                          placeholder={t(
                            "so.checklistReports.forms.createUpdate.fields.keyField.label"
                          )}
                          value={values.keyField}
                          onChange={(item) => {
                            setFieldValue("keyField", [item]);
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              borderColor: hasError(errors, "keyField")
                                ? "red"
                                : base.borderColor,
                              marginRight: 10,
                            }),
                            menu: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          options={
                            currentVisit
                              ? formatSelectArrayOptions(currentVisit.keys)
                              : []
                          }
                        />
                      </React.Fragment>
                    ) : (
                      <Select
                        inputId={"select-elements-processRelationship"}
                        placeholder={t(
                          "so.checklistReports.forms.createUpdate.fields.isVisit.options.processRelationship"
                        )}
                        value={values.processRelationshipId}
                        onChange={(item) => {
                          setFieldValue("processRelationshipId", [item]);
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderColor: hasError(
                              errors,
                              "processRelationshipId"
                            )
                              ? "red"
                              : base.borderColor,
                            marginRight: 10,
                          }),
                          menu: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={formatSelectOptions(
                          processRelationships,
                          "name"
                        )}
                      />
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12}>
                    <Field
                      name="targetValue"
                      render={({ field }) => {
                        return (
                          <TextField
                            {...field}
                            id="targetValue"
                            label={t(
                              "so.checklistReports.forms.createUpdate.fields.targetValue.label"
                            )}
                            success={
                              !hasError(errors, "targetValue") &&
                              touched.targetValue
                            }
                            error={hasError(errors, "targetValue")}
                            margin="normal"
                            type={"number"}
                            fullWidth
                          />
                        );
                      }}
                    />
                  </GridItem>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Form>
  );
};

CreateUpdateChecklistReports.prototype = {
  visits: PropTypes.array.isRequired,
  processRelationships: PropTypes.array.isRequired,
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  radioControl: {
    width: "100%",
    textAlign: "left",
  },
  formLabel: {
    marginLeft: "15px",
  },
  radio: {
    "&$checked": {
      color: "#6ab3d4",
    },
  },
  checked: {},
}));

const CreateUpdateChecklistReportsWF = withFormik({
  validationSchema: createUpdateChecklistReportsValidationSchema,
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const { initialValues, visits, processRelationships } = props;

    if (initialValues) {
      const { _id, ...rest } = initialValues;

      const currentVisit = initialValues.visitId
        ? visits.find((visit) => visit._id === initialValues.visitId)
        : null;
      const currentProcessRelationship = initialValues.processRelationshipId
        ? processRelationships.find(
            (processRelationship) =>
              processRelationship._id === initialValues.processRelationshipId
          )
        : null;
      return {
        ...rest,
        periodicity: {
          label: "",
          value: initialValues.periodicity,
        },
        visitId:
          initialValues.isVisit === true
            ? currentVisit
              ? formatSelectOptions([currentVisit], "name")
              : []
            : [],
        keyField:
          initialValues.isVisit === true
            ? currentVisit
              ? formatSelectArrayOptions([initialValues.keyField])
              : []
            : [],
        processRelationshipId:
          initialValues.isVisit === false
            ? currentProcessRelationship
              ? formatSelectOptions([currentProcessRelationship], "name")
              : []
            : [],
        isVisit: initialValues.isVisit ? "Visit" : "ProcessRelationship",
      };
    } else {
      //default
      return {
        name: "",
        periodicity: {
          label: "",
          value: SO_CHECKLIST_REPORT_PERIODICITY.Monthly,
        },
        initialDate: Moment().startOf("year").toDate(),
        finalDate: Moment().endOf("year").toDate(),
        isVisit: "Visit",
      };
    }
  },
  handleSubmit: (values, { props }) => {
    props.submitSuccess({
      ...values,
      periodicity: values.periodicity.value,
      isVisit: values.isVisit === "Visit",
      visitId: values.isVisit === "Visit" ? values.visitId[0].value : undefined,
      keyField:
        values.isVisit === "Visit" ? values.keyField[0].value : undefined,
      processRelationshipId:
        values.isVisit === "ProcessRelationship"
          ? values.processRelationshipId[0].value
          : undefined,
    });
  },
  validateOnChange: false,
})(CreateUpdateChecklistReports);

export default CreateUpdateChecklistReportsWF;
