module.exports = {
  formatConstantOptions: (translation, path, constantOptions) => {
    return Object.keys(constantOptions).map((option) => {
      return {
        label: translation(`${path}.${option}`),
        value: option,
      };
    });
  },
  formatSelectOptions: (elementArray, labelProp) => {
    return elementArray.map((element) => ({
      value: element._id.toString(),
      label: element[labelProp].toString(),
    }));
  },
  formatSelectArrayOptions: (elementArray) => {
    return elementArray.map((element) => ({
      value: element,
      label: element,
    }));
  },
  formatSelectArrayOptionsCustomLabel: (elementArray, calculateLabel) => {
    return elementArray.map((element) => ({
      value: element,
      label: calculateLabel(element),
    }));
  },
  formatSelectArrayObjects: (elementArray, valueProp, labelFunc) => {
    return elementArray.map((element) => ({
      value: element[valueProp],
      label: labelFunc(element),
    }));
  },
};
