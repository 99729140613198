import gql from "graphql-tag";

export const CHECKLIST_REPORT_CREATED_SUBSCRIPTION = gql`
  subscription checklistReportCreated($where: ChecklistReportCreatedCreatedWhereInput!){
    checklistReportCreated(where: $where){
      node{
        app
        userId
        status 
        url
        action
      }
    }
  }
`;
