import React from 'react';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import AddBoxIcon from '@material-ui/icons/AddBox';
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import ChecklistAnalisysVisitReportFormWF from '../../forms/reports/ChecklistAnalisysVisitReportForm';
import history from "../../utils/history";
import SummaryChecklistAnalisysReportFormWF from '../../forms/reports/SummaryChecklistAnalisysReportForm';
import CustomReportFormWF from '../../forms/reports/CustomReportForm';
import ChecklistHistoryReportFormWF from '../../forms/reports/ChecklistHistoryReportForm';

const EditChecklistAnalisysVisitReportView = ({ app, visits, submitSuccess, currentVisit, users, segments, teams, disabled }) => {
    const { t } = useTranslation("checklistAnalisys");
    const { dataVisit } = visits;
    const { dataUsers } = users;
    const { dataSegments } = segments;
    const { dataTeams } = teams;

    const initData = {
      app: app,
      currentVisit: currentVisit,
      visits: dataVisit.visits ? dataVisit.visits : [],
      users: dataUsers.userGHs ? dataUsers.userGHs : [],
      segments: dataSegments.segments ? dataSegments.segments : [],
      teams: dataTeams.getTeamsByApp ? dataTeams.getTeamsByApp : []
    }

    const renderForm = () => {
      switch (currentVisit.type) {
        case "SummaryVisit":
          return (
            <SummaryChecklistAnalisysReportFormWF
              initDataForm={initData}
              submitSuccess={submitSuccess}
              handleBack={() => {
                history.goBack();
              }}
              disabled={disabled}
            />
          );
        case "CustomVisit":
          return (
            <CustomReportFormWF
              initDataForm={initData}
              submitSuccess={submitSuccess}
              handleBack={() => {
                history.goBack();
              }}
              disabled={disabled}
            />
          );
        case "ChecklistHistory":
          return (
            <ChecklistHistoryReportFormWF
              initDataForm={initData}
              submitSuccess={submitSuccess}
              handleBack={() => {
                history.goBack();
              }}
              disabled={disabled}
            />
          );
        default:
          return (
            <ChecklistAnalisysVisitReportFormWF
              initDataForm={initData}
              submitSuccess={submitSuccess}
              handleBack={() => {
                history.goBack();
              }}
              disabled={disabled}
            />
          );
      }
    }
    return (
      <GridContainer justify="center">
        <GridItem xs={8}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <AddBoxIcon />
              </CardIcon>
              <h4 style={style.cardTitle}>
              </h4>
            </CardHeader>
            <CardBody>
                { renderForm() }
            </CardBody> 
          </Card>
        </GridItem>
      </GridContainer>
    );
}
EditChecklistAnalisysVisitReportView.prototype = {
    app: PropTypes.string.isRequired,
    visits: PropTypes.object.isRequired,
    submitSuccess: PropTypes.func.isRequired,
    currentVisit: PropTypes.object,
    users: PropTypes.object.isRequired,
    segments: PropTypes.object.isRequired,
    teams: PropTypes.object.isRequired,
}
const style = {
    cardTitle: {
      color: "#3C4858"
    },
  };
export default EditChecklistAnalisysVisitReportView;
