export const TICKET_REPORT_STATUS = {
  CREATED: "Created",
  ASSIGNED: "Assigned",
  RESOLVED: "Resolved",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
};

export const TICKET_REPORT_VIEW_TYPES = {
  COMMENTS: "COMMENTS",
  TRIGGER: "TRIGGER",
  FINISHER: "FINISHER",
  TIMELINE: "TIMELINE",
};

export const APPROVAL_ITEM_STATUS = {
  Pending: "Pending",
  Fulfilled: "Fulfilled",
};

export const APPROVAL_FLOW_ITEM_STATUS = {
  Pending: "Pending",
  Fulfilled: "Fulfilled",
  Approved: "Approved",
  Rejected: "Rejected",
  NotReached: "NotReached",
};

export const VISIT_ENGINE_TYPES = {
  Entry: "Entry",
  Activity: "Activity",
  Exit: "Exit",
};

export const APPROVAL_LOG_TYPE = {
  Percentage: "Percentage",
  Expiration: "Expiration",
  User: "User",
  Admin: "Admin",
};

export const APPROVAL_LOG_STATUS = {
  Approved: "Approved",
  Rejected: "Rejected",
};

export const GLOBAL_CATEGORIES = {
  1: {
    id: "idxGlobalAverage",
    gqlName: "global",
    operator: 1,
    name: "Promedio Global",
  },
  5: {
    id: "idxGlobalEfficacy",
    gqlName: "globalEfficacy",
    operator: 5,
    name: "Eficacia Global",
  },
};

export const FLAT_CHANGE = 10.0;

export const OPERATIONS = {
  1: "modules.questionEngine.checkListConf.average",
  2: "modules.questionEngine.checkListConf.sum",
  5: "modules.questionEngine.checkListConf.efficacy",
};

export const OPERATIONS_WITH_GLOBAL = {
  1: "modules.questionEngine.checkListConf.average",
  5: "modules.questionEngine.checkListConf.efficacy",
};

export const CHECKLIST_EXCLUDED_OPERATIONS = {
  3: "modules.questionEngine.checkListConf.maximum",
  4: "modules.questionEngine.checkListConf.minimum",
};

export const OPERATIONS_LABEL = {
  1: "Promedio",
  2: "Suma",
  5: "Eficacia",
};

export const COMPARISON_OPERATORS = {
  lessThan: {
    value: "lessThan",
    label: "MENOR QUE",
  },
  greaterThan: {
    value: "greaterThan",
    label: "MAYOR QUE",
  },
  lessEqual: {
    value: "lessEqual",
    label: "MENOR IGUAL",
  },
  greaterEqual: {
    value: "greaterEqual",
    label: "MAYOR IGUAL",
  },
  equalThan: {
    value: "equalThan",
    label: "IGUAL QUE",
  },
};

export const REPORT_ACTIONS = {
  combinedScoresPDF: "combinedScoresPDF",
  combinedScoresXLS: "combinedScoresXLS",
  keyfieldDashboardPDF: "keyfieldDashboardPDF",
  averageAnalysisPDF: "averageAnalysisPDF",
  averageAnalysisXLS: "averageAnalysisXLS",
  rankingsPDF: "rankingsPDF",
  rankingsXLS: "rankingsXLS",
  categoryDashboardPDF: "categoryDashboardPDF",
  categoryDashboardXLS: "categoryDashboardXLS",
  opportunityAreasPDF: "opportunityAreasPDF",
  opportunityAreasXLS: "opportunityAreasXLS",
  generalExecutionXLS: "generalExecutionXLS",
  generalExecutionPDF: "generalExecutionPDF",
  activeUserPDF: "activeUserPDF",
  activeUserXLS: "activeUserXLS",
  visitingTimePDF: "visitingTimePDF",
  galleryVisorPDF: "galleryVisorPDF",
};

export const REPORT_ACTION = {
  ALL_USERS: "allUsers",
  SCREEN_VISITS: "allScreenVisits",
  ANSWERS: "answers",
  ANSWERS_CHECKLIST: "answersChecklist",
  ANSWERS_IMAGES: "answersImages",
  VISITS: "visits",
  VISITS_CHECKLIST: "visitsChecklist",
  VISITS_INDIVIDUAL_PDF: "visitsIndividualPDF",
  VISITS_FULL_PDF: "visitsFullPDF",
  VISITS_CHECKLIST_INDIVIDUAL_PDF: "visitsChecklistIndividualPDF",
  VISITS_CHECKLIST_FULL_PDF: "visitsChecklistFullPDF",
  VISITS_EVIDENCE_PDF: 'visitsEvidencePDF',
  VISITS_NEGATIVES_PDF: 'visitsNegativePDF',
  CUSTOM_VISIT_REPORT_PDF: 'newCustomVisitReportPDF',
  ROUTES: "routes",
  PDF_VISIT_FULL: "pdfVisitFull",
  VISIT_UNIFIED: "visitsUnified",
  CHECKLIST_UNIFIED_VISIT: "visitsChecklistUnified",
  CHECKLIST_ANALISYS_VISIT_PDF: 'checklistAnalisysVisitPDF',
  CUSTOM_REPORT_PDF: 'customVisitReportPDF',
  SIMPLE_REPORT_PDF: 'simpleVisitReportPDF',
  SUMMARY_CHECKLIST_ALALISYS_VISIT: 'summaryChecklistAnalysisVisitPDF',
  TABLE_VISIT: 'tableVisit',
  TABLE_MODULE: 'tableModule',
  QUESTION_ENGINE_ANSWERS_PDF: 'questionEngineAnswersPDF',
  CHECKLIST_HISTORY: 'checklistHistory',
  PROCESS_RELATIONSHIP_EMAIL: 'emailProcessRelationship'
}

export const SO_COUNTER_REPORT_PERIODICITY = {
  Weekly: "Weekly",
  Biweekly: "Biweekly",
  Monthly: "Monthly",
  Bimonthly: "Bimonthly",
  Quarterly: "Quarterly",
};

export const SO_CHECKLIST_REPORT_PERIODICITY = {
  Weekly: "Weekly",
  Biweekly: "Biweekly",
  Monthly: "Monthly",
  Bimonthly: "Bimonthly",
  Quarterly: "Quarterly",
};

export const PROCESS_RELATIONSHIP_ELEMENT_TYPES = {
  MASSIVE_LIST: "MassiveList",
  GEOFENCES: "Geofences",
};

export const VISIT_SCHEDULING_RESOLVER_TYPES = {
  Segment: "Segment",
  User: "User",
  All: "All",
};

export const PROCESS_RELATIONSHIP_REPORT_TYPE = {
  DAILY: "Daily",
  WEEKLY: "Weekly"
}

export const REPORT_TYPE = {
  1: "Manual",
  2: "Scheluded",
  3: "Automatic"
}

export const REPORT_STATUS = {
  0: "Processing",
  1: "Created",
  2: "Error"
}

export const CHECKLIST_ANALISYS_VISIT_TYPE = {
  ExitVisit: "ExitVisit",
  SummaryVisit: "SummaryVisit",
  CustomVisit: "CustomVisit",
  ChecklistHistory: "ChecklistHistory"
}