import React from 'react';
import PropTypes from 'prop-types';

// core components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// styled components
import DivCenter from "components/styled-components/DivCenter";

const MassiveListView = ({selected}) => {
  return (
    <List>
      {selected.map((selectedOption, index) => {
        return (
          <ListItem key={index}>
            <ListItemText>
              <DivCenter>{selectedOption}</DivCenter>
            </ListItemText>
          </ListItem>
        );
      })}
    </List>

  );
};

MassiveListView.propTypes = {
  selected: PropTypes.array.isRequired
};

export default MassiveListView;
