import React from 'react';
import PropTypes from 'prop-types';

import Button from "../Button";

import withStyles from "@material-ui/core/styles/withStyles";
import EmailIcon from '@material-ui/icons/Email';
import ComingSoon from '../ComingSoon/ComingSoon';
import EmailMessageView from 'Views/EmailMessage/EmailMessageView';

function SendEmailButton({
  color, 
  defaultBody, 
  defaultSubject,
  hasXLS, 
  size, 
  isReady, 
  onClick, 
  sendPDFReport, 
  sendXLSReport
}){
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return(
    <>
      <Button
        justIcon
        simple
        size = {size == null ? "sm" : size}
        color = {color == null ? "info" : color}
        onClick={handleClick}
      >
        <EmailIcon/>
      </Button>
      { isReady ?
        <EmailMessageView
          defaultBody={defaultBody}
          defaultSubject={defaultSubject}
          open={open}
          hasXLS={hasXLS}
          handleClose={handleClose}
          sendPDFReport={sendPDFReport}
          sendXLSReport={sendXLSReport}
        />
      :
        <ComingSoon
          anchorEl={anchorEl}
          id={id}
          handleClose={handleClose}
          open={open}
        />
      } 
    </>
  );
};

SendEmailButton.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "twitter",
    "facebook",
    "google",
    "linkedin",
    "pinterest",
    "youtube",
    "tumblr",
    "github",
    "behance",
    "dribbble",
    "reddit",
    "transparent"
  ]),
  defaultBody: PropTypes.object,
  defaultSubject: PropTypes.object,
  isReady: PropTypes.bool.isRequired,
  hasXLS: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  sendPDFReport: PropTypes.func,
  sendXLSReport: PropTypes.func,
  size: PropTypes.oneOf(["sm", "lg"]),
};

SendEmailButton.defaultProps = {
  hasXLS: false,
};

const styles = theme => ({
});

export default withStyles(styles)(SendEmailButton);