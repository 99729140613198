import React from "react";
import {useTranslation} from 'react-i18next';
import {useQuery, useMutation, useSubscription} from "@apollo/react-hooks";

// Own components
import LoadingCircleView from "../../Views/LoadingCircleView";
import VisitAnswerApprovalItemView from "../../Views/Visits/VisitAnswerApprovalItemView";

import useGlobal from "../../store";
import {VISIT_APPROVAL_ITEM_INFO} from "./gql/Queries";
import {CREATE_APPROVAL_LOG_MUTATION, CREATE_ENGINE_ANSWER_UPDATE_LOG_MUTATION} from "./gql/Mutations";
import {APPROVAL_ITEMS_STATUS_CHANGED_SUBSCRIPTION} from "./gql/Subscriptions";
import {APPROVAL_LOG_TYPE} from "../../constants";
import {approvalItemChangedNotification} from "../../utils/snackNotifications"

function VisitAnswerApprovalItem({match, history}) {
  const [ session, globalActions] = useGlobal(
    state => state.session,
    actions => actions
  );
  const { t, } = useTranslation('visits');

  const mainQueryVariables = {
    approvalItem: match.params.approveItemId,
    visitId: match.params.visitId,
    visitAnswerId: match.params.visitAnswerId
  };

  const {data, error, loading, refetch: refetchVisitAnswerApprovalItems} = useQuery(
    VISIT_APPROVAL_ITEM_INFO,
    {
      variables: mainQueryVariables,
      fetchPolicy: 'network-only'
    }
  );
  const {
    data: dataApprovalItemsSubscription,
    error: errorApprovalItemsSubscription,
    loading: loadingApprovalItemsSubscription
  } = useSubscription(APPROVAL_ITEMS_STATUS_CHANGED_SUBSCRIPTION, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : '',
        userId: session.user ? session.user._id : '',
        approvalItems: [match.params.approveItemId]
      }
    },
    onSubscriptionData: ({client, subscriptionData}) => {
      const {status, node} = subscriptionData.data.approvalItemsStatusChanged;

      approvalItemChangedNotification(t, globalActions, status, node);
      refetchVisitAnswerApprovalItems();
    }
  });
  const [createApprovalLog, {loading: loadingCreateApproval}] = useMutation(CREATE_APPROVAL_LOG_MUTATION);
  const [createEngineAnswerUpdateLog] = useMutation(CREATE_ENGINE_ANSWER_UPDATE_LOG_MUTATION,{
    onCompleted: () => {
      refetchVisitAnswerApprovalItems();
    },
  });

  if (loading) {
    return <LoadingCircleView/>
  } else {
    return (
      <VisitAnswerApprovalItemView
        visit={data.visit}
        approvalItem={data.approvalItem}
        approvalItems={data.approvalItems}
        processAuthorization={(approvalItemAuthorize, status) => {
          createApprovalLog({
            variables: {
              data: {
                app: approvalItemAuthorize.app,
                userId: session.user._id,
                status: status,
                type: APPROVAL_LOG_TYPE.User,
                visitAnswerId: approvalItemAuthorize.visitAnswerId,
                approvalItemId: approvalItemAuthorize._id
              }
            },
            update: () => {
              history.goBack();
            }
          })
        }}
        processingAuthorization={loadingCreateApproval}
        processAnswerUpdateLog={(updateInfo) => {

          const variables = {
            data: {
              app: session.appRef._id,
              userId: session.user._id,
              visitAnswerId: match.params.visitAnswerId,
              ...updateInfo
            }
          };
          createEngineAnswerUpdateLog({
            variables: variables,
            onError: () => {
              globalActions.notification.openNotification('tr', {
                message: 'Ocurrió un error al guardar la modificación',
                color: 'error'
              });
            }
          });
        }}
      />
    );
  }

}

const styles = theme => ({
  center: {
    textAlign: "center"
  }
});

export default VisitAnswerApprovalItem;
