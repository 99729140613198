import React, { useState } from 'react';
import ReactTable from "react-table";
import { Typography } from '@material-ui/core';
import DivCenter from "../../components/styled-components/DivCenter";
import moment from "moment";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ConfirmOptionsIconButton from "components/ConfirmOptionsIconButton/ConfirmOptionsIconButton";
import i18n from "../../i18n";
import {useTranslation} from "react-i18next";
import RateReview from '@material-ui/icons/RateReview';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import WithConfirmComment from "../../modals/ConfirmCommentModal";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Checkbox } from "@material-ui/core";

const groupBy = key => array =>
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
    }),
    {}
  );

const RequisitionsTable = ({
  data,
  goToConfirmation,
  processAuthorization,
  setCurrentRightView,
  filters,
  setFilters,
  status,
  setStatus,
  showArchived,
  setShowArchived,
  checkedItems,
  setCheckedItems
}) => {
  const { t } = useTranslation("requisitions");

  const today = moment();

  const handleCheckboxChange = (event, item) => {
    setCheckedItems({
      ...checkedItems,
      [item._id]: event.target.checked
    });
  };

  const isExpired = (expirationDate, today) => {
    return moment(expirationDate).isBefore(today);
  };

  const containsFilter = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
  };

  return (
      <ReactTable
      data={data}
      columns={[
        {
          Header: (
            <DivCenter style={{ ...styles.headerTitle }}>Requisición</DivCenter>
          ),
          accessor: "elementName",
          filterable: true,
          sortable: false,
          filterMethod: containsFilter,
          style: { textAlign: "center", whiteSpace: "break-spaces" },
          Cell: ({ original }) => {
            return (
              <div>{original.elementName}</div>
            );
          },
        },
        {
          Header: <DivCenter style={styles.headerTitle}>Estado</DivCenter>,
          accessor: "status",
          filterable: true,
          sortable: false,
          style: { textAlign: "center" },
          filterMethod: (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (filter.value === "All") {
              return true;
            }
            return String(row[id]).toLowerCase().includes(filter.value.toLowerCase())

          },
          Cell: ({ original }) => {
            return (
              <Typography style={styles.contentText}>
                {t(`requisitions.status.${original.status}`)}
              </Typography>
            );
          },
          Filter: ({filter, onChange}) => (
            <Select
              style={{ width: "100%" }}
              value={filter ? filter.value : "All"}
              onChange={(e) => {
                onChange(e.target.value);
              }}
            >
              <MenuItem value={"All"} key={"All"}>
                Todas
              </MenuItem>
              <MenuItem value={"Pending"} key={"Pending"}>
                Pendientes
              </MenuItem>

              <MenuItem value={"Rejected"} key={"Rejected"}>
                Rechazadas
              </MenuItem>

              <MenuItem value={"Approved"} key={"Approved"}>
                Aprobadas
              </MenuItem>

              <MenuItem value={"Completed"} key={"Completed"}>
                Completadas
              </MenuItem>
            </Select>

          ),
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>Devoluciones</DivCenter>
          ),
          accessor: "totalPenginDates",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          Cell: ({ original }) => {
            const rejectCount = original.requisitionLogs.filter(
              (requisition) => requisition.status === "Rejected"
            );
            return (
              <Typography style={styles.contentText}>
                {rejectCount.length ? rejectCount.length : 0}
              </Typography>
            );
          },
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>Fecha limite</DivCenter>
          ),
          accessor: "expirationDate",
          filterable: true,
          sortable: false,
          style: { textAlign: "center", whiteSpace: "break-spaces" },
          Cell: ({ original }) => {
            return (
              <Typography style={styles.contentText}>
                {moment(original.expirationDate).format("DD-MM-YYYY")}
              </Typography>
            );
          },
          Filter: () => (
            <Select
              style={{ width: "100%" }}
              value={status}
              onChange={(e) => {
                
                const defaultParams = {
                  processRelationshipId: filters.processRelationshipId,
                  requisitionId: filters.requisitionId,
                  app: filters.app,
                }

                const expiredOpion = {
                  'All' : () =>  ({ 
                    ...defaultParams
                   }),
                  'Valid' : () => ({ 
                    ...defaultParams,
                    expirationDate_gte: moment(new Date()).format(),
                   }),
                  'Expired' : () => ({ 
                    ...defaultParams,
                    expirationDate_lte: moment(new Date()).format(),
                   })
                }

                setFilters(expiredOpion[e.target.value]);
                setStatus(e.target.value);
              }}
            >
              <MenuItem value={"All"} key={"All"}>
                Todas
              </MenuItem>
              <MenuItem value={"Valid"} key={"Valid"}>
                Vigentes
              </MenuItem>

              <MenuItem value={"Expired"} key={"Expired"}>
                Vencidas
              </MenuItem>
            </Select>
          ),
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>Fecha Aprobación</DivCenter>
          ),
          accessor: "approvedDate",
          filterable: false,
          sortable: false,
          style: { textAlign: "center", whiteSpace: "break-spaces" },
          Cell: ({ original }) => {
            if (original.status === 'Approved') {
              return (
                <Typography style={styles.contentText}>
                  {moment(original.approvedDate).format("DD-MM-YYYY")}
                </Typography>
              );
            }else{
              return[];
            }
          },
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>Área que lo emite</DivCenter>
          ),
          accessor: "areaName",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          Cell: ({ original }) => {
            return (
              <Typography style={styles.contentText}>
                {original.requisitionRef.areaName}
              </Typography>
            );
          },
        },
        {
          Header: (
            <DivCenter style={{ ...styles.headerTitle }}>
              Usuario Asignado
            </DivCenter>
          ),
          accessor: "assignedUserRef",
          filterable: true,
          sortable: false,
          filterMethod: (filter, row) => {
            const fullName = `${row[filter.id].firstName} ${row[filter.id].lastName}`;
            return fullName.toLowerCase().includes(filter.value.toLowerCase());
          },
          style: { textAlign: "center", whiteSpace: "break-spaces" },
          Cell: ({ original }) => {
            return (
              <Typography style={styles.contentText}>
                {`${original.assignedUserRef.firstName} ${original.assignedUserRef.lastName}`}
              </Typography>
            );
          },
        },
        {
          Header: (<DivCenter></DivCenter>),
          accessor: "action",
          filterable: false,
          sortable: false,
          style: { textAlign: "center", width: "100%" },
          Cell: ({ original }) => {
            return (
              <div>
                {
                  <div key={original._id}>
                    <Checkbox
                      disabled={ showArchived === true && isExpired(original.expirationDate, today)}
                      checked={checkedItems[original._id] || false}
                      onChange={(event) => handleCheckboxChange(event, original)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </div>
                }
              </div>
            );
          },
        },
        {
          Header: <DivCenter style={styles.headerTitle}></DivCenter>,
          accessor: "action",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          Cell: ({ original }) => {
            return (
              <div>
                {original.status !== "Pending" ? (
                  <Button
                    justIcon
                    round
                    simple
                    color="info"
                    onClick={() => {
                      const logsAnswers = original.requisitionLogs.filter(
                        (log) => log.questionAnswer
                      );

                      const groupLogs = groupBy("answer");

                      setCurrentRightView({
                        open: true,
                        requisition: groupLogs(logsAnswers),
                        row: original
                      });

                    }}
                  >
                    <Tooltip title={"Ver"}>
                      <VisibilityIcon style={{ fontSize: 40 }} />
                    </Tooltip>
                  </Button>
                ) : null}
                {original.status === "Completed" ? (
                  <ConfirmOptionsIconButton
                    t={t}
                    baseTooltip={
                      "requisitions.approvalRequisition.tooltips.buttonApprove"
                    }
                    baseLabel={
                      "requisitions.approvalRequisition.tooltips.labelApprove"
                    }
                    baseColor={"info"}
                    confirmDelay={5000}
                    baseIcon={RateReview}
                    confirmOptions={[
                      {
                        confirmTooltip:
                          "requisitions.approvalRequisition.tooltips.buttonAccept",
                        confirmOperation: () => {
                          goToConfirmation({
                            title: i18n.t("common.dialogs.confirmTitle", {
                              ns: "common",
                            }),
                            message: `Aprobar ${original.elementName} esta requisición`,
                            status: "Approve",
                            callback: () => {
                              processAuthorization(original._id, {
                                status: "Approved",
                                approvedDate: new Date(),
                              });
                            },
                          });
                        },
                        confirmColor: "success",
                        confirmIcon: CheckOutlinedIcon,
                      },
                      {
                        confirmTooltip:
                          "requisitions.approvalRequisition.tooltips.buttonReject",
                        confirmOperation: () => {
                          goToConfirmation({
                            title: i18n.t("common.dialogs.confirmTitle", {
                              ns: "common",
                            }),
                            comment: "",
                            status: "Reject",
                            callback: (result) => {
                              processAuthorization(original._id, {
                                status: "Rejected",
                                ...result,
                              });
                            },
                          });
                        },
                        confirmColor: "danger",
                        confirmIcon: CancelIcon,
                      },
                    ]}
                  />
                ) : null}
              </div>
            );
          },
        },
      ]}
      defaultPageSize={data.length}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
      resizable={false}
    />
  );
};

const styles = {
    headerTitle: {
      fontSize: "20px",
      whiteSpace: 'break-spaces',
      fontWeight: "500", 
    },
    contentText: {
      fontSize: 18,
    },
    
  };

export default WithConfirmComment(RequisitionsTable);
