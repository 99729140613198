import React from "react";
import PropTypes from "prop-types";
import Chart from 'chart.js';

import withStyles from "@material-ui/core/styles/withStyles";

function PieChart({data, id}) {
  const [chart, setChart] = React.useState(null); 
  const chartRef = React.useRef();

  React.useEffect(() => {
    const canvasRef = chartRef.current.getContext("2d");    
    if (chart) chart.destroy();
    let temp = new Chart(canvasRef, {
      type: "pie",
      data: data,
      options: {
        responsive: true,
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          callbacks: {
              label: function(tooltipItem, data) {
                  return data.labels[tooltipItem.index] + 
                    ' : ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + '%';
              }
          }
        }
      }
    });
    setChart(temp);
  }, [data]);
  
  return (
    <canvas
      id={id}
      ref={chartRef}
    />
  );
}

const styles = theme => ({
});

PieChart.propTypes = {
  id: PropTypes.string,
  chart: PropTypes.object,
  data: PropTypes.object.isRequired,
  handleChartChange: PropTypes.func
};

export default withStyles(styles)(PieChart);
