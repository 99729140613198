import React, { useMemo, useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import ReactTable from "react-table";
import DivCenter from "../components/styled-components/DivCenter";
import Badge from "components/Badge/Badge";
import useGlobal from "../store";
import EditIcon from "@material-ui/icons/Edit";
import TextFieldContentWidth from "../Views/SO/components/TextFieldContentWidth";
import Button from "components/CustomButtons/Button.jsx";
import { baseDateAnswerFormat } from "../utils/dates/dates";

const ProratedObjectiveModal = ({
  open,
  setOpen,
  currentViewIndicator,
  getIndicatorRangeResult,
  dataIndicatorResult,
  customIndicatorObjectives,
  setCustomIndicatorObjectives,
  canEdit,
  setCanEdit,
  applyRangePending,
  setIsModify,
  setShowAlert,
  loadingIndicatorResult
}) => {
  const [session] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const [globalObjective, setGlobalObjective] = useState(0);
  const [currentEditObjective, setCurrentEditObject] = useState({
    row: "",
  });
  const [customObjectives, setCustomObjectives] = useState([]);

  useMemo(() => {
    if (open && dataIndicatorResult.length) {
      const totalResult = dataIndicatorResult.reduce((total, current) => {return total + current.result}, 0);

      if (!currentViewIndicator?.indicator?.proratedObjective?.length || !totalResult) {

        const global = currentViewIndicator?.indicator?.value
        ? currentViewIndicator?.indicator?.value
        : currentViewIndicator?.indicator?.targetValue;

        const residue = global % dataIndicatorResult.length;
        const avgObjective =
          (global - residue) / dataIndicatorResult.length;
        
        const prorated = dataIndicatorResult.map((objective, index) => {
          let currentObjective = 0;

          if (index < residue) {
            currentObjective = avgObjective + 1;
          } else {
            currentObjective = avgObjective;
          }

          return {
            ...objective,
            objective: currentObjective,
            order: index
          };
        });

        setCustomObjectives([...prorated]);
      }else{
        const currentFilter = currentViewIndicator?.indicator?.keFieldAnswer
                      ? "keFieldAnswer"
                      : "userEmail";

        let calculated = dataIndicatorResult.map((item, index) => {
          const itemFound = currentViewIndicator?.indicator?.proratedObjective.find(
            (objective) =>
              item[currentFilter] === objective[currentFilter] &&
              objective.order === index
          );

          return {
            ...item,
            objective: itemFound ? itemFound.objective : 0,
            order: itemFound ? itemFound.order : index
          };
          
        });
        setCustomObjectives([...calculated]);
      }
    }

  }, [open, dataIndicatorResult, currentViewIndicator]);

  useEffect(() => {
    if (open) {
      setGlobalObjective(
        currentViewIndicator?.indicator?.value
          ? currentViewIndicator?.indicator?.value
          : currentViewIndicator?.indicator?.targetValue
      );

      getIndicatorRangeResult({
        variables: {
          where: {
            appId: session.user ? session.appRef._id : "",
            indicator: {
              type: currentViewIndicator.indicator.type, 
              referenceId: currentViewIndicator.indicator.referenceId, 
              fieldRefId: currentViewIndicator.indicator.fieldRefId, 
              targetValue: currentViewIndicator.indicator.targetValue, 
              keyField: currentViewIndicator.indicator.keyField,
              visitId: currentViewIndicator.indicator.visitId,
              segment: currentViewIndicator.indicator.segment, 
              segmentRef: currentViewIndicator.indicator.segmentRef, 
            },
            range: {
              ...currentViewIndicator?.infoRow,
            },
            save: false,
          },
        },
      });
    }
  }, [currentViewIndicator, open]);

  const dataTable = useMemo(() => {
    if (open) {
      const result = customObjectives.map((objective) => {
          return {
            ...objective,
            objective: objective.objective,
            fullfilment: `${(objective.objective > 0
              ? (objective.result / objective.objective) * 100
              : 0
            ).toFixed(2)} %`,
          };
        });
        return [...result];
    } else {
      return [];
    }
  }, [open, currentViewIndicator, customObjectives]);

  const handleClose = () => {
    setOpen(false);
    setCurrentEditObject({row: ""})
    setCustomObjectives([]);
    setGlobalObjective(0);
    setCanEdit(!canEdit)
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"md"}
      fullWidth={true}
    >
      <DialogTitle onClose={handleClose}>
        <GridContainer>
          <GridItem xs={6}>
            <p style={{ fontWeight: "normal" }}>
              Ejecución de indicador en{" "}
              <span style={{ fontWeight: "bold" }}>
                {currentViewIndicator
                  ? currentViewIndicator?.indicator?.keyField
                    ? currentViewIndicator?.indicator?.keyField
                    : currentViewIndicator?.indicator?.segmentRef.name
                  : ""}
              </span>
            </p>
            <p style={{ fontWeight: "normal" }}>
              Objetivo{" "}
              <span style={{ fontWeight: "bold" }}>{globalObjective}</span>
            </p>
            <p style={{ fontWeight: "normal" }}>
              Periodo:{" "}
              <span style={{ fontWeight: "bold" }}>{`${baseDateAnswerFormat(
                currentViewIndicator?.infoRow?.initialDate
              )} - ${baseDateAnswerFormat(
                currentViewIndicator?.infoRow?.finalDate
              )}`}</span>
            </p>
          </GridItem>
          <GridItem xs={6}>
            <div
              style={{
                float: "right",
              }}
            >
              <Button
                size="sm"
                color="success"
                disabled={!canEdit}
                onClick={() => {
                  setIsModify(true);
                  setShowAlert(true);
                  const currentOrder = currentViewIndicator.infoRow.order;
                  const customIndicatorObjectiveFound = customIndicatorObjectives.find(
                    (custom) =>
                      custom.indicatorId ===
                        currentViewIndicator.indicator._id &&
                      custom.order === currentOrder
                  );

                  if (customIndicatorObjectiveFound) {
                    setCustomIndicatorObjectives(
                      customIndicatorObjectives.map((custom) => {
                        if (
                          custom.indicatorId ===
                            currentViewIndicator.indicator._id &&
                          custom.order === currentOrder
                        ) {
                          return {
                            ...custom,
                            value: globalObjective,
                            proratedObjective: customObjectives,
                          };
                        }
                        return custom;
                      })
                    );
                  } else {
                    setCustomIndicatorObjectives([
                      ...customIndicatorObjectives,
                      {
                        order: currentOrder,
                        indicatorId: currentViewIndicator.indicator._id,
                        value: globalObjective,
                        proratedObjective: customObjectives,
                      },
                    ]);
                  }

                  handleClose();
                }}
              >
                Aceptar
              </Button>

              <Button
                size="sm"
                color="success"
                disabled={!canEdit}
                onClick={() => {
                  applyRangePending(
                    globalObjective,
                    currentViewIndicator.indicator,
                    dataTable
                  );
                  handleClose();
                }}
              >
                Aceptar y aplicar en periodos futuros
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </DialogTitle>
      <DialogContent dividers>
        <GridContainer>
          <GridItem xs={12}>
            <div
              style={{
                float: "right",
              }}
            >
              <Badge color={"success"}>Total: {dataTable?.length ?? 0}</Badge>
            </div>
          </GridItem>
          <GridItem xs={12}>
            {loadingIndicatorResult ? (
              <div
                style={{
                  width: "100px !important",
                  marginLeft: "50%",
                  marginRight: "50%",
                }}
              >
                <CircularProgress
                  style={{
                    margin: "16px",
                  }}
                />
              </div>
            ) : (
              <ReactTable
                data={currentViewIndicator?.baseInfo?.result !== 0 ? dataTable : []}
                columns={[
                  {
                    Header: (
                      <DivCenter>
                        {currentViewIndicator
                          ? currentViewIndicator?.indicator?.keyField
                            ? currentViewIndicator?.indicator?.keyField
                            : currentViewIndicator?.indicator?.segmentRef.name
                          : ""}
                      </DivCenter>
                    ),
                    filterable: true,
                    resizable: false,
                    sortable: false,
                    borderLeft: true,
                    style: {
                      whiteSpace: "unset",
                    },
                    filterMethod: (filter, row) => {
                      const { _original } = row;
                      const filterElement = _original?.userEmail
                        ? "userEmail"
                        : "keyFieldAnswer";

                      return String(_original[filterElement].toLowerCase())?.includes(filter.value.toLowerCase());
                    },
                    Cell: ({ original }) => {
                      return (
                        <DivCenter>
                          <Typography>
                            {original.userEmail
                              ? original.userEmail
                              : original.keyFieldAnswer}
                          </Typography>
                        </DivCenter>
                      );
                    },
                  },
                  {
                    Header: <DivCenter>Cumplimiento</DivCenter>,
                    filterable: false,
                    resizable: false,
                    sortable: false,
                    borderLeft: true,
                    Cell: ({ original }) => {
                      const currentRow = original?.keFieldAnswer
                        ? original?.keFieldAnswer
                        : original?.userEmail;
                      const customObjectiveFound = customObjectives.find(
                        (objective) => objective.row === currentRow
                      );

                      const currentValue = customObjectiveFound
                        ? `${(customObjectiveFound.objective > 0
                            ? (customObjectiveFound.result /
                                customObjectiveFound.objective) *
                              100
                            : 0
                          ).toFixed(2)} %`
                        : original.fullfilment;

                      return (
                        <DivCenter>
                          <Typography>{currentValue}</Typography>
                        </DivCenter>
                      );
                    },
                  },
                  {
                    Header: <DivCenter>Objetivo</DivCenter>,
                    filterable: false,
                    resizable: false,
                    sortable: false,
                    borderLeft: true,
                    Cell: ({ original }) => {
                      const currentRow = original?.keFieldAnswer
                        ? original?.keFieldAnswer
                        : original?.userEmail;

                      const currentFilter = original?.keFieldAnswer
                        ? "keFieldAnswer"
                        : "userEmail";

                      const customObjectiveFound = customObjectives.find(
                        (objective) =>
                          objective?.row?.item === currentRow &&
                          objective?.row?.order === original.order
                      );

                      const currentValue = customObjectiveFound
                        ? customObjectiveFound.objective
                        : original.objective;

                      if (
                        currentEditObjective?.row?.item === currentRow &&
                        currentEditObjective?.row?.order === original.order
                      ) {
                        return (
                          <DivCenter>
                            <TextFieldContentWidth
                              value={currentValue}
                              onChange={(newObjective) => {
                                const tmpCustomObjectives = customObjectives.map(
                                  (custom) => {
                                    if (
                                      custom[currentFilter] === currentRow &&
                                      custom.order === original.order
                                    ) {
                                      return {
                                        ...custom,
                                        objective: newObjective,
                                      };
                                    }
                                    return custom;
                                  }
                                );

                                setCustomObjectives([...tmpCustomObjectives]);

                                const newGlobal = tmpCustomObjectives.reduce(
                                  (total, objective) => {
                                    return total + objective.objective;
                                  },
                                  0
                                );

                                setGlobalObjective(newGlobal);
                              }}
                              endEdit={() => {
                                setCurrentEditObject({
                                  row: "",
                                });
                              }}
                            />
                          </DivCenter>
                        );
                      } else {
                        return (
                          <DivCenter>
                            {currentValue}
                            {canEdit ? (
                              <Button
                                justIcon
                                round
                                simple
                                color="info"
                                onClick={() => {
                                  setCurrentEditObject({
                                    row: {
                                      order: original.order,
                                      item: original?.keFieldAnswer
                                        ? original?.keFieldAnswer
                                        : original?.userEmail,
                                    },
                                  });
                                }}
                              >
                                <EditIcon />
                              </Button>
                            ) : (
                              []
                            )}
                          </DivCenter>
                        );
                      }
                    },
                  },
                  {
                    Header: <DivCenter>Ejecución</DivCenter>,
                    filterable: false,
                    resizable: false,
                    sortable: false,
                    borderLeft: true,
                    Cell: ({ original }) => {
                      return (
                        <DivCenter>
                          <Typography>{original.result.toFixed(2)}</Typography>
                        </DivCenter>
                      );
                    },
                  },
                ]}
                pageSize={dataTable?.length || 0}
                showPaginationTop={false}
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            )}
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
};

export default ProratedObjectiveModal;
