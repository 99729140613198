import React, { useState, useCallback } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Select, { components } from 'react-select';
import useGlobal from "../../store";
import DateRangeInput from '../../components/FiltersSidebar/DateRangeInput';
import { getAcumCountActivity, getFrequencyColumns, getKeyfieldOptions, groupByKeyfields } from '../../utils/analytics/dataManipulation';
import ChronologicalReportTable from '../../tables/Reports/ChronologicalReportTable';
import GetAppIcon from '@material-ui/icons/GetApp';
import CardIcon from "components/Card/CardIcon.jsx";
import { useTranslation } from "react-i18next";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

const ChronologicalReportView = ({
    selecteVisit,
    visits,
    activities,
    segmentOptions,
    closedVisits,
    segment,
    handleSegmentChange,
    classes,
    selectActivities,
    handleActivitiesChange,
    keyfields,
    keyfieldsOptions,
    currentKeyfield,
    handleKeyFieldsChange,
    frequencyOptions,
    handleFrequencyChange,
    frequency,
    keyfieldsValues,
    handleKeyfieldsValuesChange,
    visitAnswer,
    createChronologicalReport
}) => {

    const { t } = useTranslation("chronological");
    const [filters, globalActions] = useGlobal(
        state => state.filters,
        actions => actions
    );

    const visitsOptions = visits.map(visit => ({ label: visit.name, value: visit._id }));

    const visitSelectChange = useCallback((value) => {
        handleActivitiesChange([]);
        handleKeyFieldsChange(null)
        handleSegmentChange({value: "Todos", label: "Todos"});
        globalActions.filters.setVisitID(value);
    }, [globalActions]);

    let keyfieldValueOptions = [];

    if (currentKeyfield) {
        const keyfield = keyfields.find(keyfield => keyfield.id === currentKeyfield.value);
        const groupedIndex = groupByKeyfields(visitAnswer, [keyfield]);        
        keyfieldValueOptions = getKeyfieldOptions(groupedIndex, visitAnswer).map(item => {
            return ({value: item.keyfield.value, label: item.keyfield.value});
        });
    }

    //console.log(keyfieldValueOptions)
    
    const currentActivities = selectActivities === null || !selectActivities.length ? activities : selectActivities;
    const { columnsDates, data } = getFrequencyColumns(filters.range.startDate, filters.range.endDate, frequency, currentActivities);
    const tmpData = getAcumCountActivity(closedVisits, data, columnsDates);

    const wrapXLSData = () => {
        const wrap = {
            data: tmpData,
            columns: columnsDates,
            activities: currentActivities,
        }

        if (segment.value !== 'Todos') {
            wrap.segment = segment.value;
        }

        if (keyfieldsValues.length) {
            wrap.keyFieldValue_in = keyfieldsValues.map(keyfieldValue => keyfieldValue.value);
            if (currentKeyfield) {
                wrap.keyFieldOption = currentKeyfield.value;
            }
        }
        return wrap;
    };

    const handleXLSReportClick = React.useCallback(() => {
        const payload = wrapXLSData();
        createChronologicalReport(payload);
    }, [ createChronologicalReport, tmpData, columnsDates, segment, currentActivities ]);

    return (
        <GridContainer>
            <GridItem xs={3}>
                <Card className={classes.cardLessBorder}>
                    <CardHeader color="info" text>
                        <GridContainer>
                        <GridItem>
                            <CardText color="info">
                            <h4>{t('chronological.selelectVisit')}</h4>
                            </CardText>
                        </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody className={classes.cardSelectorVisit}>
                        <div className={classes.selectors}>
                        <Select
                            className={classes.selector}
                            placeholder={t('chronological.selelectVisit')}
                            value={selecteVisit ? { label: selecteVisit.name, value: selecteVisit._id } : ''}
                            onChange={visitSelectChange}
                            options={visitsOptions}
                        />
                        </div>
                    </CardBody>
                </Card>

                <Card className={classes.cardLessBorder}>
                    <CardHeader color="info" text>
                        <GridContainer>
                        <GridItem>
                            <CardText color="info">
                            <h4>{t('chronological.selectKeyField')}</h4>
                            </CardText>
                        </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody className={classes.cardSelectorKeyField}>
                        <div className={classes.selectors}>
                            <Select
                                className={classes.selector}
                                placeholder={t('chronological.selectKeyField')}
                                value={currentKeyfield}
                                onChange={handleKeyFieldsChange}
                                options={keyfieldsOptions}
                            />
                        </div>
                        {currentKeyfield && 
                            <div className={classes.selectors}>
                                <Select
                                    isMulti
                                    className={classes.selector}
                                    placeholder={t('chronological.selectKeyFields')}
                                    value={keyfieldsValues}
                                    onChange={handleKeyfieldsValuesChange}
                                    options={keyfieldValueOptions}
                                    components={{
                                        ValueContainer: CustomValueContainer,
                                    }}
                                    styles={{
                                        container: (provided) => ({
                                          ...provided,
                                          marginTop: 20,
                                        }),
                                        valueContainer: (provided) => ({
                                          ...provided,
                                          overflow: "visible",
                                        }),
                                        placeholder: (provided, state) => ({
                                          ...provided,
                                          position: "absolute",
                                          top:
                                            state.hasValue || state.selectProps.inputValue
                                              ? -15
                                              : "50%",
                                          transition: "top 0.1s, font-size 0.1s",
                                          fontSize:
                                            (state.hasValue || state.selectProps.inputValue) &&
                                            13,
                                        }),
                                    }}
                                />
                            </div>
                        }
                    </CardBody>
                </Card>
                
                <DateRangeInput
                    startDate={filters.range.startDate}
                    endDate={filters.range.endDate}
                    setStartDateRange={globalActions.filters.setStartDateRange}
                    setEndDateRange={globalActions.filters.setEndDateRange}
                />
            </GridItem>
            <GridItem xs={9}>
                <Card className={classes.cardLessBorder}>
                    <CardHeader color="info" text>
                        <GridContainer>
                            <GridItem xs={11}>
                                <CardText color="info">
                                <h4>{t('chronological.title')}</h4>
                                </CardText>
                            </GridItem>
                            <GridItem xs={1}>
                                <CardIcon color="info" className={classes.pointer} onClick={handleXLSReportClick}>
                                    <GetAppIcon />
                                </CardIcon>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <div className={classes.selectors}>
                            <div>
                                <Select
                                    closeMenuOnSelect={false}
                                    isClearable={false}
                                    isMulti
                                    className={classes.selectorAct}
                                    placeholder={t('chronological.selectActivity')}
                                    value={currentActivities}
                                    onChange={handleActivitiesChange}
                                    options={activities}
                                    components={{
                                        ValueContainer: CustomValueContainer,
                                    }}
                                    styles={{
                                        container: (provided) => ({
                                          ...provided,
                                          marginTop: 20,
                                        }),
                                        valueContainer: (provided) => ({
                                          ...provided,
                                          overflow: "visible",
                                        }),
                                        placeholder: (provided, state) => ({
                                          ...provided,
                                          position: "absolute",
                                          top:
                                            state.hasValue || state.selectProps.inputValue
                                              ? -15
                                              : "50%",
                                          transition: "top 0.1s, font-size 0.1s",
                                          fontSize:
                                            (state.hasValue || state.selectProps.inputValue) &&
                                            13,
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className={classes.selectors}>
                            <div>
                                <Select
                                    className={classes.selector}
                                    placeholder={t('chronological.segment')}
                                    value={segment}
                                    onChange={handleSegmentChange}
                                    options={segmentOptions}
                                    components={{
                                        ValueContainer: CustomValueContainer,
                                    }}
                                    styles={{
                                        container: (provided) => ({
                                          ...provided,
                                          marginTop: 20,
                                        }),
                                        valueContainer: (provided) => ({
                                          ...provided,
                                          overflow: "visible",
                                        }),
                                        placeholder: (provided, state) => ({
                                          ...provided,
                                          position: "absolute",
                                          top:
                                            state.hasValue || state.selectProps.inputValue
                                              ? -15
                                              : "50%",
                                          transition: "top 0.1s, font-size 0.1s",
                                          fontSize:
                                            (state.hasValue || state.selectProps.inputValue) &&
                                            13,
                                        }),
                                    }}
                                />
                            </div>

                            <div>
                                <Select
                                    className={classes.selector}
                                    placeholder={t('chronological.periodicity')}
                                    value={frequency}
                                    onChange={handleFrequencyChange}
                                    options={frequencyOptions}
                                    components={{
                                        ValueContainer: CustomValueContainer,
                                    }}
                                    styles={{
                                        container: (provided) => ({
                                          ...provided,
                                          marginTop: 20,
                                        }),
                                        valueContainer: (provided) => ({
                                          ...provided,
                                          overflow: "visible",
                                        }),
                                        placeholder: (provided, state) => ({
                                          ...provided,
                                          position: "absolute",
                                          top:
                                            state.hasValue || state.selectProps.inputValue
                                              ? -15
                                              : "50%",
                                          transition: "top 0.1s, font-size 0.1s",
                                          fontSize:
                                            (state.hasValue || state.selectProps.inputValue) &&
                                            13,
                                        }),
                                    }}
                                />
                            </div>

                        </div>

                        <ChronologicalReportTable data={tmpData} columnsDates={columnsDates}/>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

const styles = theme => ({
  cardSelectorVisit: {
    zIndex: 11
  },
  cardSelectorKeyField: {
    zIndex: 10
  },
  cardLessBorder: {
    marginBottom: "0px"
  },
  selector: {
    margin: "10px 20px",
    width: "400px"
  },
  selectorAct: {
    margin: "10px 20px",
    width: "840px"
  },
  selectors:{
    display: "flex"
  },
  pointer: {
    cursor: 'pointer'
  },
});

export default withStyles(styles)(ChronologicalReportView);
