import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

// custom components
import ButtonIcon from "components/styled/ButtonIcon";

// core components
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Cancel from "@material-ui/icons/Cancel";
import Check from "@material-ui/icons/Check";
import { Field, Form, withFormik } from "formik";
import * as yup from "yup";
import {
    TextField
  } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WithConfirmComment = BaseForm =>
  function WithConfirmationHook(props) {
    const {t} = useTranslation('common');

    const [isConfirming, setConfirming] = useState(false);
    const [confirmInfo, setConfirmingInfo] = useState({});

    const toggleConfirmation = (confirmInfo) => {
      setConfirming(true);
      setConfirmingInfo(confirmInfo)
    };

    const handleCancel = () => {
      setConfirming(false);
    };

    const handleSuccess = (values) => {
      confirmInfo.callback(values);
      setConfirming(false);
    };

    return (
      <React.Fragment>
        <BaseForm {...props} goToConfirmation={toggleConfirmation} />

        {confirmInfo.status === "Reject" ? (
          <SimpleFormFK
            handleSuccess={handleSuccess}
            handleCancel={handleCancel}
            isConfirming={isConfirming}
            t={t}
            confirmInfo={confirmInfo}
          />
        ) : (
          <SimpleDialog
            handleSuccess={handleSuccess}
            handleCancel={handleCancel}
            isConfirming={isConfirming}
            t={t}
            confirmInfo={confirmInfo}
          />
        )}
      </React.Fragment>
    );
  }
;

const SimpleDialog = ({
    handleSuccess,
    isConfirming,
    handleCancel,
    t,
    confirmInfo
}) => {
return (
  <Dialog
    open={isConfirming}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleCancel}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="alert-dialog-slide-title">
      {confirmInfo.title || ""}
    </DialogTitle>
    <DialogContent style={{ textAlign: "center" }}>
      {confirmInfo.message || ""}
    </DialogContent>
    <DialogActions>
      <ButtonIcon
        icon={Cancel}
        label={t("common.dialogs.cancel")}
        color={"danger"}
        block={true}
        onClick={handleCancel}
      />
      <ButtonIcon
        icon={Check}
        label={t("common.dialogs.accept")}
        color={"success"}
        block={true}
        onClick={handleSuccess}
      />
    </DialogActions>
  </Dialog>
);
}


const CommentSchema = yup.object().shape({
  comment: yup.string().required()
  });

const SimpleForm = ({
    isConfirming,
    handleCancel,
    submitForm,
    t,
    confirmInfo
}) => {
    return (
      <Form>
        <Dialog
          open={isConfirming}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCancel}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          
          <DialogTitle id="alert-dialog-slide-title">{confirmInfo.title || ''}</DialogTitle>
          <DialogContent style={{ textAlign: "center" }}>
            <Field
              name="comment"
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    id="comment"
                    label={"Comentario"}
                    type={"text"}
                    required
                    fullWidth
                  />
                );
              }}
            />
          </DialogContent>
          <DialogActions>
            <ButtonIcon
              icon={Cancel}
              label={t("common.dialogs.cancel")}
              color={"danger"}
              block={true}
              onClick={handleCancel}
            />
            <ButtonIcon
              icon={Check}
              label={t("common.dialogs.accept")}
              color={"success"}
              block={true}
              onClick={() => {
                submitForm();
              }}
            />
          </DialogActions>
        </Dialog>
      </Form>
    );
}

const SimpleFormFK = withFormik({
    validationSchema: CommentSchema,
    enableReinitialize: true,
    mapPropsToValues: ({ confirmInfo }) => {
      return {
        comment: confirmInfo.comment
      };
    },
    handleSubmit: (values, { props }) => {
      props.handleSuccess(values);
    },
  })(SimpleForm);

export default WithConfirmComment;
