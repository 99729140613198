import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from "classnames";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";

const LanguageSwitcher = ({ classes }) => {
   const { i18n } = useTranslation();
   const [open, setOpen] = useState(false);
   const [anchorEl, setAnchorEl] = useState(null); // Se maneja mejor el anchorEl como estado

   const handleClick = (event) => {
     setAnchorEl(event.currentTarget);
     setOpen(!open);
   };
 
   const handleClose = () => {
     setOpen(false);
   };

   const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      handleClose(); // Cerramos el menú al seleccionar un idioma
   };

   const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);
   const managerClasses = classNames({
     [classes.managerClasses]: true
   });

   // Mostrar el idioma actual
   const currentLang = i18n.language

   return (
      <div>
         <Button
            color="transparent"
            justIcon
            aria-label="Language"
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
            onClick={handleClick}
            className={classes.buttonLink}
         >
            {currentLang}  {/* Mostrar el idioma actual */}
            
            <Hidden mdUp implementation="css">
               <span onClick={handleClick} className={classes.linkText}>                  
               </span>
            </Hidden>
         </Button>
         <Popper
            open={open}
            anchorEl={anchorEl}
            transition
            disablePortal
            placement="bottom-left"
            className={classNames({
               [classes.popperClose]: !open,
               [classes.pooperResponsive]: true,
               [classes.pooperNav]: true
            })}
         >
            {({ TransitionProps, placement }) => (
               <Grow
                  {...TransitionProps}
                  id="menu-list"
                  style={{ transformOrigin: "0 0 0" }}
               >
                  <Paper className={classes.dropdown}>
                     <ClickAwayListener onClickAway={handleClose}>
                        <MenuList role="menu">
                           <MenuItem
                              onClick={() => changeLanguage('en')}
                              className={dropdownItem}
                           >
                              Inglés
                           </MenuItem>
                           <MenuItem
                              onClick={() => changeLanguage('es')}
                              className={dropdownItem}
                           >
                              Español
                           </MenuItem>
                           <MenuItem
                              onClick={() => changeLanguage('br')}
                              className={dropdownItem}
                           >
                              Portugués
                           </MenuItem>
                        </MenuList>
                     </ClickAwayListener>
                  </Paper>
               </Grow>
            )}
         </Popper>
      </div>
   );
};

export default LanguageSwitcher;
