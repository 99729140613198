import React, { useState } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { flatten } from "ramda";

import { CircularProgress } from "@material-ui/core";

import NewProcessRelationshipView from "../../Views/ProcessRelationship/NewProcessRelationshipView";

import { PROCESS_RELATIONSHIP_ELEMENT_TYPES } from "../../constants";
import {
  PROCESS_RELATIONSHIP,
  PROCESS_RELATIONSHIP_BASE_MODELS,
  VALID_VISITS_BY_ELEMENT,
  MASSIVE_LIST_ELEMENTS,
  GEOFENCE_GROUP_ELEMENTS,
  WORK_PLAN_TABLE_FIELDS,
  WORK_PLAN_CHECKLISTS,
  VISITS_FIELD_CONFIGURATION,
  GEOFENCE_ELEMENTS,
} from "./gql/Queries";
import { UPDATE_PROCESS_RELATIONSHIP } from "./gql/Mutations";

import history from "utils/history";
import useGlobal from "../../store";

const EditProcessRelationship = (props) => {
  const { object } = props.location.state;
  const [session] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );
  const {
    data: processRelationship,
    loading: loadingProcessRelationship,
    error: errorProcessRelationship,
  } = useQuery(PROCESS_RELATIONSHIP, {
    variables: {
      _id: object._id,
    },
    fetchPolicy: "network-only",
  });
  const {
    data: baseModels,
    loading: loadingBaseModels,
    error: errorBaseModels,
  } = useQuery(PROCESS_RELATIONSHIP_BASE_MODELS, {
    variables: {
      app: session.user ? session.appRef._id : "",
    },
    fetchPolicy: "network-only",
  });
  const [
    getValidVisitsByElement,
    { data: validVisits, loading: loadingValidVisits, error: errorValidVisits },
  ] = useLazyQuery(VALID_VISITS_BY_ELEMENT);
  const [
    getMassiveListItems,
    {
      data: massiveListItems,
      loading: loadingMassiveListItems,
      error: errorMassiveListItems,
    },
  ] = useLazyQuery(MASSIVE_LIST_ELEMENTS);
  const [
    getGeofenceGroupItems,
    {
      data: geofenceGroupItems,
      loading: loadingGeofenceGroupItems,
      error: errorGeofenceGroupItems,
    },
  ] = useLazyQuery(GEOFENCE_ELEMENTS);
  const [
    getAllMassiveListItems,
    {
      data: AllMassiveListItems,
      loading: loadingAllMassiveListItems,
    },
  ] = useLazyQuery(MASSIVE_LIST_ELEMENTS);
  const [
    getAllGeofenceGroupItems,
    {
      data: allGeofenceGroupItems,
      loading: loadingAllGeofenceGroupItems,
    },
  ] = useLazyQuery(GEOFENCE_ELEMENTS);
  const [
    getTableFieldsByVisits,
    { data: tableFields, loading: loadingTableFields, error: errorTableFields },
  ] = useLazyQuery(WORK_PLAN_TABLE_FIELDS);
  const [
    getCheckFieldsByVisits,
    { data: checkFields, loading: loadingCheckFields, error: errorCheckFields },
  ] = useLazyQuery(WORK_PLAN_CHECKLISTS);
  const [
    getVisitsFieldConfigurationByVisits,
    {
      data: visitsFieldConfiguration,
      loading: loadingVisitsFieldConfiguration,
      error: errorVisitsFieldConfiguration,
    },
  ] = useLazyQuery(VISITS_FIELD_CONFIGURATION);
  const [updateProcessRelationship] = useMutation(UPDATE_PROCESS_RELATIONSHIP, {
    onCompleted: (mutationData) => {
      console.log("mutationData", mutationData);
      history.push("/objects");
    },
  });

  const [currentElementType, setCurrentElementType] = useState(null);
  const [currentElement, setCurrentElement] = useState([]);
  const [paginationUsers, setPaginationUser] = useState({});

  if (
    !loadingBaseModels &&
    !errorBaseModels &&
    !loadingProcessRelationship &&
    !errorProcessRelationship
  ) {
    const {
      massiveLists,
      geofencesGroup,
      userMobiles,
      questionEngineModules,
    } = baseModels;
    return (
      <NewProcessRelationshipView
        app={session.user ? session.appRef._id : ""}
        processRelationship={processRelationship.getProcessRelationship}
        massiveLists={massiveLists}
        geofences={geofencesGroup}
        userMobiles={userMobiles}
        questionEngineModules={questionEngineModules}
        submitSuccess={(values) => {
          const { BaseForm, VisitConfigForm, UsersForm, WorkPlanForm, ReportConfig } = values;

          const tableFields = WorkPlanForm.tableFieldsSelected
            .filter((tableField) => tableField.selected)
            .map((tableField) => ({
              visitId: tableField.visitId,
              moduleId: tableField.moduleId,
              key: tableField.key,
              activityName: tableField.activityName,
            }));
          const checkFields = WorkPlanForm.checklistFieldsSelected
            .filter((checkField) => checkField.selected)
            .map((checkField) => ({
              visitId: checkField.visitId,
              moduleId: checkField.moduleId,
              key: checkField.key,
              activityName: checkField.activityName,
            }));

          const createProcessRelationshipData = {
            app: session.user ? session.appRef._id : "",
            name: BaseForm.name,
            description: BaseForm.description,
            elementsType: BaseForm.elementType,
            elementsList: BaseForm.elements.map((element) => element.value),
            visits: VisitConfigForm.visits,
            visitPonderation: !VisitConfigForm.operationPonderable
              ? undefined
              : VisitConfigForm.visitPonderation,
            operationPonderable: VisitConfigForm.operationPonderable,
            visitWithNA: VisitConfigForm.visitWithNA,
            periodicity: VisitConfigForm.periodicity,
            assignedUserInfo: {
              label: UsersForm.roleName,
              relations: UsersForm.relations
                .map((relation) => ({
                  userId: relation.userId?.value,
                  elementId: relation.elementId,
                  tags: relation.tags,
                }))
                .filter((relation) => !!relation.userId),
            },
            workPlanConfig: WorkPlanForm.solutionModuleId
              ? {
                  solutionModuleId: WorkPlanForm.solutionModuleId.value,
                  tableFields: tableFields.length > 0 ? tableFields : undefined,
                  checklistFields:
                    checkFields.length > 0 ? checkFields : undefined,
                }
              : undefined,
            reportConfig: ReportConfig
          };

          updateProcessRelationship({
            variables: {
              where: {
                _id: processRelationship.getProcessRelationship._id,
                session: session
              },
              data: createProcessRelationshipData,
            },
          });
        }}
        updateValidVisits={(elementType, elements) => {
          if (
            elementType === PROCESS_RELATIONSHIP_ELEMENT_TYPES.MASSIVE_LIST &&
            elements.length > 0
          ) {
            getValidVisitsByElement({
              variables: {
                app: session.user ? session.appRef._id : "",
                massiveListId: elements[0].value,
                keyFieldType: [14, 15],
              },
            });
            getMassiveListItems({
              variables: {
                massiveList: elements[0].value,
                ...paginationUsers
              },
            });
            setCurrentElementType(
              PROCESS_RELATIONSHIP_ELEMENT_TYPES.MASSIVE_LIST
            );
            setCurrentElement(elements[0].value);
          }
          if (
            elementType === PROCESS_RELATIONSHIP_ELEMENT_TYPES.GEOFENCES &&
            elements.length > 0
          ) {
            getValidVisitsByElement({
              variables: {
                app: session.user ? session.appRef._id : "",
                massiveListId: null,
                keyFieldType: [19],
              },
            });
            getGeofenceGroupItems({
              variables: {
                app: session.user ? session.appRef._id : "",
                groupIds: elements.map((element) => element.value),
                archived: false,
                ...paginationUsers
              },
            });
            setCurrentElement(elements.map((element) => element.value));
            setCurrentElementType(PROCESS_RELATIONSHIP_ELEMENT_TYPES.GEOFENCES);
          }
        }}
        isUpdatingValidVisits={loadingValidVisits}
        validVisits={
          !loadingValidVisits && !errorValidVisits && validVisits
            ? validVisits.visitsByKeyField
            : []
        }
        isUpdatingElements={
          loadingGeofenceGroupItems || loadingMassiveListItems
        }
        elementsList={
          currentElementType
            ? currentElementType ===
              PROCESS_RELATIONSHIP_ELEMENT_TYPES.MASSIVE_LIST
              ? !loadingMassiveListItems &&
                !errorMassiveListItems &&
                massiveListItems
                ? massiveListItems.massiveListItems
                : []
              : !loadingGeofenceGroupItems &&
                !errorGeofenceGroupItems &&
                geofenceGroupItems
              ? geofenceGroupItems.geofences.map(geofence => ({
                  ...geofence,
                  value: geofence.name
              }))
              : []
            : []
        }
        allElementsList={
          currentElementType
            ? currentElementType ===
              PROCESS_RELATIONSHIP_ELEMENT_TYPES.MASSIVE_LIST
              ? !loadingAllGeofenceGroupItems &&
                AllMassiveListItems
                ? AllMassiveListItems.massiveListItems
                : []
              : !loadingAllGeofenceGroupItems &&
                allGeofenceGroupItems
              ? allGeofenceGroupItems.geofences.map(geofence => ({
                  ...geofence,
                  value: geofence.name
              }))
              : []
            : []
        }
        paginationUsers={paginationUsers}
        paginationAction={(pagination) => {
          /* if (currentElementType === PROCESS_RELATIONSHIP_ELEMENT_TYPES.MASSIVE_LIST) {
            getMassiveListItems({
              variables: {
                massiveList: currentElement,
                ...pagination
              },
            });
          }else{
            getGeofenceGroupItems({
              variables: {
                app: session.user ? session.appRef._id : "",
                groupIds: currentElement,
                archived: false,
                ...pagination
              },
            });
          }
          setPaginationUser(pagination); */

        }}
        loadingElementsList={
          loadingMassiveListItems || loadingGeofenceGroupItems
        }
        updateSelectedVisitsInfo={(visitIds) => {
          if (visitIds.length > 0) {
            const queryOptions = {
              variables: {
                app: session.user ? session.appRef._id : "",
                visitIds: visitIds,
              },
            };
            getCheckFieldsByVisits(queryOptions);
            getTableFieldsByVisits(queryOptions);
            getVisitsFieldConfigurationByVisits(queryOptions);
          }
        }}
        tableFields={
          !loadingTableFields && !errorTableFields && tableFields
            ? tableFields.workPlanTables
            : []
        }
        checkFields={
          !loadingCheckFields && !errorCheckFields && checkFields
            ? checkFields.workPlanChecklists
            : []
        }
        visitsConfigurations={
          !loadingVisitsFieldConfiguration &&
          !errorVisitsFieldConfiguration &&
          visitsFieldConfiguration
            ? visitsFieldConfiguration.visits
            : []
        }
      />
    );
  }
  if (loadingBaseModels || loadingProcessRelationship) {
    return (
      <div style={style.progressCnt}>
        <CircularProgress style={style.progress} />
      </div>
    );
  }

  if (errorBaseModels || errorProcessRelationship) {
    return (
      <div>
        {errorBaseModels}
        {errorProcessRelationship}
      </div>
    );
  }
};

const style = {
  progress: {
    margin: "16px",
  },
  progressCnt: {
    width: "100px !important",
    marginLeft: "50%",
    marginRight: "50%",
  },
};

export default EditProcessRelationship;
