import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

// styled components
import DivCenter from "../../components/styled-components/DivCenter";
import withStyles from "@material-ui/core/styles/withStyles";
import PreviewButton from '../../components/Reports/PreviewButton';

function VisitsVisorTable({classes, data, handleClick, keyfields}) {
  function filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    
    if (typeof row[id] === 'number') {
      row[id] = row[id].toString();
    }

    return (
        row[id] !== undefined ?
            String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        :
            true
    );
}

  let headers = [
    {
      id: "actions",
      Header: "",
      accessor: "id",
      filterable: false,
      sortable: false,
      Cell: ({value}) => {
        return (
          <DivCenter>
            <PreviewButton
              isReady={true}
              handleClick={() => {
                handleClick(value)
              }}
            />
          </DivCenter>
        );
      },
      maxWidth: 50
    },
    {
      id: "name",
      Header: <DivCenter>Nombre Completo</DivCenter>,
      accessor: "userName",
      filterable: true,
      sortable: false,
      style: { textAlign: "center" },
      minWidth: 150
    },
    {
      id: "emailID",
      Header: <DivCenter>Correo Electronico</DivCenter>,
      accessor: "email",
      filterable: false,
      sortable: false,
      style: { textAlign: "center" },
      minWidth: 150
    },
    {
      id: "checkin",
      Header: <DivCenter>Fecha de entrada</DivCenter>,
      accessor: "checkInDate",
      filterable: false,
      sortable: false,
      style: { textAlign: "center" },
      minWidth: 150
    },
    {
      id: "durationID",
      Header: <DivCenter>Duracion</DivCenter>,
      accessor: "duration",
      filterable: false,
      sortable: false,
      style: { textAlign: "center" },
      minWidth: 150
    },
    {
      id: "checkout",
      Header: <DivCenter>Fecha de salida</DivCenter>,
      accessor: "checkOutDate",
      filterable: false,
      sortable: false,
      style: { textAlign: "center" },
      minWidth: 150
    }
  ];

  keyfields.forEach((keyfield, idx) => {
    headers.push({
      id: "keyfield"+idx,
      Header: <DivCenter>{keyfield.name}</DivCenter>,
      accessor: "keyfields."+keyfield.name,
      filterable: true,
      sortable: false,
      style: { textAlign: "center" },
      minWidth: 150
    });
  });

  return (
    <ReactTable
      data={data}
      columns={headers}
      defaultPageSize={data.length}
      showPaginationTop={false}
      showPaginationBottom={false}
      style={{
        height: "640px" // This will force the table body to overflow and scroll, since there is not enough room
      }}
      className="-striped -highlight"
      defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row) }
    />
  );
};

VisitsVisorTable.propTypes = {
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  keyfields: PropTypes.array.isRequired,
};

const styles = theme => ({
});

export default withStyles(styles)(VisitsVisorTable);
