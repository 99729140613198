import React from 'react'
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import DivCenter from '../../components/styled-components/DivCenter';
import moment from "moment";
import {useTranslation} from 'react-i18next';

const HistoryTable = ({events}) => {
    const { t, } = useTranslation('history'); 
    return (
      <ReactTable
        data={events}
        columns={[
          {
            Header: <DivCenter>Usuario</DivCenter>,
            accessor: "userEmail",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 10
          },
          {
            Header: <DivCenter>Sección</DivCenter>,
            accessor: "feature",
            Cell: d=> {
              return t(`history.elements.${d.original.feature}`)
            },
            filterable: false,
            sortable: false,
            style: { textAlign: "left" },
            minWidth: 10
          },
          {
            Header: <DivCenter>Evento</DivCenter>,
            accessor: "event",
            Cell: d=> {
                return t(`history.event.${d.original.event}`)
            },
            filterable: false,
            sortable: false,
            style: { textAlign: "left" },
            minWidth: 10
          },
          {
            Header: <DivCenter>Elemento</DivCenter>,
            accessor: "elementName",
            filterable: false,
            sortable: false,
            style: { textAlign: "left" },
            minWidth: 10
          },
          {
            Header: <DivCenter>Fecha</DivCenter>,
            accessor: "created",
            Cell: d => {
              return moment(d.original.created.value) 
                .local()
                .format("DD-MM-YY : hh:mm A");
            },
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 10
          }
        ]}
        defaultPageSize={15}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
    );
}

HistoryTable.propTypes = {
    events: PropTypes.array.isRequired,
}

export default HistoryTable;
