export const buildGeneralExecutionSubject = ({visit, keyfield, startDate, endDate}) => {
  return `Ejecución General en ${visit} analizado para ${keyfield} en ${startDate} - ${endDate}`;
}

export const buildGeneralExecutionPDFBody = ({visit, keyfield, startDate, endDate, executionCount}) => {
  return `
    <p>Hola,</p>
    <br>
    <p>Te envío la ejecución general de visitas a ${keyfield} entre las fechas de ${startDate} - ${endDate}.</p>
    <br>
    <p>Envio adjunta la gráfica de ejecución definida como ${executionCount} visitas en este periodo.</p>
  `;
}

export const buildGeneralExecutionXLSBody = ({visit, keyfield, startDate, endDate, executionCount}) => {
  return `
    <p>Hola,</p>
    <br>
    <p>Envío adjunto un XLS con la lista de ejecutadas y la lista de no ejecutadas de la visita ${visit} para el campo clave ${keyfield}.</p>

  `;
}