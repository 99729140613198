import React from 'react';
import PropTypes from 'prop-types';

import Popover from '@material-ui/core/Popover';
import withStyles from "@material-ui/core/styles/withStyles";

function ComingSoon({classes, id, open, anchorEl, handleClose}){
  return (
    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.popoverMessage}>
          <span><b>Próximamente</b></span>
        </div>
      </Popover>
  ) 
}

const styles = theme => ({
  popoverMessage: {
    fontSize: "18px",
    padding: "10px" 
  }
});

ComingSoon.propTypes = {
  id: PropTypes.string, 
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object, 
  handleClose: PropTypes.func.isRequired
};

export default withStyles(styles)(ComingSoon);