import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

// custom components
import Button from "components/CustomButtons/Button.jsx";

// core components
import Tooltip from '@material-ui/core/Tooltip';

// icons
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";

const CancelSubmitEditForm = ({cancelEdit, submitForm, enableSubmit}) => {
  const { t, } = useTranslation('common');

  return (
    <React.Fragment>
      <Tooltip title={t('common.views.cancelSave')}>
        <Button block
                color={'danger'}
                onClick={cancelEdit}>
          <CancelIcon />
        </Button>
      </Tooltip>
      <Tooltip title={t('common.views.submitSave')}>
        <Button block
                color={'success'}
                disabled={!enableSubmit}
                onClick={submitForm}>
          <CheckIcon />
        </Button>
      </Tooltip>
    </React.Fragment>
  );
};

CancelSubmitEditForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  enableSubmit: PropTypes.bool.isRequired,
};

export default CancelSubmitEditForm;
