import React  from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

// styled components
import DivCenter from "../../components/styled-components/DivCenter";
import withStyles from "@material-ui/core/styles/withStyles";

function CombinedScoresTable({classes, keyfields, category, data}){
  if(keyfields === [] || category === null || data === null) 
    return <div></div>;

  const tableData = data.map(item => ({
    ...item,
    category: {
      ...item.category,
      value: item.category.value !== null ? item.category.value.toFixed(2) : 'N/A'
    }
  }));

  let headers = keyfields.map(keyfield => {
    return {
      id: keyfield.name,
      Header: (<DivCenter>{keyfield.name}</DivCenter>),
      accessor: "keyfields."+keyfield.name,
      filterable: false,
      sortable: false,
      style: { textAlign: "center" },
      minWidth: 200
    };
  });

  headers.push({
    id: "visitCount",
    Header: (<DivCenter># de Visitas</DivCenter>),
    accessor: "visitAnswersCount",
    filterable: false,
    sortable: false,
    style: { textAlign: "center" },
    minWidth: 200
  });
  
  headers.push({
    id: "valueColumn",
    Header: (<DivCenter>{category.name}</DivCenter>),
    accessor: "category.value",
    filterable: false,
    sortable: false,
    style: { textAlign: "center" },
    minWidth: 200
  });

  /* headers.push({
    Header: "",
    id: "row",
    maxWidth: 50,
    filterable: false,
    Cell: (row) => {
        return <div>{row.index}</div>;
    }
  }) */

  return (
    
      <ReactTable
        data={tableData}
        columns={headers}
        defaultPageSize={10}        
        showPaginationTop={false}
        showPaginationBottom={true}
        className="-striped -highlight"
        // onPaginationChange={(a,b,c) =>{
        //   console.log(a,b,c)
        // } }
        onFetchData={(state, instance) => {
          console.log(state, instance)
        }}
        
      />
    
  );
};

CombinedScoresTable.propTypes = {
  category: PropTypes.object,
  data: PropTypes.array
};

const styles = theme => ({
  marginTable: {
    marginTop: " 15px"
  },
  scrollableSection: {
    height: "400px",
    overflowY: "auto"
  }
});

export default withStyles(styles)(CombinedScoresTable);