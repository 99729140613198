import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import {useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom'

// core components
import Badge from "components/Badge/Badge.jsx";
import Button from "components/CustomButtons/Button.js";

// icons
import EditIcon from "@material-ui/icons/Edit";
import Dvr from "@material-ui/icons/Dvr";
import AddAlert from "@material-ui/icons/AddAlert";
import LockIcon from '@material-ui/icons/Lock';

// styled components
import DivCenter from "../../components/styled-components/DivCenter";
import useGlobal from '../../store';
import auth from '../../utils/auth';
import { Tooltip } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";

const TriggerQuestionModulesView = ({triggers}) => {
  const [hoverOn, changeHoverOn] = useState(false);
  const { t, } = useTranslation('tickets');

  if (triggers.length) {

    return (
      <div style={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}
        onMouseEnter={() => changeHoverOn(true)}
        onMouseLeave={() => changeHoverOn(false)}>
        {
          hoverOn ? triggers.map((trigger, index) => {
            return (
              <h6 key={index}>{trigger.module.name}</h6>
            );
          }) : <h6>{`${triggers[0].module.name}${triggers.length > 1 ? '...' : ''}`}</h6>
        }
      </div>
    );
  } else {
    return (
      <div>{t('common.views.none')}</div>
    );
  }
};


const TicketListTable = props => {
  const { t, } = useTranslation('tickets');
  const [formattedData, changeFormattedData] = useState([]);
  const { archive } = props;
  useEffect(() => {
    changeFormattedData(props.data.map((item) => {
      return {
        ...item
      }
    }))
  }, [props.data]);

  const [session] = useGlobal((state) => state.session);

  const hasPermission = (permission) => {
    if (
      session.user &&
      !auth.hasPermission(permission, session.permissions)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const getActions = (d) => {
    return (
      <div className="actions-right">
        <Button
          justIcon
          round
          simple
          color="danger"
          onClick={() => {
            props.setModalDelayAlert({
              open: true,
              ticket: d.original._id,
              app: d.original.app,
            });
          }}
        >
          <Tooltip
            id="tooltip-top"
            title={t("tickets.tooltips.alerts")}
            placement="top"
          >
            <AddAlert />
          </Tooltip>
        </Button>

        {hasPermission("ticketsEdit") ? (
          <Button
            justIcon
            round
            simple
            color="info"
            onClick={() => {
              props.setModalSecurity({
                open: true,
                ticket: d.original._id,
                ticketInfo: d.original,
              });
            }}
          >
            <Tooltip
              id="tooltip-top"
              title={t("tickets.tooltips.security")}
              placement="top"
            >
              <LockIcon />
            </Tooltip>
          </Button>
        ) : (
          ""
        )}

        {hasPermission("ticketsEdit") ? (
          <Link
            to={{
              pathname: `/tickets/${d.original._id}/edit`,
              state: {
                ticket: d.original,
              },
            }}
          >
            <Button justIcon round simple color="info">
              <Tooltip
                id="tooltip-top"
                title={t("tickets.tooltips.edit")}
                placement="top"
              >
                <EditIcon />
              </Tooltip>
            </Button>{" "}
          </Link>
        ) : (
          ""
        )}
        {hasPermission("ticketsAdmin") ? (
          <Link
            to={{
              pathname: `/tickets/${d.original._id}/maintenance`,
              state: {
                ticket: d.original,
              },
            }}
          >
            <Button justIcon round simple color="warning">
              <Tooltip
                id="tooltip-top"
                title={t("tickets.tooltips.details")}
                placement="top"
              >
                <Dvr />
              </Tooltip>
            </Button>{" "}
          </Link>
        ) : (
          ""
        )}

        {hasPermission("ticketsEdit") ? (
          <Button
            justIcon
            round
            simple
            color="danger"
            onClick={() => {
              archive(d.original)
            }}
          >
            <Tooltip
              id="tooltip-top"
              title={d.original.archived ? t("tickets.tooltips.unArchive") : t("tickets.tooltips.archive")}
              placement="top"
            >
              <DeleteIcon />
            </Tooltip>
          </Button>
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <ReactTable
      data={formattedData}
      columns={[
        {
          Header: t('tickets.list.columns.nameDescription'),
          accessor: "name",
          filterable: false,
          sortable: false,
          Cell: (d) => {
            return (
              <div>
                <h4>{d.original.name}<br/>
                <small>{d.original.description}</small>
                </h4>
              </div>
            )
          },
          minWidth: 200,
          maxWidth: 400
        },
        {
          Header: (<DivCenter>{t('tickets.list.columns.emails')}</DivCenter>),
          accessor: "emails",
          filterable: false,
          sortable: false,
          Cell: (d) => {
            if (d.row.emails.length) {
              return (
                <div>
                  {d.row.emails.map((email, index) => {
                    return (
                      <React.Fragment key={index}>
                        <small>{email}</small>
                        {index !== d.row.emails.length ? <br/> : []}
                      </React.Fragment>
                    );
                  })}
                </div>
              );
            }else{
              return (
                <div>Ninguno</div>
              );
            }
          },
          style: { textAlign: "center" },
          minWidth: 120,
          maxWidth: 300
        },
        {
          Header: (<DivCenter>{t('tickets.list.columns.triggers')}</DivCenter>),
          accessor: "triggers",
          filterable: false,
          sortable: false,
          Cell: (d) => <TriggerQuestionModulesView triggers={d.row.triggers || []} />,
          style: { textAlign: "center" },
          minWidth: 130,
          maxWidth: 200
        },
        {
          Header: (<DivCenter>{t('tickets.list.columns.finisher')}</DivCenter>),
          accessor: "finisher",
          filterable: false,
          sortable: false,
          Cell: (d) => {
            if (d.row.finisher.module) {
              return (
                <h6>{d.row.finisher.module.name}</h6>
              );
            }else{
              return (
                <div>Ninguno</div>
              );
            }
          },
          style: { textAlign: "center" },
          minWidth: 150,
          maxWidth: 200
        },
        {
          Header: (<DivCenter>{t('tickets.list.columns.pendingAssign')}</DivCenter>),
          accessor: "pendingAssign",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 70,
          maxWidth: 120
        },
        {
          Header: (<DivCenter>{t('tickets.list.columns.pendingReview')}</DivCenter>),
          accessor: "pendingReview",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 70,
          maxWidth: 120
        },
        {
          Header: (<DivCenter>{t('tickets.list.columns.pendingSolve')}</DivCenter>),
          accessor: "pendingSolve",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 70,
          maxWidth: 120
        },
        {
          Header: "",
          id: "actions",
          Cell: (d) => {
            return getActions(d)
          },
          filterable: false,
          sortable: false,
          minWidth: 30,
          minWidth: 200
        }
      ]}
      defaultPageSize={25}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
      style={{
        height: "600px"
      }}
    />
  );
};

TicketListTable.propTypes = {
  data: PropTypes.array.isRequired,
  setModalDelayAlert: PropTypes.func
};

export default TicketListTable;
