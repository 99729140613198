import React, { useState } from 'react';
import QuestionEngineAnswerView from "../QuestionEngineAnswers/QuestionEngineAnswerView";
import ReactTable from 'react-table';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import DivCenter from "components/styled-components/DivCenter";
import Divider from '@material-ui/core/Divider';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Lightbox from 'react-image-lightbox';
import Moment from 'moment';
import {useTranslation} from "react-i18next";

const ActivityDetailView = ({ activity, classes }) => {
    if (activity) {
        return (
          <div className={classes.mainContainer}>
            <h4 className={classes.textTitle}>Información de Plan de Trabajo:</h4>
            <h4 className={classes.center}>{activity.taskName}</h4>
            { activity.solutionAnswer ? <h4 className={classes.textTitle}>SOLUCIÓN DE PDT</h4> : [] }
            
            { activity.solutionAnswer ? (<QuestionEngineAnswerView questionEngineAnswer={activity.solutionAnswer} />) : null }

            <Divider className={classes.divider}/>

            <AnswerInfo activity={activity} classes={classes} />
          </div>
        );
      
    } else {
      return null;
    }
}

const AnswerInfo = ({activity, classes}) => {
    const { answerTaskInfo, reportedByUserRef } = activity;
    const [lightBoxOpen, toggleLightBox] = useState(false);
    const [lightBoxImages, setLightBoxImages] = useState([]);
    const [currentLightBoxImage, setCurrentLightBoxImage] = useState(0);

    const openImageLightBox = (imgInfo) => {
        setLightBoxImages(imgInfo.images);
        setCurrentLightBoxImage(imgInfo.currentImage);
        toggleLightBox(true);
      }

    const { t } = useTranslation("question-engine-module-answer");
    return (
      <div className={classes.mainContainer}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <h4 className={classes.textTitle}>LEVANTAMIENTO DE PDT</h4>
              </GridItem>
              <GridItem xs={6}>
                <h4 className={classes.noMargin}>
                  <small className={classes.normalColor}>Campo:</small>
                  <br />
                  <small className={classes.normalColor}>
                    {activity.answerFieldLabel}
                  </small>
                </h4>
              </GridItem>
              <GridItem xs={6}>
                <h4 className={classes.noMargin}>
                  <small className={classes.normalColor}>
                    {`${Moment(activity.reportedDate).format(
                      "dddd, MMMM Do YYYY, h:mm:ss A"
                    )}`}
                  </small>
                </h4>
              </GridItem>
              <GridItem xs={12} className={classes.marginTop}>
                <h4 className={classes.noMargin}>
                  <small className={classes.normalColor}>
                    Usuario que contesto:
                  </small>
                  <br />
                  <small className={classes.normalColor}>
                    {reportedByUserRef.email}
                  </small>
                </h4>
              </GridItem>

              <GridItem xs={12}>
                <h4 className={classes.textBold}>
                  {t("question-engine-module-answer.view.answers")}
                </h4>
                <Card>
                  <CardBody>
                    {answerTaskInfo.type === "TableField" ? (
                      !!answerTaskInfo.answer && !!answerTaskInfo.field ? (
                        renderTable(answerTaskInfo.field, [
                          answerTaskInfo.answer,
                        ])
                      ) : (
                        <DivCenter>
                          <h5>
                            Informacion de configuracion o datos faltantes
                          </h5>
                        </DivCenter>
                      )
                    ) : (
                      renderCheckList(
                        answerTaskInfo.answer,
                        answerTaskInfo.option,
                        openImageLightBox
                      )
                    )}
                  </CardBody>
                  {lightBoxOpen ? (
                    <Lightbox
                      mainSrc={lightBoxImages[currentLightBoxImage]}
                      nextSrc={
                        lightBoxImages[
                          (currentLightBoxImage + 1) % lightBoxImages.length
                        ]
                      }
                      prevSrc={
                        lightBoxImages[
                          (currentLightBoxImage + lightBoxImages.length - 1) %
                            lightBoxImages.length
                        ]
                      }
                      onCloseRequest={() => toggleLightBox(false)}
                      onMovePrevRequest={() =>
                        setCurrentLightBoxImage(
                          (currentLightBoxImage + lightBoxImages.length - 1) %
                            lightBoxImages.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setCurrentLightBoxImage(
                          (currentLightBoxImage + 1) % lightBoxImages.length
                        )
                      }
                      reactModalStyle={{
                        overlay: {
                          zIndex: 10000,
                        },
                      }}
                    />
                  ) : (
                    []
                  )}
                </Card>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
};

const renderTable = (config, rows) => {
    return (
      <ReactTable
        data={rows}
        columns={config.columns.map((column, idx) => {
          return {
            id: idx,
            Header: <DivCenter>{column.label}</DivCenter>,
            filterable: false,
            sortable: false,
            Cell: ({ original }) => {
              
              let answer;
              Object.keys(original).map(key => {
                answer = Array.isArray(original[column.key]) ? original[column.key].join(',') : original[column.key];
              });

              return answer ? <DivCenter>{answer}</DivCenter> : '';
            },
            minWidth: column.width,
          }
        })}
        defaultPageSize={rows.length}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
    );
};

const renderCheckList = (answer, option, openImageLightBox) => {
    const classes = makeStyles(styles);
    
    const photos = answer?.photoImgs ? [answer.photoImgs] : [];
    const signatures = answer?.signatureImgs ? [answer.signatureImgs] : [];

    const checkListImages = photos.concat(signatures);
    return (
      <GridContainer>
        <GridItem xs={10}>
          <List>
            <ListItem>
              <ListItemAvatar>
                {option.image ? (
                  <Avatar alt={`checkOptionImage`} src={option.image} />
                ) : <div></div>}
              </ListItemAvatar>
              <ListItemText>
                <DivCenter>{answer.name ? answer.name : option.name}</DivCenter>
              </ListItemText>
            </ListItem>
          </List>
        </GridItem>
        <GridItem xs={12}>
          {answer.comment ? (
            <React.Fragment>
              <h6>{`Comentario`}</h6>
              <p>{answer.comment}</p>
            </React.Fragment>
          ) : (
            []
          )}
          {answer.email ? (
            <React.Fragment>
              <h6>{`Email`}</h6>
              <p>{answer.email}</p>
            </React.Fragment>
          ) : (
            []
          )}
        </GridItem>
        <GridItem xs={12}>
          {checkListImages.length > 0 ? (
            <div className={classes.root}>
              <GridList
                cellHeight={150}
                className={classes.gridList}
                cols={checkListImages.length >= 3 ? 3 : checkListImages.length}
              >
                {checkListImages.map((tile, index) => (
                  <GridListTile
                    key={index}
                    cols={1}
                    onClick={() => {
                      openImageLightBox({
                        images: checkListImages.map((img) => img.original),
                        currentImage: index,
                      });
                    }}
                  >
                    <img src={tile.i320} alt={tile.i320} />
                  </GridListTile>
                ))}
              </GridList>
            </div>
          ) : (
            []
          )}
        </GridItem>
        
      </GridContainer>
    );
};

const styles = (theme) => ({
  mainContainer: {
    marginTop: 50,
    padding: 15,
  },
  center: {
    textAlign: "center",
  },
  textTitle: {
      fontWeight: 'bold',
      textAlign: "center",
  },
  divider: {
    height: '4px',
    backgroundColor: 'rgb(0 0 0 / 20%)'
  },
  right:{
    textAlign: "end"
  },
  noMargin: {
    margin: 0
  },
  normalColor: {
    color: 'black'
  },
  textBold: {
    fontWeight: 'bold',
    textAlign: "center"
  },
  marginTop: {
    marginTop: 15
  }
});

export default withStyles(styles)(ActivityDetailView);
