import gql from "graphql-tag";

export const PROCESS_RELATIONSHIP_BASE_MODELS = gql`
  query processRelationshipBaseModels($app: ID! $email_contains: String) {
    geofencesGroup(where: { app: $app, activated: true, archived: false }) {
      _id
      name
    }
    massiveLists(where: { app: $app }) {
      _id
      name
    }
    userMobiles(where: { app: $app, email_contains: $email_contains }) {
      _id
      firstName
      email
    }
    questionEngineModules(
      where: { app: $app, is_on_visit: false, archived: false }
    ) {
      _id
      module {
        _id
        name
      }
    }
  }
`;

export const USERS_MOBILE = gql`
  query userMobiles($app: ID! $email_contains: String) {
    userMobiles(where: { app: $app, email_contains: $email_contains }) {
      _id
      firstName
      email
    }
  }
`;

export const VALID_VISITS_BY_ELEMENT = gql`
  query visitsByKeyField(
    $app: ID!
    $massiveListId: ID
    $keyFieldType: [Int!]!
  ) {
    visitsByKeyField(
      where: {
        app: $app
        massiveList: $massiveListId
        hasKeyFieldType_in: $keyFieldType
        hasChecklistConfiguration: true
        isArchived: false
      }
    ) {
      _id
      name
    }
  }
`;

export const MASSIVE_LIST_ELEMENTS = gql`
  query massiveListItems($massiveList: ID!, $first: Int, $skip: Int) {
    massiveListItems(
      where: { massiveList: $massiveList },
      first: $first, skip: $skip
    ) {
      _id
      value
    }
  }
`;

export const GEOFENCE_GROUP_ELEMENTS = gql`
  query geofencesGroup($app: ID!, $groupIds: [ID!]) {
    geofencesGroup(where: { app: $app, _id_in: $groupIds }) {
      _id
      name
      geofences {
        _id
        name
      }
    }
  }
`;

export const GEOFENCE_ELEMENTS = gql`
  query geofences($app: ID!, $groupIds: [ID!], $archived: Boolean $first: Int, $skip: Int) {
    geofences(where: { 
      app: $app, 
      geofenceGroup_in: $groupIds
      archived: $archived
      }, 
      first: $first, 
      skip: $skip) {
      _id
      name
    }
  }
`;

export const WORK_PLAN_TABLE_FIELDS = gql`
  query workPlanTables($app: ID!, $visitIds: [ID!]!) {
    workPlanTables(where: { app: $app, visitIds: $visitIds }) {
      ... on TableField {
        __typename
        _id
        type
        key
        label
      }
    }
  }
`;

export const WORK_PLAN_CHECKLISTS = gql`
  query workPlanChecklists($app: ID!, $visitIds: [ID!]!) {
    workPlanChecklists(where: { app: $app, visitIds: $visitIds }) {
      _id
      name
    }
  }
`;

export const VISITS_FIELD_CONFIGURATION = gql`
  query visitsFieldConfig($app: ID!, $visitIds: [ID!]!) {
    visits(where: { app: $app, _id_in: $visitIds }) {
      _id
      name
      engines {
        moduleId
        moduleRef {
          _id
          name
          properties
          propertiesRef {
            __typename
            ... on QuestionEngineModule {
              _id
              fields {
                __typename
                ... on TableField {
                  _id
                  type
                  key
                  label
                }
                ... on ChecklistField {
                  _id
                  type
                  key
                  label
                  checkList
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PROCESS_RELATIONSHIPS = gql`
  query getProcessRelationships($where: ProcessRelationshipWhereInput!, $first: Int, $skip: Int) {
    getProcessRelationships(where: $where, first: $first, skip: $skip) {
      _id
      name
      app
      description
      elementsType
      elementsList
      elementsRef
      periodicity
      requisitionConfig {
        _id
        processRelationshipId
        name
        description
        expirationDate
        elementsIds
      }
    }
  }
`;

export const PROCESS_RELATIONSHIP = gql`
  query getProcessRelationship($_id: ID!) {
    getProcessRelationship(where: { _id: $_id }) {
      _id
      app
      name
      description
      elementsType
      elementsList
      visits
      operationPonderable
      periodicity
      visitWithNA
      assignedUserInfo {
        label
        relations {
          userId
          elementId
          tags
        }
      }
      visitPonderation {
        visitId
        ponderation
      }
      workPlanConfig {
        tableFields {
          visitId
          moduleId
          key
          activityName
        }
        checklistFields {
          visitId
          moduleId
          key
          activityName
        }
        solutionModuleId
        penaltiesConfig {
          _id
          indicator
          operation
          value
          percentagePenalty
        }
      }
      reportConfig {
        enable
        subject
        reportType
        weekday
        reportHour
        timezone {
          zone
          utc
        }
        startDate
        endDate
      }
    }
  }
`;

export const PROCESS_RELATIONSHIPS_CALCULATIONS = gql`
  query getProcessRelationshipCalculations(
    $id: ID!
    $created_gt: DateTime!
    $created_lte: DateTime!
  ) {
    getProcessRelationshipCalculations(
      where: { _id: $id, created_gt: $created_gt, created_lte: $created_lte }
    ) {
      processRelationship {
        _id
        periodicity
      }
      periodicityRanges {
        startDate
        endDate
      }
      results {
        _id
        name
        periodicity
      }
      resultsTotal
    }
  }
`;

export const PROCESS_RELATIONSHIPS_CALCULATIONS_DETAIL = gql`
  query getProcessRelationshipCalculations(
    $id: ID!
    $created_gt: DateTime!
    $created_lte: DateTime!
    $element: ID!
  ) {
    getProcessRelationshipCalculations(
      where: {
        _id: $id
        created_gt: $created_gt
        created_lte: $created_lte
        element: $element
      }
    ) {
      processRelationship {
        _id
        periodicity
      }
      periodicityRanges {
        startDate
        endDate
      }
      results {
        _id
        name
        periodicity
      }
      resultsTotal
      penalties
    }
  }
`;

export const WORK_PLAN_INDICATORS = gql`
  query WORK_PLAN_INDICATORS($where: WorkPlanActivityWhereInput) {
    workPlanIndicator(where: $where) {
      usersActivitiesPending
      elementActivitiesPending
      averageActivitiesPending
      totalAcitivites
      activitiesCompleted
    }
  }
`;

export const WORK_PLAN_ELEMENTS_INDICATORS = gql`
  query getElementsIndicators($where: WorkPlanActivityWhereInput) {
    getElementsIndicators(where: $where) {
      _id
      name
      indicators {
        totalPending
        totalPenginDates
        percentagePending
      }
    }
  }
`;

export const WORK_PLAN_ELEMENTS_ACTIVITIES = gql`
  query ELEMENTS_ACTIVITIES($where: WorkPlanActivityWhereInput) {
    getElementsActivities(where: $where) {
      _id
      elementName
      reportedByUserRef {
        firstName
        lastName
        email
        salt
        extra {
          key
          value
        }
      }
      visitId
      visitRef {
        _id
        name
      }
      taskName
      answerFieldKey
      answerFieldLabel
      answerTaskInfo
      status
      reportedDate
      solvedDate
      solutionAnswer {
        _id
        module {
          _id
          name
          propertiesRef {
              __typename
              ... on QuestionEngineModule {
                _id
                fields {
                  __typename
                  ... on OpenField {
                    _id
                    key
                  }
                  ... on NumericField {
                    _id
                    key
                  }
                  ... on DateField {
                    _id
                    key
                  }
                  ... on PhoneField {
                    _id
                    key
                  }
                  ... on EmailField {
                    _id
                    key
                  }
                  ... on PasswordField {
                    _id
                    key
                  }
                  ... on OpenMultilineField {
                    _id
                    key
                  }
                  ... on PhotoReportField {
                    _id
                    key
                  }
                  ... on MapField {
                    _id
                    key
                    completeAddress
                  }
                  ... on SignatureField {
                    _id
                    key
                  }
                  ... on CodeBarField {
                    _id
                    key
                  }
                  ... on ChecklistField {
                    _id
                    key
                  }
                  ... on TokenField {
                    _id
                    key
                  }
                  ... on TableField {
                    _id
                    key
                  }
                  ... on GeofenceField {
                    _id
                    key
                  }
                  ... on ListUniqueField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on ListMultipleField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on MassiveListUniqueField {
                    _id
                    key
                    massiveList
                  }
                  ... on MassiveListMultipleField {
                    _id
                    key
                    massiveList
                  }
                  ... on FileField {
                    _id
                    key
                  }
                }
              }
            }
        }
        userId
        userRef {
          _id
          email
          firstName
          lastName
        }
        answers {
          key
          questionLabel
          answer {
            __typename
            ... on OpenFieldAnswer {
              OpenFieldAnswer: value
            }
            ... on NumericFieldAnswer {
              NumericFieldAnswer: value
            }
            ... on DateFieldAnswer {
              DateFieldAnswer: value
            }
            ... on PhoneFieldAnswer {
              PhoneFieldAnswer: value
            }
            ... on ListUniqueFieldAnswer {
              ListUniqueFieldAnswer: value
              config {
                key
                type
                label
                help
                required
                listStyle
                options {
                  _id
                  value
                  name
                  segment
                  emails
                  image {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
              }
            }
            ... on ListMultipleFieldAnswer {
              ListMultipleFieldAnswer: value
              config {
                key
                type
                label
                help
                required
                listStyle
                options {
                  _id
                  value
                  name
                  segment
                  emails
                  image {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
              }
            }
            ... on EmailFieldAnswer {
              EmailFieldAnswer: value
            }
            ... on PasswordFieldAnswer {
              PasswordFieldAnswer: value
            }
            ... on OpenMultilineFieldAnswer {
              OpenMultilineFieldAnswer: value
            }
            ... on PhotoReportFieldAnswer {
              PhotoReportFieldAnswer: value {
                original
                i1280
                i240
                i320
                i480
                i640
                i960
              }
            }
            ... on MapFieldAnswer {
              longitude
              latitude
              address
            }
            ... on SignatureFieldAnswer {
              SignatureFieldAnswer: value {
                original
                i1280
                i240
                i320
                i480
                i640
                i960
              }
            }
            ... on CodeBarFieldAnswer {
              CodeBarFieldAnswer: value
            }
            ... on MassiveListUniqueFieldAnswer {
              MassiveListUniqueFieldAnswer: value
              config {
                massiveList
              }
            }
            ... on MassiveListMultipleFieldAnswer {
              MassiveListMultipleFieldAnswer: value
              config {
                massiveList
              }
            }
            ... on ChecklistFieldAnswer {
              ChecklistFieldAnswer: id
              comment
              email
              photos {
                original
                i1280
                i240
                i320
                i480
                i640
                i960
              }
              signature {
                original
                i1280
                i240
                i320
                i480
                i640
                i960
              }
            }
            ... on TokenFieldAnswer {
              TokenFieldAnswer: value
            }
            ... on TableFieldAnswer {
              rows {
                answers {
                  key
                  answer {
                    __typename
                    ... on OpenSubFieldAnswer {
                      OpenSubFieldAnswer: value
                    }
                    ... on NumericSubFieldAnswer {
                      NumericSubFieldAnswer: value
                    }
                    ... on DateSubFieldAnswer {
                      DateSubFieldAnswer: value
                    }
                    ... on PhoneSubFieldAnswer {
                      PhoneSubFieldAnswer: value
                    }
                    ... on MassiveListUniqueSubFieldAnswer {
                      MassiveListUniqueSubFieldAnswer: value
                    }
                  }
                }
              }
              config {
                _id
                key
                type
                label
                columns {
                  __typename
                  ... on OpenSubField {
                    _id
                    key
                    type
                    label
                    width
                  }
                  ... on NumericSubField {
                    _id
                    key
                    type
                    label
                    width
                    numericFormatStyle
                  }
                  ... on DateSubField {
                    _id
                    key
                    type
                    label
                    width
                  }
                  ... on PhoneSubField {
                    _id
                    key
                    type
                    label
                    width
                  }
                  ... on MassiveListUniqueSubField {
                    _id
                    key
                    type
                    label
                    width
                  }
                }
              }
            }
            ... on GeofenceFieldAnswer {
              GeofenceFieldAnswer: value
            }
            ... on FileFieldAnswer {
              FileFieldAnswer: files {
                filename
                file
              }
            }
          }
        }
        checklists {
          _id
          app
          name
          options {
            _id
            name
            image {
              i320
              i480
            }
            numericValue
          }
        }
        created
      }
    }
  }
`;

export const QUESTION_ENGINES_MODULES_NO_VISIT = gql`
  query questionEngineModuleNoVisit($app: ID!) {
    questionEngineModules(
      where: { app: $app, is_on_visit: false, archived: false }
    ) {
      _id
      module {
        _id
        name
      }
    }
  }
`;

export const REQUISITIONS = gql`
  query getRequisitionConfig($where: RequisitionConfigWhereInput!) {
    getRequisitionConfig(where: $where) {
      _id
      processRelationshipId
      name
      description
      expirationDate
      elementsIds
      solutionModuleId
      screenId
      images {
        original
        i1280
        i240
        i320
        i480
        i640
        i960
      }
      areaName
      processActivities {
        process
        elementProcess
      }
      expiredSwitch
    }
  }
`;

export const PENALTIES_REQUISITIONS = gql`
  query getPenaltiesRequisition($where: RequisitionPenaltiesWhereInput!) {
    getPenaltiesRequisition(where: $where) {
      _id
      processRelationshipId
      requisitionId
      indicator
      operation
      value
      percentagePenalty
      requisitionRef {
        _id
        name
      }
    }
  }
`;

export const REQUISITION_INDICATORS = gql`
  query getRequisitionIndicator($where: RequisitionActivityWhereInput) {
    getRequisitionIndicator(where: $where){
      openRequisition
      approvedRequisition
      processRequisition
      pendingRequisition
      completedRequisition
    }
  }
`;

export const REQUISITIONS_ACTIVITIES = gql`
  query getRequisitionsActivitiesUnfiltered($where: RequisitionActivityWhereInput) {
    getRequisitionsActivitiesUnfiltered(where: $where) {
      _id
      app
      processRelationshipId
      requisitionId
      requisitionRef {
        _id
        name
        areaName
        description
        images {
          original
          i240
          i320
          i480
          i640
          i960
          i1280
        }
        screenId
        expirationDate
      }
      elementId
      elementName
      assignedUserId
      assignedUserRef {
        firstName
        lastName
      }
      status
      expirationDate
      reportedDate
      approvedDate
      requisitionLogs {
        userId
        userType
        status
        answer
        questionAnswer {
          _id
          module {
            _id
            name
            propertiesRef {
              __typename
              ... on QuestionEngineModule {
                _id
                fields {
                  __typename
                  ... on OpenField {
                    _id
                    key
                  }
                  ... on NumericField {
                    _id
                    key
                  }
                  ... on DateField {
                    _id
                    key
                  }
                  ... on PhoneField {
                    _id
                    key
                  }
                  ... on EmailField {
                    _id
                    key
                  }
                  ... on PasswordField {
                    _id
                    key
                  }
                  ... on OpenMultilineField {
                    _id
                    key
                  }
                  ... on PhotoReportField {
                    _id
                    key
                  }
                  ... on MapField {
                    _id
                    key
                    completeAddress
                  }
                  ... on SignatureField {
                    _id
                    key
                  }
                  ... on CodeBarField {
                    _id
                    key
                  }
                  ... on ChecklistField {
                    _id
                    key
                  }
                  ... on TokenField {
                    _id
                    key
                  }
                  ... on TableField {
                    _id
                    key
                  }
                  ... on GeofenceField {
                    _id
                    key
                  }
                  ... on ListUniqueField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on ListMultipleField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on MassiveListUniqueField {
                    _id
                    key
                    massiveList
                  }
                  ... on MassiveListMultipleField {
                    _id
                    key
                    massiveList
                  }
                  ... on FileField {
                    _id
                    key
                  }
                }
              }
            }
          }
          userId
          userRef {
            _id
            email
            firstName
            lastName
          }
          answers {
            key
            questionLabel
            answer {
              __typename
              ... on OpenFieldAnswer {
                OpenFieldAnswer: value
              }
              ... on NumericFieldAnswer {
                NumericFieldAnswer: value
              }
              ... on DateFieldAnswer {
                DateFieldAnswer: value
              }
              ... on PhoneFieldAnswer {
                PhoneFieldAnswer: value
              }
              ... on ListUniqueFieldAnswer {
                ListUniqueFieldAnswer: value
                config {
                  key
                  type
                  label
                  help
                  required
                  listStyle
                  options {
                    _id
                    value
                    name
                    segment
                    emails
                    image {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                }
              }
              ... on ListMultipleFieldAnswer {
                ListMultipleFieldAnswer: value
                config {
                  key
                  type
                  label
                  help
                  required
                  listStyle
                  options {
                    _id
                    value
                    name
                    segment
                    emails
                    image {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                }
              }
              ... on EmailFieldAnswer {
                EmailFieldAnswer: value
              }
              ... on PasswordFieldAnswer {
                PasswordFieldAnswer: value
              }
              ... on OpenMultilineFieldAnswer {
                OpenMultilineFieldAnswer: value
              }
              ... on PhotoReportFieldAnswer {
                PhotoReportFieldAnswer: value {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on MapFieldAnswer {
                longitude
                latitude
                address
              }
              ... on SignatureFieldAnswer {
                SignatureFieldAnswer: value {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on CodeBarFieldAnswer {
                CodeBarFieldAnswer: value
              }
              ... on MassiveListUniqueFieldAnswer {
                MassiveListUniqueFieldAnswer: value
                config {
                  massiveList
                }
              }
              ... on MassiveListMultipleFieldAnswer {
                MassiveListMultipleFieldAnswer: value
                config {
                  massiveList
                }
              }
              ... on ChecklistFieldAnswer {
                ChecklistFieldAnswer: id
                comment
                email
                photos {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
                signature {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on TokenFieldAnswer {
                TokenFieldAnswer: value
              }
              ... on TableFieldAnswer {
                rows {
                  answers {
                    key
                    answer {
                      __typename
                      ... on OpenSubFieldAnswer {
                        OpenSubFieldAnswer: value
                      }
                      ... on NumericSubFieldAnswer {
                        NumericSubFieldAnswer: value
                      }
                      ... on DateSubFieldAnswer {
                        DateSubFieldAnswer: value
                      }
                      ... on PhoneSubFieldAnswer {
                        PhoneSubFieldAnswer: value
                      }
                      ... on MassiveListUniqueSubFieldAnswer {
                        MassiveListUniqueSubFieldAnswer: value
                      }
                    }
                  }
                }
                config {
                  _id
                  key
                  type
                  label
                  columns {
                    __typename
                    ... on OpenSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on NumericSubField {
                      _id
                      key
                      type
                      label
                      width
                      numericFormatStyle
                    }
                    ... on DateSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on PhoneSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on MassiveListUniqueSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                  }
                }
              }
              ... on GeofenceFieldAnswer {
                GeofenceFieldAnswer: value
              }
              ... on FileFieldAnswer {
                FileFieldAnswer: files {
                  filename
                  file
                }
              }
            }
          }
          checklists {
            _id
            app
            name
            options {
              _id
              name
              image {
                i320
                i480
              }
              numericValue
            }
          }
          created
        }
        comment
        created
      }
      archived
    }
  }
`;

export const EMAIL_REPORTS= gql`
  query EMAIL_REPORTS($where: EmailProcessRelationshipReportWhereInput, $first: Int, $skip: Int) {
    getEmailProcessRelationshipReports(where: $where, first: $first, skip: $skip) {
      _id
      app
      query
      action
      name
      processRelationshipId
      created
    }
  }
`;

export const GET_VISITS_BY_APP= gql`
  query Visits($where: VisitWhereInput!) {
    visits(where: $where) {
      _id
      name
      keys
    }
  }
`;