import React from 'react';
import {compose} from "recompose";
import {useTranslation} from 'react-i18next';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "../CustomButtons/Button";
// styles
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";
// utils
import {getNamespacesFromKeys} from "../../utils/translation/namespaces";

const ButtonIcon = ({classes, label, icon, ...props}) => {
  const {t,} = useTranslation(getNamespacesFromKeys([label]));
  const Icon = icon;

  return (
    <Button className={classes.marginRight} {...props}>
      <Icon className={classes.icons}/> {t(label)}
    </Button>
  );
};

const styles = {
  ...buttonsStyle
};

export default compose(
  withStyles(styles),
)(ButtonIcon);

