import Tickets from "../containers/Tickets/tickets.jsx";
import NewTicket from "../containers/Tickets/new.jsx";
import EditTicket from "../containers/Tickets/edit.jsx";
import MaintenanceTicket from "../containers/Tickets/maintenance.jsx";
import AdminApprovalItems from "../containers/Visits/adminApprovalItems.jsx";
import UserApprovalItems from "../containers/Visits/userApprovalItems.jsx";
import GalleryVisorItem from "../containers/Visits/GalleryVisorItem.jsx";
import VisitApprovalItems from "../containers/Visits/visitApprovalItems";
import VisitAnswerApprovalItem from "../containers/Visits/visitAnswerApprovalItem";
import VisitsDashboardItem from "../containers/Visits/visitsDashboardItem";
import VisitsDashboardKeyfieldItem from "../containers/Visits/visitsDashboardKeyfieldItem";
import VisitsDashboardCategoryItem from "../containers/Visits/visitsDashboardCategoryItem";
import VisitsVisorItem from "../containers/Visits/VisitsVisorItem.jsx";
import AdminDataItem from "../containers/Visits/adminDataItem";
import TablesVisorItem from "../containers/Visits/tablesVisorItem.jsx";
// @material-ui/icons
//prueba
import GroupWork from "@material-ui/icons/GroupWork";
import ExploreIcon from "@material-ui/icons/Explore";
import BubbleChartIcon from "@material-ui/icons/InsertChart";
import OpportunityAreasItem from "../containers/Visits/opportunityAreasItem.jsx";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TechManual from "../containers/Tutorials/TechManual.jsx";
import Dashboard from "../containers/Dashboard/Dashboard";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import PieChartIcon from "@material-ui/icons/PieChart";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import Reports from "../containers/Reports/Reports.jsx";
import TableChartIcon from "@material-ui/icons/TableChart";
import History from "../containers/History/History.jsx";
import TimelineIcon from "@material-ui/icons/Timeline";
import Users from "../containers/Users/Users.jsx";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import MessageContent from "../containers/Messages/MessageContent.jsx";
import GeneralMessages from "../containers/Messages/GeneralMessages.jsx";
import CustomMessages from "../containers/Messages/CustomMessages.jsx";
import ChecklistAnalisysVisitReport from "../containers/Reports/ChecklistAnalisysVisitReport.jsx";
import ProcessRelationshipList from "../containers/ProcessRelationship/ProcessRelationshipList.jsx";
import NewCheckListAnalisysVisitReport from "../containers/Reports/NewCheckListAnalisysVisitReport.jsx";
import NewProcessRelationship from "../containers/ProcessRelationship/NewProcessRelationship.jsx";
import EditProcessRelationship from "../containers/ProcessRelationship/EditProcessRelationship";
import UpdateChecklistAnalisysVisitReport from "../containers/Reports/UpdateChecklistAnalisysVisitReport.jsx";
import ListAltIcon from "@material-ui/icons/ListAlt";

import LocationsMap from "../containers/Locations/LocationsMap/locationsMap";
import VisitAnswersList from "../containers/Reports/VisitAnswersList.jsx";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import HistogramVisit from "../containers/Visits/HistogramVisit.js";
import ChronologicalReport from "../containers/Reports/ChronologicalReport.jsx";
import CounterReportList from "../containers/SO/CounterReportList.jsx";
import CounterReportNew from "../containers/SO/CounterReportNew.jsx";
import CounterReportEdit from "../containers/SO/CounterReportEdit.jsx";
import CounterReport from "../containers/SO/CounterReport.jsx";

import ChecklistReportList from "../containers/SO/ChecklistReport/ChecklistReportList.jsx";
import ChecklistReportNew from "../containers/SO/ChecklistReportNew.jsx";
import ChecklistReportEdit from "../containers/SO/ChecklistReport/ChecklistReportEdit.jsx";
import ChecklistReport from "../containers/SO/ChecklistReport/ChecklistReport.jsx";

import TabulatorProcessRelationship from "../containers/ProcessRelationship/TabulatorProcessRelationship.jsx";
import DetailProcessRelationship from "../containers/ProcessRelationship/DetailProcessRelationship.jsx";
import WorkPlanAdmin from "../containers/ProcessRelationship/WorkPlanAdmin.jsx";
import ElementsActivities from "../containers/ProcessRelationship/ElementsActivities.jsx";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import NewRequisitionConfig from "../containers/ProcessRelationship/NewRequisitionConfig.jsx";
import RequisitionsAdmin from "../containers/ProcessRelationship/RequisitionsAdmin.jsx";
import EditRequisition from "../containers/ProcessRelationship/EditRequisition.jsx";
import RequisitionList from "../containers/ProcessRelationship/RequisitionList.jsx";
import VisitSchedulingList from "../containers/VisithScheduling/VisitSchedulingList";
import VisitSchedulingNew from "../containers/VisithScheduling/VisitSchedulingNew";
import VisitSchedulingEdit from "../containers/VisithScheduling/VisitSchedulingEdit";
import VisitSchedulingTabulator from "../containers/VisithScheduling/VisitSchedulingTabulator";
import VisitSchedulingTabulatorDetail from "../containers/VisithScheduling/VisitSchedulingTabulatorDetail";

const {
  LINK_WIKI,
  LINK_WORKSHP_MANUAL,
  LINK_SLA_BEEPQUEST,
  LINK_BEEPQUEST_ACADEMY,
} = window._env_;

const dashRoutes = [
  {
    collapse: true,
    path: "/dashboard",
    id: "reports-menu",
    name: "Reportes",
    mini: "PC",
    state: "openReports",
    icon: PieChartIcon,
    views: [
      {
        show: true,
        path: "/analytics",
        name: "analyticsReport",
        mini: "IG",
        component: Dashboard,
        icon: TimelineIcon,
      },
      {
        show: true,
        path: "/chronologicalReport",
        name: "chronologicalReport",
        mini: "RC",
        component: ChronologicalReport,
        icon: TimelineIcon,
      },
      {
        show: true,
        path: "/users",
        name: "userReport",
        mini: "RU",
        component: Users,
        icon: AccountBoxIcon,
      },
      {
        show: true,
        path: "/reports",
        name: "generateReport",
        mini: "RG",
        component: Reports,
        icon: TableChartIcon,
      },
      {
        show: true,
        path: "/history",
        name: "history",
        mini: "H",
        component: History,
        icon: TimelineIcon,
      },
      {
        show: false,
        path: "/message/content",
        name: "detailMessage",
        component: MessageContent,
      },
      {
        show: false,
        path: "/message/general",
        name: "generalMessage",
        mini: "MG",
        component: GeneralMessages,
      },
      {
        show: false,
        path: "/message/custom",
        name: "customMessage",
        mini: "MP",
        component: CustomMessages,
      },
      {
        show: false,
        path: "/new/checklistAnalisysVisit",
        name: "checklistAnalisysVisitReport",
        mini: "NRE",
        component: NewCheckListAnalisysVisitReport,
      },
      {
        show: false,
        path: "/edit/checklistAnalisysVisit",
        name: "checklistAnalisysVisitReport",
        mini: "NRE",
        component: UpdateChecklistAnalisysVisitReport,
      },
      {
        show: false,
        path: "/list/visitAnswers",
        name: "visitanswers",
        mini: "VA",
        component: VisitAnswersList,
      },
      {
        path: "/objects/:id/edit",
        name: "editObject",
        mini: "TE",
        component: EditProcessRelationship,
        show: false,
      },
      {
        show: false,
        path: "/objects/:id/tabulator",
        name: "tabulatorProcessRelationship",
        mini: "TPR",
        component: TabulatorProcessRelationship,
      },
      {
        show: false,
        path: "/objects/new",
        name: "processRelationship",
        mini: "NPR",
        component: NewProcessRelationship,
      },
      {
        show: false,
        path: "/object/:id/detail",
        name: "detailProcessRelationship",
        mini: "DPR",
        component: DetailProcessRelationship,
      },
      {
        show: false,
        path: "/objects/:id/workPlan",
        name: "workPlan",
        mini: "WP",
        component: WorkPlanAdmin,
      },
      {
        show: false,
        path: "/objects/:id/element/:elementId",
        name: "workPlan",
        mini: "WP",
        component: ElementsActivities,
      },
      {
        show: false,
        path: "/objects/:id/requisition/new",
        name: "newRequisition",
        mini: "NR",
        component: NewRequisitionConfig,
      },
      {
        show: false,
        path: "/objects/:id/requisition/list",
        name: "listRequisition",
        mini: "NR",
        component: RequisitionsAdmin,
      },
      {
        show: false,
        path: "/objects/:id/requisition/edit/:requisitionId",
        name: "editRequisition",
        mini: "NR",
        component: EditRequisition,
      },
      {
        show: false,
        path: "/objects/:id/requisition/view/:requisitionId",
        name: "viewRequisition",
        mini: "VR",
        component: RequisitionList,
      },
    ],
  },
  {
    collapse: false,
    path: "/checklistAnalisysVisit",
    name: "Central de Reportes",
    mini: "CR",
    component: ChecklistAnalisysVisitReport,
    icon: ListAltIcon,
    views: [],
  },
  {
    collapse: false,
    path: "/objects",
    name: "Objetos",
    mini: "OC",
    component: ProcessRelationshipList,
    icon: DonutLargeIcon,
    views: [],
  },
  {
    collapse: false,
    path: "/visits/:idVisit/dashboard",
    name: "Dashboard de Checklist",
    mini: "DC",
    component: VisitsDashboardItem,
    icon: BubbleChartIcon,
    views: [],
  },
  {
    collapse: true,
    path: "/so",
    id: "standard-operation-menu",
    name: "Delta Operativo",
    state: "openSO",
    icon: GroupWork,
    views: [
      {
        path: "/so/counterReport/new",
        name: "counterReportNew",
        mini: "NCR",
        component: CounterReportNew,
        show: false,
      },
      {
        path: "/so/counterReport/:id/edit",
        name: "counterReportEdit",
        mini: "ECR",
        component: CounterReportEdit,
        show: false,
      },
      {
        path: "/so/counterReport/:id/view",
        name: "counterReportView",
        mini: "VCR",
        component: CounterReport,
        show: false,
      },
      {
        path: "/so/counterReport",
        name: "counterReport",
        id: "counter-report-submenu",
        mini: "EC",
        component: CounterReportList,
        show: true,
      },
      {
        path: "/so/checklistReport/new",
        name: "checklistReportDetails",
        mini: "NCR",
        component: ChecklistReportNew,
        show: false,
      },
      {
        path: "/so/checklistReport/:id/edit",
        name: "checklistReportEdit",
        mini: "ECR",
        component: ChecklistReportEdit,
        show: false,
      },
      {
        path: "/so/checklistReport/:id/view",
        name: "checklistReportView",
        mini: "VCR",
        component: ChecklistReport,
        show: false,
      },
      {
        path: "/so/checklistReport",
        name: "checklistReport",
        id: "checklist-report-submenu",
        mini: "CS",
        component: ChecklistReportList,
        show: true,
      },
    ],
  },
  {
    collapse: true,
    path: "/tickets",
    id: "incidences-menu",
    name: "Incidencias",
    state: "openTickets",
    icon: ConfirmationNumberIcon,
    views: [
      {
        path: "/tickets/all",
        name: "listTicket",
        mini: "T",
        component: Tickets,
        show: true,
      },
      {
        path: "/tickets/new",
        name: "newTicket",
        mini: "NT",
        component: NewTicket,
        show: true,
      },
      {
        path: "/tickets/:id/edit",
        name: "editTicket",
        mini: "TE",
        component: EditTicket,
        show: false,
      },
      {
        path: "/tickets/:id/maintenance",
        name: "maintenanceTicket",
        mini: "TM",
        component: MaintenanceTicket,
        show: false,
      },
    ],
  },
  {
    collapse: true,
    path: "/visits",
    id: "visits-menu",
    name: "Visitas",
    state: "openVisits",
    icon: MeetingRoomIcon,
    views: [
      {
        path: "/visits/approval/all",
        name: "userApprovalItems",
        mini: "AP",
        component: UserApprovalItems,
        show: true,
      },
      {
        path: "/visits/approval/admin",
        name: "adminApprovalItems",
        mini: "AA",
        component: AdminApprovalItems,
        permission: "approvalItemsAdmin",
        show: true,
      },
      {
        path: "/visits/:idKeyfield/keyfield/:idVisit/dashboard",
        name: "visitsDashboardKeyfield",
        mini: "DC",
        component: VisitsDashboardKeyfieldItem,
        show: false,
      },
      {
        path: "/visits/:idCategory/category/:idVisit/dashboard",
        name: "visitsDashboardCategory",
        mini: "DC",
        component: VisitsDashboardCategoryItem,
        show: false,
      },
      {
        path: "/visits/:idVisit/adminData",
        name: "adminData",
        mini: "AD",
        component: AdminDataItem,
        show: false,
      },
      {
        path: "/visits/:idVisit/visitsVisor",
        name: "visitsVisor",
        mini: "VA",
        component: VisitsVisorItem,
        show: false,
      },
      {
        path: "/visits/:id/approval",
        name: "visitApprovalItems",
        mini: "VA",
        component: VisitApprovalItems,
        show: false,
      },
      {
        path:
          "/visits/:visitId/visitAnswer/:visitAnswerId/approve/:approveItemId",
        name: "visitAnswerApprovalItem",
        mini: "VA",
        component: VisitAnswerApprovalItem,
        show: false,
      },
      {
        path: "/visits/:idVisit/opportunityAreas",
        name: "opportunityAreas",
        mini: "OA",
        component: OpportunityAreasItem,
        show: false,
      },
      {
        path: "/visits/:idVisit/galleryVisor",
        name: "galleryVisor",
        mini: "GV",
        component: GalleryVisorItem,
        show: false,
      },
      {
        path: "/visits/:idVisit/tablesVisor",
        name: "tablesVisor",
        mini: "TV",
        component: TablesVisorItem,
        show: false,
      },
      {
        path: "/visits/:idVisit/histogram",
        name: "histogram",
        mini: "H",
        component: HistogramVisit,
        show: false,
      },

      // Visit scheduling
      {
        path: "/visitScheduling/new",
        name: "visitSchedulingNew",
        mini: "S",
        component: VisitSchedulingNew,
        show: false,
      },
      {
        path: "/visitScheduling/:id/edit",
        name: "visitSchedulingEdit",
        mini: "S",
        component: VisitSchedulingEdit,
        show: false,
      },
      {
        path: "/visitScheduling/:id/tabulator",
        name: "visitSchedulingTabulator",
        mini: "S",
        component: VisitSchedulingTabulator,
        show: false,
      },
      {
        path: "/visitScheduling/:id/tabulatorDetail",
        name: "visitSchedulingTabulatorDetail",
        mini: "S",
        component: VisitSchedulingTabulatorDetail,
        show: false,
      },
      {
        path: "/visitScheduling",
        name: "visitScheduling",
        mini: "S",
        component: VisitSchedulingList,
        show: true,
      },
    ],
  },
  {
    collapse: false,
    path: "/locations",
    id: "locations-menu",
    name: "Localizaciones",
    mini: "L",
    component: LocationsMap,
    icon: ExploreIcon,
    views: [],
  },
  {
    collapse: false,
    path: LINK_BEEPQUEST_ACADEMY,
    id: "academy-menu",
    name: "BeepQuest Academy",
    mini: "BA",
    link: true,
    icon: VideoLibraryIcon,
    views: [],
  },
  {
    collapse: true,
    path: "/resourses",
    id: "resources-menu",
    name: "Recursos",
    state: "openResourses",
    icon: AssignmentIcon,
    views: [
      {
        path: LINK_WORKSHP_MANUAL,
        name: "workshop",
        mini: "WS",
        show: true,
        link: true,
      },
      {
        path: LINK_WIKI,
        name: "wiki",
        mini: "WK",
        show: true,
        link: true,
      },
      {
        path: "/tutorials/techmanual",
        name: "techManual",
        mini: "MT",
        component: TechManual,
        show: true,
      },
      {
        path: LINK_SLA_BEEPQUEST,
        name: "bq",
        mini: "SLA's",
        show: true,
        link: true,
      },
    ],
  },
  { redirect: true, path: "/", pathTo: "/analytics", name: "Dashboard" },
];

export default dashRoutes;
