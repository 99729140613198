import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";

// @material-ui/icons
import Person from "@material-ui/icons/Person";

// core components
import Button from "components/Button";

import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";
import LanguageSwitcher from "./LanguageSwitcher";

let anchorEl = null;

function HeaderLinks({ classes, history }) {
  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen(!open);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleLogOut(){
    handleClose();
    history.replace('login');
  }

  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });

  return (
    <div>
      <div className={managerClasses}>

        <div style={{ float: "right", marginTop: 8}}>
          <LanguageSwitcher classes={classes} />
        </div>


        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={open ? "menu-list" : null}
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.buttonLink}
          buttonRef={node => {
            anchorEl = node;
          }}
        >
          <Person className={classes.headerLinksSvg} />
          <Hidden mdUp implementation="css">
            <span onClick={handleClick} className={classes.linkText}>
              {/* {"Profile"} */}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorEl}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !open,
            [classes.pooperResponsive]: true,
            [classes.pooperNav]: true
          })}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleLogOut}
                      className={dropdownItem}
                    >
                      {"Log Out"}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(headerLinksStyle)(HeaderLinks);
