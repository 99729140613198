import React from 'react';
import ReactTable from "react-table";
import DivCenter from "../../components/styled-components/DivCenter";
import moment from "moment";
import DescriptionIcon from '@material-ui/icons/Description';
import Button from 'components/Button';

const EmailReportTable = ({
    emailReports,
    generateReport
}) => {
    return (
      <ReactTable
        data={emailReports}
        columns={[
          {
            Header: <DivCenter>Elementos</DivCenter>,
            accessor: "elements",
            Cell: ({original}) => {
                const { query } = original;
                if (query?.elements) {
                    return query?.elements?.map(element => element.name);
                }

                return '';
            },
            filterable: false,
            sortable: false,
            style: {
              textAlign: "left",
              whiteSpace: "unset",
            },
            minWidth: 30,
          },
          {
            Header: <DivCenter>Usuario</DivCenter>,
            accessor: "user",
            Cell: ({original}) => {
                const { query } = original;
                if (query?.user) {
                    return query?.user;
                }

                return '';
            },
            filterable: false,
            sortable: false,
            style: {
              textAlign: "left",
              whiteSpace: "unset",
            },
            minWidth: 30,
            maxWidth: 500
          },
          {
            id: "created",
            Header: <DivCenter>Fecha de envio</DivCenter>,
            accessor: (d) => {
              return moment(d.created).local().format("DD-MM-YY : hh:mm:ss A");
            },
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 30,
            maxWidth: 500
          },
          {
            Header: <DivCenter>Generar y Descargar Reporte</DivCenter>,
            accessor: "_id",
            Cell: ({original}) => {              
              return (
                <DivCenter>
                  <Button
                    justIcon
                    size="sm"
                    color={"info"}
                    onClick={() =>{
                      generateReport(original._id)
                    }}
                  >
                    <DescriptionIcon />
                  </Button>
                </DivCenter>
              );
            },
            filterable: false,
            sortable: false,
            style: { textAlign: "left", whiteSpace: "unset" },
            maxWidth: 500,
          },
        ]}
        defaultPageSize={15}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
    );
}

const styles = {
  linkColor: {
    color: "inherit",
  }
}

export default EmailReportTable;