import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";

function Position({ ...props }) {
  const { classes, children, left, right } = props;

  const position = typeof left !== "undefined" && left ? "left" : "right";

  return (
    <div className={classes.list}>
      <div className={classes[position]}>{children}</div>
    </div>
  );
}

const flexProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};

const styles = theme => ({
  list: {
    display: "flex"
  },
  left: {
    marginRight: "auto",
    order: 2,
    ...flexProperties
  },
  right: {
    marginLeft: "auto",
    order: 2,
    ...flexProperties
  }
});

Position.propTypes = {
  classes: PropTypes.object.isRequired,
  left: PropTypes.bool,
  right: PropTypes.bool
};

export default withStyles(styles)(Position);
