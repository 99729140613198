export const buildAverageAnalysisSubject = ({keyfield, visit}) => {
  return `Análisis de Promedio para ${keyfield} en ${visit}`;
}

export const buildAverageAnalysisPDFBody = ({keyfield, visit}) => {
  return `
    <p>Hola,</p>
    <br>
    <p>Envío adjunto un PDF con la información general de ${visit} para ${keyfield} en donde encontrarás:</p>
    <br>
    <ul>
      <li>Conteo de visitas y promedio por ${keyfield}</li>
      <li>Ranking global de ${keyfield} en el rango de fechas</li>
      <li>Análisis tabulado por categorías</li>
    </ul>
    <br>
  `;
}

export const buildAverageAnalysisXLSBody = ({keyfield, visit}) => {
  return `
    <p>Hola,</p>
    <br>
    <p>Envío adjunto un XLS con la información general de ${visit} para ${keyfield} en donde encontrarás:</p>
    <br>
    <ul>
      <li>Conteo de visitas y promedio por ${keyfield}</li>
      <li>Ranking global de ${keyfield} en el rango de fechas</li>
      <li>Análisis tabulado por categorías</li>
    </ul>
    <br>
  `;
}