import React from 'react'
import LastUsersRegisterTable from '../../tables/Dashboard/LastUsersRegisterTable';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import TablePagination from '../../pagination/table/TablePagination';
import { CircularProgress } from '@material-ui/core';

const UsersView = ({ infoUsers, paginationAction, userMobileFilter }) => {
    const { loading, users } = infoUsers;
    return (
      <Card>
        <CardBody>
          <TablePagination
            data={users.userMobiles ? users.userMobiles : []}
            paginationAction={paginationAction}
            filter={userMobileFilter}
          />

          {loading ? (
            <div style={styles.progressCnt}>
              <CircularProgress style={styles.progress} />
            </div>
          ) : (
            <LastUsersRegisterTable
              users={users.userMobiles ? users.userMobiles : []}
              pageSize={15}
            />
          )}
        </CardBody>
      </Card>
    );
}
const styles = {
  progress: {
    margin: '16px'
  },
  progressCnt:{
    width: '100px !important',
    marginLeft: '50%',
    marginRight: '50%'
  },
};
export default UsersView;