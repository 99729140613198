import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import { useTranslation } from "react-i18next";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

const ChecklistAnalisysTypeStep = (props) => {
  const { t } = useTranslation("checklistAnalisys");
  const { classes, setConfigReport, configReport } = props;
  const [selectType, setSelectType] = useState({
    ExitVisit: true,
    SummaryVisit: false,
    CustomVisit: false,
    ChecklistHistory: false
  });

  const handleChange = (name) => (event) => {
    let newState = selectType;
    for (const key in newState) {
      if (key !== name) {
        newState[key] = false;
      } else {
        newState[key] = true;
      }
    }

    setConfigReport({ ...configReport, type: name });
    setSelectType({ ...newState });
  };

  return (
    <div>
      <h4 className={classes.infoText}>{t("checklistAnalisys.wizar.reportType")}</h4>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <GridContainer>
            <GridItem xs={12} sm={3}>
              <div className={classes.choiche}>
                <Checkbox
                  checked={selectType.ExitVisit}
                  tabIndex={-1}
                  onChange={handleChange("ExitVisit")}
                  checkedIcon={
                    <i
                      className={
                        "fas fa-clipboard-check " + classes.iconCheckboxIcon
                      }
                    />
                  }
                  icon={
                    <i
                      className={
                        "fas fa-clipboard-check " + classes.iconCheckboxIcon
                      }
                    />
                  }
                  classes={{
                    checked: classes.iconCheckboxChecked,
                    root: classes.iconCheckbox,
                  }}
                />
                <h6>{t("checklistAnalisys.wizar.type.exitVisit.title")}</h6>
                <p>{t("checklistAnalisys.wizar.type.exitVisit.description")}</p>
              </div>
            </GridItem>
            <GridItem xs={12} sm={3}>
              <div className={classes.choiche}>
                <Checkbox
                  checked={selectType.SummaryVisit}
                  tabIndex={-1}
                  onChange={handleChange("SummaryVisit")}
                  checkedIcon={
                    <i
                      className={"fas fa-chart-bar " + classes.iconCheckboxIcon}
                    />
                  }
                  icon={
                    <i
                      className={"fas fa-chart-bar " + classes.iconCheckboxIcon}
                    />
                  }
                  classes={{
                    checked: classes.iconCheckboxChecked,
                    root: classes.iconCheckbox,
                  }}
                />
                <h6>{t("checklistAnalisys.wizar.type.summaryVisit.title")}</h6>
                <p>{t("checklistAnalisys.wizar.type.summaryVisit.description")}</p>
              </div>
            </GridItem>
            <GridItem xs={12} sm={3}>
              <div className={classes.choiche}>
                <Checkbox
                  checked={selectType.CustomVisit}
                  tabIndex={-1}
                  onChange={handleChange("CustomVisit")}
                  checkedIcon={
                    <i
                      className={"fas fa-clipboard-list " + classes.iconCheckboxIcon}
                    />
                  }
                  icon={
                    <i
                      className={"fas fa-clipboard-list " + classes.iconCheckboxIcon}
                    />
                  }
                  classes={{
                    checked: classes.iconCheckboxChecked,
                    root: classes.iconCheckbox,
                  }}
                />
                <h6>{t("checklistAnalisys.wizar.type.customVisit.title")}</h6>
                <p>{t("checklistAnalisys.wizar.type.customVisit.description")}</p>
              </div>
            </GridItem>
            <GridItem xs={12} sm={3}>
              <div className={classes.choiche}>
                <Checkbox
                  checked={selectType.ChecklistHistory}
                  tabIndex={-1}
                  onChange={handleChange("ChecklistHistory")}
                  checkedIcon={
                    <i
                      className={"fas fa-tasks " + classes.iconCheckboxIcon}
                    />
                  }
                  icon={
                    <i
                      className={"fas fa-tasks " + classes.iconCheckboxIcon}
                    />
                  }
                  classes={{
                    checked: classes.iconCheckboxChecked,
                    root: classes.iconCheckbox,
                  }}
                />
                <h6>{t("checklistAnalisys.wizar.type.checklistHistory.title")}</h6>
                <p>{t("checklistAnalisys.wizar.type.checklistHistory.description")}</p>
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default withStyles(style)(ChecklistAnalisysTypeStep);
