import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {useQuery} from "react-apollo-hooks";
import { Field, Form, withFormik } from 'formik';
import * as yup from 'yup';
import Datetime from "react-datetime";
import styled from 'styled-components';

// own components
import FieldAutosuggestMultiAsync from "../fields/FieldAutosuggestMultiAsync";

// core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import Search from '@material-ui/icons/Search';

// custom components
import ButtonIcon from '../../components/styled/ButtonIcon';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

//Utils
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import { REPORTS_NAME_SEARCH } from "../gql/Queries";
import useGlobal from '../../store';
import { REPORT_TYPE, REPORT_ACTION, REPORT_STATUS } from '../../constants';

const DivMarginBottom = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

/*
 * Validation Schema for TicketReportMaintenanceFilterForm
 * */
const validationSchema = yup.object().shape({
  status: yup.array().of(yup.string()),
  dateIn: yup.date(),
  dateEnd: yup.date()
});

const ReportsTableFilterForm = ({classes, ...props}) => {
  // Main hooks
  const { t } = useTranslation(['visits']);
  const { t:tt } = useTranslation("general_adaptation");
  const [ session, ] = useGlobal(
    state => state.session,
    actions => actions
  );

  // Formik bag
  const { setFieldValue, values, submitForm, visits } = props;

  const [currentReportNameSearch, setCurrentReportNameSearch] = useState({
    app: session.user ? session.appRef._id : '',
    name: ''
  });
  // Query for search
  const {data: dataReportsName, loading: loadingReportsName} = useQuery(REPORTS_NAME_SEARCH, {
    variables: {
      where: currentReportNameSearch
    }
  });
  return (
    <Form>
      <GridContainer>
        <GridItem xs={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel className={classes.selectLabel}>
              {t("reports.forms.reportType", {ns: 'reports'})}
            </InputLabel>
            <Field
              name="type_in"
              render={({ field }) => {
                return (
                  <Select
                    multiple
                    value={field.value}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    inputProps={{ ...field }}
                  >
                    {Object.keys(REPORT_TYPE).map(type => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }}
                        value={parseInt(type)}
                        key={REPORT_TYPE[type]}
                      >
                        {t(`reports.model.ReportType.${REPORT_TYPE[type]}`,
                          {ns: 'reports'})}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={3}>
          <InputLabel className={classes.label}>
            {t("reports.forms.startDateCreated", {ns: 'reports'})}
          </InputLabel>
          <Datetime
            name={'dateIn'}
            timeFormat={false}
            defaultValue={values.dateIn}
            onChange={e => {
              if (e === '') {
                setFieldValue('dateIn', e);
              }else{
                setFieldValue('dateIn', e._d);
              }
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <InputLabel className={classes.label}>
            {t("reports.forms.endDateCreated", {ns: 'reports'})}
          </InputLabel>
          <Datetime
            name={'dateEnd'}
            timeFormat={false}
            defaultValue={values.dateEnd}
            onChange={e => {
              if (e === '') {
                setFieldValue('dateEnd', e);
              }else{
                setFieldValue('dateEnd', e._d);
              }
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <ButtonIcon
            icon={Search}
            label={tt("search")}
            color={'primary'}
            block={true}
            type="submit"
            onClick={() => {
              submitForm();
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel className={classes.selectLabel}>
              {t("reports.forms.reportAction", {ns: 'reports'})}
            </InputLabel>
            <Field
              name="action_in"
              render={({ field }) => {
                return (
                  <Select
                    multiple
                    value={field.value}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    inputProps={{ ...field }}
                  >
                    {Object.keys(REPORT_ACTION).map(action => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }}
                        value={action}
                        key={REPORT_ACTION[action]}
                      >
                        {t(`reports.${REPORT_ACTION[action]}`,
                          {ns: 'reports'})}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel className={classes.selectLabel}>
              {t("reports.forms.reportStatus", {ns: 'reports'})}
            </InputLabel>
            <Field
              name="status_in"
              render={({ field }) => {
                return (
                  <Select
                    multiple
                    value={field.value}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    inputProps={{ ...field }}
                  >
                    {Object.keys(REPORT_STATUS).map(status => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }}
                        value={parseInt(status)}
                        key={REPORT_STATUS[status]}
                      >
                        {t(`reports.model.ReportStatus.${REPORT_STATUS[status]}`,
                          {ns: 'reports'})}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
        </GridItem>
        {/* <GridItem xs={3}>
          <FieldAutosuggestMultiAsync
            name={'reportname'}
            suggestionList={
              loadingReportsName ?
                [] :
                dataReportsName.reportNames || []
            }
            getSuggestionId={suggestion => suggestion}
            getSuggestionName={suggestion => suggestion}
            getSuggestionById={id => {
              if (!loadingReportsName) {
                return dataReportsName.reportNames.find(name => name === id);
              }
              return {
                value: id
              };
            }}
            placeholder={'reports.forms.reportName'}
            tableTitle={'common.views.selected'}
            noResultsLabel={'common.views.noSelected'}
            loadSuggestions={(value) => {
              setCurrentReportNameSearch({
                ...currentReportNameSearch,
                "name": value
              })
            }}
          />
        </GridItem> */}
      </GridContainer>
    </Form>
  );
};

ReportsTableFilterForm.propTypes = {
  submitSuccess: PropTypes.func.isRequired
};

const ReportsTableFilterFormWF = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => {
    return {
      type_in: initialValues.type_in,
      action_in: initialValues.action_in,
      status_in: initialValues.status_in,
      dateIn: initialValues.dateIn,
      dateEnd: initialValues.dateEnd
    };
  },
  handleSubmit: (values, { props }) => {
    props.submitSuccess(values);
  }
})(ReportsTableFilterForm);

export default withStyles(extendedFormsStyle)(ReportsTableFilterFormWF);
