import gql from "graphql-tag";

export const CHECK_SESSION = gql`
  query checkSession($token: String!, $app: String!) {
    checkSession(token: $token app: $app) {
      user {
        _id
        __typename
        firstName
        lastName
        email
        archived
      }
      token
      expiration
      creation
      access {
        app
        role {
          name
          global
        }
        teams
      }
      appId,
      appRef {
        _id
        name
        description
        icon
        appType
        iconThumbnails {
          original
          i320
          i240
        }
      }
      permissions
      featuresPlan{
        _id
        name
        features
      }
    }
  }
`;
