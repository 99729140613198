import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form, withFormik } from 'formik';
import * as yup from "yup";

// own components
import EngineAnswerFieldFooter from "../../Views/QuestionEngineAnswers/EngineAnswerFieldFooter";
import CancelSubmitEditForm from "./CancelSubmitEditForm";
import FieldListOptions from "../fields/FieldListOptions";
import WithConfirmation from "../WithConfirmation";

// custom components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from "components/Card/CardFooter.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";

import i18n from "../../i18n";

const validationSchema = yup.object().shape({
  value: yup.string(),
});

const ListMultipleFieldAnswerEditForm = ({...props}) => {
  const { t } = useTranslation('tickets');

  // Formik bag
  const { errors, touched, dirty, submitForm } = props;

  // basic answer props
  const {answerData} = props;

  return (
    <Form>
      <Card >
        <CardBody>
          <GridContainer>
            <GridItem xs={10}>
              <FieldListOptions name={'value'} options={answerData.answer.config.options} multi={true} />
            </GridItem>
            <GridItem xs={2}>
              <CancelSubmitEditForm
                cancelEdit={() => props.cancelEdit(answerData)}
                submitForm={submitForm}
                enableSubmit={dirty}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <EngineAnswerFieldFooter answerData={answerData} />
        </CardFooter>
      </Card>
    </Form>
  );
};

ListMultipleFieldAnswerEditForm.propTypes = {
  answerData: PropTypes.object.isRequired,
  submitSuccess: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired
};

const ListMultipleFieldAnswerEditFormWF = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: true,
  mapPropsToValues: ({answerData}) => {
    return {
      value: answerData.answer.ListMultipleFieldAnswer,
    };
  },
  handleSubmit: (values, { props }) => {
    props.goToConfirmation({
      title: i18n.t('question-engine-module-answer.messages.confirmEditFieldTitle', {
        ns: 'question-engine-module-answer'
      }),
      message: values.value.join(' - '),
      callback: () => {
        props.submitSuccess(values);
      }
    })
  }
})(ListMultipleFieldAnswerEditForm);

export default WithConfirmation(ListMultipleFieldAnswerEditFormWF);
