import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form, withFormik } from 'formik';
import * as yup from 'yup';
import Datetime from "react-datetime";

import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "react-select";
import TextField from '@material-ui/core/TextField';

// icons
import Search from '@material-ui/icons/Search';

// custom components
import ButtonIcon from '../../components/styled/ButtonIcon';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

//Utils
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";



const validationSchema = yup.object().shape({
  reportedDate_gte: yup.date().required(),
  reportedDate_lte: yup.date().required(),
  visit_in: yup.array(),
  keyField: yup.object()
});

const DatesFiltersForm = ({ classes, ...props }) => {
  // Main hooks
  const { t } = useTranslation('checklistAnalisys');

  // Formik bag
  const { setFieldValue, values, submitForm, handleChange, visits, handleResetSubmit, submitSuccess, propsFilterDefaultValues } = props;

  const [formFields, setFormFields] = useState([]);
  const [formValues, setFormValues] = useState({});

  const [selectVisitIn, setSelectVisitIn] = useState(null)

  const [startDate, setStartDate] = useState(new Date(propsFilterDefaultValues.reportedDate_gte))
  const [endDate, setEndDate] = useState(new Date(propsFilterDefaultValues.reportedDate_lte))

  const createFields = (e) => {
    setFormFields(e.keys);
    setFieldValue("visit_in", [e.value]);
    setSelectVisitIn(e)
  };

  const handleValue = (e, index) => {
    setFormValues({ ...formValues, ...e });
    setFieldValue('keyField', { ...formValues, ...e });
  };

  const handleReset = () => {
    
    // Clear Select and Keys values
    setFormFields([]);
    setFieldValue("visit_in", []);
    setSelectVisitIn(null)

    // clear dates    
    setStartDate(new Date(propsFilterDefaultValues.reportedDate_gte))
    setEndDate(new Date(propsFilterDefaultValues.reportedDate_lte))

    // send Data Props Default
    handleResetSubmit()
  } 

  return (
    <Form>
      <GridContainer
        style={{
          marginBottom: 20,
        }}
      >
        <GridItem xs={4}>
          <InputLabel className={classes.label}>
            Visita
          </InputLabel>
          <Field
            name="visit_in"
            render={({ field }) => {
              return (
                <Select
                  name="visit_in"
                  labelId="visit-select-label"
                  id="visit-select-id"
                  key="visit-key"
                  placeholder="Selecciona"
                  label="Visita"
                  options={visits}
                  inputProps={{ ...field }}
                  onChange={createFields}
                  value={selectVisitIn}
                />
              )
            }}
          />
        </GridItem>
        <GridItem xs={2}>
          <InputLabel className={classes.label}>
            {t("checklistAnalisys.form.fields.startDateCreated.label")}
          </InputLabel>
          <Datetime            
            name={"reportedDate_gte"}
            timeFormat={false}
            value={new Date(startDate)}
            onChange={(e) => {
              setStartDate(e)
              if (e === "") {
                setFieldValue("reportedDate_gte", e);
              } else {
                setFieldValue("reportedDate_gte", e._d);
              }
            }}
            className={{ fontsize: '20px' }}
          />
        </GridItem>
        <GridItem xs={2}>
          <InputLabel className={classes.label}>
            {t("checklistAnalisys.form.fields.endDateCreated.label")}
          </InputLabel>
          <Datetime
            name={"reportedDate_lte"}
            timeFormat={false}
            value={new Date(endDate)}
            onChange={(e) => {
              setEndDate(e)
              if (e === "") {                
                setFieldValue("reportedDate_lte", e);
              } else {
                setFieldValue("reportedDate_lte", e._d);
              }
            }}
          />
        </GridItem>
        <GridItem xs={2}>
          <Button block color="beepquest" onClick={() => submitForm()} type="submit">Filtrar</Button>
        </GridItem>
        <GridItem xs={2}>
          <Button block color="beepquest" onClick={() => handleReset() } type="reset">Limpiar filtros</Button>
        </GridItem>
        
      </GridContainer>

      <GridContainer
        style={{
          marginBottom: 20,
        }}
      >
        {/* {formFields.map((key, index) => (
          <GridItem xs={3} key={key}>
            <TextField
              variant='outlined'
              id={key}
              label={key}
              type={"text"}
              name={key}
              key={key}
              onChange={(e) => {
                let keyfieldValue = {}
                keyfieldValue[key] = e.target.value
                handleValue(keyfieldValue, index)
              }}
              index={index}
              fullWidth
            />
          </GridItem>
        ))} */}
      </GridContainer>
    </Form>
  );
};

DatesFiltersForm.propTypes = {
  submitSuccess: PropTypes.func.isRequired
};

const DatesFiltersFormWF = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: true,
  mapPropsToValues: ({ initialValues }) => {
    return {
      reportedDate_gte: initialValues.reportedDate_gte,
      reportedDate_lte: initialValues.reportedDate_lte,
      keyField: initialValues.keyField
    };
  },
  handleSubmit: (values, { props }) => {
    props.submitSuccess(values);
  }
})(DatesFiltersForm);

export default withStyles(extendedFormsStyle)(DatesFiltersFormWF);
