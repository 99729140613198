import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
import ReactTable from "react-table";
import DivCenter from "../../components/styled-components/DivCenter";
import {Tooltip} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import moment from "moment";
import {compose, prop, sortWith, length, descend} from "ramda";
import Icon from "@material-ui/core/Icon";

const VisitSchedulingTabulatorDetailTable = ({
  data,
  visitScheduling,
  handleClick
}) => {
  const { t } = useTranslation(["visitScheduling", "common"]);

  const sortByCompleted = sortWith([
    descend(compose(length, prop("answerIds")))
  ]);
  const tableData = sortByCompleted(data);

  return (
    <ReactTable
      data={tableData}
      columns={[
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("visitScheduling.tabulatorDetail.columns.email")}
            </DivCenter>
          ),
          accessor: "userRef.email",
          filterable: false,
          sortable: false,
          style: {
            textAlign: "center",
            whiteSpace: "unset",
            fontWeight: "400",
            ...styles.contentText
          }
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("visitScheduling.tabulatorDetail.columns.user")}
            </DivCenter>
          ),
          accessor: "name",
          Cell: ({original}) => {
            return (
              <div style={styles.contentSection}>
                { original.userRef.firstName } { original.userRef.lastName }
              </div>
            );
          },
          filterable: false,
          sortable: false,
          style: {
            textAlign: "center",
            whiteSpace: "unset",
            fontWeight: "400",
            ...styles.contentText
          }
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("visitScheduling.tabulatorDetail.columns.complete")}
            </DivCenter>
          ),
          accessor: "count",
          Cell: ({ original }) => {
            return (
              <Icon color={original.count > 0 ? "primary" : "secondary"}>{original.count > 0 ? "check" : "clear"}</Icon>
            );
          },
          filterable: false,
          sortable: false,
          style: {
            textAlign: "center",
            whiteSpace: "unset",
            fontWeight: "400",
            ...styles.contentText
          }
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("visitScheduling.tabulatorDetail.columns.firstAnswerDate")}
            </DivCenter>
          ),
          accessor: "firstAnswerDate",
          Cell: ({ original }) => {
            if (original.firstAnswerDate) {
              return <DivCenter>
                {moment(original.firstAnswerDate).format('DD/MM/YYYY')}
              </DivCenter>;
            }
            return "";
          },
          filterable: false,
          sortable: false,
          style: {
            textAlign: "center",
            whiteSpace: "unset",
            fontWeight: "400",
            ...styles.contentText
          }
        },
        {
          Header: <DivCenter/>,
          accessor: "actions",
          Cell: ({ original }) => {
            if(original.answerIds && original.answerIds.length > 0) {
              return (
                <div>
                  <Button justIcon round simple color="info"
                          onClick={() => {
                            handleClick(original.answerIds[0])
                          }}
                  >
                    <Tooltip title={t("visitScheduling.tooltip.edit")}>
                      <VisibilityIcon />
                    </Tooltip>
                  </Button>
                </div>
              );
            }
            return "";
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
        }
      ]}
      pageSize={tableData.length > 10 ? 10 : tableData.length}
      defaultPageSize={10}
      showPaginationTop={false}
      showPaginationBottom={true}
      className="-striped -highlight"
      resizable={false}
    />
  );
};

const styles = {
  headerTitle: {
    fontSize: "20px",
    whiteSpace: "unset",
    fontWeight: "500",
  },
  contentText: {
    fontSize: "17px",
  },
  contentSection: {
    fontSize: "20px",
    fontWeight: 'bold',
    padding: "20px"
  },
  regularButton: {
    color: "red",
    "&:hover": {
      backgroundColor: "green",
      color: "#6ab3d4",
    },
  },
};

export default VisitSchedulingTabulatorDetailTable;
