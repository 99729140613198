import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { NavLink } from "react-router-dom";
// import avatar from "assets/img/faces/marc.jpg";
import useGlobal from "../../store";

const AppMenu = ({
                    classes,
                    userWrapperClass,
                    photo,
                    openCollapse,
                    caret,
                    openApp,
                    itemText,
                    collapseItemText,
                    collapseItemMini
                  }) => {

  const [session, globalActions] = useGlobal(
    state => state.session,
    actions => actions
  );

  return (
    <div className={userWrapperClass}>
      <div className={photo}>
        <img src={session.user ? session.appRef.iconThumbnails.i240: ""} className={classes.avatarImg} alt="..." />
      </div>
      <List className={classes.list}>
        <ListItem className={classes.item + " " + classes.userItem}>
          <NavLink
            to={"#"}
            className={classes.itemLink + " " + classes.userCollapseButton}
            onClick={() => openCollapse("openApp")}
          >
            {session.user ?
              <ListItemText
                primary={session.user ? `${session.appRef.name}` : ''}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      classes.userCaret +
                      " " +
                      (openApp ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={itemText + " " + classes.userItemText}
              /> : []
            }
          </NavLink>
          <Collapse in={openApp} unmountOnExit>
            <List className={classes.list + " " + classes.collapseList}>
            </List>
          </Collapse>
        </ListItem>
      </List>
    </div>
  )
};

export default AppMenu;
