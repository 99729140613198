import React, { useMemo, useState } from "react";
import HistoryView from "../../Views/History/HistoryView";
import { ALL_EVENTS_FEED } from "../Dashboard/gql/Query";
import { useQuery } from "react-apollo-hooks";

// Local state
import useGlobal from "../../store";
import { useTranslation } from "react-i18next";
import auth from "../../utils/auth";
import history from "../../utils/history";

const History = () => {
  const { t } = useTranslation("common");
  const [session, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );
  const [eventsFilter, setEventsFilter] = useState({
    where: {
      app: session.user ? session.appRef._id : "",
      client_in: ["gh", "dashboard"],
    },
    first: 15,
    skip: 0,
    orderBy: "created_DESC",
  });

  async function paginationAction(values) {
    setEventsFilter(values);
  }

  const {
    data: dataAllEvents,
    error: errorAllEvents,
    loading: loadingAllEvents,
    refetch: refetchAllEvents,
  } = useQuery(ALL_EVENTS_FEED, {
    variables: eventsFilter,
    fetchPolicy: "network-only",
  });

  useMemo(() => {
    if (
      session.user &&
      !auth.hasPermission("panelEvents", session.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: t('common.hasNotPermission'),
        color: "danger"
      });
      history.push("dashboard");
    }
  }, [session]);


  return (
    <HistoryView
      infoEvents={{ loading: loadingAllEvents, events: dataAllEvents }}
      paginationAction={paginationAction}
      eventsFilter={eventsFilter}
    />
  );
};

export default History;
