import React from "react";
import {useTranslation} from 'react-i18next';
import {useSubscription} from "react-apollo-hooks";
import moment from "moment";

// Own components
import useGlobal from "../../store";
import { CHECKLIST_REPORT_CREATED_SUBSCRIPTION } from "./gql/Subscriptions";
import { reportCreationCompletedNotification } from "../../utils/snackNotifications";
import ReportNotifierView from "../../Views/ReportNotifier/ReportNotifierView";

function ReportNotifierItem({match}) {
  const [session, ] = useGlobal(
    state => state.session,
    actions => actions
  );
  const [reports, globalActions] = useGlobal(
    state => state.reports,
    actions => actions
  );
  const { t, } = useTranslation('reports');

  // Checklist reports Subscriptions
  const {
    data: dataReportSubscription,
    error: errorReportSubscription,
    loading: loadingReportSubscription
  } = useSubscription(CHECKLIST_REPORT_CREATED_SUBSCRIPTION, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : '',
        userId: session.user ? session.user._id : '',
      }
    },
    onSubscriptionData: ({client, subscriptionData}) => {
      const {node} = subscriptionData.data.checklistReportCreated;
      // Format the current new report object
      const newReport = {
        label: t(node.action),
        date: moment().format("DD/MM/YYYY - HH:mm"),
        url: node.url
      };
      
      globalActions.reports.addReport(newReport);
      reportCreationCompletedNotification(t, globalActions, node);
    }
  });

  return (
    <ReportNotifierView
      reports={reports.reportsList}
      lastInsert={reports.lastInsert}
    />
  );
}

export default ReportNotifierItem;
