import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { InputLabel, TextField } from "@material-ui/core";
import Select from "react-select";
import { useMutation } from "react-apollo-hooks";
import { GET_KEY_FIELD_ANSWERS } from "../../containers/ProcessRelationship/gql/Mutations";
import useGlobal from "../../store";
import { Autocomplete } from "@material-ui/lab";
import Datetime from "react-datetime";
import moment from "moment";

const VisitAnswersKeysForm = ({ visits, submitSuccess }) => {
  const [session] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );
  const [selectVisitIn, setSelectVisitIn] = useState(null);
  const [keyFieldsAnswers, setKeyFieldsAnswers] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(1, "months").startOf("day").format()
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).endOf("day").format()
  );
  const [keyFields, setKeyFields] = useState({});

  const keyFieldAnswersMutation = useMutation(GET_KEY_FIELD_ANSWERS);

  const executeMutation = (options) => {
    if (selectVisitIn || options.visit) {
        keyFieldAnswersMutation({
          variables: {
            where: {
              visitId: options.visit ? options.visit : selectVisitIn.value,
              app: session.user ? session.appRef._id : "",
              created_gte: options.startDate ? options.startDate : startDate,
              created_lte: options.endDate ? options.endDate : endDate,
            },
          },
        }).then(
          (result) => {
            const { keyFieldsAnswers } = result?.data;

            if (keyFieldsAnswers) {
              setKeyFieldsAnswers(keyFieldsAnswers);
            }
          },
          (error) => {
            console.log({
              error,
            });
          }
        );
    }
    
  }

  return (
    <>
      <GridContainer
        style={{
          marginBottom: 20,
        }}
      >
        <GridItem xs={4}>
          <InputLabel
            style={{
              marginBottom: 10,
            }}
          >
            Visita
          </InputLabel>
          <Select
            name="visit_in"
            labelId="visit-select-label"
            id="visit-select-id"
            key="visit-key"
            placeholder="Selecciona"
            label="Visita"
            options={visits}
            onChange={(visit)=>{
                setSelectVisitIn(visit);
                setKeyFieldsAnswers([]);
                setKeyFields({});
                executeMutation({ visit: visit.value });
            }}
            value={selectVisitIn}
          />
        </GridItem>
        <GridItem xs={2}>
          <InputLabel
            style={{
              marginBottom: 10,
            }}
          >
            Fecha de inicio
          </InputLabel>
          <Datetime
            name={"reportedDate_gte"}
            timeFormat={false}
            value={new Date(startDate)}
            onChange={(e) => {
              setStartDate(e._d);
              executeMutation({ startDate: e._d });
            }}
            className={{ fontsize: "20px" }}
          />
        </GridItem>
        <GridItem xs={2}>
          <InputLabel
            style={{
              marginBottom: 10,
            }}
          >
            Fecha final
          </InputLabel>
          <Datetime
            name={"reportedDate_lte"}
            timeFormat={false}
            value={new Date(endDate)}
            onChange={(e) => {
              setEndDate(e._d);
              executeMutation({ endDate: e._d });
            }}
          />
        </GridItem>
        <GridItem xs={2}>
          <Button block color="beepquest" onClick={() => {
            let values = {
              reportedDate_gte: moment(startDate).format(),
              reportedDate_lte: moment(endDate).format(),
              keyField: keyFields,
            };

              if (selectVisitIn) {
                values.visit_in = [selectVisitIn.value];
                submitSuccess(values);  
              }else{
                submitSuccess(values);  
              }
          }} type="submit">Filtrar</Button>
        </GridItem>
        <GridItem xs={2}>
          <Button block color="beepquest" onClick={() => {
            submitSuccess({}, true);
            setSelectVisitIn(null);
            setKeyFieldsAnswers([]);
            setKeyFields({});
            setStartDate(moment(new Date()).subtract(1, "months").startOf("day").format());
            setEndDate(moment(new Date()).endOf("day").format());

          } } type="reset">Limpiar filtros</Button>
        </GridItem>
      </GridContainer>
      <GridContainer
        style={{
          marginBottom: 20,
        }}
      >
        {keyFieldsAnswers.map((field, index) => (
          <GridItem xs={3} key={index}>
            <Autocomplete
              multiple={false}
              filterSelectedOptions
              options={field.answers}
              onChange={(event, value) => {
                let tmpKeyFields = {
                    ...keyFields
                };

                tmpKeyFields[field.keyField] = value;
                setKeyFields({
                    ...tmpKeyFields
                })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={field.keyField}
                  fullWidth
                  margin="normal"
                />
              )}
            />
          </GridItem>
        ))}
      </GridContainer>
    </>
  );
};

export default VisitAnswersKeysForm;
