import React, {useState, useEffect} from "react";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import Snackbar from "components/Snackbar/Snackbar.jsx";

import useGlobal from "../../store";

function SnackbarContainer(){
  const [notifications, globalActions] = useGlobal(
    state => state.notifications,
    actions => actions
  );

  const {tr} = notifications;

  return (
    <Snackbar
      place={'tr'}
      color={tr.color}
      icon={AddAlert}
      message={tr.message || ''}
      open={tr.open}
      closeNotification={() => {
        globalActions.notification.closeNotification('tr');
      }}
      autoHideDuration={tr.autoHideDuration}
    />
  );
}

export default SnackbarContainer;
