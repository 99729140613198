import React from 'react';
import ReactTable from "react-table";
import DivCenter from "../../components/styled-components/DivCenter";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from "components/CustomButtons/Button.js";
import AddIcon from '@material-ui/icons/Add';
import { Tooltip } from "@material-ui/core";

const PenaltyCountTable = ({
  penalties,
  setVisibleForm,
  visibleForm,
  setPenaltiesVisible,
  penaltiesVisible,
  setEditForm
}) => {
  return (
    <ReactTable
      data={penalties}
      columns={[
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              <Box
                display="flex"
                alignItems="center"
                style={{ float: "left", width: "100%" }}
              >
                <Box style={{ width: "100%" }}>
                  <Typography style={styles.titleText}>
                    PENALIZACIONES
                  </Typography>
                </Box>
                <Box style={{ width: "50%", textAlign: "right" }}>
                  <Button
                    justIcon
                    round
                    simple
                    color="info"
                    onClick={() => {
                      setVisibleForm(!visibleForm);
                      setEditForm(false);
                    }}
                  >
                    <Tooltip title="Agregar">
                      <AddIcon style={{ fontSize: 40 }} />
                    </Tooltip>
                  </Button>
                </Box>
              </Box>
            </DivCenter>
          ),
          accessor: "total",
          Cell: ({ original }) => {
            return (
              <Typography
                style={styles.contentText}
                onClick={() => setPenaltiesVisible(!penaltiesVisible)}
              >
                {original.total}
              </Typography>
            );
          },
          filterable: false,
          sortable: false,
        },
      ]}
      defaultPageSize={1}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
    />
  );
};

const styles = {
  headerTitle: {
    whiteSpace: 'break-spaces',
    fontWeight: "500", 
    fontSize: "18px",
  },
  titleText: {
    fontSize: 18,
  },
  contentText: {
    fontSize: "30px",
    textAlign: "center",
    fontWeight: "bold",
    cursor: "pointer",
    '&:hover': {
      textDecoration: "underline",
    },
  },
  regularButton: {
    color: "red",
    "&:hover": {
      backgroundColor: "green",
      color: "#6ab3d4",
    },
  },
};

export default PenaltyCountTable;
