import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import {useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom'

// custom components
import Button from "components/CustomButtons/Button.js";

// icons
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardTwoTone";

// styled components
import DivCenter from "../../components/styled-components/DivCenter";

// utils
import history from '../../utils/history';
import useGlobal from '../../store';
import auth from '../../utils/auth';

const UserApprovalItemsTable = props => {
  const { t, } = useTranslation('visits');
  const [formattedData, changeFormattedData] = useState([]);

  useEffect(() => {
    changeFormattedData(props.data.map((item) => {
      return {
        ...item
      }
    }))
  }, [props.data]);

  const [session] = useGlobal((state) => state.session);

  const hasPermission = (permission) => {
    if (session.user && !auth.hasPermission(permission, session.permissions)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <ReactTable
      data={formattedData}
      getTrProps={(state, rowInfo, column, instance) => ({
        onClick: e => history.push(`/visits/${rowInfo.original.visit._id}/approval`)
      })}
      columns={[
        {
          Header: (<DivCenter>Ir</DivCenter>),
          id: "actions",
          Cell: (d) => {
            return (
              <DivCenter>
                {hasPermission("approbalVisitEdit") ? (
                  <Link
                    to={{
                      pathname: `/visits/${d.original.visit._id}/approval`,
                    }}
                  >
                    <Button justIcon round simple color="info">
                      <ArrowForwardIcon />
                    </Button>{" "}
                  </Link>
                ) : (
                  ""
                )}
              </DivCenter>
            );
          },
          filterable: false,
          sortable: false,
          width: 100
        },
        {
          Header: t('visits.list.columns.nameVisit'),
          accessor: "visit.name",
          filterable: false,
          sortable: false,
          minWidth: 200
        },
        {
          Header: (<DivCenter>{t('visits.list.columns.pendingCount')}</DivCenter>),
          accessor: "approvalItems.length",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          maxWidth: 200
        }
      ]}
      defaultPageSize={15}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
    />
  );
};

UserApprovalItemsTable.propTypes = {
  data: PropTypes.array.isRequired
};

export default UserApprovalItemsTable;
