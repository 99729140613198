import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import * as yup from "yup";
import { Field, Form, withFormik } from "formik";
//import Select from "react-select";
import { TextField } from "@material-ui/core";
import ButtonIcon from "components/styled/ButtonIcon";
import SaveIcon from "@material-ui/icons/Save";
import { useTranslation } from "react-i18next";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  InputBase,
  InputAdornment,
  Input,
} from "@material-ui/core";

const PenaltyRequisitionFormSchema = yup.object().shape({
  requisitionId: yup.string(),
  indicator: yup.string().required(),
  operation: yup.string().required(),
  value: yup.number().required(),
  percentagePenalty: yup.number().required(),
});

const PenaltyRequisitionForm = ({ submitForm, setFieldValue, requisitions, values }) => {
  const { t } = useTranslation("processRelationship");

  /* const indicatorsOpts = [
    {
      label: t("processRelationship.workPlan.indictors.TotalPending"),
      value: "TotalPending",
    },
    {
      label: t("processRelationship.workPlan.indictors.PercentagePending"),
      value: "PercentagePending",
    },
    {
      label: t("processRelationship.workPlan.indictors.ResponseTimeAverage"),
      value: "ResponseTimeAverage",
    },
  ]; */

  const indicatorsRequisition = [
    {
      label: 'Devoluciones',
      value: 'Returns'
    },
    {
      label: 'Tiempo promedio de cierre',
      value: 'AverageClosingTime'
    },
    {
      label: 'No cumplir',
      value: 'NotComplying'
    },
  ];

  const indicatorsGlobal = [
    {
      label: 'Vencidas',
      value: 'GlobalReturns'
    },
    {
      label: 'Devueltas',
      value: 'GlobalExpirations'
    }
  ];

  const operationsOpts = [
    {
      label: t("processRelationship.workPlan.operations.lte"),
      value: "lte",
    },
    {
      label: t("processRelationship.workPlan.operations.lt"),
      value: "lt",
    },
    {
      label: t("processRelationship.workPlan.operations.gte"),
      value: "gte",
    },
    {
      label: t("processRelationship.workPlan.operations.gt"),
      value: "gt",
    },
    {
      label: t("processRelationship.workPlan.operations.eq"),
      value: "eq",
    },
  ];

  return (
    <Form>
      <GridContainer alignItems="center">
      <GridItem xs={3}>
          <Field
            name="requisitionId"
            render={({ field }) => {
              return (
                <FormControl style={styles.formControl}>
                  <InputLabel>Requisición</InputLabel>
                  <Select
                    value={field.value}
                    onChange={(event) => {
                      setFieldValue("requisitionId", event.target.value);

                      if (event.target.value === 'ALL') {
                        setFieldValue("indicator", 'GlobalReturns');
                      }else{
                        setFieldValue("indicator", 'Returns');
                      }
                    }}
                    input={<BootstrapInput />}
                  >
                    <MenuItem key={"all"} value={'ALL'}>
                      Todas
                    </MenuItem>
                    {requisitions.map((requisition, index) => (
                      <MenuItem key={index} value={requisition._id}>
                        {requisition.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <Field
            name="indicator"
            render={({ field }) => {
              return (
                <FormControl style={styles.formControl}>
                  <InputLabel>
                    {t("processRelationship.workPlan.list.indicator")}
                  </InputLabel>
                  <Select
                    value={field.value}
                    onChange={(event) => {
                      setFieldValue("indicator", event.target.value);
                    }}
                    input={<BootstrapInput />}
                  >
                    {values.requisitionId !== 'ALL' 
                      ? indicatorsRequisition.map((indicator, index) => (
                          <MenuItem key={index} value={indicator.value}>
                            {indicator.label}
                          </MenuItem>
                        ))
                      : indicatorsGlobal.map((indicator, index) => (
                          <MenuItem key={index} value={indicator.value}>
                            {indicator.label}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              );
            }}
          />
        </GridItem>
        <GridItem xs={2}>
          <Field
            name="operation"
            render={({ field }) => {
              return (
                <FormControl style={styles.formControl}>
                  <InputLabel>
                    {t("processRelationship.workPlan.list.operation")}
                  </InputLabel>
                  <Select
                    value={field.value}
                    onChange={(event) => {
                      setFieldValue("operation", event.target.value);
                    }}
                    input={<BootstrapInput />}
                  >
                    {operationsOpts.map((operation, index) => (
                      <MenuItem key={index} value={operation.value}>
                        {operation.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }}
          />
        </GridItem>

        <GridItem xs={2}>
          <Field
            name="value"
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  id="value"
                  type="number"
                  label={t("processRelationship.workPlan.list.value")}
                  inputProps={{
                    style: { textAlign: "right" },
                    min: 0,
                  }}
                />
              );
            }}
          />
        </GridItem>

        <GridItem xs={2}>
          <Field
            name="percentagePenalty"
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <InputLabel htmlFor="standard-adornment-amount">
                    {t("processRelationship.workPlan.list.affectation")}
                  </InputLabel>
                  <Input
                    {...field}
                    id="percentagePenalty"
                    type="number"
                    inputProps={{
                      style: { textAlign: "right" },
                      min: 0,
                    }}
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                  />
                </FormControl>
              );
              
            }}
          />
        </GridItem>

        <GridItem xs={2}>
          <ButtonIcon
            icon={SaveIcon}
            label="Guardar"
            color={"info"}
            block={true}
            onClick={() => {
              submitForm();
            }}
          />
        </GridItem>
      </GridContainer>
    </Form>
  );
};

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const styles = {
  formControl: {
    width: "100%",
  },
};

const PenaltyRequisitionFormWF = withFormik({
  validationSchema: PenaltyRequisitionFormSchema,
  enableReinitialize: true,
  mapPropsToValues: ({ penalty }) => {

    return {
      requisitionId : penalty?.requisitionId ?? 'ALL',
      indicator: penalty?.indicator ?? "GlobalReturns",
      operation: penalty?.operation ?? "lte",
      value: penalty?.value ?? 0,
      percentagePenalty: penalty?.percentagePenalty ?? 0,
    };
  },
  handleSubmit: (values, { props }) => {
    props.submitValues(values);
  },
})(PenaltyRequisitionForm);

export default PenaltyRequisitionFormWF;
