import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

// styled components
import DivCenter from "../../components/styled-components/DivCenter";
import { isGlobalCategory } from '../../utils/analytics/dataManipulation';
import { useTranslation } from 'react-i18next';

function OpportunityAreasTable({data}){
  const { t } = useTranslation('common');

  if(data === null || (Array.isArray(data) && !data.length)) return <div></div>; 

  const sampleCategories = data[0].categories;

  let headers = sampleCategories.map((category, index) => {
    const label = isGlobalCategory(category.id)? category.name : "Promedio de "+category.name;
    return {
      id: category.name,
      Header: (<DivCenter>{label}</DivCenter>),
      accessor: "categories."+index+".evaluation",
      filterable: false,
      sortable: false,
      Cell: ({value}) => {return value ? value.toFixed(2) : 0},
      style: { textAlign: "center" },
      minWidth: 250
    };
  });

  headers.unshift({
    id: "keyfield",
    Header: (<DivCenter>Campo Clave</DivCenter>),
    accessor: "keyfield.value",
    filterable: false,
    sortable: false,
    style: { textAlign: "center" },
    minWidth: 250
  });


  return (
    <ReactTable
      data={data}
      columns={headers}
      defaultPageSize={10}
      previousText={t('common.pagination.back')}
      nextText={t('common.pagination.next')}
      pageText={t('common.pagination.page')}
      ofText={t('common.pagination.of')}
      rowsText={t('common.pagination.rows')}
      noDataText={t('common.pagination.noData')}
      className="-striped -highlight"
    />
  );
};

OpportunityAreasTable.propTypes = {
  category: PropTypes.object,
  data: PropTypes.array
};

export default OpportunityAreasTable;