import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import Button from "../../components/CustomButtons/Button";
import history from "../../utils/history";
import TablePagination from "../../pagination/table/TablePagination";
import {CircularProgress} from "@material-ui/core";
import React, {useState} from "react";
import VisitSchedulingTable from "../../tables/visitScheduling/visitSchedulingTable";
import {useTranslation} from "react-i18next";
import useGlobal from '../../store';
import auth from '../../utils/auth';

const VisitSchedulingListView = ({
  visitSchedulings,
  visitSchedulingFilter,
  archiveVisitScheduling,
  isArchivedMode,
  changeArchivedMode,
  paginationAction,
  plan
}) => {
  const { t } = useTranslation("visitScheduling");
  const [visitScheduling, setVisitScheduling] = useState(null);
  const { loading } = visitSchedulings;
  const data = visitSchedulings.data.visitSchedulings
  const schedulingVisit = plan ? plan?.features?.visitScheduling : 'on';

  const [session] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const hasPermission = (permission) => {
    if ( session.user && !auth.hasPermission(permission, session.permissions)) return false;
    return true;
  };

  return (
    <Card>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <AssignmentTurnedInIcon />
        </CardIcon>
        <div style={style.left}>
          <h4 style={style.cardTitle}>{t("visitScheduling.title")}</h4>
        </div>
        <div style={style.right}>
          <Button
            id={"archived-object"}
            color={"danger"}
            size="sm"
            onClick={() => {
              changeArchivedMode();
            }}
          >
            {!isArchivedMode
              ? t("visitScheduling.list.buttons.viewArchived")
              : t("visitScheduling.list.buttons.viewActive")}
          </Button>
          <Button
            id={"new-object"}
            size="sm"
            color="info"
            onClick={() => history.push(`/visitScheduling/new`)}
            disabled={schedulingVisit === 'off' || !hasPermission('schedulingVisitCreated') ? true : false}
          >
            {t("visitScheduling.list.buttons.new")}
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <TablePagination
          data={data ? data : []}
          paginationAction={paginationAction}
          filter={visitSchedulingFilter}
        />
        {loading ? (
          <div style={style.progressCnt}>
            <CircularProgress style={style.progress} />
          </div>
        ) : (
          <VisitSchedulingTable
            data={data}
            archiveVisitScheduling={archiveVisitScheduling}
          />
        )}
      </CardBody>
    </Card>
  );
};

const style = {
  cardTitle: {
    color: "#3C4858",
  },
  progress: {
    margin: "16px",
  },
  progressCnt: {
    width: "100px !important",
    marginLeft: "50%",
    marginRight: "50%",
  },
  cardHeight: {
    height: "430px",
  },
  left: {
    float: "left",
  },
  right: {
    float: "right",
  },
};

export default VisitSchedulingListView;
