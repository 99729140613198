import React from 'react';
import PropTypes from 'prop-types';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Badge from "components/Badge/Badge";
import DivCenter from '../../../components/styled-components/DivCenter';
import { CircularProgress } from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DescriptionIcon from '@material-ui/icons/Description';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

const InfoApp = ({ infoApp }) => {
  const { loading, indexes } = infoApp;
  const { t, } = useTranslation('dashboard'); 
    return (
      <div>
        {loading ? (
          <CircularProgress />
        ) : indexes.indexesDashboard ? (
          <div>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={4}>
                    <AccountBoxIcon color="beepquest" />
                  </GridItem>
                  <GridItem xs={4}><DivCenter> {t(`dashboard.indexes.users`)} </DivCenter></GridItem>
                  <GridItem xs={4}><DivCenter><Badge color="beepquest"> {indexes.indexesDashboard.users} </Badge></DivCenter></GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={4}>
                    <MobileScreenShareIcon color="beepquest"  />
                  </GridItem>
                  <GridItem xs={4}><DivCenter> {t(`dashboard.indexes.screens`)} </DivCenter></GridItem>
                  <GridItem xs={4}><DivCenter><Badge color="beepquest"> {indexes.indexesDashboard.screens} </Badge></DivCenter></GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={4}>
                  <ViewModuleIcon color="beepquest"  />
                </GridItem>
                <GridItem xs={4}><DivCenter> {t(`dashboard.indexes.modules`)} </DivCenter></GridItem>
                <GridItem xs={4}><DivCenter><Badge color="beepquest"> {indexes.indexesDashboard.modules} </Badge></DivCenter></GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={4}>
                  <DescriptionIcon color="beepquest"  />
                </GridItem>
                <GridItem xs={4}><DivCenter> {t(`dashboard.indexes.reports`)} </DivCenter></GridItem>
                <GridItem xs={4}><DivCenter><Badge color="beepquest"> {indexes.indexesDashboard.reports} </Badge></DivCenter></GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          </div>
        ) : (
          []
        )}
      </div>
    );
};

InfoApp.propTypes ={
    infoApp: PropTypes.object.isRequired,
};


export default InfoApp;
