import React from 'react';
import PropTypes from 'prop-types';

import Button from "../Button";

import withStyles from "@material-ui/core/styles/withStyles";
import GetAppIcon from '@material-ui/icons/GetApp';
import ComingSoon from '../ComingSoon/ComingSoon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

function DownloadButton({
  classes,
  color, 
  size, 
  isReady,
  hasXLS,
  onClick, 
  onClickPDF, 
  onClickXLS
}){
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePDFClick = () => {
    setAnchorEl(null);
    onClickPDF();
  };

  const handleXLSClick = () => {
    setAnchorEl(null);
    onClickXLS();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div>
      <Button
        justIcon
        simple
        size = {size === null ? "sm" : size}
        color = {color === null ? "info" : color}
        onClick={handleClick}
      >
        <GetAppIcon/>
      </Button>
      { isReady ?
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handlePDFClick}>Reporte PDF</MenuItem>
          {hasXLS && <MenuItem onClick={handleXLSClick}>Reporte XLS</MenuItem>}
        </Menu>
      :
        <ComingSoon
          anchorEl={anchorEl}
          id={id}
          handleClose={handleClose}
          open={open}
        />
      }
    </div>
  );
};

DownloadButton.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "twitter",
    "facebook",
    "google",
    "linkedin",
    "pinterest",
    "youtube",
    "tumblr",
    "github",
    "behance",
    "dribbble",
    "reddit",
    "transparent",
    "beepquest"
  ]),
  isReady: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onClickPDF: PropTypes.func,
  onClickXLS: PropTypes.func,
  hasXLS: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(["sm", "lg"]),
};

const styles = theme => ({
});

export default withStyles(styles)(DownloadButton);