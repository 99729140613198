import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo-hooks";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";

import { LinearProgress } from "@material-ui/core";

import CounterReportView from "../../Views/SO/CounterReportView";

import useGlobal from "../../store";
import { SO_COUNTER_REPORT_VIEW, SO_GET_INDICATOR_RANGE_RESULT } from "./gql/Queries";
import {
  CALCULATE_RANGE_RESULT,
  GENERATE_SO_COUNTER_REPORT,
  UPDATE_SO_COUNTER_REPORT_CUSTOM_OBJECTIVES,
} from "./gql/Mutations";

const CounterReport = (props) => {
  const { counterReport } = props.location.state;
  const [session, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const [
    performedIndicatorCalculations,
    setPerformedIndicatorCalculations,
  ] = useState([]);
  const [calculationsInfo, setCalculationsInfo] = useState({
    calculations: 0,
    fulfilled: 0,
    processed: false,
  });

  const [isModify, setIsModify] = useState(false);

  const {
    data: dataSOCounterReport,
    error: errorSOCounterReport,
    loading: loadingSOCounterReport,
  } = useQuery(SO_COUNTER_REPORT_VIEW, {
    variables: {
      counterReportId: counterReport._id,
      app: session.user ? session.appRef._id : "",
    },
    fetchPolicy: "network-only",
  });

  const [
    getIndicatorRangeResult,
    {
      data: dataIndicatorResult,
      loading: loadingIndicatorResult,
      error: errorIndicatorResult,
    },
  ] = useLazyQuery(SO_GET_INDICATOR_RANGE_RESULT);

  const [generateSoCounterReport] = useMutation(GENERATE_SO_COUNTER_REPORT, {
    onCompleted: () => {
      globalActions.notification.openNotification("tr", {
        message: "Procesando Reporte",
        color: "success",
      });
    },
  });
  const [updateCustomObjectives] = useMutation(
    UPDATE_SO_COUNTER_REPORT_CUSTOM_OBJECTIVES,
    {
      onCompleted: () => {
        globalActions.notification.openNotification("tr", {
          message: "Objetivos actualizados",
          color: "success",
        });
      },
    }
  );
  const [calculateIndicatorResult] = useMutation(CALCULATE_RANGE_RESULT, {
    onCompleted: (mutationData) => {
      setCalculationsInfo({
        ...calculationsInfo,
        fulfilled: calculationsInfo.fulfilled + 1,
      });
      setPerformedIndicatorCalculations([
        ...performedIndicatorCalculations,
        mutationData.calculateIndicatorRangeResult,
      ]);
    },
  });

  useEffect(() => {
    if (dataSOCounterReport?.soCounterReportResult) {
      const { soCounterReportResult } = dataSOCounterReport;
      const orderedRanges = soCounterReportResult.periodicityRanges.map(
        (range) => {
          const currentDate = Date.now();
          const initialDate = new Date(range.initialDate);
          const finalDate = new Date(range.finalDate);
          return {
            range,
            rangePassed: currentDate > initialDate,
            rangePending: currentDate < finalDate,
          };
        }
      );
      orderedRanges.map((range) => {
        const periodicityRange = range.range;
        if (range.rangePassed && range.rangePending) {
          soCounterReportResult.indicators.map((indicator) => {
            const calculationFound = soCounterReportResult.calculatedIndicatorResults.find(
              (calculation) =>
                calculation.indicatorId === indicator._id &&
                calculation.order === range.range.order
            );

            if (!calculationFound) {
              setCalculationsInfo({
                ...calculationsInfo,
                calculations: calculationsInfo.calculations + 1,
              });
              calculateIndicatorResult({
                variables: {
                  data: {
                    soCounterReportId: soCounterReportResult._id,
                    appId: session.user ? session.appRef._id : "",
                    indicator: indicator,
                    range: {
                      order: periodicityRange.order,
                      periodicity: periodicityRange.periodicity,
                      initialDate: periodicityRange.initialDate,
                      finalDate: periodicityRange.finalDate,
                    },
                    save: false,
                  },
                },
              });
            }
          });
        } else if (range.rangePassed && !range.rangePending) {
          soCounterReportResult.indicators.map((indicator) => {
            const calculationFound = soCounterReportResult.calculatedIndicatorResults.find(
              (calculation) =>
                calculation.indicatorId === indicator._id &&
                calculation.order === range.range.order
            );
            if (!calculationFound) {
              setCalculationsInfo({
                ...calculationsInfo,
                calculations: calculationsInfo.calculations + 1,
              });
              calculateIndicatorResult({
                variables: {
                  data: {
                    soCounterReportId: soCounterReportResult._id,
                    appId: session.user ? session.appRef._id : "",
                    indicator: indicator,
                    range: {
                      order: periodicityRange.order,
                      periodicity: periodicityRange.periodicity,
                      initialDate: periodicityRange.initialDate,
                      finalDate: periodicityRange.finalDate,
                    },
                    save: true,
                  },
                },
              });
            }
          });
        }
      });
      setCalculationsInfo({
        ...calculationsInfo,
        processed: true,
      });
    }
  }, [dataSOCounterReport.soCounterReportResult]);

  if (!loadingSOCounterReport) {
    return (
      <React.Fragment>
        {dataSOCounterReport?.soCounterReportResult ? (
          <React.Fragment>
            <CounterReportView
              getIndicatorRangeResult={getIndicatorRangeResult}
              dataIndicatorResult={dataIndicatorResult?.getIndicatorRangeResult ?? []}
              loadingIndicatorResult={loadingIndicatorResult}
              soCounterReportData={dataSOCounterReport.soCounterReportResult}
              isModify={isModify}
              setIsModify={setIsModify}
              calculatedIndicatorResults={[
                ...dataSOCounterReport.soCounterReportResult
                  .calculatedIndicatorResults,
                ...performedIndicatorCalculations,
              ]}
              baseModels={{
                visits: dataSOCounterReport.visits,
                questionEngineModules:
                  dataSOCounterReport.questionEngineModules,
              }}
              generateSoCounterReport={(
                counterReport,
                customObjectives,
                calculatedIndicators
              ) => {

                const periodicityRanges =
                dataSOCounterReport?.soCounterReportResult
                    ?.periodicityRanges ?? [];

                const orderedRanges = periodicityRanges.map(
                  (range) => {
                    const currentDate = Date.now();
                    const initialDate = new Date(range.initialDate);
                    const finalDate = new Date(range.finalDate);
                    return {
                      range,
                      rangePassed: currentDate > initialDate,
                      rangePending: currentDate < finalDate,
                    };
                  }
                );

                const calculatedResultRender = calculatedIndicators.map(result => {
                  const calculationFound = orderedRanges.find(
                    (range) =>
                    result.order === range.range.order
                  );
                  if (calculationFound) {
                    return {
                      ...result,
                      initialDate: calculationFound.range.initialDate,
                      finalDate: calculationFound.range.finalDate,
                    }
                  } else{
                    return result;
                  }
                });

                const customObjectivesRender = customObjectives.map(custom => {
                  const calculationFound = orderedRanges.find(
                    (range) =>
                    custom.order === range.range.order
                  );
                  if (calculationFound) {
                    return {
                      ...custom,
                      initialDate: calculationFound.range.initialDate,
                      finalDate: calculationFound.range.finalDate,
                    }
                  } else{
                    return custom;
                  }
                });

                generateSoCounterReport({
                  variables: {
                    where: {
                      _id: counterReport._id,
                    },
                    data: {
                      userId: session.user._id,
                      customIndicatorObjectives: customObjectivesRender,
                      calculatedIndicatorResults: calculatedResultRender,
                    },
                  },
                });
              }}
              updateVCustomObjectives={(customObjectives) => {
                setIsModify(false);
                updateCustomObjectives({
                  variables: {
                    data: customObjectives,
                    where: {
                      _id: counterReport._id,
                    },
                  },
                });
              }}
              canGenerateReport={
                calculationsInfo.processed
                  ? calculationsInfo.calculations === calculationsInfo.fulfilled
                  : false
              }
            />
          </React.Fragment>
        ) : (
          []
        )}
      </React.Fragment>
    );
  }
  if (errorSOCounterReport) {
    return <div>{errorSOCounterReport}</div>;
  }
  return <LinearProgress />;
};

export default CounterReport;
