import React from 'react';
import LastReportsGeneratedTable from '../../tables/Dashboard/LastReportsGeneratedTable';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { CircularProgress } from '@material-ui/core';
import TablePagination from '../../pagination/table/TablePagination';
import { useTranslation } from 'react-i18next';
import ReportsTableFilterForm from '../../forms/reports/ReportsTableFilterForm';

const ReportsView = ({ infoReports, paginationAction, reportsFilter, filterChanged, submitSuccess }) => {
  const { loading, reports } = infoReports;
  const { t } = useTranslation(['visits']);
console.log(reportsFilter.where)
console.log(filterChanged)
  return (
    <Card>
      <CardBody>
        <ReportsTableFilterForm
          initialValues={reportsFilter.where}
          submitSuccess={submitSuccess}
        />

        <TablePagination
          data={reports.allReports ? reports.allReports : []}
          paginationAction={paginationAction}
          filter={reportsFilter}
        />
        {loading ? (
          <div style={styles.progressCnt}>
            <CircularProgress style={styles.progress} />
          </div>
        ) : (
          <LastReportsGeneratedTable
            paginationAction={paginationAction}
            reportsFilter={reportsFilter}
            lastReports={reports.allReports ? reports.allReports : []}
            type={true}
            name={true}
            nameAction={false}
            action={true}
            user={true}
            email={true}
            status={true}
            created={true}
            completedDate={true}
            url={true}
            pageSize={15}
          />
        )}
      </CardBody>
    </Card>
  );
};

const styles = {
  progress: {
    margin: '16px'
  },
  progressCnt:{
    width: '100px !important',
    marginLeft: '50%',
    marginRight: '50%'
  },
};
 export default ReportsView;