import React from 'react'
import ReactTable from 'react-table';
import PropTypes from "prop-types";
import DivCenter from '../../components/styled-components/DivCenter';
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Link } from 'react-router-dom';
import {  Tooltip } from '@material-ui/core';
import EditIcon from "@material-ui/icons/Edit";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from '@material-ui/icons/Visibility';
import SearchIcon from '@material-ui/icons/Search';
import useGlobal from '../../store';
import auth from '../../utils/auth';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const ChecklistAnalisysTable = ({data, submitSuccess, setModalStatus}) => {
    const { t } = useTranslation("checklistAnalisys");

    const [session] = useGlobal((state) => state.session);

    const hasPermission = (permission) => {
      if (
        session.user &&
        !auth.hasPermission(permission, session.permissions)
      ) {
        return false;
      } else {
        return true;
      }
    };

    const getActions = (original) => {
      return (
        <div>
          {original.type === "SummaryVisit"  ? (
            <Tooltip title={t("checklistAnalisys.tooltip.generateReport")}>
              <Button 
                justIcon 
                round 
                simple 
                color="info"
                onClick={()=>{
                  setModalStatus({
                    open: true,
                    reportConfig: original
                  })
                }}>
                  <PictureAsPdfIcon />
              </Button>
            </Tooltip>
          ) : (
            ""
          )}
          {original.type === "CustomVisit" ? (
            <Tooltip title={t("checklistAnalisys.tooltip.answers")}>
              <Link
                to={{
                  pathname: `/list/visitAnswers`,
                  state: {
                    visit: original,
                  },
                }}
              >
                <Button justIcon round simple color="info">
                  <SearchIcon />
                </Button>{" "}
              </Link>
            </Tooltip>
          ) : (
            ""
          )}

          <Tooltip title={t("checklistAnalisys.tooltip.view")}>
            <Link
              to={{
                pathname: `/edit/checklistAnalisysVisit`,
                state: {
                  visit: original,
                  disabled: true,
                },
              }}
            >
              <Button justIcon round simple color="info">
                <VisibilityIcon />
              </Button>{" "}
            </Link>
          </Tooltip>

          {(original.type === "ExitVisit" || original.type === "CustomVisit") &&
          hasPermission("reportCenterEdit") ? (
            <Tooltip title={t("checklistAnalisys.tooltip.edit")}>
              <Link
                to={{
                  pathname: `/edit/checklistAnalisysVisit`,
                  state: {
                    visit: original,
                    disabled: false,
                  },
                }}
              >
                <Button justIcon round simple color="info">
                  <EditIcon />
                </Button>{" "}
              </Link>
            </Tooltip>
          ) : (
            ""
          )}

          {hasPermission("reportCenterArchived") ? (
            <Tooltip title={t("checklistAnalisys.tooltip.archived")}>
              <Button
                justIcon
                round
                simple
                color="danger"
                onClick={() => {
                  submitSuccess(original);
                }}
              >
                <DeleteForever />
              </Button>
            </Tooltip>
          ) : (
            ""
          )}
        </div>
      );
    }

    return (
      <ReactTable
        data={data}
        columns={[
          {
              Header: <DivCenter>{t('checklistAnalisys.list.columns.generated')}</DivCenter>,
              accessor: "count",
              filterable: false,
              sortable: false,
              style: { textAlign: "center" },
              minWidth: 15
          },
          {
            Header: <DivCenter>{t('checklistAnalisys.list.columns.type')}</DivCenter>,
            accessor: "type",
            Cell: ({original}) => t(`checklistAnalisys.list.static.type.${original.type}`),
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 20
          },
          {
          Header: <DivCenter>{t('checklistAnalisys.list.columns.name')}</DivCenter>,
            accessor: "name",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 30
          },
          {
            Header: <DivCenter>{t('checklistAnalisys.list.columns.visit')}</DivCenter>,
            accessor: "visitRef",
            Cell: ({original})=> {
              return original.visitRef.name
            },
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 30
          },
          {
            Header: <DivCenter>{t('checklistAnalisys.list.columns.keyfield')}</DivCenter>,
            accessor: "keyField",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 30
          },
          {
            Header: <DivCenter>{t('checklistAnalisys.list.columns.emails')}</DivCenter>,
            accessor: "emails",
            Cell: ({original}) => {
              return original.emails.join(',')
            },
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 30
          },
          {
            Header: <DivCenter>{t('checklistAnalisys.list.columns.created')}</DivCenter>,
            accessor: "created",
            Cell: ({original}) => {
              return moment(original.created)
                .local()
                .format("DD-MM-YY : hh:mm A");
            },
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 30
          },
          {
            Header: <DivCenter></DivCenter>,
            accessor: "actions",
            Cell: ({original}) => {
              return getActions(original);             
            },
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 30
          },
        ]}
        defaultPageSize={15}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
    );
};

ChecklistAnalisysTable.propTypes = {
    data: PropTypes.array.isRequired,
    submitSuccess: PropTypes.func.isRequired,
}

export default ChecklistAnalisysTable
