import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";

function ElementSelected({ ...props }) {
    const {
        classes,
        value,
        isSelected
    } = props;

    return (
        <Paper className={isSelected ? classes.selected : classes.unSelected}>{value}</Paper>
    );
}

const styles = theme => ({
    unSelected: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
    },
    selected: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        backgroundColor: 'black',
        color: 'white'
    }
});

ElementSelected.propTypes = {
    classes: PropTypes.object.isRequired,
};

ElementSelected.defaultProps = {
};

export default withStyles(styles)(ElementSelected);
