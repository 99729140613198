import React,{useState} from 'react'
import {useQuery } from "react-apollo-hooks";
import { MESSAGES_FEED } from '../Dashboard/gql/Query';
import useGlobal from '../../store';
import CustomMessagesView from '../../Views/Messages/CustomMessagesView';

const CustomMessages = () => {
    const [ session , ] = useGlobal(
      state => state.session,
      actions => actions
    );
    const [messagesFilter, setMessagesFilter] = useState({
        where: {
            app: session.user ? session.appRef._id : "",
        },
        first: 15,
        skip: 0,
      });

      const paginationAction  = async (values) => {
        setMessagesFilter(values);
      }

      const {
        data: dataMessage,
        loading: loadingMessage,
      } = useQuery(MESSAGES_FEED, {
        variables: messagesFilter,
        fetchPolicy: "network-only"
      });
    return (
      <CustomMessagesView
        messages={{ loading: loadingMessage, data: dataMessage }}
        paginationAction={paginationAction}
        messagesFilter={messagesFilter}
      />
    );
};

export default CustomMessages;
