import React from 'react';
import PropTypes from 'prop-types';
import {Field} from "formik";
import {useTranslation} from "react-i18next";

// Core components
import Table from "components/Table/Table.jsx";
import Danger from "components/Typography/Danger.jsx";

// Styled Components
import DivMarginCenter from "../../components/styled-components/DivMarginCenter";
import DivCenter from "../../components/styled-components/DivCenter";

// Custom components
import SuggestionSearchAsync from "../../components/styled/SuggestionSearchAsync";
import ButtonSimpleIcon from "../../components/styled/ButtonSimpleIcon";

// utils
import {getNamespacesFromKeys} from "../../utils/translation/namespaces";

/*
* Custom field Autosuggest multi
* Allows searching and automatically setting field value from a list of suggestions
* It adds all selected values on a table.
* */
const FieldAutosuggestMultiAsync = ({
                                 name, hidden,
                                 suggestionList, getSuggestionName,
                                 getSuggestionId, getSuggestionById,
                                 placeholder, tableTitle, noResultsLabel,
                                 loadSuggestions, error
                               }) => {

  const {t, i18n} = useTranslation(
    getNamespacesFromKeys([tableTitle, noResultsLabel])
  );

  // Generate rows for table from field value
  const getTableFieldValue = (field, form) => {

    if (!field.value) {
      return [];
    }
    // Generate row
    return field.value.reduce((result, id) => {
      const currentSelectedSuggestion = getSuggestionById(id);
      if (currentSelectedSuggestion) {
        // Add row with name and delete button
        result.push([
          getSuggestionName(currentSelectedSuggestion),
          (<DivCenter>
            <ButtonSimpleIcon color="danger" icon="close" onClick={() => {
              const filteredValue = field.value.filter((item) => {
                return item !== id;
              });
              form.setFieldValue(name, filteredValue);
            }}/>
          </DivCenter>)
        ]);
        return result;
      }
      return result;
    }, [])
  };
  // Process suggestion selection
  const suggestionSelected = (id, currentFieldValue, setValue) => {
    // concat id to current field value
    if (!currentFieldValue) {
      setValue(name, [id]);
      return;
    }

    // Check if already added
    const indexOf = currentFieldValue.indexOf(id);

    if (indexOf === -1) {
      const result = currentFieldValue.concat(id);
      setValue(name, result);
    }
  };

  return (
    <Field name={name}>
      {({field, form}) => {
        if (!hidden) {
          return (
            <DivMarginCenter>
              {/* ERROR */}
              {
                !error ? [] : <Danger>{error}</Danger>
              }
              {/*SUGGESTION INPUT*/}
              <SuggestionSearchAsync
                suggestionList={suggestionList}
                selected={(id) => {
                  suggestionSelected(id, field.value, form.setFieldValue);
                }}
                placeholder={placeholder}
                getSuggestionName={getSuggestionName}
                getSuggestionId={getSuggestionId}
                loadSuggestions={loadSuggestions}
              />
              {/*TABLE FOR SELECTED VALUES WITH DEFAULT*/}
              {
                field && field.value && !!field.value.length ?
                  <Table
                    hover
                    tableHead={[t(tableTitle), ""]}
                    tableData={getTableFieldValue(field, form)}
                  /> :
                  <DivMarginCenter>
                    {t(noResultsLabel)}
                  </DivMarginCenter>
              }
            </DivMarginCenter>
          );
        }else {
          return [];
        }
      }}
    </Field>
  );
};

FieldAutosuggestMultiAsync.propTypes = {
  // field name
  name: PropTypes.string.isRequired,
  // suggestion object list
  suggestionList: PropTypes.array.isRequired,
  // how to calculate suggestion name
  getSuggestionName: PropTypes.func.isRequired,
  // how to calculate suggestion id
  getSuggestionId: PropTypes.func.isRequired,
  // how to calculate suggestion from id
  getSuggestionById: PropTypes.func.isRequired,
  // hide component
  hidden: PropTypes.bool,
  // placeholder translation key
  placeholder: PropTypes.string.isRequired,
  // tableTitle translation key
  tableTitle: PropTypes.string.isRequired,
  // noResultsLabel translation key
  noResultsLabel: PropTypes.string.isRequired,
  // wire up to suggestion search
  loadSuggestions: PropTypes.func.isRequired,
};

export default FieldAutosuggestMultiAsync;
