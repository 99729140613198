import React from 'react';
import PropTypes from 'prop-types';
import {Field} from "formik";

// core components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Radio from '@material-ui/core/Radio';

// styled components
import DivCenter from "../../components/styled-components/DivCenter";

const FieldChecklistOptions = ({name, options}) => {
  return (
    <Field name={name} render={({field, form}) => {
      const {setFieldValue} = form;

      return (
        <List>
          {options.map((option, index) => {
            return (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar
                    alt={`Avatar n°${index + 1}`}
                    src={option.image.i480}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <DivCenter>{option.name}</DivCenter>
                </ListItemText>
                <ListItemSecondaryAction>
                  <Radio
                    edge="end"
                    value={option._id}
                    onChange={() => {
                      setFieldValue(name, option._id)
                    }}
                    checked={field.value === option._id}
                    inputProps={{'aria-labelledby': index}}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      );
    }}/>
  );
};

FieldChecklistOptions.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  multi: PropTypes.bool,
};

export default FieldChecklistOptions;
