import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";

// styled components
import DivCenter from "../../components/styled-components/DivCenter";
import "react-table/react-table.css";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import ShuffleOutlinedIcon from "@material-ui/icons/ShuffleOutlined";
import withStyles from "@material-ui/core/styles/withStyles";

function CombinedAnalyticsTable({ classes, data }) {
  return (
    <div >
      <ReactTable
        data={[data]}
        columns={[
          {
            id: "min",
            Header:
                <DivCenter
                  style={{
                    display: "flex",
                    flexDirection: "center",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <ExpandMoreOutlinedIcon />
                  <b>Min</b>
                </DivCenter>,
            accessor: "min",
            filterable: false,
            sortable: false,
            style: {
              textAlign: "center",
              borderBlockStart: "1px solid gray",
              borderRight: "1px solid gray"
            },
            minWidth: 150
          },
          {
            id: "max",
            Header:
              <div>
                <DivCenter
                  style={{
                    display: "flex",
                    flexDirection: "center",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <ExpandLessOutlinedIcon />
                  <b>
                    Max
                  </b>
                </DivCenter>
              </div>,
            accessor: "max",
            filterable: false,
            sortable: false,
            style: {
              textAlign: "center",
              borderBlockStart: "1px solid gray",
              borderRight: "1px solid gray"
            },
            minWidth: 150
          },
          {
            id: "average",
            Header:
              <div>
                <DivCenter
                  style={{
                    display: "flex",
                    flexDirection: "center",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <ShuffleOutlinedIcon />
                  <b>
                    Promedio
                  </b>
                </DivCenter>
              </div>,
            accessor: "average",
            filterable: false,
            sortable: false,
            style: { textAlign: "center", borderBlockStart: "1px solid gray" },
            minWidth: 150
          }
        ]}
        defaultPageSize={1}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
    </div>
  );
}

const style = {
  borderTableFull: {
    border: "1px solid gray"

  }
};

CombinedAnalyticsTable.propTypes = {
  data: PropTypes.object
};

export default withStyles(style)(CombinedAnalyticsTable);
