import React from 'react';
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Button from 'components/Button';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import FiltersSidebar from "components/FiltersSidebar/FiltersSidebar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import SendEmailButton from "components/Reports/SendEmailButton"
import DownloadButton from "components/Reports/DownloadButton"

import history from '../../utils/history';
import VisitsVisorTable from '../../tables/Visits/VisitsVisorTable';
import { getVisitsVisorRows } from '../../utils/analytics/dataManipulation';
import VisitAnswerItem from '../../containers/Visits/VisitsVisorItem/VisitAnswerItem';
import Drawer from "@material-ui/core/Drawer";

const VisitsVisorItemView = ({
  classes,
  activeVisitAnswer,
  keyfields,
  operators,
  visit,
  visits,
  visitAnswers,
  closedVisitAnswer,
}) => {
  const [visitID, setVisitID] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const { loadingVisitAnswersClosedCount, dataVisitanswerClosedCount } = closedVisitAnswer;

  let closedCount = null;
  if (!loadingVisitAnswersClosedCount && dataVisitanswerClosedCount) {
    const { visitAnswerConnection } = dataVisitanswerClosedCount;
    if (visitAnswerConnection) {
      const { aggregate } = visitAnswerConnection;
      closedCount = aggregate.count;
    }
  }

  const { loadingVisitAnswersClosed, dataVisitAnswer } = visitAnswers;

  const handleViewClick = (id) => {
    setVisitID(id);
    setOpen(true);
  };

  const tableData = React.useMemo(
    () => getVisitsVisorRows(dataVisitAnswer)
  , [dataVisitAnswer]);


  return (
    <GridContainer>
      <GridItem xs={3}>
        <FiltersSidebar
          activeVisitAnswer={activeVisitAnswer}
          closedVisitAnswer={closedCount}
          visits={visits}
          visitAnswers={visitAnswers}
          operators={operators}
        />
      </GridItem>
      <GridItem xs={9}>
        <Button
          simple
          size="lg"
          color="info"
          onClick={history.goBack}
          className={classes.buttonNoMargin}
        >
          <ArrowBackIcon/><span>Volver al tablero general</span>
        </Button>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" text>
                <GridContainer>
                  <GridItem xs={6}>
                    <CardText color="info">
                      <h4 className={classes.cardTitle}>Visor de Visitas</h4>
                    </CardText>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <VisitsVisorTable
                  data={tableData}
                  keyfields={keyfields}
                  handleClick={handleViewClick}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={open}
          onClose={() => setOpen(false)}
        >
          <div style={{minWidth: 800, maxWidth: 800}}>
            <Card>
              <CardHeader color="info" text>
                <GridContainer>
                  <GridItem xs={2}>
                    <CardText color="info" className={classes.noPadding}>
                      <SendEmailButton
                        color="white"
                        isReady={false}
                        size="lg"
                      />
                    </CardText>
                  </GridItem>
                  <GridItem xs={2}>
                    <CardText color="info" className={classes.noPadding}>
                      <DownloadButton
                        color="white"
                        isReady={false}
                        size="lg"
                      />
                    </CardText>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {
                  visitID !== null ?
                    <VisitAnswerItem
                      classes={classes}
                      visitAnswerId={visitID}
                      visit={visit}
                    /> : []
                }
              </CardBody>
            </Card>
          </div>
        </Drawer>
      </GridItem>
    </GridContainer>
  );
};

VisitsVisorItemView.propTypes = {
  activeVisitAnswer: PropTypes.object.isRequired,
  keyfields: PropTypes.array.isRequired,
  operators: PropTypes.array.isRequired,
  visit: PropTypes.object,
  visits: PropTypes.array.isRequired,
  visitAnswers: PropTypes.object.isRequired,
};

const styles = theme => ({
  buttonNoMargin:{
    padding: "10px 0px"
  },
  noPadding: {
    padding: 0
  }
});

export default withStyles(styles)(VisitsVisorItemView);
