import React from "react";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";
import cx from "classnames";
import SidebarWrapper from "./SidebarWrapper";
import UserMenu from "./UserMenu";
import AppMenu from "./AppMenu";
import { NavLink } from "react-router-dom";
import LinksMenu from "./LinksMenu";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";

// core components
import HeaderLinks from "components/Header/HeaderLinks";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openApp: false,
      miniActive: true,
      openTickets: this.activeRoute("/tickets")
    };
  }

  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  };

  openCollapse = collapse => {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  };

  isRouteActive = route => {
    return this.state[route];
  };

  render() {
    const { classes, color, logo, routes, bgColor, image, rtlActive, logoText } = this.props;

    // user section
    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive
      });

    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive
      });

    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });

    const caret = classes.caret;

    const collapseItemMini = classes.collapseItemMini;

    const photo = classes.photo;

    const app = (
      <AppMenu
        classes={classes}
        userWrapperClass={userWrapperClass}
        photo={photo}
        openCollapse={this.openCollapse}
        caret={caret}
        openApp={this.state.openApp}
        itemText={itemText}
        collapseItemText={collapseItemText}
        collapseItemMini={collapseItemMini}
      />
    );

    const user = (
      <UserMenu
        classes={classes}
        userWrapperClass={userWrapperClass}
        photo={photo}
        openCollapse={this.openCollapse}
        caret={caret}
        openAvatar={this.state.openAvatar}
        itemText={itemText}
        collapseItemText={collapseItemText}
        collapseItemMini={collapseItemMini}
      />
    );

    //links section

    var links = (
      <LinksMenu
        classes={classes}
        userWrapperClass={userWrapperClass}
        photo={photo}
        openCollapse={this.openCollapse}
        caret={caret}
        openAvatar={this.state.openAvatar}
        itemText={itemText}
        collapseItemText={collapseItemText}
        collapseItemMini={collapseItemMini}
        activeRoute={this.activeRoute}
        miniActiveProps={this.props.miniActive}
        miniActiveState={this.state.miniActive}
        isRouteActive={this.isRouteActive}
        color={color}
        routes={routes}
      />
    );

    // styles for logo
    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive
      });
    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive
      });
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });

    const brand = (
      <div className={logoClasses}>
        <a href="https://beepquest.com" className={logoMini}>
          <img src={logo} alt="logo" className={classes.img} />
        </a>
        <a href="https://beepquest.com" className={logoNormal}>
          <img src={logoText} alt="logo" />
        </a>
      </div>
    );

    //styles for drawer
    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });

    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              app={app}
              headerLinks={<HeaderLinks />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={"left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
          >
            {brand}
            <SidebarWrapper
              user={user}
              app={app}
              className={sidebarWrapper}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue"
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose"
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(sidebarStyle)(Sidebar);
