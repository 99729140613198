export const selectionEnum = {
  all: "0",
  selectedItems: "1"
};

/* 
 * Receives an array with items, each item has a categories object.
 * Returns and array with categories and items inside
 */ 
function bucketItemsToCategories(items){
  let buckets = {};
  items.forEach(item => {
    item.categories.forEach(category => {
      if(buckets[category.id] === undefined) 
        buckets[category.id] = {
          id: category.id,
          name: category.name,
          items: []
        };
      let newItem = {
        id: item.id,
        keyfield: item.keyfield,
        evaluation: category.evaluation
      };
      buckets[category.id].items.push(newItem);
    });
  }); 
  return Object.values(buckets);
}

/* 
 * Receives an array with items, a configuration and seleted items
 * if the configuration equals selectedItems, it should filter the 
 * remaining elements in items
 */
function filterItems(items, config, selectedItems){
  if(config === selectionEnum.all) return items;
  if(!selectedItems) return [];

  const selectedMap = new Map();
  selectedItems.forEach(({value}) => {
    selectedMap.set(value, true);
  });

  const filteredItems = [];
  items.forEach(item => {
    
    if(selectedMap.has(item.keyfield.value)){
      filteredItems.push(item);
    }
  });
  return filteredItems;
}

/* 
 * Calculates the min, max and average from an array of items
 * Returns an object with such values
 */
function getAnalysis(items){
  let acum = 0.0;
  let analysis = {
    min: undefined,
    max: undefined, 
    best: "",
    worst: "",
    average: 0.0
  };

  items.forEach(item => {
    acum += item.evaluation;
    if(!analysis.min || analysis.min > item.evaluation){
      analysis.min = item.evaluation;
      analysis.worst = item.keyfield.value;
    }
    if(!analysis.max || analysis.max < item.evaluation){
      analysis.max = item.evaluation;
      analysis.best = item.keyfield.value;
    }
  });

  analysis.min = analysis.min.toFixed(2);
  analysis.max = analysis.max.toFixed(2);
  analysis.average = (acum / items.length).toFixed(2);

  return analysis;
}

/* 
 * Receives an array with categories and its items
 * generate the analysis per category using the items
 * Return an array addding the analysis
 */
function doAnalysisPerCategory(categories){
  let categoriesWithAnalysis = [];
  categories.forEach(category => {
    const analysis = getAnalysis(category.items);
    categoriesWithAnalysis.push({...category, analysis})
  });
  return categoriesWithAnalysis;
}

/* 
 * Receives an array with items, a configuration and seleted items
 * if the configuration equals selectedItems, it should filter the 
 * remaining elements in items
 */
export function calculateWithConfig(items, config, selectedItems){
  
  const filteredItems = filterItems(items, config, selectedItems);
  const categories = bucketItemsToCategories(filteredItems);
  const withAnalysis = doAnalysisPerCategory(categories);
  return withAnalysis;
}

export function getUniqueKeyfields(items){
  if(!items) return [];
  let bucket = {};
  items.forEach(item => {
    if(!bucket[item.keyfield.value]){
      bucket[item.keyfield.value] = {
        ...item.keyfield,
        count: 0
      };
    }
    bucket[item.keyfield.value].count++;
  });
  return Object.values(bucket);
}

export function supervisionAnalysis(items){
  let analysis = {
    uniqueKeyfields: 0,
    totalSupervisions: 0,
    averageSupervisions: 0.0 
  }; 
  if(!items || items.length === 0) return analysis;

  const uniqueKeyfields = getUniqueKeyfields(items);
  analysis.uniqueKeyfields = uniqueKeyfields.length;
  analysis.totalSupervisions = uniqueKeyfields.reduce((acumm, current) => acumm + current.count, 0);
  analysis.averageSupervisions = analysis.totalSupervisions / analysis.uniqueKeyfields;

  return analysis;
}