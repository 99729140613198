import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Field, FieldArray, Form, withFormik} from "formik";
import * as yup from 'yup';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";

// icons
import SaveAlt from "@material-ui/icons/SaveAlt";

// custom components
import CardTitle from "../components/styled/CardTitle";
import RadioButton from "../components/styled/RadioButton";
import ButtonIcon from "../components/styled/ButtonIcon";


// styled components
import DivCenter from "components/styled-components/DivCenter";

// custom fields
import FieldAutosuggestSingle from "./fields/FieldAutosuggestSingle";
import FieldEmailArrayTags from "./fields/FieldEmailArrayTags";

//Utils
import {hasError, getErrorLabel} from "../utils/forms/inputs";
import {getAllowedFinisherModules, getAllowedTriggerModules} from "../utils/forms/filters";

// local state
import useGlobal from "../store";
import {Chip, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { green } from '@material-ui/core/colors';
import FormHelperText from '@material-ui/core/FormHelperText';

/*
* Validation Schema for NewTicketForm
* */
const newTicketValidationSchema = yup.object().shape({
  name: yup.string().required('isRequired'),
  description: yup.string(),
  emails: yup.array().of(yup.string().email('notEmail')),
  whoResolves: yup.string().required(),
  finisher: yup.string().required('isRequired'),
  triggers: yup.array().of(yup.string().required('isRequired')).required('isRequired'),
  pdfReportConfig: yup.object().shape({
    emails: yup.array().of(yup.string().email()),
    emaiToWhoReport: yup.boolean(),
    emaiToWhoResolve: yup.boolean()
  })
});

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const NewTicketForm = props => {

  console.log('NewTicketForm', props)
  // Main hooks
  const { t, } = useTranslation('tickets');
  const [, globalActions] = useGlobal();

  // Formik bag
  const {
    errors,
    touched,
    setFieldValue,
    values,
    submitForm,
    submitCount,
    isSubmitting,
    isValid
  } = props;

  const [segmentOptions, setSegmentOptions] = useState([]);
  const [segmentPendingAssign, setSegmentPendingAssign] = useState([]);

  // Submit validation failed effect
  useEffect(() => {
    const defaultSegment = {_id: '', name: t('tickets.forms.update.segmentDefault')};
    if (props.segments) {
      setSegmentOptions([defaultSegment, ...props.segments])
    }
    if (!values.ticketSegmentAssignConfigs.length) {
      setFieldValue('ticketSegmentAssignConfigs',
        [
          {segment: defaultSegment}
        ])
    }else {
      setFieldValue('ticketSegmentAssignConfigs', values.ticketSegmentAssignConfigs.map((assign) => {
        if (!assign.segment) {
          return {
            ...assign,
            segment: defaultSegment
          }
        }
        return assign;
      }))
    }
  }, []);
  useEffect(() => {
    // process assigned segments
    const assignedSegments = values.ticketSegmentAssignConfigs
      .map(assign => assign.segment).filter(assign => assign);
    const segmentPendingAssign = segmentOptions.filter((segment) => {
      return !assignedSegments.find(assignedSegment => {
        return segment._id === assignedSegment._id;
      });
    });
    setSegmentPendingAssign(segmentPendingAssign)

    // validate and add next row
    const lastAssign = values.ticketSegmentAssignConfigs[values.ticketSegmentAssignConfigs.length - 1];
    if (lastAssign && lastAssign.segment && lastAssign.userMobile && segmentPendingAssign.length) {
      setFieldValue('ticketSegmentAssignConfigs',
        [
          ...values.ticketSegmentAssignConfigs,
          {segment: segmentPendingAssign[0]}
        ])
    }
  }, [values.ticketSegmentAssignConfigs]);
  useEffect(() => {

    if (submitCount > 0 && !isSubmitting && !isValid) {

      const fields = Object.keys(errors);

      if (fields.length) {
        globalActions.notification.openNotification('tr', {
          message: t('tickets.forms.new.validation.failed'),
          color: 'danger'
        });
      }
    }

  }, [submitCount, isSubmitting]);

  const triggerAllowedModule = useMemo(() =>
      getAllowedTriggerModules(props.allowedQuestionModules, values.finisher, values.triggers),
    [props.allowedQuestionModules, values.finisher, values.triggers])

  const finisherAllowedModule = useMemo(() =>
      getAllowedFinisherModules(props.allowedQuestionModulesForFinish, values.triggers, values.finisher),
    [props.allowedQuestionModulesForFinish, values.triggers, values.finisher])

  const handleChange = (event) => {
    setFieldValue(`pdfReportConfig.${event.target.name}`, event.target.checked);
  };

  return (
    <Form>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="info" text>
              <GridContainer>
                <GridItem xs={8}>
                  <CardText color="info">
                    <CardTitle>{t('tickets.forms.new.mainConfig')}</CardTitle>
                  </CardText>
                </GridItem>
                <GridItem xs={4}>
                  <ButtonIcon icon={SaveAlt}
                              label={'tickets.forms.new.saveButton'}
                              color={'info'}
                              block={true}
                              onClick={() => {
                                submitForm();
                              }} />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <Field name="name" render={({field}) => {
                    return (
                      <TextField
                        {...field}
                        id="name"
                        helperText={hasError(errors, 'name') ?
                          getErrorLabel(t,
                            'tickets.forms.fields',
                            'name', errors) :
                          t('tickets.forms.fields.name.helperText')}
                        label={t('tickets.forms.fields.name.label')}
                        success={!hasError(errors, 'name') && touched.name}
                        error={hasError(errors, 'name') && touched.name}
                        margin="normal"
                        type={"text"}
                        fullWidth
                      />                    );
                  }}/>
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <Field name="description" render={({field}) => {
                    return (
                      <TextField
                        {...field}
                        id="description"
                        helperText={t('tickets.forms.fields.description.helperText')}
                        label={t('tickets.forms.fields.description.label')}
                        type={"text"}
                        fullWidth
                        success={!hasError(errors, 'description') && touched.description}
                        error={hasError(errors, 'description')}
                      />
                    );
                  }}/>
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <FieldEmailArrayTags
                    name={'emails'}
                    placeholder={'tickets.forms.fields.emails.placeholder'}
                    label={'tickets.forms.fields.emails.label'} />
                </GridItem>
                <GridItem xs={12}>
                  <h4>{t('tickets.forms.update.pdfReportConfiguration')}</h4>
                </GridItem>

                <GridItem xs={12}>
                  <FormHelperText>{t('tickets.forms.update.pdfConfigurationHelper')}</FormHelperText>
                </GridItem>
                <GridItem xs={12}>
                  <GridContainer justify="center">
                    <GridItem xs={6}>
                      <FormControlLabel
                        control={
                          <GreenSwitch 
                            checked={values.pdfReportConfig.emaiToWhoReport} 
                            onChange={handleChange} 
                            name="emaiToWhoReport" 
                          />}
                        label={t('tickets.forms.fields.emaiToWhoReport.label')}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <FormControlLabel
                        control={
                          <GreenSwitch 
                            checked={values.pdfReportConfig.emaiToWhoResolve} 
                            onChange={handleChange} 
                            name="emaiToWhoResolve" 
                          />}
                        label={t('tickets.forms.fields.emaiToWhoResolve.label')}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12} sm={12}>
                  <FieldEmailArrayTags
                    name={'pdfReportConfig.emails'}
                    placeholder={'tickets.forms.fields.pdfEmails.label'}
                    label={'tickets.forms.fields.pdfEmails.label'} />
                </GridItem>
                
                <GridItem xs={12}>
                  <h4>{t('tickets.forms.update.interactionsConfig')}</h4>
                </GridItem>
                <GridItem xs={12}>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    id="triggers"
                    options={triggerAllowedModule || []}
                    getOptionLabel={suggestion => suggestion ? suggestion.module.name : ''}
                    value={values.triggers}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return <Chip label={option.module.name} {...getTagProps({ index })} />;
                      })
                    }
                    onChange={(event, value) => {
                      setFieldValue('triggers', value);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={t('tickets.forms.fields.triggers.label')}
                        success={!hasError(errors, 'triggers') && touched.triggers}
                        error={hasError(errors, 'triggers') && touched.triggers}
                        fullWidth
                        margin="normal"
                      />
                    )}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Autocomplete
                    multiple={false}
                    filterSelectedOptions
                    id="finisher"
                    options={finisherAllowedModule || []}
                    getOptionLabel={suggestion => suggestion ? suggestion.module.name : ''}
                    value={values.finisher}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return <Chip label={option.module.name} {...getTagProps({ index })} />;
                      })
                    }
                    onChange={(event, value) => {
                      setFieldValue('finisher', value);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={t('tickets.forms.fields.finisher.label')}
                        fullWidth
                        success={!hasError(errors, 'finisher') && touched.finisher}
                        error={hasError(errors, 'finisher') && touched.finisher}
                        margin="normal"
                      />
                    )}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <h4>Autoasignación por segmento</h4>
                </GridItem>
                <FieldArray name="ticketSegmentAssignConfigs" render={() => {

                  const renderAssignRow = (assignConfig, index) => {
                    return (
                      <React.Fragment key={index}>
                        <GridItem xs={12} lg={6}>
                          <Autocomplete
                            multiple={false}
                            filterSelectedOptions
                            options={segmentPendingAssign}
                            getOptionLabel={segment => segment.name}
                            value={assignConfig.segment}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => {
                                return <Chip label={option.name} {...getTagProps({ index })} />;
                              })
                            }
                            onChange={(event, value) => {
                              setFieldValue(`ticketSegmentAssignConfigs.${index}.segment`, value);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="standard"
                                label={"Segmento"}
                                fullWidth
                                margin="normal"
                              />
                            )}
                          />
                        </GridItem>
                        <GridItem xs={12} lg={6}>
                          <Autocomplete
                            multiple={false}
                            filterSelectedOptions
                            options={props.userMobiles || []}
                            getOptionLabel={userMobile => userMobile.email}
                            value={assignConfig.userMobile}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => {
                                return <Chip label={option.email} {...getTagProps({ index })} />;
                              })
                            }
                            onChange={(event, value) => {
                              setFieldValue(`ticketSegmentAssignConfigs.${index}.userMobile`, value);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="standard"
                                label={"Usuario a asignar"}
                                fullWidth
                                margin="normal"
                              />
                            )}
                          />
                        </GridItem>
                      </React.Fragment>
                    );
                  }

                  return values.ticketSegmentAssignConfigs
                    .map((assignConfig, index) => renderAssignRow(assignConfig, index));
                }} />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Form>
  );
};

NewTicketForm.propTypes = {
  submitSuccess: PropTypes.func.isRequired,
  segments: PropTypes.arrayOf(PropTypes.object),
  allowedQuestionModules: PropTypes.arrayOf(PropTypes.object),
  allowedQuestionModulesForFinish: PropTypes.arrayOf(PropTypes.object)
};

const NewTicketFormWF = withFormik({
  validationSchema: newTicketValidationSchema,
  mapPropsToValues: props => ({
    name: "",
    description: "",
    emails: [],
    whoResolves: 'User',
    triggers: [],
    finisher: '',
    ticketSegmentAssignConfigs: [],
    pdfReportConfig: {
      emails: [],
      emaiToWhoReport: false,
      emaiToWhoResolve: false,
    },
  }),
  handleSubmit: (values, {props}) => {
    props.submitSuccess(values);
  },
  validateOnChange: false
})(NewTicketForm);

export default NewTicketFormWF;
