import React, { useState } from 'react';
import useGlobal from "../../store";
import { PROCESS_RELATIONSHIP, WORK_PLAN_ELEMENTS_INDICATORS, WORK_PLAN_INDICATORS, GET_VISITS_BY_APP } from './gql/Queries';
import { useQuery, useMutation } from "react-apollo-hooks";
import WorkPlanAdminView from '../../Views/ProcessRelationship/WorkPlanAdminView';
import moment from "moment";
import _ from "lodash";
import { useMemo } from 'react';
import { GENERATE_WORKPLAN_XLS } from '../Reports/gql/Mutations';
import Moment from 'moment';
import { 
  reportCreationFailedNotification, 
  reportCreationOnProgressNotification,
  reportSentNotification,
  reportSentFailedNotification,
} from "../../utils/snackNotifications";

const WorkPlanAdmin = (props) => {
    const { match } = props;

    const processRelationshipId = match?.params?.id;

    const [session, globalActions] = useGlobal(
        (state) => state.session,
        (actions) => actions
    );

    const propsFilterDefaultValues = {
      processRelationshipId: processRelationshipId,
      app: session.user ? session.appRef._id : "",
      status_in: ["Pending"],
      reportedDate_gte: moment(new Date())
        .subtract(1, "months")
        .startOf("day")
        .format(),
      reportedDate_lte: moment(new Date()).endOf("day").format(),
      userId: session.user ? session.user._id : "",
    }

    const [filters, setFilters] = useState(propsFilterDefaultValues);

    const {
      data: indicators,
      loading: loadingIndicators,
      error: errorIndicators,
    } = useQuery(WORK_PLAN_INDICATORS, {
      variables: {
          where: {
            app: session.user ? session.appRef._id : "",
            processRelationshipId: processRelationshipId,
            status_in: ["Pending"],
            userId: session.user ? session.user._id : "",
          }
        
      },
      fetchPolicy: "network-only",
    });

    const {
      data: processRelationship,
      loading: loadingProcessRelationship,
      error: errorProcessRelationship,
      refetch
    } = useQuery(PROCESS_RELATIONSHIP, {
      variables: {
        _id: processRelationshipId,
      },
      fetchPolicy: "network-only",
    });

    const {
        data: elementsActivities,
        loading: loadingElementsActivities,
        error: errorElementsActivities,
        refetch: refetchElements
      } = useQuery(WORK_PLAN_ELEMENTS_INDICATORS, {
        variables: {
          where: filters,
        },
        fetchPolicy: "network-only",
      });

    const {
        data: visitsData,
        loading: loadingVisits,
      } = useQuery(GET_VISITS_BY_APP, {
        variables: {
          where: {
            app: filters.app,
            hasChecklistConfiguration: true,
            isArchived: false
          },
        },
        fetchPolicy: "network-only",
      });

    const elementsSorted = useMemo(() => {
      if (elementsActivities?.getElementsIndicators) {
        return _.orderBy(elementsActivities?.getElementsIndicators, ['indicators.percentagePending'], ['desc'])
      }else{
        return [];
      }
    },[elementsActivities]);
    
    // Add General Data required to all the mutation calls
    const addGeneralData = (payload, emailData) => {
      delete payload.status_in
      let data = {
        processRelationshipId,
        ...payload
      };
      data.session = session;

      return data;
    };

    // Execute the mutation and trigger the notifications
    const executeMutation = (mutation, data, isEmail = false) => {
      mutation({ variables: { data: data }}).then(
        result => {
          if(isEmail) reportSentNotification(globalActions);
          else reportCreationOnProgressNotification(globalActions);
        }, 
        error => {
          if(isEmail) reportSentFailedNotification(globalActions);
          else reportCreationFailedNotification(globalActions);
        });
    };

    const createWorkplanReportMutation = useMutation(GENERATE_WORKPLAN_XLS);

    const createWorkplanReport = React.useCallback((payload) => {
      const data = addGeneralData(payload);
      executeMutation(createWorkplanReportMutation, data);       
    }, [ createWorkplanReportMutation, filters, session, Moment ]);
    
    return (
        <WorkPlanAdminView 
            propsFilterDefaultValues={propsFilterDefaultValues}
            indicators={{ loadingIndicators: loadingIndicators, indicators: indicators }}
            processRelationship={processRelationship?.getProcessRelationship ?? {}}
            elementsActivities={{loadingElements: loadingElementsActivities, elements: elementsSorted}}
            filters={filters}
            filterChanged={(filter, reset=false) => {
                let changedFilter = {
                    ...filters,
                  ...filter
                };           
                
                if(reset){
                  setFilters(propsFilterDefaultValues);
                }else{
                  setFilters(changedFilter);
                }

            }}
            createWorkplanReport={createWorkplanReport}
            visitsData={visitsData}
        />
    )
}

export default WorkPlanAdmin;
