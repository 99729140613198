import {includes} from "ramda";

export const getAllowedTriggerModules = (allowedQuestionModules, finisher, triggers) => {
  const triggerIds = triggers.map(trigger => trigger._id);

  return finisher ? allowedQuestionModules.filter((module) => {
    return module._id !== finisher._id && !includes(module._id, triggerIds);
  }) : allowedQuestionModules;
}

export const getAllowedFinisherModules = (allowedQuestionModules, triggers, finisher) => {
  const triggerIds = triggers.map(trigger => trigger._id);

  return triggers ? allowedQuestionModules.filter((module) => {
    return !includes(module._id, triggerIds) && module._id !== finisher._id
  }) : allowedQuestionModules;
}
