import React from 'react'
import HistoryTable from '../../tables/History/HistoryTable'
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { CircularProgress } from '@material-ui/core';
import TablePagination from '../../pagination/table/TablePagination';

const HistoryView = ({ infoEvents, eventsFilter, paginationAction }) => {
    const { loading, events } = infoEvents;
    return (
      <Card>
        <CardBody>
          <TablePagination
            data={events.allEvent ? events.allEvent : []}
            paginationAction={paginationAction}
            filter={eventsFilter}
          />
          {loading ? (
            <div style={styles.progressCnt}>
              <CircularProgress style={styles.progress} />
            </div>
          ) : (
            <HistoryTable events={events.allEvent ? events.allEvent : []} />
          )}
        </CardBody>
      </Card>
    );
}
const styles = {
  progress: {
    margin: '16px'
  },
  progressCnt:{
    width: '100px !important',
    marginLeft: '50%',
    marginRight: '50%'
  },
};
export default HistoryView;