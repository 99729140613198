import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { Field, Form, withFormik } from "formik";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import ButtonIcon from "components/styled/ButtonIcon";
import { useTranslation } from "react-i18next";
import { TextField, Chip } from "@material-ui/core";
//import { NavLink } from "react-router-dom";
import { getOperationsFromColumns } from "../../utils/analytics/dataManipulation";
import history from "../../utils/history";
import useGlobal from "../../store";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import SelectTimezone, { getTimezoneProps } from "@capaj/react-select-timezone";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import moment from "moment";

const summaryChecklistAnalisysFormSchema = yup.object().shape({
  app: yup.string().required(),
  visit: yup.string().required(),
  keyField: yup.string().required(),
  name: yup.string().required(),
  groups: yup.array(),
  emails: yup.array().required(),
  operation: yup.string().required(),
  type: yup.string().required(),
  programableReport: yup.object().shape({
      message: yup.string().required(),
      reportType: yup.number().required(),
      dateFilter: yup.number().required(),
      intervalCriteria: yup.number().required(),
      intervalDays: yup.array().required(),
      intervalValue: yup.number().required(),
      reportContent: yup.string().required(),
      startDateTimezone: yup.object().shape({
        zone: yup.string().required(),
        utc: yup.string().required()
      }),
      endDateTimezone: yup.object().shape({
        zone: yup.string().required(),
        utc: yup.string().required()
      }),
      startDate: yup.date().required(),
      endDate: yup.date().required(),
  }).required()
});

const SummaryChecklistAnalisysReportForm = (props) => {
  const { t } = useTranslation("checklistAnalisys");
  const classes = useStyles();
  let {
    setFieldValue,
    submitForm,
    errors,
    touched,
    initDataForm,
    handleBack,
    values,
    disabled
  } = props;
  const { teams, segments, users, currentVisit, visits } = initDataForm;

  const [visit, setVisit] = useState(null);
  const [key, setKey] = useState(null);
  const [groups, setGroups] = useState([]);
  const [operations, setOperations] = useState([]);
  const [selectedOpreation, setSelectedOpreation] = useState({});
  const [, globalActions] = useGlobal();
  const [dateFilter, setDateFilter] = useState("2");
  const intervalsCriteria = [
      {
          label: t("checklistAnalisys.form.fields.intervalCriteria.options.day"),
          value: 1
      },
      {
        label: t("checklistAnalisys.form.fields.intervalCriteria.options.week"),
        value: 2
      },
      {
        label: t("checklistAnalisys.form.fields.intervalCriteria.options.month"),
        value: 3
      }
  ];

  const defaultIntervalDays = [false, false, false, false, false, false, false];
  const defaultTimezone = {
    zone: 'America/Mexico_City',
    utc: '-5.00'
  };

  const [intervalCriteria, setIntervalCriteria] = useState(intervalsCriteria[0]);
  const [intervalDays, setIntervalDays] = useState( values && values.programableReport ? values.programableReport.intervalDays : defaultIntervalDays);

  const renderOperations = (operations) => {
    let globalOperations = [];
    operations.map((operation) => {
      if (operation.id === 5) {
        globalOperations.push("globalEfficacy");
      }

      if (operation.id === 1) {
        globalOperations.push("global");
      }
    });

    setOperations(globalOperations);

    return globalOperations;
  };

  useEffect(() => {
    //New Report config
    if (visits.length > 0 && visit === null && !currentVisit) {
      setVisit(visits[0]);
      setFieldValue("visit", visits[0]._id);
      setFieldValue("keyField", visits[0].keys[0]);
      setKey(visits[0].keys.length > 0 ? visits[0].keys[0] : null);

      const allOperations =
        visits[0].checkListConfiguration &&
        visits[0].checkListConfiguration.columns
          ? getOperationsFromColumns(visits[0].checkListConfiguration.columns)
          : [];

      const globalOperations = renderOperations(allOperations);

      setSelectedOpreation(
        globalOperations.length ? globalOperations[0] : null
      );
      setFieldValue(
        "operation",
        globalOperations.length ? globalOperations[0] : null
      );
    }

    //For Edit operation
    if (visits.length > 0 && visit === null && currentVisit) {
      let visitfind = visits.find((visit) => {
        return visit._id.toString() === currentVisit.visit.toString();
      });
      setVisit(visitfind);
      setKey(currentVisit.keyField ? currentVisit.keyField : null);

      const allOperations =
        visitfind.checkListConfiguration &&
        visitfind.checkListConfiguration.columns
          ? getOperationsFromColumns(visitfind.checkListConfiguration.columns)
          : [];

      const globalOperations = renderOperations(allOperations);
      setSelectedOpreation(
        globalOperations.length ? globalOperations[0] : null
      );
      setFieldValue(
        "operation",
        globalOperations.length ? globalOperations[0] : null
      );
      
      //Programable report config
      const { programableReport } = currentVisit;
      setDateFilter(programableReport.dateFilter.toString());

      const findInterval = intervalsCriteria.find(interval => interval.value === programableReport.intervalCriteria)
      setIntervalCriteria(findInterval)

      
    }


    let tmpGroups = [];
    if (teams.length > 0) {
      teams.map((team) => {
        tmpGroups.push({ ...team, type: "team" });
      });
    }

    if (segments.length > 0) {
      segments.map((segment) => {
        tmpGroups.push({ ...segment, type: "segment" });
      });
    }

    setGroups(tmpGroups);
  }, [props.visits]);

  const changeDateFilter = (event) => {
    setDateFilter(event.target.value);
    setFieldValue('programableReport.dateFilter', parseInt(event.target.value) )
  };

  const changedIntervalDay = (event) => {
      let tmpIntervalDays = intervalDays;
      tmpIntervalDays[parseInt(event.target.name)] = event.target.checked;

    setIntervalDays(tmpIntervalDays);
    setFieldValue('programableReport.intervalDays', tmpIntervalDays);
  };

  return (
    <Form>
      <GridContainer>
        <GridItem xs={6}>
            <GridItem xs={12}>
            <Field
                name="programableReport.message"
                render={({ field }) => {
                return (
                    <TextField
                    disabled={disabled}
                    {...field}
                    id="message"
                    required
                    error={errors.message && touched.message}
                    helperText={
                        errors.message &&
                        touched.message &&
                        t("checklistAnalisys.form.fields.about.error.isRequired")
                    }
                    label={t("checklistAnalisys.form.fields.about.helperText")}
                    margin="normal"
                    fullWidth
                    />
                );
                }}
            />
            </GridItem>
            <GridItem xs={12}>
            <Field
                name="name"
                render={({ field }) => {
                return (
                    <TextField
                    disabled={disabled}
                    {...field}
                    id="name"
                    required
                    error={errors.name && touched.name}
                    helperText={
                        errors.name &&
                        touched.name &&
                        t("checklistAnalisys.form.fields.name.error.isRequired")
                    }
                    label={t("checklistAnalisys.form.fields.name.helperText")}
                    margin="normal"
                    fullWidth
                    />
                );
                }}
            />
            </GridItem>
            <GridItem xs={12}>
                <Autocomplete
                    disabled={disabled}
                    id="visit"
                    value={visit}
                    options={visits}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                        setVisit(newValue);
                        setFieldValue("visit", newValue !== null ? newValue._id : null);
                        setFieldValue("programableReport.reportContent", newValue !== null ? newValue._id : null);
                        setKey(
                            newValue !== null && newValue.keys.length > 0
                            ? newValue.keys[0]
                            : null
                        );
                        setFieldValue(
                            "keyField",
                            newValue !== null && newValue.keys.length > 0
                            ? newValue.keys[0]
                            : null
                        );

                        let visitfind = visits.find((visit) => {
                          return visit._id.toString() === newValue._id.toString();
                        });
        
                        const allOperations =
                        visitfind.checkListConfiguration &&
                        visitfind.checkListConfiguration.columns
                          ? getOperationsFromColumns(visitfind.checkListConfiguration.columns)
                          : [];
                
                        const globalOperations = renderOperations(allOperations);
        
                        setSelectedOpreation(
                          globalOperations.length ? globalOperations[0] : null
                        );
        
                        setFieldValue(
                          "operation",
                          globalOperations.length ? globalOperations[0] : null
                        );
                    }}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("checklistAnalisys.form.fields.visit.helperText")}
                        margin="normal"
                        fullWidth
                        error={errors.visit && touched.visit}
                        helperText={
                        errors.visit &&
                        touched.visit &&
                        t("checklistAnalisys.form.fields.visit.error.isRequired")
                        }
                    />
                    )}
                />
            </GridItem>
            <GridItem xs={12}>
            <Autocomplete
                disabled={disabled}
                id="keyField"
                value={key}
                options={visit !== null ? visit.keys : []}
                onChange={(event, newValue) => {
                    setKey(newValue);
                    setFieldValue("keyField", newValue);
                }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("checklistAnalisys.form.fields.keyField.helperText")}
                    margin="normal"
                    fullWidth
                    error={errors.keyField && touched.keyField}
                    helperText={
                    errors.keyField &&
                    touched.keyField &&
                    t("checklistAnalisys.form.fields.keyField.error.isRequired")
                    }
                />
                )}
            />
            </GridItem>
            <GridItem xs={12}>
            <Autocomplete
                disabled={disabled}
                multiple
                filterSelectedOptions
                id="groups"
                options={groups}
                getOptionLabel={(option) =>
                `${option.name} (${t(`checklistAnalisys.type.${option.type}`)})`
                }
                defaultValue={currentVisit ? currentVisit.groups : []}
                renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                    <Chip
                        label={`${option.name} (${t(
                        `checklistAnalisys.type.${option.type}`
                        )})`}
                        {...getTagProps({ index })}
                    />
                    );
                })
                }
                onChange={(event, value) => {
                setFieldValue("groups", value);
                }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={t("checklistAnalisys.form.fields.groups.helperText")}
                    fullWidth
                    margin="normal"
                />
                )}
            />
            </GridItem>
            <GridItem xs={12}>
            <Autocomplete
                disabled={disabled}
                multiple
                freeSolo
                filterSelectedOptions
                id="emails"
                error={errors.emails && touched.emails}
                errorText={touched.emails && errors.emails}
                options={users.map((user) => user.email)}
                defaultValue={currentVisit ? currentVisit.emails : []}
                renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return <Chip label={option} {...getTagProps({ index })} />;
                })
                }
                onChange={(event, value) => setFieldValue("emails", value)}
                renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={t("checklistAnalisys.form.fields.emails.helperText")}
                    fullWidth
                    margin="normal"
                    error={errors.emails && touched.emails}
                    helperText={
                    errors.emails &&
                    touched.emails &&
                    t("checklistAnalisys.form.fields.emails.error.isRequired")
                    }
                />
                )}
            />
            </GridItem>
            <GridItem xs={12}>
          <Autocomplete
            disabled={disabled}
            id="operation"
            value={selectedOpreation}
            options={operations}
            getOptionLabel={(option) =>
              t(`checklistAnalisys.operations.${option}`)
            }
            onChange={(event, newValue) => {
              setSelectedOpreation(newValue);
              setFieldValue("operation", newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("checklistAnalisys.form.fields.operation.helperText")}
                margin="normal"
                fullWidth
                error={errors.operation && touched.operation}
                helperText={
                  errors.keyField &&
                  touched.keyField &&
                  t("checklistAnalisys.form.fields.operation.error.isRequired")
                }
              />
            )}
          />
        </GridItem>
        </GridItem>
        <GridItem xs={6}>
            <GridItem xs={12}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">{t("checklistAnalisys.form.fields.dateFilter.label")}</FormLabel>
                    <RadioGroup aria-label="gender" name="dateFilter" value={dateFilter} onChange={changeDateFilter}>
                        <FormControlLabel value="2" control={<Radio disabled={disabled} color="primary" />} label={t("checklistAnalisys.form.fields.dateFilter.options.week")} />
                        <FormControlLabel value="3" control={<Radio disabled={disabled} color="primary" />} label={t("checklistAnalisys.form.fields.dateFilter.options.month")} />
                        <FormControlLabel value="4" control={<Radio disabled={disabled} color="primary" />} label={t("checklistAnalisys.form.fields.dateFilter.options.quarterly")} />
                        <FormControlLabel value="5" control={<Radio disabled={disabled} color="primary" />} label={t("checklistAnalisys.form.fields.dateFilter.options.biannual")} />
                        <FormControlLabel value="6" control={<Radio disabled={disabled} color="primary" />} label={t("checklistAnalisys.form.fields.dateFilter.options.year")} />
                    </RadioGroup>
                </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <GridContainer>
                <GridItem xs={12} sm={5} md={4} lg={4}>
                  <Autocomplete
                      disabled={disabled}
                      id="intervalCriteria"
                      value={intervalCriteria}
                      options={intervalsCriteria}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, option) => {
                          if (option !== null) {
                              setIntervalCriteria(option)
                              setFieldValue("programableReport.intervalCriteria", option.value);
                          }else{
                              setIntervalCriteria(intervalsCriteria[0])
                          }
                          
                      }}
                      renderInput={(params) => (
                      <TextField
                          {...params}
                          label={t("checklistAnalisys.form.fields.intervalCriteria.label")}
                          margin="normal"
                          fullWidth
                      />
                      )}
                  />
                </GridItem>
                { intervalCriteria.value === 2 ?
                  <GridItem xs={12}> 
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormLabel component="legend">{t("checklistAnalisys.form.fields.intervalDays.labelWeek")}</FormLabel>
                      <FormGroup row>
                          <FormControlLabel
                              control={<Checkbox disabled={disabled} color="primary" checked={intervalDays[0]} onChange={changedIntervalDay} name="0" />}
                              label={t("checklistAnalisys.form.fields.intervalDays.options.monday")}
                          />
                          <FormControlLabel
                              control={<Checkbox disabled={disabled} color="primary" checked={intervalDays[1]} onChange={changedIntervalDay} name="1" />}
                              label={t("checklistAnalisys.form.fields.intervalDays.options.tuesday")}
                          />
                          <FormControlLabel
                              control={<Checkbox disabled={disabled} color="primary" checked={intervalDays[2]} onChange={changedIntervalDay} name="2" />}
                              label={t("checklistAnalisys.form.fields.intervalDays.options.wednesday")}
                          />
                          <FormControlLabel
                              control={<Checkbox disabled={disabled} color="primary" checked={intervalDays[3]} onChange={changedIntervalDay} name="3" />}
                              label={t("checklistAnalisys.form.fields.intervalDays.options.thursday")}
                          />
                          <FormControlLabel
                              control={<Checkbox disabled={disabled} color="primary" checked={intervalDays[4]} onChange={changedIntervalDay} name="4" />}
                              label={t("checklistAnalisys.form.fields.intervalDays.options.friday")}
                          />
                          <FormControlLabel
                              control={<Checkbox disabled={disabled} color="primary" checked={intervalDays[5]} onChange={changedIntervalDay} name="5" />}
                              label={t("checklistAnalisys.form.fields.intervalDays.options.saturday")}
                          />
                          <FormControlLabel
                              control={<Checkbox disabled={disabled} color="primary" checked={intervalDays[6]} onChange={changedIntervalDay} name="6" />}
                              label={t("checklistAnalisys.form.fields.intervalDays.options.sunday")}
                          />
                          
                      </FormGroup>
                  </FormControl>
                  </GridItem>
                  :
                  <GridItem xs={12} sm={6}>
                    <Field
                        name="programableReport.intervalValue"
                          render={({ field }) => {
                          return (
                              <TextField
                              disabled={disabled}
                              {...field}
                              id="intervalValue"
                              required
                              label={t("checklistAnalisys.form.fields.intervalDays.labelDefault")}
                              margin="normal"
                              type="number"
                              min="1"
                              fullWidth
                              />
                          );
                        }}
                    />
                  </GridItem>
                }
                
              </GridContainer>
            </GridItem>
            
            <GridItem xs={12}>
              <FormLabel component="legend">{t("checklistAnalisys.form.labels.expiredDate")}</FormLabel>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Datetime
                      disabled={disabled}
                      defaultValue={ values && values.programableReport ? values.programableReport.startDate : new Date()}
                      onChange={(d)=>{ 
                        setFieldValue("programableReport.startDate", d._d);
                      }}
                      timeFormat={'h:mm a'}
                      inputProps={{ placeholder: "Fecha", disabled: disabled }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <SelectTimezone
                    disabled={disabled}
                    value={ values && values.programableReport ? values.programableReport.startDateTimezone.zone : defaultTimezone.zone}
                    isClearable
                    guess
                    onChange={val => {
                      const timezoneProps = getTimezoneProps(val);

                      setFieldValue("programableReport.startDateTimezone", {
                        zone: val,
                        utc: timezoneProps.time
                      });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Datetime
                      disabled={disabled}
                      defaultValue={values && values.programableReport ? values.programableReport.endDate: moment().add(1, 'M').endOf('day')}
                      onChange={(d)=>{ 
                        setFieldValue("programableReport.endDate", d._d);
                      }}
                      timeFormat={'h:mm a'}
                      inputProps={{ placeholder: "Fecha", disabled: disabled }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <SelectTimezone
                    disabled={disabled}
                    value={values && values.programableReport ? values.programableReport.endDateTimezone.zone : defaultTimezone.zone}
                    isClearable
                    guess
                    onChange={val => {
                      const timezoneProps = getTimezoneProps(val);

                      setFieldValue("programableReport.endDateTimezone", {
                        zone: val,
                        utc: timezoneProps.time
                      });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem>
          <ButtonIcon
            onClick={handleBack}
            icon={Cancel}
            color={"primary"}
            label={t("checklistAnalisys.form.buttons.previous")}
          />
        </GridItem>
        <GridItem>
          <ButtonIcon
            disabled={disabled}
            icon={CheckCircleIcon}
            label={t("checklistAnalisys.form.buttons.accept")}
            color={"info"}
            block={true}
            onClick={() => {
              submitForm();
            }}
          />
        </GridItem>
      </GridContainer>
    </Form>
  );
};

const SummaryChecklistAnalisysReportFormWF = withFormik({
  validationSchema: summaryChecklistAnalisysFormSchema,
  enableReinitialize: true,
  mapPropsToValues: ({ initDataForm }) => {
    const { app, visits, currentVisit } = initDataForm;
    let visit = "";
    let key = "";
    let name = "";
    let groups = [];
    let emails = [];
    let operation = "";
    let programableReport = {
        reportType: 10,
        dateFilter: 2,
        intervalCriteria: 1,
        intervalDays: [ false, false, false, false, false, false, false ],
        intervalValue: 1,
        reportContent: visits.length ? visits[0]._id : "",
        startDateTimezone: {
          zone: 'America/Mexico_City',
          utc: '-5.00'
        },
        endDateTimezone: {
          zone: 'America/Mexico_City',
          utc: '-5.00'
        },
        startDate: new Date(),
        endDate: moment().add(1, 'M').endOf('day')
    };

    if (visits.length > 0 && currentVisit) {
      visit = currentVisit.visit;
      key = currentVisit.keyField;
      name = currentVisit.name;
      groups = currentVisit.groups;
      emails = currentVisit.emails;
      operation = currentVisit.operation;
      programableReport = currentVisit.programableReport;
    }

    return {
      app: app,
      visit: visit,
      keyField: key,
      name: name,
      groups: groups,
      emails: emails,
      operation: operation,
      type: "SummaryVisit",
      programableReport: programableReport,
    };
  },
  handleSubmit: (values, { props }) => {
    props.submitSuccess(values);
  },
})(SummaryChecklistAnalisysReportForm);

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(3),
    },
}));

export default SummaryChecklistAnalisysReportFormWF;
