import React, { useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";

import useGlobal from "../../store";
import { SO_COUNTER_REPORTS } from "./gql/Queries";
import {
  GENERATE_SO_COUNTER_REPORT,
  ARCHIVE_SO_COUNTER_REPORT,
} from "./gql/Mutations";

import CounterReportListView from "../../Views/SO/CounterReportListView";
import { GET_PLAN_FEATURES_APP } from "../Locations/gql/Queries";
import auth from "../../utils/auth";
import history from "../../utils/history";
import { useTranslation } from "react-i18next";

const CounterReportList = () => {
  const { t } = useTranslation("common");
  const [session, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );
  const [archivedMode, setArchivedMode] = useState(false);

  const {
    data: dataSOCounterReports,
    error: errorSOCounterReports,
    loading: loadingSOCounterReports,
    refetch: refetchSOCounterReports,
  } = useQuery(SO_COUNTER_REPORTS, {
    variables: {
      app: session.user ? session.appRef._id : "",
      archived: archivedMode,
    },
    fetchPolicy: "network-only",
  });

  const {
    data: dataPlan,
  } = useQuery(GET_PLAN_FEATURES_APP, {
    variables: {
      where: {
        _id: session.user ? session.appRef._id : '',
      }
    }
  });

  const [generateSoCounterReport] = useMutation(GENERATE_SO_COUNTER_REPORT, {
    onCompleted: (mutatinoDatea) => {
      console.log("mutatinoDatea", mutatinoDatea);
      globalActions.notification.openNotification("tr", {
        message: "Procesando Reporte",
        color: "success",
      });
    },
  });

  const [archiveSoCounterReport] = useMutation(ARCHIVE_SO_COUNTER_REPORT, {
    onCompleted: (mutatinoDatea) => {
      globalActions.notification.openNotification("tr", {
        message: "Status de reporte actualizado",
        color: "success",
      });
      refetchSOCounterReports();
    },
  });

  useMemo(() => {
    if (
      session.user &&
      !auth.hasPermission("soCounterView", session.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: t('common.hasNotPermission'),
        color: "danger"
      });
      history.push('dashboard');
    } 
  }, [session]);

  return (
    <CounterReportListView
      plan={dataPlan?.getPlanFeaturesApp || null}
      soCounterReports={dataSOCounterReports?.soCounterReports || []}
      soCounterReportsLoading={loadingSOCounterReports}
      generateReport={(counterReport) => {
        generateSoCounterReport({
          variables: {
            where: {
              _id: counterReport._id,
            },
            data: {
              userId: session.user._id,
            },
          },
        });
      }}
      isArchivedMode={archivedMode}
      changeArchivedMode={() => {
        setArchivedMode(!archivedMode);
      }}
      archive={(counterReport) => {
        archiveSoCounterReport({
          variables: {
            where: {
              _id: counterReport._id,
            },
            data: {
              archived: !archivedMode,
            },
          },
        });
      }}
    />
  );
};

export default CounterReportList;
