import React from 'react'
import MessagesContentView from '../../Views/Messages/MessagesContentView';

const MessageContent = ({location}) => {
    const { state } = location;
    return (
        <MessagesContentView 
            message={state.message}
        />
    )
};

export default MessageContent;
