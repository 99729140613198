import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CloseIcon from "@material-ui/icons/Close";
import { useQuery } from "react-apollo-hooks";
import { USERS_GH_SEARCH } from "../forms/gql/Queries";
import useGlobal from "../store";
import { Autocomplete } from "@material-ui/lab";
import Button from "../components/Button";
import { UPDATE_TICKET_USERS_ADMINS } from "../containers/Tickets/Mutations";
import Swal from "sweetalert2";
import { useMutation } from "@apollo/react-hooks";
import { useEffect } from "react";
import { TICKET_FEED } from "../containers/Tickets/Queries";

const SecurityAlertsModal = ({ modalStatus, setModalStatus }) => {
  const { t } = useTranslation("tickets");
  const classes = withStyles(styles);

  const [session] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const [currentUserGHSearch, setCurrentUserGHSearch] = useState({
    app_access: session.user ? session.appRef._id : "",
    email_contains: "",
    archived: false,
  });

  const [formData, setFormData] = useState([]);
  const [enableSwitch, setEnableSwitch] = useState(false);

  // Query for search
  const { data: dataUsersGH, loading: loadingUsersGH } = useQuery(
    USERS_GH_SEARCH,
    {
      variables: {
        where: currentUserGHSearch,
      },
    }
  );

  console.log({
    dataUsersGH
  })

  const { data: ticketInfo, loading: ticketLoading } = useQuery(
    TICKET_FEED,
    {
      variables: {
        where: {
            _id: modalStatus.ticket
        },
      },
    }
  );

  const [saveChanges] = useMutation(UPDATE_TICKET_USERS_ADMINS);

  const handleClose = () => {
    setModalStatus({
      open: false,
      ticket: null,
    });

    
  };

  const usersGh = useMemo(() => {
    if (!loadingUsersGH && dataUsersGH) {
      if (formData.length) {
        return dataUsersGH.userGHs.filter(
          (user) => !formData.find((data) => data._id === user._id)
        );
      } else {
        return dataUsersGH.userGHs;
      }
    }

    return [];
  }, [loadingUsersGH, formData]);

  useEffect(() => {
    if (!ticketLoading && ticketInfo?.ticket) {
      const { usersAdmins } = ticketInfo?.ticket;
      setEnableSwitch(
        usersAdmins?.enableSecurity ? usersAdmins?.enableSecurity : false
      );
      setFormData(usersAdmins?.users ? usersAdmins?.users : []);
    }
  }, [modalStatus, ticketInfo]);

  const saveData = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton:
          "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-raised MuiButton-raisedPrimary mr-2",
        cancelButton:
          "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSuccess MuiButton-raised MuiButton-raisedSuccess",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "¡Atención!",
        text: "¿Deseas guardar los cambios?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "No",
        reverseButtons: true,
        didOpen: () => {
          document.querySelector(".swal2-container").style.zIndex = "9999";
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "success",
            title: "Muy bien",
            text: "",
            didOpen: () => {
              document.querySelector(".swal2-container").style.zIndex = "9999";
            },
          });

          saveChanges({
            variables: {
              data: {
                ticketId: modalStatus.ticket,
                enableSecurity: enableSwitch,
                users: formData,
              },
            },
          });
        }
      });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalStatus?.open}
      maxWidth={"xs"}
      fullWidth={true}
    >
      <DialogTitleComponent id="customized-dialog-title" onClose={handleClose}>
        {t("tickets.modals.security.title")}
      </DialogTitleComponent>
      <DialogContent dividers>
        <div className={classes.root}>
          <GridContainer justify="center">
            <GridItem xs={12}>
              {t("tickets.modals.security.enable")}
              <br />
              <Switch
                color="primary"
                checked={enableSwitch}
                onChange={(event) => {
                  setEnableSwitch(event.target.checked);
                }}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  icon: classes.switchIcon,
                  iconChecked: classes.switchIconChecked,
                  bar: classes.switchBar,
                }}
              />
              <hr style={{ marginTop: "16px" }} />
            </GridItem>
          </GridContainer>

          <GridContainer justify="center">
            <GridItem xs={12}>
              <Autocomplete
                disabled={!enableSwitch}
                id="user-admin"
                //value={value}
                options={usersGh}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.fullName}
                renderOption={(option) => (
                  <Fragment>
                    {option.fullName} ({option.email})
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("tickets.modals.security.admins")}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: t("tickets.modals.security.admins"),
                    }}
                  />
                )}
                onChange={(_, option) => {
                  if (option) {
                    const tmpUsers = formData.length
                      ? [...formData, option]
                      : [option];
                    setFormData(tmpUsers);
                  }
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12}>
              <List>
                {formData.map((user, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={user.fullName}
                      secondary={user.email}
                    />
                    <ListItemSecondaryAction>
                      <Tooltip title={t("tickets.modals.security.delete")}>
                        <IconButton
                          disabled={!enableSwitch}
                          edge="end"
                          aria-label={t("tickets.modals.security.delete")}
                          onClick={() => {
                            const tmpData = formData.filter(
                              (data) => data._id !== user._id
                            );
                            setFormData(tmpData);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </GridItem>
            <GridItem xs={12}>
              <Button
                size="sm"
                color={"success"}
                fullWidth
                onClick={() => {
                  saveData();
                }}
              >
                {t("tickets.modals.security.save")}
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const DialogTitleComponent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle disableTypography {...other}>
      <GridContainer>
        <GridItem xs={10}>
          <Typography variant="h6">{children}</Typography>
        </GridItem>
        <GridItem xs={2} style={{ textAlign: "end" }}>
          {onClose ? (
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </GridItem>
      </GridContainer>
    </DialogTitle>
  );
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  deleteButton: {
    "&:hover": {
      color: "red",
    },
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "rgba(0, 0, 0, 0.26)",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex",
  },
  marginTop: {
    marginTop: "50px !important",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default SecurityAlertsModal;
