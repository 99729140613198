export const buildVisitingTimeSubject = ({visit, startDate, endDate}) => {
  return `Tiempo de Visita en ${visit} ${startDate} - ${endDate}`;
}

export const buildVisitingTimePDFBody = ({visit, averageTime, hour, minute, second, percentage}) => {
  return `
    <p>Hola,</p>
    <br>
    <p>En este mail te envío un resumen del análisis de los tiempos que se están demorando los usuarios en la visita de ${visit}.</p>
    <br>
    <p>En general el promedio de visita ha sido de ${averageTime} y el rango de tiempos definido como visita ideal es de ${hour}:${minute}:${second}, en ${percentage}% de las visitas se ha cumplido con el tiempo esperado.</p>
    <p>Adjunto encontrarás un análisis de cuáles son las visitas que cumplieron con el tiempo esperado y cuáles no.</p>
  `;
}
