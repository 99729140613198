import React from 'react'
import MessageTable from '../../tables/Messages/MessageTable';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { CircularProgress } from '@material-ui/core';
import Button from 'components/Button';
import history from '../../utils/history';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TablePagination from '../../pagination/table/TablePagination';

const GeneralMessagesView = ({ messages, paginationAction, messagesFilter }) => {
    
    const { loading, data } = messages;
    return (
      <div>
        <Button
          simple
          size="lg"
          color="info"
          onClick={history.goBack}
          style={styles.buttonNoMargin}
        >
          <ArrowBackIcon />
          <span>Volver al indicadores generales</span>
        </Button>
        <Card>
          <CardBody>
          <TablePagination
              data={data.getMessages ? data.getMessages : []}
              paginationAction={paginationAction}
              filter={messagesFilter}
            />
            {loading ? (
              <div style={styles.progressCnt}>
                <CircularProgress style={styles.progress} />
              </div>
            ) : (
              <MessageTable
                messages={data.getMessages ? data.getMessages : []}
              />
            )}
          </CardBody>
        </Card>
      </div>
    );
}
const styles = {
    progress: {
      margin: '16px'
    },
    progressCnt:{
      width: '100px !important',
      marginLeft: '50%',
      marginRight: '50%'
    },
    buttonNoMargin:{
      padding: "10px 0px"
    },
  };
export default GeneralMessagesView;