import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

import {successColor, warningColor, dangerColor} from "assets/jss/material-dashboard-pro-react.jsx";

import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import withStyles from "@material-ui/core/styles/withStyles";

import { FLAT_CHANGE } from '../../constants';

// styled components
import DivCenter from "../../components/styled-components/DivCenter";
import { relativeChange } from '../../utils/analytics/relativeChange';

function RelativeChangeTable({classes, data, datesData, averageRowData, changeRowData}){

  if(!data || !data.labels || !data.datasets || !data.datasets[0].data) return <div/>;
  const dates = data.labels;
  const values = data.datasets[0].data;
  
  let averageRow = {
    label: "Promedio",
    values: {},
    dataValues: values
  };
  let changeRow = {
    label: "Crecimiento",
    values: {}
  };
  dates.forEach((item, idx, data) => {
    averageRow.values[idx] = parseFloat(values[idx]).toFixed(2);
    changeRow.values[idx] = idx === 0 ? "-" :
      (values[idx] ===  values[idx-1]) ? 0.0 :
        relativeChange(values[idx-1], values[idx]); 
  });

  averageRowData.current = averageRow;
  datesData.current = dates;
  changeRowData.current = changeRow;

  let headers = [{
      Header: (<DivCenter></DivCenter>),
      accessor: "label",
      filterable: false,
      sortable: false,
      style: { textAlign: "center" },
      minWidth: 150
  }];

  const dateHeaders = dates.map((date, index) => {
    return {
      id: index,
      Header: (<DivCenter>{date}</DivCenter>),
      accessor: "values."+index,
      filterable: false,
      sortable: false,
      style: { textAlign: "center" },
      minWidth: 150,
      Cell: (d) => {
        let result = d.value;
        if(typeof d.value === "number"){
          if(d.value === 0.0){
            result = <>{" "+d.value.toFixed(2)}</>;
          } else if (Math.abs(d.value) <= FLAT_CHANGE){
            result = <><TrendingFlatIcon className={classes.trendingFlat}/>{` ${d.value.toFixed(2)}%`}</>;
          } else if(d.value > 0.0){
            result = <><TrendingUpIcon className={classes.trendingUp}/>{` ${d.value.toFixed(2)}%`}</>;
          } else {
            result = <><TrendingDownIcon className={classes.trendingDown}/>{` ${d.value.toFixed(2)}%`}</>;
          }
        }
        return <DivCenter>{result}</DivCenter>;
      }
    };
  });

  headers = [...headers,  ...dateHeaders];

  return (
    <ReactTable
      data={[averageRow, changeRow]}
      columns={headers}
      defaultPageSize={2}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
    />
  );
};

const styles = theme => ({
  trendingUp: {
    color: successColor,
  },
  trendingDown: {
    color: dangerColor
  },
  trendingFlat: {
    color: warningColor
  }
});

RelativeChangeTable.propTypes = {
  data: PropTypes.object
};

export default withStyles(styles)(RelativeChangeTable);

