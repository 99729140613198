module.exports = {
  IndicatorCounter: {
    VisitCounter: "VisitCounter",
    QuestionEngineModuleCounter: "QuestionEngineModuleCounter",
  },
  IndicatorField: {
    FieldSum: "FieldSum",
    FieldAverage: "FieldAverage",
  },
};
