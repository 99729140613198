import React from 'react';
import PropTypes from 'prop-types';
import {compose} from "recompose";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material-ui icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";

// Core components
import Button from "components/CustomButtons/Button.jsx";

const renderIcon = (icon, classes) => {
  if (icon === 'edit') {
    return (<Edit className={classes.icon} />)
  }else if (icon === 'close') {
    return (<Close className={classes.icon} />)
  }else if (icon === 'check') {
    return (<Check className={classes.icon} />)
  }else {
    return (<Check className={classes.icon} />)
  }
};

const ButtonSimpleIcon = ({classes, color, icon, ...props}) => {
  return (
    <Button
      simple
      color={color}
      className={classes.actionButton}
      {...props}
    >
      {renderIcon(icon, classes)}
    </Button>
  );
};

ButtonSimpleIcon.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  key: PropTypes.number,
};

const styles = {
  icon: {
    verticalAlign: "middle",
    width: "17px",
    height: "17px",
    top: "-1px",
    position: "relative"
  },
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px"
    }
  }
};

export default compose(
  withStyles(styles),
)(ButtonSimpleIcon);

