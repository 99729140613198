
export const getNamespacesFromKeys = (keys) => {
  if (keys && keys.length) {
    return keys.reduce((result, key) => {
      if (!!key) {
        const namespace = key.split('.')[0];

        // Check if already added
        if (!!namespace) {
          const indexOf = result.indexOf(namespace);

          if (indexOf === -1) {
            result.push(namespace);
          }
        }
        return result;
      }
    }, []);
  }
  return [];
};
