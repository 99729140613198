import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, Form, withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { isEmpty } from "ramda";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// UI components
import {
  FormControl,
  TextField,
  makeStyles,
  Typography,
  Checkbox,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Popover,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import { flatten } from "ramda";
import Select from "react-select";
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { debounce } from "lodash";

// Utils
import { getErrorLabel, hasError } from "../../utils/forms/inputs";

const WorkPlanFormValidationSchema = yup.object().shape({
  checklistFieldsSelected: yup.array(),
  tableFieldsSelected: yup.array(),
  solutionModuleId: yup
    .object()
    .shape({
      name: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .test("activities-no-solution", "activitiesNoSolution", function (value) {
      const { parent } = this;
      const { checklistFieldsSelected, tableFieldsSelected } = parent;

      if (
        (checklistFieldsSelected &&
          checklistFieldsSelected.length &&
          checklistFieldsSelected.filter((field) => field.selected).length >
            0) ||
        (tableFieldsSelected &&
          tableFieldsSelected.length &&
          tableFieldsSelected.filter((field) => field.selected).length > 0)
      ) {
        return value;
      }
      return true;
    }).required(),
});

const WorkPlanForm = (props) => {
  const { t } = useTranslation("processRelationship");
  const classes = useStyles();
  const {
    questionEngineModules,
    checkFields,
    visitsConfigurations,
    updateValues
  } = props;

  

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [labelText, setLabelText] = React.useState(null);
  const [isFormValid, setFormValid] = React.useState(true);

  // Formik bag
  const { errors, touched, values, setFieldValue, submitForm, isValid } = props;
  const { tableFieldsSelected, checklistFieldsSelected } = values;

  useEffect(() => {
    submitForm();
  }, []);
  useEffect(() => {
    /* console.log('====== tables ', arrangeTableFields(  visitsConfigurations,  tableFieldsSelected))
    console.log('====== checklist ', arrangeCheckFields(visitsConfigurations,checklistFieldsSelected)) */
    if (!isValid) {
      updateValues(values, false);
    } else {
      if (
        tableFieldsSelected.some(
          (tableField) => tableField.selected && !tableField.activityName
        ) ||
        checklistFieldsSelected.some(
          (checklistField) =>
            checklistField.selected && !checklistField.activityName
        )
      ) {
        updateValues(values, false);
      } else {
        submitForm();
      }
    }
  }, [values, isValid]);


  const arrangeAllFields = (tableFields, checklistFields) => {
    
    return tableFields.concat(checklistFields)
    .reduce((visits, current) => {
      const visitFound = visits.find(visit => visit._id === current._id);
      const visitIndex = visits.findIndex(visit => visit._id === current._id);
      if (visitFound) {
        current.engines.map(currentEngine => {
          const engine = visitFound.engines.find(engine => engine.moduleId === currentEngine.moduleId);
          const engineIndex = visitFound.engines.findIndex(engine => engine.moduleId === currentEngine.moduleId);
          if (engine) {
            visits[visitIndex].engines[engineIndex].fields.push(...currentEngine.fields)
          }else{
            visits[visitIndex].engines.push(currentEngine);
          }
        });
      }else{
        visits.push(current);
      }

      return visits;
    },[]);

  };

  const arrangeCheckFields = (visitConfigs, currentCheckFields) => {
    return visitConfigs
      .map((visitConfig) => {
        return {
          _id: visitConfig._id,
          name: visitConfig.name,
          engines: visitConfig.engines
            .map((engine) => {
              return {
                moduleId: engine.moduleId,
                name: engine.moduleRef.name,
                fields: currentCheckFields.filter(
                  (field) =>
                    field.visitId === visitConfig._id &&
                    field.moduleId === engine.moduleId
                ).map(field => { return { ...field, type: 16 } }),
              };
            })
            .filter((engine) => engine.fields.length > 0),
        };
      })
      .filter((visitConfig) => visitConfig.engines.length > 0);
  };
  const arrangeTableFields = (visitConfigs, currentTableFields) => {
    return visitConfigs
      .map((visitConfig) => {
        return {
          _id: visitConfig._id,
          name: visitConfig.name,
          engines: visitConfig.engines
            .map((engine) => {
              return {
                moduleId: engine.moduleId,
                name: engine.moduleRef.name,
                fields: currentTableFields.filter(
                  (field) =>
                    field.visitId === visitConfig._id &&
                    field.moduleId === engine.moduleId
                ).map(field => { return { ...field, type: 18 } }),
              };
            })
            .filter((engine) => engine.fields.length > 0),
        };
      })
      .filter((visitConfig) => visitConfig.engines.length > 0);
  };

  const open = Boolean(anchorEl);

  const handleClick = (event, label) => {
    setAnchorEl(event.currentTarget);
    setLabelText(label);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLabelText(null);
  };

  const debouncedUpdateTextField = useCallback(
    debounce((arrayField, index, value) => {
      setFieldValue(`${arrayField}.${index}.activityName`, value);
    }, 10), []
  );

  const handleOnChangeActivityName = (arrayField, index, value) => {
    debouncedUpdateTextField(arrayField, index, value);
  }

  return (
    <Form>
      <GridContainer id={"work-plan-container"} justify="center">
        <GridItem xs={4}></GridItem>
        <GridItem xs={4}>
          <FormControl fullWidth={true}>
            <h4>
              {t(
                "processRelationship.forms.fields.workPlan.solutionModuleId.label"
              )}
            </h4>
            <Select
              value={values.solutionModuleId}
              onChange={(item) => {
                setFieldValue("solutionModuleId", item);
              }}
              options={questionEngineModules.map((module) => ({
                value: module._id,
                label: module.module.name,
              }))}
            />
          </FormControl>
          <div>
            {hasError(errors, "solutionModuleId")
              ? getErrorLabel(
                  t,
                  "processRelationship.forms.fields.workPlan",
                  "solutionModuleId",
                  errors
                )
              : ""}
          </div>
        </GridItem>
        <GridItem xs={4}></GridItem>
        <GridItem xs={12}>
          <br />
        </GridItem>
        <GridItem xs={8}>
          <Typography variant="body2" component="p" className={classes.formControl}>
            {t("processRelationship.forms.fields.workPlan.activities.label")}
          </Typography>

          <GridContainer justify="center" className={classes.workPlanContainer}>
                {arrangeAllFields(
                  arrangeTableFields(visitsConfigurations, tableFieldsSelected),
                  arrangeCheckFields(visitsConfigurations, checklistFieldsSelected)
                ).map(visitConfig => {
                  return (
                    <GridItem xs={12}>
                      <Card
                        className={classes.cardVisit}
                        variant="outlined"
                        key={visitConfig._id}
                      >
                        <CardContent>
                          <Typography variant="h5" component="h3" className={classes.visitTitle}>
                            {visitConfig.name}
                          </Typography>
                          <Divider />
                          {visitConfig.engines.map((engine,index) => {
                            return (
                              <GridContainer key={index}>
                                <GridItem xs={12}>
                                  <Accordion key={engine.moduleId} className={classes.cardVisit}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      key={index}
                                    >
                                      <Typography
                                        component="p"
                                        key={engine.moduleId}
                                        className={classes.formControl}
                                      >
                                        {`Modulo: ${engine.name}`}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails key={engine.moduleId}>
                                      <List className={classes.root}>
                                        {engine.fields.map((field, index) => {
                                          return (
                                            <ListItem key={field._id}>
                                              <ListItemIcon key={index}>
                                                <Checkbox
                                                  edge="start"
                                                  color="default"
                                                  classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked,
                                                  }}
                                                  onChange={() => {
                                                    const currentTableFieldIndex = tableFieldsSelected.findIndex(
                                                      (formTableField) =>
                                                        formTableField.key ===
                                                          field.key &&
                                                        formTableField.moduleId ===
                                                          field.moduleId
                                                    );

                                                    if (
                                                      currentTableFieldIndex !==
                                                      -1
                                                    ) {
                                                      setFieldValue(
                                                        `tableFieldsSelected.${currentTableFieldIndex}.selected`,
                                                        !field.selected
                                                      );
                                                    } else {
                                                      const currentCheckFieldIndex = checklistFieldsSelected.findIndex(
                                                        (formCheckField) =>
                                                          formCheckField.key ===
                                                            field.key &&
                                                          formCheckField.moduleId ===
                                                            field.moduleId
                                                      );
                                                      setFieldValue(
                                                        `checklistFieldsSelected.${currentCheckFieldIndex}.selected`,
                                                        !field.selected
                                                      );
                                                    }
                                                  }}
                                                  checked={field.selected}
                                                />
                                              </ListItemIcon>
                                              <TextField
                                                disabled={!field.selected}
                                                error={
                                                  tableFieldsSelected.some(
                                                    (tableField) =>
                                                      tableField.key ===
                                                        field.key &&
                                                      tableField.selected &&
                                                      !tableField.activityName
                                                  ) ||
                                                  checklistFieldsSelected.some(
                                                    (checklistField) =>
                                                      checklistField.key ===
                                                        field.key &&
                                                      checklistField.selected &&
                                                      !checklistField.activityName
                                                  )
                                                }
                                                id="tableActivityName"
                                                helperText={
                                                  "Nombre de Actividad"
                                                }
                                                type={"text"}
                                                defaultValue={
                                                  field.activityName
                                                }
                                                value={field.activityName || ""}
                                                className={classes.textField}
                                                onChange={({ target }) => {
                                                  /////////
                                                  const currentTableFieldIndex = tableFieldsSelected.findIndex(
                                                    (formTableField) =>
                                                      formTableField.key ===
                                                        field.key &&
                                                      formTableField.moduleId ===
                                                        field.moduleId
                                                  );

                                                  if (
                                                    currentTableFieldIndex !==
                                                    -1
                                                  ) {

                                                    handleOnChangeActivityName('tableFieldsSelected', currentTableFieldIndex, target.value)
                                                  } else {
                                                    const currentCheckFieldIndex = checklistFieldsSelected.findIndex(
                                                      (formCheckField) =>
                                                        formCheckField.key ===
                                                          field.key &&
                                                        formCheckField.moduleId ===
                                                          field.moduleId
                                                    );

                                                    handleOnChangeActivityName('checklistFieldsSelected', currentCheckFieldIndex, target.value)
                                                  }
                                                }}
                                                fullWidth
                                                onFocus={(event) => {
                                                  event.target.select();
                                                }}
                                              />

                                              <ListItemSecondaryAction>
                                                <GridContainer>
                                                  <GridItem xs={8}>
                                                    <TextField
                                                      disabled
                                                      InputProps={{
                                                        classes: {
                                                          disabled:
                                                            classes.rootDisabled,
                                                        },
                                                      }}
                                                      id="label"
                                                      helperText={`Campo  ${
                                                        field.type === 18
                                                          ? "tabla"
                                                          : "calificable"
                                                      }`}
                                                      type={"text"}
                                                      defaultValue={field.key}
                                                      value={field.key || ""}
                                                      fullWidth
                                                    />
                                                  </GridItem>
                                                  <GridItem xs={4}>
                                                    <IconButton
                                                      onClick={(event) => {
                                                        handleClick(
                                                          event,
                                                          field.label
                                                        );
                                                      }}
                                                      edge="end"
                                                      aria-label="info"
                                                    >
                                                      <InfoIcon />
                                                    </IconButton>

                                                    <Popover
                                                      id={"simple-popover"}
                                                      open={open}
                                                      anchorEl={anchorEl}
                                                      onClose={handleClose}
                                                      anchorOrigin={{
                                                        vertical: "top",
                                                        horizontal: "right",
                                                      }}
                                                      transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "left",
                                                      }}
                                                    >
                                                      <Typography
                                                        className={
                                                          classes.typography
                                                        }
                                                      >
                                                        {labelText}
                                                      </Typography>
                                                    </Popover>
                                                  </GridItem>
                                                </GridContainer>
                                              </ListItemSecondaryAction>
                                            </ListItem>
                                          );
                                        })}
                                      </List>
                                    </AccordionDetails>
                                  </Accordion>
                                </GridItem>
                              </GridContainer>
                            );
                          })}
                        </CardContent>
                      </Card>
                    </GridItem>
                  );
                })}
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Form>
  );
};

WorkPlanForm.propTypes = {
  tableFields: PropTypes.array,
  checkFields: PropTypes.array,
  visitsConfigurations: PropTypes.array,
  updateValues: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  visitTitle: {
    margin: theme.spacing(1),
    width: "100%",
    textAlign: "center",
    fontSize: '18px',
    fontWeight: '400'
  },
  formControl: {
    //margin: theme.spacing(1),
    width: "100%",
    //textAlign: "center",
    fontSize: '16px'
  },
  formControlEmail: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formLabel: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  textField: {
    width: "65%",
  },
  radio: {
    "&$checked": {
      color: "#6ab3d4",
    },
  },
  checked: {},
  rootDisabled: {
    color: "black !important",
  },
  typography: {
    padding: theme.spacing(2),
  },
  workPlanContainer: {
    height: '400px',
    overflowY: 'scroll'
  },
  cardVisit: {
    marginTop: '10px'
  }
}));

const getTableFieldsFromCurrentConfig = (visitConfigurations) => {
  return flatten(
    visitConfigurations.map((visitConfig) => {
      return visitConfig.engines.map((engine) => {
        return engine.moduleRef.propertiesRef.fields
          .filter((field) => {
            if (field && !isEmpty(field)) {
              return field.__typename === "TableField";
            }
            return false;
          })
          .map((field) => ({
            ...field,
            visitId: visitConfig._id,
            moduleId: engine.moduleId,
          }));
      });
    })
  );
};

const getCheckFieldsFromCurrentConfig = (checklists, visitConfigurations) => {
  const checklistIds = new Set(checklists.map((checklist) => checklist._id));
  return flatten(
    visitConfigurations.map((visitConfig) => {
      return visitConfig.engines.map((engine) => {
        return engine.moduleRef.propertiesRef.fields
          .filter((field) => {
            if (field && !isEmpty(field)) {
              return (
                field.__typename === "ChecklistField" &&
                checklistIds.has(field.checkList)
              );
            }
            return false;
          })
          .map((field) => ({
            ...field,
            visitId: visitConfig._id,
            moduleId: engine.moduleId,
          }));
      });
    })
  );
};
const initializeCheckFieldsValues = (
  initialCheckListFields,
  currentCheckListFields
) => {
  return currentCheckListFields.map((currentCheckField) => {
    const initialCheckFound = initialCheckListFields.find(
      (checkField) =>
        checkField.visitId === currentCheckField.visitId &&
        checkField.moduleId === currentCheckField.moduleId &&
        checkField.key === currentCheckField.key
    );
    if (initialCheckFound) {
      return {
        ...initialCheckFound,
        label: currentCheckField.label
      };
    } else {
      return {
        ...currentCheckField,
        selected: false,
      };
    }
  });
};

const initializeTableFieldsValues = (
  initialTableFields,
  currentTableFields
) => {
  return currentTableFields.map((currentTableField) => {
    const initialTableFound = initialTableFields.find(
      (tableField) =>
        tableField.visitId === currentTableField.visitId &&
        tableField.moduleId === currentTableField.moduleId &&
        tableField.key === currentTableField.key
    );
    if (initialTableFound) {
      return {
        ...initialTableFound,
        label: currentTableField.label
      };
    } else {
      return {
        ...currentTableField,
        selected: false,
      };
    }
  });
};

const getCurrentChecklistFieldsValues = (
  initialCheckListFields,
  checklists,
  visitConfigurations
) => {
  const currentCheckListFields = getCheckFieldsFromCurrentConfig(
    checklists,
    visitConfigurations
  );
  return initializeCheckFieldsValues(
    initialCheckListFields,
    currentCheckListFields
  );
};

const getCurrentTableFieldsValues = (
  initialTableFields,
  visitConfigurations
) => {
  const currentTableFields = getTableFieldsFromCurrentConfig(
    visitConfigurations
  );
  return initializeTableFieldsValues(initialTableFields, currentTableFields);
};

const WorkPlanFormWF = withFormik({
  validationSchema: WorkPlanFormValidationSchema,
  validateOnChange: true,
  validateOnMount: true,
  mapPropsToValues: (props) => {
    return {
      checklistFieldsSelected: getCurrentChecklistFieldsValues(
        props.initialValues.checklistFieldsSelected || [],
        props.checkFields,
        props.visitsConfigurations
      ),
      tableFieldsSelected: getCurrentTableFieldsValues(
        props.initialValues.tableFieldsSelected || [],
        props.visitsConfigurations
      ),
      solutionModuleId: props.initialValues.solutionModuleId || null,
    };
  },
  handleSubmit: (values, { props }) => {
    props.updateValues(values, true);
  },
})(WorkPlanForm);

export default WorkPlanFormWF;
