import React, { useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ElementActivitiesTable from '../../tables/ProcessRelationship/ElementActivitiesTable';
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import ActivityDetailView from './ActivityDetailView';
import Divider from '@material-ui/core/Divider';
import history from '../../utils/history';
import Button from 'components/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const ElementsActivitiesView = (props) => {
  const {
      indicators,
      processRelationship,
      elementsActivities,
      element,
      setFilters,
      filters
  } = props;

  const [currentRightView, setCurrentRightView] = useState({
    open: false,
    activity: null,
    view: ''
  });

  const usersActivitiesPending =
    indicators?.indicators?.workPlanIndicator?.usersActivitiesPending ?? 0;

  const elementActivitiesPending =
    indicators?.indicators?.workPlanIndicator?.elementActivitiesPending ?? 0;

  const averageActivitiesPending =
    indicators?.indicators?.workPlanIndicator?.averageActivitiesPending ?? 0;

  const totalActivities =
    indicators?.indicators?.workPlanIndicator?.totalAcitivites ?? 0;

  const activitiesCompleted =
    indicators?.indicators?.workPlanIndicator?.activitiesCompleted ?? 0;

  const calculateProgress = () => {
    return (activitiesCompleted * 100) / totalActivities;
  };

  const { t } = useTranslation("processRelationship");

  const { loadingElements } = elementsActivities;

    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Button
            simple
            size="lg"
            color="info"
            onClick={history.goBack}
            style={styles.buttonNoPadding}
          >
            <ArrowBackIcon />
            <span>{t('processRelationship.back')}</span>
          </Button>
          <Typography
            style={{ marginBottom: 40, ...styles.textCenter }}
            variant="h4"
            color="textSecondary"
          >{`${processRelationship?.name
                ? processRelationship.name.toUpperCase()
                : ""}`}</Typography>
        </GridItem>
        <GridItem xs={12}>
          <GridContainer justify="center">
            <GridItem xs={3}>
              <Card>
                <CardHeader>
                  <GridContainer>
                    <GridItem xs={8}>
                      <p style={styles.cardTitle}>
                        {t(
                          "processRelationship.workPlan.indictors.usersActive"
                        )}
                      </p>
                    </GridItem>
                    <Divider orientation="vertical" flexItem />
                    <GridItem xs={3}>
                      <h3 style={styles.title}>{usersActivitiesPending}</h3>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
              </Card>
            </GridItem>

            <GridItem xs={3}>
              <Card>
                <CardHeader>
                  <GridContainer>
                    <GridItem xs={8}>
                      <p style={styles.cardTitle}>
                        {t(
                          "processRelationship.workPlan.indictors.elementAactivitiesActive"
                        )}
                      </p>
                    </GridItem>
                    <Divider orientation="vertical" flexItem />
                    <GridItem xs={3}>
                      <h3 style={styles.title}>{elementActivitiesPending}</h3>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
              </Card>
            </GridItem>

            <GridItem xs={3}>
              <Card>
                <CardHeader>
                  <GridContainer>
                    <GridItem xs={8}>
                      <p style={styles.cardTitle}>
                        {t(
                          "processRelationship.workPlan.indictors.averageElementsActive"
                        )}
                      </p>
                    </GridItem>
                    <Divider orientation="vertical" flexItem />
                    <GridItem xs={3}>
                      <h3 style={styles.title}>{averageActivitiesPending}</h3>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={9} style={styles.progress}>
          <Box alignItems="center">
            <Typography
              style={styles.textCenter}
              variant="h5"
              color="textSecondary"
            >{`ACTIVIDADES COMPLETADAS POR ${
              processRelationship?.name
                ? processRelationship.name.toUpperCase()
                : ""
            }:  ${activitiesCompleted} / ${totalActivities}`}</Typography>
            <BorderLinearProgress
              variant="determinate"
              value={calculateProgress()}
            />
          </Box>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <PlaylistAddCheckIcon />
              </CardIcon>
              <div style={styles.left}>
                <h4
                  style={styles.cardTitle}
                >{`Lista de Actividades de ${element}`}</h4>
              </div>
            </CardHeader>
            <CardBody>
              {loadingElements ? (
                <div style={styles.progressCnt}>
                  <CircularProgress style={styles.progress} />
                </div>
              ) : (
                <ElementActivitiesTable
                  data={elementsActivities.elements}
                  setCurrentRightView={setCurrentRightView}
                  setFilters={setFilters}
                  filters={filters}
                />
              )}

              <React.Fragment>
                <Drawer
                  variant="temporary"
                  anchor={"right"}
                  open={currentRightView.open}
                  onClose={() =>
                    setCurrentRightView({ ...currentRightView, open: false })
                  }
                >
                  <div style={{ minWidth: 450, maxWidth: 450 }}>
                    <ActivityDetailView activity={currentRightView.activity} />
                  </div>
                </Drawer>
              </React.Fragment>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
}

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      margin: 20,
      height: 40,
      borderRadius: 7,
    },
    colorPrimary: {
      backgroundColor: '#d5d5d5',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#6ab3d4',
    },
  }))(LinearProgress);
  
  
  const styles = {
    cardTitle: {
      color: "#3C4858",
      fontSize: "18px",
      marginTop: "30px",
      marginBottom: "25px",
    },
    title: {
      color: "#3C4858",
      textDecoration: "none",
      fontWeight: "500",
      fontSize: "30px",
      marginTop: "30px",
      marginBottom: "25px",
      minHeight: "32px",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      textAlign: "center",
    },
    progress: {
      margin: "16px",
    },
    progressCnt: {
      width: "100px !important",
      marginLeft: "50%",
      marginRight: "50%",
    },
    cardHeight: {
      height: "430px",
    },
    left: {
      float: "left",
    },
    right: {
      float: "right",
    },
    textCenter: {
      textAlign: "center",
    },
    progress: {
      margin: "16px",
    },
    progressCnt: {
      width: "100px !important",
      marginLeft: "50%",
      marginRight: "50%",
    },
    buttonNoPadding: {
      padding: "15px 0px",
    }
  };

export default ElementsActivitiesView;
