import React from "react";
import PropTypes from "prop-types";
import Chart from 'chart.js';

import withStyles from "@material-ui/core/styles/withStyles";

let chart = null;

function LineChart({data}) {
  const chartRef = React.useRef();

  React.useEffect(() => {
    const canvasRef = chartRef.current.getContext("2d");
    if (chart) chart.destroy();
    chart = new Chart(canvasRef, {
      type: "line",
      data: data,
      options: {
        responsive: true
      }
    });
  }, [data]);
  
  return (
    <canvas
      id="myChart"
      ref={chartRef}
    />
  );
}

const styles = theme => ({
});

LineChart.propTypes = {
  data: PropTypes.object.isRequired
};

export default withStyles(styles)(LineChart);
