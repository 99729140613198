export const buildCategoryDashboardSubject = ({keyfield, category, startDate, endDate}) => {
  return `Evolución de ${keyfield} para ${category} en ${startDate} - ${endDate}`;
}

export const buildCategoryDashboardBody = ({keyfield, category, startDate, endDate}) => {
  return `
    <p>Hola,</p>
    <br>
    <p>Adjunto en este correo la evolución de calificación para ${keyfield} en ${category} desde ${startDate} hasta ${endDate} para que pueda evaluar crecimientos, decrecimientos y su comportamiento.</p>
  `;
}
