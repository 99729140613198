import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import Moment from 'moment';

import useGlobal from "../../store";

import DateRangeInput from "./DateRangeInput";
import OperationSelect from "./OperationSelect";
import VisitSelect from "./VisitSelect";
import ActiveVisits from "./ActiveVisits";
import ClosedVisits from "./ClosedVisits";
import VisitsHistory from "./VisitsHistory";

function FiltersSidebar({activeVisitAnswer, visits, visitAnswers, operators, closedVisitAnswer}){
  const [filters, globalActions] = useGlobal(
    state => state.filters,
    actions => actions
  );

  let closedVisitsAnswers = [];

  const { loadingVisitAnswersClosed, dataVisitAnswer } = visitAnswers;

  if (!loadingVisitAnswersClosed) {
    if (dataVisitAnswer) {
      closedVisitsAnswers = dataVisitAnswer.map((visitAnswer) => ({...visitAnswer, checkOutDate: Moment(visitAnswer.checkOutDate)}));;
    }
  }
  /* const closedVisitsAnswers = 
    visitAnswers
      .map((visitAnswer) => ({...visitAnswer, checkOutDate: Moment(visitAnswer.checkOutDate)})); */

  const visitSelectChange = React.useCallback((value) => {
    globalActions.filters.setVisitID(value);
    globalActions.filters.setOperation(null);
  }, [globalActions])

  return (
    <React.Fragment>
      <VisitSelect
        visitsIDs={visits}
        selectedVisitID={filters.selectedVisitID}
        setVisitID={visitSelectChange}
      />
      <OperationSelect
        operations={operators}
        selectedOperation={filters.selectedOperation}
        setOperation={globalActions.filters.setOperation}
      />
      <DateRangeInput
        startDate={filters.range.startDate}
        endDate={filters.range.endDate}
        setStartDateRange={globalActions.filters.setStartDateRange}
        setEndDateRange={globalActions.filters.setEndDateRange}
      />
      <ActiveVisits
        activeVisitAnswer={activeVisitAnswer}
        visitID={filters.selectedVisitID? filters.selectedVisitID.value : ''}
      />
      <ClosedVisits
        count={closedVisitAnswer}
        visitID={filters.selectedVisitID? filters.selectedVisitID.value : ''}
      />
      <VisitsHistory
        visitAnswers={closedVisitsAnswers}
      />
    </React.Fragment>
  );
}

FiltersSidebar.propTypes = {
  activeVisitAnswer: PropTypes.object.isRequired,
  visits: PropTypes.array.isRequired,
  visitAnswers: PropTypes.object.isRequired,
  operators: PropTypes.array.isRequired,
};

export default FiltersSidebar;