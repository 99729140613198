import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import QuestionEngineAnswerView from "../QuestionEngineAnswers/QuestionEngineAnswerView";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ConfirmOptionsIconButton from "components/ConfirmOptionsIconButton/ConfirmOptionsIconButton";
import { RateReview } from "@material-ui/icons";
import WithConfirmComment from "../../modals/ConfirmCommentModal";
import i18n from "../../i18n";
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import { useTranslation } from "react-i18next";

const RequisitionDetailView = ({
  requisition,
  requisitionHeader,
  goToConfirmation,
  processAuthorization,
  callbackApproval
}) => {
  const classes = useStyles();
  let result = [];

  const { elementName, status, _id } = requisitionHeader;
  const { t } = useTranslation("requisitions");

  const approvalComponent = () => {
    return (
      <ConfirmOptionsIconButton
        t={t}
        baseTooltip={"requisitions.approvalRequisition.tooltips.buttonApprove"}
        baseLabel={"requisitions.approvalRequisition.tooltips.labelApprove"}
        baseColor={"info"}
        confirmDelay={5000}
        baseIcon={RateReview}
        confirmOptions={[
          {
            confirmTooltip:
              "requisitions.approvalRequisition.tooltips.buttonAccept",
            confirmOperation: () => {
              goToConfirmation({
                title: i18n.t("common.dialogs.confirmTitle", {
                  ns: "common",
                }),
                message: `Aprobar ${elementName} esta requisición`,
                status: "Approve",
                callback: () => {
                  processAuthorization(_id, {
                    status: "Approved",
                    approvedDate: new Date(),
                  });

                  callbackApproval();
                },
              });
            },
            confirmColor: "success",
            confirmIcon: CheckOutlinedIcon,
          },
          {
            confirmTooltip:
              "requisitions.approvalRequisition.tooltips.buttonReject",
            confirmOperation: () => {
              goToConfirmation({
                title: i18n.t("common.dialogs.confirmTitle", {
                  ns: "common",
                }),
                comment: "",
                status: "Reject",
                callback: (result) => {
                  processAuthorization(_id, {
                    status: "Rejected",
                    ...result,
                  });

                  callbackApproval();
                },
              });
            },
            confirmColor: "danger",
            confirmIcon: CancelIcon,
          },
        ]}
      />
    );
  };

  const approvalButton = status === "Completed" ? approvalComponent : null;

  if (requisition) {
    return (
      <div className={classes.mainContainer}>
        <h4 className={classes.textTitle}>Información de Requisición</h4>
        {elementName ? (
          <h4 className={classes.center}>Elemento: {elementName}</h4>
        ) : (
          []
        )}
        {Object.keys(requisition).reduce((result, id) => {
          if (requisition[id].length > 1) {
            const infoRequisition = requisition[id].find(
              (answer) => answer.status === "Rejected"
            );

            result.push(
              <Fragment>
                <QuestionEngineAnswerView
                  questionEngineAnswer={infoRequisition.questionAnswer}
                  approvalButton={approvalButton}
                />
                <Divider className={classes.divider} />

                <h4 className={classes.textTitle}>Criterio de Rechazo:</h4>
                <Card>
                  <CardBody>
                    <h4 className={classes.center}>
                      {infoRequisition.comment}
                    </h4>
                  </CardBody>
                </Card>
                <Divider className={classes.divider} />
              </Fragment>
            );
          } else {
            result.push(
              <Fragment>
                <QuestionEngineAnswerView
                  questionEngineAnswer={requisition[id][0].questionAnswer}
                  approvalButton={approvalButton}
                />
                <Divider className={classes.divider} />
              </Fragment>
            );
          }

          return result;
        }, [])}
      </div>
    );
  } else {
    return null;
  }
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: 50,
    padding: 15,
  },
  center: {
    textAlign: "center",
  },
  textTitle: {
    fontWeight: "bold",
    textAlign: "center",
  },
  textTitle: {
    fontWeight: "bold",
    textAlign: "center",
  },
  divider: {
    height: "4px",
    backgroundColor: "rgb(0 0 0 / 20%)",
  },
}));

export default WithConfirmComment(RequisitionDetailView);
