import React, { useState, useEffect }  from "react";
import NewRequisitionConfigView from "../../Views/ProcessRelationship/NewRequisitionConfigView";
import {
  PROCESS_RELATIONSHIP,
  MASSIVE_LIST_ELEMENTS,
  GEOFENCE_GROUP_ELEMENTS,
  QUESTION_ENGINES_MODULES_NO_VISIT,
} from "./gql/Queries";
import LoadingCircleView from "../../Views/LoadingCircleView";
import useGlobal from "../../store";
import { CREATE_REQUISITION_CONFIG, UPDATE_REQUISITION_CONFIG } from "./gql/Mutations";
import history from "utils/history";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";

const EditRequisition = ({ match, location }) => {
  const processRelationshipId = match?.params?.id ?? "";
  const requisitionId = match?.params?.requisitionId ?? "";
  const requisition = location?.state?.requisition ?? {};

  /* console.log(processRelationshipId, requisitionId);
  console.log(requisition); */

  const [currentProcessRelationship, setCurrentProcessRelationship] = useState({});
  
  const [session] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const {
    data: processRelationship,
    loading: loadingProcessRelationship,
    error: errorProcessRelationship,
  } = useQuery(PROCESS_RELATIONSHIP, {
    variables: {
      _id: processRelationshipId,
    },
    fetchPolicy: "network-only",
  });

  const {
    data: questionEngineModules,
    loading: loadingQuestionEngine,
  } = useQuery(QUESTION_ENGINES_MODULES_NO_VISIT, {
    variables: {
      app: session.user ? session.appRef._id : "",
    },
    fetchPolicy: "network-only",
  });

  const [
    getMassiveListItems,
    {
      data: massiveListItems,
      loading: loadingMassiveListItems,
      error: errorMassiveListItems,
    },
  ] = useLazyQuery(MASSIVE_LIST_ELEMENTS);
  const [
    getGeofenceGroupItems,
    {
      data: geofenceGroupItems,
      loading: loadingGeofenceGroupItems,
      error: errorGeofenceGroupItems,
    },
  ] = useLazyQuery(GEOFENCE_GROUP_ELEMENTS);

  const [updateRequisition] = useMutation(UPDATE_REQUISITION_CONFIG, {
    onCompleted: (mutationData) => {
      history.goBack();
    },
    onError: (error) => {
        console.log(error)
    }
  });

  useEffect(() => {
    if (!loadingProcessRelationship) {
      const processRelationshipRef =
        processRelationship?.getProcessRelationship ?? {};

      setCurrentProcessRelationship(processRelationshipRef);
      if (processRelationshipRef.elementsType === "MassiveList") {
        getMassiveListItems({
          variables: {
            massiveList: processRelationshipRef.elementsList[0],
          },
        });
      } else {
        getGeofenceGroupItems({
          variables: {
            app: processRelationshipRef.app,
            groupIds: processRelationshipRef.elementsList,
          },
        });
      }
    }
  }, [loadingProcessRelationship]);



 if (!loadingProcessRelationship && !loadingGeofenceGroupItems && !loadingMassiveListItems && !loadingQuestionEngine) {
    
    const relations = currentProcessRelationship?.assignedUserInfo?.relations ?? [];
    let filterElements = [];

    if (currentProcessRelationship.elementsType === "MassiveList") {
        const elementList = massiveListItems?.massiveListItems ?? [];

        filterElements = elementList
          .filter((element) =>
            relations.some((relation) => relation.elementId === element._id)
          )
          .map((element) => ({ 
            _id: element._id, 
            name: element.value,
            tags: relations.find(relation => relation.elementId === element._id)?.tags || []
          }));
    }else{
        const elementList = (geofenceGroupItems?.geofencesGroup ?? []).reduce((result, current) => {
            if (current?.geofences?.length) {
                return result.concat(current.geofences);
            }
            return result
         }, []);
        
        filterElements = elementList
        .filter(element => relations.some(relation => relation.elementId === element._id))
        .map(element => ({
          ...element,
          tags: relations.find(relation => relation.elementId === element._id)?.tags || []
        }));

    }
    
    return (
      <NewRequisitionConfigView
        disabled={true}
        editAction={true}
        requisition={requisition}
        elemets={filterElements}
        questionEngineModules={
          questionEngineModules?.questionEngineModules ?? []
        }
        submitSuccess={(values) => {
          const data = {
            app: session.user ? session.appRef._id : "",
            processRelationshipId: processRelationshipId,
            name: values.name,
            areaName: values.areaName,
            description: values.description,
            expirationDate: values.expirationDate,
            elementsIds: values.elementsIds,
            elementsValues: values.elementsValues,
            solutionType: values.solutionType,
            solutionModuleId: values.solutionModuleId,
            questionEngineName: values.questionEngineName,
            userCreated: session.user._id,
            expiredSwitch: values.expiredSwitch,
          };

          const images = values.images.map(image => {
            if (image.url.includes('https')) {
              return {
                ...image
              };
            }

            return {
              file: image.file
            }
          });

          let variables = {
            data: data,
            images: images,
            where:{
                _id: requisitionId
            }
          };

          updateRequisition({
            variables: variables,
          });
        }}
      />
    );
  }else{
    return <LoadingCircleView />;
  }

};

export default EditRequisition;
