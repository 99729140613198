import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { orderBy } from "lodash";
import Chart from "chart.js";
import * as ChartAnnotation from "chartjs-plugin-annotation";

import {
  IconButton,
  Box,
  Paper,
  Typography,
  Divider,
  LinearProgress,
  Tooltip,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { makeStyles } from "@material-ui/core/styles";
import ReactTable from "react-table";
import Select from "react-select";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/Button";

import DivCenter from "../../../components/styled-components/DivCenter";

import { baseDateAnswerFormat } from "../../../utils/dates/dates";
import {
  formatSelectOptions,
  formatSelectArrayOptionsCustomLabel,
} from "../../../utils/formatter";
import { removeFloatZero } from "../../../utils/numeral/numeral";

const barColorPalette = [
  "#0069A3",
  "#008EBA",
  "#00B0B8",
  "#00CFA1",
  "#95E883",
  "#F9F871",
  "#456BAF",
  "#706AB6",
  "#9668B5",
  "#B865AE",
  "#D563A1",
];

const paginateColumns = (array, page_size, page_number) => {
  return array.slice(
    page_number * page_size,
    page_number * page_size + page_size
  );
};
const formatGeofenceLabel = (element) => {
  const labelParts = element.split("/");
  if (labelParts.length > 1) {
    return labelParts[1];
  } else {
    return labelParts[0];
  }
};
const defaultAllOption = {
  value: "All",
  label: "Todos",
};

let chart = null;
const BarChartKeys = ({ data, valueObjective }) => {
  const chartRef = React.useRef();
  const { t } = useTranslation("common");

  React.useEffect(() => {
    const canvasRef = chartRef.current.getContext("2d");
    if (chart) chart.destroy();
    chart = new Chart(canvasRef, {
      type: "bar",
      data: data,
      options: {
        legend: {
          display: true,
        },
        responsive: true,
        tooltips: {
          display: true,
        },
        hover: {
          animationDuration: 0,
          onHover: function (e) {
            const point = chart.getElementAtEvent(e);
            if (point.length) e.target.style.cursor = "pointer";
            else e.target.style.cursor = "default";
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                max: 100,
                min: 0,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        annotation: {
          annotations: [
            {
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              value: valueObjective,
              borderColor: "rgb(75, 192, 192)",
              borderWidth: 4,
            },
          ],
        },
      },
      plugins: [ChartAnnotation],
    });
  }, [data]);

  return <canvas id="elementsChart" ref={chartRef} />;
};

const ChecklistReportView = ({
  soChecklistReportData,
  calculatedKeyResults,
  calculationsInfo,
  validKeyColumns,
  validKeyTotals,
  columnsPageSize,
  generateReport,
}) => {
  const { t } = useTranslation("so");
  const classes = useStyles();
  const [currentColumnPage, setCurrentColumnPage] = useState(0);
  const [viewType, setViewType] = useState("Table");
  const [currentSelectedElements, setCurrentSelectedElements] = useState([]);
  const [currentSelectedRanges, setCurrentSelectedRanges] = useState([
    defaultAllOption,
  ]);

  const calculatedRangeInfos = useMemo(() => {
    return Object.keys(calculatedKeyResults).map((key) => {
      const rangeInfo = soChecklistReportData.periodicityRanges.find(
        (range) => range.order.toString() === key
      );
      return {
        _id: rangeInfo.order,
        label: `${baseDateAnswerFormat(
          rangeInfo.initialDate
        )} ${baseDateAnswerFormat(rangeInfo.finalDate)}`,
      };
    });
  }, [calculatedKeyResults, soChecklistReportData]);
  const currentColumnsPagination = useMemo(() => {
    const paginatedColumns = paginateColumns(
      validKeyColumns,
      columnsPageSize,
      currentColumnPage
    );
    setCurrentSelectedElements(
      formatSelectArrayOptionsCustomLabel(paginatedColumns, (element) =>
        formatGeofenceLabel(element)
      )
    );
    return paginatedColumns;
  }, [validKeyColumns, currentColumnPage]);
  const calculatedChecklistTable = useMemo(() => {
    const orderedRanges = soChecklistReportData.periodicityRanges.map(
      (range) => {
        const currentDate = Date.now();
        const initialDate = new Date(range.initialDate);
        const finalDate = new Date(range.finalDate);
        return {
          infoRow: range,
          rangePassed: currentDate > initialDate,
          rangePending: currentDate < finalDate,
        };
      }
    );

    // Custom order
    const actualRange = orderedRanges.filter(
      (range) => range.rangePassed && range.rangePending
    );
    const passedRanges = orderBy(
      orderedRanges.filter((range) => range.rangePassed && !range.rangePending),
      ["infoRow.initialDate"],
      ["desc"]
    );
    const pendingRanges = orderBy(
      orderedRanges.filter((range) => range.rangePending && !range.rangePassed),
      ["infoRow.initialDate"],
      ["asc"]
    );
    let totalRows = [{}];

    // Set section headers
    if (actualRange.length > 0) {
      totalRows = totalRows.map((item, index) => {
        if (index === 0) {
          item.actualTitle = true;
        }
        return item;
      });
      let actualRows = actualRange;
      let passedRows = passedRanges;
      let pendingRows = pendingRanges;
      if (passedRanges.length > 0) {
        actualRows = actualRange.map((item, index) => {
          if (index === 0) {
            item.passedTitle = true;
          }
          return item;
        });
      }
      if (pendingRanges.length > 0) {
        if (passedRanges.length > 0) {
          passedRows = passedRows.map((item, index) => {
            if (index === passedRows.length - 1) {
              item.pendingTitle = true;
            }
            return item;
          });
        } else {
          totalRows = totalRows.map((item, index) => {
            if (index === 0) {
              item.pendingTitle = true;
            }
            return item;
          });
        }
      }
      return [...totalRows, ...actualRows, ...passedRows, ...pendingRows];
    } else {
      let passedRows = passedRanges;
      let pendingRows = pendingRanges;
      if (passedRanges.length > 0) {
        totalRows = totalRows.map((item, index) => {
          if (index === 0) {
            item.passedTitle = true;
          }
          return item;
        });
      }
      if (pendingRanges.length > 0) {
        if (passedRanges.length > 0) {
          passedRows = passedRows.map((item, index) => {
            if (index === passedRows.length - 1) {
              item.pendingTitle = true;
            }
            return item;
          });
        } else {
          totalRows = totalRows.map((item, index) => {
            if (index === 0) {
              item.pendingTitle = true;
            }
            return item;
          });
        }
      }
      return [...totalRows, ...passedRows, ...pendingRows];
    }
  }, [soChecklistReportData]);
  const selectedRangeInfos = useMemo(() => {
    const allOptionFound = currentSelectedRanges.find(
      (range) => range.value === "All"
    );
    return allOptionFound
      ? formatSelectOptions(calculatedRangeInfos, "label")
      : currentSelectedRanges;
  }, [currentSelectedRanges]);
  const calculatedColumns = useMemo(() => {
    const labelColumn = {
      Header: () => {
        return <DivCenter>Periodo</DivCenter>;
      },
      Cell: (rowInfo) => {
        const { original, index } = rowInfo;
        if (index === 0) {
          return (
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: 500,
                color: "#3C4858",
              }}
            >
              Total
            </Typography>
          );
        }
        return (
          <Box>
            {baseDateAnswerFormat(original.infoRow.initialDate)}
            <br />
            {baseDateAnswerFormat(original.infoRow.finalDate)}
          </Box>
        );
      },
      filterable: false,
      resizable: false,
      sortable: false,
      style: { textAlign: "center", whiteSpace: "unset" },
    };
    const validKeyResultColumns = () => {
      return currentColumnsPagination.map((column) => ({
        Header: () => {
          return (
            <Tooltip title={column}>
              <DivCenter>{formatGeofenceLabel(column)}</DivCenter>
            </Tooltip>
          );
        },
        Cell: (rowInfo) => {
          const { original, index } = rowInfo;
          if (index === 0) {
            return (
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: 500,
                  color: "#3C4858",
                }}
              >
                {validKeyTotals[column]
                  ? `${removeFloatZero(validKeyTotals[column])} %`
                  : ""}
              </Typography>
            );
          }
          // future ranges
          if (!original.rangePassed && original.rangePending) {
            return <Box>-</Box>;
          }
          // calculated ranges
          const rangeCalculated = calculatedKeyResults[original.infoRow.order];
          if (rangeCalculated) {
            const columnResult = rangeCalculated[column];
            if (columnResult) {
              return (
                <Box>
                  {columnResult ? `${removeFloatZero(columnResult)} %` : "N/A"}
                </Box>
              );
            }
            return <Box>-</Box>;
          }
          return <Box>{"N/A"}</Box>;
        },
        filterable: false,
        resizable: false,
        sortable: false,
        style: { textAlign: "center", whiteSpace: "unset" },
      }));
    };

    if (
      calculationsInfo.processed &&
      calculationsInfo.fulfilled === calculationsInfo.calculations
    ) {
      const resultColumns = validKeyResultColumns();
      return [
        {
          expander: true,
          Expander: (props) => {
            return null;
          },
        },
        labelColumn,
        ...resultColumns,
      ];
    }

    return [
      {
        expander: true,
        Expander: (props) => {
          return null;
        },
      },
      labelColumn,
    ];
  }, [
    calculatedKeyResults,
    validKeyColumns,
    calculationsInfo,
    currentColumnsPagination,
  ]);

  return (
    <GridContainer justify={"center"}>
      <GridItem xs={12} sm={12} md={2}>
        <Card>
          <CardBody>
            <Button
              size="sm"
              color={"info"}
              fullWidth
              onClick={() => {
                generateReport([]);
              }}
            >
              Generar Reporte
            </Button>
            <Divider />

            <p
              className={classes.textCenter}
            >{`En este reporte existen ${validKeyColumns.length} unidades`}</p>
            <p className={classes.textCenter}>{`Estas viendo la pagina ${
              currentColumnPage + 1
            }`}</p>
            <p className={classes.textCenter}>
              <Tooltip title={"Primera"}>
                <IconButton
                  size={"small"}
                  variant={"outlined"}
                  color={"primary"}
                  onClick={() => {
                    setCurrentColumnPage(0);
                  }}
                  disabled={currentColumnPage === 0}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Anterior"}>
                <IconButton
                  size={"small"}
                  variant={"outlined"}
                  color={"primary"}
                  onClick={() => {
                    setCurrentColumnPage(currentColumnPage - 1);
                  }}
                  disabled={currentColumnPage === 0}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Siguiente"}>
                <IconButton
                  size={"small"}
                  variant={"outlined"}
                  color={"primary"}
                  onClick={() => {
                    setCurrentColumnPage(currentColumnPage + 1);
                  }}
                  disabled={
                    (currentColumnPage + 1) * columnsPageSize >=
                    validKeyColumns.length
                  }
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Ultima"}>
                <IconButton
                  size={"small"}
                  variant={"outlined"}
                  color={"primary"}
                  onClick={() => {
                    setCurrentColumnPage(
                      Math.floor(validKeyColumns.length / columnsPageSize)
                    );
                  }}
                  disabled={
                    (currentColumnPage + 1) * columnsPageSize >=
                    validKeyColumns.length
                  }
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </Tooltip>
            </p>
            <FormControl component={"fieldset"}>
              <FormLabel>
                <p>Ver información en forma de:</p>
              </FormLabel>
              <RadioGroup
                row
                aria-label="viewType"
                name="viewType"
                value={viewType}
                onChange={(e) => {
                  setViewType(e.target.value);
                }}
              >
                <FormControlLabel
                  id={"table-mode"}
                  className={classes.formLabel}
                  value="Table"
                  control={
                    <Radio
                      color="default"
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={"Tabla"}
                />
                <FormControlLabel
                  id={"chart-mode"}
                  className={classes.formLabel}
                  value="Chart"
                  control={
                    <Radio
                      color="default"
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={"Graficas"}
                />
              </RadioGroup>
            </FormControl>
            <br />
            {viewType === "Chart" ? (
              <React.Fragment>
                <Divider />
                <br />
                <Select
                  inputId={"select-elements"}
                  placeholder={"Elementos"}
                  value={currentSelectedElements}
                  onChange={(value) => {
                    setCurrentSelectedElements(value);
                  }}
                  isMulti={true}
                  options={formatSelectArrayOptionsCustomLabel(
                    validKeyColumns,
                    (element) => formatGeofenceLabel(element)
                  )}
                />
                <Select
                  inputId={"select-ranges"}
                  placeholder={"Rangos"}
                  value={currentSelectedRanges}
                  onChange={(value) => {
                    const lastOptionSelected =
                      value && value.length > 0
                        ? value[value.length - 1]
                        : null;

                    if (
                      !lastOptionSelected ||
                      lastOptionSelected.value === "All"
                    ) {
                      setCurrentSelectedRanges([defaultAllOption]);
                    } else {
                      setCurrentSelectedRanges(
                        value.filter((option) => option.value !== "All")
                      );
                    }
                  }}
                  isMulti={true}
                  options={[
                    defaultAllOption,
                    ...formatSelectOptions(calculatedRangeInfos, "label"),
                  ]}
                />
              </React.Fragment>
            ) : (
              []
            )}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={10}>
        {viewType === "Table" ? (
          <Paper>
            {calculationsInfo.fulfilled !== calculationsInfo.calculations ? (
              <LinearProgress />
            ) : (
              []
            )}

            <ReactTable
              data={calculatedChecklistTable}
              columns={calculatedColumns}
              pageSize={calculatedChecklistTable?.length || 0}
              showPaginationTop={false}
              showPaginationBottom={false}
              SubComponent={({ original }) => {
                if (
                  original.pendingTitle ||
                  original.passedTitle ||
                  original.actualTitle
                ) {
                  return (
                    <Box
                      borderTop={1}
                      borderColor="grey.200"
                      style={{
                        padding: "20px",
                        marginLeft: "50px",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "18px",
                          fontWeight: 500,
                          color: "#3C4858",
                        }}
                      >
                        {original.pendingTitle ? "Periodos Pendientes" : ""}
                        {original.passedTitle ? "Periodos Pasados" : ""}
                        {original.actualTitle ? "Periodo Actual" : ""}
                      </Typography>
                    </Box>
                  );
                }
                return [];
              }}
              defaultExpanded={calculatedChecklistTable.map(
                (element, index) => {
                  return { index: true };
                }
              )}
              expanded={calculatedChecklistTable.map((element, index) => {
                return { index: true };
              })}
              Expander={(pops) => null}
              getTdProps={(state, rowInfo, column, instance) => {
                if (column.borderLeft) {
                  return {
                    style: {
                      borderLeft: "1px solid #bdbdbd",
                    },
                  };
                }
                return {};
              }}
              className="-highlight"
            />
          </Paper>
        ) : (
          <Paper>
            <GridContainer>
              <GridItem xs={12} style={{ margin: "20px" }}>
                <BarChartKeys
                  valueObjective={soChecklistReportData.targetValue}
                  data={{
                    labels: selectedRangeInfos.map(
                      (rangeCalculated) => rangeCalculated.label
                    ),
                    datasets:
                      currentSelectedElements?.map((element, index) => {
                        return {
                          label: element.label,
                          backgroundColor: barColorPalette[index],
                          data: selectedRangeInfos.map((range) =>
                            calculatedKeyResults[range.value][element.value]
                              ? removeFloatZero(
                                  calculatedKeyResults[range.value][
                                    element.value
                                  ]
                                )
                              : null
                          ),
                        };
                      }) || [],
                  }}
                />
              </GridItem>
            </GridContainer>
          </Paper>
        )}
      </GridItem>
    </GridContainer>
  );
};

ChecklistReportView.propTypes = {
  soChecklistReportData: PropTypes.object.isRequired,
  calculatedKeyResults: PropTypes.object,
  calculationsInfo: PropTypes.object.isRequired,
  validKeyColumns: PropTypes.array.isRequired,
  columnsPageSize: PropTypes.number.isRequired,
  validKeyTotals: PropTypes.object.isRequired,
  generateReport: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  radioControl: {
    width: "100%",
    textAlign: "left",
  },
  formLabel: {
    marginLeft: "15px",
  },
  radio: {
    "&$checked": {
      color: "#6ab3d4",
    },
  },
  headerTitle: {
    fontSize: "12px",
    whiteSpace: "unset",
    fontWeight: "500",
  },
  checked: {},
  textCenter: {
    textAlign: "center",
  },
}));

export default ChecklistReportView;
