import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import Button from "components/CustomButtons/Button.js";
import Favorite from "@material-ui/icons/Favorite";

import withStyles from "@material-ui/core/styles/withStyles";

function ActionRow({ ...props }) {
    const {
        classes,
        children,
        action,
        color,
        classStyle
    } = props;

    return (
        <Button justIcon round simple onClick={action} color={color} className={classStyle}>
            {children}
        </Button>
    );
}

const styles = theme => ({

});

ActionRow.propTypes = {
    children: PropTypes.element,
    action: PropTypes.func,
    color: PropTypes.string,
    classStyle: PropTypes.string
};

ActionRow.defaultProps = {
    children: <Favorite/>,
    action: () => {},
    color: 'info',
    classStyle: 'like'
};

export default withStyles(styles)(ActionRow);
