import gql from "graphql-tag";

export const APPROVAL_ITEMS_STATUS_CHANGED_SUBSCRIPTION = gql`
  subscription approvalItemsStatusChanged($where: ApprovalItemsStatusChangedWhereInput!){
    approvalItemsStatusChanged(where: $where){
      status
      node {
        app
        userId
        userRef {
          email
          fullName
        }
        status
        created
        visitAnswerId
        visitAnswerRef {
          _id
          app
          visitId
          visitRef {
            _id
            name
          }
        }
        approvalLogId
        approvalLogRef {
          _id
          app
          userId
          userRef {
            _id
            firstName
            lastName
            email
          }
          status
          type
          created
        }
        created
      }
    }
  }
`;

export const QUESTION_ANSWERS_UPDATE_LOG_CREATED_SUBSCRIPTION = gql`
  subscription questionAnswerUpdateLogCreated($where: QuestionAnswerUpdateLogCreatedWhereInput!){
    questionAnswerUpdateLogCreated(where: $where){
      node {
        _id
        app
        userId
        userRef {
          _id
          email
        }
        questionAnswersId
        key
        created
      }
    }
  }
`;
