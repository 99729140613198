import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// custom components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from "components/Card/CardFooter.jsx";
import Position from 'components/Position';
import {useTranslation} from "react-i18next";
import DivCenter from "components/styled-components/DivCenter";

const MapFieldAnswerView = ({field, answerData}) => {
  const { t, } = useTranslation('question-engine-module-answer');
  
  const label = useMemo(() => {
    if (field?.completeAddress) {
      return answerData.answer.address ? answerData.answer.address : `LNG: ${answerData.answer.latitude} -  LAT: ${answerData.answer.longitude}`;
    }else{
      return `LNG: ${answerData.answer.longitude} - LAT: ${answerData.answer.latitude}`;
    }
  }, [field]);

  return (
    <Card>
      <CardBody>
        <DivCenter>
          <h4>{label}</h4>
        </DivCenter>
      </CardBody>
      <CardFooter>
        <Position left>
          <small>{`${t("question-engine-module-answer.view.field")} : ${
            answerData.questionLabel
          }`}</small>
        </Position>
        <Position right>
          <small>{`${t("question-engine-module-answer.view.keyField")} : ${
            answerData.key
          }`}</small>
        </Position>
      </CardFooter>
    </Card>
  );
};

MapFieldAnswerView.propTypes = {

};

export default MapFieldAnswerView;
