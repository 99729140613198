import React, {useState} from "react";
import Moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useQuery, useMutation} from "react-apollo-hooks";

import { VISIT_ITEMS, VISIT_ANSWER_ITEMS, VISIT_ANSWER_COUNT, USER_SEGMENT_ITEMS } from "./gql/Queries";
import { 
  CREATE_OPPORTUNITY_AREAS_REPORT_MUTATION,
  CREATE_OPPORTUNITY_AREAS_XLS_REPORT_MUTATION
} from "./gql/Mutations";
import useGlobal from "../../store";
import OpportunityAreasItemView from "../../Views/Visits/OpportunityAreasItemView";
import LoadingCircleView from "../../Views/LoadingCircleView";
import { getOperationsFromColumns, getKeyfields, parseVisitAnswers, classifyAndFilter, filterVisitAnswerByUsersSegment } from "../../utils/analytics/dataManipulation";
import { 
  reportCreationFailedNotification, 
  reportCreationOnProgressNotification,
  reportSentNotification,
  reportSentFailedNotification,
} from "../../utils/snackNotifications";
import LoadingProcessView from "../../Views/LoadingProcessView";
import { SEGMENTS_FEED } from "../Reports/gql/Queries";

function OpportunityAreasItem({match}) {
  const [ session, globalActions] = useGlobal(
    state => state.session,
    actions => actions
  );
  const [ filters, filtersActions ] = useGlobal(
    state => state.filters,
    actions => actions.filters
  );
  const [segment, setSegment] = React.useState({value: "Todos", label: "Todos"});
  const [currentKeyfield, setCurrentKeyField] = React.useState(null);

  const { t, } = useTranslation('visits');
  const visitID = match.params.idVisit === ":idVisit" ? null : match.params.idVisit;
  
  const {data, loading} = useQuery(VISIT_ITEMS, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : '',
        hasChecklistConfiguration: true,
        isArchived: false
      }
    },
    fetchPolicy: 'network-only'
  });

  const {
    data: dataVisitAnswers, 
    loading: loadingVisitAnswers,
  } = useQuery(VISIT_ANSWER_ITEMS, {
      variables: {
        where: {
          userId: session.user._id,
          app: session.appId,
          visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
          checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
          checkInDate_lte: filters.range.endDate,
          approvalStatus_not_in: ["Rejected", "Excluded"],
          hasCheckOutDate: true
        }
      },
      skip: !filters.selectedVisitID,
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    });

    const {
      data: dataVisitAnswersOpen, 
      loading: loadingVisitAnswersOpen
    } = useQuery(VISIT_ANSWER_COUNT, {
        variables: {
          where: {
            userId: session.user._id,
            app: session.appId,
            visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
            checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
            checkInDate_lte: filters.range.endDate,
            approvalStatus_not_in: ["Rejected", "Excluded"],
            hasCheckOutDate: false
          }
        },
        skip: !filters.selectedVisitID,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      });

  const {
      data: dataVisitAnswersClosed, 
      loading: loadingVisitAnswersClosed
    } = useQuery(VISIT_ANSWER_COUNT, {
        variables: {
          where: {
            userId: session.user._id,
            app: session.appId,
            visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
            checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
            checkInDate_lte: filters.range.endDate,
            approvalStatus_not_in: ["Rejected", "Excluded"],
            hasCheckOutDate: true
          }
        },
        skip: !filters.selectedVisitID,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      });

  const { data: dataSegments, loading: loadingSegments } = useQuery(SEGMENTS_FEED, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : "",
        archived: false
      }
    }
  });

const {
    data: dataUserSegment, 
    loading: loadingUserSegment,
  } = useQuery(USER_SEGMENT_ITEMS, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : '',
        segment: segment.value
      }
    },
    skip: segment.value === "Todos",
    fetchPolicy: 'network-only'
  });

  // Add General Data required to all the mutation calls
  const addGeneralData = (payload, emailData) => {
    let data = { query: { 
      visit: filters.selectedVisitID, 
      operation: filters.selectedOperation, 
      ...payload
    }};
    data.visitId = filters.selectedVisitID?  filters.selectedVisitID.value : '';
    data.dateRange = {
      startDate: filters.range.startDate.format("DD-MM-YYYY"),
      endData: Moment(filters.range.endDate).format("DD-MM-YYYY")
    };
    data.app = session.user ? session.appRef._id : '';
    data.session = session;

    if(!!emailData){
      data.sendByEmail = true;
      data.emailInput = {
        contentHtml: emailData.contentHtml,
        subject: emailData.subject,
        to: emailData.to
      }
    }

    return data;
  };

  // Execute the mutation and trigger the notifications
  const executeMutation = (mutation, data, isEmail = false) => {
    mutation({ variables: { data: data }}).then(
      result => {
        if(isEmail) reportSentNotification(globalActions);
        else reportCreationOnProgressNotification(globalActions);
      }, 
      error => {
        console.log(error, data)
        if(isEmail) reportSentFailedNotification(globalActions);
        else reportCreationFailedNotification(globalActions);
      });
  };

  const handleSegmentChange = React.useCallback((option) => {
    setSegment(option);
  }, [setSegment]);

  // Initilize the mutations
  const createOpportunityAreasReportMutation = useMutation(CREATE_OPPORTUNITY_AREAS_REPORT_MUTATION);
  const createOpportunityAreasXLSReportMutation = useMutation(CREATE_OPPORTUNITY_AREAS_XLS_REPORT_MUTATION);
  const createOpportunityAreasReport = React.useCallback((payload) => {
    const data = addGeneralData(payload);
    executeMutation(createOpportunityAreasReportMutation, data); 
  }, [ createOpportunityAreasReportMutation, filters, session, Moment ]);

  const createOpportunityAreasXLSReport = React.useCallback((payload) => {
    const data = addGeneralData(payload);
    executeMutation(createOpportunityAreasXLSReportMutation, data); 
  }, [ createOpportunityAreasXLSReportMutation, filters, session, Moment ]);

  const sendOpportunityAreasPDFReport = React.useCallback((payload, emailData) => {
    const data = addGeneralData(payload, emailData);
    executeMutation(createOpportunityAreasReportMutation, data, true); 
  }, [ createOpportunityAreasReportMutation, filters, session, Moment ]);

  const sendOpportunityAreasXLSReport = React.useCallback((payload, emailData) => {
    const data = addGeneralData(payload, emailData);
    executeMutation(createOpportunityAreasXLSReportMutation, data, true); 
  }, [ createOpportunityAreasXLSReportMutation, filters, session, Moment ]);

  const filteredDataVisitAnswers = dataVisitAnswers && dataVisitAnswers.visitAnswers ? 
    dataVisitAnswers.visitAnswers.filter(visitAnswer => visitAnswer) : [];


  // If the store is empty or different, use the visitID from the route params
  if(data.visits && (
      (!!visitID && !filters.selectedVisitID) || 
      (!!visitID && visitID !== filters.selectedVisitID.value))){
    const option = data.visits.find((visit) => visitID === visit._id);
    filtersActions.setVisitID({label: option.name, value: option._id});
  }

  // Select by default the first visit ID
  if(!visitID && !filters.selectedVisitID && data.visits && data.visits.length > 0) {
    const option = data.visits[0];
    filtersActions.setVisitID({label: option.name, value: option._id});
  }

  let closedCount = null;
  if (!loadingVisitAnswersClosed && dataVisitAnswersClosed) {
    const { visitAnswerConnection } = dataVisitAnswersClosed;
    if (visitAnswerConnection) {
      const { aggregate } = visitAnswerConnection;
      closedCount = aggregate.count;
    }
  }

  if (loading || loadingVisitAnswers || loadingVisitAnswersClosed || loadingVisitAnswersOpen || loadingSegments || loadingUserSegment) {
    return <LoadingProcessView closedCount={closedCount}/>
  } else {
    const selecteVisit = data.visits ? 
      data.visits.find((visit) => 
        filters.selectedVisitID && 
        filters.selectedVisitID.value === visit._id) 
      : null;

    const operators = 
      selecteVisit && selecteVisit.checkListConfiguration 
      && selecteVisit.checkListConfiguration.columns ? 
        getOperationsFromColumns(selecteVisit.checkListConfiguration.columns) 
        : [];

    const selectedOperation = operators.find(
      operator => 
        filters.selectedOperation && 
        filters.selectedOperation.value === operator.id);

    // Select by default the first option
    if(!selectedOperation && operators && operators.length > 0) {
      const option = operators[0];
      filtersActions.setOperation({label: option.name, value: option.id});
    }
    
    const visits = data.visits ? data.visits.map(({_id, name}) => ({id: _id, name})) : []; 
    const keyfields = getKeyfields(selecteVisit);
    const [activeCount, closedVisits] = classifyAndFilter(filteredDataVisitAnswers);
    const parsedClosed = closedVisits ? 
      parseVisitAnswers(closedVisits, keyfields) : [];
    
    const emailData = {
      visit: filters.selectedVisitID?  filters.selectedVisitID.label : '', 
      startDate: filters.range.startDate.format("DD-MM-YYYY"),
      endDate: Moment(filters.range.endDate).format("DD-MM-YYYY")
    };

    const segmentOptions = [ 
      {value: "Todos", label: "Todos"},
      ...dataSegments.segments
      .map((item) => ({
        value: item._id,
        label: item.name
      }))
    ];

    const userSegment = dataUserSegment && dataUserSegment.userSegment ? dataUserSegment.userSegment : [];

    const visitAnswersFilter = filterVisitAnswerByUsersSegment(parsedClosed, userSegment);

    return (
      <OpportunityAreasItemView
        activeVisitAnswer={{ loadingActiveVisitanswers: loadingVisitAnswersOpen, dataOpenVisitanswer: dataVisitAnswersOpen }}
        closedVisitAnswer={{ loadingVisitAnswersClosedCount: loadingVisitAnswersClosed, dataVisitanswerClosedCount: dataVisitAnswersClosed }}
        createOpportunityAreasReport={createOpportunityAreasReport}
        createOpportunityAreasXLSReport={createOpportunityAreasXLSReport}
        emailData={emailData}
        keyfields={keyfields}
        visits={visits}
        visitAnswers={{ loadingVisitAnswersClosed: loadingVisitAnswers, dataVisitAnswer: visitAnswersFilter }}
        operators={operators}
        operation={selectedOperation}
        sendOpportunityAreasPDFReport={sendOpportunityAreasPDFReport}
        sendOpportunityAreasXLSReport={sendOpportunityAreasXLSReport}
        segment={segment}
        segments={segmentOptions}
        handleSegmentChange={handleSegmentChange}
        currentKeyfield={currentKeyfield}
        setCurrentKeyField={setCurrentKeyField}
      />
    );
  }

}

export default OpportunityAreasItem;
