import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

// own components
import QuestionEngineAnswerView from "../QuestionEngineAnswers/QuestionEngineAnswerView";

// custom components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import DivCenter from "components/styled-components/DivCenter";
import Info from "components/Typography/Info.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// core components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';

// utils
import {baseDateFormat, duration} from "../../utils/dates/dates";
import {percentage} from "../../utils/numeral/numeral";
import {getGlobalChecklistColumns} from "../../utils/views/visits";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const VisitAnswerView = ({visit, visitAnswer, editable, processAnswerUpdateLog, questionAnswerUpdateLogs}) => {
  const { t, } = useTranslation(['visits', 'checklist-configuration']);
  const {checkInDate, checkOutDate} = visitAnswer;
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <DivCenter>
                <h4 className={classes.relevantText}>
                  {t('visits.visitAnswer.keyFieldsTitle')}
                </h4>
              </DivCenter>
              <DivCenter className={classes.visitAnswerContainer}>
                {
                  visitAnswer.keys.reduce((result, key, index) => {
                    const keyAnswer = visitAnswer.checkIn.questionAnswersRef.answers.find((answer) => {
                      return answer.key === key;
                    });

                    result.push(
                      <span className={classes.keyFieldAnswer} key={index}>{keyAnswer ?
                        keyAnswer.answer[keyAnswer.answer.__typename] : ""}</span>
                    );
                    if (visitAnswer.keys.length !== index + 1) {
                      result.push(' - ')
                    }
                    return result;
                  }, [])
                }
              </DivCenter>
            </GridItem>
            <GridItem xs={4} className={classes.noPadding}>
              <Paper variant="outlined" className={classes.paper}>
                <DivCenter><p>{t('visits.visitAnswer.entryLabel')}</p></DivCenter>
                <DivCenter><p className={classes.relevantText}>{baseDateFormat(checkInDate)}</p></DivCenter>
              </Paper>

            </GridItem>
            <GridItem xs={4} className={classes.noPadding}>
            <Paper variant="outlined" className={classes.paper}>
              <DivCenter><p>{t('visits.visitAnswer.exitLabel')}</p></DivCenter>
              <DivCenter><p className={classes.relevantText}>{baseDateFormat(checkOutDate)}</p></DivCenter>
              </Paper>
            </GridItem>
            <GridItem xs={4} className={classes.noPadding}>
              <Paper variant="outlined" className={classes.paper}>
              <DivCenter><p>{t('visits.visitAnswer.durationLabel')}</p></DivCenter>
              <DivCenter><p className={classes.relevantText}>{duration(checkInDate, checkOutDate)}</p></DivCenter>
              </Paper>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {
        visit && visit.checkListConfiguration && visit.checkListConfiguration.columns ?
          (
            <Card>
              <CardBody>
                <Grid container>
                  <GridItem xs={12}>
                    <DivCenter><h3><strong>{t('checklist-configuration:checklist-configuration.title')}</strong></h3></DivCenter>
                  </GridItem>
                  {
                    visit.checkListConfiguration.columns.map((column, index) => {
                      const categoryResults = visitAnswer.calculatedChecklistColumns ?
                        visitAnswer.calculatedChecklistColumns
                          .categories.find(category => category.name === column.category) : null;

                      return (
                        <React.Fragment key={index}>
                          <Grid item xs={12}>
                            <DivCenter>
                              <h4><strong>{column.category}</strong></h4>
                            </DivCenter>
                          </Grid>
                          <Grid item xs={12} className={classes.noPadding}>
                            <Grid container justify="center" spacing={0}>
                                {
                                  column.operation.map((operation, index) => {
                                    const operationValue = categoryResults ?
                                      categoryResults.operations.find(operationR => operationR.name === operation.name) :
                                      null;
                                    if (operationValue) {
                                      return (
                                        <Grid item xs>
                                          <Paper variant="outlined" className={classes.paper}>
                                            <DivCenter key={index}>
                                              <span>
                                                {t(
                                                  `checklist-configuration:checklist-configuration.${operation.name}`
                                                )}
                                              </span>
                                              <br />
                                              <span>
                                                {operation.name.endsWith(
                                                  "efficacy"
                                                ) ||
                                                operation.name.endsWith(
                                                  "average"
                                                )
                                                  ? percentage(
                                                      operationValue.value / 100
                                                    )
                                                  : operationValue.value}
                                              </span>
                                            </DivCenter>
                                          </Paper>
                                        </Grid>
                                      );
                                    }
                                  })
                                }
                            </Grid>

                          </Grid>
                        </React.Fragment>
                      );
                    })
                  }
                  {
                    getGlobalChecklistColumns(visit).length ?
                      <React.Fragment>
                        <Grid item xs={12}>
                          <DivCenter>
                            <h4><strong>Globales</strong></h4>
                          </DivCenter>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container justify="center" spacing={0}>
                          <div className={classes.calculationRow}>
                            {
                              getGlobalChecklistColumns(visit).map((globalName, index) => {
                                const {globals} = visitAnswer.calculatedChecklistColumns ?
                                  visitAnswer.calculatedChecklistColumns : {globals: []};
                                const globalColumn = globals.find(global => global.name === globalName);

                                if (globalColumn) {
                                  console.log(globalColumn)
                                  return (
                                    <Grid item xs>
                                      <Paper variant="outlined" className={classes.paper}>
                                        <DivCenter key={index}>
                                          <span>{t(`checklist-configuration:checklist-configuration.globals.${globalName}`)}</span>
                                          <br/>
                                          <span>
                                            {
                                              !!globalColumn ? percentage(globalColumn.value / 100) : 0
                                            }
                                          </span>
                                      </DivCenter>
                                      </Paper>
                                    </Grid>

                                  );
                                } else{
                                  return [];
                                }
                              })
                            }
                          </div>
                          </Grid>

                        </Grid>
                      </React.Fragment> : []
                  }
                </Grid>
              </CardBody>
            </Card>
          )
          : []
      }
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="entry-content"
          id="entry-header"
        >
          <DivCenter><h3 className={classes.relevantText}>{t('visits.visitAnswer.entryTitle')}</h3></DivCenter>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <QuestionEngineAnswerView
            questionEngineAnswer={visitAnswer.checkIn.questionAnswersRef}
            editable={editable}
            preventEdit={visit.keys}
            processAnswerUpdateLog={processAnswerUpdateLog}
            questionAnswerUpdateLogs={questionAnswerUpdateLogs}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {
        visitAnswer.activities.length ?
          visitAnswer.activities.map((activity, index) => {
            return (
              <ExpansionPanel key={index}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${activity.moduleId}${index}-content`}
                  id={`${activity.moduleId}${index}-header`}
                >
                  <DivCenter><h3 className={classes.relevantText}>{activity.questionAnswersRef.module.name}</h3></DivCenter>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <QuestionEngineAnswerView
                    questionEngineAnswer={activity.questionAnswersRef}
                    editable={editable}
                    processAnswerUpdateLog={processAnswerUpdateLog}
                    questionAnswerUpdateLogs={questionAnswerUpdateLogs}
                    key={index}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          }) :
          <DivCenter>
            <Info>
              <h5>{t('visits.visitAnswer.noActivities')}</h5>
            </Info>
          </DivCenter>
      }
      {visitAnswer.checkOut ?
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="exit-content"
          id="exit-header"
        >
          <DivCenter><h3 className={classes.relevantText}>{t('visits.visitAnswer.exitTitle')}</h3></DivCenter>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <QuestionEngineAnswerView
            questionEngineAnswer={visitAnswer.checkOut.questionAnswersRef}
            editable={editable}
            processAnswerUpdateLog={processAnswerUpdateLog}
            questionAnswerUpdateLogs={questionAnswerUpdateLogs}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      : ""
      }
    </React.Fragment>
  );
};

VisitAnswerView.propTypes = {
  classes: PropTypes.object.isRequired,
  visitAnswer: PropTypes.object.isRequired,
  visit: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  processAnswerUpdateLog: PropTypes.func,
  questionAnswerUpdateLogs: PropTypes.array
};

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: 50,
    padding: 15
  },
  center: {
    textAlign: "center"
  },
  visitAnswerContainer: {
    paddingBottom: 20
  },
  sectionTitle: {
    textAlign: "center"
  },
  relevantText: {
    fontWeight: 'bold'
  },
  calculationRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  keyFieldAnswer: {
    fontSize: 18,
  },
  noPadding: {
    padding: '0px !important'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }
}))

export default VisitAnswerView;
