import React, { useMemo, useState } from 'react';
import {useTranslation} from 'react-i18next';
import { useMutation } from 'react-apollo-hooks';
import Moment from 'moment';

// own components
import TicketReportTriggerView from './TicketReportTriggerView';
import TicketReportFinisherView from './TicketReportFinisherView';
import TicketReportTimelineView from './TicketReportTimelineView';
import TicketReportCommentView from './TicketReportCommentView';
import TicketReportMaintenanceFilterForm from '../../forms/tickets/TicketReportMaintenanceFilterForm';
import LoadingCircleView from "../LoadingCircleView";

// core components
import Drawer from "@material-ui/core/Drawer";
import Tooltip from '@material-ui/core/Tooltip';
import ButtonBase from "@material-ui/core/ButtonBase";

import withStyles from '@material-ui/core/styles/withStyles';

import {
  boxShadow,
  drawerWidth,
  transition
} from "assets/jss/material-dashboard-pro-react.jsx";

// icons
import CommentIcon from '@material-ui/icons/Comment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RateReview from '@material-ui/icons/RateReview';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import AvTimerIcon from '@material-ui/icons/AvTimer';

// custom components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Position from 'components/Position';
import Button from 'components/Button';
import TableList, { ActionRow } from 'components/TableList';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardText from "components/Card/CardText.jsx";
import ModalElements from 'components/ModalElements';
import Badge from 'components/Badge/Badge';
import ConfirmIconButton from "components/ConfirmIconButton/ConfirmIconButton";
import ConfirmOptionsIconButton from "components/ConfirmOptionsIconButton/ConfirmOptionsIconButton";

// styled components
import DivCenter from "../../components/styled-components/DivCenter";

// Local state
import {
  TICKET_REPORTS_FEED,
  GET_VALID_TICKET_USERS_MOBILE_FEED
} from './../../containers/Tickets/Queries';
import {
  UPDATE_TICKET_REPORT_MUTATION,
  CREATE_TICKET_REPORT_COMMENT,
  DELETE_TICKET_REPORT_COMMENT
} from './../../containers/Tickets/Mutations';
import {
  TICKET_REPORT_VIEW_TYPES,
  TICKET_REPORT_STATUS
} from "../../constants";
import {getColorByStatus} from "../../utils/views/tickets";
import TablePagination from '../../pagination/table/TablePagination';
import { Switch } from '@material-ui/core';
import { duration } from "../../utils/dates/dates";

const renderCurrentView = (currentRightView, successComment, handleDeleteComment, ticketReport,ticketConfig) => {
  switch (currentRightView.view) {
    case TICKET_REPORT_VIEW_TYPES.COMMENTS:
      return (<TicketReportCommentView ticketReport={ticketReport}
                                       createTicketReportComment={successComment}
                                       deleteTicketReportComment={handleDeleteComment}/>);
    case TICKET_REPORT_VIEW_TYPES.TRIGGER:
      return (<TicketReportTriggerView ticketReport={ticketReport} ticketConfig={ticketConfig}/>);
    case TICKET_REPORT_VIEW_TYPES.FINISHER:
      return (<TicketReportFinisherView ticketReport={ticketReport}/>);
    case TICKET_REPORT_VIEW_TYPES.TIMELINE:
      return (<TicketReportTimelineView ticketReport={ticketReport}/>);
  }
};

const TicketConfigView = (props) => {
  const { classes, data, loading, app, ticket, user, currentFilter, 
    filterChanged, paginationAction, createcreateTicketReportXLS } = props;
  const {loadingFilters, segments, userMobiles} = props;
  const { filterRejected, filterAccepted, filterPending,ticketConfig } = props;
  const { ticketReports, RejectedCount, AcceptedCount } = data;

  const { t, } = useTranslation('tickets');
  const { t:tt } = useTranslation("general_adaptation");

  const createTicketReportComment = useMutation(CREATE_TICKET_REPORT_COMMENT);
  const deleteTicketReportComment = useMutation(DELETE_TICKET_REPORT_COMMENT);
  const updateTicketReport = useMutation(UPDATE_TICKET_REPORT_MUTATION);

  const [modalUser, setModalUser] = useState({
    open: false,
    type: 'modules'
  });
  const [currentRightView, setCurrentRightView] = useState({
    open: false,
    ticketReport: null,
    view: ''
  });

  const getSelectedElements = selectedElements => {
    handleModalClose();
  };

  const handleModalClose = () => {
    setModalUser({
      open: false,
      type: 'modules'
    });
  };

  const handleDeleteComment = dataEvent => {
    deleteTicketReportComment({
      variables: { _id: dataEvent._id, comment: dataEvent.comment }
    });
  };

  const handleCreateComment = formData => {
    createTicketReportComment({
      variables: {
        _id: formData.id,
        text: formData.text,
        userId: user._id,
        userType: user.__typename
      }
    });
  };

  const getLastAssignedDate = ticketReport => {
    const assignedLogs = ticketReport.ticketReportLogs.filter(log => log.status === 'Assigned');
    if (assignedLogs.length && ticketReport.status === TICKET_REPORT_STATUS.ASSIGNED) {
      return assignedLogs[assignedLogs.length - 1].created;
    }
    return '';
  };
  const getLastStatusDate = (ticketReport, status) => {
    const statusLog = ticketReport.ticketReportLogs.filter(log => log.status === status);
    if (statusLog.length) {
      return statusLog[statusLog.length - 1].created;
    }
    return false;
  };

  const columns = useMemo(() => {
    const { hasFolio } = ticketConfig;
    let ticketColumns = [
      {
        id: "status",
        Header: <DivCenter>{tt("state")}</DivCenter>,
        accessor: "status",
        Cell: (props) => (
          <Badge color={getColorByStatus(props.value)}>
            {t(`tickets.model.status.${props.value}`)}
          </Badge>
        ),
        maxWidth: 120,
        style: { textAlign: "center" },
        sortable: true,
        filterable: false,
      },
      {
        id: "questionEngineAnswerTrigger[0].module.name",
        Header: (
          <div style={{ textAlign: "right" }}>
            {tt("reported_by")}
          </div>
        ),
        accessor:
          "questionEngineAnswerTrigger[0].userRef.email",
        style: { textAlign: "right" },
        maxWidth: 200,
        sortable: true,
        filterable: false,
      },
      {
        id: "created",
        Cell: (d) => {
          return Moment(
            d.original.questionEngineAnswerTrigger[0]
              .created
          ).local().format("DD/MM/YYYY HH:mm:ss")
        },
        style: { 
          textAlign: "left",
          whiteSpace: "unset",
        },
        maxWidth: 120,
        sortable: false,
        filterable: false,
      },
      {
        id: "viewTrigger",
        Cell: (d) => {
          return (
            <Tooltip title={`Ver Info`}>
              <span>
                <Button
                  justIcon
                  simple
                  size="sm"
                  color="info"
                  onClick={() => {
                    setCurrentRightView({
                      ...currentRightView,
                      open: true,
                      view: TICKET_REPORT_VIEW_TYPES.TRIGGER,
                      ticketReport: d.original._id,
                    });
                  }}
                >
                  <RemoveRedEyeIcon />
                </Button>
              </span>
            </Tooltip>
          );
        },
        style: { textAlign: "left" },
        maxWidth: 35,
        sortable: false,
        filterable: false,
      },
      {
        id: "assignedName",
        accessor: "userRef.email",
        Header: (
          <div style={{ textAlign: "right" }}>
            {"Asignado a"}
          </div>
        ),
        Cell: (d) => {
          if (
            d.original.status !== TICKET_REPORT_STATUS.CREATED
          ) {
            return d.original.userRef
              ? d.original.userRef.email
              : "";
          }
          return "";
        },
        style: { textAlign: "right" },
        maxWidth: 200,
        sortable: true,
        filterable: false,
      },
      {
        id: "assignTrigger",
        Cell: (d) => {
          if (
            d.original.status === "Created" ||
            d.original.status === "Rejected"
          ) {
            return (
              <Tooltip
                title={t(
                  "tickets.maintenance.tooltips.buttonAssign"
                )}
              >
                <span>
                  <Button
                    justIcon
                    simple
                    size="sm"
                    color="primary"
                    onClick={() => {
                      setModalUser({
                        open: true,
                        type: "Module",
                        okCallback: (dataSelected) => {
                          if (dataSelected.length) {
                            updateTicketReport({
                              variables: {
                                _id: d.original._id,
                                app: app,
                                ticket: ticket,
                                ticketReportLog: {
                                  userId: user._id,
                                  userType: user.__typename,
                                  status:
                                    TICKET_REPORT_STATUS.ASSIGNED,
                                },
                                user: dataSelected[0]._id,
                                status:
                                  TICKET_REPORT_STATUS.ASSIGNED,
                              },
                            });
                          }
                        },
                      });
                    }}
                  >
                    <PersonAddIcon />
                  </Button>
                </span>
              </Tooltip>
            );
          }
          if (d.original.status === "Assigned") {
            return (
              <ConfirmIconButton
                t={t}
                baseTooltip={
                  "tickets.maintenance.tooltips.buttonDiscard"
                }
                confirmTooltip={
                  "tickets.maintenance.tooltips.confirmDiscard"
                }
                baseIcon={PersonOutlineIcon}
                baseColor={"warning"}
                simple={true}
                confirmDelay={2000}
                confirmOperation={() => {
                  updateTicketReport({
                    variables: {
                      _id: d.original._id,
                      app: app,
                      ticket: ticket,
                      ticketReportLog: {
                        userId: user._id,
                        userType: user.__typename,
                        status: TICKET_REPORT_STATUS.CREATED,
                      },
                      status: TICKET_REPORT_STATUS.CREATED,
                    },
                  });
                }}
              />
            );
          }
          return [];
        },
        style: { textAlign: "left" },
        maxWidth: 35,
        sortable: false,
        filterable: false,
      },
      {
        id: "dateAssigned",
        Cell: (d) => {
          return getLastAssignedDate(d.original)
          ? Moment(getLastAssignedDate(d.original))
              .local()
              .format("DD/MM/YYYY HH:mm:ss")
          : ""
        },
        style: { 
          textAlign: "left",
          whiteSpace: "unset",
        },
        maxWidth: 120,
        sortable: false,
        filterable: false,
      },
      {
        id: "separator",
        sortable: false,
        filterable: false,
        maxWidth: 15,
      },
      {
        id: "dateSolved",
        Header: (
          <div style={{ textAlign: "right" }}>{tt("resolved")}</div>
        ),
        Cell: (d) => {
          const solvedDate = getLastStatusDate(
            d.original,
            TICKET_REPORT_STATUS.RESOLVED
          );
          if (solvedDate) {
            return Moment(solvedDate).local().format("DD/MM/YYYY HH:mm:ss")
          }
          return [];
        },
        style: { 
          textAlign: "right", 
          whiteSpace: "unset",
        },
        maxWidth: 120,
        sortable: false,
        filterable: false,
      },
      {
        id: "viewFinisher",
        Cell: (d) => {
          if (
            d.original.questionEngineAnswerResolved &&
            d.original.questionEngineAnswerResolved.length
          ) {
            return (
              <Tooltip title={`Ver Info`}>
                <span>
                  <Button
                    justIcon
                    simple
                    size="sm"
                    color="info"
                    onClick={() => {
                      setCurrentRightView({
                        ...currentRightView,
                        open: true,
                        view: TICKET_REPORT_VIEW_TYPES.FINISHER,
                        ticketReport: d.original._id,
                      });
                    }}
                  >
                    <RemoveRedEyeIcon />
                  </Button>
                </span>
              </Tooltip>
            );
          }
          return [];
        },
        style: { textAlign: "left" },
        maxWidth: 35,
        sortable: false,
        filterable: false,
      },
      {
        id: "resolutionDuration",
        Header: (
          <DivCenter style={{ 
            textAlign: "right",
            whiteSpace: "unset",
          }}>
            {tt("duration")}
          </DivCenter>
        ),
        Cell: (d) => {
          const solvedDate = getLastStatusDate(
            d.original,
            TICKET_REPORT_STATUS.RESOLVED
          );
          if (solvedDate) {
            const startDate = d.original.questionEngineAnswerTrigger[0].created;
            const solvedDuration = duration(startDate, solvedDate);                            
            return solvedDuration
          }
          return [];
        },
        style: { 
          textAlign: "right", 
          whiteSpace: "unset",
        },
        maxWidth: 120,
        sortable: false,
        filterable: false,
      },
      {
        accessor: "actions",
        Cell: (d) => {
          return (
            <div className="actions-right">
              {setButtonByStatus(
                d.original,
                setModalUser,
                { app, ticket, user },
                updateTicketReport,
                t,
                setCurrentRightView,
                currentRightView
              )}
            </div>
          );
        },
        sortable: false,
        filterable: false,
      },
    ];

    if (hasFolio) {
      ticketColumns.unshift({
        id: "folio",
        Header: (
          <div style={{ textAlign: "center" }}>
            {tt("folio")}
          </div>
        ),
        accessor:
          "folio",
        style: { textAlign: "center" },
        maxWidth: 200,
        sortable: true,
        filterable: false,
      });
    }

    return ticketColumns;
  }, [loading]);

  return (
    <div className="Tickets">
      {!loadingFilters ? (
        <Card>
          <CardBody>
            <TicketReportMaintenanceFilterForm
              initialValues={currentFilter}
              submitSuccess={filterChanged}
              userMobiles={userMobiles}
              segments={segments}
              ticketConfig={ticketConfig}
            />
          </CardBody>
        </Card>
      ) : (
        <LoadingCircleView />
      )}
      {!loading ? (
        <Card>
          <CardHeader color="info" text>
            <GridContainer>
              <GridItem xs={6}>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>
                    {t("tickets.maintenance.title")}
                  </h4>
                </CardText>
              </GridItem>
              <GridItem
                xs={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Position right>
                  <ButtonBase onClick={() => filterPending()}>
                    <Badge color={"success"}>
                      {typeof ticketReports !== "undefined" &&
                      Array.isArray(ticketReports)
                        ? ticketReports.length
                        : 0}{" "}
                      {t("tickets.maintenance.tags.pending")}
                    </Badge>
                  </ButtonBase>
                  {"    "}
                  <ButtonBase onClick={() => filterAccepted()}>
                    <Badge color={"primary"}>
                      {typeof AcceptedCount !== "undefined"
                        ? AcceptedCount.aggregate.count
                        : 0}{" "}
                      {t("tickets.maintenance.tags.accepted")}
                    </Badge>
                  </ButtonBase>
                  {"    "}
                  <ButtonBase onClick={() => filterRejected()}>
                    <Badge color={"danger"}>
                      {typeof RejectedCount !== "undefined"
                        ? RejectedCount.aggregate.count
                        : 0}{" "}
                      {t("tickets.maintenance.tags.rejected")}
                    </Badge>
                  </ButtonBase>
                </Position>
              </GridItem>
              <GridItem
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Position right>
                  <ButtonBase
                    onClick={() => {
                      createcreateTicketReportXLS();
                    }}
                  >
                    <Badge color={"info"}>
                      {t("tickets.maintenance.generateReport")}
                    </Badge>
                  </ButtonBase>
                </Position>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <React.Fragment>
              <TablePagination
                data={ticketReports ? ticketReports : []}
                paginationAction={paginationAction}
                filter={currentFilter}
              />
              {typeof ticketReports !== "undefined" &&
                ticketReports.length > 0 && (
                  <TableList
                    data={ticketReports}
                    columns={columns}
                    minRows={0}
                    defaultPageSize={15}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                )}
              {typeof ticketReports !== "undefined" && !ticketReports.length ? (
                <div
                  style={{
                    display: "flex",
                    height: "75vh",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3>{t("tickets.maintenance.noTickets")}</h3>
                </div>
              ) : (
                []
              )}
              <ModalElements
                data={[]}
                getSelectedElements={getSelectedElements}
                open={modalUser.open}
                type={modalUser.type}
                title={tt("selectuser")}
                filterLabel={tt("search")}
                query={GET_VALID_TICKET_USERS_MOBILE_FEED}
                variables={{ ticket: ticket }}
                dataElement={"ticketValidUsers"}
                textValue={(element) => {
                  return `${element.firstName} ${element.lastName}`;
                }}
                okCallback={modalUser.okCallback}
                handleModalClose={handleModalClose}
                multi={false}
              />
              <React.Fragment>
                <Drawer
                  variant="temporary"
                  anchor={"right"}
                  open={currentRightView.open}
                  onClose={() =>
                    setCurrentRightView({ ...currentRightView, open: false })
                  }
                >
                  <div style={{ minWidth: 450, maxWidth: 450 }}>
                    {renderCurrentView(
                      currentRightView,
                      handleCreateComment,
                      handleDeleteComment,
                      ticketReports.find(
                        (ticketReport) =>
                          ticketReport._id === currentRightView.ticketReport
                      ),
                      ticketConfig
                    )}
                  </div>
                </Drawer>
              </React.Fragment>
            </React.Fragment>
          </CardBody>
        </Card>
      ) : (
        <LoadingCircleView />
      )}
    </div>
  );
}

const styles = theme => ({
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    ...boxShadow,
    width: drawerWidth,
    ...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    ...transition,
    "&:before,&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      top: "0"
    },
    "&:after": {
      background: "#000",
      opacity: ".8"
    }
  },
  root: {
    width: 42,
    height: 26,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #000",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
});

export default withStyles(styles)(TicketConfigView);

export const setButtonByStatus = (ticketReport, setModalUser, params, updateTicketReport, t, setCurrentRightView, currentRightView) => {
  return (
    <React.Fragment>
      {
        ticketReport.status === TICKET_REPORT_STATUS.RESOLVED ?
          <ConfirmOptionsIconButton t={t}
                                    baseTooltip={'tickets.maintenance.tooltips.buttonApprove'}
                                    baseLabel={'tickets.maintenance.tooltips.labelApprove'}
                                    baseColor={'info'}
                                    confirmDelay={5000}
                                    baseIcon={RateReview}
                                    confirmOptions={[
                                      {
                                        confirmTooltip: 'tickets.maintenance.tooltips.buttonAccept',
                                        confirmOperation: () => {
                                          updateTicketReport({variables: {
                                              _id: ticketReport._id,
                                              app: params.app,
                                              ticket: params.ticket,
                                              ticketReportLog: {
                                                userId: params.user._id,
                                                userType: params.user.__typename,
                                                status: TICKET_REPORT_STATUS.ACCEPTED
                                              },
                                              status: TICKET_REPORT_STATUS.ACCEPTED
                                            }})
                                        },
                                        confirmColor: 'success',
                                        confirmIcon: CheckOutlinedIcon
                                      },
                                      {
                                        confirmTooltip: 'tickets.maintenance.tooltips.buttonReject',
                                        confirmOperation: () => {
                                          updateTicketReport({variables: {
                                              _id: ticketReport._id,
                                              app: params.app,
                                              ticket: params.ticket,
                                              ticketReportLog: {
                                                userId: params.user._id,
                                                userType: params.user.__typename,
                                                status: TICKET_REPORT_STATUS.ASSIGNED
                                              },
                                              status: TICKET_REPORT_STATUS.ASSIGNED
                                            }})
                                        },
                                        confirmColor: 'danger',
                                        confirmIcon: CancelIcon
                                      }

                                    ]} />
          : []
      }
      <Tooltip title={`Ver timeline`}>
        <span>
          <Button
            justIcon
            simple
            size="sm"
            color="google"
            onClick={() => {
              setCurrentRightView({
                ...currentRightView,
                open: true,
                view: TICKET_REPORT_VIEW_TYPES.TIMELINE,
                ticketReport: ticketReport._id
              })
            }}
          >
            <AvTimerIcon/>
          </Button>
        </span>
      </Tooltip>
      <Tooltip title={`Comentarios`}>
        <span>
          <Button
            justIcon
            simple
            size="sm"
            color="info"
            onClick={() => {
              setCurrentRightView({
                ...currentRightView,
                open: true,
                view: TICKET_REPORT_VIEW_TYPES.COMMENTS,
                ticketReport: ticketReport._id
              })
            }}
          >
            <CommentIcon/>
          </Button>
        </span>
      </Tooltip>
      <ConfirmIconButton t={t}
                         baseTooltip={'tickets.maintenance.tooltips.buttonDiscard'} confirmTooltip={'tickets.maintenance.tooltips.buttonDiscard'}
                         baseIcon={DeleteForeverIcon}
                         simple={true}
                         confirmDelay={2000}
                         confirmOperation={() => {
                           updateTicketReport({variables: {
                               _id: ticketReport._id,
                               app: params.app,
                               ticket: params.ticket,
                               ticketReportLog: {
                                 userId: params.user._id,
                                 userType: params.user.__typename,
                                 status: TICKET_REPORT_STATUS.REJECTED
                               },
                               status: TICKET_REPORT_STATUS.REJECTED
                             }})
                         }}/>
    </React.Fragment>
  );

};
