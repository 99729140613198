import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import {Field, Form, withFormik} from "formik";
import {number, date, object} from "yup";
import Datetime from "react-datetime";
import Select from "react-select";
import {debounce} from "lodash";

// custom components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// core components
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import Search from '@material-ui/icons/Search';

// styled components
import InputAdornmentError from "../../components/styled/InputAdornmentError";

// utils
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import {getErrorLabel, hasError} from "../../utils/forms/inputs";

const validationSchema = object().shape({
  reviewDate: date().required(),
});

const LocationsMapInputForm = ({classes, segments, plan, ...props}) => {

  const { t, } = useTranslation('locations');

  // Formik bag
  const {
    errors,
    touched,
    setFieldValue,
    values,
    submitForm,
    submitCount,
    isSubmitting,
    isValid
  } = props;

  useEffect(() => {
    userSearchDebounced();
  }, [values.userSearch]);

  const userSearchDebounced = debounce(submitForm, 300);

  const segmentOptions = segments.map((segment) => {
    return {
      label: segment.name,
      value: segment._id
    }
  });

  return (
    <Form>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={4}>
              <InputLabel className={classes.label}>
                {t("locations.forms.fields.reviewDate.label")}
              </InputLabel>
              <Datetime
                name={'reviewDate'}
                inputProps={{
                  disabled: plan?.features?.locations === 'off' ? true : false
                }}
                timeFormat={false}
                defaultValue={values.reviewDate}
                viewMode={'days'}
                onChange={async (e) => {
                  if (e === '') {
                    await setFieldValue('reviewDate', e);
                  }else{
                    await setFieldValue('reviewDate', e._d);
                  }
                  submitForm();
                }}
              />
            </GridItem>
            <GridItem xs={4}>
              <InputLabel className={classes.label}>
                {t("locations.forms.fields.segments.label")}
              </InputLabel>
              <Select
                styles={{
                  menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                placeholder={t("locations.forms.fields.segments.defaultLabel")}
                value={segmentOptions.find(segment => segment.value === values.segment)}
                onChange={async ({value}) => {
                  await setFieldValue('segment', value);
                  submitForm();
                }}
                options={segmentOptions}
                isDisabled={plan?.features?.locations === 'off' ? true : false}
              />
            </GridItem>
            <GridItem xs={4}>
              <Field name={'userSearch'} render={({field}) => {
                return (
                  <CustomInput
                    helpText={hasError(errors, 'userSearch') ?
                      getErrorLabel(t,
                        'locations.forms.fields',
                        'userSearch', errors) :
                      t('locations.forms.fields.userSearch.helperText')}
                    labelText={t('locations.forms.fields.userSearch.label')}
                    id="userSearch"
                    formControlProps={{
                      fullWidth: true
                    }}
                    success={!hasError(errors, 'userSearch') && touched.userSearch}
                    error={hasError(errors, 'userSearch')}
                    inputProps={{
                      endAdornment: (
                        hasError(errors, 'userSearch') ?
                          <InputAdornmentError/> : []
                      ),
                      disabled: plan?.features?.locations === 'off' ? true : false,
                      ...field
                    }}
                  />
                );
              }}/>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </Form>
  );
};

LocationsMapInputForm.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  submitSuccess: PropTypes.func.isRequired
};

const LocationsMapInputFormWF = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => {
    return {
      reviewDate: initialValues.reviewDate,
      segment: initialValues.segment
    };
  },
  handleSubmit: (values, { props }) => {
    props.submitSuccess(values);
  }
})(LocationsMapInputForm);

export default withStyles(extendedFormsStyle)(LocationsMapInputFormWF);

