import React from 'react'
import ReactTable from 'react-table';
import DivCenter from '../../components/styled-components/DivCenter';
import moment from "moment";
import { Link } from 'react-router-dom'
import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';

const MessageTable = ({messages}) => {
    return (
        <ReactTable
          data={messages}
          columns={[
            {
              Header: <DivCenter>Titulo</DivCenter>,
              accessor: "title",
              filterable: false,
              sortable: false,
              style: { textAlign: "center" },
              minWidth: 10
            },
            {
              Header: <DivCenter>Asunto</DivCenter>,
              accessor: "subject",
              filterable: false,
              sortable: false,
              style: { textAlign: "center" },
              minWidth: 10
            },
            {
              Header: <DivCenter>Fecha Expiración</DivCenter>,
              accessor: "expirationDate",
              Cell: d=> {
                return moment(d.original.expirationDate) 
                .local()
                .format("DD-MM-YY : hh:mm A");
              },
              filterable: false,
              sortable: false,
              style: { textAlign: "center" },
              minWidth: 10
            },
            {
              Header: <DivCenter>Fecha de creación</DivCenter>,
              accessor: "created",
              Cell: d => {
                return moment(d.original.created) 
                  .local()
                  .format("DD-MM-YY : hh:mm A");
              },
              filterable: false,
              sortable: false,
              style: { textAlign: "center" },
              minWidth: 10
            },{
              Header: "",
              id: "actions",
              Cell: d => {
                return (
                <Tooltip title="Ver mensaje">
                  <Link to={{
                  pathname: `/message/content`,
                  state: {
                    message: d.original
                  }
                }}>
                  <Button
                    justIcon
                    round
                    simple
                    color="info"
                  >
                   <VisibilityIcon />
                  </Button>{" "}
                </Link>
              </Tooltip>)
              },
              filterable: false,
              sortable: false,
              style: { textAlign: "center" },
              minWidth: 10
            }
          ]}
          defaultPageSize={15}
          showPaginationTop={false}
          showPaginationBottom={false}
          className="-striped -highlight"
        />
      );
}

export default MessageTable;
