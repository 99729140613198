import React, { useMemo, useState } from "react";
import ProcessRelationshipListView from "../../Views/ProcessRelationship/ProcessRelationshipListView";
import useGlobal from "../../store";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { PROCESS_RELATIONSHIPS } from "./gql/Queries";
import { ARCHIVE_PROCESS_RELATIONSHIP } from "./gql/Mutations";
import moment from "moment";
import { GET_PLAN_FEATURES_APP } from "../Locations/gql/Queries";
import auth from "../../utils/auth";
import history from "../../utils/history";

const ProcessRelationshipList = () => {
  const { t } = useTranslation("common");
  const [session, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );
  const [isArchivedMode, setIsArchivedMode] = useState(false);

  const [processRelationshipFilter, setProcessRelationshipFilter] = useState({
    app: session.user ? session.appRef._id : "",
    archived: false,
    expirationDate_gte: moment(new Date()).startOf("day").format(),
    session: auth.getUserSessionData(session)
  });

  const [pagination, setPagination] = useState({
    first: 15,
    skip: 0,
    orderBy: "created_ASC",
  })

  const {
    data: processRelationships,
    loading: loadingProcessRelationships,
    error: errorProcessRelatiuonships,
    refetch,
  } = useQuery(PROCESS_RELATIONSHIPS, {
    variables: {
      where: processRelationshipFilter
    },
    fetchPolicy: "network-only",
  });

  const {
    data: dataPlan,
  } = useQuery(GET_PLAN_FEATURES_APP, {
    variables: {
      where: {
        _id: session.user ? session.appRef._id : '',
      }
    }
  });

  const [archiveProcessRelationship] = useMutation(
    ARCHIVE_PROCESS_RELATIONSHIP,
    {
      onCompleted: () => {
        refetch();
      },
    }
  );

  const paginationAction  = async (values) => {
    setPagination(values);
  }

  useMemo(() => {
    if (
      session.user &&
      !auth.hasPermission("processRelationshipView", session.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: t('common.hasNotPermission'),
        color: "danger"
      });
      history.push('dashboard');
    } 
  }, [session]);

  return (
    <ProcessRelationshipListView
      plan={dataPlan?.getPlanFeaturesApp || null}
      processRelationshipFilter={processRelationshipFilter}
      paginationAction={paginationAction}
      processRelationships={{
        loading: loadingProcessRelationships,
        data: processRelationships?.getProcessRelationships,
      }}
      archiveProcessRelationship={archiveProcessRelationship}
      isArchivedMode={isArchivedMode}
      changeArchivedMode={(status) => {
        setIsArchivedMode(status);
        setProcessRelationshipFilter({
          ...processRelationshipFilter,
          archived: status
        });
      }}
    />
  );
};

export default ProcessRelationshipList;
