import React from 'react'
import PropTypes from 'prop-types';
import ChecklistAnalisysVisitReportFormWF from '../../../forms/reports/ChecklistAnalisysVisitReportForm';
import SummaryChecklistAnalisysReportFormWF from '../../../forms/reports/SummaryChecklistAnalisysReportForm';
import CustomReportFormWF from '../../../forms/reports/CustomReportForm';
import ChecklistHistoryReportFormWF from '../../../forms/reports/ChecklistHistoryReportForm';

const ChecklistAnalisysFormStep = (props) => {
    const { configReport, setConfigReport, initDataForm, submitSuccess, handleBack } = props;
    const { type } = configReport;

    const _renderVisits = (visits) => {
      const renderVisits = visits.reduce((result, visit) => {
        
        if(visit.checkListConfiguration && visit.checkListConfiguration.columns.length){
          result.push(visit);
        }
  
        return result;
      }, []);
  
      return renderVisits;
    }

    const _filtervisit = () => {
      let { visits } = initDataForm;

      initDataForm.visits = _renderVisits(visits);
    }
    
    switch (type) {
        case 'ExitVisit':
            _filtervisit();
            return (
              <ChecklistAnalisysVisitReportFormWF
                initDataForm={initDataForm}
                submitSuccess={submitSuccess}
                handleBack={handleBack}
              />
            );
        case 'SummaryVisit':
            _filtervisit();
            return (
              <SummaryChecklistAnalisysReportFormWF
                initDataForm={initDataForm}
                submitSuccess={submitSuccess}
                handleBack={handleBack}
              />
            );
        case 'CustomVisit':
            return (
              <CustomReportFormWF
                initDataForm={initDataForm}
                submitSuccess={submitSuccess}
                handleBack={handleBack}
              />
            );
        case 'ChecklistHistory':
          _filtervisit();
          return (
            <ChecklistHistoryReportFormWF
              initDataForm={initDataForm}
              submitSuccess={submitSuccess}
              handleBack={handleBack}
            />
          )
    }
}

ChecklistAnalisysFormStep.propTypes = {
    configReport: PropTypes.object.isRequired,
    setConfigReport: PropTypes.func.isRequired,
    initDataForm: PropTypes.object.isRequired,
    submitSuccess: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
}

export default ChecklistAnalisysFormStep;