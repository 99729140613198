import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import dashboardRoutes from "routes/dashboard";
import PropTypes from "prop-types";
import cx from "classnames";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";

// core components
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import ReportNotifierItem from "../ReportNotifier/ReportNotifierItem";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

import image from "assets/img/bgsidebar.jpg";
import logo from "assets/img/bqMini.png";
import logoText from "assets/img/bqText.png";

const switchRoutes = (
  <Switch>
    {dashboardRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
      if (prop.collapse)
        return prop.views.map((prop, key) => {
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        });
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

let ps;

class Layout extends Component {
  state = {
    mobileOpen: false,
    miniActive: true
  };

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  getRoute = () => {
    return this.props.location.pathname !== "/maps/full-screen-maps";
  };

  sidebarMinimize = () => {
    this.setState({ miniActive: !this.state.miniActive });
  };

  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    const { classes, miniActive, ...rest } = this.props;

    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });

    return (
      <div className={classes.wrapper}>
        <Sidebar
          miniActive={this.state.miniActive}
          color="white"
          bgColor="blue"
          routes={dashboardRoutes}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          logoText={logoText}
          logo={logo}
          image={image}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <Header
            routes={dashboardRoutes}
            {...rest}
            sidebarMinimize={this.sidebarMinimize}
            miniActive={this.state.miniActive}
            handleDrawerToggle={this.handleDrawerToggle}
          />
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        </div>
        <ReportNotifierItem/>
      </div>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(appStyle),
  withTranslation('home')
)(Layout);
