import React, { useState } from "react";
import Moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useQuery, useMutation} from "react-apollo-hooks";

// Own components
import LoadingCircleView from "../../Views/LoadingCircleView";
import VisitsDashboardKeyfieldItemView from "../../Views/Visits/VisitsDashboardKeyfieldItemView";
import useGlobal from "../../store";
import { VISIT_ITEMS, VISIT_ANSWER_ITEMS, VISIT_ANSWER_COUNT } from "./gql/Queries";
import { 
  CREATE_KEYFIELD_DASHBOARD_REPORT_MUTATION,
  CREATE_AVERAGE_ANALYSIS_REPORT_MUTATION,
  CREATE_AVERAGE_ANALYSIS_XLS_REPORT_MUTATION,
  CREATE_RANKINGS_REPORT_MUTATION,
  CREATE_RANKINGS_XLS_REPORT_MUTATION,
} from "./gql/Mutations";
import { 
  getOperationsFromColumns,
  getKeyfields,
  getCategories,
  parseVisitAnswers,
  getSelectedKeyfield,
  reduceOperations,
  addGlobalCategory,
  classifyAndFilter 
} from "../../utils/analytics/dataManipulation";
import { 
  reportCreationFailedNotification, 
  reportCreationOnProgressNotification,
  reportSentNotification,
  reportSentFailedNotification,
} from "../../utils/snackNotifications";
import LoadingProcessView from "../../Views/LoadingProcessView";
import { useEffect } from "react";

function VisitsDashboardKeyfieldItem({match}) {
  const [ session, globalActions] = useGlobal(
    state => state.session,
    actions => actions
  );
  const [ filters, filtersActions ] = useGlobal(
    state => state.filters,
    actions => actions.filters
  );
  

  const { t, } = useTranslation('visits');
  const visitID = match.params.idVisit === ":idVisit" ? null : match.params.idVisit;
  
  const {data, loading} = useQuery(VISIT_ITEMS, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : '',
        hasChecklistConfiguration: true,
        isArchived: false
      }
    },
    fetchPolicy: 'network-only'
  });

  const {
    data: dataVisitAnswers, 
    loading: loadingVisitAnswers
  } = useQuery(VISIT_ANSWER_ITEMS, {
      variables: {
        where: {
          userId: session.user._id,
          app: session.appId,
          visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
          checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
          checkInDate_lte: filters.range.endDate,
          approvalStatus_not_in: ["Rejected", "Excluded"],
          hasCheckOutDate: true
        }
      },
      skip: !filters.selectedVisitID,
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    });

    const {
      data: dataVisitAnswersOpen, 
      loading: loadingVisitAnswersOpen
    } = useQuery(VISIT_ANSWER_COUNT, {
        variables: {
          where: {
            userId: session.user._id,
            app: session.appId,
            visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
            checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
            checkInDate_lte: filters.range.endDate,
            approvalStatus_not_in: ["Rejected", "Excluded"],
            hasCheckOutDate: false
          }
        },
        skip: !filters.selectedVisitID,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      });

  const {
      data: dataVisitAnswersClosed, 
      loading: loadingVisitAnswersClosed
    } = useQuery(VISIT_ANSWER_COUNT, {
        variables: {
          where: {
            userId: session.user._id,
            app: session.appId,
            visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
            checkInDate_gt: Moment(filters.range.startDate).startOf('day').format(), 
            checkInDate_lte: filters.range.endDate,
            approvalStatus_not_in: ["Rejected", "Excluded"],
            hasCheckOutDate: true
          }
        },
        skip: !filters.selectedVisitID,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      });

  // Add General Data required to all the mutation calls
  const addGeneralData = (payload, emailData) => {
    let data = { query: { 
      visit: filters.selectedVisitID, 
      operation: filters.selectedOperation, 
      ...payload
    }};
    data.visitId = filters.selectedVisitID?  filters.selectedVisitID.value : '';
    data.dateRange = {
      startDate: filters.range.startDate.format("DD-MM-YYYY"),
      endData: Moment(filters.range.endDate).format("DD-MM-YYYY")
    };
    data.app = session.user ? session.appRef._id : '';
    data.session = session;

    if(!!emailData){
      data.sendByEmail = true;
      data.emailInput = {
        contentHtml: emailData.contentHtml,
        subject: emailData.subject,
        to: emailData.to
      }
    }

    return data;
  };

  // Execute the mutation and trigger the notifications
  const executeMutation = (mutation, data, isEmail = false) => {
    mutation({ variables: { data: data }}).then(
      result => {
        if(isEmail) reportSentNotification(globalActions);
        else reportCreationOnProgressNotification(globalActions);
      }, 
      error => {
        if(isEmail) reportSentFailedNotification(globalActions);
        else reportCreationFailedNotification(globalActions);
      });
  };

  // Initilize the mutations
  const createKeyfieldDashboardReportMutation = useMutation(CREATE_KEYFIELD_DASHBOARD_REPORT_MUTATION);
  const createAverageAnalysisReportMutation = useMutation(CREATE_AVERAGE_ANALYSIS_REPORT_MUTATION);
  const createRankingsReportMutation = useMutation(CREATE_RANKINGS_REPORT_MUTATION);
  const createAverageAnalysisXLSReportMutation = useMutation(CREATE_AVERAGE_ANALYSIS_XLS_REPORT_MUTATION);
  const createRankingsXLSReportMutation = useMutation(CREATE_RANKINGS_XLS_REPORT_MUTATION);

  const createAverageAnalysisReport = React.useCallback((payload) => {
    const data = addGeneralData(payload);
    executeMutation(createAverageAnalysisReportMutation, data); 
  }, [ createAverageAnalysisReportMutation, filters, session, Moment ]);

  const createAverageAnalysisXLSReport = React.useCallback((payload) => {
    const data = addGeneralData(payload);
    executeMutation(createAverageAnalysisXLSReportMutation, data); 
  }, [ createAverageAnalysisXLSReportMutation, filters, session, Moment ]);

  const createKeyfieldDashboardReport = React.useCallback((payload) => {
    const data = addGeneralData(payload);
    executeMutation(createKeyfieldDashboardReportMutation, data); 
  }, [ createKeyfieldDashboardReportMutation, filters, session, Moment ]);

  const createRankingsReport = React.useCallback((payload) => {
    const data = addGeneralData(payload);
    executeMutation(createRankingsReportMutation, data); 
  }, [ createRankingsReportMutation, filters, session, Moment ]);
  
  const createRankingsXLSReport = React.useCallback((payload) => {
    const data = addGeneralData(payload);
    executeMutation(createRankingsXLSReportMutation, data); 
  }, [ createRankingsXLSReportMutation, filters, session, Moment ]);

  const sendAverageAnalysisPDFReport = React.useCallback((payload, emailData) => {
    const data = addGeneralData(payload, emailData);
    executeMutation(createAverageAnalysisReportMutation, data, true); 
  }, [ createAverageAnalysisReportMutation, filters, session, Moment ]);

  const sendAverageAnalysisXLSReport = React.useCallback((payload, emailData) => {
    const data = addGeneralData(payload, emailData);
    executeMutation(createAverageAnalysisXLSReportMutation, data, true); 
  }, [ createAverageAnalysisXLSReportMutation, filters, session, Moment ]);

  const sendKeyfieldDashboardPDFReport = React.useCallback((payload, emailData) => {
    const data = addGeneralData(payload, emailData);
    executeMutation(createKeyfieldDashboardReportMutation, data, true); 
  }, [ createKeyfieldDashboardReportMutation, filters, session, Moment ]);

  const sendRankingsPDFReport = React.useCallback((payload, emailData) => {
    const data = addGeneralData(payload, emailData);
    executeMutation(createRankingsReportMutation, data, true); 
  }, [ createRankingsReportMutation, filters, session, Moment ]);

  const sendRankingsXLSReport = React.useCallback((payload, emailData) => {
    const data = addGeneralData(payload, emailData);
    executeMutation(createRankingsXLSReportMutation, data, true); 
  }, [ createRankingsXLSReportMutation, filters, session, Moment ]);

  const filteredDataVisitAnswers = dataVisitAnswers && dataVisitAnswers.visitAnswers ? 
    dataVisitAnswers.visitAnswers.filter(visitAnswer => visitAnswer) : [];

  // If the store is empty or different, use the visitID from the route params
  if(data.visits && (
      (!!visitID && !filters.selectedVisitID) || 
      (!!visitID && visitID !== filters.selectedVisitID.value))){
    const option = data.visits.find((visit) => visitID === visit._id);
    filtersActions.setVisitID({label: option.name, value: option._id});
  }

  // Select by default the first visit ID
  if(!visitID && !filters.selectedVisitID && data.visits && data.visits.length > 0) {
    const option = data.visits[0];
    filtersActions.setVisitID({label: option.name, value: option._id});
  }

  let closedCount = null;
  if (!loadingVisitAnswersClosed && dataVisitAnswersClosed) {
    const { visitAnswerConnection } = dataVisitAnswersClosed;
    if (visitAnswerConnection) {
      const { aggregate } = visitAnswerConnection;
      closedCount = aggregate.count;
    }
  }
  
  if (loading || loadingVisitAnswers || loadingVisitAnswersClosed || loadingVisitAnswersOpen) {
    return <LoadingProcessView closedCount={closedCount}/>
  } else {
    const selecteVisit = data.visits ? 
      data.visits.find((visit) => 
        filters.selectedVisitID && 
        filters.selectedVisitID.value === visit._id) 
      : null;

    const operators = 
      selecteVisit && selecteVisit.checkListConfiguration 
      && selecteVisit.checkListConfiguration.columns ? 
        getOperationsFromColumns(selecteVisit.checkListConfiguration.columns) 
        : [];

    const selectedOperation = operators.find(
      operator => 
        filters.selectedOperation && 
        filters.selectedOperation.value === operator.id);

    // Select by default the first option
    if(!selectedOperation && operators && operators.length > 0) {
      const option = operators[0];
      filtersActions.setOperation({label: option.name, value: option.id});
    }

    const visits = data.visits ? data.visits.map(({_id, name}) => ({id: _id, name})) : []; 
    const keyfields = getKeyfields(selecteVisit);
    const selectedKeyfield = getSelectedKeyfield(keyfields, match.params.idKeyfield);
    const categories = addGlobalCategory(getCategories(selectedOperation), filters.selectedOperation);
    const [activeCount, closedVisits] = classifyAndFilter(filteredDataVisitAnswers);
    const parsedClosed = closedVisits ? parseVisitAnswers(closedVisits, keyfields) : [];
    const reducedVisitAnswers = reduceOperations(parsedClosed, selectedKeyfield, selectedOperation);
    const emailData = {
      visit: filters.selectedVisitID?  filters.selectedVisitID.label : '', 
      startDate: filters.range.startDate.format("DD-MM-YYYY"),
      endDate: Moment(filters.range.endDate).format("DD-MM-YYYY")
    };
    
    return (
      <VisitsDashboardKeyfieldItemView
        activeVisitAnswer={{ loadingActiveVisitanswers: loadingVisitAnswersOpen, dataOpenVisitanswer: dataVisitAnswersOpen }}
        closedVisitAnswer={{ loadingVisitAnswersClosedCount: loadingVisitAnswersClosed, dataVisitanswerClosedCount: dataVisitAnswersClosed }}
        createAverageAnalysisReport={createAverageAnalysisReport}
        createKeyfieldDashboardReport={createKeyfieldDashboardReport}
        createRankingsReport={createRankingsReport}
        createAverageAnalysisXLSReport={createAverageAnalysisXLSReport}
        createRankingsXLSReport={createRankingsXLSReport}
        emailData={emailData}
        visits={visits}
        visitAnswers={{ loadingVisitAnswersClosed: loadingVisitAnswers, dataVisitAnswer: parsedClosed }}
        operators={operators}
        keyfield={selectedKeyfield}
        categories={categories}
        items={reducedVisitAnswers}
        sendAverageAnalysisPDFReport={sendAverageAnalysisPDFReport}
        sendAverageAnalysisXLSReport={sendAverageAnalysisXLSReport}
        sendKeyfieldDashboardPDFReport={sendKeyfieldDashboardPDFReport}
        sendRankingsPDFReport={sendRankingsPDFReport}
        sendRankingsXLSReport={sendRankingsXLSReport}
      />
    );
  }

}

export default VisitsDashboardKeyfieldItem;
