import React from 'react';
import {compose} from 'recompose';
import withStyles from "@material-ui/core/styles/withStyles";

import InputAdornment from "@material-ui/core/InputAdornment";
import Clear from "@material-ui/core/SvgIcon/SvgIcon";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const InputAdornmentSuccess = ({classes}) => {
  return (
    <InputAdornment
      position="end"
      className={classes.inputAdornment}
    >
      <Clear className={classes.inputAdornmentIconError}>
        {[]}
      </Clear>
    </InputAdornment>
  );
};

const styles = {
  ...regularFormsStyle
};


export default compose(
  withStyles(styles),
)(InputAdornmentSuccess);
