import cookie from "cookie";
const {DASH_TOKEN_COOKIE,DASH_USER_COOKIE,DASH_APP_COOKIE} = window._env_;

const session = {
  isAuthenticated: () => {
    const currentCookie = cookie.parse(document.cookie);
    const currentCookieElement = currentCookie[DASH_TOKEN_COOKIE];

    return !!currentCookieElement;
  },
  getDashAuthToken: async () => {
    const currentCookie = cookie.parse(document.cookie);
    return currentCookie[DASH_TOKEN_COOKIE];
  },
  getDashAppToken: async () => {
    const currentCookie = cookie.parse(document.cookie);
    return currentCookie[DASH_APP_COOKIE];
  },
  getDashUserToken: async () => {
    const currentCookie = cookie.parse(document.cookie);
    return currentCookie[DASH_USER_COOKIE];
  },
  setDashAuthToken: async (token) => {
    document.cookie = await cookie.serialize(DASH_TOKEN_COOKIE, token, {
      maxAge: 60 * 60 // 1 hour
    });
  },
  setDashAppToken: async (app) => {
    document.cookie = await cookie.serialize(DASH_APP_COOKIE, app, {
      maxAge: 60 * 60 // 1 hour
    });
  },
  setDashUserToken: async (user) => {
    document.cookie = await cookie.serialize(DASH_USER_COOKIE, user, {
      maxAge: 60 * 60 // 1 hour
    });
  },
  clearAuthTokens: async () => {
    document.cookie = await cookie.serialize(DASH_TOKEN_COOKIE, '', {
      expires: new Date(1900, 1, 1)
    });
    document.cookie = await cookie.serialize(DASH_APP_COOKIE, '', {
      expires: new Date(1900, 1, 1)
    });
    document.cookie = await cookie.serialize(DASH_USER_COOKIE, '', {
      expires: new Date(1900, 1, 1)
    });
  }
};

export default session;
