import { buildCombinedScoresSubject, buildCombinedScoresBody } from "./combinedScores";
import { buildRankingsSubject, buildRankingsPDFBody, buildRankingsXLSBody } from "./rankings";
import { buildAverageAnalysisSubject, buildAverageAnalysisPDFBody, buildAverageAnalysisXLSBody} from "./averageAnalysis";
import { buildKeyfieldDashboardSubject, buildKeyfieldDashboardBody } from "./keyfieldDashboard";
import { buildCategoryDashboardSubject, buildCategoryDashboardBody } from "./categoryDashboard";
import { buildOpportunityAreasSubject, buildOpportunityAreasPDFBody, buildOpportunityAreasXLSBody } from "./opportunityAreas";
import { buildGeneralExecutionSubject, buildGeneralExecutionPDFBody, buildGeneralExecutionXLSBody } from "./generalExecution";
import { buildActiveUserSubject, buildActiveUserPDFBody, buildActiveUserXLSBody } from "./activeUser";
import { buildVisitingTimeSubject, buildVisitingTimePDFBody } from "./visitingTime";
import { buildGalleryUserSubject, buildGalleryUserPDFBody } from "./galleryVisor";

import { REPORT_ACTIONS } from "../../constants";

export const getDefaultSubject = (report, content) => {
  switch(report){
    case REPORT_ACTIONS.combinedScoresPDF: 
      return buildCombinedScoresSubject(content);
    case REPORT_ACTIONS.combinedScoresXLS:
      return buildCombinedScoresSubject(content);
    case REPORT_ACTIONS.keyfieldDashboardPDF: 
      return buildKeyfieldDashboardSubject(content);
    case REPORT_ACTIONS.averageAnalysisPDF: 
      return buildAverageAnalysisSubject(content);
    case REPORT_ACTIONS.averageAnalysisXLS: 
      return buildAverageAnalysisSubject(content);
    case REPORT_ACTIONS.rankingsPDF: 
      return buildRankingsSubject(content);
    case REPORT_ACTIONS.rankingsXLS: 
      return buildRankingsSubject(content);
    case REPORT_ACTIONS.categoryDashboardPDF: 
      return buildCategoryDashboardSubject(content);
    case REPORT_ACTIONS.categoryDashboardXLS: 
      return buildCategoryDashboardSubject(content);
    case REPORT_ACTIONS.opportunityAreasPDF: 
      return buildOpportunityAreasSubject(content);
    case REPORT_ACTIONS.opportunityAreasXLS:
      return buildOpportunityAreasSubject(content);
    case REPORT_ACTIONS.generalExecutionPDF:
      return buildGeneralExecutionSubject(content);
    case REPORT_ACTIONS.generalExecutionXLS:
      return buildGeneralExecutionSubject(content);
    case REPORT_ACTIONS.activeUserPDF:
      return buildActiveUserSubject(content);
    case REPORT_ACTIONS.visitingTimePDF:
      return buildVisitingTimeSubject(content);
    case REPORT_ACTIONS.activeUserXLS:
      return buildActiveUserSubject(content);
    case REPORT_ACTIONS.galleryVisorPDF:
      return buildGalleryUserSubject(content);
    default: 
      return "";
  }
}

export const getDefaultBody = (report, content) => {
  switch(report){
    case REPORT_ACTIONS.combinedScoresPDF: 
      return buildCombinedScoresBody(content);
    case REPORT_ACTIONS.combinedScoresXLS:
      return buildCombinedScoresBody(content);
    case REPORT_ACTIONS.keyfieldDashboardPDF: 
      return buildKeyfieldDashboardBody(content);
    case REPORT_ACTIONS.averageAnalysisPDF: 
      return buildAverageAnalysisPDFBody(content);
      case REPORT_ACTIONS.averageAnalysisXLS: 
      return buildAverageAnalysisXLSBody(content);
    case REPORT_ACTIONS.rankingsPDF: 
      return buildRankingsPDFBody(content);
    case REPORT_ACTIONS.rankingsXLS: 
      return buildRankingsXLSBody(content);
    case REPORT_ACTIONS.categoryDashboardPDF: 
      return buildCategoryDashboardBody(content);
    case REPORT_ACTIONS.categoryDashboardXLS: 
      return buildCategoryDashboardBody(content);
    case REPORT_ACTIONS.opportunityAreasPDF: 
      return buildOpportunityAreasPDFBody(content);
    case REPORT_ACTIONS.opportunityAreasXLS:
      return buildOpportunityAreasXLSBody(content);
    case REPORT_ACTIONS.generalExecutionPDF:
      return buildGeneralExecutionPDFBody(content);
    case REPORT_ACTIONS.generalExecutionXLS:
      return buildGeneralExecutionXLSBody(content);
    case REPORT_ACTIONS.activeUserPDF:
      return buildActiveUserPDFBody(content);
    case REPORT_ACTIONS.visitingTimePDF:
      return buildVisitingTimePDFBody(content);
    case REPORT_ACTIONS.activeUserXLS:
      return buildActiveUserXLSBody(content);
    case REPORT_ACTIONS.galleryVisorPDF:
      return buildGalleryUserPDFBody(content);
    default: 
      return "";
  }
}