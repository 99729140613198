import React from 'react';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import TableChartIcon from '@material-ui/icons/TableChart';
import { useTranslation } from 'react-i18next';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from '../../utils/history';
import Button from 'components/Button';
import DetailProcessRelationshipTable from '../../tables/ProcessRelationship/DetailProcessRelationshipTable';
import {CircularProgress} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import {downloadCsv} from "../../utils/stringEncoding";
import {percentage100} from "../../utils/numeral/numeral";
import {Alert} from "@material-ui/lab";

const DetailProcessRelationshipView = ({
  results,
  processRelationship,
  item,
}) => {
  const { t, } = useTranslation(["processRelationship", "common"]);
  const {data, loading, resultsError } = results;
  const labelPeriodicity = t(`processRelationship.forms.fields.periodicity.options.${processRelationship.periodicity.toLowerCase()}`);

  const downloadCsvData = () => {
    const periodRanges = [
      t("processRelationship.tabulator.list.columns.elements"),
      ...data.periodicityRanges.map(
        periodicityRange => `${moment(periodicityRange.startDate).format('DD/MM/YYYY')} - ${moment(periodicityRange.endDate).format('DD/MM/YYYY')}`)
    ];
    const globalValues = ['Global', ...data.resultsTotal.map(percentage100)];
    const penalties = [
      t("processRelationship.tabulator.penalties"),
      ...data.penalties.map(penalty => percentage100(-penalty))];
    const values = data.results.map(result => [
      `${result.name}`,
      ...result.periodicity.map(percentage100)]);

    downloadCsv([periodRanges, globalValues, penalties, ...values],
      t("processRelationship.tabulator.csvFileName"));
  };

  return (
      <GridContainer>
        <GridItem>
          <Button
            simple
            size="lg"
            color="info"
            onClick={history.goBack}
          >
            <ArrowBackIcon/><span>{t('processRelationship.back')}</span>
          </Button>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <TableChartIcon />
              </CardIcon>
              <div style={style.left}>
                <h4 style={style.cardTitle}>
                  {`${t("processRelationship.tabulator.title", {
                    periodicity: labelPeriodicity
                  })} ${
                    processRelationship.name
                  } - ${item.name}`}
                </h4>
              </div>
              <div style={style.right}>
                <CardIcon color="info" style={style.pointer} onClick={downloadCsvData}>
                  <GetAppIcon />
                </CardIcon>
              </div>
            </CardHeader>
            <CardBody>

              {loading ? (
                <div style={style.progressCnt}>
                  <CircularProgress style={style.progress} />
                </div>
              ) : resultsError ? (
                <div>
                  <Alert variant="outlined" severity="error">
                    {t('common:common.error.title')}
                  </Alert>
                </div>
              ) : (
                <DetailProcessRelationshipTable
                  processRelationship={processRelationship}
                  data={data}
                  item={item}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
};

const style = {
  cardTitle: {
    color: "#3C4858"
  },
  progress: {
    margin: '16px'
  },
  progressCnt:{
    width: '100px !important',
    marginLeft: '50%',
    marginRight: '50%'
  },
  cardHeight: {
    height: "430px"
  },
  left: {
    float: "left"
  },
  right: {
    float: "right"
  },
  pointer: {
    cursor: "pointer"
  },
  marginBottom: {
    marginBottom: "25px"
  }
};

export default DetailProcessRelationshipView;
