import _ from "lodash";

export function analizeCombinedScores(data){
  if(data.length === 0) return null;
  let analytics = {
    min: 0.0,
    max: 0.0,
    average: 0.0
  };

  let visitscount = 0;
  data.forEach(item => {
    if (item.category.value !== null) {
      visitscount++;
    }
    analytics.average += item.category.value;
  });
  analytics.average /= visitscount;

  analytics.min = _.minBy(data, (item) => item.category.value) ? _.minBy(data, (item) => item.category.value).category.value.toFixed(2) : 0;
  analytics.max = _.maxBy(data, (item) => item.category.value) ? _.maxBy(data, (item) => item.category.value).category.value.toFixed(2) : 0;
  
  analytics.average = analytics.average.toFixed(2);

  return analytics;
}

export function calculateCombinedScores(group, items, operation, category, keyfields){
  if(!group || !items || !operation || !category) return 0;
  let acum = null;
  let total = 0;
  group.forEach((idx, index) => {
    if (items[idx].categories) {
      const categoryFind = items[idx].categories.find(({id}) => id === category.id);
      let result;
      if (categoryFind) {
        result = categoryFind.operations.find(({operator}) => (operator === operation.value));
      }
    if (result) {
      result = result.value;
      if (result !== null) {
        total = total + 1;
        acum += result
      }
    }
    
    }
  });
  return acum !== null ? (acum / total) : null;
}
