import React from 'react';
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import EmailMessageForm from 'forms/messages/EmailMessageForm';

function EmailMessageView({ defaultBody, defaultSubject, handleClose, hasXLS, open, sendPDFReport, sendXLSReport, type }){
  const handleSubmit = (params) => {
    if(params.reportType === "PDF") sendPDFReport(params);
    else sendXLSReport(params);
    handleClose();
  }

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">Enviar Reporte</DialogTitle>
      <DialogContent>
        <EmailMessageForm
          defaultBody={defaultBody} 
          defaultSubject={defaultSubject}
          hasXLS={hasXLS}
          onCancel={handleClose}
          submitSuccess={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
}

EmailMessageView.propTypes = {
  defaultBody: PropTypes.object,
  defaultSubject: PropTypes.object,
  handleClose: PropTypes.func.isRequired, 
  hasXLS: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  sendPDFReport: PropTypes.func,
  sendXLSReport: PropTypes.func,
};

const styles = theme => ({
});

export default withStyles(styles)(EmailMessageView);