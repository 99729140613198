import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const { NODE_ENV } = window._env_;

i18n
  .use(Backend)
  .use(LanguageDetector) // Aquí utilizas el detector de idioma
  .use(initReactI18next)
  .init({
    debug: NODE_ENV === 'development',
    defaultNS: 'common',
    lng: 'es', // Idioma por defecto
    fallbackLng: 'es', // Idioma de respaldo si el idioma seleccionado no está disponible

    interpolation: {
      escapeValue: false // No escapar los valores, útil para evitar problemas con XSS
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // Ruta donde se cargan las traducciones
    },

    detection: {
      // Configuración para el LanguageDetector
      order: ['localStorage', 'cookie', 'navigator'], // Orden de detección de idioma
      caches: ['localStorage', 'cookie'] // Donde almacenar el idioma seleccionado
    },

    react: {
      useSuspense: false,
      wait: true
    }
  });

export default i18n;
