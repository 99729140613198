import React, { useState } from 'react';
import ReactTable from "react-table";
import { Typography } from '@material-ui/core';
import DivCenter from "../../components/styled-components/DivCenter";
import { useTranslation } from "react-i18next";
import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from "moment";
import { Box, Select, MenuItem } from '@material-ui/core';

const ElementActivitiesTable = ({ data, setCurrentRightView, filters, setFilters }) => {
  const { t } = useTranslation("processRelationship");
  const [currentStatus, setCurrentStatus] = useState(filters.status_in.length > 1 ? 'All' : filters.status_in[0]);

  return (
    <ReactTable
      data={data}
      columns={[
        {
          Header: (
            <DivCenter style={{ ...styles.headerTitle }}>{t("processRelationship.workPlan.list.visit")}</DivCenter>
          ),
          accessor: "visitId",
          filterable: false,
          sortable: true,
          style: { textAlign: "center", whiteSpace: "unset" },
          Cell: ({ original }) => {
            return (
              <Typography style={styles.contentText}>
                {original?.visitRef?.name || ''}
              </Typography>
            );
          }
        },
        {
          Header: (
            <DivCenter style={{ ...styles.headerTitle }}>{t("processRelationship.workPlan.list.activity")}</DivCenter>
          ),
          accessor: "taskName",
          filterable: false,
          sortable: false,
          style: { textAlign: "left", whiteSpace: "unset" },
          Cell: ({ original }) => {
            return (
              <Box display="flex" alignItems="center" style={{ float: "left", width: "100%" }}>
                <Box style={{ width: "100%" }}>
                  <Typography style={styles.contentText}>
                    {original.taskName}
                  </Typography>
                </Box>
                <Box style={{ width: "50%", textAlign: "right" }}>
                  <Button justIcon round simple color="info" onClick={() => setCurrentRightView({ open: true, activity: original })}>
                    <VisibilityIcon style={{ fontSize: 40 }} />
                  </Button>
                </Box>
              </Box>
            );
          },
        },
        {
          Header: (
            <DivCenter style={{ ...styles.headerTitle }}>{t("processRelationship.workPlan.list.status")}</DivCenter>
          ),
          accessor: "status",
          filterable: true,
          sortable: true,
          style: { textAlign: "center" },
          Cell: ({ original }) => {
            return (
              <Typography style={styles.contentText}>
                {t(`processRelationship.workPlan.status.${original.status}`)}
              </Typography>
            );
          },
          Filter: () => {
            return (
              <Select
                value={currentStatus}
                onChange={(event) => {
                  const value = event.target.value;
                  setCurrentStatus(value);

                  let status;
                  if (value === 'All') {
                    status = ["Pending", "Completed"];
                  }else{
                    status = [value];
                  }

                  setFilters({
                    ...filters,
                    status_in: status
                  });
                }}
                style={{
                  width: "100%",
                }}
              >
                {["All", "Pending", "Completed"].map((status, index) => (
                  <MenuItem key={index} value={status}>
                    {t(`processRelationship.workPlan.status.${status}`)}
                  </MenuItem>
                ))}
              </Select>
            );
          }
        },
        {
          Header: (
            <DivCenter style={{ ...styles.headerTitle }}>
              {t("processRelationship.workPlan.list.reportedDate")}
            </DivCenter>
          ),
          accessor: "reportedDate",
          filterable: false,
          sortable: true,
          style: { textAlign: "center" },
          Cell: ({ original }) => {
            return (
              <Typography style={styles.contentText}>
                {moment(original.reportedDate).format("DD-MM-YYYY hh:mm a")}
              </Typography>
            );
          },
        },
        {
          Header: (
            <DivCenter style={{ ...styles.headerTitle }}>
              {t("processRelationship.workPlan.list.solvedDate")}
            </DivCenter>
          ),
          accessor: "solvedDate",
          filterable: false,
          sortable: true,
          style: { textAlign: "center" },
          Cell: ({ original }) => {
            return (
              <Typography style={styles.contentText}>
                {original.solvedDate
                  ? moment(original.solvedDate).format("DD-MM-YYYY hh:mm a")
                  : ""}
              </Typography>
            );
          },
        },
        {
          Header: (
            <DivCenter style={{ ...styles.headerTitle }}>
              {t("processRelationship.workPlan.list.reportedBy")}
            </DivCenter>
          ),
          accessor: "reportedByUserRef",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          Cell: ({ original }) => {
            return (
              <Typography style={styles.contentText}>
                {`${original.reportedByUserRef.firstName} ${original.reportedByUserRef.lastName}`}
              </Typography>
            );
          },
        },
        {
          Header: (
            <DivCenter style={{ ...styles.headerTitle }}>
              {t("processRelationship.workPlan.list.solvedBy")}
            </DivCenter>
          ),
          accessor: "resolveBy",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          Cell: ({ original }) => {
            return (
              <Typography style={styles.contentText}>
                {original?.solutionAnswer?.userRef?.firstName ? `${original?.solutionAnswer?.userRef?.firstName} ${original?.solutionAnswer?.userRef?.lastName}` : ''}
              </Typography>
            );
          },
        },
      ]}
      defaultPageSize={data.length}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
      resizable={false}
    />
  );
}

const styles = {
    headerTitle: {
      fontSize: "20px",
      whiteSpace: 'break-spaces',
      fontWeight: "500", 
    },
    contentText: {
      fontSize: 18,
    },
    
  };

export default ElementActivitiesTable;
