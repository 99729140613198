import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import {useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom'
import {isEmpty} from "ramda";

// core components
import Button from "components/CustomButtons/Button.js";

// icons
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardTwoTone";

// styled components
import DivCenter from "../../components/styled-components/DivCenter";

// utils
import {baseDateFormat, duration} from "../../utils/dates/dates";
import {VISIT_ENGINE_TYPES} from "../../constants";
import history from "../../utils/history";
import {percentage} from "../../utils/numeral/numeral";

const VisitApprovalItemsTable = ({visit, approvalItems}) => {
  const { t, } = useTranslation(['visits', 'checklist-configuration']);
  const [formattedData, changeFormattedData] = useState([]);
  const [keyFieldColumns, changeKeyFieldColumns] = useState([]);
  const [activityCounterColumns, changeActivityCounterColumns] = useState([]);

  useEffect(() => {
    const activityEngines = visit.engines.filter((engine) => {
      return engine.type === VISIT_ENGINE_TYPES.Activity;
    });

    changeFormattedData(approvalItems.map((item) => {
      const keyFieldValues = visit.keys.reduce((result, key) => {
        const keyAnswer = item.visitAnswerRef.checkIn.questionAnswersRef.answers.find((answer) => {
          return answer.key === key;
        });
        result[`keyAnswer_${key}`] = keyAnswer ? keyAnswer.answer[keyAnswer.answer.__typename] : '';
        return result;
      }, {});
      const activityCounterValues = activityEngines.reduce((result, engine) => {
        const activityAnswers = item.visitAnswerRef.activities.filter((activity) => {
          return activity.moduleId === engine.moduleId;
        });

        result[`activityCounter_${engine.moduleId}`] = activityAnswers.length;
        return result;
      }, {});

      return {
        ...item,
        ...keyFieldValues,
        ...activityCounterValues
      }
    }));
    changeKeyFieldColumns(visit.keys.map((key) => {
      return {
        Header: (<DivCenter>{key}</DivCenter>),
        accessor: `keyAnswer_${key}`,
        filterable: false,
        sortable: false,
        style: { textAlign: "center" },
        minWidth: 200
      }
    }));
    changeActivityCounterColumns(activityEngines.map((engine) => {
      return {
        Header: (<DivCenter>{engine.moduleRef.name}</DivCenter>),
        accessor: `activityCounter_${engine.moduleId}`,
        filterable: false,
        sortable: false,
        style: { textAlign: "center" },
        minWidth: 200
      }
    }));
  }, []);

  const checklistColumnGroups = visit.checkListConfiguration.columns.map((columnGroup) => {
    return {
      Header: (<DivCenter>{columnGroup.category}</DivCenter>),
      style: { textAlign: "center" },
      columns: columnGroup.operation.map((operation) => {
        return {
          Header: (<DivCenter>{t(`checklist-configuration:checklist-configuration.${operation.name}`)}</DivCenter>),
          id: `${columnGroup.category}${operation.name}`,
          Cell: ({original}) => {
            if (!original.visitAnswerRef || original.visitAnswerRef.calculatedChecklistColumns) {
              const categoryColumns = original.visitAnswerRef
                .calculatedChecklistColumns.categories
                .find((category) => {
                  return category.name === columnGroup.category;
                });
              if (categoryColumns) {
                const operationValue = categoryColumns.operations.find((operationColumnValue) => {
                  return operationColumnValue.name === operation.name;
                });
                return operationValue ? (
                  operation.name.endsWith('efficacy') || operation.name.endsWith('average') ?
                    percentage(operationValue.value / 100) : operationValue.value
                ) : 0;
              }
            }
            return 0;
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 100
        }
      })
    }
  });
  const globalColumns = visit.checkListConfiguration.columns.reduce((result, columnGroup) => {
    const averageOperationFound = columnGroup.operation.find(operation => operation.name.endsWith('average'));
    const efficacyOperationFound = columnGroup.operation.find(operation => operation.name.endsWith('efficacy'));

    // Validate if global group already added
    if ((!!averageOperationFound || !!efficacyOperationFound) && !result.Header) {
      result = {
        Header: (<DivCenter>Globales</DivCenter>),
        style: {textAlign: "center"},
        columns: []
      }
    }

    // Add global columns if missing
    if (averageOperationFound) {
      const globalName = 'global';
      const columnGlobalAverageFound = result.columns.find(column => column.id === globalName);
      if (!columnGlobalAverageFound) {
        result.columns = [...result.columns, {
          Header: (<DivCenter>{t(`checklist-configuration:checklist-configuration.globals.${globalName}`)}</DivCenter>),
          id: globalName,
          Cell: ({original}) => {
            if (!original.visitAnswerRef || original.visitAnswerRef.calculatedChecklistColumns) {
              const {globals} =original.visitAnswerRef.calculatedChecklistColumns;
              const globalColumn = globals.find(global => global.name === globalName);
              if (!!globalColumn) {
                return percentage(globalColumn.value / 100);
              }
            }
            return 0;
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 100
        }
        ]
      }
    }
    if (efficacyOperationFound) {
      const globalEfficacyName = 'globalEfficacy';
      const columnGlobalEfficacyFound = result.columns.find(column => column.id === globalEfficacyName);
      if (!columnGlobalEfficacyFound) {
        result.columns = [...result.columns, {
          Header: (<DivCenter>{t(`checklist-configuration:checklist-configuration.globals.${globalEfficacyName}`)}</DivCenter>),
          id: globalEfficacyName,
          Cell: ({original}) => {
            if (!original.visitAnswerRef || original.visitAnswerRef.calculatedChecklistColumns) {
              const {globals} =original.visitAnswerRef.calculatedChecklistColumns;
              const globalColumn = globals.find(global => global.name === globalEfficacyName);
              if (!!globalColumn) {
                return percentage(globalColumn.value / 100);
              }
            }
            return 0;
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 100
        }
        ]
      }
    }
    return result;
  }, {});

  const allColumns = [
    {
      Header: (<DivCenter>Ir</DivCenter>),
      id: "actions",
      Cell: (d) => {
        const {visitId, visitAnswerId, _id} = d.original;
        return <DivCenter>
          <Link to={{
            pathname: `/visits/${visitId}/visitAnswer/${visitAnswerId}/approve/${_id}`,
          }}>
            <Button
              justIcon
              round
              simple
              color="info"
            >
              <ArrowForwardIcon/>
            </Button>
            {" "}
          </Link>
        </DivCenter>
      },
      filterable: false,
      sortable: false,
      width: 100
    },
    {
      Header: <DivCenter>{t('visits.list.columns.nameUser')}</DivCenter>,
      Cell: (d) => {
        const {firstName, lastName, email} = d.original.visitAnswerRef.userRef;
        return <DivCenter><h6>{`${firstName} ${lastName}`} <br/><small>{email}</small></h6></DivCenter>;
      },
      filterable: false,
      sortable: false,
      minWidth: 180
    },
    {
      Header: (<DivCenter>{t('visits.list.columns.checkInDate')}</DivCenter>),
      accessor: "visitAnswerRef.checkInDate",
      Cell: (d) => {
        return baseDateFormat(d.original.visitAnswerRef.checkInDate);
      },
      filterable: false,
      sortable: false,
      style: { textAlign: "center" },
      minWidth: 200
    },
    {
      Header: (<DivCenter>{t('visits.list.columns.checkOutDate')}</DivCenter>),
      accessor: "visitAnswerRef.checkOutDate",
      Cell: (d) => {
        return baseDateFormat(d.original.visitAnswerRef.checkOutDate);
      },
      filterable: false,
      sortable: false,
      style: { textAlign: "center" },
      minWidth: 200
    },
    {
      Header: (<DivCenter>{t('visits.list.columns.duration')}</DivCenter>),
      Cell: (d) => {
        const {checkInDate, checkOutDate} = d.original.visitAnswerRef;
        return duration(checkInDate, checkOutDate);
      },
      filterable: false,
      sortable: false,
      style: { textAlign: "center" },
      minWidth: 100
    },
    {
      Header: (<DivCenter>{t('visits.list.columns.group.keyFields')}</DivCenter>),
      style: { textAlign: "center" },
      columns: keyFieldColumns
    },
    {
      Header: (<DivCenter>{t('visits.list.columns.group.activityCounters')}</DivCenter>),
      style: { textAlign: "center" },
      columns: activityCounterColumns
    },
    ...checklistColumnGroups
  ];

  return (
    <ReactTable
      data={formattedData}
      getTrProps={(state, rowInfo, column, instance) => ({
        onClick: e => {
          const {visitId, visitAnswerId, _id} = rowInfo.original;
          history.push(`/visits/${visitId}/visitAnswer/${visitAnswerId}/approve/${_id}`)
        }
      })}
      columns={!isEmpty(globalColumns) ? [...allColumns, globalColumns] : allColumns}
      defaultPageSize={15}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
    />
  );
};

VisitApprovalItemsTable.propTypes = {
  approvalItems: PropTypes.array.isRequired,
  visit: PropTypes.object.isRequired
};

export default VisitApprovalItemsTable;
