import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import InputLabel from "@material-ui/core/InputLabel";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { useTranslation } from "react-i18next";

const TablePagination = ({ paginationAction, filter, data, total }) => {
  const { t } = useTranslation("common");

  return (
    <GridContainer>
      <GridItem xs={4}>
        <Button
          style={styles.btnPreview}
          color="primary"
          disabled={filter.skip <= 0}
          onClick={() => {
            paginationAction({ ...filter, skip: filter.skip - 15, numPage: filter.numPage - 1 });
          }}
        >
          <KeyboardArrowLeft /> {t("common.pagination.back")}
        </Button>
      </GridItem>
      <GridItem xs={4} style={styles.textCenter}>
        {total ? (
          <InputLabel>
            {" "}
            {t("common.pagination.page")}{" "}
            {(filter.skip + filter.first) / filter.first }{" "}
            {t("common.pagination.of")} {Math.ceil(total / filter.first)}{" "}
          </InputLabel>
        ) : (
          <div></div>
        )}
      </GridItem>
      <GridItem xs={4}>
        <Button
          style={styles.btnNext}
          color="primary"
          disabled={data.length === 0 || data.length < 15}
          onClick={() => {
            paginationAction({ ...filter, skip: filter.skip + 15, numPage: filter.numPage + 1 });
          }}
        >
          {t("common.pagination.next")} <KeyboardArrowRight />
        </Button>
      </GridItem>
    </GridContainer>
  );
};

const styles = {
  btnPreview: {
    marginRight: "5px",
    float: "left"
  },
  btnNext: {
    marginRight: "5px",
    float: "right"
  },
  textCenter: {
    textAlign: 'center',
    marginTop: '20px'
  }
}

export default TablePagination;
