





import React, { useState } from "react";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";


let anchorEl = null;

const CardHeaderSimple = ({ title, titleStyle, cardRight, cardLeft }) => {  

    const [styleTitle, setStyleTitle] = useState(titleStyle ? titleStyle : {"color":"#000", "float": "left",  "margin-left":"15px"})

  return (
            <CardHeader color="info" icon style={{"border-bottom": "1px solid #e6e6e6"}}>
                {(cardLeft) && <CardText display={{ xs: "none", sm: "block" }} color="info" style={
                    { 
                        "float": "left", 
                        "margin-left":"5px", 
                        "margin-top":"5px",
                        "margin-right":"5px",
                        "margin-bottom":"5px"
                    }
                    }>
                    {cardLeft.value ? cardLeft.value : cardLeft.loading}
                </CardText>}
                <h4 style={styleTitle}>{title}</h4>
                
                {(cardRight) && <CardText color="info" style={{ "position": "absolute", "right":"5px", "top":"5px" }}>                    
                    {cardRight.value ? cardRight.value : cardRight.loading}
                </CardText>}
            </CardHeader>
        );
}


export default CardHeaderSimple;