import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import {useTranslation} from "react-i18next";

import SimpleItemSuggestion from "./SimpleItemSuggestion";
import {getNamespacesFromKeys} from "../../utils/translation/namespaces";

const SuggestionSearchAsync = ({suggestionList, selected, placeholder, getSuggestionName, getSuggestionId, loadSuggestions}) => {
  // Local State
  const [suggestions, setSuggestions] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSuggestions(suggestionList);
  }, [suggestionList]);

  const { t,  } = useTranslation(getNamespacesFromKeys([placeholder]));

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={({value}) => {
        loadSuggestions(value);
      }}
      onSuggestionsClearRequested={() => {
        setSuggestions([]);
      }}
      getSuggestionValue={getSuggestionName}
      renderSuggestion={props => {
        return (
          <SimpleItemSuggestion name={getSuggestionName(props)} />
        );
      }}
      onSuggestionSelected={(event, {suggestion}) => {
        selected(getSuggestionId(suggestion));
        setSearch('');
      }}
      inputProps={
        {
          placeholder: !!placeholder ? t(placeholder) : '',
          value: search,
          type: 'search',
          onChange: (event, {newValue, method}) => {
            if (method === 'enter' || method === 'click') {
              setSearch(newValue);
            }else{
              setSearch(event.target.value);
            }
          }
        }
      }
    />
  );

};

SuggestionSearchAsync.propTypes = {
  selected: PropTypes.func.isRequired,
  getSuggestionName: PropTypes.func.isRequired,
  getSuggestionId: PropTypes.func.isRequired,
  suggestionList: PropTypes.array.isRequired,
  loadSuggestions: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

export default SuggestionSearchAsync;
