import React from 'react';
import Chart from 'chart.js';
import { useTranslation } from 'react-i18next';

let chart = null;
const BarChart = ({data, frequencies, setOpen, setAnswers}) => {

    const chartRef = React.useRef();

    const { t } = useTranslation('common');

    const graphClickEvent = (evt, array) => {
      if(array[0]){
        
        const activePoints = chart.getElementsAtEventForMode(evt, 'point', chart.options);
        const firstPoint = activePoints[0];
        const label = chart.data.labels[firstPoint._index];

        const frequency = frequencies.find(frequency => frequency.label.trim() === label.trim());
        setAnswers(frequency);
        setOpen(true);
     }
    }

    React.useEffect(() => {
      const canvasRef = chartRef.current.getContext("2d");
      if (chart) chart.destroy();
      chart = new Chart(canvasRef, {
        type: "bar",
        data: data,
        options: {
          legend: false,
          responsive: true,
          tooltips: {
            callbacks: {
              title: () => {
                return t('common.views.viewDetails');
              }
            }
          },
          hover: {
            animationDuration: 0,
            onHover: function(e) {
              const point = chart.getElementAtEvent(e);
              if (point.length) e.target.style.cursor = 'pointer';
              else e.target.style.cursor = 'default';
           }
          },
          animation: {
            duration: 1,
            onComplete: function() {
              const chartInstance = chart;
              const ctx = chartInstance.ctx;

              ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';

              data.datasets.forEach(function(dataset, i) {
                const meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function(bar, index) {
                  const data = dataset.data[index];
                  
                  ctx.fillText(data, bar._model.x, bar._model.y - 5);
                });
              });
            }
          },
          onClick: graphClickEvent,
          scales: {
            yAxes: [{
              ticks: {
                max: Math.max(...data.datasets[0].data) + 5,
                beginAtZero: true
              }
            }],
            xAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        },
        
      });
    }, [data]);


    return <canvas id="myChart" ref={chartRef} />;
}

export default BarChart;
