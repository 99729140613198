import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import withStyles from "@material-ui/core/styles/withStyles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Button from 'components/Button';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import FiltersSidebar from "components/FiltersSidebar/FiltersSidebar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import RadarChart from 'components/Chart/RadarChart';
import SendEmailButton from "components/Reports/SendEmailButton";
import DownloadButton from "components/Reports/DownloadButton";

import OpportunityAreasTable from 'tables/Visits/OpportunityAreasTable';
import history from '../../utils/history';
import { groupByKeyfields, reducedGroupedVisitsAnswers, reduceOperations, getRadarChartData } from '../../utils/analytics/dataManipulation';
import { getDefaultSubject, getDefaultBody } from '../../utils/emails/defaultContent';
import { REPORT_ACTIONS } from '../../constants';
import { useTranslation } from 'react-i18next';

const OpportunityAreasItemView = ({
  classes, 
  activeVisitAnswer,
  closedVisitAnswer,
  createOpportunityAreasReport,
  createOpportunityAreasXLSReport,
  emailData,
  keyfields,
  operation,
  operators,
  sendOpportunityAreasPDFReport,
  sendOpportunityAreasXLSReport,
  visits,
  visitAnswers,
  segment,
  segments,
  handleSegmentChange,
  currentKeyfield,
  setCurrentKeyField
}) => {

  const [keyfieldOption, setKeyfieldOption] = React.useState({
    value: currentKeyfield ? currentKeyfield.value : keyfields[0].id, 
    label: currentKeyfield ? currentKeyfield.label : keyfields[0].name
  });

  const { t } = useTranslation("general_adaptation");

  const { dataVisitAnswer } = visitAnswers;

  const selectedKeyfield = keyfields.find((keyfield) => keyfield.id === keyfieldOption.value);
  const reducedItems = reduceOperations(dataVisitAnswer, selectedKeyfield, operation); 
  const groupsByKeyfield = keyfieldOption ? groupByKeyfields(reducedItems, [selectedKeyfield]) : [];
  const groupedItems = reducedGroupedVisitsAnswers(groupsByKeyfield, reducedItems);

  const chartData = React.useMemo(() => 
    getRadarChartData(dataVisitAnswer, operation)
  , [dataVisitAnswer, operation]);

  const onSelectChange = React.useCallback((keyfieldOption) => {
    setKeyfieldOption(keyfieldOption);
    setCurrentKeyField(keyfieldOption);
  }, [setKeyfieldOption]);

  const selectOptions = React.useMemo(() => 
    keyfields.map((keyfield) => ({
      value: keyfield.id,
      label: keyfield.name,
    }))
  , [keyfields]);
  
  

  const wrapPDFData = () => {
    return {
      keyfield: {
        name: keyfieldOption.label,
        value: keyfieldOption.value,
      },
      globalCategories: {
        labels: chartData.labels,
        data: chartData.datasets[0].data
      },
      items: groupedItems
    };
  };

  const wrapXLSData = () => {
    return {
      keyfield: {
        name: keyfieldOption.label,
        value: keyfieldOption.value,
      },
      items: groupedItems
    };
  };

  const handlePDFReportClick = React.useCallback(() => {
    const payload = wrapPDFData();
    createOpportunityAreasReport(payload);
  }, [ chartData, createOpportunityAreasReport,  groupedItems, keyfieldOption ]);

  const handleXLSReportClick = React.useCallback(() => {
    const payload = wrapXLSData();
    createOpportunityAreasXLSReport(payload);
  }, [ createOpportunityAreasXLSReport, groupedItems, keyfieldOption ]);

  const handlePDFReportEmailClick = React.useCallback((emailData) => {
    const payload = wrapPDFData();
    sendOpportunityAreasPDFReport(payload, emailData);
  }, [ chartData, sendOpportunityAreasPDFReport,  groupedItems, keyfieldOption ]);

  const handleXLSReportEmailClick = React.useCallback((emailData) => {
    const payload = wrapXLSData();
    sendOpportunityAreasXLSReport(payload, emailData);
  }, [ sendOpportunityAreasXLSReport, groupedItems, keyfieldOption ]);

  const defaultBody = {
    PDF: getDefaultBody( REPORT_ACTIONS.opportunityAreasPDF, 
      { visit: emailData.visit, keyfield: keyfieldOption.label } ),
    XLS: getDefaultBody( REPORT_ACTIONS.opportunityAreasXLS,
      { visit: emailData.visit, keyfield: keyfieldOption.label } ),
  };

  const defaultSubject = {
    PDF: getDefaultSubject( REPORT_ACTIONS.opportunityAreasPDF,
      { visit: emailData.visit, keyfield: keyfieldOption.label,
        startDate: emailData.startDate, endDate: emailData.endDate } ),
    XLS: getDefaultSubject( REPORT_ACTIONS.opportunityAreasXLS,
      { visit: emailData.visit, keyfield: keyfieldOption.label,
        startDate: emailData.startDate, endDate: emailData.endDate } )
  };

  const { loadingVisitAnswersClosedCount, dataVisitanswerClosedCount } = closedVisitAnswer;

  let closedCount = null;
  if (!loadingVisitAnswersClosedCount && dataVisitanswerClosedCount) {
    const { visitAnswerConnection } = dataVisitanswerClosedCount;
    if (visitAnswerConnection) {
      const { aggregate } = visitAnswerConnection;
      closedCount = aggregate.count;
    }
  }

  return (
    <GridContainer>
      <GridItem xs={3}>
        <FiltersSidebar
          activeVisitAnswer={activeVisitAnswer}
          closedVisitAnswer={closedCount}
          visits={visits}
          visitAnswers={visitAnswers}
          operators={operators}
        />
      </GridItem>
      <GridItem xs={9}>
        <Button
            simple
            size="lg"
            color="info"
            onClick={history.goBack}
            className={classes.buttonNoMargin}
          >
          <ArrowBackIcon/><span>{t("back_to_table")}</span>
        </Button>
        <Card>
          <CardHeader color="info" text>
            <GridContainer>
              <GridItem xs={10}>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>{t("oportunities_area")}</h4>
                </CardText>
              </GridItem>
              <GridItem xs={1}>
                <CardText color="info" className={classes.noPadding}>
                  <SendEmailButton
                    color="white"
                    defaultBody={defaultBody}
                    defaultSubject={defaultSubject}
                    isReady={true}
                    hasXLS={true}
                    sendPDFReport={handlePDFReportEmailClick}
                    sendXLSReport={handleXLSReportEmailClick}
                    size="lg"
                  />
                </CardText>
              </GridItem>
              <GridItem xs={1}>
                <CardText color="info" className={classes.noPadding}>
                  <DownloadButton
                    color="white"
                    isReady={true}
                    size="lg"
                    hasXLS={true}
                    onClickPDF={handlePDFReportClick}
                    onClickXLS={handleXLSReportClick}
                  />
                </CardText>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <h3>{t("dist_cat")} {keyfieldOption.label}</h3>
            <GridContainer>
              <GridItem xs={3}>
                <Select
                  className={classes.selector}
                  placeholder={"Seleccionar un Campo Clave"}
                  value={keyfieldOption}
                  onChange={onSelectChange}
                  options={selectOptions}
                />
              </GridItem>
              <GridItem xs={3}>
                <Select
                  className={classes.selector}
                  placeholder={"Seleccionar un Segmento"}
                  value={segment}
                  onChange={handleSegmentChange}
                  options={segments}
                />
              </GridItem>
            </GridContainer>
            <div className={classes.container}>
              <RadarChart
                data={chartData}
              />
            </div>
            <div className={classes.container}>
              
              <OpportunityAreasTable
                data={groupedItems}
              />
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

OpportunityAreasItemView.propTypes = {
  activeVisitAnswer: PropTypes.object.isRequired,
  createOpportunityAreasReport: PropTypes.func.isRequired,
  createOpportunityAreasXLSReport: PropTypes.func.isRequired,
  emailData: PropTypes.object.isRequired,
  keyfields: PropTypes.array.isRequired,
  operation: PropTypes.object,
  operators: PropTypes.array.isRequired,
  sendOpportunityAreasPDFReport: PropTypes.func.isRequired,
  sendOpportunityAreasXLSReport: PropTypes.func.isRequired,
  visits: PropTypes.array.isRequired,
  visitAnswers: PropTypes.object.isRequired,
};

const styles = theme => ({
  buttonNoMargin:{
    padding: "15px 0px"
  },
  container: {
    margin: "20px 0px"
  },
  containerSelect: {
    display: "flex",
    margin: "20px 0px"
  },
  noPadding: {
    padding: 0
  },
});

export default withStyles(styles)(OpportunityAreasItemView);
