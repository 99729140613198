import React, { useMemo, useState } from "react";
import { useQuery } from "react-apollo-hooks";
import { useMutation } from "@apollo/react-hooks";

import useGlobal from "../../../store";
import {
  SO_CHECKLIST_REPORTS,
  SO_CHECKLIST_REPORTS_BASE_MODELS,
} from "../gql/Queries";
import {
  GENERATE_SO_CHECKLIST_REPORT,
  ARCHIVE_SO_CHECKLIST_REPORT,
} from "../gql/Mutations";

import ChecklistReportListView from "../../../Views/SO/Checklist/ChecklistReportListView";
import auth from "../../../utils/auth";
import history from "../../../utils/history";
import { useTranslation } from "react-i18next";

const ChecklistReportList = () => {
  const { t } = useTranslation("common");
  const [session, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );
  const [archivedMode, setArchivedMode] = useState(false);

  const {
    data: dataSOChecklistReports,
    error: errorSOChecklistReports,
    loading: loadingSOChecklistReports,
    refetch: refetchSOChecklistReports,
  } = useQuery(SO_CHECKLIST_REPORTS, {
    variables: {
      app: session.user ? session.appRef._id : "",
      archived: archivedMode,
    },
    fetchPolicy: "network-only",
  });

  const {
    data: dataSOChecklistReportsBaseModels,
    loading: loadingSOChecklistReportsBaseModels,
  } = useQuery(SO_CHECKLIST_REPORTS_BASE_MODELS, {
    variables: {
      app: session.user ? session.appRef._id : "",
      archived: false,
    },
    fetchPolicy: "network-only",
  });

  const [generateSoChecklistReport] = useMutation(
    GENERATE_SO_CHECKLIST_REPORT,
    {
      onCompleted: (mutatinoDatea) => {
        console.log("mutatinoDatea", mutatinoDatea);
        globalActions.notification.openNotification("tr", {
          message: "Procesando Reporte",
          color: "success",
        });
      },
    }
  );

  const [archiveSoChecklistReport] = useMutation(ARCHIVE_SO_CHECKLIST_REPORT, {
    onCompleted: (mutatinoDatea) => {
      globalActions.notification.openNotification("tr", {
        message: "Status de reporte actualizado",
        color: "success",
      });
      refetchSOChecklistReports();
    },
  });

  useMemo(() => {
    if (
      session.user &&
      !auth.hasPermission("soChecklistView", session.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: t("common.hasNotPermission"),
        color: "danger",
      });
      history.push("/analytics");
    }
  }, [session]);

  return (
    <ChecklistReportListView
      soChecklistReports={dataSOChecklistReports?.soChecklistReports || []}
      soChecklistReportsLoading={
        loadingSOChecklistReports || loadingSOChecklistReportsBaseModels
      }
      generateReport={(checklistReport) => {
        generateSoChecklistReport({
          variables: {
            where: {
              _id: checklistReport._id,
            },
            data: {
              userId: session.user._id,
            },
          },
        });
      }}
      visits={dataSOChecklistReportsBaseModels?.visits || []}
      processRelationships={
        dataSOChecklistReportsBaseModels?.getProcessRelationships || []
      }
      isArchivedMode={archivedMode}
      changeArchivedMode={() => {
        setArchivedMode(!archivedMode);
      }}
      archive={(checklistReport) => {
        archiveSoChecklistReport({
          variables: {
            where: {
              _id: checklistReport._id,
            },
            data: {
              archived: !archivedMode,
            },
          },
        });
      }}
    />
  );
};

export default ChecklistReportList;
