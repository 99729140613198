import React from 'react';
import PropTypes from 'prop-types';

// core components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

// styled components
import DivCenter from "components/styled-components/DivCenter";

const ListOptionView = ({options, selected}) => {
  return (
    <List>
      {selected.map((selectedOption, index) => {
        const option = options.find(option => option.value === selectedOption);

        if (option) {
          return (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar
                  alt={`Avatar n°${index + 1}`}
                  src={option.image.i240}
                />
              </ListItemAvatar>
              <ListItemText>
                <DivCenter>{option.value}</DivCenter>
              </ListItemText>
            </ListItem>
          );
        }else{
          return [];
        }
      })}
    </List>

  );
};

ListOptionView.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired
};

export default ListOptionView;
