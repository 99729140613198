import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

// custom components
import ButtonIcon from "../components/styled/ButtonIcon";

// core components
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Cancel from "@material-ui/icons/Cancel";
import Check from "@material-ui/icons/Check";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WithConfirmation = BaseForm =>
  function WithConfirmationHook(props) {
    const {t} = useTranslation('common');

    const [isConfirming, setConfirming] = useState(false);
    const [confirmInfo, setConfirmingInfo] = useState({});

    const toggleConfirmation = (confirmInfo) => {
      setConfirming(true);
      setConfirmingInfo(confirmInfo)
    };

    const handleCancel = () => {
      setConfirming(false);
    };

    const handleSuccess = () => {
      confirmInfo.callback();
      setConfirming(false);
    };

    return <React.Fragment>
      <BaseForm {...props}
                goToConfirmation={toggleConfirmation} />
      <Dialog
        open={isConfirming}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCancel}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{confirmInfo.title || ''}</DialogTitle>
        <DialogContent style={{textAlign: 'center'}}>
          <DialogContentText id="alert-dialog-slide-description">
            {confirmInfo.message || ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonIcon
            icon={Cancel}
            label={t('common.dialogs.cancel')}
            color={'danger'}
            block={true}
            onClick={handleCancel}
          />
          <ButtonIcon
            icon={Check}
            label={t('common.dialogs.accept')}
            color={'success'}
            block={true}
            onClick={handleSuccess}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  }
;

export default WithConfirmation;
