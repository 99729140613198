import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import useGlobal from "../../store";
import { DOGFOODING_PRODUCTS, hasPermissions } from "../../utils/Whitelist";
import { includes } from "ramda";

const LinksMenu = ({
  classes,
  openCollapse,
  routes,
  activeRoute,
  miniActiveProps,
  miniActiveState,
  isRouteActive,
  color,
}) => {
  const { t } = useTranslation("routeTitles");
  const [session, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );
  const showChecklistDashboard = hasPermissions(
    DOGFOODING_PRODUCTS.ChecklistDashboard,
    session.user._id
  );

  return (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.redirect) {
          return null;
        }
        if (prop.collapse) {
          const navLinkClasses =
            classes.itemLink +
            " " +
            cx({
              [" " + classes.collapseActive]: activeRoute(prop.path),
            });
          const itemText =
            classes.itemText +
            " " +
            cx({
              [classes.itemTextMini]: miniActiveProps && miniActiveState,
            });
          const collapseItemText =
            classes.collapseItemText +
            " " +
            cx({
              [classes.collapseItemTextMini]:
                miniActiveProps && miniActiveState,
            });
          const itemIcon = classes.itemIcon;
          const caret = classes.caret;
          return (
            <ListItem key={key} className={classes.item}>
              <NavLink
                id={prop.id}
                to={"#"}
                className={navLinkClasses}
                onClick={() => openCollapse(prop.state)}
              >
                <ListItemIcon className={itemIcon}>
                  {typeof prop.icon === "string" ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <prop.icon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={prop.name}
                  secondary={
                    <b
                      className={
                        caret +
                        " " +
                        (isRouteActive(prop.state) ? classes.caretActive : "")
                      }
                    />
                  }
                  disableTypography={true}
                  className={itemText}
                />
              </NavLink>
              <Collapse in={isRouteActive(prop.state)} unmountOnExit>
                <List className={classes.list + " " + classes.collapseList}>
                  {prop.views.map((view, key) => {
                    if (view.show) {
                      if (
                        view.name === DOGFOODING_PRODUCTS.ChecklistDashboard &&
                        !showChecklistDashboard
                      ) {
                        return null;
                      }
                      if (view.redirect) {
                        return null;
                      }
                      if (
                        view.permission &&
                        !includes(view.permission, session.permissions)
                      ) {
                        return null;
                      }
                      const navLinkClasses =
                        classes.collapseItemLink +
                        " " +
                        cx({
                          [" " + classes[color]]: activeRoute(view.path),
                        });
                      const collapseItemMini = classes.collapseItemMini;
                      if (view.link) {
                        return (
                          <ListItem
                            key={key}
                            className={classes.collapseItem}
                            button
                          >
                            <div className={navLinkClasses}>
                              <a
                                style={style.linkColor}
                                href={view.path}
                                target="_blank"
                              >
                                <span className={collapseItemMini}>
                                  {view.mini}
                                </span>
                                <ListItemText
                                  primary={t(`routeTitles.menu.${view.name}`)}
                                  disableTypography={true}
                                  className={collapseItemText}
                                />
                              </a>
                            </div>
                          </ListItem>
                        );
                      } else {
                        const anchorIdProps = {
                          id: view.id || undefined,
                        };

                        return (
                          <ListItem
                            key={key}
                            className={classes.collapseItem}
                            button
                          >
                            <NavLink
                              to={view.path}
                              className={navLinkClasses}
                              {...anchorIdProps}
                            >
                              <span className={collapseItemMini}>
                                {view.mini}
                              </span>
                              <ListItemText
                                primary={t(`routeTitles.menu.${view.name}`)}
                                disableTypography={true}
                                className={collapseItemText}
                              />
                            </NavLink>
                          </ListItem>
                        );
                      }
                    } else {
                      return null;
                    }
                  })}
                </List>
              </Collapse>
            </ListItem>
          );
        }

        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes[color]]: activeRoute(prop.path),
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]: miniActiveProps && miniActiveState,
          });
        const itemIcon = classes.itemIcon;

        if (prop.link) {
          return (
            <ListItem key={key} className={classes.item} button>
              <div className={navLinkClasses}>
                <a style={style.linkColor} href={prop.path} target="_blank">
                  <ListItemIcon className={itemIcon}>
                    {typeof prop.icon === "string" ? (
                      <Icon>{prop.icon}</Icon>
                    ) : (
                      <prop.icon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.name}
                    disableTypography={true}
                    className={itemText}
                  />
                </a>
              </div>
            </ListItem>
          );
        } else {
          return (
            <ListItem key={key} className={classes.item} button>
              <NavLink to={prop.path} className={navLinkClasses}>
                <ListItemIcon className={itemIcon}>
                  {typeof prop.icon === "string" ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <prop.icon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  className={itemText}
                />
              </NavLink>
            </ListItem>
          );
        }
      })}
    </List>
  );
};

const style = {
  linkColor: {
    color: "inherit",
  },
};

export default LinksMenu;
