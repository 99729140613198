import React from 'react';

const SimpleItemSuggestion = ({name}) => {

  return (
    <div>
      {name}
    </div>
  );
};

export default SimpleItemSuggestion;
