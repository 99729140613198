import React from "react";
import { VISIT_ITEMS, USERS_GH, SEGMENTS_FEED, TEAMS_FEED } from "./gql/Queries";
import { useQuery, useMutation } from "react-apollo-hooks";
import useGlobal from "../../store";
import { useTranslation } from "react-i18next";
import history from "../../utils/history";
import { UPDATE_CHECKLIST_ANALISYS_REPORT_MUTATION } from "./gql/Mutations";
import { CircularProgress } from "@material-ui/core";
import EditChecklistAnalisysVisitReportView from "../../Views/Reports/EditChecklistAnalisysVisitReportView";

const UpdateChecklistAnalisysVisitReport = ({location}) => {
  const { t } = useTranslation("checklistAnalisys");
  const { state } = location;
  const [session] = useGlobal(
    state => state.session,
    actions => actions
  );
  const [, globalActions] = useGlobal();
  const { data: dataVisit, loading: loadingVisit } = useQuery(VISIT_ITEMS, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : "",
        //hasChecklistConfiguration: true,
        isArchived: false
      }
    },
    fetchPolicy: "network-only"
  });
  const { data: dataUsers, loading: loadingUsers } = useQuery(USERS_GH, {
    variables: {
      where: {
        app_access: session.user ? session.appRef._id : "",
        archived: false
      }
    },
    fetchPolicy: "network-only"
  });
  const { data: dataSegments, loading: loadingSegments } = useQuery(SEGMENTS_FEED, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : "",
        archived: false
      }
    },
    fetchPolicy: "network-only"
  });
  const { data: dataTeams, loading: loadingTeams } = useQuery(TEAMS_FEED, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : "",
        archived: false
      }
    },
    fetchPolicy: "network-only"
  });
  const updateReport = useMutation(UPDATE_CHECKLIST_ANALISYS_REPORT_MUTATION);

  /* const checkIfVisitHasConfig = (visit) => {
    if (visit.checklistAnalisysReport.length) {
      return true;
    }else{
      return false;
    }
  }

  const _renderVisist = (visits) => {
    
    const originalVisits = JSON.parse(JSON.stringify(visits));
    const currentVisit = originalVisits.find(visit => visit._id.toString() === state.visit.visit.toString());

    const renderVisit = visits.reduce((result, visit) => {
      if (checkIfVisitHasConfig(visit)) {

        const configKeys = visit.checklistAnalisysReport.map(config => config.keyField);

        visit.keys = visit.keys.filter(key => !configKeys.includes(key));
        currentVisit.keys = currentVisit.keys.filter(key => key === state.visit.keyField);

        if (visit.keys.length) {
          result.push(visit);
        }
      }else{
        result.push(visit);
      }

      return result;
    }, []);

    if (!renderVisit.length) {
      renderVisit.push(currentVisit); 
    }

    return renderVisit;
  } 

  //Check report config previews
  if (!loadingVisit && dataVisit && dataVisit.visits && dataVisit.visits.length) {
    const renderVisits = _renderVisist(dataVisit.visits);
    dataVisit.visits = renderVisits;
  } */

  const _renderVisits = (visits) => {
    const renderVisits = visits.reduce((result, visit) => {
      
      if(visit.checkListConfiguration && visit.checkListConfiguration.columns.length){
        result.push(visit);
      }

      return result;
    }, []);

    return renderVisits;
  }

  if (!loadingVisit && dataVisit && dataVisit.visits && dataVisit.visits.length) {
    if (state.visit.type !== "CustomVisit") {
      dataVisit.visits = _renderVisits(dataVisit.visits);
    }
    
  }

  if (!loadingVisit && !loadingUsers && !loadingSegments && !loadingTeams) {
    return (
      <EditChecklistAnalisysVisitReportView
        app={session.user ? session.appRef._id : ""}
        visits={{ loadingVisit: loadingVisit, dataVisit: dataVisit }}
        users={{ loadingUsers: loadingUsers, dataUsers: dataUsers }}
        segments={{ loadingSegments: loadingSegments, dataSegments: dataSegments }}
        teams={{ loadingTeams: loadingTeams, dataTeams: dataTeams }}
        currentVisit={state.visit}
        disabled={state.disabled}
        submitSuccess={(values) => {

          let variables = {
            data: {
              ...values
            },
            where: {
              _id: state.visit._id
            }
          };

          const propMutation = updateReport({ variables: variables });
  
          if (!propMutation.loading) {
            if (!propMutation.error) {
              // Add success notification
              globalActions.notification.openNotification("tr", {
                message: t('checklistAnalisys.banner.update'),
                color: "success"
              });
              history.push(`/checklistAnalisysVisit`);
            }else{
              globalActions.notification.openNotification("tr", {
                message: t('checklistAnalisys.banner.fail'),
                color: "danger"
              });
            }
          }

        }}
      />
    );
  }else{
    return (
      <div style={style.progressCnt}>
        <CircularProgress style={style.progress} />
      </div>
    )
  }
  
};

const style = {
  progress: {
    margin: "16px",
  },
  progressCnt: {
    width: "100px !important",
    marginLeft: "50%",
    marginRight: "50%",
  },
};

export default UpdateChecklistAnalisysVisitReport;
