import React from 'react';
import PropTypes from 'prop-types';
import TagsInput from "react-tagsinput";
import {useTranslation} from "react-i18next";

// Core components
import CustomInput from "components/CustomInput/CustomInput.jsx";

// utils
import {validateEmail} from "../../utils/forms/inputs";
import {getNamespacesFromKeys} from "../../utils/translation/namespaces";

const EmailTagsInput = ({tags, tagsChanged, placeholder, label}) => {
  const {t,} = useTranslation(getNamespacesFromKeys([placeholder, label]));
  return (
    <TagsInput
      value={tags}
      onChange={(values) => {
        tagsChanged(values);
      }}
      onlyUnique={true}
      validate={(tag) => {
        return validateEmail(tag);
      }}
      tagProps={{className: "react-tagsinput-tag info"}}
      renderInput={({addTag, ...props}) => {
        const {onChange, value, ...other} = props;
        return (
          <CustomInput
            id="emails"
            type={"text"}
            formControlProps={{
              fullWidth: true
            }}
            labelText={t(label)}
            inputProps={{
              onChange: onChange,
              value: value,
              ...other
            }}
          />
        );
      }}
      inputProps={{
        placeholder: t(placeholder),
        style: {
          width: '100%'
        }
      }}
    />
  );
};

EmailTagsInput.propTypes = {
  tags: PropTypes.array.isRequired,
  tagsChanged: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default EmailTagsInput;
