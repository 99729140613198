import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

// core components
import Tooltip from '@material-ui/core/Tooltip';

// custom components
import Button from "components/CustomButtons/Button.jsx";

// icons
import EditIcon from "@material-ui/icons/Edit";
import LayersIcon from "@material-ui/icons/Layers";

const EngineAnswerFieldEditButton = ({changeToEditable, toggleLogChangesView}) => {
  const { t, } = useTranslation('question-engine-module-answer');

  return (
    <React.Fragment>
      <Tooltip title={t('question-engine-module-answer.view.initEdit')}>
        <Button block
                color={'info'}
                onClick={changeToEditable}>
          <EditIcon />
        </Button>
      </Tooltip>
      <Tooltip title={t('question-engine-module-answer.view.logChangesView')}>
        <Button block
                color={'primary'}
                onClick={toggleLogChangesView}>
          <LayersIcon />
        </Button>
      </Tooltip>
    </React.Fragment>
  );
};

EngineAnswerFieldEditButton.propTypes = {
  changeToEditable: PropTypes.func.isRequired,
  toggleLogChangesView: PropTypes.func.isRequired
};

export default EngineAnswerFieldEditButton;
