export function initializeKeyfields(keyfieldsCount){
  let newState = [];
  while(keyfieldsCount){
    newState.push(true);
    keyfieldsCount--;
  }
  return newState;
}

export function initializeCategories(categoryCount, hasGlobal){
  let newState = [];
  while(categoryCount){
    newState.push(false);
    categoryCount--;
  }
  if(!hasGlobal && newState.length > 0) newState[0] = true;
  return newState;
}