import React from "react";
import {useQuery} from "react-apollo-hooks";
import PropTypes from 'prop-types';

// Own components
import useGlobal from "../../../store";
import { USER_MOBILE_ITEMS, USER_SEGMENT_ITEMS } from "../gql/Queries";
import ActiveUsersView from "../../../Views/Visits/AdminData/ActiveUsersView";

function ActiveUsersItem({segments, visitAnswers, createActiveUSerReport, sendActiveUserPDFReport, 
  emailData, createActiveUserXLSReport, sendActiveUserXLSReport}){
  const [session] = useGlobal(
    state => state.session,
    actions => actions
  );
  const [segment, setSegment] = React.useState({value: "Todos", label: "Todos"});

  const handleSegmentChange = React.useCallback((option) => {
    setSegment(option);
  }, [setSegment]);

  const segmentOptions = [ 
    {value: "Todos", label: "Todos"},
    ...segments
      .map((item) => ({
        value: item._id,
        label: item.name
      }))
  ];
    
  const {
    data: dataAll, 
    loading: loadingAll,
  } = useQuery(USER_MOBILE_ITEMS, {
      variables: {
        where: {
          app: session.user ? session.appRef._id : '',
          archived: false
        }
      },
      skip: segment.value !== "Todos",
      fetchPolicy: 'network-only'
    });

  const {
    data: dataSegment, 
    loading: loadingSegment,
  } = useQuery(USER_SEGMENT_ITEMS, {
      variables: {
        where: {
          app: session.user ? session.appRef._id : '',
          segment: segment.value
        }
      },
      skip: segment.value === "Todos",
      fetchPolicy: 'network-only'
    });

  const allUsers = dataAll && dataAll.userMobiles ? 
    dataAll.userMobiles.map(user => ({
      _id: user._id,
      name: user.firstName + user.lastName
    })) 
  : [];

  const segmentUsers = dataSegment && dataSegment.userSegment ? 
    dataSegment.userSegment.map(user => ({
      _id: user.user._id,
      name: user.name
    })) 
  : [];
    
  return (
    <ActiveUsersView
      loading={segment.value === "Todos" ? loadingAll : loadingSegment}    
      handleSegmentChange={handleSegmentChange}
      segment={segment}
      segmentsOptions={segmentOptions}
      users={segment.value === "Todos" ? allUsers : segmentUsers}
      visitAnswers={visitAnswers}
      createActiveUSerReport={createActiveUSerReport}
      createActiveUserXLSReport={createActiveUserXLSReport}
      emailData={emailData}
      sendActiveUserPDFReport={sendActiveUserPDFReport}
      sendActiveUserXLSReport={sendActiveUserXLSReport}
    />
  );
}

ActiveUsersItem.propTypes = {
  segments: PropTypes.array.isRequired,
  visitAnswers: PropTypes.array.isRequired,
  createActiveUSerReport: PropTypes.func.isRequired,
  emailData: PropTypes.object.isRequired,
  sendActiveUserPDFReport: PropTypes.func.isRequired,
  createActiveUserXLSReport: PropTypes.func.isRequired,
  sendActiveUserXLSReport: PropTypes.func.isRequired
};

export default ActiveUsersItem;
