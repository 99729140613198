import React, { useState } from "react";
import { withStyles, useTheme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { useTranslation } from "react-i18next";
import SwipeableViews from "react-swipeable-views";
import PenaltiesWorkPlan from "../containers/ProcessRelationship/PenaltiesWorkPlan";
import PenaltiesRequisitions from "../containers/ProcessRelationship/PenaltiesRequisitions";

const PenaltiesModal = ({ open, setOpen, processRelationship }) => {
  const { t } = useTranslation("processRelationship");
  const theme = useTheme();
  const classes = withStyles(styles);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <DialogTitleComponent id="customized-dialog-title" onClose={handleClose}>
        {t("processRelationship.workPlan.penalties.title")}
      </DialogTitleComponent>
      <DialogContent dividers>
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label={t('processRelationship.workPlan.title')} {...a11yProps(0)} />
              <Tab label={t('processRelationship.requisitions.title')} {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
             <PenaltiesWorkPlan processRelationship={processRelationship} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <PenaltiesRequisitions processRelationship={processRelationship} />
            </TabPanel>

          </SwipeableViews>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
};

const DialogTitleComponent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle disableTypography {...other}>
      <GridContainer>
        <GridItem xs={10}>
          <Typography variant="h6">{children}</Typography>
        </GridItem>
        <GridItem xs={2} style={{ textAlign: "end" }}>
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </GridItem>
      </GridContainer>
    </DialogTitle>
  );
}

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export default PenaltiesModal;
