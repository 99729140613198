import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SettingsIcon from '@material-ui/icons/Settings';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import StepConnector from '@material-ui/core/StepConnector';
import Button from "components/Button";
import Typography from '@material-ui/core/Typography';
import { useTranslation } from "react-i18next";
import ChecklistAnalisysTypeStep from './Steps/ChecklistAnalisysTypeStep';
import ChecklistAnalisysFormStep from './Steps/ChecklistAnalisysFormStep';
import history from '../../utils/history';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient(60deg, #26c6da, #6ab3d4)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient(60deg, #26c6da, #6ab3d4)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient(60deg, #26c6da, #6ab3d4)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient(60deg, #26c6da, #6ab3d4)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <CheckBoxIcon />,
    2: <SettingsIcon />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  buttonNext: {
    marginRight: theme.spacing(1),
    float: 'right'
  },
  buttonLeft: {
    marginRight: theme.spacing(1),
    float: 'left'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));


const ChecklistAnalisysReportWizar = (props) => {
  const { configReport, setConfigReport, initDataForm, submitSuccess } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const { t } = useTranslation("checklistAnalisys");
  const steps = [t("checklistAnalisys.wizar.secction.type"), t("checklistAnalisys.wizar.secction.config")];;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <ChecklistAnalisysTypeStep
            configReport={configReport}
            setConfigReport={setConfigReport}
          />
        );
      case 1:
        return (
          <ChecklistAnalisysFormStep
            configReport={configReport}
            setConfigReport={setConfigReport}
            initDataForm={initDataForm}
            submitSuccess={submitSuccess}
            handleBack={handleBack}
          />
        );
    }
  }

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
            </Typography>
            <Button onClick={handleReset} className={classes.buttonNext} color="primary">
              Reset
            </Button>
          </div>
        ) : (
          <div>
            {getStepContent(activeStep)}
            { activeStep === steps.length - 1 ? <div></div> : (
              <div>
                <Button 
                  onClick={()=> { history.goBack(); }} 
                  className={classes.buttonLeft} 
                  color="primary">
                  {t("checklistAnalisys.form.buttons.cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  onClick={handleNext}
                  className={classes.buttonNext}
                >
                  {activeStep === steps.length - 1 ? t("checklistAnalisys.form.buttons.accept") : t("checklistAnalisys.form.buttons.next")}
                </Button>
              </div>
            ) }
            
          </div>
        )}
      </div>
    </div>
  );
}

ChecklistAnalisysReportWizar.propTypes = {
  configReport: PropTypes.object.isRequired,
  setConfigReport: PropTypes.func.isRequired,
  initDataForm: PropTypes.object.isRequired,
  submitSuccess: PropTypes.func.isRequired,
}

export default ChecklistAnalisysReportWizar;