const checklistDashboardUsers = [
  {
    id : "59e52a4a23febd1300d2519c",
    email : "abdias@beepquest.com"
  },
  {
    id : "59c92061623de112002cf29f",
    email : "berny@beepquest.com"
  },
  {
    id : "5bb21efaff948f130040db09",
    email : "contacto@beepquest.com"
  },
  {
    id : "5a57e3551d408e1500a43315",
    email : "ivan@beepquest.com"
  },
  {
    id : "59e61e39623de112002cf2d0",
    email : "liz@beepquest.com"
  }
];

const DOGFOODING_USERS = {
  "visitsDashboard": checklistDashboardUsers
}

export const DOGFOODING_PRODUCTS = {
  ChecklistDashboard: "visitsDashboard"
};

export function hasPermissions(product, userID){
  const validUsers = DOGFOODING_USERS[product];
  if(!validUsers) return false; 
  return !!validUsers.find(validUser => validUser.id === userID);
}