import React, {Fragment, useMemo, useState, useEffect} from 'react';
import {includes, pipe, pluck, uniq, flatten, take} from 'ramda';
import {useQuery, useLazyQuery, useSubscription} from "@apollo/react-hooks";
import Moment from "moment";
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import TagsInput from "react-tagsinput";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
// own components
import UsersLocationsMap from "../../../maps/UsersLocationsMap";
import LocationsMapInputForm from '../../../forms/locations/LocationsMapInputForm';
import LoadingCircleView from "../../../Views/LoadingCircleView";

// custom components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Accordion from "components/Accordion/Accordion.jsx";
import SmallPagination from "components/Pagination/SmallPagination.jsx";
import Button from "components/CustomButtons/Button.js";

// core components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import MuiMenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';


// icons
import PersonPin from '@material-ui/icons/PersonPin';
import ExploreOff from '@material-ui/icons/ExploreOff';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SettingsIcon from '@material-ui/icons/Settings';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';

// utils
import {endOfDay, startOfDay} from "../../../utils/dates/dates";

import {APP_SEGMENTS_ALL} from "../../Tickets/Queries";
import {
  MOBILE_LOCATIONS_SEGMENT_INACTIVE,
  SEGMENT_USER_MOBILE,
  MOBILE_LOCATIONS,
  GET_PLAN_FEATURES_APP
} from "../gql/Queries";
import {
  MOBILE_LOCATION_CREATED_SUBSCRIPTION
} from "../gql/Subscriptions";

import useGlobal from "../../../store";
import auth from '../../../utils/auth';
import history from '../../../utils/history';


function getActiveUsers(usersMobile, userLocations, active = false) {
  const getActiveUserIds = pipe(pluck('userId'), uniq);
  const activeUserIds = getActiveUserIds(userLocations);
  return usersMobile.filter((user) => {
    return active ?
      includes(user._id, activeUserIds) :
      !includes(user._id, activeUserIds);
  });
}

function filterInactiveUsers(currentInactiveUsers, filter = '') {
  const usersInactive = currentInactiveUsers ? (
    !!filter ? currentInactiveUsers
      .mobileLocationsSegmentInactiveUsers.filter((user) => {
        return filter ? (
          user.firstName.toLowerCase().includes(filter.toLowerCase()) ||
          user.lastName.toLowerCase().includes(filter.toLowerCase()) ||
          user.email.toLowerCase().includes(filter.toLowerCase())
        ) : true;
      }) : currentInactiveUsers.mobileLocationsSegmentInactiveUsers
  ) : [];
  return usersInactive;
}

function mergeMobileLocations(usersMobile, mobileLocations, mobileLocationsNotified) {
  return usersMobile ? flatten(usersMobile.map(user => {
    if (mobileLocations) {
      const userMobileLocations = mobileLocations.filter(location => location.userId === user._id);
      const userMobileLocationsNotified = mobileLocationsNotified.filter(location => location.userId === user._id);
      return [...userMobileLocations, ...userMobileLocationsNotified];
    }
    return [];
  })) : []
}

const drawerWidth = 350;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: ({inActiveUsersDrawerOpen}) => inActiveUsersDrawerOpen ? 0 : -drawerWidth ,
  },
  center: {
    textAlign: 'center'
  },
  listItemSelected: {
    backgroundColor: '#bbdefb !important'
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: "#ccc",
        "&$checked": {
          // Controls checked color for the thumb
          color: "#f2ff00"
        }
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: "#fff",
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: "#fff"
        }
      }
    }
  }
}));


const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #000',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const LocationsMap = () => {

  

  const [settingsMap, setSettingsMap] = useState({
    // size: [window.localStorage.getItem('map_config_size_w') || 25, window.localStorage.getItem('map_config_size_h') || 50],
    size: window.localStorage.getItem('map_config_size') || 10,
    singleLocation: window.localStorage.getItem('map_config_singleLocation') == "false" ? false : true,
    colorPinEnd: window.localStorage.getItem('map_config_colorPinEnd') || "markers/map-marker-green1-s.png"
  })

 

  const [modalOpenClose, setmodalOpenClose] = useState("none")
  
  const [currrentViewMap, serCurrentViewMap] = useState({
    zoom: 6,
    center: [22, -102]
  });
  const [selectedColorValue, setSelectedValue] = useState('markers/map-marker-green1-s.png');

  


  const { t, } = useTranslation(['locations', 'common']);
  const [{session}, globalActions] = useGlobal();

  const [currentUsersSelected, setCurrentUsersSelected] = useState([]);
  const [currentSegment, setCurrentSegment] = useState('');
  const [currentReviewDate, setCurrentReviewDate] = useState(startOfDay());
  const [currentUserSearch, setCurrentUserSearch] = useState('');
  const [currentUserPagination, setCurrentUserPagination] = useState({
    pageSize: 10,
    skip: 0,
    page: 0,
    orderBy: 'lastName_DESC'
  });
  const [mobileLocationsNotified, setMobileLocationsNotified] = useState([]);
  const [inActiveUsersDrawerOpen, setInActiveUsersDrawerOpen] = useState(true);
  const [plan, setPlan] = useState(null);
  const handle = useFullScreenHandle();
  const [personName, setPersonName] = useState([]);

  let markerInfo = [
    {
      file: "markers/map-marker-green1-s.png",
      color: 'green'
    },
    {
      file: "markers/map-marker-purp-s.png",
      color: '#58439d'
    },
    {
      file: "markers/map-marker-blk1-s.png",
      color: '#000000'
    },
    {
      file: "markers/map-marker-blue1-s.png",
      color: '#71d0e3'
    },
    {
      file: "markers/map-marker-blue2-s.png",
      color: '#143a4a'
    },
    {
      file: "markers/map-marker-or1-s.png",
      color: '#ef9639'
    },
    {
      file: "markers/map-marker-or2-s.png",
      color: '#a5824e'
    },
    {
      file: "markers/map-marker-pnk1-s.png",
      color: '#f0b2c8'
    },
    {
      file: "markers/map-marker-red1-s.png",
      color: '#be2e52'
    },
    {
      file: "markers/map-marker-red2-s.png",
      color: '#d64d44'
    },
    {
      file: "markers/map-marker-yel1-s.png",
      color: '#f1b350'
    },
    
  ];

  const handleChangeColor = (event) => {  

    window.localStorage.setItem('map_config_colorPinEnd', event.target.value)  
    setSettingsMap({
      size: settingsMap.size,
      singleLocation: settingsMap.singleLocation,
      colorPinEnd: event.target.value
    })
  };

  const changeTypeViewPin = (event, value) => {       
    
    window.localStorage.setItem('map_config_singleLocation', value)    
    setSettingsMap({
      size: settingsMap.size,
      singleLocation: value,
      colorPinEnd: settingsMap.colorPinEnd
    })
  };


  const classes = useStyles({inActiveUsersDrawerOpen});
  const {
    data: dataSegments,
    loading: loadingSegments,
    error: errorSegments
  } = useQuery(APP_SEGMENTS_ALL, {
    variables: {
      "where": {
        "app": session.user ? session.appRef._id : '',
        "archived": false
      }
    },
    onCompleted: () => {
      setCurrentSegment(dataSegments.segments[0]._id);
    }
  });
  const [getInActiveUsersForSegment, {
    data: dataInactiveUsers,
    loading: loadingInactiveUsers,
    error: errorInactiveUsers,
    refetch: refetchInactiveUsers
  }] = useLazyQuery(MOBILE_LOCATIONS_SEGMENT_INACTIVE);
  const [getSegmentUserMobiles, {
    data: dataSegmentUserMobiles,
    loading: loadingSegmentUserMobiles,
    error: errorSegmentUserMobiles
  }] = useLazyQuery(SEGMENT_USER_MOBILE, {
    variables: {},
    onCompleted: function() {
      getMobileLocations({
        variables: {
          where: {
            app: session.user ? session.appRef._id : '',
            created_gte: startOfDay(currentReviewDate).toISOString(),
            created_lte: endOfDay(currentReviewDate).toISOString(),
            userId_in: dataSegmentUserMobiles.segmentUserMobiles.map(user => user._id)
          }
        },
        fetchPolicy: 'network-only'
      });
    }
  });
  const [getMobileLocations, {
    data: dataMobileLocations,
    loading: loadingMobileLocations,
    error: errorMobileLocations
  }] = useLazyQuery(MOBILE_LOCATIONS);
  const segmentUserIds = dataSegmentUserMobiles ?
    dataSegmentUserMobiles.segmentUserMobiles.map(user => user._id) : [];
  const inactiveUserIds = dataInactiveUsers ?
    dataInactiveUsers.mobileLocationsSegmentInactiveUsers.map(user => user._id) : [];

  const {
    data: dataMobileLocationCreatedSubscription,
    error: errorMobileLocationCreatedSubscription,
    loading: loadingMobileLocationCreatedSubscription
  } = useSubscription(MOBILE_LOCATION_CREATED_SUBSCRIPTION, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : '',
        userIds: segmentUserIds.concat(inactiveUserIds),
        createdStart: startOfDay(currentReviewDate).toISOString(),
        createdEnd: endOfDay(currentReviewDate).toISOString(),
      }
    },
    onSubscriptionData: ({client, subscriptionData}) => {
      const userActiveFound = dataSegmentUserMobiles.segmentUserMobiles ?
        dataSegmentUserMobiles.segmentUserMobiles.find((user) => {
          return user._id === subscriptionData.data.mobileLocationCreated.node.userId;
        }) : null;
      const userInActiveFound = dataInactiveUsers.mobileLocationsSegmentInactiveUsers ?
        dataInactiveUsers.mobileLocationsSegmentInactiveUsers.find((user) => {
          return user._id === subscriptionData.data.mobileLocationCreated.node.userId;
        }) : null;

      if (userActiveFound) {
        globalActions.notification.openNotification('tr', {
          message: t('locations.map.notifications.locationCreated', {userName: userActiveFound.email}),
          color: 'success'
        });
        setMobileLocationsNotified([...mobileLocationsNotified, subscriptionData.data.mobileLocationCreated.node]);
      }else{
        globalActions.notification.openNotification('tr', {
          message: t('locations.map.notifications.locationUserInactiveCreated', {userName: userInActiveFound.email}),
          color: 'warning'
        });
        refetchInactiveUsers();
      }
    }
  });

  const {
    data: dataPlan,
    loading: loadingPlan,
  } = useQuery(GET_PLAN_FEATURES_APP, {
    variables: {
      "where": {
        "_id": session.user ? session.appRef._id : '',
      }
    }
  });

  useEffect(() => {
    if (currentSegment) {
      setCurrentUsersSelected([]);
      getInActiveUsersForSegment({
        variables: {
          where: {
            app: session.user ? session.appRef._id : '',
            segment: currentSegment,
            created_gte: startOfDay(currentReviewDate).toISOString(),
            created_lte: endOfDay(currentReviewDate).toISOString()
          }
        },
        fetchPolicy: 'network-only'
      })
    }
  }, [currentSegment, currentReviewDate, currentUserSearch]);
  useEffect(() => {
    if (dataInactiveUsers && !loadingInactiveUsers) {
      setCurrentUsersSelected([]);
      getSegmentUserMobiles({
        variables: {
          "where": {
            app: session.user ? session.appRef._id : '',
            segment: currentSegment,
            userId_not_in:
              dataInactiveUsers.mobileLocationsSegmentInactiveUsers.map(user => user._id),
            firstName_contains: currentUserSearch,
            lastName_contains: currentUserSearch,
            email_contains: currentUserSearch,
          },
          "skip": currentUserPagination.pageSize * currentUserPagination.page,
          "first": currentUserPagination.pageSize,
          "orderBy": currentUserPagination.orderBy
        },
        fetchPolicy: 'network-only'
      })
    }
  }, [currentUserPagination, dataInactiveUsers, loadingInactiveUsers]);

  useEffect(() => {
    if (dataPlan) {
      setPlan(dataPlan?.getPlanFeaturesApp)
      setInActiveUsersDrawerOpen(dataPlan?.getPlanFeaturesApp?.features?.locations === 'off' ? false : true);
    }
  },[dataPlan]);

  function valuetext(value) {
    return `${value}`;
  }

  const changeSizePing = (event, newValue) => {  
    window.localStorage.setItem('map_config_size', newValue)    
    setSettingsMap({
      size: newValue,
      singleLocation: settingsMap.singleLocation,
      colorPinEnd: settingsMap.colorPinEnd
    })
  }
  

  const filteredInactiveUsers = useMemo(() =>
    filterInactiveUsers(dataInactiveUsers, currentUserSearch),
    [dataInactiveUsers, currentUserSearch]);
  const mergedMobileLocations = useMemo(() =>
      mergeMobileLocations(
        dataSegmentUserMobiles ? dataSegmentUserMobiles.segmentUserMobiles : [],
        dataMobileLocations ? dataMobileLocations.mobileLocations : [],
        mobileLocationsNotified
      ),
    [dataSegmentUserMobiles, dataMobileLocations, mobileLocationsNotified]);

  const activeUsers = dataSegmentUserMobiles ?
    dataSegmentUserMobiles.segmentUserMobiles : [];
  const activeUsersConnection = dataSegmentUserMobiles ?
    dataSegmentUserMobiles.segmentUserMobilesConnection : {};
  const activeUsersCount = activeUsersConnection.aggregate ? activeUsersConnection.aggregate.count : 0;
  const inActiveUsers = filteredInactiveUsers || [];
  const inActiveUsersCount = dataInactiveUsers ? dataInactiveUsers.mobileLocationsSegmentInactiveUsers.length : 0;

  useMemo(() => {
    if (
      session.user &&
      !auth.hasPermission("trackingView", session.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: t('common:common.hasNotPermission'),
        color: "danger"
      });
      history.push('dashboard');
    } 
  }, [session]);
  
  return (
    <div className={classes.root}>
           
      <main className={clsx(classes.content, {
        [classes.contentShift]: inActiveUsersDrawerOpen,
      })}>      
        <GridContainer>          
          <GridItem xs={12} md={8} lg={8}>
            {
              loadingSegments ?
                <LoadingCircleView /> :
                <LocationsMapInputForm
                  plan={plan}
                  initialValues={{
                    segment: currentSegment,
                    reviewDate: currentReviewDate
                  }}
                  segments={dataSegments.segments}
                  submitSuccess={(values) => {
                    console.log('values', values);
                    setCurrentSegment(values.segment);
                    setCurrentReviewDate(Moment(values.reviewDate));
                    setCurrentUserSearch(values.userSearch);
                    setCurrentUserPagination({
                      pageSize: 10,
                      skip: 0,
                      page: 0,
                      orderBy: 'lastName_DESC'
                    });
                  }}/>
            }
          </GridItem>
          <GridItem xs={12} md={4} lg={4}>
            <Card>
              <CardBody style={{
                minHeight: '106.625px'
              }}>
                <GridContainer>
                  <GridItem xs={12}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-around'
                    }}>
                      <div style={{
                        textAlign: 'center'
                      }}>                        
                        <h5><strong>{`${activeUsersCount}`}</strong></h5>
                        <h5><strong>{`${inActiveUsersCount}`}</strong></h5>
                      </div>
                      <div>
                        <h5>{t('locations.map.activeUsersCount')}</h5>
                        <h5>{t('locations.map.inActiveUsersCount')}</h5>
                      </div>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <Button
                          justIcon
                          round
                          simple
                          color="info"
                          disabled={plan?.features?.locations === 'off' ? true : false}
                          onClick={() => {
                            setInActiveUsersDrawerOpen(!inActiveUsersDrawerOpen);
                          }}
                        >
                          <VisibilityIcon />
                        </Button>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <FullScreen handle={handle}>
              <Card>
                <div style={{
                    "width":"100%",
                    "height":"100%",
                    "backgroundColor":"#ffffffe0",
                    "zIndex": 9999,
                    "display":modalOpenClose,
                    "position": "absolute",
                  }}>
                    <GridContainer>          
                      <GridItem xs={12} md={12} lg={12}>
                        
                          <center>
                            <h4 style={{"marginTop":"10px"}}>Configuración de Mapa</h4>
                          </center>
                          <CloseIcon 
                          onClick={() => setmodalOpenClose("none")}
                          style={{
                            "color":"red",
                            "position": "absolute",
                            "float":"right",
                            "right":"25px",
                            "top":"18px",  
                            "cursor":"pointer"                   
                          }} />
                          <hr />
                      </GridItem>
                      <GridItem xs={6} md={6} lg={6}>
                        <center>
                          Tamaño de Pin
                          <Box style={{ width: "70%", "marginBottom":"5px", "marginTop":"12px" }}>
                            <Slider
                              aria-label="Size Pin"
                              defaultValue={settingsMap.size}
                              getAriaValueText={valuetext}
                              onChange={changeSizePing}
                              valueLabelDisplay="auto"
                              step={10}
                              marks
                              min={10}
                              max={100}
                              style={{ color: "#6ab3d4"}}
                              className={classes.overrides}
                            />
                            
                          </Box>
                        </center>
                      </GridItem>
                      <GridItem xs={6} md={6} lg={6}>      
                        <center>
                          <p>Ver unicamente el Pin final</p>
                          <Box style={{ width: "70%", "marginBottom":"12px" }}>
                            <IOSSwitch checked={settingsMap.singleLocation ? true : false}  onChange={changeTypeViewPin} />
                          </Box>
                        </center>
                      </GridItem>
                      <GridItem xs={12} md={12} lg={12}> 
                        <center>
                          <p>Cambiar Color de Pin</p>
                          <GridContainer>                          
                              {markerInfo.map((e) => (
                                <GridItem xs={2} md={2} lg={2} style={{"marginBottom":"10px"}}>  
                                  <center>
                                    <Radio
                                      checked={settingsMap.colorPinEnd === e["file"]}
                                      style={{ color: "#6ab3d4"}}
                                      onChange={handleChangeColor}
                                      value={e["file"]}
                                      name="radio-change-color"
                                      inputProps={{ 'aria-label': e["file"] }}
                                    />
                                    <img src={e["file"]} />
                                  </center>                            
                                </GridItem>
                              ))}
                          </GridContainer>   
                        </center>                   
                      </GridItem>
                    </GridContainer>
                  </div>
                  <CardBody>
                    <FullscreenIcon 
                      onClick={handle.active ? handle.exit : handle.enter}
                      style={{
                        "color":"#6ab3d4",
                        "position": "absolute",
                        "float":"right",
                        "right":"55px",
                        "top":"18px",
                        "zIndex": 9998,
                        "cursor": "pointer"
                      }} />
                    <SettingsIcon 
                      onClick={() => setmodalOpenClose("block")}
                      style={{
                        "color":"#6ab3d4",
                        "position": "absolute",
                        "float":"right",
                        "right":"25px",
                        "top":"18px",
                        "zIndex": 9998,
                        "cursor": "pointer"
                      }} />


                    
                    
                    <UsersLocationsMap 
                    fullScreen={handle.active}
                    currrentViewMap={currrentViewMap}
                    serCurrentViewMap={serCurrentViewMap}
                    selectedColorValue={selectedColorValue}
                    markerInfo={markerInfo}
                    usersMobile={dataSegmentUserMobiles ?
                      dataSegmentUserMobiles.segmentUserMobiles : []}
                                      userLocations={mergedMobileLocations}
                                      usersSelected={currentUsersSelected}
                                      settingsMap={settingsMap}
                    />
                  </CardBody>
              </Card>
            </FullScreen>
          </GridItem>
        </GridContainer>
      </main>
      <Drawer className={classes.drawer}
              variant="persistent"
              anchor="right"
              open={inActiveUsersDrawerOpen}
              classes={{
                paper: classes.drawerPaper,
              }}>
        <div className={classes.drawerHeader}>
          <h4>{t('locations.map.segmentUsers', {userCount: inActiveUsersCount + activeUsersCount})}</h4>
          <IconButton onClick={() => {
            setInActiveUsersDrawerOpen(!inActiveUsersDrawerOpen);
          }}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        {
          currentUsersSelected.length ?
            <Fragment>
              <div className={classes.center}>
                <div className={classes.drawerHeader}>
                  <h5><strong>{t('locations.map.usersSelectedTitle')}</strong></h5>
                  <IconButton onClick={() => {
                    setCurrentUsersSelected([]);
                  }}>
                    <ClearAllIcon />
                  </IconButton>
                </div>
                <TagsInput
                  value={currentUsersSelected.map(userSelected => userSelected.email)}
                  onChange={(tags) => {
                    setCurrentUsersSelected(
                      tags.map(
                        tag => currentUsersSelected.find(userSelected => userSelected.email === tag)
                      ));
                  }}
                  tagProps={{className: "react-tagsinput-tag info"}}
                  inputProps={{
                    style: {
                      width: '100%',
                      display: 'none'
                    }
                  }}
                />
              </div>
              <Divider />
            </Fragment>
            : []
        }
        <div style={{width: '100%'}}>
          {
            !!activeUsers.length ?
              <Fragment>
                <div className={classes.center}>
                  <h5><strong>{t('locations.map.activeUsersTitle')}</strong></h5>
                </div>
                <SmallPagination
                  page={currentUserPagination.page}
                  pageSize={currentUserPagination.pageSize}
                  itemCount={activeUsersCount}
                  onPageChange={(page) => {
                    setCurrentUserPagination({
                      ...currentUserPagination,
                      page
                    })
                  }} />
                <List>
                  {activeUsers.map((user) => {
                    const userSelectedFound = currentUsersSelected.find(
                      userSelected => userSelected._id === user._id
                    );
                    return (
                      <ListItem classes={{selected: classes.listItemSelected}} key={user._id} onClick={() => {

                        if (!userSelectedFound) {
                          setCurrentUsersSelected([...currentUsersSelected, user]);
                        }
                      }} selected={!!userSelectedFound}>
                        <ListItemIcon>
                          <PersonPin />
                        </ListItemIcon>
                        <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={user.email} />
                      </ListItem>
                    );
                  })}
                </List>
              </Fragment> :
              <div className={classes.center}>
                <h4>{t('locations.map.noActiveUsers')}</h4>
              </div>
          }
        </div>
        <Divider />
        <div style={{width: '100%'}}>
          {
            !!inActiveUsers.length ?
              <Fragment>
                <div className={classes.center}>
                  <h5><strong>{t('locations.map.inActiveUsersTitle')}</strong></h5>
                </div>
                <List>
                  {inActiveUsers.map((user) => {
                    return (
                      <ListItem key={user._id}>
                        <ListItemIcon>
                          <ExploreOff color={'secondary'} />
                        </ListItemIcon>
                        <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={user.email} />
                      </ListItem>
                    );
                  })}
                </List>
              </Fragment> :
              <div className={classes.center}>
                <h4>{t('locations.map.noInActiveUsers')}</h4>
              </div>
          }
        </div>
      </Drawer>
    </div>
  );
};

LocationsMap.propTypes = {

};

export default LocationsMap;
