import React,{ useMemo, useState } from 'react';
import ReportsView from '../../Views/Reports/ReportsView';
// Local state
import useGlobal from '../../store';
import { ALL_REPORTS_FEED } from '../Dashboard/gql/Query';
import {useQuery } from "react-apollo-hooks";
import auth from '../../utils/auth';
import history from '../../utils/history';
import { useTranslation } from 'react-i18next';
import moment from "moment";

const Reports = () => {
    const { t } = useTranslation("common");
    const [ session , globalActions] = useGlobal(
      state => state.session,
      actions => actions,
    );
    const [reportsFilter, setReportsFilter] = useState({
        where: {
          app: session.user ? session.appRef._id : "",
          type_in: [],
          action_in: [],
          status_in: [],
          dateIn: moment().startOf('day').subtract(30, 'days'),
          dateEnd: moment().endOf('day'),
        },
        first: 15,
        skip: 0,
        orderBy: "created_ASC"

      });

      const paginationAction  = async (values) => {
        setReportsFilter(values);
      }

      const {
        data: dataAllReports,
        loading: loadingAllReports,
      } = useQuery(ALL_REPORTS_FEED, {
        variables: reportsFilter,
        fetchPolicy: "network-only"
      });

    useMemo(() => {
      if (
        session.user &&
        !auth.hasPermission("allReportView", session.permissions)
      ) {
        globalActions.notification.openNotification("tr", {
          message: t('common.hasNotPermission'),
          color: "danger"
        });
        history.push("dashboard");
      }
    }, [session]);

    return (
        <ReportsView
            infoReports={{ loading: loadingAllReports, reports: dataAllReports }}
            paginationAction={paginationAction}
            reportsFilter={reportsFilter}
            submitSuccess={(values) => {
              setReportsFilter({...reportsFilter, 
              where: {
                ...reportsFilter.where,
                ...values
              }
              })
            }}
        />
    )
};

export default Reports;
