import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import Select from 'react-select';
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import CardHeaderSimple from "components/Header/CardHeaderSimple"
import { useTranslation } from "react-i18next";
function OperationSelect({classes, operations, selectedOperation, setOperation}){
  const selectOptions = operations.map( operation => ({
    value: operation.id,
    label: operation.name
  }));

  const { t } = useTranslation("general_adaptation");
  
  return (
    <Card className={classes.cardLessBorder}>
      {/* <CardHeader color="info" text>
        <GridContainer>
          <GridItem>
            <CardText color="info">
              <h4>Selecciona la operación</h4>
            </CardText>
          </GridItem>
        </GridContainer>
      </CardHeader> */}
      
      <CardHeaderSimple title={t("select_the_operation")} />   
      <CardBody className={classes.cardSelector}>
        <div className={classes.selectors}>
          <Select
            className={classes.selector}
            placeholder={t("select_operation")}
            value={selectedOperation}
            onChange={setOperation}
            options={selectOptions}
          />
        </div>
      </CardBody>
    </Card>
  );
  
}

OperationSelect.propTypes = {
  operations: PropTypes.array,
  selectedOperation: PropTypes.object,
  setOperation: PropTypes.func
};

const styles = theme => ({
  cardSelector: {
    zIndex: 10
  },
  cardLessBorder: {
    marginBottom: "0px"
  },
  selector: {
    width: "700px"
  },
  selectors:{
    display: "flex"
  }
});

export default withStyles(styles)(OperationSelect);
