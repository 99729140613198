module.exports = {
  readUTF8String: (bytes) => {
    var ix = 0;

    if (bytes.slice(0, 3) == "\xEF\xBB\xBF") {
      ix = 3;
    }

    var string = "";
    for (; ix < bytes.length; ix++) {
      var byte1 = bytes[ix].charCodeAt(0);
      if (byte1 < 0x80) {
        string += String.fromCharCode(byte1);
      } else if (byte1 >= 0xc2 && byte1 < 0xe0) {
        var byte2 = bytes[++ix].charCodeAt(0);
        string += String.fromCharCode(((byte1 & 0x1f) << 6) + (byte2 & 0x3f));
      } else if (byte1 >= 0xe0 && byte1 < 0xf0) {
        var byte2 = bytes[++ix].charCodeAt(0);
        var byte3 = bytes[++ix].charCodeAt(0);
        string += String.fromCharCode(
          ((byte1 & 0xff) << 12) + ((byte2 & 0x3f) << 6) + (byte3 & 0x3f)
        );
      } else if (byte1 >= 0xf0 && byte1 < 0xf5) {
        var byte2 = bytes[++ix].charCodeAt(0);
        var byte3 = bytes[++ix].charCodeAt(0);
        var byte4 = bytes[++ix].charCodeAt(0);
        var codepoint =
          ((byte1 & 0x07) << 18) +
          ((byte2 & 0x3f) << 12) +
          ((byte3 & 0x3f) << 6) +
          (byte4 & 0x3f);
        codepoint -= 0x10000;
        string += String.fromCharCode(
          (codepoint >> 10) + 0xd800,
          (codepoint & 0x3ff) + 0xdc00
        );
      }
    }

    return string;
  },
  downloadCsv: (rows, fileName) => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      rows.map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  }
};
