const auth = {
    hasPermission: (permission, currentPermissions) => {
      const findPermission = currentPermissions.find(rolPermission => rolPermission === permission);
      return findPermission ? true : false;
    },
    getUserSessionData: (session) => {
      const { user, appId } = session;
  
      return {
        user: {
          _id: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
          fullName: `${user.firstName} ${user.lastName}`,
          email: user.email
        },
        appId
      }
    }
    
  };
  
  export default auth;