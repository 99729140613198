import React from 'react';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import { CircularProgress } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";

const LoadingProcessView = ({
  closedCount,
  classes,
}) => {
  return (
    <GridContainer justify="center">
      <GridItem className={classes.container}>
        <Card>
          <CardBody>
            <CircularProgress className={classes.progress} />
            <div className={classes.textCenter}>Procesando ... {closedCount ? closedCount + ' supervisiones' : ''}</div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const styles = theme => ({
    container: {
      marginTop: '20%',
      marginBottom: '50%'
    },
    progress:{
      marginLeft: '40%',
      marginRight: '50%'
    },
    textCenter:{
      textAlign: 'center'
    }
  });
export default withStyles(styles)(LoadingProcessView)
