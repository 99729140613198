import { gql } from 'apollo-boost';

export const CREATE_CHECKLIST_ANALISYS_REPORT_MUTATION = gql`
  mutation createChecklistAnalisysReport($data: ChecklistAnalisysVisitReportInput! $programableReportData: ProgramableReportWhereInput) {
    createChecklistAnalisysReport(data: $data, programableReportData: $programableReportData) {
      app
      name
      visit
      keyField
      created
    }
  }
`;

export const UPDATE_CHECKLIST_ANALISYS_REPORT_MUTATION = gql`
  mutation updateChecklistAnalisysReport($data: ChecklistAnalisysVisitReportInput! $where: ChecklistAnalisysVisitReportWhereInput!) {
    updateChecklistAnalisysReport(data: $data, where: $where) {
      app
      name
      visit
      keyField
      created
    }
  }
`;

export const CREATE_CUSTOM_REPORT_MUTATION = gql`
  mutation createCustomReport(
    $data: ChecklistRepordInput 
    $where: ChecklistAnalisysVisitReportWhereInput) {
    createCustomReport(
      data: $data 
      where: $where)
  }
`;

export const CREATE_CHRONOLOGICAL_REPORT_MUTATION = gql`
  mutation createCustomReport($data: ChecklistRepordInput) {
    createChronologicalReport(data: $data)
  }
`;

export const MANUAL_CHECKLIST_SUMMARY_VISIT = gql`
  mutation manualChecklistSummaryVisit($data: ChecklistRepordInput) {
    manualChecklistSummaryVisit(data: $data)
  }
`;

export const GENERATE_WORKPLAN_XLS = gql`
  mutation GenerateWorkplanXLS($data: WorkPlanActivityWhereInput) {
    generateWorkplanXLS(data: $data)
  }
`;