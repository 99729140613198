import React from "react";
import PropTypes from "prop-types";
import Chart from "chart.js";

import withStyles from "@material-ui/core/styles/withStyles";

let chart = null;

function RadarChart({ data }) {
  const chartRef = React.useRef();

  React.useEffect(() => {
    const canvasRef = chartRef.current.getContext("2d");
    if (chart) chart.destroy();
    chart = new Chart(canvasRef, {
      type: "radar",
      data: data,
      options: {
        scale: {
          ticks: {
            beginAtZero: true,
            max: 100,
          },
        },
        responsive: true,
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return (
                data.datasets[tooltipItem.datasetIndex].label +
                " : " +
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              );
            },
          },
        },
      },
    });
  }, [data]);

  return <canvas id="myChart" ref={chartRef} />;
}

const styles = (theme) => ({});

RadarChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(RadarChart);
