import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form, withFormik } from 'formik';
import * as yup from "yup";

// own components
import EngineAnswerFieldFooter from "../../Views/QuestionEngineAnswers/EngineAnswerFieldFooter";
import CancelSubmitEditForm from "./CancelSubmitEditForm";
import FieldChecklistOptions from "../fields/FieldChecklistOptions";
import WithConfirmation from "../WithConfirmation";

// custom components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from "components/Card/CardFooter.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornmentError from "../../components/styled/InputAdornmentError";

// utils
import {hasError} from "../../utils/forms/inputs";
import i18n from "../../i18n";

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  comment: yup.string().nullable()
});

const ChecklistFieldAnswerEditForm = ({...props}) => {
  const { t } = useTranslation('tickets');

  // Formik bag
  const { values, errors, touched, dirty, submitForm } = props;

  // basic answer props
  const {answerData, checklist} = props;
  return (
    <Form>
      <Card >
        <CardBody>
          <GridContainer>
            <GridItem xs={10}>
              <FieldChecklistOptions name={'id'} options={checklist.options} multi={true} />
            </GridItem>
            <GridItem xs={2}>
              <CancelSubmitEditForm
                cancelEdit={() => props.cancelEdit(answerData)}
                submitForm={submitForm}
                enableSubmit={dirty}
              />
            </GridItem>
            {
              values.comment ?
                <GridItem xs={10}>
                  <Field name="comment" render={({field}) => {
                    return (
                      <CustomInput
                        id="value"
                        type={"text"}
                        formControlProps={{
                          fullWidth: true
                        }}
                        success={!hasError(errors, 'value') && touched.value}
                        error={hasError(errors, 'value')}
                        inputProps={{
                          autoComplete: "true",
                          endAdornment: (
                            hasError(errors, 'value') ?
                              <InputAdornmentError/> : []
                          ),
                          ...field
                        }}
                        centered
                      />
                    );
                  }}/>
                </GridItem>
              : []
            }
          </GridContainer>
        </CardBody>
        <CardFooter>
          <EngineAnswerFieldFooter answerData={answerData} />
        </CardFooter>
      </Card>
    </Form>
  );
};

ChecklistFieldAnswerEditForm.propTypes = {
  answerData: PropTypes.object.isRequired,
  submitSuccess: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  checklist: PropTypes.object.isRequired,
};

const ChecklistFieldAnswerEditFormWF = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: true,
  mapPropsToValues: ({answerData}) => {
    return {
      id: answerData.answer.ChecklistFieldAnswer,
      comment: answerData.answer.comment
    };
  },
  handleSubmit: (values, { props }) => {
    const checklistOptionSelected = props.checklist.options.find(option => option._id === values.id);

    props.goToConfirmation({
      title: i18n.t('question-engine-module-answer.messages.confirmEditFieldTitle', {
        ns: 'question-engine-module-answer'
      }),
      message: checklistOptionSelected ? checklistOptionSelected.name : '',
      callback: () => {
        props.submitSuccess(values);
      }
    })
  }
})(ChecklistFieldAnswerEditForm);

export default WithConfirmation(ChecklistFieldAnswerEditFormWF);
