import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form, withFormik } from 'formik';
import * as yup from 'yup';
import Datetime from "react-datetime";
import styled from 'styled-components';

// core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import Search from '@material-ui/icons/Search';

// custom components
import ButtonIcon from '../../components/styled/ButtonIcon';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

//Utils
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import {TICKET_REPORT_STATUS} from "../../constants";
import {Chip, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const DivMarginBottom = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

/*
 * Validation Schema for TicketReportMaintenanceFilterForm
 * */
const validationSchema = yup.object().shape({
  status: yup.array().of(yup.string()),
  startDateCreated: yup.date().required(),
  endDateCreated: yup.date().required(),
  usersMobileReportedBy: yup.array().of(yup.string()),
  segmentReportedBy: yup.string()
});

const TicketReportMaintenanceFilterForm = ({classes, ...props}) => {
  // Main hooks
  const { t } = useTranslation('tickets');
  const { t:tt } = useTranslation("general_adaptation");

  // Formik bag
  const { setFieldValue, values, submitForm, ticketConfig } = props;

  return (
    <Form>
      <GridContainer>
        <GridItem xs={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel className={classes.selectLabel}>
              {t("tickets.forms.maintenanceFilter.status")}
            </InputLabel>
            <Field
              name="status"
              render={({ field }) => {
                return (
                  <Select
                    multiple
                    value={field.value}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    inputProps={{ ...field }}
                  >
                    {Object.keys(TICKET_REPORT_STATUS).map((status) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={TICKET_REPORT_STATUS[status]}
                        key={TICKET_REPORT_STATUS[status]}
                      >
                        {t(
                          `tickets.model.status.${TICKET_REPORT_STATUS[status]}`
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={3}>
          <InputLabel className={classes.label}>
            {t("tickets.forms.maintenanceFilter.startDateCreated")}
          </InputLabel>
          <Datetime
            name={"startDateCreated"}
            timeFormat={false}
            defaultValue={values.startDateCreated}
            onChange={(e) => {
              if (e === "") {
                setFieldValue("startDateCreated", e);
              } else {
                setFieldValue("startDateCreated", e._d);
              }
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <InputLabel className={classes.label}>
            {t("tickets.forms.maintenanceFilter.endDateCreated")}
          </InputLabel>
          <Datetime
            name={"endDateCreated"}
            timeFormat={false}
            defaultValue={values.endDateCreated}
            onChange={(e) => {
              if (e === "") {
                setFieldValue("endDateCreated", e);
              } else {
                setFieldValue("endDateCreated", e._d);
              }
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <ButtonIcon
            icon={Search}
            label={tt("search")}
            color={"primary"}
            block={true}
            onClick={() => {
              submitForm();
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <Autocomplete
            multiple={true}
            filterSelectedOptions
            options={props.userMobiles || []}
            getOptionLabel={(userMobile) => userMobile.email}
            value={values.userMobile}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return (
                  <Chip label={option.email} {...getTagProps({ index })} />
                );
              })
            }
            onChange={(event, value) => {
              setFieldValue(`usersMobileReportedBy`, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={"Reportado por Usuario(s)"}
                fullWidth
                margin="normal"
              />
            )}
          />
        </GridItem>
        <GridItem xs={3}>
          <Autocomplete
            multiple={false}
            filterSelectedOptions
            options={props.segments || []}
            getOptionLabel={(segment) => segment.name}
            value={values.segmentReportedBy}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return <Chip label={option.name} {...getTagProps({ index })} />;
              })
            }
            onChange={(event, value) => {
              setFieldValue(`segmentReportedBy`, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={"Reportado por Segmento"}
                fullWidth
                margin="normal"
              />
            )}
          />
        </GridItem>
        {ticketConfig.hasFolio ? (
          <GridItem xs={3}>
             <Field
                name="folio"
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      variant="standard"
                      label={"Folio"}
                      fullWidth
                      margin="normal"
                    />
                  );
                }}
              />
          </GridItem>
        ) : (
          []
        )}
      </GridContainer>
    </Form>
  );
};

TicketReportMaintenanceFilterForm.propTypes = {
  submitSuccess: PropTypes.func.isRequired
};

const TicketReportMaintenanceFilterFormWF = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => {
    return {
      status: initialValues.status,
      startDateCreated: initialValues.created_gte,
      endDateCreated: initialValues.created_lte,
      usersMobileReportedBy: initialValues.usersMobileReportedBy,
      segmentReportedBy: initialValues.segmentReportedBy,
    };
  },
  handleSubmit: (values, { props }) => {
    props.submitSuccess(values);
  }
})(TicketReportMaintenanceFilterForm);

export default withStyles(extendedFormsStyle)(TicketReportMaintenanceFilterFormWF);
