export const buildGalleryUserSubject = ({visit, startDate, endDate}) => {
  return `Reporte de Imágenes en ${visit} para ${startDate} - ${endDate}`;
}

export const buildGalleryUserPDFBody = ({visit, startDate, endDate, filterModules, filterCategories, filterKeyfield, filterUsers}) => {
  let template = `
  <p>Hola,</p>
  <br>
  <p>Te envio este mail con ligas a las imagenes de ${visit} para ${startDate} a ${endDate}.</p>
  `;

  if (filterKeyfield && filterKeyfield.items) {
    const key = filterKeyfield.key.value
    const items = filterKeyfield.items.map(item => item.value).join()
    template += `
    <br>
    <p>* Se está estudiando la ejecución de ${key} para ${items}.</p>
    <br>`
  }
  if (filterUsers) {
    const user = filterUsers.map(user => user.label).join()
    template += `
    <br>
    <p>* Se ha filtrado solamente las fotos capturadas por ${user}.</p>
    <br>`
  }
  if (filterModules) {
    const form = filterModules.map(form => form.label).join()
    template += `
    <br>
    <p>* Los formularios incluidos en este reporte son ${form}.</p>
    <br>`
  }
  if (filterCategories) {
    const criterion = filterCategories.criteria.value
    const operation = filterCategories.operation.label
    template += `
    <br>
    <p>* Para filtrar estas fotos se respeta la operación de ${criterion}, ${operation}.</p>
    <br>`
  }
  template += `
  <br>
  <p>Dicho esto, para tu análisis te hago la lista de las imágenes que se deben contemplar. (Pica en la miniatura para reflejar el link)</p>
  <br>
  `
  return template
}
