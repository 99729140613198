import gql from "graphql-tag";

export const USER_APPROVAL_ITEMS = gql`
  query userApprovalItems($where: ApprovalItemWhereInput!) {
    approvalItems(where: $where) {
      app
      userId
      status
      created
      visitAnswerId
      visitAnswerRef {
        _id
        app
        visitId
        visitRef {
          _id
          name
        }
      }
      created
    }
  }
`;

export const VISIT_APPROVAL_ITEMS_INFO = gql`
  query visitPendingApprovalItemsInfo($userId: ID!, $visitId: ID!) {
    approvalItems(
      where: { userId: $userId, visitId: $visitId, status: Pending }
    ) {
      _id
      app
      userId
      status
      visitId
      visitAnswerId
      visitAnswerRef {
        _id
        checkInDate
        checkOutDate
        userId
        userRef {
          _id
          email
          firstName
          lastName
        }
        checkIn {
          questionAnswersId
          questionAnswersRef {
            answers {
              key
              questionLabel
              answer {
                __typename
                ... on OpenFieldAnswer {
                  OpenFieldAnswer: value
                }
                ... on NumericFieldAnswer {
                  NumericFieldAnswer: value
                }
                ... on DateFieldAnswer {
                  DateFieldAnswer: value
                }
                ... on PhoneFieldAnswer {
                  PhoneFieldAnswer: value
                }
                ... on ListUniqueFieldAnswer {
                  ListUniqueFieldAnswer: value
                }
                ... on ListMultipleFieldAnswer {
                  ListMultipleFieldAnswer: value
                }
                ... on EmailFieldAnswer {
                  EmailFieldAnswer: value
                }
                ... on PasswordFieldAnswer {
                  PasswordFieldAnswer: value
                }
                ... on OpenMultilineFieldAnswer {
                  OpenMultilineFieldAnswer: value
                }
                ... on PhotoReportFieldAnswer {
                  PhotoReportFieldAnswer: value {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
                ... on MapFieldAnswer {
                  longitude
                  latitude
                  address
                }
                ... on SignatureFieldAnswer {
                  SignatureFieldAnswer: value {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
                ... on CodeBarFieldAnswer {
                  CodeBarFieldAnswer: value
                }
                ... on MassiveListUniqueFieldAnswer {
                  MassiveListUniqueFieldAnswer: value
                }
                ... on MassiveListMultipleFieldAnswer {
                  MassiveListMultipleFieldAnswer: value
                }
                ... on ChecklistFieldAnswer {
                  ChecklistFieldAnswer: id
                  comment
                  email
                  photos {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                  signature {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
                ... on TokenFieldAnswer {
                  TokenFieldAnswer: value
                }
                ... on TableFieldAnswer {
                  rows {
                    answers {
                      key
                      answer {
                        ... on OpenSubFieldAnswer {
                          OpenSubFieldAnswer: value
                        }
                        ... on NumericSubFieldAnswer {
                          NumericSubFieldAnswer: value
                        }
                        ... on DateSubFieldAnswer {
                          DateSubFieldAnswer: value
                        }
                        ... on PhoneSubFieldAnswer {
                          PhoneSubFieldAnswer: value
                        }
                        ... on MassiveListUniqueSubFieldAnswer {
                          MassiveListUniqueSubFieldAnswer: value
                        }
                      }
                    }
                  }
                  config {
                    _id
                    key
                    type
                    label
                    columns {
                      ... on OpenSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                      ... on NumericSubField {
                        _id
                        key
                        type
                        label
                        width
                        numericFormatStyle
                      }
                      ... on DateSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                      ... on PhoneSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                      ... on MassiveListUniqueSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                    }
                  }
                }
                ... on GeofenceFieldAnswer {
                  GeofenceFieldAnswer: value
                }
                ... on FileFieldAnswer {
                  FileFieldAnswer: files {
                    filename
                    file
                  }
                }
              }
            }
          }
        }
        activities {
          moduleId
        }
        calculatedChecklistColumns {
          categories {
            name
            operations {
              name
              operator
              value
            }
          }
          globals {
            name
            value
          }
        }
      }
      created
    }
    visit(where: { _id: $visitId }) {
      name
      engines {
        moduleId
        moduleRef {
          _id
          name
        }
        type
      }
      checkListConfiguration {
        _id
        needsApproval
        approvalConfig {
          hasExpiration
          approvalFlow {
            email
            userId
            order
            approvalPercent
          }
        }
        columns {
          category
          operation {
            name
            operator
          }
          ponderation
        }
      }
      keys
      created
    }
  }
`;

export const VISIT_APPROVAL_ITEM_INFO = gql`
  query visitPendingApprovalItemsInfo(
    $approvalItem: ID!
    $visitId: ID!
    $visitAnswerId: ID!
  ) {
    visit(where: { _id: $visitId }) {
      name
      engines {
        moduleId
        type
        moduleRef {
          _id
          name
        }
      }
      checkListConfiguration {
        _id
        needsApproval
        approvalConfig {
          hasExpiration
          expirationHours
          expirationDefault
          approvalFlow {
            email
            userId
            order
            approvalPercent
          }
        }
        columns {
          category
          operation {
            name
            operator
          }
          ponderation
        }
      }
      keys
      created
    }
    approvalItem(where: { _id: $approvalItem }) {
      _id
      app
      userId
      userRef {
        _id
        firstName
        lastName
        email
      }
      status
      created
      visitId
      visitAnswerId
      visitAnswerRef {
        _id
        checkInDate
        checkOutDate
        userId
        userRef {
          _id
          email
          firstName
          lastName
        }
        keys
        checkIn {
          questionAnswersId
          questionAnswersRef {
            _id
            module {
              _id
              name
              propertiesRef {
                __typename
                ... on QuestionEngineModule {
                  _id
                  fields {
                    __typename
                    ... on OpenField {
                      _id
                      key
                    }
                    ... on NumericField {
                      _id
                      key
                    }
                    ... on DateField {
                      _id
                      key
                    }
                    ... on PhoneField {
                      _id
                      key
                    }
                    ... on EmailField {
                      _id
                      key
                    }
                    ... on PasswordField {
                      _id
                      key
                    }
                    ... on OpenMultilineField {
                      _id
                      key
                    }
                    ... on PhotoReportField {
                      _id
                      key
                    }
                    ... on MapField {
                      _id
                      key
                      completeAddress
                    }
                    ... on SignatureField {
                      _id
                      key
                    }
                    ... on CodeBarField {
                      _id
                      key
                    }
                    ... on ChecklistField {
                      _id
                      key
                    }
                    ... on TokenField {
                      _id
                      key
                    }
                    ... on TableField {
                      _id
                      key
                    }
                    ... on GeofenceField {
                      _id
                      key
                    }
                    ... on ListUniqueField {
                      _id
                      key
                      options {
                        name
                        value
                      }
                    }
                    ... on ListMultipleField {
                      _id
                      key
                      options {
                        name
                        value
                      }
                    }
                    ... on MassiveListUniqueField {
                      _id
                      key
                      massiveList
                    }
                    ... on MassiveListMultipleField {
                      _id
                      key
                      massiveList
                    }
                    ... on FileField {
                      _id
                      key
                    }
                  }
                }
              }
            }
            userId
            userRef {
              _id
              email
              firstName
              lastName
            }
            answers {
              key
              questionLabel
              answer {
                __typename
                ... on OpenFieldAnswer {
                  OpenFieldAnswer: value
                }
                ... on NumericFieldAnswer {
                  NumericFieldAnswer: value
                }
                ... on DateFieldAnswer {
                  DateFieldAnswer: value
                }
                ... on PhoneFieldAnswer {
                  PhoneFieldAnswer: value
                }
                ... on ListUniqueFieldAnswer {
                  ListUniqueFieldAnswer: value
                  config {
                    key
                    type
                    label
                    help
                    required
                    listStyle
                    options {
                      _id
                      value
                      name
                      segment
                      emails
                      image {
                        original
                        i1280
                        i240
                        i320
                        i480
                        i640
                        i960
                      }
                    }
                  }
                }
                ... on ListMultipleFieldAnswer {
                  ListMultipleFieldAnswer: value
                  config {
                    key
                    type
                    label
                    help
                    required
                    listStyle
                    options {
                      _id
                      value
                      name
                      segment
                      emails
                      image {
                        original
                        i1280
                        i240
                        i320
                        i480
                        i640
                        i960
                      }
                    }
                  }
                }
                ... on EmailFieldAnswer {
                  EmailFieldAnswer: value
                }
                ... on PasswordFieldAnswer {
                  PasswordFieldAnswer: value
                }
                ... on OpenMultilineFieldAnswer {
                  OpenMultilineFieldAnswer: value
                }
                ... on PhotoReportFieldAnswer {
                  PhotoReportFieldAnswer: value {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
                ... on MapFieldAnswer {
                  longitude
                  latitude
                  address
                }
                ... on SignatureFieldAnswer {
                  SignatureFieldAnswer: value {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
                ... on CodeBarFieldAnswer {
                  CodeBarFieldAnswer: value
                }
                ... on MassiveListUniqueFieldAnswer {
                  MassiveListUniqueFieldAnswer: value
                  config {
                    massiveList
                  }
                }
                ... on MassiveListMultipleFieldAnswer {
                  MassiveListMultipleFieldAnswer: value
                  config {
                    massiveList
                  }
                }
                ... on ChecklistFieldAnswer {
                  ChecklistFieldAnswer: id
                  comment
                  email
                  photos {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                  signature {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
                ... on TokenFieldAnswer {
                  TokenFieldAnswer: value
                }
                ... on TableFieldAnswer {
                  rows {
                    answers {
                      key
                      answer {
                        __typename
                        ... on OpenSubFieldAnswer {
                          OpenSubFieldAnswer: value
                        }
                        ... on NumericSubFieldAnswer {
                          NumericSubFieldAnswer: value
                        }
                        ... on DateSubFieldAnswer {
                          DateSubFieldAnswer: value
                        }
                        ... on PhoneSubFieldAnswer {
                          PhoneSubFieldAnswer: value
                        }
                        ... on MassiveListUniqueSubFieldAnswer {
                          MassiveListUniqueSubFieldAnswer: value
                        }
                      }
                    }
                  }
                  config {
                    _id
                    key
                    type
                    label
                    columns {
                      __typename
                      ... on OpenSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                      ... on NumericSubField {
                        _id
                        key
                        type
                        label
                        width
                        numericFormatStyle
                      }
                      ... on DateSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                      ... on PhoneSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                      ... on MassiveListUniqueSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                    }
                  }
                }
                ... on GeofenceFieldAnswer {
                  GeofenceFieldAnswer: value
                }
                ... on FileFieldAnswer {
                  FileFieldAnswer: files {
                    filename
                    file
                  }
                }
              }
            }
            checklists {
              _id
              app
              name
              options {
                _id
                name
                image {
                  i320
                  i480
                }
                numericValue
              }
            }
          }
        }
        checkOut {
          questionAnswersId
          questionAnswersRef {
            _id
            module {
              _id
              name
              propertiesRef {
                __typename
                ... on QuestionEngineModule {
                  _id
                  fields {
                    __typename
                    ... on OpenField {
                      _id
                      key
                    }
                    ... on NumericField {
                      _id
                      key
                    }
                    ... on DateField {
                      _id
                      key
                    }
                    ... on PhoneField {
                      _id
                      key
                    }
                    ... on EmailField {
                      _id
                      key
                    }
                    ... on PasswordField {
                      _id
                      key
                    }
                    ... on OpenMultilineField {
                      _id
                      key
                    }
                    ... on PhotoReportField {
                      _id
                      key
                    }
                    ... on MapField {
                      _id
                      key
                      completeAddress
                    }
                    ... on SignatureField {
                      _id
                      key
                    }
                    ... on CodeBarField {
                      _id
                      key
                    }
                    ... on ChecklistField {
                      _id
                      key
                    }
                    ... on TokenField {
                      _id
                      key
                    }
                    ... on TableField {
                      _id
                      key
                    }
                    ... on GeofenceField {
                      _id
                      key
                    }
                    ... on ListUniqueField {
                      _id
                      key
                      options {
                        name
                        value
                      }
                    }
                    ... on ListMultipleField {
                      _id
                      key
                      options {
                        name
                        value
                      }
                    }
                    ... on MassiveListUniqueField {
                      _id
                      key
                      massiveList
                    }
                    ... on MassiveListMultipleField {
                      _id
                      key
                      massiveList
                    }
                    ... on FileField {
                      _id
                      key
                    }
                  }
                }
              }
            }
            userId
            userRef {
              _id
              email
              firstName
              lastName
            }
            answers {
              key
              questionLabel
              answer {
                __typename
                ... on OpenFieldAnswer {
                  OpenFieldAnswer: value
                }
                ... on NumericFieldAnswer {
                  NumericFieldAnswer: value
                }
                ... on DateFieldAnswer {
                  DateFieldAnswer: value
                }
                ... on PhoneFieldAnswer {
                  PhoneFieldAnswer: value
                }
                ... on ListUniqueFieldAnswer {
                  ListUniqueFieldAnswer: value
                  config {
                    key
                    type
                    label
                    help
                    required
                    listStyle
                    options {
                      _id
                      value
                      name
                      segment
                      emails
                      image {
                        original
                        i1280
                        i240
                        i320
                        i480
                        i640
                        i960
                      }
                    }
                  }
                }
                ... on ListMultipleFieldAnswer {
                  ListMultipleFieldAnswer: value
                  config {
                    key
                    type
                    label
                    help
                    required
                    listStyle
                    options {
                      _id
                      value
                      name
                      segment
                      emails
                      image {
                        original
                        i1280
                        i240
                        i320
                        i480
                        i640
                        i960
                      }
                    }
                  }
                }
                ... on EmailFieldAnswer {
                  EmailFieldAnswer: value
                }
                ... on PasswordFieldAnswer {
                  PasswordFieldAnswer: value
                }
                ... on OpenMultilineFieldAnswer {
                  OpenMultilineFieldAnswer: value
                }
                ... on PhotoReportFieldAnswer {
                  PhotoReportFieldAnswer: value {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
                ... on MapFieldAnswer {
                  longitude
                  latitude
                  address
                }
                ... on SignatureFieldAnswer {
                  SignatureFieldAnswer: value {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
                ... on CodeBarFieldAnswer {
                  CodeBarFieldAnswer: value
                }
                ... on MassiveListUniqueFieldAnswer {
                  MassiveListUniqueFieldAnswer: value
                  config {
                    massiveList
                  }
                }
                ... on MassiveListMultipleFieldAnswer {
                  MassiveListMultipleFieldAnswer: value
                  config {
                    massiveList
                  }
                }
                ... on ChecklistFieldAnswer {
                  ChecklistFieldAnswer: id
                  comment
                  email
                  photos {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                  signature {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
                ... on TokenFieldAnswer {
                  TokenFieldAnswer: value
                }
                ... on TableFieldAnswer {
                  rows {
                    answers {
                      key
                      answer {
                        __typename
                        ... on OpenSubFieldAnswer {
                          OpenSubFieldAnswer: value
                        }
                        ... on NumericSubFieldAnswer {
                          NumericSubFieldAnswer: value
                        }
                        ... on DateSubFieldAnswer {
                          DateSubFieldAnswer: value
                        }
                        ... on PhoneSubFieldAnswer {
                          PhoneSubFieldAnswer: value
                        }
                        ... on MassiveListUniqueSubFieldAnswer {
                          MassiveListUniqueSubFieldAnswer: value
                        }
                      }
                    }
                  }
                  config {
                    _id
                    key
                    type
                    label
                    columns {
                      __typename
                      ... on OpenSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                      ... on NumericSubField {
                        _id
                        key
                        type
                        label
                        width
                        numericFormatStyle
                      }
                      ... on DateSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                      ... on PhoneSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                      ... on MassiveListUniqueSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                    }
                  }
                }
                ... on GeofenceFieldAnswer {
                  GeofenceFieldAnswer: value
                }
                ... on FileFieldAnswer {
                  FileFieldAnswer: files {
                    filename
                    file
                  }
                }
              }
            }
            checklists {
              _id
              app
              name
              options {
                _id
                name
                image {
                  i320
                  i480
                }
                numericValue
              }
            }
          }
        }
        activities {
          moduleId
          questionAnswersId
          questionAnswersRef {
            _id
            module {
              _id
              name
              propertiesRef {
                __typename
                ... on QuestionEngineModule {
                  _id
                  fields {
                    __typename
                    ... on OpenField {
                      _id
                      key
                    }
                    ... on NumericField {
                      _id
                      key
                    }
                    ... on DateField {
                      _id
                      key
                    }
                    ... on PhoneField {
                      _id
                      key
                    }
                    ... on EmailField {
                      _id
                      key
                    }
                    ... on PasswordField {
                      _id
                      key
                    }
                    ... on OpenMultilineField {
                      _id
                      key
                    }
                    ... on PhotoReportField {
                      _id
                      key
                    }
                    ... on MapField {
                      _id
                      key
                      completeAddress
                    }
                    ... on SignatureField {
                      _id
                      key
                    }
                    ... on CodeBarField {
                      _id
                      key
                    }
                    ... on ChecklistField {
                      _id
                      key
                    }
                    ... on TokenField {
                      _id
                      key
                    }
                    ... on TableField {
                      _id
                      key
                    }
                    ... on GeofenceField {
                      _id
                      key
                    }
                    ... on ListUniqueField {
                      _id
                      key
                      options {
                        name
                        value
                      }
                    }
                    ... on ListMultipleField {
                      _id
                      key
                      options {
                        name
                        value
                      }
                    }
                    ... on MassiveListUniqueField {
                      _id
                      key
                      massiveList
                    }
                    ... on MassiveListMultipleField {
                      _id
                      key
                      massiveList
                    }
                    ... on FileField {
                      _id
                      key
                    }
                  }
                }
              }
            }
            userId
            userRef {
              _id
              email
              firstName
              lastName
            }
            answers {
              key
              questionLabel
              answer {
                __typename
                ... on OpenFieldAnswer {
                  OpenFieldAnswer: value
                }
                ... on NumericFieldAnswer {
                  NumericFieldAnswer: value
                }
                ... on DateFieldAnswer {
                  DateFieldAnswer: value
                }
                ... on PhoneFieldAnswer {
                  PhoneFieldAnswer: value
                }
                ... on ListUniqueFieldAnswer {
                  ListUniqueFieldAnswer: value
                  config {
                    key
                    type
                    label
                    help
                    required
                    listStyle
                    options {
                      _id
                      value
                      name
                      segment
                      emails
                      image {
                        original
                        i1280
                        i240
                        i320
                        i480
                        i640
                        i960
                      }
                    }
                  }
                }
                ... on ListMultipleFieldAnswer {
                  ListMultipleFieldAnswer: value
                  config {
                    key
                    type
                    label
                    help
                    required
                    listStyle
                    options {
                      _id
                      value
                      name
                      segment
                      emails
                      image {
                        original
                        i1280
                        i240
                        i320
                        i480
                        i640
                        i960
                      }
                    }
                  }
                }
                ... on EmailFieldAnswer {
                  EmailFieldAnswer: value
                }
                ... on PasswordFieldAnswer {
                  PasswordFieldAnswer: value
                }
                ... on OpenMultilineFieldAnswer {
                  OpenMultilineFieldAnswer: value
                }
                ... on PhotoReportFieldAnswer {
                  PhotoReportFieldAnswer: value {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
                ... on MapFieldAnswer {
                  longitude
                  latitude
                  address
                }
                ... on SignatureFieldAnswer {
                  SignatureFieldAnswer: value {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
                ... on CodeBarFieldAnswer {
                  CodeBarFieldAnswer: value
                }
                ... on MassiveListUniqueFieldAnswer {
                  MassiveListUniqueFieldAnswer: value
                  config {
                    massiveList
                  }
                }
                ... on MassiveListMultipleFieldAnswer {
                  MassiveListMultipleFieldAnswer: value
                  config {
                    massiveList
                  }
                }
                ... on ChecklistFieldAnswer {
                  ChecklistFieldAnswer: id
                  comment
                  email
                  photos {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                  signature {
                    original
                    i1280
                    i240
                    i320
                    i480
                    i640
                    i960
                  }
                }
                ... on TokenFieldAnswer {
                  TokenFieldAnswer: value
                }
                ... on TableFieldAnswer {
                  rows {
                    answers {
                      key
                      answer {
                        __typename
                        ... on OpenSubFieldAnswer {
                          OpenSubFieldAnswer: value
                        }
                        ... on NumericSubFieldAnswer {
                          NumericSubFieldAnswer: value
                        }
                        ... on DateSubFieldAnswer {
                          DateSubFieldAnswer: value
                        }
                        ... on PhoneSubFieldAnswer {
                          PhoneSubFieldAnswer: value
                        }
                        ... on MassiveListUniqueSubFieldAnswer {
                          MassiveListUniqueSubFieldAnswer: value
                        }
                      }
                    }
                  }
                  config {
                    _id
                    key
                    type
                    label
                    columns {
                      __typename
                      ... on OpenSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                      ... on NumericSubField {
                        _id
                        key
                        type
                        label
                        width
                        numericFormatStyle
                      }
                      ... on DateSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                      ... on PhoneSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                      ... on MassiveListUniqueSubField {
                        _id
                        key
                        type
                        label
                        width
                      }
                    }
                  }
                }
                ... on GeofenceFieldAnswer {
                  GeofenceFieldAnswer: value
                }
                ... on FileFieldAnswer {
                  FileFieldAnswer: files {
                    filename
                    file
                  }
                }
              }
            }
            checklists {
              _id
              app
              name
              options {
                _id
                name
                image {
                  i320
                  i480
                }
                numericValue
              }
            }
          }
        }
        calculatedChecklistColumns {
          categories {
            name
            operations {
              name
              operator
              value
            }
          }
          globals {
            name
            value
          }
        }
      }
    }
    approvalItems(where: { visitAnswerId: $visitAnswerId }) {
      _id
      app
      visitAnswerId
      userId
      userRef {
        _id
        firstName
        lastName
        email
      }
      approvalLogId
      approvalLogRef {
        _id
        app
        userId
        userRef {
          _id
          firstName
          lastName
          email
        }
        status
        type
        created
      }
      step
      status
      created
    }
  }
`;

export const VISIT_ITEMS = gql`
  query visitItems($where: VisitWhereInput!) {
    visits(where: $where) {
      _id
      name
      app
      keys
      checkListConfiguration {
        _id
        needsApproval
        columns {
          category
          operation {
            name
            operator
          }
        }
      }
      engines {
        visitId
        moduleId
        type
        moduleRef {
          _id
          name
          type
          propertiesRef {
            __typename
            ... on QuestionEngineModule {
              _id
              fields {
                __typename
                ... on OpenField {
                  _id
                  key
                }
                ... on NumericField {
                  _id
                  key
                }
                ... on DateField {
                  _id
                  key
                }
                ... on PhoneField {
                  _id
                  key
                }
                ... on EmailField {
                  _id
                  key
                }
                ... on PasswordField {
                  _id
                  key
                }
                ... on OpenMultilineField {
                  _id
                  key
                }
                ... on PhotoReportField {
                  _id
                  key
                }
                ... on MapField {
                  _id
                  key
                }
                ... on SignatureField {
                  _id
                  key
                }
                ... on CodeBarField {
                  _id
                  key
                }
                ... on ChecklistField {
                  _id
                  key
                }
                ... on TokenField {
                  _id
                  key
                }
                ... on TableField {
                  _id
                  key
                }
                ... on GeofenceField {
                  _id
                  key
                }
                ... on ListUniqueField {
                  _id
                  key
                  options {
                    name
                    value
                  }
                }
                ... on ListMultipleField {
                  _id
                  key
                  options {
                    name
                    value
                  }
                }
                ... on MassiveListUniqueField {
                  _id
                  key
                  massiveList
                }
                ... on MassiveListMultipleField {
                  _id
                  key
                  massiveList
                }
              }
            }
          }
        }
      }
      engines {
        visitId
        moduleId
        type
        moduleRef {
          _id
          type
          propertiesRef {
            __typename
            ... on QuestionEngineModule {
              _id
              fields {
                __typename
                ... on OpenField {
                  _id
                  key
                }
                ... on NumericField {
                  _id
                  key
                }
                ... on DateField {
                  _id
                  key
                }
                ... on PhoneField {
                  _id
                  key
                }
                ... on EmailField {
                  _id
                  key
                }
                ... on PasswordField {
                  _id
                  key
                }
                ... on OpenMultilineField {
                  _id
                  key
                }
                ... on PhotoReportField {
                  _id
                  key
                }
                ... on MapField {
                  _id
                  key
                }
                ... on SignatureField {
                  _id
                  key
                }
                ... on CodeBarField {
                  _id
                  key
                }
                ... on ChecklistField {
                  _id
                  key
                }
                ... on TokenField {
                  _id
                  key
                }
                ... on TableField {
                  _id
                  key
                }
                ... on GeofenceField {
                  _id
                  key
                }
                ... on ListUniqueField {
                  _id
                  key
                  options {
                    name
                    value
                  }
                }
                ... on ListMultipleField {
                  _id
                  key
                  options {
                    name
                    value
                  }
                }
                ... on MassiveListUniqueField {
                  _id
                  key
                  massiveList
                }
                ... on MassiveListMultipleField {
                  _id
                  key
                  massiveList
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const VISIT_ANSWER_ITEMS = gql`
  query visitAnswerItems(
    $where: VisitAnswerWhereInput!
    $orderBy: VisitAnswerOrderByInput
  ) {
    visitAnswers(where: $where, orderBy: $orderBy) {
      _id
      approvalStatus
      userId
      userRef {
        _id
        firstName
        lastName
        email
      }
      checkInDate
      checkOutDate
      activities {
        moduleId
      }
      checkIn {
        questionAnswersId
        questionAnswersRef {
          _id
          answers {
            key
            answer {
              __typename
              ... on OpenFieldAnswer {
                OpenFieldAnswer: value
              }
              ... on NumericFieldAnswer {
                NumericFieldAnswer: value
              }
              ... on DateFieldAnswer {
                DateFieldAnswer: value
              }
              ... on PhoneFieldAnswer {
                PhoneFieldAnswer: value
              }
              ... on ListUniqueFieldAnswer {
                ListUniqueFieldAnswer: value
              }
              ... on ListMultipleFieldAnswer {
                ListMultipleFieldAnswer: value
              }
              ... on EmailFieldAnswer {
                EmailFieldAnswer: value
              }
              ... on PasswordFieldAnswer {
                PasswordFieldAnswer: value
              }
              ... on OpenMultilineFieldAnswer {
                OpenMultilineFieldAnswer: value
              }
              ... on CodeBarFieldAnswer {
                CodeBarFieldAnswer: value
              }
              ... on MassiveListUniqueFieldAnswer {
                MassiveListUniqueFieldAnswer: value
              }
              ... on MassiveListMultipleFieldAnswer {
                MassiveListMultipleFieldAnswer: value
              }
              ... on TokenFieldAnswer {
                TokenFieldAnswer: value
              }
              ... on GeofenceFieldAnswer {
                GeofenceFieldAnswer: value
              }
              ... on FileFieldAnswer {
                FileFieldAnswer: files {
                  filename
                  file
                }
              }
            }
          }
        }
      }
      calculatedChecklistColumns {
        categories {
          name
          operations {
            name
            operator
            value
          }
        }
        globals {
          name
          value
        }
      }
    }
  }
`;

export const VISIT_ANSWER_COUNT = gql`
  query visitAnswerConnection($where: VisitAnswerWhereInput!) {
    visitAnswerConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const VISIT_ANSWER_IMAGES_ITEMS = gql`
  query visitAnswerItems(
    $where: VisitAnswerWhereInput!
    $orderBy: VisitAnswerOrderByInput
  ) {
    visitAnswers(where: $where, orderBy: $orderBy) {
      _id
      approvalStatus
      userId
      userRef {
        _id
        firstName
        lastName
        email
      }
      checkInDate
      checkOutDate
      checkIn {
        moduleId
        questionAnswersId
        questionAnswersRef {
          _id
          created
          module {
            _id
            name
            propertiesRef {
              __typename
              ... on QuestionEngineModule {
                _id
                fields {
                  __typename
                  ... on OpenField {
                    _id
                    key
                  }
                  ... on NumericField {
                    _id
                    key
                  }
                  ... on DateField {
                    _id
                    key
                  }
                  ... on PhoneField {
                    _id
                    key
                  }
                  ... on EmailField {
                    _id
                    key
                  }
                  ... on PasswordField {
                    _id
                    key
                  }
                  ... on OpenMultilineField {
                    _id
                    key
                  }
                  ... on PhotoReportField {
                    _id
                    key
                  }
                  ... on MapField {
                    _id
                    key
                    completeAddress
                  }
                  ... on SignatureField {
                    _id
                    key
                  }
                  ... on CodeBarField {
                    _id
                    key
                  }
                  ... on ChecklistField {
                    _id
                    key
                  }
                  ... on TokenField {
                    _id
                    key
                  }
                  ... on TableField {
                    _id
                    key
                  }
                  ... on GeofenceField {
                    _id
                    key
                  }
                  ... on ListUniqueField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on ListMultipleField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on MassiveListUniqueField {
                    _id
                    key
                    massiveList
                  }
                  ... on MassiveListMultipleField {
                    _id
                    key
                    massiveList
                  }
                  ... on FileField {
                    _id
                    key
                  }
                }
              }
            }
          }
          userId
          userRef {
            _id
            email
            firstName
            lastName
          }
          answers {
            key
            questionLabel
            answer {
              __typename
              ... on OpenFieldAnswer {
                OpenFieldAnswer: value
              }
              ... on NumericFieldAnswer {
                NumericFieldAnswer: value
              }
              ... on DateFieldAnswer {
                DateFieldAnswer: value
              }
              ... on PhoneFieldAnswer {
                PhoneFieldAnswer: value
              }
              ... on ListUniqueFieldAnswer {
                ListUniqueFieldAnswer: value
                config {
                  key
                  type
                  label
                  help
                  required
                  listStyle
                  options {
                    _id
                    value
                    name
                    segment
                    emails
                    image {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                }
              }
              ... on ListMultipleFieldAnswer {
                ListMultipleFieldAnswer: value
                config {
                  key
                  type
                  label
                  help
                  required
                  listStyle
                  options {
                    _id
                    value
                    name
                    segment
                    emails
                    image {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                }
              }
              ... on EmailFieldAnswer {
                EmailFieldAnswer: value
              }
              ... on PasswordFieldAnswer {
                PasswordFieldAnswer: value
              }
              ... on OpenMultilineFieldAnswer {
                OpenMultilineFieldAnswer: value
              }
              ... on PhotoReportFieldAnswer {
                PhotoReportFieldAnswer: value {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on MapFieldAnswer {
                longitude
                latitude
                address
              }
              ... on SignatureFieldAnswer {
                SignatureFieldAnswer: value {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on CodeBarFieldAnswer {
                CodeBarFieldAnswer: value
              }
              ... on MassiveListUniqueFieldAnswer {
                MassiveListUniqueFieldAnswer: value
                config {
                  massiveList
                }
              }
              ... on MassiveListMultipleFieldAnswer {
                MassiveListMultipleFieldAnswer: value
                config {
                  massiveList
                }
              }
              ... on ChecklistFieldAnswer {
                ChecklistFieldAnswer: id
                comment
                email
                photos {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
                signature {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on TokenFieldAnswer {
                TokenFieldAnswer: value
              }
              ... on TableFieldAnswer {
                rows {
                  answers {
                    key
                    answer {
                      __typename
                      ... on OpenSubFieldAnswer {
                        OpenSubFieldAnswer: value
                      }
                      ... on NumericSubFieldAnswer {
                        NumericSubFieldAnswer: value
                      }
                      ... on DateSubFieldAnswer {
                        DateSubFieldAnswer: value
                      }
                      ... on PhoneSubFieldAnswer {
                        PhoneSubFieldAnswer: value
                      }
                      ... on MassiveListUniqueSubFieldAnswer {
                        MassiveListUniqueSubFieldAnswer: value
                      }
                    }
                  }
                }
                config {
                  _id
                  key
                  type
                  label
                  columns {
                    __typename
                    ... on OpenSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on NumericSubField {
                      _id
                      key
                      type
                      label
                      width
                      numericFormatStyle
                    }
                    ... on DateSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on PhoneSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on MassiveListUniqueSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                  }
                }
              }
              ... on GeofenceFieldAnswer {
                GeofenceFieldAnswer: value
              }
              ... on FileFieldAnswer {
                FileFieldAnswer: files {
                  filename
                  file
                }
              }
            }
          }
          checklists {
            _id
            app
            name
            options {
              _id
              name
              image {
                i320
                i480
              }
              imageRequired
              signatureRequired
              numericValue
            }
          }
        }
      }
      checkOut {
        moduleId
        questionAnswersRef {
          _id
          created
          module {
            _id
            name
            propertiesRef {
              __typename
              ... on QuestionEngineModule {
                _id
                fields {
                  __typename
                  ... on OpenField {
                    _id
                    key
                  }
                  ... on NumericField {
                    _id
                    key
                  }
                  ... on DateField {
                    _id
                    key
                  }
                  ... on PhoneField {
                    _id
                    key
                  }
                  ... on EmailField {
                    _id
                    key
                  }
                  ... on PasswordField {
                    _id
                    key
                  }
                  ... on OpenMultilineField {
                    _id
                    key
                  }
                  ... on PhotoReportField {
                    _id
                    key
                  }
                  ... on MapField {
                    _id
                    key
                    completeAddress
                  }
                  ... on SignatureField {
                    _id
                    key
                  }
                  ... on CodeBarField {
                    _id
                    key
                  }
                  ... on ChecklistField {
                    _id
                    key
                  }
                  ... on TokenField {
                    _id
                    key
                  }
                  ... on TableField {
                    _id
                    key
                  }
                  ... on GeofenceField {
                    _id
                    key
                  }
                  ... on ListUniqueField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on ListMultipleField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on MassiveListUniqueField {
                    _id
                    key
                    massiveList
                  }
                  ... on MassiveListMultipleField {
                    _id
                    key
                    massiveList
                  }
                  ... on FileField {
                    _id
                    key
                  }
                }
              }
            }
          }
          userId
          userRef {
            _id
            email
            firstName
            lastName
          }
          answers {
            key
            questionLabel
            answer {
              __typename
              ... on OpenFieldAnswer {
                OpenFieldAnswer: value
              }
              ... on NumericFieldAnswer {
                NumericFieldAnswer: value
              }
              ... on DateFieldAnswer {
                DateFieldAnswer: value
              }
              ... on PhoneFieldAnswer {
                PhoneFieldAnswer: value
              }
              ... on ListUniqueFieldAnswer {
                ListUniqueFieldAnswer: value
                config {
                  key
                  type
                  label
                  help
                  required
                  listStyle
                  options {
                    _id
                    value
                    name
                    segment
                    emails
                    image {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                }
              }
              ... on ListMultipleFieldAnswer {
                ListMultipleFieldAnswer: value
                config {
                  key
                  type
                  label
                  help
                  required
                  listStyle
                  options {
                    _id
                    value
                    name
                    segment
                    emails
                    image {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                }
              }
              ... on EmailFieldAnswer {
                EmailFieldAnswer: value
              }
              ... on PasswordFieldAnswer {
                PasswordFieldAnswer: value
              }
              ... on OpenMultilineFieldAnswer {
                OpenMultilineFieldAnswer: value
              }
              ... on PhotoReportFieldAnswer {
                PhotoReportFieldAnswer: value {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on MapFieldAnswer {
                longitude
                latitude
                address
              }
              ... on SignatureFieldAnswer {
                SignatureFieldAnswer: value {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on CodeBarFieldAnswer {
                CodeBarFieldAnswer: value
              }
              ... on MassiveListUniqueFieldAnswer {
                MassiveListUniqueFieldAnswer: value
                config {
                  massiveList
                }
              }
              ... on MassiveListMultipleFieldAnswer {
                MassiveListMultipleFieldAnswer: value
                config {
                  massiveList
                }
              }
              ... on ChecklistFieldAnswer {
                ChecklistFieldAnswer: id
                comment
                email
                photos {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
                signature {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on TokenFieldAnswer {
                TokenFieldAnswer: value
              }
              ... on TableFieldAnswer {
                rows {
                  answers {
                    key
                    answer {
                      __typename
                      ... on OpenSubFieldAnswer {
                        OpenSubFieldAnswer: value
                      }
                      ... on NumericSubFieldAnswer {
                        NumericSubFieldAnswer: value
                      }
                      ... on DateSubFieldAnswer {
                        DateSubFieldAnswer: value
                      }
                      ... on PhoneSubFieldAnswer {
                        PhoneSubFieldAnswer: value
                      }
                      ... on MassiveListUniqueSubFieldAnswer {
                        MassiveListUniqueSubFieldAnswer: value
                      }
                    }
                  }
                }
                config {
                  _id
                  key
                  type
                  label
                  columns {
                    __typename
                    ... on OpenSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on NumericSubField {
                      _id
                      key
                      type
                      label
                      width
                      numericFormatStyle
                    }
                    ... on DateSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on PhoneSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on MassiveListUniqueSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                  }
                }
              }
              ... on GeofenceFieldAnswer {
                GeofenceFieldAnswer: value
              }
              ... on FileFieldAnswer {
                FileFieldAnswer: files {
                  filename
                  file
                }
              }
            }
          }
          checklists {
            _id
            app
            name
            options {
              _id
              name
              image {
                i320
                i480
              }
              imageRequired
              signatureRequired
              numericValue
            }
          }
        }
      }
      activities {
        moduleId
        questionAnswersRef {
          _id
          created
          module {
            _id
            name
            propertiesRef {
              __typename
              ... on QuestionEngineModule {
                _id
                fields {
                  __typename
                  ... on OpenField {
                    _id
                    key
                  }
                  ... on NumericField {
                    _id
                    key
                  }
                  ... on DateField {
                    _id
                    key
                  }
                  ... on PhoneField {
                    _id
                    key
                  }
                  ... on EmailField {
                    _id
                    key
                  }
                  ... on PasswordField {
                    _id
                    key
                  }
                  ... on OpenMultilineField {
                    _id
                    key
                  }
                  ... on PhotoReportField {
                    _id
                    key
                  }
                  ... on MapField {
                    _id
                    key
                    completeAddress
                  }
                  ... on SignatureField {
                    _id
                    key
                  }
                  ... on CodeBarField {
                    _id
                    key
                  }
                  ... on ChecklistField {
                    _id
                    key
                  }
                  ... on TokenField {
                    _id
                    key
                  }
                  ... on TableField {
                    _id
                    key
                  }
                  ... on GeofenceField {
                    _id
                    key
                  }
                  ... on ListUniqueField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on ListMultipleField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on MassiveListUniqueField {
                    _id
                    key
                    massiveList
                  }
                  ... on MassiveListMultipleField {
                    _id
                    key
                    massiveList
                  }
                  ... on FileField {
                    _id
                    key
                  }
                }
              }
            }
          }
          userId
          userRef {
            _id
            email
            firstName
            lastName
          }
          answers {
            key
            questionLabel
            answer {
              __typename
              ... on OpenFieldAnswer {
                OpenFieldAnswer: value
              }
              ... on NumericFieldAnswer {
                NumericFieldAnswer: value
              }
              ... on DateFieldAnswer {
                DateFieldAnswer: value
              }
              ... on PhoneFieldAnswer {
                PhoneFieldAnswer: value
              }
              ... on ListUniqueFieldAnswer {
                ListUniqueFieldAnswer: value
                config {
                  key
                  type
                  label
                  help
                  required
                  listStyle
                  options {
                    _id
                    value
                    name
                    segment
                    emails
                    image {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                }
              }
              ... on ListMultipleFieldAnswer {
                ListMultipleFieldAnswer: value
                config {
                  key
                  type
                  label
                  help
                  required
                  listStyle
                  options {
                    _id
                    value
                    name
                    segment
                    emails
                    image {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                }
              }
              ... on EmailFieldAnswer {
                EmailFieldAnswer: value
              }
              ... on PasswordFieldAnswer {
                PasswordFieldAnswer: value
              }
              ... on OpenMultilineFieldAnswer {
                OpenMultilineFieldAnswer: value
              }
              ... on PhotoReportFieldAnswer {
                PhotoReportFieldAnswer: value {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on MapFieldAnswer {
                longitude
                latitude
                address
              }
              ... on SignatureFieldAnswer {
                SignatureFieldAnswer: value {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on CodeBarFieldAnswer {
                CodeBarFieldAnswer: value
              }
              ... on MassiveListUniqueFieldAnswer {
                MassiveListUniqueFieldAnswer: value
                config {
                  massiveList
                }
              }
              ... on MassiveListMultipleFieldAnswer {
                MassiveListMultipleFieldAnswer: value
                config {
                  massiveList
                }
              }
              ... on ChecklistFieldAnswer {
                ChecklistFieldAnswer: id
                comment
                email
                photos {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
                signature {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on TokenFieldAnswer {
                TokenFieldAnswer: value
              }
              ... on TableFieldAnswer {
                rows {
                  answers {
                    key
                    answer {
                      __typename
                      ... on OpenSubFieldAnswer {
                        OpenSubFieldAnswer: value
                      }
                      ... on NumericSubFieldAnswer {
                        NumericSubFieldAnswer: value
                      }
                      ... on DateSubFieldAnswer {
                        DateSubFieldAnswer: value
                      }
                      ... on PhoneSubFieldAnswer {
                        PhoneSubFieldAnswer: value
                      }
                      ... on MassiveListUniqueSubFieldAnswer {
                        MassiveListUniqueSubFieldAnswer: value
                      }
                    }
                  }
                }
                config {
                  _id
                  key
                  type
                  label
                  columns {
                    __typename
                    ... on OpenSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on NumericSubField {
                      _id
                      key
                      type
                      label
                      width
                      numericFormatStyle
                    }
                    ... on DateSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on PhoneSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on MassiveListUniqueSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                  }
                }
              }
              ... on GeofenceFieldAnswer {
                GeofenceFieldAnswer: value
              }
              ... on FileFieldAnswer {
                FileFieldAnswer: files {
                  filename
                  file
                }
              }
            }
          }
          checklists {
            _id
            app
            name
            options {
              _id
              name
              image {
                i320
                i480
              }
              imageRequired
              signatureRequired
              numericValue
            }
          }
        }
      }
      calculatedChecklistColumns {
        categories {
          name
          operations {
            name
            operator
            value
          }
        }
        globals {
          name
          value
        }
      }
    }
  }
`;

export const VISIT_ANSWER_REPORT = gql`
  query visitAnswerItems($where: VisitAnswerWhereInput!) {
    visitAnswers(where: $where) {
      _id
      checkInDate
      checkOutDate
      userId
      userRef {
        _id
        email
        firstName
        lastName
      }
      keys
      checkIn {
        questionAnswersId
        questionAnswersRef {
          _id
          module {
            _id
            name
            propertiesRef {
              __typename
              ... on QuestionEngineModule {
                _id
                fields {
                  __typename
                  ... on OpenField {
                    _id
                    key
                  }
                  ... on NumericField {
                    _id
                    key
                  }
                  ... on DateField {
                    _id
                    key
                  }
                  ... on PhoneField {
                    _id
                    key
                  }
                  ... on EmailField {
                    _id
                    key
                  }
                  ... on PasswordField {
                    _id
                    key
                  }
                  ... on OpenMultilineField {
                    _id
                    key
                  }
                  ... on PhotoReportField {
                    _id
                    key
                  }
                  ... on MapField {
                    _id
                    key
                    completeAddress
                  }
                  ... on SignatureField {
                    _id
                    key
                  }
                  ... on CodeBarField {
                    _id
                    key
                  }
                  ... on ChecklistField {
                    _id
                    key
                  }
                  ... on TokenField {
                    _id
                    key
                  }
                  ... on TableField {
                    _id
                    key
                  }
                  ... on GeofenceField {
                    _id
                    key
                  }
                  ... on ListUniqueField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on ListMultipleField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on MassiveListUniqueField {
                    _id
                    key
                    massiveList
                  }
                  ... on MassiveListMultipleField {
                    _id
                    key
                    massiveList
                  }
                  ... on FileField {
                    _id
                    key
                  }
                }
              }
            }
          }
          userId
          userRef {
            _id
            email
            firstName
            lastName
          }
          answers {
            key
            questionLabel
            answer {
              __typename
              ... on OpenFieldAnswer {
                OpenFieldAnswer: value
              }
              ... on NumericFieldAnswer {
                NumericFieldAnswer: value
              }
              ... on DateFieldAnswer {
                DateFieldAnswer: value
              }
              ... on PhoneFieldAnswer {
                PhoneFieldAnswer: value
              }
              ... on ListUniqueFieldAnswer {
                ListUniqueFieldAnswer: value
                config {
                  key
                  type
                  label
                  help
                  required
                  listStyle
                  options {
                    _id
                    value
                    name
                    segment
                    emails
                    image {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                }
              }
              ... on ListMultipleFieldAnswer {
                ListMultipleFieldAnswer: value
                config {
                  key
                  type
                  label
                  help
                  required
                  listStyle
                  options {
                    _id
                    value
                    name
                    segment
                    emails
                    image {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                }
              }
              ... on EmailFieldAnswer {
                EmailFieldAnswer: value
              }
              ... on PasswordFieldAnswer {
                PasswordFieldAnswer: value
              }
              ... on OpenMultilineFieldAnswer {
                OpenMultilineFieldAnswer: value
              }
              ... on PhotoReportFieldAnswer {
                PhotoReportFieldAnswer: value {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on MapFieldAnswer {
                longitude
                latitude
                address
              }
              ... on SignatureFieldAnswer {
                SignatureFieldAnswer: value {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on CodeBarFieldAnswer {
                CodeBarFieldAnswer: value
              }
              ... on MassiveListUniqueFieldAnswer {
                MassiveListUniqueFieldAnswer: value
                config {
                  massiveList
                }
              }
              ... on MassiveListMultipleFieldAnswer {
                MassiveListMultipleFieldAnswer: value
                config {
                  massiveList
                }
              }
              ... on ChecklistFieldAnswer {
                ChecklistFieldAnswer: id
                comment
                email
                photos {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
                signature {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on TokenFieldAnswer {
                TokenFieldAnswer: value
              }
              ... on TableFieldAnswer {
                rows {
                  answers {
                    key
                    answer {
                      __typename
                      ... on OpenSubFieldAnswer {
                        OpenSubFieldAnswer: value
                      }
                      ... on NumericSubFieldAnswer {
                        NumericSubFieldAnswer: value
                      }
                      ... on DateSubFieldAnswer {
                        DateSubFieldAnswer: value
                      }
                      ... on PhoneSubFieldAnswer {
                        PhoneSubFieldAnswer: value
                      }
                      ... on MassiveListUniqueSubFieldAnswer {
                        MassiveListUniqueSubFieldAnswer: value
                      }
                    }
                  }
                }
                config {
                  _id
                  key
                  type
                  label
                  columns {
                    __typename
                    ... on OpenSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on NumericSubField {
                      _id
                      key
                      type
                      label
                      width
                      numericFormatStyle
                    }
                    ... on DateSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on PhoneSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on MassiveListUniqueSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                  }
                }
              }
              ... on GeofenceFieldAnswer {
                GeofenceFieldAnswer: value
              }
              ... on FileFieldAnswer {
                FileFieldAnswer: files {
                  filename
                  file
                }
              }
            }
          }
          checklists {
            _id
            app
            name
            options {
              _id
              name
              image {
                i320
                i480
              }
              numericValue
            }
          }
        }
      }
      checkOut {
        questionAnswersId
        questionAnswersRef {
          _id
          module {
            _id
            name
            propertiesRef {
              __typename
              ... on QuestionEngineModule {
                _id
                fields {
                  __typename
                  ... on OpenField {
                    _id
                    key
                  }
                  ... on NumericField {
                    _id
                    key
                  }
                  ... on DateField {
                    _id
                    key
                  }
                  ... on PhoneField {
                    _id
                    key
                  }
                  ... on EmailField {
                    _id
                    key
                  }
                  ... on PasswordField {
                    _id
                    key
                  }
                  ... on OpenMultilineField {
                    _id
                    key
                  }
                  ... on PhotoReportField {
                    _id
                    key
                  }
                  ... on MapField {
                    _id
                    key
                    completeAddress
                  }
                  ... on SignatureField {
                    _id
                    key
                  }
                  ... on CodeBarField {
                    _id
                    key
                  }
                  ... on ChecklistField {
                    _id
                    key
                  }
                  ... on TokenField {
                    _id
                    key
                  }
                  ... on TableField {
                    _id
                    key
                  }
                  ... on GeofenceField {
                    _id
                    key
                  }
                  ... on ListUniqueField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on ListMultipleField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on MassiveListUniqueField {
                    _id
                    key
                    massiveList
                  }
                  ... on MassiveListMultipleField {
                    _id
                    key
                    massiveList
                  }
                  ... on FileField {
                    _id
                    key
                  }
                }
              }
            }
          }
          userId
          userRef {
            _id
            email
            firstName
            lastName
          }
          answers {
            key
            questionLabel
            answer {
              __typename
              ... on OpenFieldAnswer {
                OpenFieldAnswer: value
              }
              ... on NumericFieldAnswer {
                NumericFieldAnswer: value
              }
              ... on DateFieldAnswer {
                DateFieldAnswer: value
              }
              ... on PhoneFieldAnswer {
                PhoneFieldAnswer: value
              }
              ... on ListUniqueFieldAnswer {
                ListUniqueFieldAnswer: value
                config {
                  key
                  type
                  label
                  help
                  required
                  separator
                  separatorPosition
                  separatorSize
                  separatorText
                  listStyle
                  options {
                    _id
                    value
                    name
                    segment
                    emails
                    image {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                }
              }
              ... on ListMultipleFieldAnswer {
                ListMultipleFieldAnswer: value
                config {
                  key
                  type
                  label
                  help
                  required
                  separator
                  separatorPosition
                  separatorSize
                  separatorText
                  listStyle
                  options {
                    _id
                    value
                    name
                    segment
                    emails
                    image {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                }
              }
              ... on EmailFieldAnswer {
                EmailFieldAnswer: value
              }
              ... on PasswordFieldAnswer {
                PasswordFieldAnswer: value
              }
              ... on OpenMultilineFieldAnswer {
                OpenMultilineFieldAnswer: value
              }
              ... on PhotoReportFieldAnswer {
                PhotoReportFieldAnswer: value {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on MapFieldAnswer {
                longitude
                latitude
                address
              }
              ... on SignatureFieldAnswer {
                SignatureFieldAnswer: value {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on CodeBarFieldAnswer {
                CodeBarFieldAnswer: value
              }
              ... on MassiveListUniqueFieldAnswer {
                MassiveListUniqueFieldAnswer: value
                config {
                  massiveList
                }
              }
              ... on MassiveListMultipleFieldAnswer {
                MassiveListMultipleFieldAnswer: value
                config {
                  massiveList
                }
              }
              ... on ChecklistFieldAnswer {
                ChecklistFieldAnswer: id
                comment
                email
                photos {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
                signature {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on TokenFieldAnswer {
                TokenFieldAnswer: value
              }
              ... on TableFieldAnswer {
                rows {
                  answers {
                    key
                    answer {
                      __typename
                      ... on OpenSubFieldAnswer {
                        OpenSubFieldAnswer: value
                      }
                      ... on NumericSubFieldAnswer {
                        NumericSubFieldAnswer: value
                      }
                      ... on DateSubFieldAnswer {
                        DateSubFieldAnswer: value
                      }
                      ... on PhoneSubFieldAnswer {
                        PhoneSubFieldAnswer: value
                      }
                      ... on MassiveListUniqueSubFieldAnswer {
                        MassiveListUniqueSubFieldAnswer: value
                      }
                    }
                  }
                }
                config {
                  _id
                  key
                  type
                  label
                  columns {
                    __typename
                    ... on OpenSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on NumericSubField {
                      _id
                      key
                      type
                      label
                      width
                      numericFormatStyle
                    }
                    ... on DateSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on PhoneSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on MassiveListUniqueSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                  }
                }
              }
              ... on GeofenceFieldAnswer {
                GeofenceFieldAnswer: value
              }
              ... on FileFieldAnswer {
                FileFieldAnswer: files {
                  filename
                  file
                }
              }
            }
          }
          checklists {
            _id
            app
            name
            options {
              _id
              name
              image {
                i320
                i480
              }
              numericValue
            }
          }
        }
      }
      activities {
        moduleId
        questionAnswersId
        questionAnswersRef {
          _id
          module {
            _id
            name
            propertiesRef {
              __typename
              ... on QuestionEngineModule {
                _id
                fields {
                  __typename
                  ... on OpenField {
                    _id
                    key
                  }
                  ... on NumericField {
                    _id
                    key
                  }
                  ... on DateField {
                    _id
                    key
                  }
                  ... on PhoneField {
                    _id
                    key
                  }
                  ... on EmailField {
                    _id
                    key
                  }
                  ... on PasswordField {
                    _id
                    key
                  }
                  ... on OpenMultilineField {
                    _id
                    key
                  }
                  ... on PhotoReportField {
                    _id
                    key
                  }
                  ... on MapField {
                    _id
                    key
                    completeAddress
                  }
                  ... on SignatureField {
                    _id
                    key
                  }
                  ... on CodeBarField {
                    _id
                    key
                  }
                  ... on ChecklistField {
                    _id
                    key
                  }
                  ... on TokenField {
                    _id
                    key
                  }
                  ... on TableField {
                    _id
                    key
                  }
                  ... on GeofenceField {
                    _id
                    key
                  }
                  ... on ListUniqueField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on ListMultipleField {
                    _id
                    key
                    options {
                      name
                      value
                    }
                  }
                  ... on MassiveListUniqueField {
                    _id
                    key
                    massiveList
                  }
                  ... on MassiveListMultipleField {
                    _id
                    key
                    massiveList
                  }
                  ... on FileField {
                    _id
                    key
                  }
                }
              }
            }
          }
          userId
          userRef {
            _id
            email
            firstName
            lastName
          }
          answers {
            key
            questionLabel
            answer {
              __typename
              ... on OpenFieldAnswer {
                OpenFieldAnswer: value
              }
              ... on NumericFieldAnswer {
                NumericFieldAnswer: value
              }
              ... on DateFieldAnswer {
                DateFieldAnswer: value
              }
              ... on PhoneFieldAnswer {
                PhoneFieldAnswer: value
              }
              ... on ListUniqueFieldAnswer {
                ListUniqueFieldAnswer: value
                config {
                  key
                  type
                  label
                  help
                  required
                  separator
                  separatorPosition
                  separatorSize
                  separatorText
                  listStyle
                  options {
                    _id
                    value
                    name
                    segment
                    emails
                    image {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                }
              }
              ... on ListMultipleFieldAnswer {
                ListMultipleFieldAnswer: value
                config {
                  key
                  type
                  label
                  help
                  required
                  separator
                  separatorPosition
                  separatorSize
                  separatorText
                  listStyle
                  options {
                    _id
                    value
                    name
                    segment
                    emails
                    image {
                      original
                      i1280
                      i240
                      i320
                      i480
                      i640
                      i960
                    }
                  }
                }
              }
              ... on EmailFieldAnswer {
                EmailFieldAnswer: value
              }
              ... on PasswordFieldAnswer {
                PasswordFieldAnswer: value
              }
              ... on OpenMultilineFieldAnswer {
                OpenMultilineFieldAnswer: value
              }
              ... on PhotoReportFieldAnswer {
                PhotoReportFieldAnswer: value {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on MapFieldAnswer {
                longitude
                latitude
                address
              }
              ... on SignatureFieldAnswer {
                SignatureFieldAnswer: value {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on CodeBarFieldAnswer {
                CodeBarFieldAnswer: value
              }
              ... on MassiveListUniqueFieldAnswer {
                MassiveListUniqueFieldAnswer: value
                config {
                  massiveList
                }
              }
              ... on MassiveListMultipleFieldAnswer {
                MassiveListMultipleFieldAnswer: value
                config {
                  massiveList
                }
              }
              ... on ChecklistFieldAnswer {
                ChecklistFieldAnswer: id
                comment
                email
                photos {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
                signature {
                  original
                  i1280
                  i240
                  i320
                  i480
                  i640
                  i960
                }
              }
              ... on TokenFieldAnswer {
                TokenFieldAnswer: value
              }
              ... on TableFieldAnswer {
                rows {
                  answers {
                    key
                    answer {
                      __typename
                      ... on OpenSubFieldAnswer {
                        OpenSubFieldAnswer: value
                      }
                      ... on NumericSubFieldAnswer {
                        NumericSubFieldAnswer: value
                      }
                      ... on DateSubFieldAnswer {
                        DateSubFieldAnswer: value
                      }
                      ... on PhoneSubFieldAnswer {
                        PhoneSubFieldAnswer: value
                      }
                      ... on MassiveListUniqueSubFieldAnswer {
                        MassiveListUniqueSubFieldAnswer: value
                      }
                    }
                  }
                }
                config {
                  _id
                  key
                  type
                  label
                  columns {
                    __typename
                    ... on OpenSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on NumericSubField {
                      _id
                      key
                      type
                      label
                      width
                      numericFormatStyle
                    }
                    ... on DateSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on PhoneSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                    ... on MassiveListUniqueSubField {
                      _id
                      key
                      type
                      label
                      width
                    }
                  }
                }
              }
              ... on GeofenceFieldAnswer {
                GeofenceFieldAnswer: value
              }
              ... on FileFieldAnswer {
                FileFieldAnswer: files {
                  filename
                  file
                }
              }
            }
          }
          checklists {
            _id
            app
            name
            options {
              _id
              name
              image {
                i320
                i480
              }
              numericValue
            }
          }
        }
      }
      calculatedChecklistColumns {
        categories {
          name
          operations {
            name
            operator
            value
          }
        }
        globals {
          name
          value
        }
      }
    }
  }
`;

export const APPROVAL_ITEMS_ADMIN = gql`
  query approvalItems($where: ApprovalItemWhereInput!) {
    approvalItems(where: $where, orderBy: created_ASC) {
      _id
      app
      userId
      userRef {
        _id
        email
        firstName
        lastName
      }
      status
      approvalLogId
      approvalLogRef {
        _id
        status
        type
        created
      }
      visitAnswerId
      visitId
      visitRef {
        _id
        name
      }
      visitAnswerRef {
        _id
        checkInDate
        checkOutDate
        userId
        userRef {
          _id
          email
          firstName
          lastName
        }
      }
      created
    }
  }
`;

export const QUESTION_ANSWER_UPDATE_LOG_VISIT = gql`
  query questionEngineAnswersUpdateLogs(
    $where: QuestionEngineAnswersUpdateLogWhereInput!
  ) {
    questionEngineAnswersUpdateLogs(where: $where) {
      _id
      app
      userId
      userRef {
        _id
        email
      }
      questionAnswersId
      key
      previousAnswer {
        __typename
        ... on OpenFieldAnswer {
          OpenFieldAnswer: value
        }
        ... on NumericFieldAnswer {
          NumericFieldAnswer: value
        }
        ... on DateFieldAnswer {
          DateFieldAnswer: value
        }
        ... on PhoneFieldAnswer {
          PhoneFieldAnswer: value
        }
        ... on ListUniqueFieldAnswer {
          ListUniqueFieldAnswer: value
        }
        ... on ListMultipleFieldAnswer {
          ListMultipleFieldAnswer: value
        }
        ... on EmailFieldAnswer {
          EmailFieldAnswer: value
        }
        ... on PasswordFieldAnswer {
          PasswordFieldAnswer: value
        }
        ... on OpenMultilineFieldAnswer {
          OpenMultilineFieldAnswer: value
        }
        ... on PhotoReportFieldAnswer {
          PhotoReportFieldAnswer: value {
            original
            i1280
            i240
            i320
            i480
            i640
            i960
          }
        }
        ... on MapFieldAnswer {
          longitude
          latitude
        }
        ... on SignatureFieldAnswer {
          SignatureFieldAnswer: value {
            original
            i1280
            i240
            i320
            i480
            i640
            i960
          }
        }
        ... on CodeBarFieldAnswer {
          CodeBarFieldAnswer: value
        }
        ... on MassiveListUniqueFieldAnswer {
          MassiveListUniqueFieldAnswer: value
        }
        ... on MassiveListMultipleFieldAnswer {
          MassiveListMultipleFieldAnswer: value
        }
        ... on ChecklistFieldAnswer {
          id
          comment
          photos {
            original
            i1280
            i240
            i320
            i480
            i640
            i960
          }
        }
        ... on TokenFieldAnswer {
          TokenFieldAnswer: value
        }
        ... on TableFieldAnswer {
          rows {
            answers {
              key
              answer {
                __typename
                ... on OpenSubFieldAnswer {
                  OpenSubFieldAnswer: value
                }
                ... on NumericSubFieldAnswer {
                  NumericSubFieldAnswer: value
                }
                ... on DateSubFieldAnswer {
                  DateSubFieldAnswer: value
                }
                ... on PhoneSubFieldAnswer {
                  PhoneSubFieldAnswer: value
                }
                ... on MassiveListUniqueSubFieldAnswer {
                  MassiveListUniqueSubFieldAnswer: value
                }
              }
            }
          }
        }
        ... on GeofenceFieldAnswer {
          GeofenceFieldAnswer: value
        }
      }
      created
    }
  }
`;

export const SEGMENT_ITEMS = gql`
  query segmentItems($where: SegmentWhereInput) {
    segments(where: $where) {
      _id
      name
    }
  }
`;

export const USER_MOBILE_ITEMS = gql`
  query userMobileItems($where: UserMobileWhereInput!) {
    userMobiles(where: $where) {
      _id
      firstName
      lastName
    }
  }
`;

export const MASSIVE_LIST_ITEMS = gql`
  query massiveListItems($where: MassiveListItemWhereInput) {
    massiveListItems(where: $where) {
      _id
      value
    }
  }
`;

export const USER_SEGMENT_ITEMS = gql`
  query userSegmentItems($where: UserSegmentWhereInput!) {
    userSegment(where: $where) {
      _id
      name
      user {
        _id
      }
    }
  }
`;

export const GEOFENCES_GROUP_ITEMS = gql`
  query geofencesGroupItems($where: GeofenceGroupItemWhereInput) {
    geofencesGroup(where: $where) {
      _id
      name
      archived
      geofences {
        _id
        name
        archived
      }
    }
  }
`;
