import React from 'react';
import PropTypes from 'prop-types';

// own components
import EngineAnswerFieldFooter from "../EngineAnswerFieldFooter";

// custom components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from "components/Card/CardFooter.jsx";
import Position from 'components/Position';

// core components
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import withStyles from "@material-ui/core/styles/withStyles";

const SignatureFieldAnswerView = ({answerData, openImageLightBox, classes}) => {
  return (
    <Card >
      <CardBody>
        <div className={classes.root}>
          <GridList cellHeight={150} className={classes.gridList}
                    cols={answerData.answer.SignatureFieldAnswer.length >= 3
                      ? 3 : answerData.answer.SignatureFieldAnswer.length}>
            {answerData.answer.SignatureFieldAnswer.map((tile, index) => (
              <GridListTile key={index} cols={1} onClick={() => {
                openImageLightBox({
                  images: answerData.answer.SignatureFieldAnswer.map(img => img.original),
                  currentImage: index
                })
              }}>
                <img 
                  src={tile.i320} 
                  alt={tile.i320}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src=tile.original;
                  }} 
                />
              </GridListTile>
            ))}
          </GridList>
        </div>
      </CardBody>
      <CardFooter>
        <EngineAnswerFieldFooter answerData={answerData} />
      </CardFooter>
    </Card>
  );
};

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 160,
  },
});

SignatureFieldAnswerView.propTypes = {
  answerData: PropTypes.object.isRequired
};

export default withStyles(styles)(SignatureFieldAnswerView);
