import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import DivCenter from "../../components/styled-components/DivCenter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import ConfirmIconButton from "../../components/ConfirmIconButton/ConfirmIconButton";

import VisibilityIcon from '@material-ui/icons/Visibility';
import TableChartIcon from "@material-ui/icons/TableChart";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import RoomIcon from "@material-ui/icons/Room";
import AssignmentIcon from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import DonutSmall from "@material-ui/icons/DonutSmall";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import useGlobal from "../../store";
import auth from "../../utils/auth";

const ProcessRelationshipTable = ({
  data,
  archiveProcessRelationship,
  isArchivedMode,
  setOpen,
  open,
  setProcessRelationship
}) => {
  const { t } = useTranslation("processRelationship");

  const [session] = useGlobal((state) => state.session);

  const hasPermission = (permission) => {
    if (session.user && !auth.hasPermission(permission, session.permissions)) return false;
    return true; 
  };

  const getActions = (original) => {
    return (
      <div>
        {hasPermission("processRelationshipView") ? (
          <Link
            to={{
              pathname: `/objects/${original._id}/tabulator`,
              state: {
                item: original,
              },
            }}
          >
            <Button justIcon round simple color="info">
              <Tooltip title={t("processRelationship.tooltip.tabulator")}>
                <TableChartIcon />
              </Tooltip>
            </Button>
          </Link>
        ) : (
          ""
        )}
        {hasPermission("processRelationshipView") ? (
          <Link
            to={{
              pathname: `/objects/${original._id}/workPlan`,
            }}
          >
            <Button justIcon round simple color="info">
              <Tooltip title={t("processRelationship.tooltip.workPlan")}>
                <DonutSmall />
              </Tooltip>
            </Button>
          </Link>
        ) : (
          ""
        )}
        {hasPermission("processRelationshipView") ? (
          <Button
            justIcon
            round
            simple
            color="info"
            onClick={() => {
              setOpen(!open);
              setProcessRelationship(original);
            }}
          >
            <Tooltip title={t("processRelationship.tooltip.penalties")}>
              <RemoveCircleIcon />
            </Tooltip>
          </Button>
        ) : (
          ""
        )}
        {hasPermission("processRelationshipEdit") ? (
          <Link
            to={{
              pathname: `/objects/${original._id}/edit`,
              state: {
                object: original,
              },
            }}
          >
            <Button justIcon round simple color="info">
              <Tooltip title={t("processRelationship.tooltip.edit")}>
                <EditIcon />
              </Tooltip>
            </Button>
          </Link>
        ) : (
          ""
        )}
        {hasPermission("processRelationshipArchived") ? (
          <ConfirmIconButton
            t={t}
            simple={true}
            baseTooltip={"processRelationship.tooltip.archived"}
            confirmTooltip={"processRelationship.tooltip.archiveConfirm"}
            baseIcon={DeleteForeverIcon}
            confirmDelay={3000}
            confirmOperation={() => {
              archiveProcessRelationship({
                variables: {
                  where: {
                    _id: original._id,
                  },
                },
              });
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <ReactTable
      data={data}
      columns={[
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("processRelationship.list.columns.name")}
            </DivCenter>
          ),
          accessor: "name",
          filterable: false,
          sortable: false,
          style: {
            textAlign: "center",
            whiteSpace: "unset",
            fontWeight: "400",
            ...styles.contentText,
          },
          //minWidth: 15,
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("processRelationship.list.columns.description")}
            </DivCenter>
          ),
          accessor: "description",
          filterable: false,
          sortable: false,
          style: {
            textAlign: "center",
            whiteSpace: "unset",
            ...styles.contentText,
          },
          //minWidth: 20,
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("processRelationship.list.columns.element")}
            </DivCenter>
          ),
          accessor: "element",
          Cell: ({ original }) => {
            const { elementsRef, elementsType } = original;

            return (
              <GridContainer>
                <GridItem xs={10}>
                  {elementsRef
                    ? elementsRef.map((element) => element.name).join(", ")
                    : ""}
                </GridItem>

                <GridItem xs={2} style={{ textAlign: "left" }}>
                  {elementsType === "Geofences" ? (
                    <RoomIcon />
                  ) : (
                    <AssignmentIcon />
                  )}
                </GridItem>
              </GridContainer>
            );
          },
          filterable: false,
          sortable: false,
          style: {
            textAlign: "right",
            whiteSpace: "unset",
            ...styles.contentText,
          },
          //minWidth: 20,
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("processRelationship.list.columns.requisitions")}
            </DivCenter>
          ),
          accessor: "requisitions",
          Cell: ({ original }) => {
            const { requisitionConfig } = original;
            const url =
              requisitionConfig && requisitionConfig.length
                ? `/objects/${original._id}/requisition/list`
                : `/objects/${original._id}/requisition/new`;
            const label =
              requisitionConfig && requisitionConfig.length
                ? `${t("processRelationship.requisitions.actives")}: ${requisitionConfig.length}`
                : t("processRelationship.requisitions.new");

            if (hasPermission('requisitionView')) {
              return (
                <GridContainer>
                  <GridItem xs={10}>
                    <Link
                      to={{
                        pathname: url,
                        state: {
                          processRelationship: original,
                        },
                      }}
                      style={{
                        color: "black",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      {label}
                    </Link>
                  </GridItem>

                  <GridItem xs={2} style={{ textAlign: "left" }}>
                    <Link
                      to={{
                        pathname: `/objects/${original._id}/requisition/list`,
                        state: {
                          processRelationship: original,
                        },
                      }}
                      style={{
                        color: "black",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      <Tooltip
                        title={t(
                          "processRelationship.tooltip.viewRequisitions"
                        )}
                      >
                        <VisibilityIcon
                          style={{ color: "#6ab3d4", cursor: "pointer" }}
                        />
                      </Tooltip>
                    </Link>
                  </GridItem>
                </GridContainer>
              );
            }else{
              return (
                <div>
                    {label}
                </div>
              );
            }
          },
          filterable: false,
          sortable: false,
          style: {
            textAlign: "center",
            ...styles.contentText,
          },
          //minWidth: 20,
        },
        {
          Header: <DivCenter></DivCenter>,
          accessor: "actions",
          Cell: ({ original }) => {
            if (isArchivedMode) {
              return [];
            }
            return getActions(original);
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "right" },
          //minWidth: 30,
        },
      ]}
      defaultPageSize={15}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
      resizable={false}
    />
  );
};

const styles = {
  headerTitle: {
    fontSize: "20px",
    whiteSpace: "unset",
    fontWeight: "500",
  },
  contentText: {
    fontSize: "17px",
  },
  regularButton: {
    color: "red",
    "&:hover": {
      backgroundColor: "green",
      color: "#6ab3d4",
    },
  },
};

ProcessRelationshipTable.propTypes = {
  data: PropTypes.array.isRequired,
  archiveProcessRelationship: PropTypes.func.isRequired,
};

export default ProcessRelationshipTable;
