import gql from "graphql-tag";

export const TICKET_REPORTS_CHANGED_SUBSCRIPTION = gql`
  subscription ticketReportChanged($where: TicketReportChangedWhereInput!){
    ticketReportChanged(where: $where){
      operation
      node {
        _id
        app
        ticket {
            _id,
            name
        }
        status
        userRef {
          _id
          firstName
          lastName
        }
      }
    }
  }
`;

export const TICKET_REPORT_COMMENT_CHANGED = gql`
  subscription ticketReportCommentChanged (
    $appId: ID!
    $ticketId: ID!
  ){
    ticketReportCommentChanged(where: {
      appId: $appId,
      ticketId: $ticketId
    }) {
      operation
      ticketReport {
        _id
        app
        ticket {
          _id
          name
        }
      }
      node {
        _id
        text
        created
        userRef {
          ... on UserGh {
            _id
            email
            __typename
          }
          ... on UserMobile {
            _id
            email
            __typename
          }
        }
      }
    }
  }`;
