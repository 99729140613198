import React from 'react';
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import DetailsTable from 'components/AdminData/DetailsTable';
import ListButton from 'components/AdminData/ListButton';
import PieChart from 'components/Chart/PieChart';
import { getPieChartData } from '../../../utils/analytics/dataManipulation';
import { durationInSeconds, dateFromSeconds, duration } from '../../../utils/dates/dates';
import { getDefaultSubject, getDefaultBody } from '../../../utils/emails/defaultContent';
import { REPORT_ACTIONS } from '../../../constants';

function combineKeyfields(visitAnswer){
  if(!visitAnswer || !visitAnswer.keyfields) return "Campos Clave no definidos";
  return visitAnswer.keyfields
    .map(keyfield => keyfield.id + ": " + keyfield.value)
    .join(" - ");
}

function getDurations(visitAnswers){
  if(!visitAnswers || visitAnswers.length === 0) return [];
  return visitAnswers.map(visitAnswer =>({
    label: combineKeyfields(visitAnswer),
    durationInSeconds: durationInSeconds(
      visitAnswer.checkInDate,
      visitAnswer.checkOutDate
    ),
  }));
}

function getAverageDuration(items){
  const sum = items.reduce((acumm, current) => acumm + current.durationInSeconds, 0);
  return sum === 0 ? sum : sum / items.length;
}

function groupByDuration(items, expectedTime){
  let under = [];
  let over = [];
  items.forEach(item => {
    const expetedSeconds = expectedTime.hours * 3600 + expectedTime.minutes * 60 + expectedTime.seconds;
    if(item.durationInSeconds < expetedSeconds){
      under.push(item);
    } else {
      over.push(item);
    }
  });
  return [under, over];
}

function VisitsTimeView({  
  classes,
  visitAnswers,
  createVisitingTimeReport,
  emailData,
  sendVisitingTimePDFReport
}) {
  const [expectedTime, setExpectedTime] = React.useState({
    hours: 1,
    minutes: 0,
    seconds: 0
  });

  const handleHoursChange = React.useCallback((e) => {
    setExpectedTime({...expectedTime, hours: e.target.value});
  });

  const handleMinutesChange = React.useCallback((e) => {
    setExpectedTime({...expectedTime, minutes: e.target.value});
  });

  const handleSecondsChange = React.useCallback((e) => {
    setExpectedTime({...expectedTime, seconds: e.target.value});
  });
  
  const durationList = getDurations(visitAnswers);
  const averageTime = getAverageDuration(durationList);
  const [onTime, outOfTime] = groupByDuration(durationList, expectedTime);

  const data = [
    {
      label: "En tiempo",
      count: onTime.length,
      color: "#F4883A"
    }, {
      label: "Fuera de tiempo",
      count: outOfTime.length,
      color: "#FFC800"
    }
  ];

  const chartData = getPieChartData(data, "Tiempo de Visita");

  const wrapPDFData = () => {
    return {
      items: {
        Hours: expectedTime,
        onTime: onTime,
        outOfTime: outOfTime,
        averageTime: dateFromSeconds(averageTime),
        data: data
      },
    };
  };

  const percentage = Math.round(onTime.length/(onTime.length + outOfTime.length)*100 * 100) / 100;
  const handlePDFReportClick = React.useCallback(() => {
    const payload = wrapPDFData();
    createVisitingTimeReport(payload);
  }, [ expectedTime, expectedTime, onTime, outOfTime, averageTime, data ]);

  const handlePDFReportEmailClick = React.useCallback((emailData) => {
    const payload = wrapPDFData();
    sendVisitingTimePDFReport(payload, emailData);
  }, [ sendVisitingTimePDFReport, expectedTime, onTime, outOfTime, averageTime, data ]);

  const defaultSubject = {
    PDF: getDefaultSubject( REPORT_ACTIONS.visitingTimePDF,
      { visit: emailData.visit,
        startDate: emailData.startDate, endDate: emailData.endDate } )
  };

  const defaultBody = {
    PDF: getDefaultBody( REPORT_ACTIONS.visitingTimePDF, 
      { visit: emailData.visit, hour: expectedTime.hours, minute: expectedTime.minutes, 
        second: expectedTime.seconds, averageTime: dateFromSeconds(averageTime), 
        percentage: isNaN(percentage) ? 0 : percentage,
      })
        
  };

  return (
    <Card>
      <CardHeader color="info" text>
        <GridContainer>
          <GridItem xs={6}>
            <CardText color="info">
              <h4 className={classes.cardTitle}>Tiempo de Visita</h4>
            </CardText>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody className={classes.visitsTimeCard}>
        <GridContainer>
          <GridItem xs={6}>
            <div className={classes.hourPicker}>
              <span className={classes.title}>Tiempo esperado de visita:</span>
              <div className={classes.hourPickerControls}>
                <div className={classes.input}>
                  <CustomInput
                    labelText="Horas"
                    id="hours"
                    type={"number"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleHoursChange,
                      autoComplete: "false",
                      defaultValue: 1
                    }}
                  />
                </div>
                <div className={classes.input}>
                  <CustomInput
                    labelText="Minutos"
                    id="minutes"
                    type={"number"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleMinutesChange,
                      autoComplete: "false",
                      defaultValue: 0
                    }}
                  />
                </div>
                <div className={classes.input}>
                  <CustomInput
                    labelText="Segundos"
                    id="seconds"
                    type={"number"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleSecondsChange,
                      autoComplete: "false",
                      defaultValue: 0
                    }}
                  />
                </div>
              </div>
            </div>
            <h4>Tiempo promedio de visita: {dateFromSeconds(averageTime)}</h4>
            <ListButton
              classes={classes}
              label="Lista de En Tiempo" 
              items={onTime.map(({label}) => label)}
              enableButtons={{
                email: true,
                download: true,
                preview: false,
                xls: false
              }}
              defaultBody={defaultBody}
              defaultSubject={defaultSubject}
              handlePDFReportEmailClick={handlePDFReportEmailClick}
              handlePDFReportClick={handlePDFReportClick}
            />
            <ListButton
              classes={classes}
              label="Lista de Fuera de Tiempo" 
              items={outOfTime.map(({label}) => label)}
              enableButtons={{
                email: false,
                download: false,
                preview: false
              }} 
            />
          </GridItem>
          <GridItem xs={6}>
            <div className={classes.container}>
              <div className={classes.chart}>
                <PieChart
                  id="visitsTimeChart"
                  data={chartData}
                />
              </div>
            </div>
            <DetailsTable
              data={data} 
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

VisitsTimeView.propTypes = {
  visitAnswers: PropTypes.array.isRequired
};

const styles = theme => ({
  container:{
    display: "flex",
    justifyContent: "center"
  },
  chart: {
    width: "400px",
  },
  hourPicker:{
    display: "flex",
    alignItems: "center"
  },
  hourPickerControls:{
    display: "flex",
    margin: "0px 15px",
  },
  input: {
    margin: "0px 2px",
    width: "50px",
  },
  title:{
    fontSize: "1.3em"
  },
  visitsTimeCard:{
    zIndex: 12
  },
});

export default withStyles(styles)(VisitsTimeView);
