import React, { useState } from "react";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import ProcessRelationshipWizard from "../../wizars/ProcessRelationship";

const NewProcessRelationshipView = (props) => {
  const { t } = useTranslation("processRelationship");

  const {
    processRelationship,
    submitSuccess,
    massiveLists,
    geofences,
    userMobiles,
    questionEngineModules,
  } = props;
  const {
    updateValidVisits,
    isUpdatingValidVisits,
    validVisits,
    elementsList,
    loadingElementsList,
    updateSelectedVisitsInfo,
    tableFields,
    checkFields,
    visitsConfigurations,
    paginationUsers,
    paginationAction,
    allElementsList
  } = props;

  return (
    <GridContainer justify="center">
      <GridItem xs={10}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <AddBoxIcon />
            </CardIcon>
            <h4 style={style.cardTitle}>
              {processRelationship?.name ?? t("processRelationship.wizard.title") }
            </h4>
          </CardHeader>
          <CardBody>
            <ProcessRelationshipWizard
              processRelationship={processRelationship}
              submitSuccess={submitSuccess}
              massiveLists={massiveLists}
              geofences={geofences}
              updateValidVisits={updateValidVisits}
              isUpdatingValidVisits={isUpdatingValidVisits}
              validVisits={validVisits}
              userMobiles={userMobiles}
              questionEngineModules={questionEngineModules}
              elementsList={elementsList}
              loadingElementsList={loadingElementsList}
              updateSelectedVisitsInfo={updateSelectedVisitsInfo}
              tableFields={tableFields}
              checkFields={checkFields}
              visitsConfigurations={visitsConfigurations}
              paginationUsers={paginationUsers}
              paginationAction={paginationAction}
              allElementsList={allElementsList}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
NewProcessRelationshipView.prototype = {
  app: PropTypes.string.isRequired,
  submitSuccess: PropTypes.func.isRequired,
};
const style = {
  cardTitle: {
    color: "#3C4858",
  },
};
export default NewProcessRelationshipView;
