import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';

// core components
import withStyles from "@material-ui/core/styles/withStyles";

// custom components
import Button from "components/Button";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import DownloadButton from "components/Reports/DownloadButton";
import SendEmailButton from "components/Reports/SendEmailButton";
import PreviewButton from "components/Reports/PreviewButton";
import QuestionEngineAnswerView from '../../QuestionEngineAnswers/QuestionEngineAnswerView';
import ImageGallery from './ImageGallery';

function ModulesGalleryView({
  classes,
  modules,
  handlePDFReportEmailClick,
  defaultBody,
  defaultSubject
}) {
  const [lightBoxOpen, toggleLightBox] = React.useState(false);
  const [lightBoxImages, setLightBoxImages] = React.useState([]);
  const [currentLightBoxImage, setCurrentLightBoxImage] = React.useState(0);
  const [indexes, setIndexes] = React.useState({
    imageIdx: 0,
    answerIdx: 0
  });

  React.useEffect(() => {
    setIndexes({
      imageIdx: 0,
      answerIdx: 0
    });
  }, [modules]);

  const [imageAnswers, textModules] = React.useMemo(() => {
    const imageAnswers = [];
    const textModules = [];

    modules.forEach((moduleRef) => {
      if (moduleRef) {
        const {answers, checklists} = moduleRef.questionAnswersRef;

        answers.forEach((answer) => {
          if(answer.answer.__typename === "PhotoReportFieldAnswer") {
            imageAnswers.push({
              ...answer,
              questionAnswersRef: moduleRef.questionAnswersRef._id,
            });
          } else if(answer.answer.__typename === "ChecklistFieldAnswer") {
            const hasImage = checklists.some((checklist) => {
              const foundOption = checklist.options.find((option) => {
                return option._id === answer.answer.ChecklistFieldAnswer;
              });
              return foundOption ? foundOption.imageRequired || foundOption.signatureRequired : false;
            });
            if(hasImage || answer.answer.photos.length > 0) {
              imageAnswers.push({
                ...answer,
                questionAnswersRef: moduleRef.questionAnswersRef._id,
              });
            }
          }
        });
        textModules.push({
          ...moduleRef.questionAnswersRef,
          keyfields: moduleRef.keyfields
        })
      }
    })

    return [imageAnswers, textModules];
  }, [modules]);

  const imagesSets = React.useMemo(() => imageAnswers.map(currentAnswer => {
    switch (currentAnswer.answer.__typename) {
      case "PhotoReportFieldAnswer":
        return currentAnswer.answer.PhotoReportFieldAnswer;
      case "ChecklistFieldAnswer":
        return (currentAnswer.answer.photos || []).concat(currentAnswer.answer.signature || []);
    }
  }), [imageAnswers]);

  const currentSet = React.useMemo(() =>
    !!imagesSets && imagesSets.length > 0 ?
      imagesSets[indexes.answerIdx] : null,
  [imagesSets, indexes]);

  const currentImage = React.useMemo(() =>
    !!imagesSets && imagesSets.length > 0 ?
      imagesSets[indexes.answerIdx][indexes.imageIdx] : null,
  [imagesSets, indexes]);

  const currentModuleText = React.useMemo(() => {
    const selectedModule = imageAnswers[indexes.answerIdx];
    if(!selectedModule) return null;
    return textModules.find(textModule =>
      textModule._id === selectedModule.questionAnswersRef);
  }, [imageAnswers, indexes, textModules]);

  const nextImage = React.useCallback(() => {
    if(!imagesSets || imagesSets.length === 0) return;
    const imagesSetLength = imagesSets[indexes.answerIdx].length;
    const answersSetLength = imagesSets.length;

    const nextImageIdx = indexes.imageIdx + 1;
    if(nextImageIdx < imagesSetLength) {
      setIndexes(prevIndexes => ({
        ...prevIndexes,
        imageIdx: nextImageIdx
      }));
    } else {
      const nextAnswer = indexes.answerIdx + 1;
      if(nextAnswer < answersSetLength) {
        setIndexes({
          answerIdx: nextAnswer,
          imageIdx: 0
        });
      }
    }
  }, [imagesSets, indexes, setIndexes]);

  const prevImage = React.useCallback(() => {
    if(!imagesSets || imagesSets.length === 0) return;
    const prevImage = indexes.imageIdx - 1;
    if(prevImage >= 0) {
      setIndexes(prevIndexes => ({
        ...prevIndexes,
        imageIdx: prevImage
      }));
    } else {
      const prevAnswer = indexes.answerIdx - 1;
      if(prevAnswer >= 0) {
        setIndexes({
          answerIdx: prevAnswer,
          imageIdx: imagesSets[prevAnswer].length - 1
        });
      }
    }
  }, [imagesSets, indexes, setIndexes]);

  const isFirstImage = React.useMemo(() => {
    return indexes.imageIdx === 0 && indexes.answerIdx === 0;
  }, [indexes]);

  const isLastImage = React.useMemo(() => {
    if(!imagesSets || imagesSets.length === 0) return true;
    const imagesSetLength = imagesSets[indexes.answerIdx].length;
    const answersSetLength = imagesSets.length;

    return indexes.imageIdx === imagesSetLength - 1
      && indexes.answerIdx === answersSetLength - 1;

  }, [imagesSets, indexes, setIndexes]);

  return (
    <Card>
      <CardHeader color="info" text>
        <GridContainer>
          <GridItem xs={9}>
          </GridItem>
          <GridItem xs={1}>
            <CardText color="info" className={classes.noPadding}>
              <SendEmailButton
                color="white"
                isReady={true}
                size="lg"
                defaultBody={defaultBody}
                defaultSubject={defaultSubject}
                sendPDFReport={handlePDFReportEmailClick}
              />
            </CardText>
          </GridItem>
          <GridItem xs={1}>
            <CardText color="info" className={classes.noPadding}>
              <DownloadButton
                color="white"
                isReady={false}
                size="lg"
              />
            </CardText>
          </GridItem>
          <GridItem xs={1}>
            <CardText color="info" className={classes.noPadding}>
              <PreviewButton
                color="white"
                isReady={false}
                size="lg"
              />
            </CardText>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={6}>
            <ImageGallery
              image={currentImage}
              openImageLightBox={() => {
                setLightBoxImages(currentSet.map(image => image.original));
                setCurrentLightBoxImage(indexes.imageIdx);
                toggleLightBox(true);
              }}
            />
            <GridContainer>
              <GridItem xs={6}>
                <Button
                  size="sm"
                  color={isFirstImage ?  "primary" : "info"}
                  disabled={isFirstImage}
                  fullWidth
                  onClick={prevImage}
                >
                  Anterior
                </Button>
              </GridItem>
              <GridItem xs={6}>
                <Button
                  size="sm"
                  color={isLastImage ? "primary" : "info"}
                  disabled={isLastImage}
                  fullWidth
                  onClick={nextImage}
                >
                  Siguiente
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6}>
            { !!currentModuleText &&
              <QuestionEngineAnswerView
                questionEngineAnswer={currentModuleText}
              />
            }
          </GridItem>
        </GridContainer>
        {
          lightBoxOpen ?
            <Lightbox
              mainSrc={lightBoxImages[currentLightBoxImage]}
              nextSrc={lightBoxImages[(currentLightBoxImage + 1) % lightBoxImages.length]}
              prevSrc={lightBoxImages[(currentLightBoxImage + lightBoxImages.length - 1) % lightBoxImages.length]}
              onCloseRequest={() => toggleLightBox(false)}
              onMovePrevRequest={() =>
                setCurrentLightBoxImage((currentLightBoxImage + lightBoxImages.length - 1) % lightBoxImages.length)
              }
              onMoveNextRequest={() =>
                setCurrentLightBoxImage((currentLightBoxImage + 1) % lightBoxImages.length)
              }
              reactModalStyle={{
                overlay: {
                  zIndex: 10000
                }
              }}
            /> : []
        }
      </CardBody>
    </Card>
  );
};

const styles = theme => ({
  noPadding: {
    padding: 0
  }
});

ModulesGalleryView.propTypes = {
  modules: PropTypes.array.isRequired
};

export default withStyles(styles)(ModulesGalleryView);
