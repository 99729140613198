import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import DivCenter from "../../components/styled-components/DivCenter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import ConfirmIconButton from "../../components/ConfirmIconButton/ConfirmIconButton";

import TableChartIcon from "@material-ui/icons/TableChart";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import UnarchiveIcon from "@material-ui/icons/Unarchive";

import { baseDateAnswerFormat } from "../../utils/dates/dates";
import auth from "../../utils/auth";
import useGlobal from "../../store";

const ChecklistReportsTable = ({
  list,
  visits,
  processRelationships,
  archive,
  isArchivedMode,
}) => {
  const { t } = useTranslation("so");
  const [session] = useGlobal((state) => state.session);

  const hasPermission = (permission) => {
    if (session.user && !auth.hasPermission(permission, session.permissions)) {
      return false;
    } else {
      return true;
    }
  };

  const getActions = (original) => {
    return (
      <div>
        <Link
          to={{
            pathname: `/so/checklistReport/${original._id}/view`,
            state: {
              checklistReport: original,
            },
          }}
        >
          <Button justIcon round simple color="info">
            <Tooltip title={t("so.checklistReports.list.tooltip.viewReport")}>
              <TableChartIcon />
            </Tooltip>
          </Button>
        </Link>
        {hasPermission("soChecklistEdit") ? (
          <Link
            to={{
              pathname: `/so/checklistReport/${original._id}/edit`,
              state: {
                checklistReport: original,
              },
            }}
          >
            <Button justIcon round simple color="info">
              <Tooltip title={t("so.checklistReports.list.tooltip.edit")}>
                <EditIcon />
              </Tooltip>
            </Button>
          </Link>
        ) : (
          []
        )}
        {hasPermission("soChecklistArchived") ? (
          <ConfirmIconButton
            t={t}
            simple={true}
            baseTooltip={"so.checklistReports.list.tooltip.archived"}
            confirmTooltip={"so.checklistReports.list.tooltip.archiveConfirm"}
            baseIcon={DeleteForeverIcon}
            confirmDelay={3000}
            confirmOperation={() => {
              archive(original);
            }}
          />
        ) : (
          []
        )}
      </div>
    );
  };

  return (
    <ReactTable
      data={list}
      columns={[
        {
          Header: (
            <DivCenter>{t("so.checklistReports.list.columns.name")}</DivCenter>
          ),
          accessor: "name",
          filterable: false,
          sortable: false,
          style: { textAlign: "center", whiteSpace: "unset" },
          minWidth: 15,
        },
        {
          Header: (
            <DivCenter>
              {t("so.checklistReports.list.columns.periodicity")}
            </DivCenter>
          ),
          Cell: ({ original }) => {
            return (
              <DivCenter>
                {t(
                  `so.checklistReports.forms.createUpdate.fields.periodicity.options.${original.periodicity}`
                )}
              </DivCenter>
            );
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center", whiteSpace: "unset" },
          minWidth: 15,
        },
        {
          Header: <DivCenter>Para Fechas</DivCenter>,
          Cell: ({ original }) => {
            return (
              <DivCenter>
                {`${baseDateAnswerFormat(
                  original.initialDate
                )} ${baseDateAnswerFormat(original.finalDate)}`}
              </DivCenter>
            );
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center", whiteSpace: "unset" },
          minWidth: 15,
        },
        {
          Header: <DivCenter>Tipo de Configuración</DivCenter>,
          Cell: ({ original }) => {
            if (original.isVisit === true) {
              const currentVisit = visits.find(
                (visit) => visit._id === original.visitId
              );
              return (
                <DivCenter>
                  <p
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Visita:
                  </p>
                  {currentVisit?.name || ""}
                  <p
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {original?.keyField || ""}
                  </p>
                </DivCenter>
              );
            } else {
              const currentProcessRelationship = processRelationships.find(
                (processRelationship) =>
                  processRelationship._id === original.processRelationshipId
              );
              return (
                <DivCenter>
                  <p
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Objeto:
                  </p>
                  {currentProcessRelationship?.name || ""}
                </DivCenter>
              );
            }
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center", whiteSpace: "unset" },
          minWidth: 15,
        },
        {
          Header: <DivCenter>Estándar Esperado</DivCenter>,
          Cell: ({ original }) => {
            return <DivCenter>{`${original.targetValue} %`}</DivCenter>;
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center", whiteSpace: "unset" },
          minWidth: 15,
        },
        {
          Header: <DivCenter></DivCenter>,
          accessor: "actions",
          Cell: ({ original }) => {
            if (isArchivedMode) {
              return (
                <div>
                  <Button
                    justIcon
                    round
                    simple
                    color="success"
                    onClick={() => {
                      archive(original);
                    }}
                  >
                    <Tooltip
                      title={t("so.checklistReports.list.tooltip.unArchive")}
                    >
                      <UnarchiveIcon />
                    </Tooltip>
                  </Button>
                </div>
              );
            }
            return getActions(original);
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "right" },
          minWidth: 30,
        },
      ]}
      defaultPageSize={15}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
    />
  );
};

ChecklistReportsTable.propTypes = {
  list: PropTypes.array.isRequired,
  archive: PropTypes.func.isRequired,
  isArchivedMode: PropTypes.bool.isRequired,
};

export default ChecklistReportsTable;
