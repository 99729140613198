
export const setStartDateRange = async (store, startDate) => {
  await store.setState({
    filters: {
      ...store.state.filters,
      range: {
        ...store.state.filters.range,
        startDate
      },
    }
  });
};

export const setEndDateRange = async (store, endDate) => {
  await store.setState({
    filters: {
      ...store.state.filters,
      range: {
        ...store.state.filters.range,
        endDate
      }
    }
  });
};

export const setOperation = async (store, selectedOperation) => {
  await store.setState({
    filters: {
      ...store.state.filters,
      selectedOperation
    }
  });
};

export const setVisitID = async (store, selectedVisitID) => {
  await store.setState({
    filters: {
      ...store.state.filters,
      selectedVisitID
    }
  });
};