import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, withFormik } from "formik";
import * as yup from "yup";
import { debounce } from "lodash";
import Moment from "moment";

// core components
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  TextField,
  RadioGroup,
  Radio,
  Divider,
  ButtonBase,
  Tooltip,
  Box,
  Typography,
} from "@material-ui/core";

// icons
import SaveAlt from "@material-ui/icons/SaveAlt";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddIcon from '@material-ui/icons/Add';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';

// custom components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Badge from "components/Badge/Badge";
import Button from "components/Button";
import ConfirmIconButton from "../../components/ConfirmIconButton/ConfirmIconButton";
import Select from "react-select";
import Datetime from "react-datetime";
import ReactTable from "react-table";

// styled components
import ButtonIcon from "../../components/styled/ButtonIcon";
import CardTitle from "../../components/styled/CardTitle";
import DivCenter from "../../components/styled-components/DivCenter";

//Utils
import { getErrorLabel, hasError } from "../../utils/forms/inputs";
import { IndicatorCounter, IndicatorField } from "./constants";

// local state
import useGlobal from "../../store";
import { SO_COUNTER_REPORT_PERIODICITY } from "../../constants";
import { makeStyles } from "@material-ui/core/styles";
import AddFilterSoCounterModal from "../../modals/AddFilterSoCounterModal";

/*
 * Validation Schema for CreateUpdateCounterReports
 * */
const createUpdateCounterReportsValidationSchema = yup.object().shape({
  name: yup.string().required("isRequired"),
  periodicity: yup.string().required("isRequired"),
  initialDate: yup.date().required().max(yup.ref("finalDate")),
  finalDate: yup.date().required().min(yup.ref("initialDate")),
  indicators: yup.array().required(),
});

const CreateUpdateCounterReports = (props) => {
  // Main hooks
  const { t } = useTranslation("so");
  const [, globalActions] = useGlobal();
  const classes = useStyles();

  // Formik bag
  const {
    errors,
    touched,
    setFieldValue,
    values,
    submitForm,
    submitCount,
    isSubmitting,
    isValid,
    segments
  } = props;
  // other props
  const { visits, questionEngineModules } = props;
  // Submit validation failed effect
  useEffect(() => {}, []);
  useEffect(() => {
    if (submitCount > 0 && !isSubmitting && !isValid) {
      const fields = Object.keys(errors);

      if (fields.length) {
        globalActions.notification.openNotification("tr", {
          message: t("so.counterReports.forms.createUpdate.validation.failed"),
          color: "danger",
        });
      }
    }
  }, [submitCount, isSubmitting]);

  // custom state
  const [indicatorConfig, setIndicatorConfig] = useState(null);
  const [indicatorConfigType, setIndicatorConfigType] = useState("counter");
  const [indicatorConfigShow, setIndicatorConfigShow] = useState(false);
  const [indicatorConfigMode, setIndicatorConfigMode] = useState("create");
  const [indicatorConfigIndex, setIndicatorConfigIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [currentIndicator, setCurrentIndicator] = useState(null);

  const formatPeriodicityOptions = (options) => {
    return options.map((option) => {
      return {
        label: t(
          `so.counterReports.forms.createUpdate.fields.periodicity.options.${option}`
        ),
        value: option,
      };
    });
  };
  const formatReferenceVisitOptions = (options) => {
    return options.map((option) => {
      return {
        label: option.name,
        value: option._id,
      };
    });
  };
  const formatReferenceQuestionModuleOptions = (options) => {
    return options.map((option) => {
      return {
        label: option.module.name,
        value: option.module._id,
      };
    });
  };
  const formatReferenceQuestionModuleNumericFieldOptions = (options) => {
    return options
      .filter((option) => {
        const currentNumericFields = option.fields.filter(
          (field) => field?.__typename === "NumericField"
        );
        return currentNumericFields.length > 0;
      })
      .map((option) => {
        return {
          label: option.module.name,
          value: option.module._id,
        };
      });
  };
  const formatQuestionModuleNumericFieldsOptions = (
    questionEngineModules,
    referenceId
  ) => {
    if (!referenceId) {
      return [];
    }
    const selectedQuestionEngineModule = questionEngineModules.find(
      (module) => module.module._id === referenceId
    );
    if (selectedQuestionEngineModule) {
      return selectedQuestionEngineModule.fields
        .filter((field) => field?.__typename === "NumericField")
        .map((field) => ({
          label: field.key,
          value: field._id,
        }));
    }
    return [];
  };
  const getSelectedFieldKeyReference = (
    questionEngineModules,
    referenceId,
    fieldRefId
  ) => {
    if (!referenceId) {
      return null;
    }
    const selectedQuestionEngineModule = questionEngineModules.find(
      (module) => module.module._id === referenceId
    );
    if (selectedQuestionEngineModule) {
      const fieldSelected = selectedQuestionEngineModule.fields.find(
        (field) => field?._id === fieldRefId
      );
      return fieldSelected
        ? {
            label: fieldSelected.key,
            value: fieldSelected._id,
          }
        : null;
    }
    return null;
  };
  const getSelectedReferenceByType = (referenceId, type) => {
    if (!referenceId) {
      return null;
    }
    if (type === IndicatorCounter.VisitCounter) {
      const referenceFound = visits.find((visit) => visit._id === referenceId);
      return referenceFound
        ? {
            label: referenceFound.name,
            value: referenceFound._id,
          }
        : null;
    } else {
      const referenceFound = questionEngineModules.find(
        (module) => module.module._id === referenceId
      );
      return referenceFound
        ? {
            label: referenceFound.module.name,
            value: referenceFound.module._id,
          }
        : null;
    }
  };
  const getSelectedPeriodicity = (periodicity) => {
    return {
      label: t(
        `so.counterReports.forms.createUpdate.fields.periodicity.options.${periodicity}`
      ),
      value: periodicity,
    };
  };
  const validIndicatorConfig = (indicatorConfig) => {
    let valid = true;
    if (indicatorConfig.targetValue <= 0) {
      valid = false;
    }
    if (!indicatorConfig.referenceId) {
      valid = false;
    }
    return valid;
  };
  const renderIndicationConfiguration = () => {
    if (indicatorConfigType === "counter") {
      return (
        <React.Fragment>
          <IndicatorConfigurationCounter
            {...indicatorConfig}
            onChange={(state) => {
              console.log(state);
              let updatedIndicator = {
                ...state
              };

              if (indicatorConfig._id) {
                updatedIndicator._id = indicatorConfig._id
              }
              setIndicatorConfig(updatedIndicator);
            }}
          />
          <Divider variant="middle" />
        </React.Fragment>
      );
    }
    if (indicatorConfigType === "field") {
      return (
        <React.Fragment>
          <IndicatorConfigurationField
            {...indicatorConfig}
            onChange={(state) => {
              console.log(state);
              let updatedIndicator = {
                ...state
              };

              if (indicatorConfig._id) {
                updatedIndicator._id = indicatorConfig._id
              }
              setIndicatorConfig(state);
            }}
          />
          <Divider variant="middle" />
        </React.Fragment>
      );
    }
    return [];
  };

  const IndicatorConfigurationCounter = ({
    type,
    referenceId,
    targetValue,
    onChange,
    index,
    keyField,
    segment,
    title
  }) => {
    const NumberFieldComponent = ({ currentValue }) => {
      const debouncedUpdate = debounce((value) => {
        onChange({
          type: type,
          referenceId: referenceId,
          targetValue: parseInt(value),
          keyField: keyField,
          segment: segment,
          title: title
        });
      }, 500);

      return (
        <TextField
          id="target-input"
          label={t(
            "so.counterReports.forms.createUpdate.fields.indicators.fields.target.label"
          )}
          type={"number"}
          defaultValue={currentValue}
          onChange={({ target }) => {
            debouncedUpdate(target.value);
          }}
          onFocus={(event) => {
            event.target.select();
          }}
          error={currentValue <= 0}
          fullWidth
        />
      );
    };

    const TitleFieldComponent = ({ currentValue }) => {

      const debouncedUpdate = debounce((value) => {
        onChange({
          type: type,
          referenceId: referenceId,
          targetValue: targetValue,
          keyField: keyField,
          segment: segment,
          title: value
        });
      }, 500);

      return (
        <TextField
          id="title"
          label={t(
            "so.counterReports.forms.createUpdate.fields.name.label"
          )}
          margin="normal"
          defaultValue={currentValue}
          type={"text"}
          fullWidth
          onChange={({ target }) => {
            debouncedUpdate(target.value);
          }}
          onFocus={(event) => {
            event.target.select();
          }}
        />
      );
    };

    return (
      <GridContainer xs={12} justify="center">
        <GridItem xs={10} sm={10}>
          <TitleFieldComponent currentValue={title}/>
        </GridItem>
        <GridItem xs={4}>
          <FormControl component="fieldset" className={classes.radioControl}>
            <RadioGroup
              row
              aria-label="indicatorType"
              name="indicatorType"
              value={type}
              onChange={(e) => {
                onChange({
                  type: e.target.value,
                  referenceId: null,
                  targetValue: targetValue,
                  title: title
                });
              }}
            >
              <FormControlLabel
                id={"type-visit"}
                className={classes.formLabel}
                value={IndicatorCounter.VisitCounter}
                control={
                  <Radio
                    color="default"
                    classes={{ root: classes.radio, checked: classes.checked }}
                  />
                }
                label={
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      color: "#3C4858",
                    }}
                  >
                    {t(
                      `so.counterReports.forms.createUpdate.fields.indicators.fields.type.options.${IndicatorCounter.VisitCounter}`
                    )}
                  </Typography>
                }
              />
              <FormControlLabel
                id={"type-question-engine-module"}
                className={classes.formLabel}
                value={IndicatorCounter.QuestionEngineModuleCounter}
                control={
                  <Radio
                    color="default"
                    classes={{ root: classes.radio, checked: classes.checked }}
                  />
                }
                label={
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      color: "#3C4858",
                    }}
                  >
                    {t(
                      `so.counterReports.forms.createUpdate.fields.indicators.fields.type.options.${IndicatorCounter.QuestionEngineModuleCounter}`
                    )}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </GridItem>
        <GridItem
          xs={6}
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              bottom: 5,
            }}
          >
            {type === IndicatorCounter.VisitCounter ? (
              <Select
                inputId={"select-reference"}
                placeholder={t(
                  "so.counterReports.forms.createUpdate.fields.indicators.fields.reference.label"
                )}
                value={getSelectedReferenceByType(referenceId, type)}
                onChange={(item) => {
                  onChange({
                    type: type,
                    referenceId: item.value,
                    targetValue: targetValue,
                    title: title,
                  });
                }}
                options={formatReferenceVisitOptions(visits)}
                styles={{
                  control: (base) => ({
                    ...base,
                    borderColor: referenceId ? base.borderColor : "red",
                    marginRight: 10,
                  }),
                  menu: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            ) : (
              <Select
                inputId={"select-reference"}
                placeholder={t(
                  "so.counterReports.forms.createUpdate.fields.indicators.fields.reference.label"
                )}
                value={getSelectedReferenceByType(referenceId, type)}
                onChange={(item) => {
                  onChange({
                    type: type,
                    referenceId: item.value,
                    targetValue: targetValue,
                    title: title
                  });
                }}
                options={formatReferenceQuestionModuleOptions(
                  questionEngineModules
                )}
                styles={{
                  control: (base) => ({
                    ...base,
                    borderColor: referenceId ? base.borderColor : "red",
                  }),
                  menu: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            )}
          </div>
        </GridItem>
        <GridItem
          xs={2}
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              margin: 5,
              bottom: 5,
            }}
          >
            <NumberFieldComponent currentValue={targetValue} fullWidth />
          </div>
        </GridItem>
      </GridContainer>
    );
  };
  const IndicatorConfigurationField = ({
    type,
    referenceId,
    targetValue,
    fieldRefId,
    onChange,
    index,
    keyField,
    segment,
    title
  }) => {
    const NumberFieldComponent = ({ currentValue }) => {
      const debouncedUpdate = debounce((value) => {
        onChange({
          type: type,
          referenceId: referenceId,
          targetValue: parseInt(value),
          fieldRefId: fieldRefId,
          segment: segment,
          title: title
        });
      }, 500);

      return (
        <TextField
          id="target-input"
          label={t(
            "so.counterReports.forms.createUpdate.fields.indicators.fields.target.label"
          )}
          type={"number"}
          defaultValue={currentValue}
          onChange={({ target }) => {
            debouncedUpdate(target.value);
          }}
          onFocus={(event) => {
            event.target.select();
          }}
          error={currentValue <= 0}
          fullWidth
        />
      );
    };

    const TitleFieldComponent = ({ currentValue }) => {

      const debouncedUpdate = debounce((value) => {
        onChange({
          type: type,
          referenceId: referenceId,
          targetValue: targetValue,
          fieldRefId: fieldRefId,
          segment: segment,
          title: value
        });
      }, 500);

      return (
        <TextField
          id="title"
          label={t(
            "so.counterReports.forms.createUpdate.fields.name.label"
          )}
          margin="normal"
          defaultValue={currentValue}
          type={"text"}
          fullWidth
          onChange={({ target }) => {
            debouncedUpdate(target.value);
          }}
          onFocus={(event) => {
            event.target.select();
          }}
        />
      );
    };

    return (
      <GridContainer xs={12} justify="center">
        <GridItem xs={10} sm={10}>
          <TitleFieldComponent currentValue={title}/>
        </GridItem>
        <GridItem xs={3}>
          <FormControl component="fieldset" className={classes.radioControl}>
            <RadioGroup
              row
              aria-label="indicatorType"
              name="indicatorType"
              value={type}
              onChange={(e) => {
                onChange({
                  type: e.target.value,
                  referenceId: null,
                  targetValue: targetValue,
                  fieldRefId: fieldRefId,
                  title: title
                });
              }}
            >
              <FormControlLabel
                id={"type-field-sum"}
                className={classes.formLabel}
                value={IndicatorField.FieldSum}
                control={
                  <Radio
                    color="default"
                    classes={{ root: classes.radio, checked: classes.checked }}
                  />
                }
                label={
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      color: "#3C4858",
                    }}
                  >
                    {t(
                      `so.counterReports.forms.createUpdate.fields.indicators.fields.type.options.${IndicatorField.FieldSum}`
                    )}
                  </Typography>
                }
              />
              <FormControlLabel
                id={"type-field-average"}
                className={classes.formLabel}
                value={IndicatorField.FieldAverage}
                control={
                  <Radio
                    color="default"
                    classes={{ root: classes.radio, checked: classes.checked }}
                  />
                }
                label={
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      color: "#3C4858",
                    }}
                  >
                    {t(
                      `so.counterReports.forms.createUpdate.fields.indicators.fields.type.options.${IndicatorField.FieldAverage}`
                    )}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </GridItem>
        <GridItem
          xs={4}
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              bottom: 5,
            }}
          >
            <Select
              inputId={"select-reference"}
              placeholder={t(
                "so.counterReports.forms.createUpdate.fields.indicators.fields.reference.label"
              )}
              value={getSelectedReferenceByType(referenceId, type)}
              onChange={(item) => {
                onChange({
                  type: type,
                  referenceId: item.value,
                  targetValue: targetValue,
                  fieldRefId: fieldRefId,
                  title: title
                });
              }}
              options={formatReferenceQuestionModuleNumericFieldOptions(
                questionEngineModules
              )}
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: referenceId ? base.borderColor : "red",
                }),
                menu: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </div>
        </GridItem>
        <GridItem
          xs={3}
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              bottom: 5,
            }}
          >
            <Select
              inputId={"select-key"}
              placeholder={t(
                "so.counterReports.forms.createUpdate.fields.indicators.fields.fieldKey.label"
              )}
              value={getSelectedFieldKeyReference(
                questionEngineModules,
                referenceId,
                fieldRefId
              )}
              onChange={(item) => {
                onChange({
                  type: type,
                  referenceId: referenceId,
                  targetValue: targetValue,
                  fieldRefId: item.value,
                  title: title
                });
              }}
              options={formatQuestionModuleNumericFieldsOptions(
                questionEngineModules,
                referenceId
              )}
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: referenceId ? base.borderColor : "red",
                }),
                menu: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </div>
        </GridItem>
        <GridItem
          xs={2}
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              margin: 5,
              bottom: 5,
            }}
          >
            <NumberFieldComponent currentValue={targetValue} fullWidth />
          </div>
        </GridItem>
      </GridContainer>
    );
  };

  return (
    <Form>
      <GridContainer justify="center">
        <GridItem xs={12} md={8} lg={6}>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <Card>
                <CardHeader color="info" text>
                  <GridContainer>
                    <GridItem xs={8}>
                      <CardText color="info">
                        <CardTitle>
                          {t("so.counterReports.forms.createUpdate.mainConfig")}
                        </CardTitle>
                      </CardText>
                    </GridItem>
                    <GridItem xs={4}>
                      <ButtonIcon
                        icon={SaveAlt}
                        label={
                          "so.counterReports.forms.createUpdate.buttons.save"
                        }
                        color={"success"}
                        block={true}
                        onClick={() => {
                          submitForm();
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12}>
                      <Field
                        name="name"
                        render={({ field }) => {
                          return (
                            <TextField
                              {...field}
                              id="name"
                              helperText={
                                hasError(errors, "name")
                                  ? getErrorLabel(
                                      t,
                                      "so.counterReports.forms.createUpdate.fields",
                                      "name",
                                      errors
                                    )
                                  : t(
                                      "so.counterReports.forms.createUpdate.fields.name.helperText"
                                    )
                              }
                              label={t(
                                "so.counterReports.forms.createUpdate.fields.name.label"
                              )}
                              success={
                                !hasError(errors, "name") && touched.name
                              }
                              error={hasError(errors, "name")}
                              margin="normal"
                              type={"text"}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <FormControl className={classes.formControl}>
                        <FormLabel>
                          {t(
                            "so.counterReports.forms.createUpdate.fields.periodicity.label"
                          )}
                        </FormLabel>
                        <Select
                          inputId={"select-periodicity"}
                          placeholder={t(
                            "so.counterReports.forms.createUpdate.fields.periodicity.helperText"
                          )}
                          value={getSelectedPeriodicity(
                            values.periodicity.value
                          )}
                          onChange={(item) => {
                            setFieldValue("periodicity", item);
                          }}
                          options={formatPeriodicityOptions(
                            Object.keys(SO_COUNTER_REPORT_PERIODICITY)
                          )}
                          styles={{
                            menu: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <Datetime
                        name={"initialDate"}
                        timeFormat={false}
                        dateFormat={"DD-MM-YYYY"}
                        defaultValue={Moment(values.initialDate).format(
                          "DD-MM-YYYY"
                        )}
                        onChange={(e) => {
                          if (e) {
                            console.log(e);
                            setFieldValue("initialDate", e);
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <Datetime
                        name={"finalDate"}
                        timeFormat={false}
                        dateFormat={"DD-MM-YYYY"}
                        defaultValue={Moment(values.finalDate).format(
                          "DD-MM-YYYY"
                        )}
                        onChange={(e) => {
                          if (e) {
                            console.log(e);
                            setFieldValue("finalDate", e);
                          }
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={6}>
                      <h4>
                        {t("so.counterReports.forms.createUpdate.indicators")}
                      </h4>
                    </GridItem>
                    <GridItem
                      xs={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          bottom: 10,
                          right: 10,
                        }}
                      >
                        {indicatorConfigShow === false ? (
                          <React.Fragment>
                            <ButtonBase
                              onClick={() => {
                                setIndicatorConfigType("counter");
                                setIndicatorConfig({
                                  type: "VisitCounter",
                                  referenceId: null,
                                  targetValue: 0,
                                });
                                setIndicatorConfigMode("create");
                                setIndicatorConfigShow(!indicatorConfigShow);
                              }}
                            >
                              <Badge color={"info"}>
                                {t(
                                  "so.counterReports.forms.createUpdate.buttons.addIndicatorCounter"
                                )}
                              </Badge>
                            </ButtonBase>
                            <ButtonBase
                              onClick={() => {
                                const field = "field";
                                setIndicatorConfigType(field);
                                setIndicatorConfig({
                                  type: "FieldSum",
                                  referenceId: null,
                                  targetValue: 0,
                                });
                                setIndicatorConfigMode("create");
                                setIndicatorConfigShow(!indicatorConfigShow);
                              }}
                            >
                              <Badge color={"primary"}>
                                {t(
                                  "so.counterReports.forms.createUpdate.buttons.addIndicatorField"
                                )}
                              </Badge>
                            </ButtonBase>
                          </React.Fragment>
                        ) : (
                          []
                        )}
                        {indicatorConfigShow === true ? (
                          <React.Fragment>
                            <ButtonBase
                              onClick={() => {
                                setIndicatorConfigShow(!indicatorConfigShow);
                              }}
                            >
                              <Badge color={"danger"}>Cancelar</Badge>
                            </ButtonBase>
                            <ButtonBase
                              onClick={() => {
                                if (validIndicatorConfig(indicatorConfig)) {
                                  if (indicatorConfigMode === "create") {
                                    setFieldValue("indicators", [
                                      ...values.indicators,
                                      indicatorConfig,
                                    ]);
                                  } else {
                                    setFieldValue(
                                      "indicators",
                                      values.indicators.map(
                                        (indicator, index) => {
                                          if (index === indicatorConfigIndex) {
                                            return indicatorConfig;
                                          }
                                          return indicator;
                                        }
                                      )
                                    );
                                  }
                                  setIndicatorConfigShow(!indicatorConfigShow);
                                }
                              }}
                            >
                              <Badge color={"success"}>
                                {t(
                                  "so.counterReports.forms.createUpdate.buttons.saveIndicator"
                                )}
                              </Badge>
                            </ButtonBase>
                          </React.Fragment>
                        ) : (
                          []
                        )}
                      </div>
                    </GridItem>
                  </GridContainer>

                  {indicatorConfigShow ? renderIndicationConfiguration() : []}
                  {values.indicators?.length || 0 ? (
                    <ReactTable
                      data={values.indicators}
                      columns={[
                        {
                          Header: (
                            <DivCenter>
                              {t(
                                "so.counterReports.forms.createUpdate.fields.indicators.fields.type.label"
                              )}
                            </DivCenter>
                          ),
                          Cell: ({ original }) => {
                            return t(
                              `so.counterReports.forms.createUpdate.fields.indicators.fields.indicatorTable.${original.type}`
                            );
                          },
                          filterable: false,
                          resizable: false,
                          sortable: false,
                          style: { textAlign: "center", whiteSpace: "unset" },
                          minWidth: 15,
                        },
                        {
                          Header: (
                            <DivCenter>
                              {t(
                                "so.counterReports.forms.createUpdate.fields.indicators.fields.title"
                              )}
                            </DivCenter>
                          ),
                          Cell: ({ original }) => {
                            const reference = getSelectedReferenceByType(
                              original.referenceId,
                              original.type
                            );

                            return original.title ? original.title : (reference?.label || "");
                          },
                          filterable: false,
                          resizable: false,
                          sortable: false,
                          style: { textAlign: "center", whiteSpace: "unset" },
                          minWidth: 15,
                        },
                        {
                          Header: (
                            <DivCenter>
                              {t(
                                "so.counterReports.forms.createUpdate.fields.indicators.fields.reference.label"
                              )}
                            </DivCenter>
                          ),
                          Cell: ({ original }) => {
                            const reference = getSelectedReferenceByType(
                              original.referenceId,
                              original.type
                            );
                            return reference?.label || "";
                          },
                          filterable: false,
                          sortable: false,
                          style: { textAlign: "center", whiteSpace: "unset" },
                          resizable: false,
                          minWidth: 20,
                        },
                        {
                          Header: (
                            <DivCenter>
                              {t(
                                "so.counterReports.forms.createUpdate.fields.indicators.fields.target.label"
                              )}
                            </DivCenter>
                          ),
                          accessor: "targetValue",
                          filterable: false,
                          sortable: false,
                          style: { textAlign: "center", whiteSpace: "unset" },
                          resizable: false,
                          minWidth: 20,
                        },
                        {
                          Header: (
                            <DivCenter>
                              {t(
                                "so.counterReports.forms.createUpdate.fields.indicators.fields.filters.title"
                              )}
                            </DivCenter>
                          ),
                          accessor: "filterElement",
                          Cell: ({ original, index }) => {

                            if(original.keyField || original.segment) {
                              let name = '';

                              if(original.keyField){
                                name =  original.keyField;
                              }else{
                                const segment = segments.find(segment => segment._id === original.segment);
                                name = segment ? segment?.name : '';
                              }
                              return (
                                <div>
                                  {name}
                                  <Button
                                  justIcon
                                  round
                                  simple
                                  color="info"
                                  onClick={() => {
                                    setOpen(true);
                                    setCurrentIndicator(original);
                                    setIndicatorConfigIndex(index);
                                  }}
                                >
                                  <Tooltip
                                    title={t(
                                      "so.counterReports.list.tooltip.editFilter"
                                    )}
                                  >
                                     <EditIcon />
                                  </Tooltip>
                                </Button>
                                </div>
                              );
                            } else {
                              return (
                                <Button
                                  justIcon
                                  round
                                  simple
                                  color="info"
                                  onClick={() => {
                                    setOpen(true);
                                    setCurrentIndicator(original);
                                    setIndicatorConfigIndex(index);
                                  }}
                                >
                                  <Tooltip
                                    title={t(
                                      "so.counterReports.list.tooltip.addFilter"
                                    )}
                                  >
                                    <AddIcon />
                                  </Tooltip>
                                </Button>
                              );
                            }
                          },
                          filterable: false,
                          sortable: false,
                          style: { textAlign: "center", whiteSpace: "unset" },
                          resizable: false,
                          minWidth: 20,
                        },
                        {
                          Header: <DivCenter></DivCenter>,
                          accessor: "actions",
                          resizable: false,
                          maxWidth: 200,
                          Cell: ({ original, index }) => {
                            if (false) {
                              return [];
                            }

                            return (
                              <div>
                                <Button
                                  justIcon
                                  round
                                  simple
                                  color="info"
                                  onClick={() => {
                                    setIndicatorConfig(original);
                                    setIndicatorConfigType(
                                      original.type ===
                                        IndicatorCounter.VisitCounter ||
                                        original.type ===
                                          IndicatorCounter.QuestionEngineModuleCounter
                                        ? "counter"
                                        : "field"
                                    );
                                    setIndicatorConfigIndex(index);
                                    setIndicatorConfigMode("edit");
                                    setIndicatorConfigShow(true);
                                  }}
                                >
                                  <Tooltip title={t("so.tooltip.edit")}>
                                    <EditIcon />
                                  </Tooltip>
                                </Button>
                                <ConfirmIconButton
                                  t={t}
                                  simple={true}
                                  baseTooltip={
                                    "processRelationship.tooltip.archived"
                                  }
                                  confirmTooltip={
                                    "processRelationship.tooltip.archiveConfirm"
                                  }
                                  baseIcon={DeleteForeverIcon}
                                  confirmDelay={3000}
                                  confirmOperation={() => {
                                    values.indicators.splice(index, 1);
                                    setIndicatorConfigShow(false);
                                    setFieldValue("indicators", [
                                      ...values.indicators,
                                    ]);
                                  }}
                                />
                              </div>
                            );
                          },
                        },
                      ]}
                      pageSize={values.indicators.length}
                      showPaginationTop={false}
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <h5>No se han agregado contadores</h5>
                    </div>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <AddFilterSoCounterModal 
          open={open}
          setOpen={setOpen}
          indicator={currentIndicator}
          indicatorConfigIndex={indicatorConfigIndex}
          setCurrentIndicator={setCurrentIndicator}
          segments={segments}
          visits={visits}
          indicators={values.indicators}
          setFieldValue={setFieldValue}
        />
      </GridContainer>
    </Form>
  );
};

CreateUpdateCounterReports.propTypes = {};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  radioControl: {
    width: "100%",
    textAlign: "left",
  },
  formLabel: {
    marginLeft: "15px",
  },
  radio: {
    "&$checked": {
      color: "#6ab3d4",
    },
  },
  checked: {},
}));

const CreateUpdateCounterReportsWF = withFormik({
  validationSchema: createUpdateCounterReportsValidationSchema,
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const { initialValues } = props;
    if (initialValues) {
      return {
        ...initialValues,
        periodicity: {
          label: "",
          value: initialValues.periodicity,
        },
      };
    } else {
      //default
      return {
        name: "",
        periodicity: {
          label: "",
          value: SO_COUNTER_REPORT_PERIODICITY.Monthly,
        },
        initialDate: Moment().startOf("year").toDate(),
        finalDate: Moment().endOf("year").toDate(),
        indicators: [],
      };
    }
  },
  handleSubmit: (values, { props }) => {
    props.submitSuccess({
      ...values,
      periodicity: values.periodicity.value,
    });

  },
  validateOnChange: false,
})(CreateUpdateCounterReports);

export default CreateUpdateCounterReportsWF;
