import React, {useMemo, useState} from "react";
import PropTypes from 'prop-types';

// custom components
import Button from "components/CustomButtons/Button.jsx";

// icons
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import LocationsMap from "../../containers/Locations/LocationsMap/locationsMap";

const getResultsLabel = function (page, pageSize, itemCount) {
  const initial = page * pageSize;
  const end = (initial + pageSize) > itemCount ? itemCount : initial + pageSize;
  return `${initial + 1} - ${end}`
};

const getMaxPage = function (pageSize, itemCount) {
  return itemCount ? Math.trunc(itemCount / pageSize) : 0;
};

const SmallPagination = ({page, pageSize, itemCount, onPageChange}) => {
  const [currentPage, setCurrentPage] = useState(page);
  const resultsLabel = useMemo(
    () => getResultsLabel(currentPage, pageSize, itemCount),
    [currentPage, pageSize, itemCount]
  );
  const maxPage = useMemo(
    () => getMaxPage(pageSize, itemCount),
    [pageSize, itemCount]
  );

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }}>
      <div>{resultsLabel}</div>
      <Button justIcon
              simple
              disabled={!currentPage}
              onClick={() => {
                setCurrentPage(currentPage - 1);
                onPageChange(currentPage - 1);
              }}
              color={'info'}>
        <ChevronLeft/>
      </Button>
      <Button justIcon
              simple
              disabled={currentPage >= maxPage}
              onClick={() => {
                setCurrentPage(currentPage + 1);
                onPageChange(currentPage + 1);
              }}
              color={'info'}>
        <ChevronRight/>
      </Button>
    </div>
  );
};

SmallPagination.propTypes = {
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  itemCount: PropTypes.number.isRequired
};

export default SmallPagination;
