import React from 'react';

import PropTypes from 'prop-types';

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

// @material-ui/icons
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LastUsersRegisterTable from '../../../tables/Dashboard/LastUsersRegisterTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styles } from 'ansi-colors';
import Button from "components/CustomButtons/Button.jsx";
import history from '../../../utils/history';
import { useTranslation } from 'react-i18next';


const LastUsers = ({ infoUsers }) => {
  const { loading, users } = infoUsers;
  const { t:tt } = useTranslation("general_adaptation");
    return (
      <Card style={style.cardHeight}>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <AccountBoxIcon />
          </CardIcon>
          <div style={style.left}>
            <h4 style={style.cardTitle}>{tt("lastusers.title")}</h4>
          </div>
          <div style={style.right}>
            <Button size="sm" color="beepquest" onClick={() => history.push(`/users`)}>
              {tt("reportcomplete")}
            </Button>
          </div>
        </CardHeader>
        <CardBody style={styles.scrollVertical}>
          {loading ? (
            <CircularProgress style={style.progress} />
          ) : (
            <LastUsersRegisterTable
              users={users.userMobiles ? users.userMobiles : []}
              pageSize={6}
            />
          )}
        </CardBody>
      </Card>
    );
};

LastUsers.protoTypes = {
  infoUsers: PropTypes.object.isRequired,
}

const style = {
  cardTitle: {
    color: "#3C4858"
  },
  progress:{
    marginLeft: '50%',
    marginRight: '50%'
  },
  cardHeight:{
    height: "430px"
  },
  scrollVertical: {
    overflowY: 'scroll'
  },
  left:{
    float: 'left'
  },
  right:{
    float: 'right'
  }
};

export default LastUsers;
