import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
import DivCenter from "../../components/styled-components/DivCenter";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Button from "../../components/CustomButtons/Button";
import moment from "moment";

const VisitSchedulingTabulatorTable = ({
  data,
  visitScheduling
}) => {
  const { t } = useTranslation("visitScheduling");
  const results = data.visitSchedulingReport;
  let periodCounter = 0;
  const resultsRanges = {
    resultsPassed: results.ranges.passed.map(item => ({...item, periodNumber: ++periodCounter})),
    resultsActive: results.ranges.active.map(item => ({...item, periodNumber: ++periodCounter})),
    resultsFuture: results.ranges.future.map(item => ({...item, periodNumber: ++periodCounter}))
  };
  const tableData = [].concat(...["active", "passed", "future"].map(item => {
    const section = item.charAt(0).toUpperCase() + item.slice(1);
    const values = resultsRanges[`results${section}`];
    if(values.length > 0) {
      return [
        {sectionTitle: item},
        ...values
      ];
    } else {
      return [];
    }
  }));

  return (
    <ReactTable
      data={tableData}
      columns={[
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("visitScheduling.tabulator.columns.periodCount")}
            </DivCenter>
          ),
          accessor: "name",
          Cell: ({ original }) => {
            if(original.sectionTitle) {
              return (
                <div style={styles.contentSection}>
                  {t(`visitScheduling.tabulator.sections.${original.sectionTitle}`)}
                </div>
              );
            }
            return (
              <div>
                { t("visitScheduling.tabulator.columns.periodValue", {
                  periodNumber: original.periodNumber,
                  periodTotal: periodCounter
                }) }
              </div>
            );
          },
          filterable: false,
          sortable: false,
          style: {
            textAlign: "center",
            whiteSpace: "unset",
            fontWeight: "400",
            ...styles.contentText
          }
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("visitScheduling.tabulator.columns.initialDate")}
            </DivCenter>
          ),
          accessor: "initialDate",
          Cell: ({ original }) => {
            if(original.sectionTitle) {
              return "";
            }
            return <DivCenter>
              {moment(original.initialDate).format('DD/MM/YYYY')}
            </DivCenter>;
          },
          filterable: false,
          sortable: false,
          style: {
            textAlign: "center",
            whiteSpace: "unset",
            fontWeight: "400",
            ...styles.contentText
          }
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("visitScheduling.tabulator.columns.finalDate")}
            </DivCenter>
          ),
          accessor: "finalDate",
          Cell: ({ original }) => {
            if(original.sectionTitle) {
              return "";
            }
            return <DivCenter>
              {moment(original.finalDate).format('DD/MM/YYYY')}
            </DivCenter>;
          },
          filterable: false,
          sortable: false,
          style: {
            textAlign: "center",
            whiteSpace: "unset",
            fontWeight: "400",
            ...styles.contentText
          }
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("visitScheduling.tabulator.columns.userCount")}
            </DivCenter>
          ),
          accessor: "userCount",
          filterable: false,
          sortable: false,
          style: {
            textAlign: "center",
            whiteSpace: "unset",
            fontWeight: "400",
            ...styles.contentText
          }
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("visitScheduling.tabulator.columns.progress")}
            </DivCenter>
          ),
          accessor: "progress",
          filterable: false,
          sortable: false,
          style: {
            textAlign: "center",
            whiteSpace: "unset",
            fontWeight: "400",
            ...styles.contentText
          }
        },
        {
          Header: <DivCenter/>,
          accessor: "actions",
          Cell: ({ original }) => {
            if(original.sectionTitle || original.progress === null) {
              return "";
            }
            return (
              <div>
                <Link
                  to={{
                    pathname: `/visitScheduling/${visitScheduling._id}/tabulatorDetail`,
                    state: {
                      visitScheduling: visitScheduling,
                      item: original._id,
                      visitUserSchedulings: results.visitUserSchedulings
                    }
                  }}>
                  <Button justIcon round simple color="info">
                    <VisibilityIcon />
                  </Button>
                </Link>
              </div>
            );
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" }
        }
      ]}
      pageSize={tableData.length}
      defaultPageSize={5}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
      resizable={false}
    />
  );
};

const styles = {
  headerTitle: {
    fontSize: "20px",
    whiteSpace: "unset",
    fontWeight: "500",
  },
  contentText: {
    fontSize: "17px",
  },
  contentSection: {
    fontSize: "20px",
    fontWeight: 'bold',
    padding: "20px"
  },
  regularButton: {
    color: "red",
    "&:hover": {
      backgroundColor: "green",
      color: "#6ab3d4",
    },
  },
};

export default VisitSchedulingTabulatorTable;
