import React, {useState, useEffect} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import Button from "components/Button";

const AddFilterSoCounterModal = ({
  setOpen,
  open,
  indicator,
  segments,
  visits,
  indicators,
  setFieldValue,
  setCurrentIndicator,
  indicatorConfigIndex
}) => {
  const { t } = useTranslation("so");
  const classes = useStyles();

  const [type, setType] = useState("segment");
  const [selectSegment, setSelectSegment] = useState(null);
  const [selectKeyField, setSelectKeyField] = useState(null);
  const [keyFields, setKeyFields] = useState([]);
  const [isValidModule, setIsValidModule] = useState(true);
  const [currentVisitId, setCurrentVisitId] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setCurrentIndicator(null);
    setSelectKeyField(null);
    setSelectSegment(null);
    setIsValidModule(true);
    setType('segment');
  };

  const validateKeyField = () => {
      if(isValidModule) {
        return (
          <Select
            inputId={"select-reference"}
            placeholder={t(
              "so.counterReports.forms.createUpdate.fields.indicators.fields.filters.type.options.keyField"
            )}
            value={selectKeyField}
            onChange={(option) => {
              setSelectKeyField(option);
            }}
            options={keyFields}
            styles={{
              control: (base) => ({
                ...base,
                marginRight: 10,
              }),
              menu: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
        );
      }else{
          return null;
      }

  };

  useEffect(() => {
    if (indicator?.fieldRefId || indicator?.type === "QuestionEngineModuleCounter") {
      visits.map((visit) => {
        if (
          visit.engines.some(
            (engine) => engine.moduleId === indicator.referenceId
          )
        ) {
          setKeyFields(visit.keys.map((key) => ({ label: key, value: key })));
        }

      });

      const visitFind = visits.find((visit) => {
        return visit.engines.some(
          (engine) => engine.moduleId === indicator.referenceId
        )}
      );
        
      setIsValidModule(visitFind ? true : false);

      setCurrentVisitId(visitFind ? visitFind._id : null);
      

    } else {
      const visit = visits.find(
        (visit) => visit._id === indicator?.referenceId
      );
      if (visit) {
        setKeyFields(visit.keys.map((key) => ({ label: key, value: key })));
        setCurrentVisitId(visit ? visit._id : null);
      }
    }

    if (indicator?.keyField) {
        setSelectKeyField({ value: indicator?.keyField, label: indicator?.keyField  });
        setType('keyField');
    }else if (indicator?.segment) {
        const segment = segments.find(segment => segment._id === indicator?.segment);
        setSelectSegment({ value: segment?._id, label: segment?.name });
    }


  }, [indicator, visits]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"xs"}
      fullWidth={true}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {t(
          "so.counterReports.forms.createUpdate.fields.indicators.fields.filters.title"
        )}
      </DialogTitle>
      <DialogContent dividers>
        <GridContainer>
          <GridItem xs={12}>
            <FormControl component="fieldset" className={classes.radioControl}>
              <RadioGroup
                row
                aria-label="indicatorType"
                name="indicatorType"
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                {isValidModule ? (
                  <FormControlLabel
                    id={"type-field-sum"}
                    className={classes.formLabel}
                    value={"keyField"}
                    control={
                      <Radio
                        color="default"
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: 300,
                          color: "#3C4858",
                        }}
                      >
                        {t(
                          `so.counterReports.forms.createUpdate.fields.indicators.fields.filters.type.options.keyField`
                        )}
                      </Typography>
                    }
                  />
                ) : null}

                <FormControlLabel
                  id={"type-field-average"}
                  className={classes.formLabel}
                  value={"segment"}
                  control={
                    <Radio
                      color="default"
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 300,
                        color: "#3C4858",
                      }}
                    >
                      {t(
                        `so.counterReports.forms.createUpdate.fields.indicators.fields.filters.type.options.segment`
                      )}
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>

            {type === "segment" ? (
              <Select
                inputId={"select-segment"}
                placeholder={t(
                  "so.counterReports.forms.createUpdate.fields.indicators.fields.filters.type.options.segment"
                )}
                value={selectSegment}
                onChange={(option) => {
                  setSelectSegment(option);
                }}
                options={segments.map((segment) => ({
                  value: segment._id,
                  label: segment.name,
                }))}
                styles={{
                  control: (base) => ({
                    ...base,
                    marginRight: 10,
                  }),
                  menu: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            ) : (
              validateKeyField()
            )}
          </GridItem>
          <GridItem xs={12} className={classes.marginTop}>
            <GridContainer justify="center">
              {indicator?.segment || indicator?.keyField ? (
                <GridItem xs={4}>
                  <Button
                    round
                    color="danger"
                    onClick={() => {
                      const newsIndicators = indicators.map(
                        (indicatorConfig, index) => {
                          if (index === indicatorConfigIndex) {
                            indicatorConfig.segment
                              ? delete indicatorConfig.segment
                              : delete indicatorConfig.keyField;
                          }

                          return indicatorConfig;
                        }
                      );

                      setFieldValue("indicators", newsIndicators);
                      handleClose();
                    }}
                  >
                    {t("so.counterReports.list.buttons.delete")}
                  </Button>
                </GridItem>
              ) : (
                []
              )}

              <GridItem xs={4}>
                <Button
                  round
                  color="info"
                  onClick={() => {
                    let newsIndicators = [];

                    if (indicator._id) {
                      newsIndicators = indicators.map((indicatorConfig) => {
                        if (indicatorConfig._id === indicator._id) {

                          if(type === 'keyField') {
                            indicatorConfig.keyField = selectKeyField?.value;
                            indicatorConfig.visitId = currentVisitId;
                            delete indicatorConfig.segment;
                          }else{
                            indicatorConfig.segment = selectSegment?.value;
                            delete indicatorConfig.keyField;
                            delete indicatorConfig.visitId;
                          }
                        }

                        return indicatorConfig;
                      });
                    }else{
                      newsIndicators = indicators;
                      if(type === 'keyField') {
                        newsIndicators[indicatorConfigIndex].keyField = selectKeyField?.value;
                        newsIndicators[indicatorConfigIndex].visitId = currentVisitId;
                        delete newsIndicators[indicatorConfigIndex].segment;
                      }else{
                        newsIndicators[indicatorConfigIndex].segment = selectSegment?.value;
                        delete newsIndicators[indicatorConfigIndex].keyField;
                        delete newsIndicators[indicatorConfigIndex].visitId;
                      }

                    }

                    //console.log(newsIndicators)

                    setFieldValue("indicators", newsIndicators);
                    handleClose();
                  }}
                >
                  {t("so.counterReports.list.buttons.accept")}
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
};
const useStyles = makeStyles((theme) => ({
    formControl: {
      width: "100%",
    },
    radioControl: {
      width: "100%",
      textAlign: "left",
      marginBottom: "10px"
    },
    formLabel: {
      marginLeft: "15px",
    },
    radio: {
      "&$checked": {
        color: "#6ab3d4",
      },
    },
    checked: {},
    dialogPaper: {
        minHeight: '30vh',
        maxHeight: '30vh',
    },
    right: {
        float: 'right',
        position: 'absolute',
        bottom: '5%',
        right: '5%'
    },
    marginTop: {
      marginTop: '20px'
    }
  }));

export default AddFilterSoCounterModal;
