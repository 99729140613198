import { gql } from 'apollo-boost';

export const CREATE_APPROVAL_LOG_MUTATION = gql`
  mutation createApprovalLog($data: ApprovalLogInput!) {
    createApprovalLog(data: $data) {
      _id
      app
    }
  }
`;

export const CREATE_ENGINE_ANSWER_UPDATE_LOG_MUTATION = gql`
  mutation createQuestionEngineAnswerUpdateLog($data: QuestionEngineAnswerUpdateLogCreateInput!) {
    createQuestionEngineAnswerUpdateLog(data: $data){
      _id
      app
      userId
      questionAnswersId
      key
      previousAnswer {
        __typename
        ... on OpenFieldAnswer {
          OpenFieldAnswer: value
        }
        ... on NumericFieldAnswer {
          NumericFieldAnswer: value
        }
        ... on DateFieldAnswer {
          DateFieldAnswer: value
        }
        ... on PhoneFieldAnswer {
          PhoneFieldAnswer: value
        }
        ... on ListUniqueFieldAnswer {
          ListUniqueFieldAnswer: value
        }
        ... on ListMultipleFieldAnswer {
          ListMultipleFieldAnswer: value
        }
        ... on EmailFieldAnswer {
          EmailFieldAnswer: value
        }
        ... on PasswordFieldAnswer {
          PasswordFieldAnswer: value
        }
        ... on OpenMultilineFieldAnswer {
          OpenMultilineFieldAnswer: value
        }
        ... on PhotoReportFieldAnswer {
          PhotoReportFieldAnswer: value {
            original
            i1280
            i240
            i320
            i480
            i640
            i960
          }
        }
        ... on MapFieldAnswer {
          longitude
          latitude
        }
        ... on SignatureFieldAnswer {
          SignatureFieldAnswer: value {
            original
            i1280
            i240
            i320
            i480
            i640
            i960
          }
        }
        ... on CodeBarFieldAnswer {
          CodeBarFieldAnswer: value
        }
        ... on MassiveListUniqueFieldAnswer {
          MassiveListUniqueFieldAnswer: value
        }
        ... on MassiveListMultipleFieldAnswer {
          MassiveListMultipleFieldAnswer: value
        }
        ... on ChecklistFieldAnswer {
          ChecklistFieldAnswer: id
          comment
          email
          photos {
            original
            i1280
            i240
            i320
            i480
            i640
            i960
          }
          signature {
            original
            i1280
            i240
            i320
            i480
            i640
            i960
          }
        }
        ... on TokenFieldAnswer {
          TokenFieldAnswer: value
        }
        ... on TableFieldAnswer {
          rows {
            answers {
              key
              answer {
                __typename
                ... on OpenSubFieldAnswer {
                  OpenSubFieldAnswer: value
                }
                ... on NumericSubFieldAnswer {
                  NumericSubFieldAnswer: value
                }
                ... on DateSubFieldAnswer {
                  DateSubFieldAnswer: value
                }
                ... on PhoneSubFieldAnswer {
                  PhoneSubFieldAnswer: value
                }
                ... on MassiveListUniqueSubFieldAnswer {
                  MassiveListUniqueSubFieldAnswer: value
                }
              }
            }
          }
        }
        ... on GeofenceFieldAnswer {
          GeofenceFieldAnswer: value
        }
      }
      created
    }
  }
`;

export const CREATE_COMBINED_SCORES_REPORT_MUTATION = gql`
  mutation createCombinedScoresReport($data: ChecklistRepordInput!) {
    createCombinedScoresReport(data: $data)
  }
`;

export const CREATE_KEYFIELD_DASHBOARD_REPORT_MUTATION = gql`
  mutation createKeyfieldDashboardReport($data: ChecklistRepordInput!) {
    createKeyfieldDashboardReport(data: $data)
  }
`;

export const CREATE_AVERAGE_ANALYSIS_REPORT_MUTATION = gql`
  mutation createAverageAnalysisReport($data: ChecklistRepordInput!) {
    createAverageAnalysisReport(data: $data)
  }
`;

export const CREATE_RANKINGS_REPORT_MUTATION = gql`
  mutation createRankingsReport($data: ChecklistRepordInput!) {
    createRankingsReport(data: $data)
  }
`;

export const CREATE_CATEGORY_DASHBOARD_REPORT_MUTATION = gql`
  mutation createCategoryDashboardReport($data: ChecklistRepordInput!) {
    createCategoryDashboardReport(data: $data)
  }
`;

export const CREATE_OPPORTUNITY_AREAS_REPORT_MUTATION = gql`
  mutation createOpportunityAreasReport($data: ChecklistRepordInput!) {
    createOpportunityAreasReport(data: $data)
  }
`;

export const CREATE_GENERAL_EXECUTION_REPORT_MUTATION = gql`
  mutation createGeneralExecutionReport($data: ChecklistRepordInput!) {
    createGeneralExecutionReport(data: $data)
  }
`;

export const CREATE_ACTIVE_USER_REPORT_MUTATION = gql`
  mutation createActiveUserReport($data: ChecklistRepordInput!) {
    createActiveUserReport(data: $data)
  }
`;

export const CREATE_VISITING_TIME_REPORT_MUTATION = gql`
  mutation createVisitingTimeReport($data: ChecklistRepordInput!) {
    createVisitingTimeReport(data: $data)
  }
`;

export const CREATE_GALLERY_VISOR_REPORT_MUTATION = gql`
  mutation createGalleryVisorReport($data: ChecklistRepordInput!) {
    createGalleryVisorReport(data: $data)
  }
`;

export const CREATE_COMBINED_SCORES_XLS_REPORT_MUTATION = gql`
  mutation createCombinedScoresXLSReport($data: ChecklistRepordInput!) {
    createCombinedScoresXLSReport(data: $data)
  }
`;

export const CREATE_AVERAGE_ANALYSIS_XLS_REPORT_MUTATION = gql`
  mutation createAverageAnalysisXLSReport($data: ChecklistRepordInput!) {
    createAverageAnalysisXLSReport(data: $data)
  }
`;

export const CREATE_RANKINGS_XLS_REPORT_MUTATION = gql`
  mutation createRankingsXLSReport($data: ChecklistRepordInput!) {
    createRankingsXLSReport(data: $data)
  }
`;

export const CREATE_CATEGORY_DASHBOARD_XLS_REPORT_MUTATION = gql`
  mutation createCategoryDashboardXLSReport($data: ChecklistRepordInput!) {
    createCategoryDashboardXLSReport(data: $data)
  }
`;

export const CREATE_OPPORTUNITY_AREAS_XLS_REPORT_MUTATION = gql`
  mutation createOpportunityAreasXLSReport($data: ChecklistRepordInput!) {
    createOpportunityAreasXLSReport(data: $data)
  }
`;

export const CREATE_GENERAL_EXECUTION_XLS_REPORT_MUTATION = gql`
  mutation createGeneralExectuionXLSReport($data: ChecklistRepordInput!) {
    createGeneralExectuionXLSReport(data: $data)
  }
`;

export const CREATE_ACTIVE_USER_XLS_REPORT_MUTATION = gql`
  mutation createActiveUserXLSReport($data: ChecklistRepordInput!) {
    createActiveUserXLSReport(data: $data)
  }
`;
