import React from 'react';
import PropTypes from 'prop-types';

// own components
import EngineAnswerFieldFooter from "../EngineAnswerFieldFooter";
import EngineAnswerFieldEditButton from "../EngineAnswerFieldEditButton";
import EngineAnswerFieldChangesLogView from "../EngineAnswerFieldChangesLogView";

// custom components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from "components/Card/CardFooter.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DivCenter from "components/styled-components/DivCenter";

// core components
import Collapse from "@material-ui/core/Collapse";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import withStyles from "@material-ui/core/styles/withStyles";

const ChecklistFieldAnswerView = ({answerData, editable, changeToEditable,
                                    toggleLogChangesView, logChangesViewMode, checklist,
                                    openImageLightBox, classes, questionAnswerUpdateLogs}) => {

  const checklistOption = checklist.options.find((option) => {
    return option._id === answerData.answer.ChecklistFieldAnswer;
  });

  const checkListImages = (answerData.answer.photos || []).concat(answerData.answer.signature || []);
  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem xs={editable ? 10 : 12}>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    alt={`checkOptionImage`}
                    src={checklistOption.image.i320}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <DivCenter>
                    {checklistOption
                      ? checklistOption.name
                      : answerData.answer.id}
                  </DivCenter>
                </ListItemText>
              </ListItem>
            </List>
          </GridItem>

          {editable ? (
            <GridItem xs={2}>
              <EngineAnswerFieldEditButton
                changeToEditable={() => changeToEditable(answerData)}
                toggleLogChangesView={() => toggleLogChangesView(answerData)}
              />
            </GridItem>
          ) : (
            []
          )}
          <GridItem xs={12}>
            {answerData.answer.comment ? (
              <React.Fragment>
                <h6>{`Comentario`}</h6>
                <p>{answerData.answer.comment}</p>
              </React.Fragment>
            ) : (
              []
            )}
            {answerData.answer.email ? (
              <React.Fragment>
                <h6>{`Email`}</h6>
                <p>{answerData.answer.email}</p>
              </React.Fragment>
            ) : (
              []
            )}
          </GridItem>
          <GridItem xs={12}>
            {checkListImages.length > 0 ? (
              <div className={classes.root}>
                <GridList
                  cellHeight={150}
                  className={classes.gridList}
                  cols={
                    checkListImages.length >= 3 ? 3 : checkListImages.length
                  }
                >
                  {checkListImages.map((tile, index) => (
                    <GridListTile
                      key={index}
                      cols={1}
                      onClick={() => {
                        openImageLightBox({
                          images: checkListImages.map((img) => img.original),
                          currentImage: index,
                        });
                      }}
                    >
                      <img 
                        src={tile.i320} 
                        alt={tile.i320} 
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src=tile.original;
                        }} 
                      />
                    </GridListTile>
                  ))}
                </GridList>
              </div>
            ) : (
              []
            )}
          </GridItem>
          <Collapse in={logChangesViewMode}>
            <EngineAnswerFieldChangesLogView
              checklist={checklist}
              questionAnswerUpdateLogs={questionAnswerUpdateLogs}
            />
          </Collapse>
        </GridContainer>
      </CardBody>
      <CardFooter>
        <EngineAnswerFieldFooter answerData={answerData} />
      </CardFooter>
    </Card>
  );
};

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 160,
  },
});

ChecklistFieldAnswerView.propTypes = {
  answerData: PropTypes.object.isRequired,
  checklist: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChecklistFieldAnswerView);
