import ReactTable from "react-table";
import DivCenter from "../../components/styled-components/DivCenter";
import React from "react";
import {useTranslation} from "react-i18next";
import {Tooltip} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import {Link} from "react-router-dom";
import TableChartIcon from "@material-ui/icons/TableChart";
import EditIcon from "@material-ui/icons/Edit";
import ConfirmIconButton from "../../components/ConfirmIconButton/ConfirmIconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import useGlobal from '../../store';
import auth from '../../utils/auth';

const VisitSchedulingTable = ({
  data,
  archiveVisitScheduling
}) => {
  const { t } = useTranslation("visitScheduling");

  const [session] = useGlobal((state) => state.session);

  const hasPermission = (permission) => {
    if (session.user && !auth.hasPermission(permission, session.permissions)) {
      return false;
    } else {
      return true;
    }
  };

  const getActions = (original) => {
    return (
      <div>
        <Link
          to={{
            pathname: `/visitScheduling/${original._id}/tabulator`,
            state: {
              item: original,
            },
          }}
        >
          <Button justIcon round simple color="info">
            <Tooltip title={t("visitScheduling.list.tooltip.tabulator")}>
              <TableChartIcon />
            </Tooltip>
          </Button>
        </Link>
        {hasPermission("schedulingVisitEdit") ? (
          <Link
            to={{
              pathname: `/visitScheduling/${original._id}/edit`,
              state: {
                visitScheduling: original,
              },
            }}
          >
            <Button justIcon round simple color="info">
              <Tooltip title={t("visitScheduling.list.tooltip.edit")}>
                <EditIcon />
              </Tooltip>
            </Button>
          </Link>
        ) : (
          ""
        )}
        {hasPermission("schedulingVisitArchived") ? (
          <ConfirmIconButton
            t={t}
            simple={true}
            baseTooltip={"visitScheduling.list.tooltip.archived"}
            confirmTooltip={"visitScheduling.list.tooltip.archiveConfirm"}
            baseIcon={DeleteForeverIcon}
            confirmDelay={3000}
            confirmOperation={() => {
              archiveVisitScheduling({
                variables: {
                  where: {
                    _id: original._id,
                  },
                },
              });
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }

  return (
    <ReactTable
      data={data}
      columns={[
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("visitScheduling.list.columns.name")}
            </DivCenter>
          ),
          accessor: "name",
          filterable: false,
          sortable: false,
          style: {
            textAlign: "center",
            whiteSpace: "unset",
            fontWeight: "400",
            ...styles.contentText
          }
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("visitScheduling.list.columns.visit")}
            </DivCenter>
          ),
          accessor: "visitRef.name",
          filterable: false,
          sortable: false,
          style: {
            textAlign: "center",
            whiteSpace: "unset",
            ...styles.contentText
          }
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              {t("visitScheduling.list.columns.whoResolves")}
            </DivCenter>
          ),
          accessor: "whoResolves",
          Cell: ({original}) => {
            return (
              <DivCenter>
                {t(`visitScheduling.forms.createUpdate.fields.whoResolves.options.${original.whoResolves}`)}
              </DivCenter>
            );
          },
          filterable: false,
          sortable: false,
          style: {
            textAlign: "center",
            whiteSpace: "unset",
            ...styles.contentText
          }
        },
        {
          Header: <DivCenter/>,
          accessor: "actions",
          Cell: ({ original }) => {
            return getActions(original);
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "right" },
        }
      ]}
      defaultPageSize={15}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
      resizable={false}
      />
  );
};

const styles = {
  headerTitle: {
    fontSize: "20px",
    whiteSpace: "unset",
    fontWeight: "500",
  },
  contentText: {
    fontSize: "17px",
  },
  regularButton: {
    color: "red",
    "&:hover": {
      backgroundColor: "green",
      color: "#6ab3d4",
    },
  },
};

export default VisitSchedulingTable;
