import React from "react";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import { CircularProgress } from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import Button from "components/CustomButtons/Button.jsx";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ChecklistAnalisysTable from "../../tables/Reports/ChecklistAnalisysTable";
import history from '../../utils/history';
import TablePagination from '../../pagination/table/TablePagination';
import DatesFiltersForm from '../../forms/reports/ReportFilterForm';
import auth from "../../utils/auth";
import useGlobal from "../../store";
import { useState } from "react";
import ReportFiltersModal from "../../modals/ReportsFiltersModal";
import ReportsFilterForm from '../../forms/reports/ReportFilterForm';

const ChecklistAnalisysVisitReportView = ({ 
  checklistAnalisys, 
  submitSuccess, 
  paginationAction, 
  checklistAnalisysVisits, 
  filterChanged, 
  filters,
  generateReport, 
}) => {
  const { t } = useTranslation("checklistAnalisys");
  const { loading, data } = checklistAnalisys;

  const [session] = useGlobal((state) => state.session);
  const [modalStatus, setModalStatus] = useState({
    open: false,
    reportConfig: {}
  });

  const hasPermission = (permission) => {
    if ( session.user && !auth.hasPermission(permission, session.permissions)) return false;
    return true;
  }; 
console.log(filters)
  return (
    <Card>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <AssignmentTurnedInIcon />
        </CardIcon>
        <div style={style.left}>
          <h4 style={style.cardTitle}>{t("checklistAnalisys.title")}</h4>
        </div>
        <div style={style.right}>
          <Button
            size="sm"
            color="info"
            disabled={!hasPermission('reportCenterCreated')}
            onClick={() => history.push(`/new/checklistAnalisysVisit`)}
          >
            {t("checklistAnalisys.newReport")}
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <ReportsFilterForm
          initialValues={{
            ...filters.where,
            checkInDate_gt: filters.where.startDateCreated,
            checkInDate_lt: filters.where.endDateCreated,
          }}
          submitSuccess={filterChanged}
        />
        {/* <DatesFiltersForm
          initialValues={{
            ...filters,
            checkInDate_gt: filters.where.startDateCreated,
            checkInDate_lt: filters.where.endDateCreated,
          }}
          submitSuccess={filterChanged}
        /> */}
        <TablePagination
          data={
            data.getAllChecklistAnalisysVisitReport
              ? data.getAllChecklistAnalisysVisitReport
              : []
          }
          paginationAction={paginationAction}
          filter={checklistAnalisysVisits}
        />
        {loading ? (
          <div style={style.progressCnt}>
            <CircularProgress style={style.progress} />
          </div>
        ) : (
          <ChecklistAnalisysTable
            data={
              data.getAllChecklistAnalisysVisitReport
                ? data.getAllChecklistAnalisysVisitReport
                : []
            }
            submitSuccess={submitSuccess}
            setModalStatus={setModalStatus}
          />
        )}

        <ReportFiltersModal 
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          generateReport={generateReport}
        />
      </CardBody>
    </Card>
  );
};

ChecklistAnalisysVisitReportView.propTypes = {
    checklistAnalisys: PropTypes.object.isRequired,
    submitSuccess: PropTypes.func.isRequired,
    paginationAction: PropTypes.func.isRequired,
    checklistAnalisysVisits: PropTypes.object.isRequired,
};

const style = {
  cardTitle: {
    color: "#3C4858"
  },
  progress: {
    margin: '16px'
  },
  progressCnt:{
    width: '100px !important',
    marginLeft: '50%',
    marginRight: '50%'
  },
  cardHeight: {
    height: "430px"
  },
  left: {
    float: "left"
  },
  right: {
    float: "right"
  }
};

export default ChecklistAnalisysVisitReportView;
