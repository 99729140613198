import React from 'react';
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";

// Own components
import TicketListTable from "../../tables/Tickets/TicketListTable";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.jsx";
import Position from "components/Position";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Badge from "../../components/Badge/Badge";
import AddIcon from '@material-ui/icons/Add';

import {NavLink} from "react-router-dom";
import UserApprovalItemsTable from "../../tables/Visits/UserApprovalItemsTable";

const UserApprovalItemsView = ({classes, approvalItems}) => {
  return (
    <div>
      {/*TICKETS TABLE*/}
      <React.Fragment>
        <Card>
          <CardHeader color="info" text>
            <GridContainer>
              <GridItem xs={6}>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>Pendientes de Aprobación para tu usuario</h4>
                </CardText>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <UserApprovalItemsTable data={approvalItems} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </React.Fragment>
    </div>

  );
};

UserApprovalItemsView.propTypes = {
  approvalItems: PropTypes.array.isRequired
};

const styles = theme => ({
  center: {
    textAlign: "center"
  }
});

export default withStyles(styles)(UserApprovalItemsView);
