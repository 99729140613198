import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

const ObjectEditConfirmationModal = ({ open, setOpen, objectChangedValues, setActiveStep }) => {
//   const { open, setOpen, frequency, keyfield, operation } = props;
  const { t } = useTranslation('histogram');
  const handleClose = () => {
    setOpen(false);
  };
  const handleAccept = () => {
    setOpen(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Typography id="modal-modal-title" variant="h6" component="h2">
            Se han modificado <b className="MuiButton-textSecondary">{objectChangedValues.length}</b> elementos.
        </Typography>
        <p>Mostrando primeros 50 resultados modificados</p>

        <hr />
        <TableContainer component={Paper}>
          <Table aria-label="Objects table">
            <TableHead>
              <TableRow>
                <TableCell>Elemento</TableCell>
                <TableCell align="right">Usuario anterior</TableCell>
                <TableCell align="right">Nuevo usuario</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {objectChangedValues.slice(0, 50).map(({
                elementId,
                elementName,
                userEmail,
                oldUserEmail
            }) => (
                <TableRow key={elementId}>
                  <TableCell component="th" scope="row">
                    {elementName}
                  </TableCell>
                  <TableCell align="right">{oldUserEmail}</TableCell>
                  <TableCell align="right">{userEmail}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('histogram.close')}
        </Button>
        <Button onClick={(handleAccept)} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ObjectEditConfirmationModal;
