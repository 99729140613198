import React from 'react';
import PropTypes from 'prop-types';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import withStyles from "@material-ui/core/styles/withStyles";

function ReportNotifierView({
  classes,
  lastInsert,
  reports,
}) {
  const [open, setOpen] = React.useState(false);

  // On reports change, open the drawer
  React.useEffect(() => {
    if(reports.length > 0){
      setOpen(true);
    }
  }, [lastInsert]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const reportItems = reports.map(({date, label, url}, idx) => (
    <a href={url} target="_blank" key={idx}>
      <ListItem button key={idx}>
        <ListItemIcon><DescriptionIcon /></ListItemIcon>
        <ListItemText primary={label} secondary={date}/>
        <GetAppIcon/>
      </ListItem>
    </a>
  ));

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
    >
      <div
        className={classes.container}
      >
        <h3>Sus reportes generados</h3> 
        <List
          className={classes.list}
        >
          {reportItems}
        </List>
      </div>
    </Drawer>
  );
};



ReportNotifierView.propTypes = {
  lastInsert: PropTypes.number.isRequired,
  reports: PropTypes.array.isRequired,
};

const styles = theme => ({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "50px",
  },
  list: {
    width: "800px",
    padding: "10px 40px"
  },
});

export default withStyles(styles)(ReportNotifierView);
