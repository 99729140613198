import React from 'react';
import TechManualView from '../../Views/Tutorials/TechManualView';
import { manualsMock } from './constants/mocks';

const TechManual = () => {
    let manuals = manualsMock;
    return (
        <TechManualView 
            manuals={manuals}
        />
    )
};

export default TechManual;
