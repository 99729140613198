import gql from "graphql-tag";

export const USER_MOBILES_FEED = gql`
  query UserMobiles(
    $where: UserMobileWhereInput!
    $first: Int
    $skip: Int
    $orderBy: UserMobileOrderByInput
  ) {
    userMobiles(where: $where, first: $first, skip: $skip, orderBy: $orderBy) {
      _id
      firstName
      lastName
      email
      created
      archived
    }
  }
`;

export const ALL_EVENTS_FEED = gql`
  query AllEvent(
    $where: EventWhereInput!
    $first: Int
    $skip: Int
    $orderBy: EventOrderByInput
  ) {
    allEvent(where: $where, first: $first, skip: $skip, orderBy: $orderBy) {
      elementName
      userName
      client
      reference
      feature
      event
      userEmail
      created {
        value
      }
    }
  }
`;

export const ALL_REPORTS_FEED = gql`
  query AllReports(
    $where: ReportWhereInput!
    $first: Int
    $skip: Int
    $orderBy: ReportOrderByInput
  ) {
    allReports(where: $where, first: $first, skip: $skip, orderBy: $orderBy) {
      app
      status
      name
      user {
        email
      }
      url
      urls
      action
      type
      query
      completionDate
      created
    }
  }
`;

export const INDEXES_DASHBOARD_FEED = gql`
  query IndexesDashboard($where: ReportWhereInput!) {
    indexesDashboard(where: $where) {
      users
      screens
      modules
      reports
    }
  }
`;

export const INDEXES_REPORT_FEED = gql`
  query IndexesReport($where: ReportWhereInput!) {
    indexesReport(where: $where) {
      manual
      programmable
      automatic
    }
  }
`;

export const MESSAGES_FEED = gql`
  query(
  $where: MessageWhereInput 
  $first: Int 
  $skip: Int){
  getMessages(
    where: $where 
    first: $first 
    skip: $skip){
    app
    to
    title
    subject
    contentHtml
    expirationDate
    created
  }  	
}
`;