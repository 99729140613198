import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import Select from 'react-select';

import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import RadioButton from "components/styled/RadioButton";

const radioSelection = {
  all: 0,
  selectedItems: 1
};

function RadioWithList({ classes, isMulti, label, defaultLabel, listOptions, onFilterChange }) {
  const [config, setConfig] = React.useState(radioSelection.all);
  const [selectedItems, setSelectedItems] = React.useState([]);

  const handleRadioChange = React.useCallback(radioOption => {
    setConfig(radioOption);
    if(radioOption === radioSelection.all) onFilterChange(null);
    else onFilterChange(selectedItems);
  }, [selectedItems, setConfig, onFilterChange]);

  const handleSelectedItemsChange = React.useCallback(selectedOption => {
    setSelectedItems(selectedOption);
    onFilterChange(selectedOption);
  }, [setSelectedItems, onFilterChange]);

  return (
    <div>
      <div>
        <RadioButton
          checked={config === radioSelection.all}
          onChange={() => handleRadioChange(radioSelection.all)}
          value={radioSelection.all}
          label={defaultLabel || "Incluir todos"}
        />
        <RadioButton
          checked={config === radioSelection.selectedItems}
          onChange={() => handleRadioChange(radioSelection.selectedItems)}
          value={radioSelection.selectedItems}
          label={"Agregar " + label}
        />
      </div>
      {config === radioSelection.selectedItems &&
        <div>
          <Select
            placeholder={"Buscar " + label}
            value={selectedItems}
            onChange={handleSelectedItemsChange}
            options={listOptions}
            isMulti={isMulti}
          />
        </div>
      }
    </div>
  );
}

RadioWithList.propTypes = {
  isMulti: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  defaultLabel: PropTypes.string,
  placeholder: PropTypes.string,
  listOptions: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

const styles = theme => ({
});

export default withStyles(styles)(RadioWithList);
