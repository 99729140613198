import React from "react";
import {
  VISIT_ITEMS,
  VISIT_ANSWER_ITEMS,
  VISIT_ANSWER_COUNT,
  USER_SEGMENT_ITEMS,
} from "./gql/Queries";
import LoadingProcessView from "../../Views/LoadingProcessView";
import useGlobal from "../../store";
import { useQuery } from "react-apollo-hooks";
import Moment from 'moment';
import { getOperationsFromColumns, getKeyfields, parseVisitAnswers, classifyAndFilter, reduceToSelectedOperation, filterVisitAnswerByUsersSegment } from "../../utils/analytics/dataManipulation";
import HistogramView from "../../Views/Visits/HistogramView";
import { SEGMENTS_FEED } from "../Reports/gql/Queries";

const HistogramVisit = ({ match }) => {
  const [session] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );
  const [filters, filtersActions] = useGlobal(
    (state) => state.filters,
    (actions) => actions.filters
  );
  
  const [currentKeyfield, setCurrentKeyField] = React.useState(null);
  const [segment, setSegment] = React.useState({value: "Todos", label: "Todos"});

  const handleSegmentChange = React.useCallback((option) => {
    setSegment(option);
  }, [setSegment]);


  const visitID =
    match.params.idVisit === ":idVisit" ? null : match.params.idVisit;

  const {
    data: dataUserSegment, 
    loading: loadingUserSegment,
  } = useQuery(USER_SEGMENT_ITEMS, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : '',
        segment: segment.value
      }
    },
    skip: segment.value === "Todos",
    fetchPolicy: 'network-only'
  });

  const { data: dataSegments, loading: loadingSegments } = useQuery(SEGMENTS_FEED, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : "",
        archived: false
      }
    }
  })

  const { data, loading } = useQuery(VISIT_ITEMS, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : "",
        hasChecklistConfiguration: true,
        isArchived: false,
      },
    },
    fetchPolicy: "network-only",
  });

  const { data: dataVisitAnswers, loading: loadingVisitAnswers } = useQuery(
    VISIT_ANSWER_ITEMS,
    {
      variables: {
        where: {
          userId: session.user._id,
          app: session.appId,
          visitId: filters.selectedVisitID ? filters.selectedVisitID.value : "",
          checkInDate_gt: Moment(filters.range.startDate)
            .startOf("day")
            .format(),
          checkInDate_lte: filters.range.endDate,
          approvalStatus_not_in: ["Rejected", "Excluded"],
          hasCheckOutDate: true,
        },
      },
      skip: !filters.selectedVisitID,
      fetchPolicy: "network-only",
      errorPolicy: "all",
    }
  );

  const {
    data: dataVisitAnswersOpen,
    loading: loadingVisitAnswersOpen,
  } = useQuery(VISIT_ANSWER_COUNT, {
    variables: {
      where: {
        userId: session.user._id,
        app: session.appId,
        visitId: filters.selectedVisitID ? filters.selectedVisitID.value : "",
        checkInDate_gt: Moment(filters.range.startDate).startOf("day").format(),
        checkInDate_lte: filters.range.endDate,
        approvalStatus_not_in: ["Rejected", "Excluded"],
        hasCheckOutDate: false,
      },
    },
    skip: !filters.selectedVisitID,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const {
    data: dataVisitAnswersClosed,
    loading: loadingVisitAnswersClosed,
  } = useQuery(VISIT_ANSWER_COUNT, {
    variables: {
      where: {
        userId: session.user._id,
        app: session.appId,
        visitId: filters.selectedVisitID ? filters.selectedVisitID.value : "",
        checkInDate_gt: Moment(filters.range.startDate).startOf("day").format(),
        checkInDate_lte: filters.range.endDate,
        approvalStatus_not_in: ["Rejected", "Excluded"],
        hasCheckOutDate: true,
      },
    },
    skip: !filters.selectedVisitID,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });



  const filteredDataVisitAnswers = dataVisitAnswers && dataVisitAnswers.visitAnswers ? 
    dataVisitAnswers.visitAnswers.filter(visitAnswer => visitAnswer) : [];

  // If the store is empty or different, use the visitID from the route params
  if (
    data.visits &&
    ((!!visitID && !filters.selectedVisitID) ||
      (!!visitID && visitID !== filters.selectedVisitID.value))
  ) {

    const option = data.visits.find((visit) => visitID === visit._id);
    filtersActions.setVisitID({ label: option.name, value: option._id });
  }

  // Select by default the first visit ID
  if (
    !visitID &&
    !filters.selectedVisitID &&
    data.visits &&
    data.visits.length > 0
  ) {
    const option = data.visits[0];
    filtersActions.setVisitID({ label: option.name, value: option._id });
  }

  let closedCount = null;
  if (!loadingVisitAnswersClosed && dataVisitAnswersClosed) {
    const { visitAnswerConnection } = dataVisitAnswersClosed;
    if (visitAnswerConnection) {
      const { aggregate } = visitAnswerConnection;
      closedCount = aggregate.count;
    }
  } 

  if (
    loading ||
    loadingVisitAnswers ||
    loadingVisitAnswersClosed ||
    loadingVisitAnswersOpen ||
    loadingSegments ||
    loadingUserSegment
  ) {
    return <LoadingProcessView closedCount={closedCount} />;
  } else {
    const selecteVisit = data.visits
      ? data.visits.find(
          (visit) =>
            filters.selectedVisitID &&
            filters.selectedVisitID.value === visit._id
        )
      : null;

    const operators =
      selecteVisit &&
      selecteVisit.checkListConfiguration &&
      selecteVisit.checkListConfiguration.columns
        ? getOperationsFromColumns(selecteVisit.checkListConfiguration.columns)
        : [];

    const selectedOperation = operators.find(
      (operator) =>
        filters.selectedOperation &&
        filters.selectedOperation.value === operator.id
    );

    // Select by default the first option
    if (!selectedOperation && operators && operators.length > 0) {
      const option = operators[0];
      filtersActions.setOperation({ label: option.name, value: option.id });
    }

    const visits = data.visits
      ? data.visits.map(({ _id, name }) => ({ id: _id, name }))
      : [];
    const keyfields = getKeyfields(selecteVisit);
    const [activeCount, closedVisits] = classifyAndFilter(
      filteredDataVisitAnswers
    );
    const parsedClosed = closedVisits
      ? parseVisitAnswers(closedVisits, keyfields)
      : [];

    const reducedVisitAnswers = reduceToSelectedOperation(parsedClosed, selectedOperation);

    const segmentOptions = [ 
      {value: "Todos", label: "Todos"},
      ...dataSegments.segments
      .map((item) => ({
        value: item._id,
        label: item.name
      }))
    ];

    const userSegment = dataUserSegment && dataUserSegment.userSegment ? dataUserSegment.userSegment : [];

    const visitAnswersFilter = filterVisitAnswerByUsersSegment(reducedVisitAnswers, userSegment);

    return (
      <HistogramView
        activeVisitAnswer={{
          loadingActiveVisitanswers: loadingVisitAnswersOpen,
          dataOpenVisitanswer: dataVisitAnswersOpen,
        }}
        keyfields={keyfields}
        visits={visits}
        visitAnswers={{
          loadingVisitAnswersClosed: loadingVisitAnswers,
          dataVisitAnswer: parsedClosed,
        }}
        operators={operators}
        operation={selectedOperation}
        closedCount={closedCount}
        items={visitAnswersFilter}
        segment={segment}
        segments={segmentOptions}
        handleSegmentChange={handleSegmentChange}
        currentKeyfield={currentKeyfield}
        setCurrentKeyField={setCurrentKeyField}
      />
    );
  }

  
};

export default HistogramVisit;
