import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import history from '../../utils/history';
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Button from 'components/Button';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import FiltersSidebar from "components/FiltersSidebar/FiltersSidebar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import SendEmailButton from "components/Reports/SendEmailButton";
import DownloadButton from "components/Reports/DownloadButton";
import { groupByKeyfields, getChartData } from '../../utils/analytics/dataManipulation';
import LineChart from '../../components/Chart/LineChart';
import RelativeChangeTable from '../../tables/Visits/RelativeChangeTable';

import { getDefaultSubject, getDefaultBody } from '../../utils/emails/defaultContent';
import { REPORT_ACTIONS } from '../../constants';

const VisitsDashboardCategoryItemView = ({
  classes, 
  activeVisitAnswer, 
  category,
  createCategoryDashboardReport,
  createCategoryDashboardXLSReport,
  emailData,
  keyfields, 
  items, 
  visits, 
  visitAnswers, 
  operators,
  sendCategoryDashboardPDFReport,
  sendCategoryDashboardXLSReport,
  closedVisitAnswer,
}) => {
  const datesData = React.useRef(null);
  const averageRowData = React.useRef(null);
  const changeRowData = React.useRef(null);

  const [selectedKeyfieldOption, setSelectedKeyfieldOption] = React.useState({value: keyfields[0].id, label:keyfields[0].name});
  const [selectedItemOption, setSelectedItemOption] = React.useState(null);

  const { loadingVisitAnswersClosedCount, dataVisitanswerClosedCount } = closedVisitAnswer;

  let closedCount = null;
  if (!loadingVisitAnswersClosedCount && dataVisitanswerClosedCount) {
    const { visitAnswerConnection } = dataVisitanswerClosedCount;
    if (visitAnswerConnection) {
      const { aggregate } = visitAnswerConnection;
      closedCount = aggregate.count;
    }
  }

  const { loadingVisitAnswersClosed, dataVisitAnswer } = visitAnswers;

  const wrapPDFData = () => {
    return {
      categoryDashboard: {
        keyfield: {
          name: selectedKeyfieldOption.label,
          value: selectedItemOption.label
        },
        category: {
          name: category.name,
          value: category.id
        },
        date: datesData.current,
        averageRowData: averageRowData.current,
        changeRowData: changeRowData.current,
      },
    };
  };

  const wrapXLSData = () => {
    return {
      categoryDashboard: {
        keyfield: {
          name: selectedKeyfieldOption.label,
          value: selectedItemOption.label
        },
        category: {
          name: category.name,
          value: category.id
        },
        date: datesData.current,
        averageRowData: averageRowData.current,
        changeRowData: changeRowData.current,
      },
    };
  };

  const handlePDFReportClick = React.useCallback(() => {
    let payload = wrapPDFData();
    createCategoryDashboardReport(payload);
  }, [ 
    createCategoryDashboardReport, 
    averageRowData,
    category, 
    changeRowData,
    datesData, 
    selectedKeyfieldOption, 
    selectedItemOption  
  ]);

  const handleXLSReportClick = React.useCallback(() => {
    let payload = wrapXLSData();
    createCategoryDashboardXLSReport(payload);
  }, [ 
    averageRowData,
    category,
    changeRowData,
    createCategoryDashboardReport,
    datesData,
    selectedKeyfieldOption, 
    selectedItemOption  
  ]);

  const handlePDFReportEmailClick = React.useCallback((emailData) => {
    let payload = wrapPDFData();
    sendCategoryDashboardPDFReport(payload, emailData);
  }, [ 
    averageRowData,
    category, 
    changeRowData,
    datesData, 
    selectedKeyfieldOption, 
    selectedItemOption,
    sendCategoryDashboardPDFReport,
  ]);

  const handleXLSReportEmailClick = React.useCallback((emailData) => {
    let payload = wrapXLSData();
    sendCategoryDashboardXLSReport(payload, emailData);
  }, [ 
    averageRowData,
    category, 
    changeRowData,
    datesData,
    sendCategoryDashboardXLSReport,
    selectedKeyfieldOption, 
    selectedItemOption  
  ]);

  const onKeyfieldSelectChange = React.useCallback((keyfieldOption) => {
    setSelectedItemOption(null);
    setSelectedKeyfieldOption(keyfieldOption)
  }, [setSelectedKeyfieldOption]);

  const onItemSelectChange = React.useCallback((selectedOption) => {
    setSelectedItemOption(selectedOption);
  }, [setSelectedItemOption]);

  const selectedKeyfield = keyfields.find((keyfield) => keyfield.id === selectedKeyfieldOption.value);

  const selectOptions = keyfields.map((keyfield) => ({
    value: keyfield.id,
    label: keyfield.name,
  }));

  const groupsByKeyfield = selectedKeyfield ? groupByKeyfields(items, [selectedKeyfield]) : [];

  const itemsSuggestions = groupsByKeyfield.map((group) => {
    const keyfield = items[group[0]].keyfields.find(({id}) => id === selectedKeyfieldOption.value);
    return {
      value: keyfield.value, 
      label: keyfield.value
    };
  });

  const data = groupsByKeyfield ? getChartData(groupsByKeyfield, items, selectedItemOption, category) : {};

  const defaultBody = {
    PDF: getDefaultBody( REPORT_ACTIONS.categoryDashboardPDF, 
      { category: category.name, keyfield: selectedKeyfieldOption.label,
        startDate: emailData.startDate, endDate: emailData.endDate } ),
    XLS: getDefaultBody( REPORT_ACTIONS.categoryDashboardXLS,
      { category: category.name, keyfield: selectedKeyfieldOption.label,
        startDate: emailData.startDate, endDate: emailData.endDate } )
  };

  const defaultSubject = {
    PDF: getDefaultSubject( REPORT_ACTIONS.categoryDashboardPDF,
      { category: category.name, keyfield: selectedKeyfieldOption.label,
        startDate: emailData.startDate, endDate: emailData.endDate } ),
    XLS: getDefaultSubject( REPORT_ACTIONS.categoryDashboardXLS,
      { category: category.name, keyfield: selectedKeyfieldOption.label,
        startDate: emailData.startDate, endDate: emailData.endDate } )
  };
  return (
    <GridContainer>
      <GridItem xs={3}>
        <FiltersSidebar
          activeVisitAnswer={activeVisitAnswer}
          closedVisitAnswer={closedCount}
          visits={visits}
          visitAnswers={visitAnswers}
          operators={operators}
        />
      </GridItem>
      <GridItem xs={9}>
        <Button
          simple
          size="lg"
          color="info"
          onClick={history.goBack}
          className={classes.buttonNoMargin}
        >
          <ArrowBackIcon/><span>Volver al tablero general</span>
        </Button>
        <Card className={classes.cardLessBorder}>
          <CardHeader color="info" text>
            <GridContainer>
              <GridItem xs={10}>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>{category.name}</h4>
                </CardText>
              </GridItem>
              <GridItem xs={1}>
                <CardText color="info" className={classes.noPadding}>
                  <SendEmailButton
                    color="white"
                    defaultBody={defaultBody}
                    defaultSubject={defaultSubject}
                    isReady={true}
                    hasXLS={true}
                    sendPDFReport={handlePDFReportEmailClick}
                    sendXLSReport={handleXLSReportEmailClick}
                    size="lg"
                  />
                </CardText>
              </GridItem>
              <GridItem xs={1}>
                <CardText color="info" className={classes.noPadding}>
                  <DownloadButton
                    color="white"
                    isReady={true}
                    size="lg"
                    hasXLS={true}
                    onClickPDF={handlePDFReportClick}
                    onClickXLS={handleXLSReportClick}
                  />
                </CardText>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <div className={classes.selectors}>
              <div>
                <Select
                  className={classes.selector}
                  placeholder={"Seleccionar un Campo Clave"}
                  value={selectedKeyfieldOption}
                  onChange={onKeyfieldSelectChange}
                  options={selectOptions}
                />
              </div>
              <div>
                <Select
                  className={classes.selector}
                  placeholder={"Seleccionar una  " + selectedKeyfield.name}
                  value={selectedItemOption}
                  onChange={onItemSelectChange}
                  options={itemsSuggestions}
                />
              </div>
            </div>
            { data && <LineChart data={data}/>}
            { data && 
              <RelativeChangeTable 
                data={data}
                datesData = {datesData}
                averageRowData = {averageRowData}
                changeRowData = {changeRowData}
              />
            }
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

VisitsDashboardCategoryItemView.propTypes = {
  activeVisitAnswer: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  createCategoryDashboardReport: PropTypes.func.isRequired,
  createCategoryDashboardXLSReport: PropTypes.func.isRequired,
  emailData: PropTypes.object.isRequired,
  keyfields: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  visits: PropTypes.array.isRequired,
  visitAnswers: PropTypes.object.isRequired,
  operators: PropTypes.array.isRequired,
  sendCategoryDashboardPDFReport: PropTypes.func.isRequired,
  sendCategoryDashboardXLSReport: PropTypes.func.isRequired,
};

const styles = theme => ({
  buttonNoMargin:{
    padding: "10px 0px"
  },
  cardLessBorder: {
    marginBottom: "0px"
  },
  selector: {
    margin: "10px 20px",
    width: "300px"
  },
  selectors:{
    display: "flex"
  },
  noPadding: {
    padding: 0
  },
});

export default withStyles(styles)(VisitsDashboardCategoryItemView);
