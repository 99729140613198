import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import DetailsTable from 'components/AdminData/DetailsTable';
import ListButton from 'components/AdminData/ListButton';
import PieChart from 'components/Chart/PieChart';
import { getPieChartData } from '../../../utils/analytics/dataManipulation';
import { getDefaultSubject, getDefaultBody } from '../../../utils/emails/defaultContent';
import { REPORT_ACTIONS } from '../../../constants';

function countedActiveUsers(users, activities){
  let bucket = {};
  users.forEach(user => {
    bucket[user._id] = {
      id: user._id,
      label: user.name,
      count: 0
    };
  });
  activities.forEach(activity => {
    if(bucket[activity.userRef._id]){
      bucket[activity.userRef._id].count++;
    }
  });
  return Object.values(bucket);
};

function groupByRepetitions(users, repetitions){
  let under = [];
  let over = [];
  users.forEach(user => {
    if(user.count < repetitions){
      under.push(user);
    } else {
      over.push(user);
    }
  });
  return [under, over];
};

function ActiveUsersView({  
  classes,
  handleSegmentChange,
  segment,
  segmentsOptions,
  users,
  visitAnswers,
  createActiveUSerReport,
  emailData,
  sendActiveUserPDFReport,
  createActiveUserXLSReport,
  sendActiveUserXLSReport
}) {
  const [executionCount, setExcecutionCount] = React.useState(1);
  const handleExecutionCountChange = React.useCallback((e) => {
    setExcecutionCount(e.target.value);
  }, [setExcecutionCount]);

  const countedUsers = countedActiveUsers(users, visitAnswers);
  const [inactiveUsers, activeUsers] = groupByRepetitions(countedUsers, executionCount);

  const data = [
    {
      label: "Activos",
      count: activeUsers.length,
      color: "#7DB651"
    }, {
      label: "Inactivos",
      count: inactiveUsers.length,
      color: "#68A3DD"
    }
  ];

  const chartData = getPieChartData(data, "Usuarios Activos");

  const wrapPDFData = () => {
    return {
      segment: segment,
      items: {
        active: activeUsers,
        inactive: inactiveUsers,
        quantity: executionCount,
        data: data
      },
    };
  };
  
  const wrapXLSData = () => {
    return {
      segment: segment,
      items: {
        active: activeUsers,
        inactive: inactiveUsers,
        quantity: executionCount,
        data: data
      },
    };
  };

  const handlePDFReportClick = React.useCallback(() => {
    const payload = wrapPDFData();
    createActiveUSerReport(payload);
  }, [ createActiveUSerReport, activeUsers, inactiveUsers, segment, executionCount]);

  const handleXLSReportClick = React.useCallback(() => {
    const payload = wrapXLSData();
    createActiveUserXLSReport(payload);
  }, [ createActiveUserXLSReport, activeUsers, inactiveUsers, segment, executionCount]);

  const handlePDFReportEmailClick = React.useCallback((emailData) => {
    const payload = wrapPDFData();
    sendActiveUserPDFReport(payload, emailData);
  }, [ sendActiveUserPDFReport, activeUsers, inactiveUsers, segment, executionCount ]);

  const handleXLSReportEmailClick = React.useCallback((emailData) => {
    const payload = wrapXLSData();
    sendActiveUserXLSReport(payload, emailData);
  }, [ sendActiveUserXLSReport, activeUsers, inactiveUsers, segment, executionCount ]);

  const defaultSubject = {
    PDF: getDefaultSubject( REPORT_ACTIONS.activeUserPDF,
      { visit: emailData.visit, segment: segment.label,
        startDate: emailData.startDate, endDate: emailData.endDate } ),
    XLS: getDefaultSubject(
      REPORT_ACTIONS.activeUserXLS,
      { visit: emailData.visit, segment: segment.label,
        startDate: emailData.startDate, endDate: emailData.endDate })
  };

  const defaultBody = {
    PDF: getDefaultBody( REPORT_ACTIONS.activeUserPDF, 
      { visit: emailData.visit, segment: segment.label, executionCount,
        startDate: emailData.startDate, endDate: emailData.endDate, executionCount: executionCount} 
        ),
    XLS: getDefaultBody(REPORT_ACTIONS.activeUserXLS,
      { 
        visit: emailData.visit, segment: segment.label, executionCount,
        startDate: emailData.startDate, endDate: emailData.endDate, executionCount: executionCount
      })
  };
  
  return (
    <Card>
      <CardHeader color="info" text>
        <GridContainer>
          <GridItem xs={6}>
            <CardText color="info">
              <h4 className={classes.cardTitle}>Usuarios Activos</h4>
            </CardText>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody className={classes.activeUsersCard}>
        <GridContainer >
          <GridItem xs={6}>
            <div>
              <h4>Seleccionar un Segmento</h4>
            </div>
            <Select
              className={classes.selector}
              placeholder={"Selecciona un Segmento"}
              value={segment}
              onChange={handleSegmentChange}
              options={segmentsOptions}
            />
            <CustomInput
              labelText="Cantidad que define una ejecución"
              id="executionCount"
              type={"number"}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: handleExecutionCountChange,
                autoComplete: "false",
                defaultValue: 1
              }}
            />
            <ListButton
              classes={classes}
              label="Lista de Activos" 
              items={activeUsers.map(({label}) => label)}
              enableButtons={{
                email: true,
                download: true,
                preview: false,
                xls: true
              }}
              defaultBody={defaultBody}
              defaultSubject={defaultSubject}
              handlePDFReportEmailClick={handlePDFReportEmailClick}
              handlePDFReportClick={handlePDFReportClick}
              handleXLSReportClick={handleXLSReportClick}
              handleXLSReportEmailClick={handleXLSReportEmailClick}
            />
            <ListButton
              classes={classes}
              label="Lista de Inactivos" 
              items={inactiveUsers.map(({label}) => label)}
              enableButtons={{
                email: false,
                download: false,
                preview: false
              }} 
            />
          </GridItem>
          <GridItem xs={6}>
            <div className={classes.container}>
              <div className={classes.chart}>
                <PieChart
                  id="activeUsersChart"
                  data={chartData}
                />
              </div>
            </div>
            <DetailsTable
              data={data} 
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

ActiveUsersView.propTypes = {
  handleSegmentChange: PropTypes.func.isRequired,
  segment: PropTypes.object.isRequired,
  segmentsOptions: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  visitAnswers: PropTypes.array.isRequired
};

const styles = theme => ({
  container:{
    display: "flex",
    justifyContent: "center"
  },
  chart: {
    width: "400px",
  },
  activeUsersCard:{
    zIndex: 13
  },
});

export default withStyles(styles)(ActiveUsersView);
