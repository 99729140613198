import React, { useMemo } from "react";
import {useTranslation} from 'react-i18next';
import { useQuery, useMutation } from 'react-apollo-hooks';

// Own components
import NewTicketForm from "../../forms/NewTicketForm";

// Local state
import useGlobal from "../../store";
import {CREATE_TICKET_MUTATION} from "./Mutations";
import {APP_SEGMENTS_ALL, ALLOWED_TICKET_QUESTION_MODULES_ALL, USER_MOBILES_FEED} from './Queries';

// utils
import history from '../../utils/history';
import LoadingCircleView from "../../Views/LoadingCircleView";
import EditTicketForm from "../../forms/EditTicketForm";
import auth from "../../utils/auth";

const NewTicketContainer = (props) => {

  const [ session, globalActions] = useGlobal(
    state => state.session,
    actions => actions
  );
  const { t, } = useTranslation(['tickets', 'common']);

  const {
    data: dataUserMobile,
    error: errorUserMobile,
    loading: loadingUserMobile,
    refetch: refetchUserMobile
  } = useQuery(USER_MOBILES_FEED, {
    variables: {
      where: {
        app: session.appRef._id,
        archived: false
      }
    },
    fetchPolicy: "network-only"
  });
  const {data: dataSegments, loading: loadingSegments} = useQuery(APP_SEGMENTS_ALL, {
    variables: {
      "where": {
        "app": session.user ? session.appRef._id : '',
        "archived": false
      }
    }
  });
  const {data: dataQEAllowed, loading: loadingQEAllowed} = useQuery(ALLOWED_TICKET_QUESTION_MODULES_ALL, {
    variables: {
      "where": {
        "app": session.user ? session.appRef._id : '',
        "archived": false
      }
    }
  });
  const createTicket = useMutation(CREATE_TICKET_MUTATION);

  useMemo(() => {
    if (
      session.user &&
      !auth.hasPermission("ticketsCreated", session.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: t('common:common.hasNotPermission'),
        color: "danger"
      });
      history.push('dashboard');
    } 
  }, [session]);

  // Loading screen
  if (loadingQEAllowed || loadingSegments || loadingUserMobile) {
    return(<LoadingCircleView />)
  }

  if ((!loadingSegments && !loadingQEAllowed && !loadingUserMobile) && (dataQEAllowed && dataSegments)) {
    return (
      <NewTicketForm
        submitSuccess={(values) => {
          const validTicketSegmentAssignConfigs = values.ticketSegmentAssignConfigs.filter((assign) => {
            return assign.segment && assign.userMobile;
          }).map((assign) => {
            return {
              segmentId: assign.segment._id,
              userMobileId: assign.userMobile._id
            }
          });

          const variables = {
            data: {
              app: session.appRef._id,
              name: values.name,
              description: values.description,
              emails: {
                set: values.emails
              },
              triggers: {
                connect: values.triggers.map(trigger => ({_id: trigger._id}))
              },
              finisher: {
                connect: {
                  _id: values.finisher._id
                }
              },
              ticketSegmentAssignConfigs: validTicketSegmentAssignConfigs,
              whoResolves: values.whoResolves,
              segment: values.segment,
              archived: false,
              pdfReportConfig: values.pdfReportConfig
            }
          };

          

          const mutationResult = createTicket({
            variables: variables
          });

          history.push('/tickets/all');

          // Add success notification
          globalActions.notification.openNotification('tr', {
            message: t('tickets.forms.new.success'),
            color: 'success'
          });
        }}
        segments={dataSegments.segments}
        userMobiles={loadingUserMobile ? []: dataUserMobile.userMobiles}
        allowedQuestionModules={dataQEAllowed.questionEngineModulesTicketUnused}
        allowedQuestionModulesForFinish={dataQEAllowed.questionEngineModulesTicketFinishUnused}
      />
    );
  }

  return [];
};


export default NewTicketContainer;
