import {createSelector} from 'reselect';
import {pipe, pluck, uniq, filter} from 'ramda';

const approvalItemsSelector = data => data.approvalItems;

export const approvalItemsByVisitSelector = createSelector(approvalItemsSelector, (approvalItems) => {

  const getUniqueVisits =
    pipe(
      pluck('visitAnswerRef'),
      pluck('visitId'),
      uniq
    );
  const uniqueVisitIds = getUniqueVisits(approvalItems);
  const visits = uniqueVisitIds.map((id) => {
    return approvalItems.find((item) => {
      return item.visitAnswerRef.visitId === id;
    }).visitAnswerRef.visitRef;
  });
  return visits.map((visit) => {
    const itemsForVisit = approvalItems.filter((item) => {
      return item.visitAnswerRef.visitRef._id === visit._id;
    });
    return {
      visit: visit,
      approvalItems: itemsForVisit
    }
  });
});
