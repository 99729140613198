import React, { useState } from 'react';
import useGlobal from "../../store";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import {
    REQUISITIONS, REQUISITION_INDICATORS
  } from "./gql/Queries";
import RequisitionsAdminView from '../../Views/ProcessRelationship/RequisitionsAdminView';
import moment from "moment";

const RequisitionsAdmin = ({ match, location }) => {
  const processRelationshipId = match?.params?.id;
  const processRelationship = location?.state?.processRelationship;

  const [session] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const [filters, setFilters] = useState({
    processRelationshipId: processRelationshipId,
    app: session.user ? session.appRef._id : "",
    //expirationDate_gte: moment(new Date()).startOf("day").format(),
    userId: session.user ? session.user._id : "",
  });

  const [status, setStatus] = useState("All");

  const {
    data: dataRequisitionsIndicator,
    loading: loadingRequisitionsIndicator,
  } = useQuery(REQUISITION_INDICATORS, {
    variables: {
      where: filters,
    },
    fetchPolicy: "network-only",
  });


  const {
    data: dataRequisitions,
    loading: loadingRequisitions,
    error: errorRequisitions,
  } = useQuery(REQUISITIONS, {
    variables: {
      where: filters,
    },
    fetchPolicy: "network-only",
  });

  return (
    <RequisitionsAdminView
      indicator={dataRequisitionsIndicator?.getRequisitionIndicator ?? {}}
      requisitions={{ loading: loadingRequisitions, data: dataRequisitions?.getRequisitionConfig ?? [] }}
      processRelationshipId={processRelationshipId}
      processRelationship={processRelationship}
      filters={filters}
      setFilters={setFilters}
      status={status}
      setStatus={setStatus}
    />
  );
}

export default RequisitionsAdmin;
