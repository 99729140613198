import React from "react";
import NewChecklistAnalisysVisitReportView from "../../Views/Reports/NewChecklistAnalisysVisitReportView";
import { VISIT_ITEMS, USERS_GH, SEGMENTS_FEED, TEAMS_FEED } from "./gql/Queries";
import { useQuery, useMutation } from "react-apollo-hooks";
import useGlobal from "../../store";
import { CREATE_CHECKLIST_ANALISYS_REPORT_MUTATION } from "./gql/Mutations";
import { useTranslation } from "react-i18next";
import history from "../../utils/history";
import { CircularProgress } from "@material-ui/core";

const NewCheckListAnalisysVisitReport = () => {
  const { t } = useTranslation("checklistAnalisys");
  const [session] = useGlobal(
    state => state.session,
    actions => actions
  );
  const [, globalActions] = useGlobal();
  const { data: dataVisit, loading: loadingVisit } = useQuery(VISIT_ITEMS, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : "",
        //hasChecklistConfiguration: true,
        isArchived: false  
      }
    },
    fetchPolicy: "network-only"
  });

  const { data: dataUsers, loading: loadingUsers } = useQuery(USERS_GH, {
    variables: {
      where: {
        app_access: session.user ? session.appRef._id : "",
        archived: false
      }
    },
    fetchPolicy: "network-only"
  });
  const { data: dataSegments, loading: loadingSegments } = useQuery(SEGMENTS_FEED, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : "",
        archived: false 
      }
    },
    fetchPolicy: "network-only"
  });

  const { data: dataTeams, loading: loadingTeams } = useQuery(TEAMS_FEED, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : "",
        archived: false
      }
    },
    fetchPolicy: "network-only"
  });

  const createReport = useMutation(CREATE_CHECKLIST_ANALISYS_REPORT_MUTATION);

  /* const checkIfVisitHasConfig = (visit) => {
    if (visit.checklistAnalisysReport.length) {
      return true;
    }else{
      return false;
    }
  }
  const _renderVisist = (visits) => {
    const renderVisit = visits.reduce((result, visit) => {
      if (checkIfVisitHasConfig(visit)) {
        const configKeys = visit.checklistAnalisysReport.map(config => config.keyField);
        visit.keys = visit.keys.filter(key => !configKeys.includes(key));
        if (visit.keys.length) {
          result.push(visit);
        }
      }else{
        result.push(visit);
      }
      return result;
    }, []);
    return renderVisit;
  } 
  //Check report config previews
  if (!loadingVisit && dataVisit && dataVisit.visits && dataVisit.visits.length) {
    const renderVisits = _renderVisist(dataVisit.visits);
    dataVisit.visits = renderVisits;
  } */

  /* const _renderVisits = (visits) => {
    const renderVisits = visits.reduce((result, visit) => {
      
      if(visit.checkListConfiguration && visit.checkListConfiguration.columns.length){
        result.push(visit);
      }

      return result;
    }, []);

    return renderVisits;
  }

  if (!loadingVisit && dataVisit && dataVisit.visits && dataVisit.visits.length) {
  
    dataVisit.visits = _renderVisits(dataVisit.visits);
  } */
  if (!loadingVisit && !loadingUsers && !loadingSegments && !loadingTeams) {
    return (
      <NewChecklistAnalisysVisitReportView
        app={session.user ? session.appRef._id : ""}
        visits={{ loadingVisit: loadingVisit, dataVisit: dataVisit }}
        users={{ loadingUsers: loadingUsers, dataUsers: dataUsers }}
        segments={{ loadingSegments: loadingSegments, dataSegments: dataSegments }}
        teams={{ loadingTeams: loadingTeams, dataTeams: dataTeams }}
        submitSuccess={(values) => {
          let data = values;
          let programableReport = values.programableReport;

          delete data.programableReport;

          let variables = {
            data: {
              ...data
            },
            programableReportData: {
              ...programableReport
            }
          };
          const propMutation = createReport({ variables: variables });
  
          if (!propMutation.loading) {
            if (!propMutation.error) {
              // Add success notification
              globalActions.notification.openNotification("tr", {
                message: t('checklistAnalisys.banner.success'),
                color: "success"
              });
              history.push(`/checklistAnalisysVisit`);
            }else{
              globalActions.notification.openNotification("tr", {
                message: t('checklistAnalisys.banner.fail'),
                color: "danger"
              });
            }
          }

        }}
      />
    );
  }else{
    return (
      <div style={style.progressCnt}>
        <CircularProgress style={style.progress} />
      </div>
    )
  }
  
};

const style = {
  progress: {
    margin: '16px'
  },
  progressCnt:{
    width: '100px !important',
    marginLeft: '50%',
    marginRight: '50%'
  }
};

export default NewCheckListAnalisysVisitReport;
