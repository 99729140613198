import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { CircularProgress } from "@material-ui/core";

import { SO_CHECKLIST_REPORTS_BASE_MODELS } from "../gql/Queries";
import { UPDATE_SO_CHECKLIST_REPORT } from "../gql/Mutations";

import CreateUpdateChecklistReportsForm from "../../../forms/SO/CreateUpdateChecklistReportsForm";

import useGlobal from "../../../store";
import history from "utils/history";

const ChecklistReportEdit = (props) => {
  const { checklistReport } = props.location.state;
  const [session, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const {
    data: dataSOChecklistReportsBaseModels,
    loading: loadingSOChecklistReportsBaseModels,
  } = useQuery(SO_CHECKLIST_REPORTS_BASE_MODELS, {
    variables: {
      app: session.user ? session.appRef._id : "",
      archived: false,
    },
    fetchPolicy: "network-only",
  });

  const [updateSOChecklistReport] = useMutation(UPDATE_SO_CHECKLIST_REPORT, {
    onCompleted: (mutationData) => {
      console.log("mutationData", mutationData);
      history.push("/so/checklistReport");
    },
  });

  if (loadingSOChecklistReportsBaseModels) {
    return <CircularProgress />;
  }
  return (
    <CreateUpdateChecklistReportsForm
      initialValues={checklistReport}
      visits={dataSOChecklistReportsBaseModels?.visits || []}
      processRelationships={
        dataSOChecklistReportsBaseModels?.getProcessRelationships || []
      }
      submitSuccess={(values) => {
        updateSOChecklistReport({
          variables: {
            data: {
              app: session.user ? session.appRef._id : "",
              ...values,
            },
            where: {
              _id: checklistReport._id,
            },
          },
        });
      }}
    />
  );
};

export default ChecklistReportEdit;
