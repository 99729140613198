import React from 'react'
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import useGlobal from "../../../store";
import Button from "components/CustomButtons/Button.jsx";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import history from '../../../utils/history';
import EmailIcon from '@material-ui/icons/Email';
import { useTranslation } from 'react-i18next';
const Item = ({message}) => {
    const { title, subject } = message;
    const { t } = useTranslation("general_adaptation");

    if (subject) {
      return (
        <div className="my-slide content">
          <h3 style={style.adjustText}>{title}</h3>
          <p style={style.adjustText}>{subject}</p>
          <Button
            size="sm"
            color="info"
            onClick={() =>
              history.push({
                pathname: "/message/content",
                state: {
                  message: message
                }
              })
            }
          >
            {t("readmore")}
          </Button>
        </div>
      ); 
    }else{
      return (
        <div className="my-slide content">
          <h3>{title}</h3>
        </div>
      );
    }
    
}

const Messages = ({messagesApp, messagesGeneral}) => {
  const { t } = useTranslation("general_adaptation");
  const [ session, ] = useGlobal(
    state => state.session,
    actions => actions
  );

    const { loading, messagesByApp } = messagesApp;
    const { loadingGeneral, dataMessageGeneral } = messagesGeneral;

    return (
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <EmailIcon />
          </CardIcon>
          <div style={style.left}>
            <h4 style={style.cardTitle}>{t("messages.title")}</h4>
          </div>
        </CardHeader>
        <CardBody>
          <GridContainer style={style.textCenter}>
            <GridItem xs={6}>
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={8} style={{ textAlign: "right" }}>
                      <h4>{t("messages.subtitle")}</h4>
                    </GridItem>
                    <GridItem xs={4}>
                      <Button
                        size="sm"
                        color="beepquest"
                        onClick={() => history.push(`/message/general`)}
                      >
                        {t("viewall")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <Carousel
                    style={style.backgroundSlide}
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    className="presentation-mode"
                  >
                    {!loadingGeneral &&
                    dataMessageGeneral.getMessages.length ? (
                      dataMessageGeneral.getMessages.map((message, index) => (
                        <Item key={index} message={message} />
                      ))
                    ) : (
                      <Item key={1} message={{ title: "Sin mensajes" }} />
                    )}
                  </Carousel>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={6}>
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={8} style={{ textAlign: "left", whiteSpace: 'unset' }}>
                      <h4>
                        {t("messages.annouforu")} {" "}
                        {session.user ? `${session.appRef.name}` : ""}
                      </h4>
                    </GridItem>
                    <GridItem xs={4}>
                      <Button
                        size="sm"
                        color="beepquest"
                        onClick={() => history.push(`/message/custom`)}
                      >
                        {t("viewall")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <Carousel
                    style={style.backgroundSlide}
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    className="presentation-mode"
                  >
                    {!loading && messagesByApp.getMessages.length ? (
                      messagesByApp.getMessages.map((message, index) => (
                        <Item key={index} message={message} />
                      ))
                    ) : (
                      <Item key={1} message={{ title: t("messages.nomessages") }} />
                    )}
                  </Carousel>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
}
const style = {
    cardTitle: {
      color: "#3C4858"
    },
    progress:{
      marginLeft: '50%',
      marginRight: '50%'
    },
    cardHeight:{
      height: "430px"
    },
    scrollVertical: {
      overflowY: 'scroll'
    },
    left:{
      float: 'left'
    },
    right:{
      float: 'right'
    },
    textCenter:{
        textAlign: 'center'
    },
    adjustText:{
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    backgroundSlide:{
        background: "none !important"
    }
  };
export default Messages;