import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {withApollo} from "react-apollo";
import {useTranslation} from "react-i18next";
import {compose} from "recompose";
import { css } from '@emotion/core';
import { CircleLoader } from 'react-spinners';

// global store
import useGlobal from "../../store";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import bqLogo from "assets/img/logos/BQ Logo Blue.png";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const ExternalLogin = ({classes, history, client}) => {
  const [, globalActions] = useGlobal();
  const { t, } = useTranslation('extLogin');

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const token = params.get('token');
    const app = params.get('app');
    const redirectTo = params.get('url');

    console.log('redirectTo', redirectTo);

    globalActions.session.checkExternalSession(client, token, app, redirectTo);
  }, []);


  return (
    <div className="Pages">
      <div className={classes.wrapper}>
        <div className={classes.fullPage}>
          <GridContainer justify="center">
            <GridItem xs={12} className={classes.center}>
              <img src={bqLogo} style={{width: '600px'}} alt="..." />
            </GridItem>
            <GridItem xs={12} className={classes.center}>
              <Card plain>
                <CardBody>
                  <h3>{t('extLogin.title')} <br/>
                  <small>{t('extLogin.subTitle')}</small>
                  </h3>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} className={classes.center}>
              <CircleLoader
                css={override}
                sizeUnit={"px"}
                size={75}
                color={'#6AB3D4'}
                loading={true}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
};

ExternalLogin.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};


const styles = theme => ({
  wrapper: {
    height: "auto",
    minHeight: "100vh",
    position: "relative",
    top: "0"
  },
  center: {
    textAlign: "center"
  },
  fullPage: {
    padding: "120px 0",
    position: "relative",
    minHeight: "100vh",
    display: "flex!important",
    margin: "0",
    border: "0",
    color: "#fff",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      minHeight: "fit-content!important"
    },

  }
});

export default compose(
  withStyles(styles),
  withApollo,
)(ExternalLogin);
