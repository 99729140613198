import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form, withFormik } from 'formik';
import Datetime from "react-datetime";
import * as yup from "yup";

// own components
import EngineAnswerFieldFooter from "../../Views/QuestionEngineAnswers/EngineAnswerFieldFooter";
import CancelSubmitEditForm from "./CancelSubmitEditForm";
import WithConfirmation from "../WithConfirmation";

// custom components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from "components/Card/CardFooter.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";

//Utils
import {baseDateAnswerFormat} from "../../utils/dates/dates";
import i18n from "../../i18n";

const validationSchema = yup.object().shape({
  value: yup.date(),
});

const DateFieldAnswerEditForm = ({...props}) => {
  const { t } = useTranslation('tickets');

  // Formik bag
  const { errors, values, dirty, setFieldValue, submitForm } = props;

  // refs
  let inputRef = useRef(null);

  // custom effects
  useEffect(() => {
    if (props.isEditRendered) {
      inputRef.current.focus();
    }
  }, [props.isEditRendered]);

  return (
    <Form>
      <Card >
        <CardBody>
          <GridContainer>
            <GridItem xs={10}>
              <Datetime
                name={'value'}
                timeFormat={false}
                defaultValue={values.value}
                inputProps={{
                  ref: inputRef,
                  readOnly: true,
                  style: {textAlign: 'center'}
                }}
                onChange={e => {
                  if (e === '') {
                    setFieldValue('value', e);
                  }else{
                    setFieldValue('value', e._d);
                  }
                }}
              />
            </GridItem>
            <GridItem xs={2}>
              <CancelSubmitEditForm
                cancelEdit={() => props.cancelEdit(props.answerData)}
                submitForm={submitForm}
                enableSubmit={dirty && !errors.length}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <EngineAnswerFieldFooter answerData={props.answerData} />
        </CardFooter>
      </Card>
    </Form>
  );
};

DateFieldAnswerEditForm.propTypes = {
  answerData: PropTypes.object.isRequired,
  submitSuccess: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired
};

const DateFieldAnswerEditFormWF = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: true,
  mapPropsToValues: ({answerData}) => {
    return {
      value: answerData.answer.DateFieldAnswer,
    };
  },
  handleSubmit: (values, { props }) => {
    props.goToConfirmation({
      title: i18n.t('question-engine-module-answer.messages.confirmEditFieldTitle', {
        ns: 'question-engine-module-answer'
      }),
      message: baseDateAnswerFormat(values.value),
      callback: () => {
        props.submitSuccess({
          value: baseDateAnswerFormat(values.value)
        });
      }
    })
  }
})(DateFieldAnswerEditForm);

export default WithConfirmation(DateFieldAnswerEditFormWF);
