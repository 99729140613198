import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DonutSmall from "@material-ui/icons/DonutSmall";
import GroupIcon from "@material-ui/icons/Group";
import EmailIcon from '@material-ui/icons/Email';
import StepConnector from "@material-ui/core/StepConnector";
import ObjectEditConfirmationModal from "../../modals/ObjectEditConfirmModal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import history from "../../utils/history";

import UsersForm from "../../forms/ProcessRelationship/UsersForm";
import WorkPlanForm from "../../forms/ProcessRelationship/WorkPlanForm";
import BaseForm from "../../forms/ProcessRelationship/BaseForm";
import VisitConfigForm from "../../forms/ProcessRelationship/VisitConfigForm";
import ReportConfigForm from "../../forms/ProcessRelationship/ReportConfigForm";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage: "linear-gradient(60deg, #26c6da, #6ab3d4)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage: "linear-gradient(60deg, #26c6da, #6ab3d4)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: "linear-gradient(60deg, #26c6da, #6ab3d4)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: "linear-gradient(60deg, #26c6da, #6ab3d4)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    0: <CheckBoxIcon />,
    1: <SettingsIcon />,
    2: <GroupIcon />,
    3: <EmailIcon />,
    4: <DonutSmall />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon - 1)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  buttonNext: {
    marginRight: theme.spacing(1),
    float: "right",
  },
  buttonLeft: {
    marginRight: theme.spacing(1),
    float: "left",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ProcessRelationshipWizard = (props) => {
  const { configReport, setConfigReport, initDataForm, submitSuccess } = props;
  const {
    processRelationship,
    massiveLists,
    geofences,
    userMobiles,
    questionEngineModules,
    elementsList,
    loadingElementsList,
    tableFields,
    checkFields,
    visitsConfigurations,
    paginationUsers,
    paginationAction,
    allElementsList,
  } = props;
  const {
    updateValidVisits,
    isUpdatingValidVisits,
    validVisits,
    updateSelectedVisitsInfo,
  } = props;
  const [objectChangedValues, setObjectChangedValues] = useState([])
  

  const getElementLists = () => {
    if (processRelationship.elementsType === "Geofences") {
      return geofences
        .filter((geofence) => {
          return processRelationship.elementsList.indexOf(geofence._id) !== -1;
        })
        .map((foundElementList) => ({
          label: foundElementList.name,
          value: foundElementList._id,
        }));
    } else {
      return massiveLists
        .filter((massiveList) => {
          return (
            processRelationship.elementsList.indexOf(massiveList._id) !== -1
          );
        })
        .map((foundElementList) => ({
          label: foundElementList.name,
          value: foundElementList._id,
        }));
    }
  };
  const getSolutionModuleObject = () => {
    if (processRelationship.workPlanConfig?.solutionModuleId) {
      const moduleFound = questionEngineModules.find(
        (module) =>
          module._id === processRelationship.workPlanConfig.solutionModuleId
      );
      return moduleFound
        ? {
            label: moduleFound.module.name,
            value: processRelationship.workPlanConfig.solutionModuleId,
          }
        : null;
    }
    return null;
  };

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [nextEnabled, setNextEnabled] = useState(false);
  const [currentFormData, setCurrentFormData] = useState(
    processRelationship
      ? {
          BaseForm: {
            name: processRelationship.name,
            description: processRelationship.description,
            elementType: processRelationship.elementsType,
            elements: getElementLists(),
          },
          WorkPlanForm: {
            solutionModuleId: getSolutionModuleObject(),
            tableFieldsSelected:
              processRelationship.workPlanConfig?.tableFields.map((field) => ({
                ...field,
                selected: true,
              })) || [],
            checklistFieldsSelected:
              processRelationship.workPlanConfig?.checklistFields.map(
                (field) => ({ ...field, selected: true })
              ) || [],
          },
          UsersForm: {
            roleName: processRelationship.assignedUserInfo.label,
            relations: processRelationship.assignedUserInfo.relations
              ? processRelationship.assignedUserInfo.relations.map(
                  (relation) => {
                    const userMobileFound = userMobiles.find(
                      (userMobile) => userMobile._id === relation.userId
                    );
                    return {
                      ...relation,
                      userId: userMobileFound
                        ? {
                            label: userMobileFound.email,
                            value: userMobileFound._id,
                          }
                        : {},
                    };
                  }
                )
              : [],
          },
          VisitConfigForm: {
            visits: processRelationship.visits,
            operationPonderable: processRelationship.operationPonderable,
            visitWithNA: processRelationship.visitWithNA,
            periodicity: processRelationship.periodicity,
            visitPonderation: processRelationship.operationPonderable
              ? processRelationship.visitPonderation
              : [],
          },
          ReportConfig: {
            enable: processRelationship?.reportConfig?.enable,
            subject: processRelationship?.reportConfig?.subject,
            reportType: processRelationship?.reportConfig?.reportType,
            weekday: processRelationship?.reportConfig?.weekday,
            reportHour: processRelationship?.reportConfig?.reportHour,
            timezone: processRelationship?.reportConfig?.timezone,
            startDate: processRelationship?.reportConfig?.startDate,
            endDate: processRelationship?.reportConfig?.endDate,
          }
        }
      : {
          BaseForm: {},
          WorkPlanForm: {},
          UsersForm: {},
          VisitConfigForm: {},
          ReportConfig: {}
        }
  );

  const { t } = useTranslation("processRelationship");
  const steps = [
    t("processRelationship.wizard.section.base"),
    t("processRelationship.wizard.section.visits"),
    t("processRelationship.wizard.section.users"),
    t("processRelationship.wizard.section.report"),
    t("processRelationship.wizard.section.pdt"),
  ];

  const [open, setOpen] = useState(false);

  const handleNext = () => {
    if (objectChangedValues.length > 0 && activeStep === 2) {
      setOpen(true)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BaseForm
            massiveLists={massiveLists}
            geofences={geofences}
            initialValues={currentFormData.BaseForm}
            updateValues={(values, isValid) => {
              if (isValid) {
                setNextEnabled(true);
                const { elementType, elements } = values;
                if (
                  elements &&
                  Array.isArray(elements) &&
                  elements.length > 0
                ) {
                  updateValidVisits(elementType, elements);
                }
                setCurrentFormData({
                  ...currentFormData,
                  BaseForm: values,
                });
              } else {
                setNextEnabled(false);
              }
            }}
          />
        );
      case 1:
        return (
          <VisitConfigForm
            validVisits={validVisits}
            isUpdatingValidVisits={isUpdatingValidVisits}
            processRelationshipName={currentFormData?.BaseForm?.name ?? ''}
            initialValues={currentFormData.VisitConfigForm}
            updateValues={(values, isValid) => {
              if (isValid) {
                setNextEnabled(true);
                setCurrentFormData({
                  ...currentFormData,
                  VisitConfigForm: values,
                });
                updateSelectedVisitsInfo(values.visits);
              } else {
                setNextEnabled(false);
              }
            }}
          />
        );
      case 2:
        return (
          <>
            <ObjectEditConfirmationModal
              open={open}
              setOpen={setOpen}
              objectChangedValues={objectChangedValues}
              setActiveStep={setActiveStep}
             />
            <UsersForm
              userMobiles={userMobiles}
              elementsList={elementsList}
              loadingElementsList={loadingElementsList}
              initialValues={currentFormData.UsersForm}
              updateValues={(values) => {
                setCurrentFormData({
                  ...currentFormData,
                  UsersForm: values,
                });
              }}
              paginationUsers={paginationUsers}
              paginationAction={paginationAction}
              allElementsList={allElementsList}
              objectChangedValues={objectChangedValues}
            />
          </>
        );
      case 3: 
        return (
          <ReportConfigForm 
            initialValues={currentFormData.ReportConfig}
            updateValues={(values)=>{
              setCurrentFormData({
                ...currentFormData,
                ReportConfig: values
              });
            }}
          />
        );
      case 4:
        return (
          <WorkPlanForm
            questionEngineModules={questionEngineModules}
            tableFields={tableFields}
            checkFields={checkFields}
            visitsConfigurations={visitsConfigurations}
            initialValues={currentFormData.WorkPlanForm}
            updateValues={(values, isValid) => {
              if (isValid) {
                setNextEnabled(true);
                setCurrentFormData({
                  ...currentFormData,
                  WorkPlanForm: values,
                });
              } else {
                setNextEnabled(false);
              }
            }}
          />
        );
    }
  };

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {getStepContent(activeStep)}
        <div>
          <Button
            onClick={() => {
              activeStep === 0 ? history.goBack() : handleBack();
            }}
            className={classes.buttonLeft}
            color="primary"
          >
            {activeStep === 0
              ? t("processRelationship.forms.buttons.cancel")
              : t("processRelationship.forms.buttons.previous")}
          </Button>
          <Button
            id={"next-button"}
            variant="contained"
            color="info"
            disabled={!nextEnabled}
            onClick={() => {
              activeStep !== steps.length - 1
                ? handleNext()
                : submitSuccess(currentFormData);
            }}
            className={classes.buttonNext}
          >
            {activeStep === steps.length - 1
              ? t("processRelationship.forms.buttons.save")
              : t("processRelationship.forms.buttons.next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

ProcessRelationshipWizard.propTypes = {
  submitSuccess: PropTypes.func.isRequired,
};

export default ProcessRelationshipWizard;
