import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Radio from "@material-ui/core/Radio";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";

const RadioButton = ({classes, ...props}) => {
  return (
    <FormControlLabel
      control={
        <Radio
          checked={props.checked}
          onChange={(event) => {
            props.onChange(event.target.value);
          }}
          value={props.value}
          icon={
            <FiberManualRecord
              className={classes.radioUnchecked}
              children={[]}
            />
          }
          checkedIcon={
            <FiberManualRecord
              className={classes.radioChecked}
              children={[]}
            />
          }
          classes={{
            checked: classes.radio,
            root: classes.radioRoot
          }}
        />
      }
      classes={{
        label: classes.label
      }}
      label={props.label}
    />
  );
};

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string
};

const styles = {
  ...customCheckboxRadioSwitch
};

export default compose(
  withStyles(styles),
)(RadioButton);
