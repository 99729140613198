import React from 'react';
import PropTypes from 'prop-types';
import {compose} from "recompose";

import {CircleLoader} from "react-spinners";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Styled Components
import DivFlexMarginCenter from "../components/styled-components/DivFlexMarginCenter";

const LoadingCircleView = ({classes}) => (
  <DivFlexMarginCenter>
    <CircleLoader
      sizeUnit={"px"}
      size={75}
      color={'#6AB3D4'}
      loading={true}
    />
  </DivFlexMarginCenter>
  )
;

LoadingCircleView.propTypes = {
  classes: PropTypes.object.isRequired
};

// Styles
const styles = () => ({
  center: {
    textAlign: "center"
  },
});

export default compose(
  withStyles(styles),
)(LoadingCircleView);
