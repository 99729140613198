import React from 'react';
import PropTypes from 'prop-types';

// custom components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from "components/Card/CardFooter.jsx";
import Position from 'components/Position';

const CodeBarFieldAnswerView = ({answerData}) => {
  return (
    <Card >
      <CardBody>
        <h6>{answerData.answer.CodeBarFieldAnswer}</h6>
      </CardBody>
      <CardFooter>
        <Position left>
          <small>{answerData.questionLabel}</small>
        </Position>
        <Position right>
          <small>{answerData.key}</small>
        </Position>
      </CardFooter>
    </Card>
  );
};

CodeBarFieldAnswerView.propTypes = {

};

export default CodeBarFieldAnswerView;
