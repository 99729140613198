import React, { useState } from "react";

import Button from "components/Button";

import Tooltip from "@material-ui/core/Tooltip";
import WarningIcon from "@material-ui/icons/Warning";

const ConfirmIconButton = ({
  t,
  baseTooltip,
  confirmTooltip,
  confirmDelay,
  confirmOperation,
  ...props
}) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  if (!isConfirming) {
    return (
      <Tooltip title={t(baseTooltip)}>
        <span>
          <Button
            justIcon
            round={!props.simple}
            simple={props.simple}
            size="sm"
            color={props.baseColor ? props.baseColor : "danger"}
            onClick={() => {
              setIsConfirming(true);
              setTimeout(() => {
                setIsConfirming(false);
              }, confirmDelay);
            }}
          >
            <props.baseIcon />
          </Button>
        </span>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={t(confirmTooltip)}>
        <span>
          <Button
            justIcon
            disabled={isDisabled}
            round={!props.simple}
            simple={props.simple}
            size="sm"
            color={isDisabled ? "gray" : "warning"}
            onClick={() => {
              setIsDisabled(true);
              confirmOperation();
            }}
          >
            <WarningIcon />
          </Button>
        </span>
      </Tooltip>
    );
  }
};

export default ConfirmIconButton;
