import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form, withFormik } from 'formik';
import * as yup from 'yup';

// core components
import CustomInput from 'components/CustomInput/CustomInput.jsx';

// icons
import Send from '@material-ui/icons/Send';

// custom components
import InputAdornmentError from '../components/styled/InputAdornmentError';
import ButtonIcon from '../components/styled/ButtonIcon';

//Utils
import { hasError } from '../utils/forms/inputs';

/*
 * Validation Schema for NewCommentForm
 * */
const newCommentValidationSchema = yup.object().shape({
  text: yup.string().required(),
  id: yup.string().required()
});

const NewCommentForm = props => {
  // Main hooks
  const { t } = useTranslation('tickets');

  // Formik bag
  const { errors, touched, setFieldValue, values, submitForm } = props;

  return (
    <Form>
      <Field
        name="text"
        render={({ field }) => {
          return (
            <CustomInput
              labelText={'Comentario'}
              id="name"
              type={'text'}
              formControlProps={{
                fullWidth: true
              }}
              success={!hasError(errors, 'name') && touched.name}
              error={hasError(errors, 'name')}
              inputProps={{
                autoComplete: 'true',
                endAdornment: hasError(errors, 'name') ? (
                  <InputAdornmentError />
                ) : (
                  []
                ),
                ...field
              }}
            />
          );
        }}
      />

      <ButtonIcon
        icon={Send}
        label={'Enviar comentario'}
        color={'info'}
        block={true}
        onClick={() => {
          submitForm();
        }}
      />
    </Form>
  );
};

NewCommentForm.propTypes = {
  submitSuccess: PropTypes.func.isRequired
};

const NewCommentFormWF = withFormik({
  validationSchema: newCommentValidationSchema,
  enableReinitialize: true,
  mapPropsToValues: props => {
    return {
      text: '',
      id: props.data.id
    };
  },
  handleSubmit: (values, { resetForm, props }) => {
    resetForm();
    props.submitSuccess(values);
  }
})(NewCommentForm);

export default NewCommentFormWF;
