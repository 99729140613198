import React from 'react';
import PropTypes from 'prop-types';

import Button from "../Button";

import withStyles from "@material-ui/core/styles/withStyles";
import VisibilityIcon from '@material-ui/icons/Visibility';

function PreviewButton({color, size, handleClick}){
  return(
    <Button
      justIcon
      simple
      size = {size == null ? "sm" : size}
      color = {color == null ? "info" : color}
      onClick={handleClick}
    >
      <VisibilityIcon/>
    </Button>
  );
};

PreviewButton.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "twitter",
    "facebook",
    "google",
    "linkedin",
    "pinterest",
    "youtube",
    "tumblr",
    "github",
    "behance",
    "dribbble",
    "reddit",
    "transparent"
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  handleClick: PropTypes.func
};

const styles = theme => ({
});

export default withStyles(styles)(PreviewButton);
