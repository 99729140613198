import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Field, Form, withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// UI components
import {
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  makeStyles,
} from "@material-ui/core";
import Select from "react-select";

// Utils
import { getErrorLabel, hasError } from "../../utils/forms/inputs";

const BaseFormValidationSchema = yup.object().shape({
  name: yup.string().required("isRequired"),
  description: yup.string(),
  elementType: yup.string().required(),
  elements: yup.array().required(),
});

const formatSelectOptions = (elementArray) => {
  return elementArray.map((element) => ({
    value: element._id,
    label: element.name,
  }));
};

const BaseForm = (props) => {
  const { t } = useTranslation("processRelationship");
  const classes = useStyles();
  const { massiveLists, geofences, updateValues } = props;

  // Formik bag
  const { errors, touched, values, setFieldValue, submitForm, isValid } = props;

  useEffect(() => {
    submitForm();
  }, []);
  useEffect(() => {
    if (!isValid) {
      updateValues(values, false);
    } else {
      submitForm();
    }
  }, [values, isValid]);

  return (
    <Form>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={4}>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <Field
                name="name"
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      id="name"
                      helperText={
                        hasError(errors, "name")
                          ? getErrorLabel(
                              t,
                              "processRelationship.forms.fields",
                              "name",
                              errors
                            )
                          : t(
                              "processRelationship.forms.fields.name.helperText"
                            )
                      }
                      label={t("processRelationship.forms.fields.name.label")}
                      success={!hasError(errors, "name") && touched.name}
                      error={hasError(errors, "name") && touched.name}
                      margin="normal"
                      type={"text"}
                      fullWidth
                    />
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Field
                name="description"
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      id="description"
                      helperText={t(
                        "processRelationship.forms.fields.description.helperText"
                      )}
                      label={t(
                        "processRelationship.forms.fields.description.label"
                      )}
                      type={"text"}
                      fullWidth
                      success={
                        !hasError(errors, "description") && touched.description
                      }
                      error={hasError(errors, "description")}
                    />
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">
                  {t("processRelationship.forms.fields.elementType.label")}
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="elementType"
                  name="elementType"
                  value={values.elementType}
                  onChange={(e) => {
                    setFieldValue("elements", []);
                    setFieldValue("elementType", e.target.value);
                  }}
                >
                  <FormControlLabel
                    id={"elements-massive-list"}
                    className={classes.formLabel}
                    value="MassiveList"
                    control={<Radio color="default" classes={{root: classes.radio, checked: classes.checked}} />}
                    label={t(
                      "processRelationship.forms.fields.elementType.options.massiveList"
                    )}
                  />
                  <FormControlLabel
                    id={"elements-geofence"}
                    className={classes.formLabel}
                    value="Geofences"
                    control={<Radio color="default" classes={{root: classes.radio, checked: classes.checked}} />}
                    label={t(
                      "processRelationship.forms.fields.elementType.options.geofences"
                    )}
                  />
                </RadioGroup>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12}>
              {values.elementType === "MassiveList" ? (
                <Select
                  inputId={"select-elements"}
                  placeholder={t(
                    "processRelationship.forms.fields.element.options.massiveList"
                  )}
                  value={values.elements}
                  onChange={(item) => {
                    setFieldValue("elements", [item]);
                  }}
                  options={formatSelectOptions(massiveLists)}
                />
              ) : (
                <Select
                  inputId={"select-elements"}
                  placeholder={t(
                    "processRelationship.forms.fields.element.options.geofences"
                  )}
                  value={values.elements}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  onChange={(item) => {
                    setFieldValue("elements", item);
                  }}
                  options={formatSelectOptions(geofences)}
                />
              )}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Form>
  );
};

BaseForm.propTypes = {
  massiveLists: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  geofences: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  updateValues: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    textAlign: "center",
  },
  formControlEmail: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formLabel: {
    marginLeft: "auto",
    marginRight: "auto",
    color: '#000'
  },
  radio: {
    '&$checked': {
      color: '#6ab3d4'
    }
  },
  checked: {}
}));

const BaseFormWF = withFormik({
  validationSchema: BaseFormValidationSchema,
  validateOnChange: true,
  validateOnMount: true,
  mapPropsToValues: (props) => {
    return {
      name: props.initialValues.name || "",
      description: props.initialValues.description || "",
      elementType: props.initialValues.elementType || "MassiveList",
      elements: props.initialValues.elements,
    };
  },
  handleSubmit: (values, { props }) => {
    props.updateValues(values, true);
  },
})(BaseForm);

export default BaseFormWF;
