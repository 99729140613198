import React from 'react';
import PropTypes from 'prop-types';

// core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import TableFieldAnswerView from '../../QuestionEngineAnswers/AnswerTypes/TableFieldAnswerView';

function ImageGallery({
  classes,
  table,
}){
  return (
    <GridList className={classes.gridList} cols={1}>
      <GridListTile cols={1}>
        { !!table ? 
          <TableFieldAnswerView answerData={table}/>
        :
          <div className={classes.textContainer}>
            <h3>Tabla no disponible</h3>
          </div>
        }
      </GridListTile>
    </GridList>
  );
}

const styles = theme => ({
  textContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: '100%',
    height: '100%',
  },
});

ImageGallery.propTypes = {
  image: PropTypes.object.isRequired,
  openImageLightBox: PropTypes.func.isRequired,
};

export default withStyles(styles)(ImageGallery);
