import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {useQuery} from "react-apollo-hooks";
import { Form, withFormik } from 'formik';
import * as yup from "yup";

// own components
import EngineAnswerFieldFooter from "../../Views/QuestionEngineAnswers/EngineAnswerFieldFooter";
import CancelSubmitEditForm from "./CancelSubmitEditForm";
import FieldAutosuggestSingleAsync from "../fields/FieldAutosuggestSingleAsync";
import WithConfirmation from "../WithConfirmation";

// custom components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from "components/Card/CardFooter.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";

import {MASSIVE_LIST_ITEMS_SEARCH} from "../gql/Queries";
import i18n from "../../i18n";

const validationSchema = yup.object().shape({
  value: yup.string(),
});

const MassiveListUniqueFieldAnswerEditForm = ({...props}) => {
  const { t } = useTranslation('tickets');

  // Formik bag
  const { dirty, submitForm } = props;

  // basic answer props
  const {answerData} = props;

  const [currentItemsSearch, setCurrentItemsSearch] = useState({
      "massiveList": answerData.answer.config.massiveList,
      "value_contains": ""
    }
  );

  // Query for search
  const {data, loading} = useQuery(MASSIVE_LIST_ITEMS_SEARCH, {
    variables: {
      where: currentItemsSearch
    }
  });

  return (
    <Form>
      <Card >
        <CardBody>
          <GridContainer>
            <GridItem xs={10}>
              <FieldAutosuggestSingleAsync
                name={'value'}
                placeholder={'common.views.searchPlaceholder'}
                getSuggestionId={suggestion => suggestion ? suggestion.value : ''}
                getSuggestionName={suggestion => suggestion ? suggestion.value : ''}
                suggestionList={
                  loading ?
                    [] :
                    [...data.massiveListItems, ...answerData.answer.MassiveListUniqueFieldAnswer] || []}
                findSelectedSuggestionCustom={
                  (suggestionList, value) => suggestionList ?
                    suggestionList.find((suggestion) => {
                      return suggestion.value === value;
                    }) : []}
                loadSuggestions={(value) => {
                  setCurrentItemsSearch({
                    ...currentItemsSearch,
                    "value_contains": value
                  })
                }}
              />
            </GridItem>
            <GridItem xs={2}>
              <CancelSubmitEditForm
                cancelEdit={() => props.cancelEdit(answerData)}
                submitForm={submitForm}
                enableSubmit={dirty}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <EngineAnswerFieldFooter answerData={answerData} />
        </CardFooter>
      </Card>
    </Form>
  );
};

MassiveListUniqueFieldAnswerEditForm.propTypes = {
  answerData: PropTypes.object.isRequired,
  submitSuccess: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired
};

const MassiveListUniqueFieldAnswerEditFormWF = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: true,
  mapPropsToValues: ({answerData}) => {
    return {
      value: answerData.answer.MassiveListUniqueFieldAnswer ?
        answerData.answer.MassiveListUniqueFieldAnswer[0] : '',
    };
  },
  handleSubmit: (values, { props }) => {
    const currentValue = !!values ? [values.value] : [];

    props.goToConfirmation({
      title: i18n.t('question-engine-module-answer.messages.confirmEditFieldTitle', {
        ns: 'question-engine-module-answer'
      }),
      message: currentValue.join(' - '),
      callback: () => {
        props.submitSuccess({
          value: !!values ? [values.value] : []
        });
      }
    })
  }
})(MassiveListUniqueFieldAnswerEditForm);

export default WithConfirmation(MassiveListUniqueFieldAnswerEditFormWF);
