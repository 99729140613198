import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import Select from 'react-select';

import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';

import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import RadioButton from "components/styled/RadioButton";

import { COMPARISON_OPERATORS } from "constants.js";
const radioSelection = {
  all: 0,
  useCriteria: 1
};

function RadioWithScoredList({ classes, onFilterChange, criterias }) {
  const operations = Object.values(COMPARISON_OPERATORS);
  const [config, setConfig] = React.useState(radioSelection.all);
  const [criteria, setCriteria] = React.useState(criterias[0]);
  const [operation, setOperation] = React.useState(operations[0]);
  const [value, setValue] = React.useState(0);

  const handleRadioChange = React.useCallback(radioOption => {
    setConfig(radioOption);
    if(radioOption === radioSelection.all) onFilterChange(null);
    else onFilterChange({
      criteria: criteria, 
      operation: operation,
      value: value
    });
  }, [criteria, operation, value, setConfig, onFilterChange]);

  const handleCriteriaChange = React.useCallback(criteriaOption => {
    onFilterChange({
      criteria: criteriaOption, 
      operation: operation,
      value: value
    })
    setCriteria(criteriaOption);
  }, [onFilterChange, operation, setCriteria, value]);

  const handleOperationChange = React.useCallback(operationOption => {
    onFilterChange({
      criteria: criteria, 
      operation: operationOption,
      value: value
    })
    setOperation(operationOption);
  }, [criteria, onFilterChange, setOperation, value]);

  const handleValueChange = React.useCallback(e => {
    onFilterChange({
      criteria: criteria, 
      operation: operation,
      value: e.target.value
    })
    setValue(e.target.value);
  }, [criteria, onFilterChange, operation, setValue]);

  return (
    <div>
      <div>
        <RadioButton
          checked={config === radioSelection.all}
          onChange={() => handleRadioChange(radioSelection.all)}
          value={radioSelection.all}
          label="Incluir todos"
        />
        <RadioButton
          checked={config === radioSelection.useCriteria}
          onChange={() => handleRadioChange(radioSelection.useCriteria)}
          value={radioSelection.useCriteria}
          label={"Respetar operacion:"}
        />
      </div>
      {config === radioSelection.useCriteria && 
        <div>
          <GridContainer>
            <GridItem xs={4}>
              <h5>Criterio</h5>
              <Select
                placeholder={"Buscar Criterio"}
                value={criteria}
                onChange={handleCriteriaChange}
                options={criterias}
              />
            </GridItem>
            <GridItem xs={4}>
              <h5>Operacion</h5>
              <Select
                placeholder={"Buscar Operacion"}
                value={operation}
                onChange={handleOperationChange}
                options={operations}
              />
            </GridItem>
            <GridItem xs={4}>
              <h5>Valor</h5>
              <TextField
                id="value"
                type="number"                
                fullWidth
                onChange={handleValueChange}
                value={value}
              />
            </GridItem>
          </GridContainer>
        </div>
      }
    </div>
  );
}

RadioWithScoredList.propTypes = {
};

const styles = theme => ({
});

export default withStyles(styles)(RadioWithScoredList);
