import React from "react";
import { PENALTIES_REQUISITIONS, REQUISITIONS } from "./gql/Queries";
import { useQuery } from "react-apollo-hooks";
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_REQUISITION_PENALTY,
    DELETE_REQUISITION_PENALTY,
  UPDATE_REQUISITION_PENALTY,
} from "./gql/Mutations";
import PenaltiesWorkPlanView from "../../Views/ProcessRelationship/PenaltiesWorkPlanView";
import PenaltiesRequisitionsView from "../../Views/ProcessRelationship/PenaltiesRequisitionsView";
import moment from "moment";

const PenaltiesRequisitions = ({ processRelationship }) => {
  const processRelationshipId = processRelationship?._id;
  const app = processRelationship?.app;

  const {
    data: dataPenalties,
    loading: loadingPenalties,
    error: errorPenalties,
    refetch,
  } = useQuery(PENALTIES_REQUISITIONS, {
    variables: {
      where: {
        processRelationshipId: processRelationshipId,
        app: app,
      },
    },
    fetchPolicy: "network-only",
  });

  const {
    data: dataRequisitions,
    loading: loadingRequisitions,
    error: errorRequisitions,
  } = useQuery(REQUISITIONS, {
    variables: {
      where: {
        processRelationshipId: processRelationshipId,
        app: app,
        expirationDate_gte: moment(new Date()).format(),
      },
    },
    fetchPolicy: "network-only",
  });

  const [createPenaltyConfig] = useMutation(CREATE_REQUISITION_PENALTY, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [updatePenaltyConfig] = useMutation(UPDATE_REQUISITION_PENALTY, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [deletePenaltyConfig] = useMutation(DELETE_REQUISITION_PENALTY, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <PenaltiesRequisitionsView
      processRelationship={processRelationship}
      requisitions={dataRequisitions?.getRequisitionConfig ?? []}
      penalties={{
        loading: loadingPenalties,
        data: dataPenalties?.getPenaltiesRequisition ?? [],
      }}
      createPenalty={createPenaltyConfig}
      updatePenaltyConfig={updatePenaltyConfig}
      deletePenaltyConfig={deletePenaltyConfig}
    />
  );
};
export default PenaltiesRequisitions;
