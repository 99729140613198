import React, { useEffect, useState } from "react";
import { Field, Form, withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Select from "react-select";
import moment from "moment";
//import * as momentTz from "moment-timezone";
import { Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, makeStyles, Switch } from "@material-ui/core";
import Datetime from "react-datetime";
import SelectTimezone, { getTimezoneProps } from "@capaj/react-select-timezone";
import { PROCESS_RELATIONSHIP_REPORT_TYPE } from "../../constants";


const ReportConfigFormValidationSchema = yup.object().shape({
  enable: yup.boolean(),
  subject: yup.string(),
  reportType: yup.string(),
  weekday: yup.array(),
  reportHour: yup.date(),
  timezone: yup.object().shape({
    zone: yup.string().required(),
    utc: yup.string().required()
  }),
  startDate: yup.date(),
  endDate: yup.date(),
});

const ReportConfigForm = (props) => {
  const { t } = useTranslation("processRelationship");
  const classes = useStyles();
  const { setFieldValue, values, submitForm } = props;

  const reportTypeOptions = [
    {
      label: t("processRelationship.reportConfig.reportType.daily"),
      value: "Daily",
    },
    {
      label: t("processRelationship.reportConfig.reportType.weekly"),
      value: "Weekly",
    },
  ];

  const defaultTimezone = {
    zone: 'America/Mexico_City',
    utc: '-5.00'
  };

  const defaultIntervalDays = [false, false, false, false, false, false, false];
  const [intervalDays, setIntervalDays] = useState(
    values.weekday && values.weekday.length
      ? values.weekday
      : defaultIntervalDays
  );

  const [switchEnable, setSwitchEnabled] = useState(values.enable);

  useEffect(() => {
    submitForm();
  }, [values]);

  const changedIntervalDay = (event) => {

    let newIntervalDate = [
      ...defaultIntervalDays
    ];

    newIntervalDate[parseInt(event.target.name)] = event.target.checked;

    setIntervalDays(newIntervalDate);
    setFieldValue("weekday", newIntervalDate);
  };

  const changedSwitchEnable = (event) => {
    setSwitchEnabled(event.target.checked);
    setFieldValue("enable", event.target.checked);
  }

  return (
    <Form>
      <GridContainer>
        <GridContainer justify="center">
          <GridItem xs={3}>
            <FormControl
              fullWidth
              component="fieldset"
              className={classes.marginTop}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={switchEnable}
                    onChange={changedSwitchEnable}
                    name="enabledSwitch"
                    classes={{
                      root: classes.root,
                      switchBase: classes.switchBase,
                      thumb: classes.thumb,
                      track: classes.track,
                      checked: classes.checked,
                    }}
                  />
                }
                label={t(
                  "processRelationship.reportConfig.enable"
                )}
                className={classes.justifyContent}
              />
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={4}>
            <h4 className={classes.centerText}>
              {t("processRelationship.reportConfig.reportType.title")}
            </h4>
            <Field
              name="reportType"
              render={({ field }) => {
                return (
                  <Select
                    isDisabled={!switchEnable}
                    placeholder={t(
                      "processRelationship.forms.fields.reportType.label"
                    )}
                    options={reportTypeOptions}
                    value={reportTypeOptions.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(option) => {
                      setFieldValue("reportType", option.value);
                    }}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        marginTop: 10,
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        overflow: "visible",
                      }),
                      placeholder: (provided, state) => ({
                        ...provided,
                        position: "absolute",
                        top:
                          state.hasValue || state.selectProps.inputValue
                            ? -15
                            : "50%",
                        transition: "top 0.1s, font-size 0.1s",
                        fontSize:
                          (state.hasValue || state.selectProps.inputValue) &&
                          13,
                      }),
                    }}
                  />
                );
              }}
            />
          </GridItem>
        </GridContainer>
        <Divider />
        {values.reportType === PROCESS_RELATIONSHIP_REPORT_TYPE.WEEKLY ? (
          <GridContainer justify="center">
            {/* <GridItem xs={4}>mi contenido</GridItem> */}
            <GridItem xs={8}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" className={classes.centerText}>
                  {t("processRelationship.reportConfig.weekdayInfo")}
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={intervalDays[0]}
                        onChange={changedIntervalDay}
                        name="0"
                        disabled={!switchEnable}
                      />
                    }
                    label={t(
                      "processRelationship.reportConfig.weekdays.monday"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={intervalDays[1]}
                        onChange={changedIntervalDay}
                        name="1"
                        disabled={!switchEnable}
                      />
                    }
                    label={t(
                      "processRelationship.reportConfig.weekdays.tuesday"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={intervalDays[2]}
                        onChange={changedIntervalDay}
                        name="2"
                        disabled={!switchEnable}
                      />
                    }
                    label={t(
                      "processRelationship.reportConfig.weekdays.wednesday"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={intervalDays[3]}
                        onChange={changedIntervalDay}
                        name="3"
                        disabled={!switchEnable}
                      />
                    }
                    label={t(
                      "processRelationship.reportConfig.weekdays.thursday"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={intervalDays[4]}
                        onChange={changedIntervalDay}
                        name="4"
                        disabled={!switchEnable}
                      />
                    }
                    label={t(
                      "processRelationship.reportConfig.weekdays.friday"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={intervalDays[5]}
                        onChange={changedIntervalDay}
                        name="5"
                        disabled={!switchEnable}
                      />
                    }
                    label={t(
                      "processRelationship.reportConfig.weekdays.saturday"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={intervalDays[6]}
                        onChange={changedIntervalDay}
                        name="6"
                        disabled={!switchEnable}
                      />
                    }
                    label={t(
                      "processRelationship.reportConfig.weekdays.sunday"
                    )}
                  />
                </FormGroup>
              </FormControl>
            </GridItem>

            <Divider />
          </GridContainer>
        ) : (
          []
        )}

        <GridContainer justify="center">
          <GridItem xs={3}>
            <FormControl component="fieldset" className={classes.marginTop}>
              <FormLabel component="legend" className={classes.centerText}>
                {t("processRelationship.reportConfig.cutOffDate")}
              </FormLabel>
              <Datetime
                dateFormat={false}
                timeFormat={"h:mm a"}
                defaultValue={moment.utc(values.reportHour).format("h:mm a")}
                inputProps={{
                  className: classes.timePicker,
                  disabled: !switchEnable
                }}
                onChange={(d) => {
                  setFieldValue("reportHour", moment(d._d).utcOffset(0, true).format());
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={3}>
            <FormControl fullWidth className={classes.marginTop}>
              <FormLabel component="legend" className={classes.centerText}>
                {t("processRelationship.reportConfig.startDate")}
              </FormLabel>
              <Datetime
                name="startDate"
                defaultValue={moment(values.startDate).format("DD/MM/YY")}
                timeFormat={false}
                onChange={(d) => {
                  setFieldValue("startDate", moment(d._d).startOf("day"));
                }}
                inputProps={{ placeholder: "Fecha", disabled: !switchEnable }}
              />
            </FormControl>
          </GridItem>

          <GridItem xs={3}>
            <FormControl fullWidth className={classes.marginTop}>
              <FormLabel component="legend" className={classes.centerText}>
                {t("processRelationship.reportConfig.endDate")}
              </FormLabel>
              <Datetime
                name="endDate"
                defaultValue={moment(values.endDate).format("DD/MM/YY")}
                timeFormat={false}
                onChange={(d) => {
                  setFieldValue("endDate", moment(d._d).endOf("day"));
                }}
                inputProps={{ placeholder: "Fecha", disabled: !switchEnable }}
              />
            </FormControl>
          </GridItem>
        </GridContainer>

        <Divider />

        <GridContainer justify="center">
          <GridItem xs={4}>
            <FormControl
              fullWidth
              component="fieldset"
              className={classes.marginTop}
            >
              <FormLabel component="legend" className={classes.centerText}>
                {t("processRelationship.reportConfig.timezone")}
              </FormLabel>
              <SelectTimezone
                disabled={!switchEnable}
                isClearable
                guess
                value={values.timezone.zone}
                onChange={(val) => {
                  const timezoneProps = getTimezoneProps(val);

                  setFieldValue("timezone", {
                    zone: val,
                    utc: timezoneProps.time,
                  });
                }}
              />
            </FormControl>
          </GridItem>
        </GridContainer>
      </GridContainer>
    </Form>
  );
};

const ReportConfigFormWF = withFormik({
  validationSchema: ReportConfigFormValidationSchema,
  mapPropsToValues: (props) => {
    const { initialValues } = props;

    const configuration = {
      enable: initialValues?.enable || false,
      subject: initialValues?.subject || "",
      reportType: initialValues?.reportType || "Daily",
      weekday: initialValues?.weekday || [false, false, false, false, false, false, false],
      reportHour: initialValues?.reportHour || moment().endOf("day"),
      timezone: initialValues?.timezone || {
        zone: "America/Mexico_City",
        utc: "-5.00",
      },
      startDate: initialValues?.startDate || moment().startOf("day"),
      endDate: initialValues?.endDate || moment().endOf("year"),
    };

    return {
      ...configuration
    };
  },
  handleSubmit: (values, { props }) => {
    let startDate = new Date(values.startDate);
    let endDate = new Date(values.endDate);

    const hours = new Date(values.reportHour).getHours();
    const mins = new Date(values.reportHour).getMinutes();
    const secs = new Date(values.reportHour).getSeconds();



    startDate.setHours(hours, mins, secs);
    endDate.setHours(hours, mins, secs);

    props.updateValues({
      ...values,
      startDate,
      endDate
    });
  },
})(ReportConfigForm);

const useStyles = makeStyles((theme) => ({
  centerText: {
    textAlign: "center",
  },
  justifyContent: {
    justifyContent: "center"
  },
  formControl: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(16),
  },
  timePicker: {
    fontSize: "20px",
    textAlign: "center",
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
  root: {
    width: 42,
    height: 26,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #000",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}));

export default ReportConfigFormWF;
