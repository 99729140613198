import React, {useMemo, useState} from 'react';
import ChecklistAnalisysVisitReportView from '../../Views/Reports/ChecklistAnalisysVisitReportView';
import {useQuery } from "react-apollo-hooks";
import useGlobal from '../../store';
import { All_CHECKLIST_ANALISYS_VISIT_FEED } from './gql/Queries';
import { MANUAL_CHECKLIST_SUMMARY_VISIT, UPDATE_CHECKLIST_ANALISYS_REPORT_MUTATION } from './gql/Mutations';
import { useTranslation } from "react-i18next";
import moment from "moment";
import auth from '../../utils/auth';
import history from '../../utils/history';
import { useMutation } from "@apollo/react-hooks";


const ChecklistAnalisysVisitReport = () => {
    const { t } = useTranslation(["checklistAnalisys", "common"]);

    const [ session , globalActions] = useGlobal(
        state => state.session,
        actions => actions
      );

    const [checklistAnalisysVisits, setChecklistAnalisysVisit ] = useState({
        where: { 
          app: session.user ? session.appRef._id : "",
          archived: false,
          startDateCreated: moment().startOf('day').subtract(30, 'days'),
          endDateCreated: moment().endOf('day'),
          type_in: [],
          action_in: [],
          status_in: [],
          session: auth.getUserSessionData(session)
        },
        first: 15,
        skip: 0,
        orderBy: 'created_ASC'
    });

    const { data, loading } = useQuery(All_CHECKLIST_ANALISYS_VISIT_FEED, {
        variables: checklistAnalisysVisits,
        fetchPolicy: "network-only"
    });

    const paginationAction  = async (values) => {
      setChecklistAnalisysVisit(values);
    }

    const [updateReport] = useMutation(UPDATE_CHECKLIST_ANALISYS_REPORT_MUTATION, {
      onCompleted: (mutationData) => {
        if (mutationData) {
          // Add success notification
          globalActions.notification.openNotification("tr", {
            message: t('checklistAnalisys.banner.update'),
            color: "success"
          });
          window.location.reload(false);

        }else{
          globalActions.notification.openNotification("tr", {
            message: t('checklistAnalisys.banner.fail'),
            color: "danger"
          });
        }
      },
    });

    const [generateReportMutation] = useMutation(MANUAL_CHECKLIST_SUMMARY_VISIT , {
      onCompleted: (mutationData) => {
        if (mutationData) {
          globalActions.notification.openNotification("tr", {
            message: "Procesando Reporte",
            color: "success",
          });
        }
      }
    })
    
    
    useMemo(() => {
      if (
        session.user &&
        !auth.hasPermission("reportCenterView", session.permissions)
      ) {
        globalActions.notification.openNotification("tr", {
          message: t('common:common.hasNotPermission'),
          color: "danger"
        });
        history.push("dashboard");
      }
    }, [session]);
    
    return (
        <ChecklistAnalisysVisitReportView
            paginationAction={paginationAction}
            checklistAnalisysVisits={checklistAnalisysVisits}
            checklistAnalisys={{ loading: loading, data: data }}
            filters={checklistAnalisysVisits}
            filterChanged={(filter) => {
              let changedFilter = {
                  ...checklistAnalisysVisits,
                  where: {
                    ...checklistAnalisysVisits.where,
                    ...filter
                  }
              };
              setChecklistAnalisysVisit(changedFilter);

          }}
            submitSuccess={(report) => {
              const reportId = report._id;
              let variables = {
                data: {
                  ...report
                },
                where: {
                  _id: reportId
                }
              };

              variables.data.archived = true;

              delete variables.data._id;
              delete variables.data.visitRef;
              delete variables.data.created;
              delete variables.data.count;
              delete variables.data.programableReport;

              updateReport({ variables: variables });

              
            }}
            generateReport={(reportConfig)=>{
              const { _id, app, visit, from, to } = reportConfig;
              const variables = {
                data: {
                  _id: _id,
                  app: app,
                  visitId: visit,
                  session: {
                    user: {
                      email: session.user.email,
                      firstName: session.user.firstName,
                      lastName: session.user.lastName,
                      _id: session.user._id
                    },
                    userId: session.user._id
                  },
                  dateRange: {
                    from: moment(from).utcOffset(0, true).format(),
                    to: moment(to).utcOffset(0, true).format()
                  },
                  query: {
                    icons: {
                      '320': session.user ? session?.appRef?.iconThumbnails?.i320 : ""
                    }
                  }
                }
              };

              generateReportMutation({
                variables: variables
              });
            }}
        />
    )
};

export default ChecklistAnalisysVisitReport;
