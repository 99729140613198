import React, {useEffect, useRef, Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";
import L from 'leaflet';
import {includes} from "ramda";

// UTILS
import {timeFormat} from "../utils/dates/dates";

const UsersLocationsMap = ({usersMobile, userLocations, usersSelected, settingsMap, currrentViewMap, serCurrentViewMap, markerInfo, fullScreen}) => {

  const mapRef = useRef(null);
  const [currentUserLayers, setCurrentUserLayers] = useState([]);  

  // configure map
  useEffect(() => {    
    mapRef.current = L.map("map", {
      center: [22, -102],
      zoom: 1,
      layers: [
        L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; BeepQuest'
        })
      ],
      zoomControl: false
    });

    mapRef.current.on('zoom', function(e) {
      try{      
        serCurrentViewMap({
            zoom: e["target"]["_animateToZoom"],
            center: [e["target"]["_animateToCenter"]["lat"], e["target"]["_animateToCenter"]["lng"]]
          })
      }catch(_){}
        
    });
    mapRef.current.on('move', function(e) {
      try{
        serCurrentViewMap({
          zoom: e["target"]["_animateToZoom"],
          center: [e["target"]["_animateToCenter"]["lat"], e["target"]["_animateToCenter"]["lng"]]
        })
      }catch(_){}
      
    });

  }, []);

  // useEffect(() => {
  //   mapRef.current.setView([22, -102], 6);
  // },[centerStg])

  useEffect(() => {
    currentUserLayers.forEach(layer => mapRef.current.removeLayer(layer));

    if (!userLocations.length || !usersMobile.length) {
      mapRef.current.setView([22, -102], 6);      
      return;
    }


    const userLayers = usersMobile.reduce((result, user, index) => {
      const currentMarkerImage = markerInfo[index];
      const userLocationsFound = userLocations.filter(userLocation => {
        return userLocation.userId === user._id;
      });
      const userLocationsFoundSorted = userLocationsFound.sort((current, next) => {
        const currentDate = new Date(current.created);
        const nextDate = new Date(next.created);
        return currentDate - nextDate;
      });
      
      let userLocationsFoundSortedFiltred = []
      let userMarkers = []

      const generateMarker = (userLocation,index) => {
        const icon = L.icon({iconUrl: currentMarkerImage.file, iconAnchor: [17, 50], popupAnchor: [-5, -50]});
        const lastIcon = L.icon({
          iconUrl: settingsMap.colorPinEnd, 
          iconAnchor: [15, 50], 
          iconSize: [15+ settingsMap.size, 40 + settingsMap.size],  
          popupAnchor: [-5, -50]        
        });

        return L.marker([userLocation.location.coordinates[0], userLocation.location.coordinates[1]], {
          opacity: (index + 1) / userLocationsFound.length,
          icon: userLocationsFound.length === index + 1 ? lastIcon : icon,
          zIndexOffset: userLocationsFound.length === index + 1 ? 1000 : 0,
          riseOnHover: true
        })
          .bindPopup(`
            <p><strong>${user.firstName} ${user.lastName}</strong><br/>${user.email}<br/>${timeFormat(userLocation.created)}</p>
          `)
          .on('mouseover', e => {
            e.target.openPopup();
          })
          .on('mouseout', e => {
            e.target.closePopup();
          });
      }

      userLocationsFoundSorted.map((userLocation, index) => {
        if(settingsMap.singleLocation){
          if(userLocationsFound.length === index + 1){
            userMarkers.push(generateMarker(userLocation, index));
          }
        }else{
          userMarkers.push(generateMarker(userLocation, index));
        }
      });



      
      result.push(L.featureGroup(userMarkers).addTo(mapRef.current));

      // add lines for markers
      if(!settingsMap.singleLocation){
        const userPolylineCoordinates = userLocationsFound
          .map(userLocation => [userLocation.location.coordinates[0], userLocation.location.coordinates[1]]);
        result.push(L.polyline(userPolylineCoordinates, {color: currentMarkerImage.color}).addTo(mapRef.current));
      }
      return result;
    }, []);

    setCurrentUserLayers(userLayers);

    if (userLocations && userLocations.length) {
      mapRef.current.fitBounds(
        userLocations.map(
          userLocation => [userLocation.location.coordinates[0], userLocation.location.coordinates[1]]
        )
      );
    }
  }, [usersMobile, userLocations, settingsMap]);

  useEffect(() => {
    if (usersSelected.length) {
      const selectedLocations = userLocations.filter(userLocation => {
        return includes(userLocation.userId, usersSelected.map(userSelected => userSelected._id));
      });
      if (selectedLocations.length) {
        mapRef.current.fitBounds(
          selectedLocations.map(
            userLocation => [userLocation.location.coordinates[0], userLocation.location.coordinates[1]]
          )
        );
      }
    }else{
      if (userLocations && userLocations.length) {
        mapRef.current.fitBounds(
          userLocations.map(
            userLocation => [userLocation.location.coordinates[0], userLocation.location.coordinates[1]]
          )
        );
      }
    }    
    // mapRef.current.setView([22, -102], 6);

    mapRef.current.setView(currrentViewMap["center"], currrentViewMap["zoom"]);
  }, [usersSelected, userLocations, settingsMap]);


  return (
    <Fragment>
      <Helmet>
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.5.1/dist/leaflet.css"
              integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
              crossOrigin=""/>
        <script src="https://unpkg.com/leaflet@1.5.1/dist/leaflet.js"
                integrity="sha512-GffPMF3RvMeYyc1LWMHtK8EbPv0iNZ8/oTtHPx9/cc2ILxQ+u905qIwdpULaqDkyBKgOaB57QTMg7ztg8Jm2Og=="
                crossOrigin=""/>
        <style>
          {`
            .leaflet-container {
              height: 500px;
              width: 50px;
            }
          `}
        </style>
      </Helmet>

      <div id="map" style={{ width: "auto", height: fullScreen ? "100vh" : "calc(100vh - 300px)" }} />

    </Fragment>
  );
};

UsersLocationsMap.propTypes = {
  usersMobile: PropTypes.array.isRequired,
  userLocations: PropTypes.array.isRequired,
  selectedUser: PropTypes.string
};

export default UsersLocationsMap;
