import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form, withFormik } from 'formik';
import * as yup from "yup";

// own components
import EngineAnswerFieldFooter from "../../Views/QuestionEngineAnswers/EngineAnswerFieldFooter";
import CancelSubmitEditForm from "./CancelSubmitEditForm";
import WithConfirmation from "../WithConfirmation";

// custom components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornmentError from "../../components/styled/InputAdornmentError";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";

//Utils
import {getErrorLabel, hasError} from "../../utils/forms/inputs";
import i18n from "../../i18n";

const validationSchema = yup.object().shape({
  value: yup.string(),
});

const OpenMultilineFieldAnswerEditForm = ({...props}) => {
  const { t } = useTranslation('tickets');

  // Formik bag
  const { errors, touched, dirty, submitForm } = props;

  // refs
  let inputRef = useRef(null);

  // custom effects
  useEffect(() => {
    if (props.isEditRendered) {
      inputRef.current.focus();
    }
  }, [props.isEditRendered]);

  return (
    <Form>
      <Card >
        <CardBody>
          <GridContainer>
            <GridItem xs={10}>
              <Field name="value" render={({field}) => {
                return (
                  <CustomInput
                    id="value"
                    type={"text"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    success={!hasError(errors, 'value') && touched.value}
                    error={hasError(errors, 'value')}
                    inputProps={{
                      autoComplete: "true",
                      endAdornment: (
                        hasError(errors, 'value') ?
                          <InputAdornmentError/> : []
                      ),
                      inputRef: inputRef,
                      multiline: true,
                      ...field
                    }}
                    centered
                  />
                );
              }}/>
            </GridItem>
            <GridItem xs={2}>
              <CancelSubmitEditForm
                cancelEdit={() => props.cancelEdit(props.answerData)}
                submitForm={submitForm}
                enableSubmit={dirty}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <EngineAnswerFieldFooter answerData={props.answerData} />
        </CardFooter>
      </Card>
    </Form>
  );
};

OpenMultilineFieldAnswerEditForm.propTypes = {
  answerData: PropTypes.object.isRequired,
  submitSuccess: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired
};

const OpenMultilineFieldAnswerEditFormWF = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: true,
  mapPropsToValues: ({answerData}) => {
    return {
      value: answerData.answer.OpenMultilineFieldAnswer,
    };
  },
  handleSubmit: (values, { props }) => {
    props.goToConfirmation({
      title: i18n.t('question-engine-module-answer.messages.confirmEditFieldTitle', {
        ns: 'question-engine-module-answer'
      }),
      message: values.value,
      callback: () => {
        props.submitSuccess(values);
      }
    })
  }
})(OpenMultilineFieldAnswerEditForm);

export default WithConfirmation(OpenMultilineFieldAnswerEditFormWF);
