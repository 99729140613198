import gql from "graphql-tag";

export const MASSIVE_LIST_ITEMS_SEARCH = gql`
  query massiveListItems(
    $where: MassiveListItemWhereInput
  ) {
    massiveListItems(where: $where, orderBy: value_DESC, first: 15) {
      _id
      value
    }
  }
`;

export const USERS_GH_SEARCH = gql`
  query userGHs($where: UserGhWhereInput!){
    userGHs(where: $where, orderBy: email_DESC){
      _id
      fullName
      email
    }
  }
`;

export const REPORTS_NAME_SEARCH = gql`
  query Query($where: ReportNameInput!) {
    reportNames(where: $where)
  }
`