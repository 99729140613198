import React, { useState } from "react";
import PenaltyCountTable from "../../tables/ProcessRelationship/PenaltyCountTable";
import PenaltyRequisitionFormWF from "../../forms/ProcessRelationship/PenaltyRequisitionForm";
import PenaltiesTable from "../../tables/ProcessRelationship/PenaltiesTable";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { CircularProgress } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import PenaltiesRequisitionTable from "../../tables/ProcessRelationship/PenaltiesRequisitionTable";

const PenaltiesRequisitionsView = (
  {
  requisitions,
  penalties,
  processRelationship,
  createPenalty,
  updatePenaltyConfig,
  deletePenaltyConfig}
) => {
  const [visibleForm, setVisibleForm] = useState(false);
  const [penaltiesVisible, setPenaltiesVisible] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [currentPenalty, setCurrentPenalty] = useState({});

  const { loading, data } = penalties;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={3}>
                <PenaltyCountTable
                  setPenaltiesVisible={setPenaltiesVisible}
                  penaltiesVisible={penaltiesVisible}
                  setVisibleForm={setVisibleForm}
                  visibleForm={visibleForm}
                  setEditForm={setEditForm}
                  penalties={[
                    {
                      total: data.length,
                    },
                  ]}
                />
              </GridItem>
              {visibleForm ? (
                <GridItem xs={9}>
                  <PenaltyRequisitionFormWF
                    penalty={currentPenalty}
                    requisitions={requisitions}
                    submitValues={(values) => {
                      let variables = {
                        data: {
                          ...values,
                          processRelationshipId: processRelationship._id,
                          app: processRelationship.app
                        }
                      };

                      if(values.requisitionId === 'ALL'){
                        delete variables.data.requisitionId;
                      }
                      
                      createPenalty({
                        variables: variables,
                      });

                      setVisibleForm(false);
                    }}
                  />
                </GridItem>
              ) : null}
              {editForm ? (
                <GridItem xs={9}>
                  <PenaltyRequisitionFormWF
                    penalty={currentPenalty}
                    requisitions={requisitions}
                    submitValues={(values) => {
                      let variables = {
                        data: {
                          ...values,
                          processRelationshipId: processRelationship._id,
                          app: processRelationship.app
                        },
                        where: {
                          _id: currentPenalty._id
                        }
                      };

                      if(values.requisitionId === 'ALL'){
                        delete variables.data.requisitionId;
                      }

                      updatePenaltyConfig({
                        variables: variables,
                      });

                      setEditForm(false);
                    }}
                  />
                </GridItem>
              ) : null}

              
               <GridItem xs={12} style={{ marginTop: 15 }}>
                {data?.length ? (
                  <div>
                    {loading ? (
                      <div style={styles.progressCnt}>
                        <CircularProgress style={styles.progress} />
                      </div>
                    ) : (
                      <PenaltiesRequisitionTable
                        processRelationship={data}
                        setCurrentPenalty={setCurrentPenalty}
                        setVisibleForm={setVisibleForm}
                        setEditForm={setEditForm}
                        deletePenaltyConfig={deletePenaltyConfig}
                        data={data}
                      />
                    )}
                  </div>
                ) : null}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const styles = {
  progress: {
    margin: "16px",
  },
  progressCnt: {
    width: "100px !important",
    marginLeft: "50%",
    marginRight: "50%",
  },
};

export default PenaltiesRequisitionsView;
