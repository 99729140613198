import React, { useState, useCallback } from 'react';
import Select from 'react-select';
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from 'components/Button';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import FiltersSidebar from "components/FiltersSidebar/FiltersSidebar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import history from '../../utils/history';
import { getAcumGroupByKeyfield, getFrequencyAnswers, filterResultNA } from '../../utils/analytics/dataManipulation';
import BarChart from '../../components/Chart/BarChart';
import KeyfieldAnswersModal from '../../modals/KeyfieldAnswersModal';
import Badge from 'components/Badge/Badge';
import { useTranslation } from 'react-i18next';

const HistogramView = ({
  classes,
  activeVisitAnswer,
  keyfields,
  operation,
  operators,
  visits,
  visitAnswers,
  closedCount,
  items,
  segment,
  segments,
  handleSegmentChange,
  currentKeyfield,
  setCurrentKeyField
}) => {
    const { t } = useTranslation('histogram');

    const [selectedKeyfieldOption, setSelectedKeyfieldOption] = useState(
        {
            value: currentKeyfield ? currentKeyfield.value : keyfields[0].id, 
            label: currentKeyfield ? currentKeyfield.label : keyfields[0].name
        }
    );
    const [open, setOpen] = useState(false);
    const [answers, setAnswers] = useState({answers: [], total: 0});    


    const selectOptions = keyfields.map((keyfield) => ({
        value: keyfield.id,
        label: keyfield.name,
    }));
    
    const onKeyfieldSelectChange = useCallback(
      (keyfieldOption) => {
        setSelectedKeyfieldOption(keyfieldOption);
        setCurrentKeyField(keyfieldOption)
      },
      [setSelectedKeyfieldOption]
    );

    const ranges = ['0-10','11-20','21-30','31-40','41-50','51-60','61-70','71-80','81-90','91-100']
    const acumData = getAcumGroupByKeyfield(items, selectedKeyfieldOption, operation);
    const [validAnswers, notValidAnswer] = filterResultNA(acumData);

    const frequencies = getFrequencyAnswers(validAnswers, ranges);

    const dataFequencies = frequencies.map(frequency => frequency.total);

    const data = {
        labels: ranges,
        datasets: [{
            label: `# ${selectedKeyfieldOption.label}`,
            data: dataFequencies,
            borderWidth: 1,
            backgroundColor: [
              "rgb(255,0,0,0.3)", 
              "rgb(255,0,0,0.3)", 
              "rgb(255,0,0,0.3)", 
              "rgb(255,0,0,0.3)", 
              "rgb(229,179,1,0.3)", 
              "rgb(229,179,1,0.3)", 
              "rgb(229,179,1,0.3)", 
              "rgb(0,161,0,0.3)", 
              "rgb(0,161,0,0.3)", 
              "rgb(0,161,0,0.3)"],
        }]
    }
    
  return (
    <GridContainer>
      <GridItem xs={3}>
        <FiltersSidebar
          activeVisitAnswer={activeVisitAnswer}
          closedVisitAnswer={closedCount}
          visits={visits}
          visitAnswers={visitAnswers}
          operators={operators}
        />
      </GridItem>
      <GridItem xs={9}>
        <Button
          simple
          size="lg"
          color="info"
          onClick={history.goBack}
          className={classes.buttonNoMargin}
        >
          <ArrowBackIcon />
          <span>{t('histogram.goBack')}</span>
        </Button>
        <Card>
          <CardHeader color="info" text>
            <GridContainer>
              <GridItem xs={10}>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>
                    {t('histogram.title')}
                  </h4>
                </CardText>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <div className={classes.selectors}>
              <div>
                <Select
                  className={classes.selector}
                  placeholder={t('histogram.fields.keyfield.label')}
                  value={selectedKeyfieldOption}
                  onChange={onKeyfieldSelectChange}
                  options={selectOptions}
                />
              </div>
              <div>
                <Select
                  className={classes.selector}
                  placeholder={t('histogram.fields.segment.label')}
                  value={segment}
                  onChange={handleSegmentChange}
                  options={segments}
                />
              </div>
            </div>
            <GridContainer>
              <GridItem xs={12}>
                <div
                  className={classes.right}
                  onClick={() => {
                    const parseAnswer = {
                      answers: notValidAnswer,
                      total: notValidAnswer.length,
                    };
                    setAnswers(parseAnswer);
                    setOpen(true);
                  }}
                >
                  <Badge color={"info"}>
                    {`# ${selectedKeyfieldOption.label} ${t('histogram.noApply')} : ${notValidAnswer.length}`}
                  </Badge>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <BarChart
                  data={data}
                  setOpen={setOpen}
                  setAnswers={setAnswers}
                  frequencies={frequencies}
                />
              </GridItem>
            </GridContainer>

            <KeyfieldAnswersModal
              open={open}
              setOpen={setOpen}
              frequency={answers}
              keyfield={selectedKeyfieldOption.label}
              operation={operation}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const styles = (theme) => ({
  buttonNoMargin: {
    padding: "15px 0px",
  },
  container: {
    margin: "20px 0px",
  },
  containerSelect: {
    display: "flex",
    margin: "20px 0px",
  },
  selector: {
    margin: "10px 20px",
    width: "300px"
  },
  selectors:{
    display: "flex"
  },   
  noPadding: {
    padding: 0,
  },
  right: {
    float: "right",
    cursor: "pointer"
  }
});

export default withStyles(styles)(HistogramView);
