import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const KeyfieldAnswersModal = (props) => {
  const { open, setOpen, frequency, keyfield, operation } = props;
  const { t } = useTranslation('histogram');
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{keyfield}</TableCell>
                <TableCell align="right">{operation.name}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {frequency.answers.map((answer) => (
                <TableRow key={answer.name}>
                  <TableCell component="th" scope="row">
                    {answer.name}
                  </TableCell>
                  <TableCell align="right">{typeof answer.total === 'string' ? answer.total : answer.total.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('histogram.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KeyfieldAnswersModal;
