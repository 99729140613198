import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {useQuery, useSubscription} from "react-apollo-hooks";

import withStyles from "@material-ui/core/styles/withStyles";

// Own components
import VisitAnswerApprovalItemTimelineView from "./VisitAnswerApprovalItemTimelineView";
import VisitAnswerView from "./VisitAnswerView";

// custom components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";

// local
import {QUESTION_ANSWER_UPDATE_LOG_VISIT, VISIT_APPROVAL_ITEM_INFO} from "../../containers/Visits/gql/Queries";
import {QUESTION_ANSWERS_UPDATE_LOG_CREATED_SUBSCRIPTION} from "../../containers/Visits/gql/Subscriptions";
import useGlobal from "../../store";
import {questionAnswerUpdateLogCreatedNotification} from "../../utils/snackNotifications";

const VisitAnswerApprovalItemView = ({classes, visit, approvalItem, approvalItems,
                                       processAuthorization, processingAuthorization, processAnswerUpdateLog}) => {
  const { t, } = useTranslation('visits');
  const [ session, globalActions ] = useGlobal(
    state => state.session,
    actions => actions
  );
  const getQuestionAnswerIds = () => {
    let ids = [];
    ids.push(approvalItem.visitAnswerRef.checkIn.questionAnswersId);
    approvalItem.visitAnswerRef.activities.map(activity => ids.push(activity.questionAnswersId));
    ids.push(approvalItem.visitAnswerRef.checkOut.questionAnswersId);
    return ids;
  };

  const {
    data: dataQuestionAnswerUpdateLogs,
    error: errorQuestionAnswerUpdateLogs,
    loading: loadingQuestionAnswerUpdateLogs,
    refetch: refetchQuestionAnswerUpdateLogs
  } = useQuery(
    QUESTION_ANSWER_UPDATE_LOG_VISIT,
    {
      variables: {
        where: {
          app: session.user ? session.appRef._id : '',
          questionAnswersId_in: getQuestionAnswerIds()
        }
      },
      fetchPolicy: 'network-only'
    }
  );

  const {
    data: dataUpdateLogsSubscription,
    error: errorUpdateLogsSubscription,
    loading: loadingUpdateLogsSubscription
  } = useSubscription(QUESTION_ANSWERS_UPDATE_LOG_CREATED_SUBSCRIPTION, {
    variables: {
      where: {
        app: session.user ? session.appRef._id : '',
        questionAnswerIds: getQuestionAnswerIds()
      }
    },
    onSubscriptionData: ({client, subscriptionData}) => {
      const {node} = subscriptionData.data.questionAnswerUpdateLogCreated;
      questionAnswerUpdateLogCreatedNotification(t, globalActions, node);
      refetchQuestionAnswerUpdateLogs()
    }
  });


  return (
    <div>
      <React.Fragment>
        <GridContainer>
          <GridItem xs={6}>
            <div className={classes.mainContainer}>
              <h3 className={classes.center}><strong>{t('visits.visitAnswerApprovalItem.visitInfoTitle')}</strong></h3>
              <GridContainer className={classes.visitAnswerContainer}>
                <VisitAnswerView
                  classes={classes}
                  visitAnswer={approvalItem.visitAnswerRef}
                  questionAnswerUpdateLogs={!loadingQuestionAnswerUpdateLogs ?
                    dataQuestionAnswerUpdateLogs.questionEngineAnswersUpdateLogs : []}
                  visit={visit}
                  editable={true}
                  processAnswerUpdateLog={processAnswerUpdateLog}
                />
              </GridContainer>
            </div>
          </GridItem>
          <GridItem xs={6}>
            <VisitAnswerApprovalItemTimelineView
              classes={classes}
              approvalItems={approvalItems}
              approvalConfig={visit.checkListConfiguration.approvalConfig}
              processAuthorization={processAuthorization}
              processingAuthorization={processingAuthorization}
            />
          </GridItem>
        </GridContainer>
      </React.Fragment>
    </div>

  );
};

VisitAnswerApprovalItemView.propTypes = {
  approvalItem: PropTypes.object.isRequired,
  visit: PropTypes.object.isRequired,
  processAuthorization: PropTypes.func.isRequired,
  processAnswerUpdateLog: PropTypes.func.isRequired
};

const styles = theme => ({
  mainContainer: {
    marginTop: 50,
    padding: 15
  },
  center: {
    textAlign: "center"
  },
  visitAnswerContainer: {
    padding: 15
  },
  sectionTitle: {
    textAlign: "center"
  },
  relevantText: {
    fontWeight: 'bold'
  },
  calculationRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  }
});

export default withStyles(styles)(VisitAnswerApprovalItemView);
