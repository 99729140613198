import Moment from "moment";

export const baseDateFormat = date => Moment(date).format('dddd - Do - MMMM - YYYY, h:mm:ss A');

export const baseDateAnswerFormat = date => Moment(date).format('DD-MM-YYYY');

export const duration = (startDate, endDate) => {
  const ms = Moment(endDate).diff(Moment(startDate));
  const d = Moment.duration(ms);
  return Math.floor(d.asHours()) + Moment.utc(ms).format(':mm:ss');
};

export const durationInSeconds = (startDate, endDate) => {
  const ms = Moment(endDate).diff(Moment(startDate));
  const d = Moment.duration(ms);
  return d.hours() * 3600 + d.minutes() * 60 + d.seconds();
};

export const dateFromSeconds = (seconds) => {
  let duration = seconds;
  let hours = duration/3600;
  duration = duration % (3600);

  let min = parseInt(duration/60);
  duration = duration % (60);

  let sec = parseInt(duration);

  if (sec < 10) {
    sec = `0${sec}`;
  }
  if (min < 10) {
    min = `0${min}`;
  }
  return `${parseInt(hours, 10)}:${min}:${sec}`;
}

export const dateFrom = (startDate, endDate) => Moment(endDate).from(startDate);

export const startOfDay = (moment) => moment ? moment.startOf('day') : Moment().startOf('day');

export const endOfDay = (moment) => moment ? moment.endOf('day') : Moment().endOf('day');

export const timeFormat = (moment) => Moment(moment).format("h:mm a");

// Function replicated from web-app-gql
export const periodicityRanges = (initialDate, endDate, periodicity, numPeriods = null, resetDate = true) => {
  //periodicity: is a string with possible values: 'Weekly', 'Biweekly', 'Monthly', 'Bimonthly', 'Quarterly'
  if (typeof initialDate === "string") {
    initialDate = new Date(initialDate);
  }
  if (typeof endDate === "string") {
    endDate = new Date(endDate);
  }
  // Create periodicityRanges
  let periodicityRange = [];

  if (resetDate) {
    switch (periodicity) {
      case 'Weekly':
      case 'Biweekly':
        // Move initialDate to Monday
        if (initialDate.getDay() > 1) {
          initialDate = Moment(initialDate).add(1 - initialDate.getDay(), 'd').toDate();
        } else if (initialDate.getDay() === 0) {
          initialDate = Moment(initialDate).add(1, 'd').toDate();
        }
        break;
      case 'Monthly':
        initialDate.setDate(1);
        break;
      case 'Bimonthly':
        if (initialDate.getMonth() % 2 === 1) {
          initialDate.setMonth(initialDate.getMonth() - 1);
        }
        initialDate.setDate(1);
        break;
      case 'Quarterly':
        if (initialDate.getMonth() % 4 !== 0) {
          initialDate.setMonth(initialDate.getMonth() - initialDate.getMonth() % 4);
        }
        initialDate.setDate(1);
        break;
    }
  }
  let periodsCount = 0;
  const stepOperator = !!numPeriods ? Math.sign(numPeriods) : 1;
  while ((!endDate || initialDate < endDate) && (!numPeriods || periodsCount < Math.abs(numPeriods))) {
    let nextDate = Moment(initialDate);
    switch (periodicity) {
      case 'Weekly':
        nextDate = nextDate.add(1 * stepOperator, 'w');
        break;
      case 'Biweekly':
        nextDate = nextDate.add(2 * stepOperator, 'w');
        break;
      case 'Monthly':
        nextDate = nextDate.add(1 * stepOperator, 'M');
        break;
      case 'Bimonthly':
        nextDate = nextDate.add(2 * stepOperator, 'M');
        break;
      case 'Quarterly':
        nextDate = nextDate.add(1 * stepOperator, 'Q');
        break;
    }
    periodicityRange.push([initialDate, nextDate.toDate()]);
    initialDate = nextDate.toDate();
    periodsCount += 1;
  }

  if (stepOperator === 1) {
    return periodicityRange;
  } else {
    return periodicityRange.map(item => item.reverse()).reverse()
  }
}
