import React from 'react';
import PropTypes from 'prop-types';
import {Field} from "formik";

// custom components
import EmailTagsInput from "../../components/styled/EmailTagsInput";

// styled components
import DivMargin from "../../components/styled-components/DivMargin";

const FieldEmailArrayTags = ({name, placeholder, label}) => {

  return (
    <Field name={name}>
      {({field, form}) => {
        return (
          <DivMargin>
            <EmailTagsInput
              tags={field.value}
              tagsChanged={(tags) => {
                form.setFieldValue(name, tags);
              }}
              placeholder={placeholder}
              label={label}
            />
          </DivMargin>
        )
      }}
    </Field>
  );
};

FieldEmailArrayTags.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FieldEmailArrayTags;
