import React, {useState} from 'react';
import useGlobal from "../../store";
import { PROCESS_RELATIONSHIP, WORK_PLAN_ELEMENTS_ACTIVITIES, WORK_PLAN_ELEMENTS_INDICATORS, WORK_PLAN_INDICATORS } from './gql/Queries';
import { useQuery } from "react-apollo-hooks";
import ElementsActivitiesView from '../../Views/ProcessRelationship/ElementsActivitiesView';
import moment from "moment";

const ElementsActivities = ({ match, location }) => {

    const processRelationshipId = match?.params?.id;
    const elementId = match?.params?.elementId;
    const element = location?.state?.element?.name ?? '';
    const currentFilters = location?.state?.filters;
    const datesRages = location?.state?.element?.datesRages ?? {};
    
    const [session] = useGlobal(
        (state) => state.session,
        (actions) => actions
    );

    const [filters, setFilters] = useState({
        processRelationshipId: processRelationshipId,
        app: session.user ? session.appRef._id : "",
        elementId: elementId,
        status_in: ["Pending", "Completed"],
        userId: session.user ? session.user._id : "",
        keyField: currentFilters?.keyField ? currentFilters?.keyField : {},
        visit_in: currentFilters?.visit_in ? currentFilters?.visit_in : [],
        ...datesRages
      });

    const {
      data: indicators,
      loading: loadingIndicators,
      error: errorIndicators,
    } = useQuery(WORK_PLAN_INDICATORS, {
      variables: {
          where: {
            app: session.user ? session.appRef._id : "",
            processRelationshipId: processRelationshipId,
            status_in: ["Pending"],
            elementId: elementId,
            userId: session.user ? session.user._id : "",
          }
        
      },
      fetchPolicy: "network-only",
    });

    const {
      data: processRelationship,
      loading: loadingProcessRelationship,
      error: errorProcessRelationship,
      refetch
    } = useQuery(PROCESS_RELATIONSHIP, {
      variables: {
        _id: processRelationshipId,
      },
      fetchPolicy: "network-only",
    });

    const {
        data: elementsActivities,
        loading: loadingElementsActivities,
        error: errorElementsActivities,
        refetch: refetchElements
      } = useQuery(WORK_PLAN_ELEMENTS_ACTIVITIES, {
        variables: {
          where: filters,
        },
        fetchPolicy: "network-only",
      });

    return (
      <ElementsActivitiesView
        indicators={{
          loadingIndicators: loadingIndicators,
          indicators: indicators,
        }}
        processRelationship={processRelationship?.getProcessRelationship ?? {}}
        elementsActivities={{ loadingElements: loadingElementsActivities, elements: elementsActivities?.getElementsActivities ?? [] }}
        element={element}
        setFilters={setFilters}
        filters={filters}
      />
    );
}

export default ElementsActivities;
