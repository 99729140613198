import React, {useState} from 'react';
import DashboardView from '../../Views/Dashboard/DashboardView';
import {useQuery } from "react-apollo-hooks";

// Local state
import useGlobal from '../../store';
import { USER_MOBILES_FEED, ALL_EVENTS_FEED, ALL_REPORTS_FEED, INDEXES_DASHBOARD_FEED, INDEXES_REPORT_FEED, MESSAGES_FEED } from './gql/Query';



const Dashboard = props => {
  const [ session , ] = useGlobal(
    state => state.session,
    actions => actions
  );
  const startDate = new Date();
  startDate.setDate(1);
  startDate.setMonth(startDate.getMonth()-1);

  const [userMobileFilter, ] = useState({
      where: { 
        app: session.user ? session.appRef._id : "",
        archived: false
      },
      first: 6,
      orderBy: 'created_ASC'
  });

  const [eventsFilter, ] = useState({
    where: {
      app: session.user ? session.appRef._id : "",
      client: 'gh'
    },
    first: 6,
    skip: 0,
    orderBy: "created_DESC"
  });

  const [reportsFilter, ] = useState({
    where: {
      app: session.user ? session.appRef._id : ""
    },
    first: 4,
    skip: 0,
    orderBy: "created_ASC"
  });

  const [indexesAppFilter, ] = useState({
    where: {
      app: session.user ? session.appRef._id : "",
      dateIn: startDate,
      dateEnd: new Date(),
      exclude: true,
      archived: false
    }
  });

  const [indexesReport, setIndexesReport] = useState({
    where: {
      app: session.user ? session.appRef._id : "",
      dateIn: startDate,
      dateEnd: new Date(),
      exclude: true
    }
  });

  const [messagesApp, ] = useState({
    where: {
      app: session.user ? session.appRef._id : "",
      expirationDate: new Date()
    }
  });

  const [messagesGeneral, ] = useState({
    where: {
      expirationDate: new Date()
    }
  });


  const {
    data: dataUserMobile,
    loading: loadingUserMobile,
  } = useQuery(USER_MOBILES_FEED, {
    variables: userMobileFilter,
    fetchPolicy: "network-only"
  });

  const {
    data: dataAllEvents,
    loading: loadingAllEvents,
  } = useQuery(ALL_EVENTS_FEED, {
    variables: eventsFilter,
    fetchPolicy: "network-only"
  });

  const {
    data: dataAllReports,
    loading: loadingAllReports,
  } = useQuery(ALL_REPORTS_FEED, {
    variables: reportsFilter,
    fetchPolicy: "network-only"
  });

  const {
    data: dataIndexesApp,
    loading: loadingIndexesApp,
  } = useQuery(INDEXES_DASHBOARD_FEED, {
    variables: indexesAppFilter,
    fetchPolicy: "network-only"
  });

  const {
    data: dataIndexesReports,
    loading: loadingIndexesReports,
  } = useQuery(INDEXES_REPORT_FEED, {
    variables: indexesReport,
    fetchPolicy: "network-only"
  });

  const {
    data: dataMessageApp,
    loading: loadingMessageApp,
  } = useQuery(MESSAGES_FEED, {
    variables: messagesApp,
    fetchPolicy: "network-only"
  });

  const {
    data: dataMessageGeneral,
    loading: loadingMessageGeneral,
  } = useQuery(MESSAGES_FEED, {
    variables: messagesGeneral,
    fetchPolicy: "network-only"
  });
  async function filterIndexesReport(values) {
    const { where } = values;
    where.app = indexesReport.where.app
    setIndexesReport({ where: where });
  }

  return (
    <DashboardView
      infoUsers={{ loading: loadingUserMobile, users: dataUserMobile }}
      infoEvents={{ loading: loadingAllEvents, events: dataAllEvents }}
      infoReports={{ loading: loadingAllReports, reports: dataAllReports }}
      indexesReport={{ loadingIndexes: loadingIndexesReports, indexesReports: dataIndexesReports }}
      filterIndexesReport={filterIndexesReport}
      infoApp={{ loading: loadingIndexesApp, indexes: dataIndexesApp }}
      messagesApp={{ loading: loadingMessageApp, messagesByApp: dataMessageApp }}
      messagesGeneral={{ loadingGeneral: loadingMessageGeneral, dataMessageGeneral: dataMessageGeneral }}
    />
  );
};

export default Dashboard;
