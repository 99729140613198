import React from "react";
import QuestionEngineAnswerView from "../QuestionEngineAnswers/QuestionEngineAnswerView";
import withStyles from "@material-ui/core/styles/withStyles";

const TicketReportFinisherView = ({ticketReport, classes}) => {
  if (ticketReport) {
    return (
      <div className={classes.mainContainer}>
        <h4 className={classes.center}>Información de Resolución de Ticket</h4>
        {
          ticketReport.questionEngineAnswerResolved && ticketReport.questionEngineAnswerResolved.length ?
            ticketReport.questionEngineAnswerResolved.map((questionEngineAnswer, index) => {
              return <QuestionEngineAnswerView questionEngineAnswer={questionEngineAnswer} key={index}/>
            }) : []
        }
      </div>
    );
  }else{
    return [];
  }
};

const styles = theme => ({
  mainContainer: {
    marginTop: 50,
    padding: 15
  },
  center: {
    textAlign: "center"
  }
});


export default withStyles(styles)(TicketReportFinisherView);

