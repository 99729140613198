import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import RadioButton from '../../../components/styled/RadioButton';
import SendEmailButton from "components/Reports/SendEmailButton";
import DownloadButton from "components/Reports/DownloadButton";
import { getDefaultSubject, getDefaultBody } from 'utils/emails/defaultContent';
import { REPORT_ACTIONS } from '../../../constants';

const sortEnum = {
  descending: -1,
  ascending: 1
};

const RankingView = ({
  classes, 
  items, 
  categories, 
  keyfield,
  handlePDFReportClick, 
  handleXLSReportClick, 
  handlePDFReportEmailClick,
  handleXLSReportEmailClick,
  rankingsData
}) => {
  const [selectedCategory, setSelectedCategory] = React.useState(
    {value: categories && categories[0].id ? categories[0].id : '', 
    label: categories && categories[0].name ? categories[0].name : ''
  });

  const [sorting, setSorting] = React.useState(sortEnum.descending);

  items.map((item, index) => {
    if (item.categories && !item.categories.length) {
      items[index].categories = categories.map(category => { 
        return {
          id: category.id,
          name: category.name,
          evaluation: 0
        }
       })
    }
  });
  
  const sortedItems = items.sort((itemA, itemB) => {

    let valueA = itemA.categories.find(({id}) => id === selectedCategory.value);
    let valueB = itemB.categories.find(({id}) => id === selectedCategory.value);

    if (valueA && valueB) {
      valueA = valueA.evaluation;
      valueB = valueB.evaluation;
      if (valueA < valueB) 
        return sorting === sortEnum.ascending? -1 : 1;
      if (valueA > valueB)
        return sorting === sortEnum.ascending? 1 : -1;
    }
    
    return -1;
  }); 

  const itemsList = sortedItems.map((item, index) => {
    const category = item.categories.find(({id}) => id === selectedCategory.value);

    return (
      <div key={index}>
        <span>{`${index+1}.-  ${item.keyfield.value}  ${category && category.evaluation ? category.evaluation.toFixed(2) : '0' }%`}</span>
      </div>
    );
  });

  const selectOptions = categories.map(category => ({
    value: category.id,
    label: category.name
  }));

  React.useEffect(() => {
    // After the data is being calculated we need to notify 
    // in case that the report needs to be generated
    rankingsData.current = {
      category: {
        name: selectedCategory.label,
        value: selectedCategory.value,
      },
      quantityKeyfields: sortedItems.length, 
      sorting: sorting === sortEnum.descending ? "Mejor a Peor" : "Peor a Mejor",
      items: sortedItems.map((item, idx) => {
        const category = item.categories.find(({id}) => id === selectedCategory.value);
        const categoryValue = (category && category.evaluation ? category.evaluation : 0);
        return {
          index: idx+1,
          name: item.keyfield.value,
          value: categoryValue
        }
      }),
    };
  });

  const defaultRankingsBody = {
    PDF: getDefaultBody( REPORT_ACTIONS.rankingsPDF, 
      { category: selectedCategory.label,
        keyfield: keyfield.name,
        sorting: sorting === sortEnum.descending ? "Mejor a Peor" : "Peor a Mejor",
      } ),
    XLS: getDefaultBody( REPORT_ACTIONS.rankingsXLS,
      { category: selectedCategory.label,
        keyfield: keyfield.name,
        sorting: sorting === sortEnum.descending ? "Mejor a Peor" : "Peor a Mejor",
      } )
  };

  const defaultRankingsSubject = {
    PDF: getDefaultSubject( REPORT_ACTIONS.rankingsPDF,
      { category: selectedCategory.label,
        keyfield: keyfield.name,
        sorting: sorting === sortEnum.descending ? "Mejor a Peor" : "Peor a Mejor",
      } ),
    XLS: getDefaultSubject( REPORT_ACTIONS.rankingsXLS,
      { category: selectedCategory.label,
        keyfield: keyfield.name,
        sorting: sorting === sortEnum.descending ? "Mejor a Peor" : "Peor a Mejor",
      } )
  };

  return (
    <Card>
      <CardHeader color="info" text>
        <GridContainer>
          <GridItem xs={7}>
            <CardText color="info">
              <h4 className={classes.cardTitle}>Rankings</h4>
            </CardText>
          </GridItem>
          <GridItem xs={2}>
            <CardText color="info" className={classes.noPadding}>
              <SendEmailButton
                color="white"
                defaultBody={defaultRankingsBody}
                defaultSubject={defaultRankingsSubject}
                isReady={true}
                hasXLS={true}
                sendPDFReport={handlePDFReportEmailClick}
                sendXLSReport={handleXLSReportEmailClick}
                size="lg"
              />
            </CardText>
          </GridItem>
          <GridItem xs={2}>
            <CardText color="info" className={classes.noPadding}>
              <DownloadButton
                color="white"
                isReady={true}
                size="lg"
                hasXLS={true}
                onClickPDF={handlePDFReportClick}
                onClickXLS={handleXLSReportClick}
              />
            </CardText>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        <div className={classes.optionsContainer}>
          <Select
            className={classes.selector}
            placeholder={"Seleccionar una categoría"}
            value={selectedCategory}
            onChange={setSelectedCategory}
            options={selectOptions}
          />
          <RadioButton
            checked={sorting === sortEnum.descending}
            onChange={() => {setSorting(sortEnum.descending)}}
            value="Mejores"
            label="Mejores"
          />
          <div className={classes.optionButton}>
            <RadioButton
              checked={sorting === sortEnum.ascending}
              onChange={() => {setSorting(sortEnum.ascending)}}
              value="Peores"
              label="Con Oportunidad"
            />
          </div> 
        </div>
        {itemsList}
      </CardBody>
    </Card>
  );
};

RankingView.propTypes = {
  items: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  keyfield: PropTypes.object.isRequired,
  handlePDFReportClick: PropTypes.func.isRequired,
  handleXLSReportClick: PropTypes.func.isRequired,
  handlePDFReportEmailClick: PropTypes.func.isRequired,
  handleXLSReportEmailClick: PropTypes.func.isRequired,
};

const styles = theme => ({
  optionsContainer: {
    flexDirection: "column",
    alignItems: "start",
    display: "flex",
    justifyContent: "space around"
  },
  optionSelect: {
    alignItems: "end"
  },
  optionButton: {
    marginTop: "-10px"
  },
  noPadding: {
    padding: 0
  },
  selector: {
    width: "100%"
  },
});

export default withStyles(styles)(RankingView);
