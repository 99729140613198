import React, { useState } from 'react';
import VisitSchedulingTabulatorDetailView from "../../Views/VisitScheduling/VisitSchedulingTabulatorDetailView";

const VisitSchedulingTabulatorDetail = ({ location }) => {
  const { state } = location;

  const visitScheduling = state?.visitScheduling;
  const rangeId = state?.item;
  const visitUserSchedulings = state?.visitUserSchedulings;
  const data = visitUserSchedulings.filter(item => item.rangeId === rangeId);

  return (
    <VisitSchedulingTabulatorDetailView
      visitScheduling={visitScheduling}
      visitUserSchedulings={data}
    />
  );
};
export default VisitSchedulingTabulatorDetail;
