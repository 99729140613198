export const buildActiveUserSubject = ({visit, segment, startDate, endDate}) => {
  return `Usuarios Activos en ${visit} analizado para el segmento ${segment} en ${startDate} - ${endDate}`;
}

export const buildActiveUserPDFBody = ({visit, segment, startDate, endDate, executionCount}) => {
  return `
    <p>Hola,</p>
    <br>
    <p>En este mail te envío la efectividad de ejecución de la visita ${visit} dada la ejecución definida por ${executionCount} repeticiones en este periodo.</p>
    <br>
    <p>Adjunto gráfica en donde se muestra la efectividad de ejecución de ${segment} en el periodo de fechas de ${startDate} - ${endDate}.</p>
  `;
}

export const buildActiveUserXLSBody = ({visit, segment, startDate, endDate, executionCount}) => {
  return `
    <p>Hola,</p>
    <br>
    <p>Envío adjunto un XLS con la lista de activos e inactivos de la visita ${visit} para el segmento ${segment}.</p>

  `;
}