import gql from "graphql-tag";

export const MOBILE_LOCATIONS_SEGMENT_INACTIVE = gql`
  query mobileLocationsSegmentInactiveUsers($where: MobileLocationSegmentInactiveInput!){
    mobileLocationsSegmentInactiveUsers(where: $where){
      _id
      firstName
      lastName
      email
    }
  }
`;

export const SEGMENT_USER_MOBILE = gql`
  query segmentUserMobiles(
    $where: UserMobileWhereInput!
    $first: Int
    $skip: Int
    $orderBy: UserMobileOrderByInput
  ) {
    segmentUserMobiles (
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ){
      _id
      email
      firstName
      lastName
    }
    segmentUserMobilesConnection(
      where: $where
    ){
      aggregate {
        count
      }
    }
  }
`;

export const MOBILE_LOCATIONS = gql`
  query mobileLocations($where: MobileLocationWhereInput!){
    mobileLocations(where: $where){
      _id
      userId
      app
      location {
        type
        coordinates
      }
      created
    }
  }
`;

export const GET_PLAN_FEATURES_APP = gql`
  query getPlanFeaturesApp($where: AppWhereUniqueInput!){
    getPlanFeaturesApp(where: $where){
      _id
      name
      features
    }
  }
`;
