import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";

// own components
import AdminApprovalItemsFilterForm from '../../forms/visits/AdminApprovalItemsFilterForm';
import LoadingCircleView from "../LoadingCircleView";

import withStyles from '@material-ui/core/styles/withStyles';

import {
  boxShadow,
  drawerWidth,
  transition
} from "assets/jss/material-dashboard-pro-react.jsx";

// custom components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Position from 'components/Position';
import Button from 'components/Button';
import TableList, { ActionRow } from 'components/TableList';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardText from "components/Card/CardText.jsx";
import Badge from 'components/Badge/Badge';

// icons
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardTwoTone";

// styled components
import DivCenter from "../../components/styled-components/DivCenter";

// local
import {getColorByStatus, getColorByLogStatus} from "../../utils/views/approvalItems";
import {baseDateFormat, duration} from "../../utils/dates/dates";
import history from "../../utils/history";

const AdminApprovalItemsView = (props) => {

  const { t, } = useTranslation(['visits', 'approvalItems']);

  const { classes, approvalItems, loading,
    currentFilter, filterChanged, visits } = props;

  return (
    <div className="AdminApprovalItems">
      <Card>
        <CardBody>
          <AdminApprovalItemsFilterForm
            initialValues={currentFilter}
            submitSuccess={filterChanged}
            visits={visits}
          />
        </CardBody>
      </Card>
      {
        !loading ?
          <Card>
            <CardHeader color="info" text>
              <GridContainer>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>Aprobaciones</h4>
                </CardText>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <React.Fragment>
                {typeof approvalItems !== 'undefined' &&
                approvalItems.length > 0 && (
                  <TableList
                    data={approvalItems}
                    getTrProps={(state, rowInfo, column, instance) => ({
                      onClick: e => {
                        const {visitId, visitAnswerId, _id} = rowInfo.original;
                        history.push(`/visits/${visitId}/visitAnswer/${visitAnswerId}/approve/${_id}`)
                      }
                    })}
                    columns={[
                      {
                        Header: (<DivCenter>Ir</DivCenter>),
                        id: "actions",
                        Cell: (d) => {
                          const {visitId, visitAnswerId, _id} = d.original;
                          return <DivCenter>
                            <Link to={{
                              pathname: `/visits/${visitId}/visitAnswer/${visitAnswerId}/approve/${_id}`,
                            }}>
                              <Button
                                justIcon
                                round
                                simple
                                color="info"
                              >
                                <ArrowForwardIcon/>
                              </Button>
                              {" "}
                            </Link>
                          </DivCenter>
                        },
                        filterable: false,
                        sortable: false,
                        width: 100
                      },
                      {
                        id: 'status',
                        Header: <DivCenter>{'Estado'}</DivCenter>,
                        accessor: 'status',
                        Cell: props => (
                          <Badge color={getColorByStatus(props.value)}>
                            {t(`approvalItems.model.ApprovalItemStatus.${props.value}`,
                              {ns: 'approvalItems'})}
                          </Badge>
                        ),
                        maxWidth: 120,
                        style: {textAlign: "center"},
                        sortable: false,
                        filterable: false
                      },
                      {
                        id: "approvalStart",
                        Header: (<DivCenter>Iniciada</DivCenter>),
                        accessor: "created",
                        Cell: props => {
                          return baseDateFormat(props.value);
                        },
                        filterable: false,
                        sortable: false,
                        style: { textAlign: "center" },
                        minWidth: 250
                      },
                      {
                        id: 'approvalUser',
                        Header: <DivCenter>Usuario Aprobador</DivCenter>,
                        Cell: (d) => {
                          const {firstName, lastName, email} = d.original.userRef;
                          return <DivCenter><h6>{`${firstName} ${lastName}`} <br/><small>{email}</small></h6></DivCenter>;
                        },
                        filterable: false,
                        sortable: false,
                        minWidth: 180
                      },
                      {
                        Header: (<DivCenter>Aprobación</DivCenter>),
                        style: { textAlign: "center" },
                        columns: [
                          {
                            id: 'approvalLogStatus',
                            Header: <DivCenter>Estatus</DivCenter>,
                            accessor: 'approvalLogRef.status',
                            Cell: props => props.value ? (
                              <Badge color={getColorByLogStatus(props.value)}>
                                {t(`approvalItems.model.ApprovalLogStatus.${props.value}`,
                                  {ns: 'approvalItems'})}
                              </Badge>
                            ) : "",
                            maxWidth: 120,
                            style: {textAlign: "center"},
                            sortable: false,
                            filterable: false
                          },
                          {
                            id: 'approvalLogType',
                            Header: <DivCenter>Tipo</DivCenter>,
                            accessor: 'approvalLogRef.type',
                            Cell: props => props.value ? (
                              <Badge color={'info'}>
                                {t(`approvalItems.model.ApprovalLogType.${props.value}`,
                                  {ns: 'approvalItems'})}
                              </Badge>
                            ) : "",
                            minWidth: 200,
                            style: {textAlign: "center"},
                            sortable: false,
                            filterable: false
                          },
                          {
                            id: "approvalEnd",
                            Header: (<DivCenter>Completada</DivCenter>),
                            accessor: "approvalLogRef.created",
                            Cell: props => {
                              return props.value ? baseDateFormat(props.value) : "";
                            },
                            filterable: false,
                            sortable: false,
                            style: { textAlign: "center" },
                            minWidth: 250
                          },
                          {
                            id: "approvalDuration",
                            Header: (<DivCenter>Tiempo de Aprobación</DivCenter>),
                            Cell: (d) => {
                              return d.original.approvalLogRef ?
                                duration(d.original.created, d.original.approvalLogRef.created)
                                : "";
                            },
                            filterable: false,
                            sortable: false,
                            style: { textAlign: "center" },
                            minWidth: 100
                          }
                        ]
                      },
                      {
                        id: 'visitName',
                        Header: <DivCenter>{'Visita'}</DivCenter>,
                        accessor: 'visitRef.name',
                        minWidth: 200,
                        style: {textAlign: "center"},
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: (<DivCenter>Respuesta de Visita</DivCenter>),
                        style: { textAlign: "center" },
                        columns: [
                          {
                            id: 'visitAnswerUser',
                            Header: <DivCenter>Usuario</DivCenter>,
                            Cell: (d) => {
                              const {firstName, lastName, email} = d.original.visitAnswerRef.userRef;
                              return <DivCenter><h6>{`${firstName} ${lastName}`} <br/><small>{email}</small></h6></DivCenter>;
                            },
                            filterable: false,
                            sortable: false,
                            minWidth: 180
                          },
                          {
                            id: "visitAnswerCheckIn",
                            Header: (<DivCenter>{t('visits.list.columns.checkInDate')}</DivCenter>),
                            accessor: "visitAnswerRef.checkInDate",
                            Cell: (d) => {
                              return baseDateFormat(d.original.visitAnswerRef.checkInDate);
                            },
                            filterable: false,
                            sortable: false,
                            style: { textAlign: "center" },
                            minWidth: 250
                          },
                          {
                            id: "visitAnswerCheckOut",
                            Header: (<DivCenter>{t('visits.list.columns.checkOutDate')}</DivCenter>),
                            accessor: "visitAnswerRef.checkOutDate",
                            Cell: (d) => {
                              return baseDateFormat(d.original.visitAnswerRef.checkOutDate);
                            },
                            filterable: false,
                            sortable: false,
                            style: { textAlign: "center" },
                            minWidth: 250
                          },
                          {
                            id: "visitAnswerDuration",
                            Header: (<DivCenter>{t('visits.list.columns.duration')}</DivCenter>),
                            Cell: (d) => {
                              const {checkInDate, checkOutDate} = d.original.visitAnswerRef;
                              return duration(checkInDate, checkOutDate);
                            },
                            filterable: false,
                            sortable: false,
                            style: { textAlign: "center" },
                            minWidth: 100
                          }
                        ]
                      }
                    ]}
                    minRows={0}
                    pageSize={approvalItems.length}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    style={{
                      maxHeight: "500px"
                    }}
                  />
                )}
                {
                  typeof approvalItems !== 'undefined' &&
                  !approvalItems.length ?
                    <div style={{
                      display: 'flex',
                      height: '75vh',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                      <h3>{t('approvalItems.admin.noApprovalItems')}</h3>
                    </div>
                    : []
                }
              </React.Fragment>
            </CardBody>
          </Card>
          : <LoadingCircleView />
      }

    </div>
  );
};

const styles = theme => ({
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    ...boxShadow,
    width: drawerWidth,
    ...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    ...transition,
    "&:before,&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      top: "0"
    },
    "&:after": {
      background: "#000",
      opacity: ".8"
    }
  },
});

export default withStyles(styles)(AdminApprovalItemsView);
