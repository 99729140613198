import React, { useState } from "react";
import PenaltyCountTable from "../../tables/ProcessRelationship/PenaltyCountTable";
import PenaltyForm from "../../forms/ProcessRelationship/PenaltyForm";
import PenaltiesTable from "../../tables/ProcessRelationship/PenaltiesTable";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { CircularProgress } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

const PenaltiesWorkPlanView = (
  {processRelationship,
  createPenalty,
  updatePenaltyConfig,
  deletePenaltyConfig}
) => {
  const [visibleForm, setVisibleForm] = useState(false);
  const [penaltiesVisible, setPenaltiesVisible] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [currentPenalty, setCurrentPenalty] = useState({});

  const { loading, data } = processRelationship;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={3}>
                <PenaltyCountTable
                  setPenaltiesVisible={setPenaltiesVisible}
                  penaltiesVisible={penaltiesVisible}
                  setVisibleForm={setVisibleForm}
                  visibleForm={visibleForm}
                  setEditForm={setEditForm}
                  penalties={[
                    {
                      total: data?.workPlanConfig?.penaltiesConfig?.length ?? 0,
                    },
                  ]}
                />
              </GridItem>
              {visibleForm ? (
                <GridItem xs={9}>
                  <PenaltyForm
                    workPlanConfig={data?.workPlanConfig ?? {}}
                    submitValues={(values) => {
                      const variables = {
                        data: {
                          ...values,
                        },
                        where: {
                          processRelationshipId: data._id,
                        },
                      };

                      createPenalty({
                        variables: variables,
                      });

                      setVisibleForm(false);
                    }}
                  />
                </GridItem>
              ) : null}
              {editForm ? (
                <GridItem xs={9}>
                  <PenaltyForm
                    workPlanConfig={currentPenalty}
                    submitValues={(values) => {
                      const variables = {
                        data: {
                          ...values,
                        },
                        where: {
                          processRelationshipId: data._id,
                          penaltyId: currentPenalty.penaltiesConfig._id,
                        },
                      };

                      updatePenaltyConfig({
                        variables: variables,
                      });

                      setEditForm(false);
                    }}
                  />
                </GridItem>
              ) : null}

              <GridItem xs={12} style={{ marginTop: 15 }}>
                {data?.workPlanConfig?.penaltiesConfig?.length ? (
                  <div>
                    {loading ? (
                      <div style={styles.progressCnt}>
                        <CircularProgress style={styles.progress} />
                      </div>
                    ) : (
                      <PenaltiesTable
                        processRelationship={data}
                        setCurrentPenalty={setCurrentPenalty}
                        setVisibleForm={setVisibleForm}
                        setEditForm={setEditForm}
                        deletePenaltyConfig={deletePenaltyConfig}
                        data={data?.workPlanConfig?.penaltiesConfig ?? []}
                      />
                    )}
                  </div>
                ) : null}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const styles = {
  progress: {
    margin: "16px",
  },
  progressCnt: {
    width: "100px !important",
    marginLeft: "50%",
    marginRight: "50%",
  },
};

export default PenaltiesWorkPlanView;
