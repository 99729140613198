import React from "react";
import Datetime from "react-datetime";
// used for making the prop types of this component
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardHeaderSimple from "components/Header/CardHeaderSimple"
import { useTranslation } from "react-i18next";
import Moment from 'moment';

function DateRangeInput({classes, startDate, endDate, setStartDateRange, setEndDateRange}){

  const { t } = useTranslation("general_adaptation");

  return (
    <Card className={classes.cardLessBorder}>      
      <CardHeaderSimple title={t("daterange")} />  
      <CardBody>
        <Datetime
          name={'startDateRange'}
          timeFormat={false}
          defaultValue={startDate}
          onChange={e => {
            if (!!e){
              setStartDateRange(Moment(e).startOf('day'));
            }
          }}
        />
        <Datetime
          name={'endDateRange'}
          timeFormat={false}
          defaultValue={endDate}
          onChange={e => {
            if (!!e){
              setEndDateRange(Moment(e).endOf('day'));
            }
          }}
        />
      </CardBody>
    </Card>
  );  
}

DateRangeInput.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.number,
  setStartDateRange: PropTypes.func,
  setEndDateRange: PropTypes.func,
};

const styles = theme => ({
  cardLessBorder: {
    marginBottom: "0px"
  }
});

export default withStyles(styles)(DateRangeInput);