import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Select from 'react-select';
import DescriptionIcon from '@material-ui/icons/Description';
import LastReportsGeneratedTable from '../../../tables/Dashboard/LastReportsGeneratedTable';
import withStyles from "@material-ui/core/styles/withStyles";
import { CircularProgress } from '@material-ui/core';
import Button from "components/CustomButtons/Button.jsx";
import history from '../../../utils/history';
import IndexesReportsTable from '../../../tables/Dashboard/IndexesReportsTable';
import { useTranslation } from 'react-i18next';

const LastReports = ({infoReports, indexesReport, classes, filterIndexesReport}) => {
  const { t } = useTranslation("general_adaptation");
  const createStartDate = period => {
    const startDate = new Date();
    startDate.setDate(1);
    startDate.setMonth(startDate.getMonth()-period);

    return startDate;
  }
  let options = [
    {
      value: 1,
      label: 'Último Mes',
      dateIn: createStartDate(1),
      dateEnd: new Date()
    },
    {
      value: 2,
      label: 'Último Semestre',
      dateIn: createStartDate(6),
      dateEnd: new Date()
    },
     {
      value: 3,
      label: 'Último Año',
      dateIn: createStartDate(12),
      dateEnd: new Date()
    },
  ];

  const handleChange = selected => {
    filterIndexesReport({
      where: { dateIn: selected.dateIn, dateEnd: selected.dateEnd }
    });
  };

  const { loading, reports } = infoReports;
  const { loadingIndexes, indexesReports } = indexesReport;
  const [ selectOptions, setSelectionOption ] = useState(options[0]);

  return (
    <Card className={classes.cardHeight}>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <DescriptionIcon />
        </CardIcon>
        <div className={classes.left}>
          <h4 className={classes.cardTitle}>{t("lastreports.title")}</h4>
        </div>
        <div className={classes.right}>
          <Button
            size="sm"
            color="beepquest"
            onClick={() => history.push(`/reports`)}
          >            
            {t("lastreports.subtitle")}
          </Button>
        </div>
      </CardHeader>
      <CardBody className={classes.scrollVertical}>
        {loading ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <LastReportsGeneratedTable
            lastReports={reports.allReports ? reports.allReports : []}
            type={true}
            name={false}
            nameAction={true}
            action={false}
            user={false}
            email={false}
            status={false}
            created={true}
            completedDate={false}
            url={false}
            pageSize={4}
          />
        )}

        <Select
          className={classes.selector}
          placeholder={t("lastreports.filter")}
          value={selectOptions}
          options={options}
          onChange={handleChange}
        />

        {loadingIndexes ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <IndexesReportsTable
            indexesReports={
              indexesReports.indexesReport ? [indexesReports.indexesReport] : []
            }
          />
        )}
      </CardBody>
    </Card>
  );
};

LastReports.proTypes = {
  infoReports: PropTypes.object.isRequired,
  indexesReport: PropTypes.array.isRequired,
}

const styles = theme => ({
  selector: {
    paddingTop: "10px",
    paddingBottom: "10px",
    width: "200px",
    position: "absolute"
  },
  selectors:{
    display: "flex"
  },
  cardTitle: {
    color: "#3C4858"
  },
  progress:{
    marginLeft: '50%',
    marginRight: '50%'
  },
  cardHeight:{
    height: "430px"
  },
  scrollVertical: {
    overflowY: 'scroll'
  },
  left:{
    float: 'left'
  },
  right:{
    float: 'right'
  }

});

export default withStyles(styles)(LastReports); 
