import React from "react";

// used for making the prop types of this component
import PropTypes from "prop-types";

import InformationCheckbox from '../InformationCheckbox/InformationCheckbox';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import withStyles from "@material-ui/core/styles/withStyles";
import { GLOBAL_CATEGORIES } from "../../constants";

function InformationCheckboxList({classes, items, config, states, setStates, global, setGlobal, operation}){
  const GLOBAL_ROW = operation ? GLOBAL_CATEGORIES[operation.value] : GLOBAL_CATEGORIES[1];
  const onClickCheckbox = React.useCallback((index) => {
    if(config.hasGlobal && index === GLOBAL_ROW.id){
      let newStates = [...states];
      newStates.fill(false);
      setStates(newStates);
      setGlobal(!global);
    } else if(config.unique){
      let newStates = [...states];
      newStates[index] = !newStates[index];
      states.forEach((state, idx) => {
        if(state === true){
          newStates[idx] = false;  
        }
      });
      if(global) setGlobal(false);
      setStates(newStates);
    } else {
      let newStates = [...states];
      newStates[index] = !newStates[index];
      setStates(newStates);
    }
  }, [config, global, states, setStates, setGlobal, GLOBAL_ROW]);

  const itemsComponents = items.map((item, index) => {
    return (
      <GridItem lg={6} sm={6} xs={12}>

        <InformationCheckbox
          isUnique={config.unique}
          name={item.name} 
          key={item.id} 
          isActive={!!states[index]}
          onClickCheckbox={() => onClickCheckbox(index)}
          onViewClick={() => config.onViewClick(item)}
        />
      </GridItem>
    );
  });

  return (
    <React.Fragment>
      <GridContainer>
          
      { config.hasGlobal && 
        <GridItem lg={6} sm={6} xs={12}>
          <InformationCheckbox
            isUnique={config.unique}
            name={GLOBAL_ROW.name} 
            key={GLOBAL_ROW.id} 
            isActive={global}
            onClickCheckbox={() => onClickCheckbox(GLOBAL_ROW.id)}
            onViewClick={() => config.onViewClick(GLOBAL_ROW)}
          />
        </GridItem>
      }
      
        {itemsComponents}
      
      </GridContainer>
    </React.Fragment>
  );
}

InformationCheckboxList.propTypes = {
  items: PropTypes.array,
  config: PropTypes.shape({
    hasGlobal: PropTypes.bool.isRequired,
    unique: PropTypes.bool.isRequired,
    setSelected: PropTypes.func.isRequired,
    onViewClick: PropTypes.func.isRequired
  })
};

const style = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly"
    
  },
};

export default withStyles(style)(InformationCheckboxList);