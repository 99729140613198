import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import DivCenter from '../../components/styled-components/DivCenter';
import moment from "moment";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {useTranslation} from 'react-i18next';

const LastUsersRegisterTable = ({ users, pageSize }) => {
    const { t, } = useTranslation('dashboard');
    return (
      <ReactTable
        data={users}
        columns={[
          {
            Header: '',
            accessor: "icon",
            Cell: d => {
                return (<AccountCircleIcon style={{ color: "#006aa3"}} />);
            },
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 10
          },
          {
            
          Header: <DivCenter>{t('dashboard.list.columns.name')}</DivCenter>,
            accessor: "firstName",
            Cell: d => {
                return `${d.original.firstName} ${d.original.lastName}`;
            },
            filterable: false,
            sortable: false,
            style: { textAlign: "left" },
            minWidth: 20
          },
          {
            Header: <DivCenter>{t('dashboard.list.columns.email')}</DivCenter>,
            accessor: "email",
            filterable: false,
            sortable: false,
            style: { textAlign: "left" },
            minWidth: 30
          },
          {
            id: 'created',
            Header: <DivCenter>{t('dashboard.list.columns.date')}</DivCenter>,
            accessor: d => {
                return moment(d.created)
                .local()
                .format("DD-MM-YY : hh:mm A");
            },
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            minWidth: 20
          }
        ]}
        defaultPageSize={pageSize}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
    );
};

LastUsersRegisterTable.propTypes = {
    users: PropTypes.array.isRequired,
}

export default LastUsersRegisterTable;
