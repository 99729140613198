import React, { useState } from 'react';
import useGlobal from "../../store";
import { useQuery } from "@apollo/react-hooks";
import { REQUISITIONS_ACTIVITIES, REQUISITION_INDICATORS } from './gql/Queries';
import RequisitionsView from '../../Views/ProcessRelationship/RequisitionsView';
import { useMutation } from "@apollo/react-hooks";
import { GENERATE_REQUISITION_ACTIVITIES_REPORT_XSL, UPDATE_REQUISITION_ACTIVITY } from './gql/Mutations';
import moment from "moment";

const RequisitionList = ({ match, location }) => {
    const processRelationshipId = match?.params?.id ?? '';
    const requisitionId = match?.params?.requisitionId ?? '';

    const requisition = location?.state?.element;

    const [session, globalActions] = useGlobal(
        (state) => state.session,
        (actions) => actions
      );

      const [filters, setFilters] = useState({
        processRelationshipId: processRelationshipId,
        requisitionId: requisitionId,
        app: session.user ? session.appRef._id : "",
        userId: session.user ? session.user._id : "",
        //expirationDate_gte: moment(new Date()).startOf("day").format(),
        archived: false
      });
    
      const [status, setStatus] = useState("All");
      const [showArchived, setShowArchived] = useState(false);
    
      const {
        data: dataRequisitions,
        loading: loadingRequisitions,
        refetch
      } = useQuery(REQUISITIONS_ACTIVITIES, {
        variables: {
          where: filters,
        },
        fetchPolicy: "network-only",
      });

      const {
        data: dataRequisitionsIndicator,
        loading: loadingRequisitionsIndicator,
        refetch: refetchIndicator
      } = useQuery(REQUISITION_INDICATORS, {
        variables: {
          where: filters,
        },
        fetchPolicy: "network-only",
      });

      const [updateRequisition] = useMutation(UPDATE_REQUISITION_ACTIVITY, {
        onCompleted: (mutationData) => {
          refetch();
          refetchIndicator();
        },
        onError: (error) => {
          console.log(error);
        },
      });

      const [generateRequisitionActivitiesReportMutation] = useMutation(GENERATE_REQUISITION_ACTIVITIES_REPORT_XSL, {
        onCompleted: (mutationData) => {
          if (mutationData) {
            globalActions.notification.openNotification("tr", {
              message: "Procesando Reporte",
              color: "success",
            });
          }
        },
      });

      return (
        <RequisitionsView
          requisitions={{
            loading: loadingRequisitions,
            data: dataRequisitions?.getRequisitionsActivitiesUnfiltered ?? [],
          }}
          processRelationshipId={processRelationshipId}
          requisition={requisition}
          indicator={dataRequisitionsIndicator?.getRequisitionIndicator ?? {}}
          processAuthorization={(requisitionId, data) => {
            const variables = {
              data: {
                ...data,
                log: {
                  userId: session.user._id,
                  userType: "UserGh",
                  status: data.status,
                },
              },
              where: {
                _id: requisitionId,
              },
            };

            updateRequisition({
              variables: variables,
            });
          }}
          filters={filters}
          setFilters={setFilters}
          status={status}
          setStatus={setStatus}
          showArchived={showArchived}
          setShowArchived={setShowArchived}
          generateReport={({ from, to }) => {
            const variables = {
              data: {
                app: session.appRef._id,
                session: {
                  user: {
                    email: session.user.email,
                    firstName: session.user.firstName,
                    lastName: session.user.lastName,
                    _id: session.user._id,
                  },
                },
                dateRange: {
                  from: from, //moment(from).utcOffset(0, true).format(),
                  to: to, //moment(to).utcOffset(0, true).format()
                },
                query: {
                  timezone: moment.tz.guess(),
                  ...filters
                },
              },
            };

            generateRequisitionActivitiesReportMutation({
              variables,
            });
          }}
          refetch={refetch}
        />
      );
}

export default RequisitionList;
