import React, { useState, useCallback, useMemo } from 'react';
import ChronologicalReportView from '../../Views/Reports/ChronologicalReportView';
import moment from 'moment';
import {useQuery, useMutation} from "react-apollo-hooks";
import useGlobal from "../../store";
import { SEGMENT_ITEMS, USER_SEGMENT_ITEMS, VISIT_ANSWER_COUNT, VISIT_ANSWER_ITEMS, VISIT_ITEMS } from '../Visits/gql/Queries';
import LoadingProcessView from '../../Views/LoadingProcessView';
import { classifyAndFilter, filterVisitAnswerByUsersSegment, getKeyfields, parseVisitAnswers, reduceVisitanswerKeyField, reduceVisitAnswerKeyfieldValues } from '../../utils/analytics/dataManipulation';
import { CREATE_CHRONOLOGICAL_REPORT_MUTATION } from './gql/Mutations';
import { reportCreationFailedNotification, reportCreationOnProgressNotification } from '../../utils/snackNotifications';
import { useTranslation } from "react-i18next";
import auth from '../../utils/auth';
import history from '../../utils/history';

const ChronologicalReport = ({ match }) => {
    const { t } = useTranslation(["chronological", "common"]);

    const [ session, globalActions] = useGlobal(
        state => state.session,
        actions => actions
    );

    const [ filters, filtersActions ] = useGlobal(
      state => state.filters,
      actions => actions.filters
    );

    const [segment, setSegment] = useState({value: "Todos", label: "Todos"});
    const [selectActivities, setSelectActivities] = useState([]);
    const [currentKeyfield, setCurrentKeyField] = React.useState(null);
    const [keyfieldsValues, setKeyfieldsValues] = React.useState([]);
    const [frequency, setFrequency] = useState({value: 2, label: "Semanal"});

    const handleSegmentChange = useCallback((option) => {
        setSegment(option);
        setKeyfieldsValues([]);
    }, [setSegment]);

    const handleActivitiesChange = useCallback((options) => {
        setSelectActivities(options);
    }, [setSelectActivities]);

    const handleKeyFieldsChange = useCallback((options) => {
        setCurrentKeyField(options);
        setKeyfieldsValues([]);
    }, [setCurrentKeyField]);

    const handleFrequencyChange = useCallback((option) => {
        setFrequency(option);
    }, [setCurrentKeyField]);

    const handleKeyfieldsValuesChange = useCallback((options) => {
        setKeyfieldsValues(options);
    }, [setKeyfieldsValues]);

    const {data: dataVisit, loading: loadingVisit} = useQuery(VISIT_ITEMS, {
        variables: {
          where: {
            app: session.user ? session.appRef._id : '',
            isArchived: false
          }
        },
        fetchPolicy: 'network-only'
    });

    const {data: dataVisitAnswers, loading: loadingVisitAnswers} = useQuery(VISIT_ANSWER_ITEMS, {
        variables: {
          where: {
            userId: session.user._id,
            app: session.appId,
            visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
            checkInDate_gt: moment(filters.range.startDate).startOf('day').format(), 
            checkInDate_lte: filters.range.endDate,
            hasCheckOutDate: true
          }
        },
        skip: !filters.selectedVisitID,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
    });

    const {data: dataVisitAnswersClosed, loading: loadingVisitAnswersClosed} = useQuery(VISIT_ANSWER_COUNT, {
      variables: {
        where: {
          userId: session.user._id,
          app: session.appId,
          visitId: filters.selectedVisitID?  filters.selectedVisitID.value : '',
          checkInDate_gt: moment(filters.range.startDate).startOf('day').format(), 
          checkInDate_lte: filters.range.endDate,
          approvalStatus_not_in: ["Rejected", "Excluded"],
          hasCheckOutDate: true
        }
      },
      skip: !filters.selectedVisitID,
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    });

    const {data: dataSegments, loading: loadingSegments } = useQuery(SEGMENT_ITEMS, {
      variables: {
        where: {
          app: session.user ? session.appRef._id : '',
          archived: false
        }
      },
      fetchPolicy: 'network-only'
    });

    const {data: dataUserSegment, loading: loadingUserSegment } = useQuery(USER_SEGMENT_ITEMS, {
        variables: {
          where: {
            app: session.user ? session.appRef._id : '',
            segment: segment.value
          }
        },
        skip: segment.value === "Todos",
        fetchPolicy: 'network-only'
    });

    // Add General Data required to all the mutation calls
    const addGeneralData = (payload) => {
        let data = { query: { 
        ...payload
        }};
        data.visitId = filters.selectedVisitID?  filters.selectedVisitID.value : '';
        data.dateRange = {
        startDate: moment(filters.range.startDate).startOf('day').format(),
        endDate: moment(filters.range.endDate).endOf('day').format()
        };
        data.app = session.user ? session.appRef._id : '';
        data.session = session;

        return data;
    };

    // Execute the mutation and trigger the notifications
    const executeMutation = (mutation, data, isEmail = false) => {
      mutation({ variables: { data: data }}).then(
        result => {
          reportCreationOnProgressNotification(globalActions);
        }, 
        error => {
          console.log(error, data)
          reportCreationFailedNotification(globalActions);
        });
    };

    const createChronologicalReportMutation = useMutation(CREATE_CHRONOLOGICAL_REPORT_MUTATION);

    const createChronologicalReport = React.useCallback((payload) => {
        const data = addGeneralData(payload);
        executeMutation(createChronologicalReportMutation, data); 
    }, [ createChronologicalReportMutation, filters, session, moment ]);

    // Select by default the first visit ID
    if (!filters.selectedVisitID && dataVisit.visits && dataVisit.visits.length) {
        const firstVisit = dataVisit.visits[0];
        filtersActions.setVisitID({label: firstVisit.name, value: firstVisit._id});
    }

    const filteredDataVisitAnswers = dataVisitAnswers && dataVisitAnswers.visitAnswers ? 
    dataVisitAnswers.visitAnswers.filter(visitAnswer => visitAnswer) : [];

    useMemo(() => {
      if (
        session.user &&
        !auth.hasPermission("chronologicalReportView", session.permissions)
      ) {
        globalActions.notification.openNotification("tr", {
          message: t('common:common.hasNotPermission'),
          color: "danger"
        });
        history.push('dashboard');
      } 
    }, [session]);

    if (loadingVisit || loadingVisitAnswers || loadingSegments || loadingUserSegment) {
        let closedCount = null;
        if (!loadingVisitAnswersClosed && dataVisitAnswersClosed) {
            const { visitAnswerConnection } = dataVisitAnswersClosed;
            if (visitAnswerConnection) {
            const { aggregate } = visitAnswerConnection;
            closedCount = aggregate.count;
            }
        }
        return <LoadingProcessView closedCount={closedCount} />
    }else{
        const selecteVisit = dataVisit.visits ? 
        dataVisit.visits.find((visit) => 
            filters.selectedVisitID && 
            filters.selectedVisitID.value === visit._id) 
        : null;

        const keyfields = getKeyfields(selecteVisit);
        const keyfieldOptions = keyfields.map(keyfield => ({ label: keyfield.name, value: keyfield.id }));

        const [,closedVisits] = classifyAndFilter(filteredDataVisitAnswers);
        const parsedClosed = closedVisits ? parseVisitAnswers(closedVisits, keyfields) : [];

        const reducedVisitAnswers = reduceVisitanswerKeyField(parsedClosed, currentKeyfield);

        let activities = [];
        if (selecteVisit) {
            activities = selecteVisit.engines.filter(engine => engine.type === 'Activity').map(engine => {
                if (engine.moduleRef) {
                    return {
                        label: engine.moduleRef.name,
                        value: engine.moduleId
                    }
                }
            });
        }

        const segmentOptions = [ 
          {value: "Todos", label: "Todos"},
          ...dataSegments.segments
          .map((item) => ({
            value: item._id,
            label: item.name
          }))
        ];

        const frequencyOptions = [
            { label: t('chronological.frequency.day'), value: 1 },
            { label: t('chronological.frequency.week'), value: 2 },
            { label: t('chronological.frequency.bweek'), value: 3 },
            { label: t('chronological.frequency.month'), value: 4 },
            { label: t('chronological.frequency.quarter'), value: 5 },
        ];

        const userSegment = dataUserSegment && dataUserSegment.userSegment ? dataUserSegment.userSegment : [];

        const visitAnswersFilter = filterVisitAnswerByUsersSegment(reducedVisitAnswers, userSegment);

        const visitAnswers = reduceVisitAnswerKeyfieldValues(visitAnswersFilter, keyfieldsValues);

        return (
            <ChronologicalReportView
                selecteVisit={selecteVisit ? selecteVisit : ''}
                visits={dataVisit.visits}
                segmentOptions={segmentOptions}
                activities={activities}
                closedVisits={visitAnswers}
                visitAnswer={visitAnswersFilter}
                segment={segment}
                handleSegmentChange={handleSegmentChange}
                selectActivities={selectActivities}
                handleActivitiesChange={handleActivitiesChange}
                keyfields={keyfields}
                keyfieldsOptions={keyfieldOptions}
                currentKeyfield={currentKeyfield}
                handleKeyFieldsChange={handleKeyFieldsChange}
                frequencyOptions={frequencyOptions}
                handleFrequencyChange={handleFrequencyChange}
                frequency={frequency}
                keyfieldsValues={keyfieldsValues}
                handleKeyfieldsValuesChange={handleKeyfieldsValuesChange}
                createChronologicalReport={createChronologicalReport}
            />
        )
    }
}

export default ChronologicalReport;
