import React from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Field, Form, withFormik } from "formik";
import { Editor } from 'react-draft-wysiwyg';
import * as yup from "yup";

import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Send from "@material-ui/icons/Send";
import Cancel from "@material-ui/icons/Cancel";

import ButtonIcon from "components/styled/ButtonIcon";
import CustomInput from "components/CustomInput/CustomInput.jsx";

const emailMessageFormSchema = yup.object().shape({
  contentHtml: yup.string(),
  to: yup.array(),
  reportType: yup.string(),
  subject: yup.string(),
});

function EmailMessageForm(props){
  const {
    onCancel,
    defaultBody,
    defaultSubject,
    hasXLS,
    setFieldValue,
    submitForm,
    values,
  } = props;

  const initialState = React.useMemo(() => {
    const blocksFromHtml = htmlToDraft(defaultBody[values.reportType]);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }, [ defaultBody, values.reportType ]);

  const [ content, setContent ] = React.useState(initialState);

  const handleChange = value => {
    setContent(value);
    setFieldValue('contentHtml', draftToHtml(convertToRaw(value.getCurrentContent())));
  }

  const handleReportTypeChange = event => {
    const blocksFromHtml = htmlToDraft(defaultBody[event.target.value]);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);

    setContent(editorState);
    setFieldValue('subject', defaultSubject[event.target.value]);
    setFieldValue('contentHtml', defaultBody[event.target.value]);
    setFieldValue('reportType', event.target.value);
  };

  return (
    <Form>
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Autocomplete
            multiple
            freeSolo
            id="tags-standard"
            options={[]} // This may be implemented in future iterations
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return <Chip label={option} {...getTagProps({ index })} />;
              })
            }
            onChange={(event, value) => setFieldValue("to", value)}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Correo"
                fullWidth
              />
            )}
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Field
            name="subject"
            render={({ field }) => {
              return (
                <CustomInput
                  labelText="Asunto: "
                  id="subject"
                  type={"text"}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    ...field
                  }}
                />
              );
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Editor
            editorState={content}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            onEditorStateChange={handleChange}
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={3}>
          <h4>Tipo de reporte: </h4>
        </GridItem>
        <GridItem xs={8}>
          <RadioGroup value={values.reportType} onChange={handleReportTypeChange} row>
            <FormControlLabel
              value="PDF"
              control={<Radio color="primary" />}
              label="PDF"
              labelPlacement="start"
            />
            {hasXLS && 
              <FormControlLabel
                value="XLS"
                control={<Radio color="primary" />}
                label="XLS"
                labelPlacement="start"
              />
            }
          </RadioGroup>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem>
          <ButtonIcon 
            icon={Cancel} 
            label="Cancelar" 
            onClick={onCancel}
          />
        </GridItem>
        <GridItem>
          <ButtonIcon
            icon={Send}
            label="Enviar"
            color={"info"}
            block={true}
            onClick={() => {
              submitForm();
            }}
          />
        </GridItem>
        
      </GridContainer>
    </Form>
  );
};

const EditOpportunityFormWF = withFormik({
    validationSchema: emailMessageFormSchema,
    mapPropsToValues: (props) => {
      return {
        contentHtml: props.defaultBody["PDF"],
        to: [],
        reportType: "PDF",
        subject: props.defaultSubject["PDF"],
      }
    },
    handleSubmit: (values, {props}) => {
        props.submitSuccess(values);
    },
})(EmailMessageForm);

export default EditOpportunityFormWF;
