export const videosMocks = [
    {
        _id: "1",
        name: 'Pantallas',
        url: 'https://youtu.be/EN6MyNzh4XA',
        type: 'CoreVideos',
    },
    {
        _id: "234234",
        name: 'Configuración de entrada y lista masiva',
        url: 'https://youtu.be/UmW0rdQnVCY',
        type: 'CoreVideos',
    },
    {
        _id: "asdsdas",
        name: 'Configuración escalas calificables',
        url: 'https://youtu.be/0woHyuOdA3A',
        type: 'CoreVideos',
    },
    {
        _id: "2",
        name: 'Motor de preguntas',
        url: 'https://youtu.be/Knt0JmUk7FU',
        type: 'CoreVideos',
    },
    {
        _id: "3",
        name: 'Salida',
        url: 'https://youtu.be/NI8y4lp_pVM',
        type: 'CoreVideos',
    },
    {
        _id: "4",
        name: 'Creación de visita y configuración de ponderaciones',
        url: 'https://youtu.be/CftbYFAR9fg',
        type: 'CoreVideos',
    },
    {
        _id: "5",
        name: 'Estatus de visita',
        url: 'https://youtu.be/PfniWAh1sho',
        type: 'CoreVideos',
    },
    {
        _id: '6',
        name: 'Configuración de correos y envío de reportes',
        url: 'https://youtu.be/xtZ4WUe98ls',
        type: 'SatelliteVideos',
    },
    {
        _id: '7',
        name: 'Contenido comercial',
        url: 'https://youtu.be/c3hiD63FQZ0',
        type: 'SatelliteVideos',
    },
    {
        _id: '8',
        name: 'Cómo configurar un plan de acción',
        url: 'https://youtu.be/Dt0_SK6G6Gg',
        type: 'SatelliteVideos',
    },
    {
        _id: '9',
        name: 'Geocercas',
        url: 'https://youtu.be/cmn7-cvdk84',
        type: 'SatelliteVideos',
    },
    {
        _id: '9',
        name: 'Geocercas',
        url: 'https://youtu.be/cmn7-cvdk84',
        type: 'SatelliteVideos',
    },
    {
        _id: '10',
        name: 'Reportes recurrentes',
        url: 'https://youtu.be/bLRO8pSiYGU',
        type: 'SatelliteVideos',
    },
    {
        _id: '11',
        name: 'Medios , plantillas, banners diseño',
        url: 'https://youtu.be/K_DpjcENrTs',
        type: 'SatelliteVideos',
    },
    {
        _id: '12',
        name: 'Campo tipo tabla',
        url: 'https://youtu.be/u-nvlP7KRBU',
        type: 'SatelliteVideos',
    },
    {
        _id: '13',
        name: 'SKU',
        url: 'https://youtu.be/KPSwf39LRJM',
        type: 'SatelliteVideos',
    },
    {
        _id: '14',
        name: 'Listas masivas',
        url: 'https://youtu.be/e3beKE2tRv0',
        type: 'SatelliteVideos',
    },
    {
        _id: '15',
        name: 'Campo tipo TOKEN',
        url: 'https://youtu.be/hDsxSfcn_Pk',
        type: 'SatelliteVideos',
    },
    {
        _id: '16',
        name: 'Seguridad global',
        url: 'https://youtu.be/IP9-is-f45Y',
        type: 'SatelliteVideos',
    },
    {
        _id: '17',
        name: 'Notificaciones Push',
        url: 'https://youtu.be/Le-cYjiMYps',
        type: 'SatelliteVideos',
    },
];

export const manualsMock = [
    {
        "_id" : "5b22146005b5890c00eef211",
        "name" : "Como eliminar pantalla Roja",
        "url" : "https://storage.cloud.google.com/production-commons/manuals/Quitar%20pantalla%20roja%20Android.pdf",
        "type" : "D",
        "tags" : [ 
            "manual"
        ],

    },
    {
        "_id" : "5b22146fcbd8160a003d10b1",
        "name" : "Como activar localización",
        "url" : "https://storage.googleapis.com/production-commons/manuals/GPSAndroid.pdf",
        "type" : "D",
        "tags" : [ 
            "manual"
        ],
    },
    {
        "_id" : "5b23d74090542c0b00c221c3",
        "name" : "Como descargar el previsualizador",
        "url" : "https://storage.cloud.google.com/production-master-franchises/5b23d74090542c0b00c221c3/Guia de como descargar el app_Idrz.pdf",
        "type" : "D",
        "tags" : [ 
            "manual"
        ],
    }
];