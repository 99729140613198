import React, {useMemo, useState}  from 'react'
import {useQuery } from "react-apollo-hooks";

// Local state
import useGlobal from '../../store';
import { USER_MOBILES_FEED } from '../Dashboard/gql/Query';
import UsersView from '../../Views/Users/UsersView';
import auth from '../../utils/auth';
import history from '../../utils/history';
import { useTranslation } from "react-i18next";

const Users = () => {
  const { t } = useTranslation("common");
  const [ session, globalActions ] = useGlobal(
    state => state.session,
    actions => actions
  );

  const [userMobileFilter, setUserMobileFilter] = useState({
    where: {
      app: session.user ? session.appRef._id : ""
    },
    first: 15,
    skip: 0,
    orderBy: "created_ASC"
  });

  async function paginationAction(values) {
    setUserMobileFilter(values);
  }

  const {
    data: dataUserMobile,
    error: errorUserMobile,
    loading: loadingUserMobile,
    refetch: refetchUserMobile
  } = useQuery(USER_MOBILES_FEED, {
    variables: userMobileFilter,
    fetchPolicy: "network-only"
  });

  useMemo(() => {
    if (
      session.user &&
      !auth.hasPermission("usersMobileView", session.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: t('common.hasNotPermission'),
        color: "danger"
      });
      history.push('dashboard');
    } 
  }, [session]);

  return (
    <UsersView 
        infoUsers={{ loading: loadingUserMobile, users: dataUserMobile }}
        paginationAction={paginationAction}
        userMobileFilter={userMobileFilter}
    />
  );
};

export default Users;