import React, {useState} from "react";

import Tooltip from "@material-ui/core/Tooltip";

import Button from 'components/Button';

const ConfirmOptionsIconButton = ({t, baseTooltip, baseLabel, baseColor, confirmDelay, confirmOptions, isLoading, ...props}) => {
  const [isConfirming, setIsConfirming] = useState(false);
  if (!isConfirming) {
    return (
      <Tooltip title={t(baseTooltip)}>
        <span>
          <Button
            justIcon={!baseLabel}
            simple
            size="sm"
            color={baseColor}
            onClick={() => {
              setIsConfirming(true);
              setTimeout(() => {
                setIsConfirming(false);
              }, confirmDelay)
            }}
          >
            <props.baseIcon/> {t(baseLabel)}
          </Button>
        </span>
      </Tooltip>
    );
  } else {
    return confirmOptions.map((confirmOption, index) => {
      return (
        <Tooltip title={t(confirmOption.confirmTooltip)} key={index}>
          <span>
            <Button
              justIcon
              round
              size="sm"
              color={confirmOption.confirmColor}
              onClick={confirmOption.confirmOperation}
            >
              <confirmOption.confirmIcon/>
            </Button>
          </span>
        </Tooltip>
      );
    })
  }
}

export default ConfirmOptionsIconButton;
