import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import DivCenter from "../../components/styled-components/DivCenter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import ConfirmIconButton from "../../components/ConfirmIconButton/ConfirmIconButton";

import TableChartIcon from "@material-ui/icons/TableChart";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PlayForWork from "@material-ui/icons/PlayForWork";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import useGlobal from '../../store';
import auth from '../../utils/auth';

const CounterReportsTable = ({ list, archive, isArchivedMode }) => {
  const { t } = useTranslation("so");

  const [session] = useGlobal((state) => state.session);

  const hasPermission = (permission) => {
    if (session.user && !auth.hasPermission(permission, session.permissions)) {
      return false;
    } else {
      return true;
    }
  };

  const getActions = (original) => {
    return (
      <div>
        <Link
          to={{
            pathname: `/so/counterReport/${original._id}/view`,
            state: {
              counterReport: original,
            },
          }}
        >
          <Button justIcon round simple color="info">
            <Tooltip title={t("so.counterReports.list.tooltip.viewReport")}>
              <TableChartIcon />
            </Tooltip>
          </Button>
        </Link>
        {hasPermission("soCounterEdit") ? (
          <Link
            to={{
              pathname: `/so/counterReport/${original._id}/edit`,
              state: {
                counterReport: original,
              },
            }}
          >
            <Button justIcon round simple color="info">
              <Tooltip title={t("so.counterReports.list.tooltip.edit")}>
                <EditIcon />
              </Tooltip>
            </Button>
          </Link>
        ) : (
          ""
        )}
        {hasPermission("soCounterArchived") ? (
          <ConfirmIconButton
            t={t}
            simple={true}
            baseTooltip={"so.counterReports.list.tooltip.archived"}
            confirmTooltip={"so.counterReports.list.tooltip.archiveConfirm"}
            baseIcon={DeleteForeverIcon}
            confirmDelay={3000}
            confirmOperation={() => {
              archive(original);
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }

  return (
    <ReactTable
      data={list}
      columns={[
        {
          Header: (
            <DivCenter>{t("so.counterReports.list.columns.name")}</DivCenter>
          ),
          accessor: "name",
          filterable: false,
          sortable: false,
          style: { textAlign: "center", whiteSpace: "unset" },
          minWidth: 15,
        },
        {
          Header: (
            <DivCenter>
              {t("so.counterReports.list.columns.periodicity")}
            </DivCenter>
          ),
          Cell: ({ original }) => {
            return (
              <DivCenter>
                {t(
                  `so.counterReports.forms.createUpdate.fields.periodicity.options.${original.periodicity}`
                )}
              </DivCenter>
            );
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center", whiteSpace: "unset" },
          minWidth: 20,
        },
        {
          Header: <DivCenter></DivCenter>,
          accessor: "actions",
          Cell: ({ original }) => {
            if (isArchivedMode) {
              return (
                <div>
                  <Button
                    justIcon
                    round
                    simple
                    color="success"
                    onClick={() => {
                      archive(original);
                    }}
                  >
                    <Tooltip
                      title={t("so.counterReports.list.tooltip.unArchive")}
                    >
                      <UnarchiveIcon />
                    </Tooltip>
                  </Button>
                </div>
              );
            }
            return getActions(original);
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "right" },
          minWidth: 30,
        },
      ]}
      defaultPageSize={15}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
    />
  );
};

CounterReportsTable.propTypes = {
  list: PropTypes.array.isRequired,
  archive: PropTypes.func.isRequired,
  isArchivedMode: PropTypes.bool.isRequired,
};

export default CounterReportsTable;
