import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import useGlobal from "../../store";
import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "../../components/Grid/GridContainer";
import {Field, Form, withFormik} from "formik";
import * as yup from "yup";
import Moment from "moment";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardText from "../../components/Card/CardText";
import CardTitle from "../../components/styled/CardTitle";
import ButtonIcon from "../../components/styled/ButtonIcon";
import SaveAlt from "@material-ui/icons/SaveAlt";
import CardBody from "../../components/Card/CardBody";
import {FormControl, FormLabel, TextField} from "@material-ui/core";
import {getErrorLabel, hasError} from "../../utils/forms/inputs";
import Select from "react-select";
import {VISIT_SCHEDULING_RESOLVER_TYPES} from "../../constants";
import {formatConstantOptions, formatSelectOptions} from "../../utils/formatter";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Datetime from "react-datetime";
import {AddCircle} from "@material-ui/icons";
import RangesTable from "../../tables/visitScheduling/RangesTable";
import CardFooter from "../../components/Card/CardFooter";
import history from "../../utils/history";
import Cancel from "@material-ui/icons/Cancel";

const createUpdateVisitSchedulingValidationSchema = yup.object().shape({
  visitId: yup.string().required("isRequired"),
  name: yup.string().required("isRequired"),
  description: yup.string().required("isRequired"),
  whoResolves: yup.string().required("isRequired"),
  rangeInitialDate: yup.date().max(yup.ref("rangeFinalDate")),
  rangeFinalDate: yup.date().min(yup.ref("rangeInitialDate")),
  ranges: yup.array().of(
    yup.object().shape({
      _id: yup.string(),
      initialDate: yup.date().required().max(yup.ref("finalDate")),
      finalDate: yup.date().required().min(yup.ref("initialDate")),
    })
  ),
  segments: yup.array().of(
    yup.string().required("isRequired")
  ),
  users: yup.array().of(
    yup.string().required("isRequired")
  )
});

const CreateUpdateVisitSchedulingForm = (props) => {
  const {t} = useTranslation("visitScheduling")
  const [, globalActions] = useGlobal();
  const classes = useStyles();

  // Formik bag
  const {
    errors,
    touched,
    setFieldValue,
    values,
    submitForm,
    isSubmitting,
    isValid,
  } = props;

  // Other props
  const {
    userMobiles,
    visits,
    segments,
  } = props;

  const userMobilesSelected = values.users ? userMobiles.filter(user => values.users.indexOf(user._id) >= 0) : [];
  const segmentsSelected = values.segments ? segments.filter(segment => values.segments.findIndex(item => item === segment._id) >= 0) : [];

  const getSelectedVisit = (periodicity) => {
    return {
      label: t(
        `so.counterReports.forms.createUpdate.fields.periodicity.options.${periodicity}`
      ),
      value: periodicity,
    };
  };

  return (
    <Form>
      <GridContainer justify="center">
        <GridItem xs={12} md={10} lg={6}>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <Card>
                <CardHeader color="info" text>
                  <GridContainer>
                    <GridItem xs={8}>
                      <CardText color="info">
                        <CardTitle>
                          {t("visitScheduling.forms.createUpdate.mainConfig")}
                        </CardTitle>
                      </CardText>
                    </GridItem>

                    <GridItem xs={4}>
                      <ButtonIcon
                        icon={SaveAlt}
                        label={
                          "visitScheduling.forms.createUpdate.buttons.save"
                        }
                        color={"success"}
                        block={true}
                        onClick={() => {
                          submitForm();
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                </CardHeader>

                <CardBody>
                  <GridContainer>

                    <GridItem xs={12} sm={12}>
                      <Field
                        name="name"
                        render={({field}) => {
                          return (
                            <TextField
                              {...field}
                              id="name"
                              helperText={
                                hasError(errors, "name")
                                  ? getErrorLabel(
                                  t,
                                  "visitScheduling.forms.createUpdate.fields",
                                  "name",
                                  errors)
                                  : t("visitScheduling.forms.createUpdate.fields.name.helperText")
                              }
                              label={t("visitScheduling.forms.createUpdate.fields.name.label")}
                              success={
                                !hasError(errors, "name") && touched.name
                              }
                              error={hasError(errors, "name")}
                              margin="normal"
                              type={"text"}
                              fullWidth
                            />
                          )
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12}>
                      <Field
                        name="description"
                        render={({field}) => {
                          return (
                            <TextField
                              {...field}
                              id="description"
                              helperText={
                                hasError(errors, "description")
                                  ? getErrorLabel(
                                  t,
                                  "visitScheduling.forms.createUpdate.fields",
                                  "description",
                                  errors)
                                  : t("visitScheduling.forms.createUpdate.fields.description.helperText")
                              }
                              label={t("visitScheduling.forms.createUpdate.fields.description.label")}
                              success={
                                !hasError(errors, "description") && touched.name
                              }
                              error={hasError(errors, "description")}
                              margin="normal"
                              type={"text"}
                              fullWidth
                            />
                          )
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12}>
                      <h4>{t('visitScheduling.forms.createUpdate.fields.visit.label')}</h4>
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <Autocomplete
                        id="select-visit"
                        options={visits}
                        value={values.visitRef}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined"/>
                        )}
                        onChange={(event, item) => {
                          if (item) {
                            setFieldValue("visitId", item._id);
                          } else {
                            setFieldValue("visitId", "");
                          }
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12}>
                      <FormControl className={classes.formControl}>
                        <FormLabel>
                          {t("visitScheduling.forms.createUpdate.fields.whoResolves.label")}
                        </FormLabel>
                        <Select
                          inputId={"select-whoResolves"}
                          placeholder={t("visitScheduling.forms.createUpdate.fields.whoResolves.helperText")}
                          value={values.whoResolves ? {
                            label: t(`visitScheduling.forms.createUpdate.fields.whoResolves.options.${values.whoResolves}`),
                            value: values.whoResolves
                          } : ''}
                          onChange={(item) => {
                            setFieldValue("whoResolves", item.value);
                          }}
                          options={formatConstantOptions(t,
                            "visitScheduling.forms.createUpdate.fields.whoResolves.options",
                            VISIT_SCHEDULING_RESOLVER_TYPES)}
                          styles={{
                            menu: (base) => ({...base, zIndex: 9999}),
                          }}
                        />
                      </FormControl>
                    </GridItem>

                    {values.whoResolves === "User" ? [
                      <GridItem xs={12} key="users-label">
                        <h4>{t('visitScheduling.forms.createUpdate.fields.users.label')}</h4>
                      </GridItem>,
                      <GridItem xs={12} sm={12} key="users-input">
                        <Autocomplete
                          id="select-users"
                          multiple={true}
                          options={userMobiles}
                          value={userMobilesSelected}
                          getOptionLabel={(option) => option.email}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined"/>
                          )}
                          onChange={(event, items) => {
                            console.log(items);
                            setFieldValue("users", items.map(item => item._id));
                          }}
                        />
                      </GridItem>
                    ] : (values.whoResolves === "Segment" ? [
                      <GridItem xs={12} key="segments-label">
                        <h4>{t('visitScheduling.forms.createUpdate.fields.segments.label')}</h4>
                      </GridItem>,
                      <GridItem xs={12} sm={12} key="segments-input">
                        <Autocomplete
                          id="select-segments"
                          multiple={true}
                          options={segments}
                          value={segmentsSelected}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined"/>
                          )}
                          onChange={(event, value) => {
                            console.log(value);
                            setFieldValue("segments", value.map(item => item._id));
                          }}
                        />
                      </GridItem>
                    ] : null)}

                    <GridItem xs={12}>
                      <h4>{t('visitScheduling.forms.createUpdate.fields.ranges.labelNew')}</h4>
                    </GridItem>

                    <GridItem xs={12}>
                      <GridContainer>
                        <GridItem xs={4}>
                          <div>
                            <Datetime
                              name="range_initial_date"
                              timeFormat={false}
                              closeOnSelect={true}
                              defaultValue={values.rangeInitialDate}
                              onChange={(e) => {
                                const dateToChange = e === "" ? e : e._d;
                                if (values.rangeFinalDate <= dateToChange) {
                                  setFieldValue("rangeFinalDate",
                                    Moment(dateToChange).add(1, 'days').toDate());
                                }
                                setFieldValue("rangeInitialDate", dateToChange);
                              }}
                              value={values.rangeInitialDate}
                            />
                          </div>
                          <div>
                            <p>{t('visitScheduling.forms.createUpdate.fields.ranges.init')}</p>
                          </div>
                        </GridItem>

                        <GridItem xs={4}>
                          <div>
                            <Datetime
                              name="range_final_date"
                              timeFormat={false}
                              closeOnSelect={true}
                              defaultValue={values.rangeFinalDate}
                              onChange={(e) => {
                                const dateToChange = e === "" ? e : e._d;
                                if (values.rangeInitialDate >= dateToChange) {
                                  setFieldValue("rangeInitialDate",
                                    Moment(dateToChange).subtract(1, 'days').toDate());
                                }
                                setFieldValue("rangeFinalDate", dateToChange);
                              }}
                              value={values.rangeFinalDate}
                            />
                          </div>
                          <div>
                            <p>{t('visitScheduling.forms.createUpdate.fields.ranges.end')}</p>
                          </div>
                        </GridItem>
                        <GridItem xs={4}>
                          <ButtonIcon
                            icon={AddCircle}
                            label={
                              "visitScheduling.forms.createUpdate.fields.ranges.addButton"
                            }
                            color={"info"}
                            block={true}
                            onClick={() => {
                              // validate duplicate ranges
                              const duplicateRange = values.ranges.find(
                                (range) => range.initialDate === values.rangeInitialDate.toISOString() &&
                                  range.finalDate === values.rangeFinalDate.toISOString());
                              if (duplicateRange) {
                                globalActions.notification.openNotification("tr", {
                                  message: t('visitScheduling.forms.createUpdate.validation.duplicateRange'),
                                  color: "warning"
                                });
                                return;
                              }
                              setFieldValue("ranges", [...values.ranges, {
                                initialDate: values.rangeInitialDate.toISOString(),
                                finalDate: values.rangeFinalDate.toISOString(),
                              }]);
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>

                    <GridItem xs={12}>
                      <h4>{t('visitScheduling.forms.createUpdate.fields.ranges.label')}</h4>
                    </GridItem>

                    <GridItem xs={12}>
                      <RangesTable
                        data={values.ranges}
                        updateData={(data) => {
                          setFieldValue("ranges", data);
                        }}
                      />
                    </GridItem>

                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <GridContainer>
                    <GridItem xs={4}>
                      <ButtonIcon
                        icon={Cancel}
                        label={
                          "visitScheduling.forms.createUpdate.buttons.cancel"
                        }
                        color={"primary"}
                        onClick={() => {
                          history.goBack()
                        }}
                      />
                    </GridItem>
                    <GridItem xs={8}>
                      <ButtonIcon
                        icon={SaveAlt}
                        label={
                          "visitScheduling.forms.createUpdate.buttons.save"
                        }
                        color={"success"}
                        block={true}
                        onClick={() => {
                          submitForm();
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Form>
  );
};

CreateUpdateVisitSchedulingForm.propTypes = {};


const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  radioControl: {
    width: "100%",
    textAlign: "left",
  },
  formLabel: {
    marginLeft: "15px",
  },
  radio: {
    "&$checked": {
      color: "#6ab3d4",
    },
  },
  checked: {},
}));

const CreateUpdateVisitSchedulingWF = withFormik({
  validationSchema: createUpdateVisitSchedulingValidationSchema,
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const {initialValues} = props;
    if (initialValues) {
      return {
        ...initialValues,
        rangeInitialDate: Moment().startOf("day").toDate(),
        rangeFinalDate: Moment().startOf("day").add(1, "days").toDate(),
      }
    } else {
      // default values
      return {
        name: "",
        description: "",
        whoResolves: "",
        rangeInitialDate: Moment().startOf("day").toDate(),
        rangeFinalDate: Moment().startOf("day").add(1, "days").toDate(),
        ranges: [],
        segments: [],
        users: [],
      };
    }
  },
  handleSubmit: (values, {props}) => {
    let {
      rangeInitialDate,
      rangeFinalDate,
      visitRef,
      _id,
      ...rest} = values;
    props.submitSuccess({
      ...rest,
      ranges: rest.ranges.map(({key, ...rest}) => ({...rest}))
    });
  },
  validateOnChange: false,
})
(CreateUpdateVisitSchedulingForm);

export default CreateUpdateVisitSchedulingWF;
