import React, {useEffect, useState} from "react";
import DivCenter from "../../components/styled-components/DivCenter";
import {Tooltip, Typography} from "@material-ui/core";
import ReactTable from "react-table";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import Button from "../../components/CustomButtons/Button";
import DeleteIcon from "@material-ui/icons/Delete";

const RangesTable = props => {
  const { t } = useTranslation("visitScheduling")
  const { updateData } = props;
  const [selectedRanges, updateSelectedRanges] = useState([]);

  useEffect(() => {
    let counter = 0;
    updateSelectedRanges(props.data.map((item) => {
      return { ...item, key: counter++ };
    }));
  }, [props.data]);

  return (
    <ReactTable
      data={selectedRanges}
      columns={[
        {
          Header: (
            <DivCenter>
              {t("visitScheduling.forms.createUpdate.fields.ranges.initialDate.label")}
            </DivCenter>
          ),
          accessor: "initialDate",
          filterable: false,
          sortable: false,
          style: { textAlign: "center", whiteSpace: "unset"},
          Cell: ({ original }) => {
            return (<Typography>
              {" "}
              {moment(original.initialDate).format('DD/MM/YYYY')}
            </Typography>)
          }
        },
        {
          Header: (
            <DivCenter>
              {t("visitScheduling.forms.createUpdate.fields.ranges.finalDate.label")}
            </DivCenter>
          ),
          accessor: "finalDate",
          filterable: false,
          sortable: false,
          style: { textAlign: "center", whiteSpace: "unset"},
          Cell: ({ original }) => {
            return (<Typography>
              {" "}
              {moment(original.finalDate).format('DD/MM/YYYY')}
            </Typography>)
          }
        },
        {
          Header: <DivCenter/>,
          accessor: "actions",
          Cell: ({ original }) => {
            return (
              <div>
                <Button justIcon round simple color="info"
                        onClick={() => {
                          updateData(selectedRanges.filter(item => item.key !== original.key));
                        }}
                >
                  <Tooltip title={t("visitScheduling.forms.createUpdate.fields.ranges.deleteButton")}>
                    <DeleteIcon />
                  </Tooltip>
                </Button>
              </div>
            );
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center", whiteSpace: "unset"},
        }
      ]}
      pageSize={props.data.length}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
      noDataText={t("visitScheduling.forms.createUpdate.fields.ranges.empty")}
      resizable={false}
    />
  );
}

RangesTable.propTypes = {
  data: PropTypes.array.isRequired
};

export default RangesTable;
