import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { CircularProgress } from "@material-ui/core";

import { SO_CHECKLIST_REPORTS_BASE_MODELS } from "./gql/Queries";
import { CREATE_SO_CHECKLIST_REPORT } from "./gql/Mutations";

import CreateUpdateChecklistReportsForm from "../../forms/SO/CreateUpdateChecklistReportsForm";

import useGlobal from "../../store";
import history from "utils/history";

const CounterReportNew = () => {
  const [session, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const {
    data: dataSOChecklistReportsBaseModels,
    error: errorSOChecklistReportsBaseModels,
    loading: loadingSOChecklistReportsBaseModels,
    refetch: refetchSOChecklistReportsBaseModels,
  } = useQuery(SO_CHECKLIST_REPORTS_BASE_MODELS, {
    variables: {
      app: session.user ? session.appRef._id : "",
      archived: false,
    },
    fetchPolicy: "network-only",
  });

  const [createSOChecklistReport] = useMutation(CREATE_SO_CHECKLIST_REPORT, {
    onCompleted: (mutationData) => {
      console.log({ mutationData });
      history.push("/so/checklistReport");
    },
  });

  if (loadingSOChecklistReportsBaseModels) {
    return <CircularProgress />;
  }
  return (
    <CreateUpdateChecklistReportsForm
      visits={dataSOChecklistReportsBaseModels?.visits || []}
      processRelationships={
        dataSOChecklistReportsBaseModels?.getProcessRelationships || []
      }
      submitSuccess={(values) => {
        createSOChecklistReport({
          variables: {
            data: {
              app: session.user ? session.appRef._id : "",
              ...values,
            },
          },
        });
      }}
    />
  );
};

export default CounterReportNew;
