import React from 'react';
import {compose} from 'recompose';
import withStyles from "@material-ui/core/styles/withStyles";

/*
* TODO
* Tried to import and apply styles with styled components as in the commented
* section below
*
* */
// import styled from 'styled-components';
// import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

// const CardTitle = styled.h4({...cardTitle});
// export default CardTitle;


const CardTitle = ({classes, children}) => {
  return(<h4 className={classes.cardTitle}>{children}</h4>)
};

const styles = {
};

export default compose(
  withStyles(styles),
)(CardTitle);
